import React, {useEffect, useState} from 'react';
import {Button} from "@material-ui/core";
import {ArrowDownwardOutlined} from "@material-ui/icons";
import fileExportElement from "../../../../helper/fileExportElement";
import service from "../../../../service/service";
import {useOrganization} from "../../../../hooks/useOrganization";


export default function ReportDownload(props) {
    const {data} = {...props}
    const {organization: masterAccountId} = useOrganization();
    const [downloadStatus, setDownloadStatus] = useState({text: 'DOWNLOAD', disabled: false})

    const handleDownload = async () => {
        if (masterAccountId !== 'no org') {
            setDownloadStatus({text: 'DOWNLOADING...', disabled: true});
            const params = {
                "master_account_id": masterAccountId.toString(),
                "account_id": data.account,
                "date": data.item.x
            }
            const response = await service.accountTrend().downloadDailyTrendForApplication(params);
            fileExportElement(response)
            setDownloadStatus({text: 'DOWNLOAD', disabled: false});
        }
    }


    useEffect(() => {
        setDownloadStatus({text: 'DOWNLOAD', disabled: false})
    }, [])

    return (
        <Button variant={'contained'}
                color={'secondary'}
                onClick={handleDownload}
                disabled={downloadStatus.disabled}
                disableElevation
                startIcon={<ArrowDownwardOutlined/>}>
            {downloadStatus.text}
        </Button>
    )
}