export const notifications = [
    {
        id: '1',
        title: 'Current user expiring soon',
        description: 'Current user will expire on Jan 01, 2022. Request for a new user now',
        tags: [
            'system', 'finops'
        ],
        createdTime: 'Oct 24, 2021',
        category: 'FINOPS',
        read: false
    },
    /*{
        id: '2',
        title: 'Weekend $5000 Savings',
        description: 'For last 4 weeks there is an average savings of $5000 by power scheduling instances and RDS in a week',
        tags: [
            'finops', 'power schedule'
        ],
        createdTime: 'Oct 24, 2021',
        category: 'FINOPS',
        read: false
    },

    {
        id: '3',
        title: 'Network information',
        description: 'Subnet and VPC information is now available on management and governance',
        tags: [
            'savings', 'finops',
        ],
        createdTime: 'Oct 24, 2021',
        category: 'FINOPS',
        read: false
    }*/
]