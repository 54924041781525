import './App.scss';
import './assets/data-grid.scss';
import {MainRoute, ParentRoute} from './routes/MainRoute'
import Dashboard from "./components/Dashboard/Dashboard";
import React, {useEffect, useState} from "react";
import {BrowserRouter as Router} from "react-router-dom";
import OrganizationContext from "./context/OrganizationContext";
import ErrorBoundary from "./components/General/ErrorBoundary";
import GlobalProvider from "./provider";


const loader = document.querySelector(".preloader");

const showLoader = () => loader.classList.remove("preloader");
const addClass = () => loader.classList.add("loader-hide");

function App() {

    useEffect(() => {
        showLoader();
        addClass();
    }, []);

    return (
        <div className="app-wrapper">
            <GlobalProvider>
                <Router history>
                    <ParentRoute/>
                </Router>
            </GlobalProvider>
        </div>
    );
}

function MainApp() {
    return (
        <div className="app-wrapper">
            <Router history>
                <div className="app-container">
                    <Dashboard/>
                    <div className="dashboard-main-wrapper">
                        <ErrorBoundary>
                            <MainRoute/>
                        </ErrorBoundary>
                    </div>
                </div>
            </Router>
        </div>
    )
}

export {App, MainApp};




