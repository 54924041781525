import CustomCellEditor from "../General/DataGrid/CustomCellEditor";


export function searchGridColumnDefinition(otherHeaders) {

    const searchFixedColumns = [{
        field: "resourceArn",
        headerName: 'Amazon Resource Name',
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        width: 250
    }]

    const ignoreList = ['arn', 'amazonresourcename', 'type', 'resourcearn']
    return otherHeaders.reduce((acc, curr) => {
        if (ignoreList.includes(curr?.toLowerCase())) return acc
        acc.push({field: curr, sortable: true, cellRendererFramework: CustomCellEditor})
        return acc
    }, searchFixedColumns)
}


export function uploadGridColumnDefinition(otherHeaders) {

    const searchFixedColumns = [{
        field: "resourceArn",
        headerName: 'Amazon Resource Name',
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        width: 250
    }]

    const ignoreList = ['arn', 'amazonresourcename', 'type', 'resourcearn']
    return otherHeaders.reduce((acc, curr) => {
        if (ignoreList.includes(curr?.toLowerCase())) return acc
        acc.push({field: curr, editable: curr.startsWith('Tag: '), sortable: true})
        return acc
    }, searchFixedColumns)
}