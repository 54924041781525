import {Autocomplete as MuiAutocomplete} from "@material-ui/lab";
import {WuTextField} from "../../components/General";


export default function Autocomplete(props) {
    const {
        autocompleteProps,
        textFieldProps,
        options,
        label = '',
        placeholder = '',
        value,
        onChange,
        onInputChange
    } = {...props}

    return (
        <MuiAutocomplete
            options={options}
            onChange={onChange}
            fullWidth
            value={value}
            onInputChange={onInputChange}
            renderInput={(params) => <WuTextField {...params} label={label}
                                                  placeholder={placeholder} {...textFieldProps}/>}
            {...autocompleteProps}
        />
    )
}