import React, {useEffect, useState} from 'react';
import DataGrid from "../../General/DataGrid/DataGrid";
import service from "../../../service/service"
import IconButton from "@material-ui/core/IconButton";
import {HelpOutlineOutlined, ScheduleOutlined} from "@material-ui/icons";
import Box from "@material-ui/core/Box";
import PowerScheduleCreate from "./PowerScheduleCreate";
import {AlertWu} from "../../AlertWu/AlertWu";
import {ProgressCircleCustomSize} from "../../ProgressCircle/ProgressCircle";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {useOrganization} from "../../../hooks/useOrganization";
import {Button, Checkbox, Divider, FormControlLabel, Paper, Typography} from "@material-ui/core";
import PowerScheduleUsageSummaryGraph from "./PowerScheduleUsageSummaryGraph";
import PowerScheduleApplicationInformation from "./PowerScheduleApplicationInformation";
import WuToolTip from "../../General/WuToolTip";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import WeeklyPowerSchedule from "./BatchPowerSchedule";
import DownloadReport from "./DownloadReport";

const admin = localStorage.getItem("loginType") === "admin";
export default function PowerSchedule() {
    const organization = useOrganization();
    const [powerSchedule, setPowerSchedule] = useState([]);
    const [masterAccountId, setMasterAccountId] = useState(organization.organization);
    const columns = [
        {
            field: "ApplicationName",
            resizable: true,
            sortable: true,
            suppressAutoSize: false
        },
        {
            field: "EC2",
            headerName: "Power Scheduled EC2",
            resizable: true,
            sortable: true,
            type: 'numericColumn',
            suppressAutoSize: false,
            valueGetter: function (params) {
                return params.data.EC2.powerScheduled
            }
        },
        {
            field: "EC2",
            headerName: "Non Scheduled EC2",
            resizable: true,
            sortable: true,
            type: 'numericColumn',
            suppressAutoSize: false,
            valueGetter: function (params) {
                return (params?.data?.EC2?.availableCount - params?.data?.EC2?.powerScheduled || 0)
            }
        },
        {
            field: "RDS",
            headerName: "Power Scheduled RDS",
            resizable: true,
            sortable: true,
            type: 'numericColumn',
            suppressAutoSize: false,
            valueGetter: function (params) {
                return params.data.RDS.powerScheduled
            }
        },
        {
            field: "EC2",
            headerName: "Non Scheduled RDS",
            resizable: true,
            sortable: true,
            type: 'numericColumn',
            suppressAutoSize: false,
            valueGetter: function (params) {
                return (params?.data?.RDS?.availableCount - params?.data?.RDS?.powerScheduled || 0)
            }
        },
        {
            cellRendererSelector: function (params) {
                let infoComponent = {
                    component: 'informationCellRenderer'
                }
                let errorComponent = {
                    component: 'errorCellRenderer'
                }
                if (params.data.statusViolation === 1) {
                    return errorComponent
                } else {
                    return infoComponent
                }
            },
            sortable: false,
            resizable: false,
            filter: false,
            width: 80,
            suppressAutoSize: false,
            cellRendererParams: {
                clicked: function (field) {
                    handleApplicationUpdate(field)
                }
            },
        }
    ]

    const [loading, setLoading] = useState(false);
    const [ready, setReady] = useState(false);
    const [createOpen, setCreateOpen] = useState(false);
    const [update, setUpdate] = useState(false);
    const [updateOpen, setUpdateOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState([false]);
    const [openSelectedCreateItem, setOpenSelectedCreateItem] = useState(null);
    const [powerScheduledOnly, setPowerScheduledOnly] = useState(true);
    const [createUpdateStatus, setCreateUpdateStatus] = useState(false);

    const [error, setError] = useState({
        error: false,
        message: ""
    })

    const handleApplicationUpdate = (e) => {
        setSelectedItem(e);
        setUpdateOpen(true);
    }
    const BtnCellRenderer = (props) => {
        const btnClickedHandler = (field) => {
            props.clicked(props.data)
        }
        return (
            <IconButton onClick={btnClickedHandler} aria-label="info" size="medium">
                <InfoOutlinedIcon fontSize="inherit"/>
            </IconButton>
        )
    }
    const CriticalError = (props) => {
        const btnClickedHandler = (field) => {
            props.data['event'] = "create";
            props.clicked(props.data)
        }
        return (
            <IconButton aria-label="info" onClick={btnClickedHandler} size="medium" style={{color: "red"}}>
                <WuToolTip title={'Power schedule for some instance/RDS removed manually from AWS'}>
                    <ErrorOutlineOutlinedIcon fontSize="inherit"/>
                </WuToolTip>

            </IconButton>
        )
    }
    const handleCreateDialogOpen = () => {
        setCreateOpen(true)
    }
    const request = async (event) => {
        try {
            setLoading(true);
            const params = {master_account_id: masterAccountId, power_scheduled_only: powerScheduledOnly}
            const response = await service.automations().getPowerScheduleSummary(params);
            setPowerSchedule(response.data.data);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            setError({error: true, message: e.message})
        }
    }
    const handleSchedule = async (e) => {
        if (e === "update") {
            await request();
            setUpdate(true);
        } else if (e === "close") {
            setOpenSelectedCreateItem(null);
            setCreateOpen(false);
        }
    }
    useEffect(() => {
        setMasterAccountId(organization.organization)
    }, [organization])
    useEffect(() => {
        if (masterAccountId !== "no org") {
            setReady(false);
            request().then(r => setReady(true));
        }
    }, [masterAccountId])
    const handleUpdateSchedule = async (e) => {
        if (e === "update") {
            await request();
            setUpdate(true);
        } else if (e === "close") {
            setUpdateOpen(false);
        } else if (e?.event === "create") {
            setOpenSelectedCreateItem(e.data);
            handleCreateDialogOpen();
        }
    }


    useEffect(() => {
        handleUpdateSchedule('update')
    }, [powerScheduledOnly])

    return (
        <Box>
            <DownloadReport/>
            <PowerScheduleApplicationInformation onChange={(e) => handleUpdateSchedule(e)} open={updateOpen}
                                                 data={selectedItem}
                                                 admin={admin}
                                                 updateStatus={createUpdateStatus}
                                                 masterAccountId={masterAccountId}
                                                 onClick={() => setUpdateOpen(true)}/>
            <PowerScheduleCreate onChange={(e) => handleSchedule(e)}
                                 onClick={(e) => setCreateUpdateStatus(!createUpdateStatus)}
                                 open={createOpen}
                                 data={openSelectedCreateItem}
                                 masterAccountId={masterAccountId}/>
            {
                loading &&
                <Box ml={1} display={'flex'} className={'gap'} alignItems={'center'}>
                    <ProgressCircleCustomSize size={16}/>
                    <Typography>Please wait...</Typography>
                </Box>
            }
            <Box mt={1}>
                <AlertWu alertOpen={{state: error.error}} severity={"error"} message={error.message}/>
            </Box>
            {
                ready &&
                <Box display={"flex"} className={"gap"} width={"100%"}>
                    <div className="onboarding-grid-wrapper w-100">
                        <DataGrid
                            update={update}
                            fileName={'power_schedule_summary'}
                            gridStyle={{height: "calc(100vh - 340px)"}}
                            gridData={powerSchedule.applicationWiseSummary}
                            frameworkComponents={{
                                informationCellRenderer: BtnCellRenderer,
                                errorCellRenderer: CriticalError,
                            }}
                            fixedColumn={columns}/>
                    </div>
                    <PowerScheduleSummaryCard onClick={handleCreateDialogOpen} data={powerSchedule.overAllSummary}
                                              powerScheduledOnly={powerScheduledOnly}
                                              onChange={(e) => setPowerScheduledOnly(e)}/>
                </Box>
            }
        </Box>
    )
}


function PowerScheduleSummaryCard(props) {
    const {data, powerScheduledOnly} = {...props}

    return (
        <Paper variant={"outlined"} style={{width: "300px"}}>
            <PowerScheduleItemSummary title={"Power Scheduled EC2"} value={data?.EC2?.powerScheduledCount || 0}
                                      borderBottomColor={"#ff0404"}/>
            <PowerScheduleItemSummary title={"Power Scheduled RDS"} value={data?.RDS?.powerScheduledCount || 0}
                                      borderBottomColor={"rgba(2,112,255,0.89)"}/>
            {
                admin &&
                <Box m={2} display={'flex'} className={'d-flex'}>
                    {/*<Button
                        onClick={props.onClick}
                        variant={"contained"}
                        color={"primary"}
                        startIcon={<EventOutlinedIcon/>}
                        disableElevation>
                        DAILY
                    </Button>*/}
                    <WeeklyPowerSchedule/>
                </Box>
            }

            <Box m={2} mt={3}><Divider/></Box>

            <Box m={2} mt={2} className={"custom-graph-wrapper"}>
                <Typography>
                    Power scheduled vs Total
                </Typography>
                <PowerScheduleUsageSummaryGraph progress={data?.EC2?.percentageOfPowerSchedule||0}
                                                bodyData={`EC2 - ${data?.EC2?.powerScheduledCount || 0}/${data?.ec2?.possiblePowerScheduleCount || 0}`}
                                                color={"rgba(255,84,41,0.56)"}/>
                <PowerScheduleUsageSummaryGraph progress={data?.RDS?.percentageOfPowerSchedule || 0}
                                                bodyData={`RDS - ${data?.RDS?.powerScheduledCount || 0}/${data?.RDS?.possiblePowerScheduleCount || 0}`}
                                                color={"rgba(41,144,255,0.56)"}/>
            </Box>
            <Box m={2} mt={3}><Divider/></Box>
            <Box m={2} mt={0}>
                <FormControlLabel
                    control={<Checkbox checked={powerScheduledOnly}
                                       onChange={(e) => props.onChange(e.target.checked)}
                                       name="powerScheduledOnly"/>}
                    label="Power Scheduled Only"
                />
            </Box>
        </Paper>
    )
}


function PowerScheduleItemSummary({title, value, borderBottomColor = "#000"}) {
    return (
        <Paper elevation={1} variant={"outlined"} className={"power-schedule-summary-card"}
               style={{borderBottom: `4px solid ${borderBottomColor}`}}>
            <div className={"custom-help-text"}>
                <HelpOutlineOutlined fontSize={"small"}/>
            </div>
            <Typography variant={"h4"} gutterBottom style={{marginLeft: -3}}>
                {value ? value.toLocaleString() : 0}
            </Typography>
            <Typography>
                {title}
            </Typography>
        </Paper>
    )
}