import {useState} from "react";
import {Box, CircularProgress, Paper, Typography} from "@material-ui/core";
import {CloudUploadOutlined} from "@material-ui/icons";
import {UploadWidget} from "./UploadWidget";
import {AdditionalOptions} from "./AdditionalOptions";
import {TitleNote} from "../TitleNode";
import {LabelWithHelpText, WuAlert, WuButton} from "../../General";
import {Accounts} from "../../General/WuAutoComplete";

export function UploadHome(props) {
    const {value, onValueChange, onUpload, apiProgress, uploadProgress} = {...props}
    const [myFiles, setMyFiles] = useState([]);

    return (
        <Paper className={'common-wrapper-class at-upload-tag'} variant={'outlined'}>
            <TitleNote title={'Upload Tag Report'} subTitle={'upload tag report and tag resources'}/>
            <Box my={2}>
                <UploadWidget
                    myFiles={myFiles}
                    setMyFiles={setMyFiles}
                    progress={uploadProgress?.percentage || 0}/>
            </Box>
            <Box my={2}>
                <LabelWithHelpText title={'Account Id'} helpText={'Select account id'}/>
                <Box mt={0.5}>
                    <Accounts
                        onChange={(e) => onValueChange('accountId', e.accountId)}
                        textFieldProps={{label: ''}}
                    />
                </Box>
            </Box>
            <Box my={2}>
                <AdditionalOptions
                    value={value}
                    onValueChange={(e) => onValueChange('additionalOptions', e)}
                />
            </Box>
            <Box display={'flex'} gridGap={8} mb={1} alignItems={'center'} justifyContent={'space-between'}>
                <Box display={'flex'} gridGap={8} mb={1} alignItems={'center'}>
                    <WuButton
                        onClick={() => onUpload(myFiles)}
                        disabled={apiProgress.loading}
                        style={{width: 100}}
                        startIcon={apiProgress.loading ? <CircularProgress size={16} color={'secondary'}/> :
                            <CloudUploadOutlined/>}
                    >
                        UPLOAD
                    </WuButton>
                    <WuButton variant={'text'} color={'secondary'}>
                        CLEAR
                    </WuButton>
                </Box>
                {
                    apiProgress.loading && <Typography gutterBottom>
                        {uploadProgress?.percentage < 99 ? 'file uploading...' : 'processing...'}
                    </Typography>
                }
            </Box>
            <WuAlert error={Boolean(apiProgress.error)}
                     success={false} errorMessage={apiProgress.error}
                     paperProps={{
                         style: {
                             marginTop: Boolean(apiProgress.error) ? '16px' : 0,
                             marginBottom: Boolean(apiProgress.error) ? '8px' : 0
                         }
                     }}
            />
        </Paper>
    )
}