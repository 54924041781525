import React, {useEffect, useState} from "react";
import {InputLabel, MenuItem} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

export default function TimeZoneSelector(props) {
    const {value, error} = {...props}
    const [retention, setRetention] = useState(value);


    useEffect(() => {
        props.onChange(retention);
    }, [retention])

    return (
        <FormControl variant="outlined" size={"small"} style={{marginTop: 8}} fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">Retention In Days</InputLabel>
            <Select
                error={error}
                margin={"dense"}
                value={retention}
                onChange={(e) => {
                    setRetention(e.target.value)
                }}
                label="Retention In Days"
            >
                <MenuItem value={'1'}>1</MenuItem>
                <MenuItem value={'3'}>3</MenuItem>
                <MenuItem value={'5'}>5</MenuItem>
                <MenuItem value={'7'}>7</MenuItem>
                <MenuItem value={'14'}>14</MenuItem>
                <MenuItem value={'30'}>30</MenuItem>
                <MenuItem value={'60'}>60</MenuItem>
                <MenuItem value={'90'}>90</MenuItem>
                <MenuItem value={'120'}>120</MenuItem>
                <MenuItem value={'150'}>150</MenuItem>
                <MenuItem value={'180'}>180</MenuItem>
                <MenuItem value={'365'}>365</MenuItem>
                <MenuItem value={'400'}>400</MenuItem>
                <MenuItem value={'545'}>545</MenuItem>
                <MenuItem value={'731'}>731</MenuItem>
                <MenuItem value={'1827'}>1827</MenuItem>
                <MenuItem value={'3653'}>3653</MenuItem>
            </Select>
        </FormControl>
    )
}