import React, {useEffect, useState} from 'react';
import './custom_wu_report.scss';
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import service from "../../../service/service";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReportSummary from "./ReportSummary/ReportSummary";
import {PaperProgress} from "../../ProgressCircle/ProgressCircle";
import {AlertWu} from "../../AlertWu/AlertWu";
import {
    accountSpendGraph,
    axisShift, axisShiftApplication, axisShiftEnvironment,
    axisShiftMonthlyCostReport, axisShiftSummary,
} from "../../../helper/dataParser";
import {Accounts, CostCode} from "../../General/WuAutoComplete";
import {Card, CardActions, CardContent, InputLabel, MenuItem, Select} from "@material-ui/core";
import PageTitle from "../../General/PageTitle/PageTitle";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {useOrganization} from "../../../hooks/useOrganization";
import ResourceIdBasedReport from "./ResourceIdBasedReport/index.";
import WuTextField from "../../General/WuTextFeild";
import FormControl from "@material-ui/core/FormControl";
import WuSelect from "../../General/WuSelect";
import InputBox from "./ResourceIdBasedReport/InputBox";
import ShowbackReport from "./ShowbackReport";
import moment from "moment";
import {ConsolidatedSpend} from "./Graphs/Graphs";

//dummy
import bu1 from '../../../assets/finthesys/bu1.json';
import bu2 from '../../../assets/finthesys/bu2.json';
import bu3 from '../../../assets/finthesys/bu3.json';
import bu4 from '../../../assets/finthesys/bu4.json';


function totalSummary(data) {
    let sum = 0
    data.forEach((item) => {
        sum += Number(item.Cost)
    })
    return sum;
}


export default function CustomWuReport() {
    const {organization: masterAccountId} = useOrganization()
    const currentYear = moment().format('YYYY')
    const YEAR_INITIAL = {
        applicationName: currentYear,
        applicationOwner: currentYear,
        costCode: currentYear,
        account: currentYear
    }

    const [loading, setLoading] = useState(false);
    const [preview, setPreview] = useState(false);
    const [applicationName, setApplicationName] = useState("");
    const [applicationData, setApplicationData] = useState([]);
    const [applicationOwnerData, setApplicationOwnerData] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [applicationOwner, setApplicationOwner] = useState("");
    const [costCode, setCostCode] = useState("1200");
    const [accountParam, setAccountParam] = useState("183270399954");
    const [error, setError] = useState("");
    const [errorBoundary, setErrorBoundary] = useState(false);
    const [summaryRequest, setSummaryRequest] = useState(false);
    const [state, setState] = useState(0);
    const [summaryData, setSummaryData] = useState([]);
    const [resourceIdCondition, setResourceIdCondition] = useState('EQUALS');
    const [resourceIdOpen, setResourceIdOpen] = useState(false);
    const [resourceId, setResourceId] = useState([]);
    const [selectedYear, setSelectedYear] = useState(YEAR_INITIAL)


    useEffect(() => {
        setLoaded(true);
        service.general().getApplicationColumns({searchKey: 'ApplicationName', masterAccountId: masterAccountId})
            .then(resApplication => {
                setApplicationData(resApplication.data.data);
            })
            .catch(err => {
                setLoaded(false);
            })
        setLoaded(true);
        service.general().getApplicationColumns({searchKey: 'ApplicationOwner', masterAccountId: masterAccountId})
            .then(resOwner => {

                setApplicationOwnerData(resOwner.data.data);
                setLoaded(false);
            })
            .catch(err => {
                setLoaded(false);
            })

    }, [masterAccountId])

    const handleYearChange = (newValue, triggerFrom) => {
        setSelectedYear(current=>{
            return {...current,  [triggerFrom]: newValue}
        })
    }

    const handleClick = (event) => {
        setPreview(false);
        setLoaded(true);
        setSummaryRequest(true);
        setErrorBoundary(false);
        setState(1);
        setError("");
        const body = {
            account: {
                "query_type": "WUUBA",
                "ad_hoc": "false",
                "account_id": masterAccountId.toString(),
                "application_name": applicationName,
                "year": selectedYear.applicationName
            },
            serviceReport: {
                "query_type": "WUUBS",
                "ad_hoc": "false",
                "account_id": masterAccountId.toString(),
                "application_name": applicationName,
                "year": selectedYear.applicationName
            },
            serviceAccount: {
                "query_type": "WUUBAS",
                "ad_hoc": "false",
                "account_id": masterAccountId.toString(),
                "application_name": applicationName,
                "year": selectedYear.applicationName
            },
            usageSummary: {
                "query_type": "WUUS",
                "ad_hoc": "false",
                "account_id": masterAccountId.toString(),
                "application_name": applicationName,
                "year": selectedYear.applicationName
            }
        }
        apiHandler(body, "name", applicationName);
    }

    const handleClickOwner = (event) => {
        setPreview(false);
        setLoaded(true);
        setSummaryRequest(true);
        setErrorBoundary(false);
        setState(1);
        setError("");
        const body = {
            account: {
                "query_type": "WUUBA",
                "ad_hoc": "false",
                "account_id": masterAccountId.toString(),
                "application_owner": applicationOwner,
                "year": selectedYear.applicationOwner
            },
            serviceReport: {
                "query_type": "WUUBS",
                "ad_hoc": "false",
                "account_id": masterAccountId.toString(),
                "application_owner": applicationOwner,
                "year": selectedYear.applicationOwner
            },
            serviceAccount: {
                "query_type": "WUUBAS",
                "ad_hoc": "false",
                "account_id": masterAccountId.toString(),
                "application_owner": applicationOwner,
                "year": selectedYear.applicationOwner
            },
            applicationName: {
                "query_type": "WUUBAN",
                "ad_hoc": "false",
                "account_id": masterAccountId.toString(),
                "application_owner": applicationOwner,
                "year": selectedYear.applicationOwner
            },
            usageSummary: {
                "query_type": "WUUS",
                "ad_hoc": "false",
                "account_id": masterAccountId.toString(),
                "application_owner": applicationOwner,
                "year": selectedYear.applicationOwner
            }
        }
        apiHandler(body, "owner", applicationOwner);
    }

    const handleClickCostCode = (event) => {
        setPreview(false);
        setLoaded(true);
        setSummaryRequest(true);
        setErrorBoundary(false);
        setState(1);
        setError("");
        const body = {
            account: {
                "query_type": "WUUBA",
                "ad_hoc": "false",
                "account_id": masterAccountId.toString(),
                "cost_code": costCode,
                "year": selectedYear.costCode
            },
            serviceReport: {
                "query_type": "WUUBS",
                "ad_hoc": "false",
                "account_id": masterAccountId.toString(),
                "cost_code": costCode,
                "year": selectedYear.costCode
            },
            serviceAccount: {
                "query_type": "WUUBAS",
                "ad_hoc": "false",
                "account_id": masterAccountId.toString(),
                "cost_code": costCode,
                "year": selectedYear.costCode
            },
            applicationName: {
                "query_type": "WUUBAN",
                "ad_hoc": "false",
                "account_id": masterAccountId.toString(),
                "cost_code": costCode,
                "year": selectedYear.costCode
            },
            usageSummary: {
                "query_type": "WUUS",
                "ad_hoc": "false",
                "account_id": masterAccountId.toString(),
                "cost_code": costCode,
                "year": selectedYear.costCode
            }
        }
        apiHandler(body, "costCode", costCode);
    }

    const handleClickAccount = (event) => {
        setPreview(false);
        setLoaded(true);
        setSummaryRequest(true);
        setErrorBoundary(false);
        setState(1);
        setError("");
        const body = {
            account: {
                "query_type": "WUUBA",
                "ad_hoc": "false",
                "account_id": masterAccountId.toString(),
                "account_param": accountParam,
                "year": selectedYear.account
            },
            serviceReport: {
                "query_type": "WUUBS",
                "ad_hoc": "false",
                "account_id": masterAccountId.toString(),
                "account_param": accountParam,
                "year": selectedYear.account
            },
            serviceAccount: {
                "query_type": "WUUBAS",
                "ad_hoc": "false",
                "account_id": masterAccountId.toString(),
                "account_param": accountParam,
                "year": selectedYear.account
            },
            applicationName: {
                "query_type": "WUUBAN",
                "ad_hoc": "false",
                "account_id": masterAccountId.toString(),
                "account_param": accountParam,
                "year": selectedYear.account
            },
            usageSummary: {
                "query_type": "WUUS",
                "ad_hoc": "false",
                "account_id": masterAccountId.toString(),
                "account_param": accountParam,
                "year": selectedYear.account
            }
        }
        apiHandler(body, "account", accountParam);
    }

    const apiHandler = (body, dataType, typeValue) => {
        let requestName = "";
        let code = "";
        let reportName = ""
        switch (dataType) {
            case "name":
                requestName = "Application Name";
                code = "WUAAYTD";
                reportName = "YTD cost by Application Name";
                break;
            case "owner":
                requestName = "Application Owner";
                code = "WUAAOYTD";
                reportName = "YTD cost by Application Owner";
                break;
            case "costCode":
                requestName = "Cost Center";
                code = "WUCR";
                reportName = "YTD cost by Cost Center";
                break;
            case "account":
                requestName = "Account";
                code = "WUCR";
                reportName = "YTD cost by Account";
                break;
            default:
                break;
        }

        let summaryTemp = {
            dataCategory: dataType,
            dataValue: typeValue,
            name: requestName,
            reportName: reportName,
            keyId: code
        }

        /*service.masterBillingReport().generateReport(body.account)
            .then(resAccount => {
                setState(2)
                service.masterBillingReport().generateReport(body.serviceReport)
                    .then(resService => {
                        setState(3)
                        service.masterBillingReport().generateReport(body.serviceAccount)
                            .then(resAccountService => {
                                setState(4);
                                service.masterBillingReport().generateReport(body.usageSummary)
                                    .then(resSummary => {
                                        setState(5);
                                        if (dataType === "owner" || dataType === "costCode" || dataType === "account") {
                                            service.masterBillingReport().generateReport(body.applicationName)
                                                .then(resApplicationName => {
                                                    summaryTemp['applicationNameReport'] = axisShiftApplication(resApplicationName.data.report);
                                                    summaryTemp['accountData'] = resAccount.data.report;
                                                    summaryTemp['accountService'] = axisShiftEnvironment(resAccountService.data.report);
                                                    summaryTemp['monthlySpendAccount'] = accountSpendGraph(resAccount.data.report);
                                                    summaryTemp['serviceSpend'] = axisShift(resService.data.report);
                                                    summaryTemp['totalCost'] = totalSummary(resAccount.data.report);
                                                    summaryTemp['monthlyAccountWiseSpend'] = axisShiftMonthlyCostReport(resAccount.data.report);
                                                    summaryTemp['summaryView'] = axisShiftSummary(resSummary.data.report);
                                                    setSummaryData(summaryTemp);
                                                    setSummaryRequest(false);
                                                    setPreview(true);
                                                    setLoaded(false);
                                                })
                                                .catch(err => {
                                                    setErrorBoundary(true);
                                                    setError(err.message);
                                                    setLoaded(false);
                                                })
                                        } else if (dataType === "name") {
                                            summaryTemp['accountData'] = resAccount.data.report;
                                            summaryTemp['accountService'] = axisShiftEnvironment(resAccountService.data.report);
                                            summaryTemp['serviceSpend'] = axisShift(resService.data.report);
                                            summaryTemp['monthlySpendAccount'] = accountSpendGraph(resAccount.data.report)
                                            summaryTemp['totalCost'] = totalSummary(resAccount.data.report);
                                            summaryTemp['monthlyAccountWiseSpend'] = axisShiftMonthlyCostReport(resAccount.data.report);
                                            summaryTemp['summaryView'] = axisShiftSummary(resSummary.data.report);
                                            setSummaryData(summaryTemp);
                                            setSummaryRequest(false);
                                            setPreview(true);
                                            setLoaded(false);
                                        }

                                    })
                                    .catch(err => {
                                        setErrorBoundary(true);
                                        setError(err.message);
                                        setLoaded(false);
                                    })
                            })
                            .catch(err => {
                                setErrorBoundary(true);
                                setError(err.message);
                                setLoaded(false);
                            })
                    })
                    .catch(err => {
                        setErrorBoundary(true);
                        setError(err.message);
                        setLoaded(false);
                    })
            })
            .catch(err => {
                setErrorBoundary(true);
                setError(err.message);
                setLoaded(false);
            })*/

        function sleep(milliseconds) {
            const date = Date.now();
            let currentDate = null;
            do {
                currentDate = Date.now();
            } while (currentDate - date < milliseconds);
        }

        service.general().getRegionList(body.account)
            .then(resAccount => {
                setState(2)
                sleep(1000)
                service.general().getRegionList(body.serviceReport)
                    .then(resService => {
                        setState(3)
                        sleep(1000)
                        service.general().getRegionList(body.serviceAccount)
                            .then(resAccountService => {
                                setState(4);
                                sleep(1000)
                                service.general().getRegionList(body.usageSummary)
                                    .then(resSummary => {
                                        setState(5);
                                        sleep(1000)
                                        if (dataType === "owner" || dataType === "costCode" || dataType === "account") {
                                            service.general().getRegionList(body.applicationName)
                                                .then(resApplicationName => {
                                                    /*summaryTemp['applicationNameReport'] = axisShiftApplication(resApplicationName.data.report);
                                                    summaryTemp['accountData'] = resAccount.data.report;
                                                    summaryTemp['accountService'] = axisShiftEnvironment(resAccountService.data.report);
                                                    summaryTemp['monthlySpendAccount'] = accountSpendGraph(resAccount.data.report);
                                                    summaryTemp['serviceSpend'] = axisShift(resService.data.report);
                                                    summaryTemp['totalCost'] = totalSummary(resAccount.data.report);
                                                    summaryTemp['monthlyAccountWiseSpend'] = axisShiftMonthlyCostReport(resAccount.data.report);
                                                    summaryTemp['summaryView'] = axisShiftSummary(resSummary.data.report);*/

                                                    summaryTemp['applicationNameReport'] = axisShiftApplication(resApplicationName.data.report);
                                                    summaryTemp['accountData'] = bu1.report;
                                                    summaryTemp['accountService'] = axisShiftEnvironment(bu3.report);
                                                    summaryTemp['monthlySpendAccount'] = accountSpendGraph(bu1.report);
                                                    summaryTemp['serviceSpend'] = axisShift(bu1.report);
                                                    summaryTemp['totalCost'] = totalSummary(bu1.report);
                                                    summaryTemp['monthlyAccountWiseSpend'] = axisShiftMonthlyCostReport(bu1.report);
                                                    summaryTemp['summaryView'] = axisShiftSummary(bu4.report);
                                                    setSummaryData(summaryTemp);
                                                    setSummaryRequest(false);
                                                    setPreview(true);
                                                    setLoaded(false);
                                                })
                                                .catch(err => {
                                                    setErrorBoundary(true);
                                                    setError(err.message);
                                                    setLoaded(false);
                                                })
                                        } else if (dataType === "name") {
                                            summaryTemp['accountData'] = bu1.report;
                                            summaryTemp['accountService'] = axisShiftEnvironment(bu3.report);
                                            summaryTemp['serviceSpend'] = axisShift(bu2.report);
                                            summaryTemp['monthlySpendAccount'] = accountSpendGraph(bu1.report)
                                            summaryTemp['totalCost'] = totalSummary(bu1.report);
                                            summaryTemp['monthlyAccountWiseSpend'] = axisShiftMonthlyCostReport(bu1.report);
                                            summaryTemp['summaryView'] = axisShiftSummary(bu4.report);
                                            setSummaryData(summaryTemp);
                                            setSummaryRequest(false);
                                            setPreview(true);
                                            setLoaded(false);
                                        }

                                    })
                                    .catch(err => {
                                        setErrorBoundary(true);
                                        setError(err.message);
                                        setLoaded(false);
                                    })
                            })
                            .catch(err => {
                                setErrorBoundary(true);
                                setError(err.message);
                                setLoaded(false);
                            })
                    })
                    .catch(err => {
                        setErrorBoundary(true);
                        setError(err.message);
                        setLoaded(false);
                    })
            })
            .catch(err => {
                setErrorBoundary(true);
                setError(err.message);
                setLoaded(false);
            })
    }

    function LoadingSnackBar(message) {
        return (
            <Snackbar
                message={message.message}
                open={loading}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                action={
                    <React.Fragment>
                        <CircularProgress style={{marginRight: 10}} size={24} color="primary"/>
                    </React.Fragment>
                }
            />
        )
    }

    const handleApplicationButtonClick = (event) => {
        setApplicationName(event);
    }

    const handleApplicationOwnerButtonClick = (event) => {
        setApplicationOwner(event);
    }

    const handleCostCenterButtonClick = (event) => {
        setCostCode(event)
    }

    const handleAccountButtonClick = (event) => {
        setAccountParam(event);
    }

    const handleClickResource = (event) => {
        setResourceIdOpen(true)
    }

    return (
        <section id="business_unit_reports" style={{width: "90%"}} className="insight-reports">
            <LoadingSnackBar message={"Generating report..."}/>
            <ResourceIdBasedReport open={resourceIdOpen}
                                   onChange={(e) => setResourceIdOpen(e)}
                                   resourceId={resourceId}
                                   resourceCondition={resourceIdCondition}/>
            <PageTitle
                title={"Showback Reports"}
                subTitle={"Year to Date showback reports"}/>
            <Box className="insight-report-wrapper">
                <InsightReportGenerationCard
                    headingComponentAvailable={true}
                    headingComponent={<Year year={selectedYear.applicationName}
                                            onChange={(e) => handleYearChange(e, 'applicationName')}/>}
                    onClick={handleClick}
                    buttonId={"WUAAYTD"}
                    shortText={"Application Name tag based year to date report"}
                    reportName={"Application Name"}>
                    <Autocomplete
                        freeSolo
                        id={"application_name"}
                        options={applicationData}
                        getOptionLabel={(application) => application.ApplicationName ? application.ApplicationName : 'no name'}
                        fullWidth
                        defaultValue={applicationData[3]}
                        onChange={(event, value) => {
                            value ? handleApplicationButtonClick(value.ApplicationName) : setApplicationName("none")
                        }}
                        size={"small"}
                        renderInput={(params) => <TextField {...params}
                                                            onChange={(e) => setApplicationName(e.target.value)}
                                                            label="Application Name"
                                                            variant="outlined"/>}
                    />
                </InsightReportGenerationCard>

                <InsightReportGenerationCard
                    headingComponentAvailable={true}
                    headingComponent={<Year year={selectedYear.applicationOwner}
                                            onChange={(e) => handleYearChange(e, 'applicationOwner')}/>}
                    onClick={handleClickOwner}
                    buttonId={"WUAAOYTD"}
                    shortText={"Application Owner tag based year to date report"}
                    reportName={"Application Owner"}>
                    <Autocomplete
                        freeSolo
                        id={"application_owner"}
                        options={applicationOwnerData}
                        getOptionLabel={(application) => application.ApplicationOwner ? application.ApplicationOwner : 'no data'}
                        fullWidth
                        defaultValue={applicationData[3]}
                        onChange={(event, value) => {
                            value ? handleApplicationOwnerButtonClick(value.ApplicationOwner) : setApplicationOwner("none")
                        }}
                        size={"small"}
                        renderInput={(params) => <TextField {...params} label="Application Owner"
                                                            onChange={(e) => setApplicationOwner(e.target.value)}
                                                            variant="outlined"/>}
                    />
                </InsightReportGenerationCard>

                <InsightReportGenerationCard
                    headingComponentAvailable={true}
                    headingComponent={<Year year={selectedYear.costCode}
                                            onChange={(e) => handleYearChange(e, 'costCode')}/>}
                    onClick={handleClickCostCode}
                    buttonId={"WUCR"}
                    shortText={"Cost Center tag based year to date report"}
                    reportName={"Cost Center"}>
                    <CostCode
                        freeSolo={true}
                        defaultValue={"1200"}
                        error={false}
                        onChange={(e) => handleCostCenterButtonClick(e.costCode)}/>
                </InsightReportGenerationCard>
                <InsightReportGenerationCard
                    headingComponentAvailable={true}
                    headingComponent={<Year year={selectedYear.account}
                                            onChange={(e) => handleYearChange(e, 'account')}/>}
                    onClick={handleClickAccount}
                    buttonId={"WUAR"}
                    shortText={"Analyse year to date spend for different Accounts"}
                    reportName={"Account"}>
                    <div style={{paddingTop: 12}}>
                        <Accounts
                            freeSolo={true}
                            defaultValue={""}
                            error={false}
                            onChange={(e) => handleAccountButtonClick(e.accountId)}/>
                    </div>
                </InsightReportGenerationCard>
                <InsightReportGenerationCard
                    onClick={handleClickResource}
                    buttonId={"WURID"}
                    headingComponentAvailable={true}
                    headingComponent={<SearchCondition value={resourceIdCondition}
                                                       onChange={(e) => setResourceIdCondition(e)}/>}
                    shortText={"Resource Id based YTD report"}
                    buttonDisabled={resourceId.length < 1}
                    reportName={"Resource Id"}>
                    <InputBox value={resourceId} setValue={setResourceId}/>
                </InsightReportGenerationCard>
                <ShowbackReport/>
            </Box>
            {
                preview && !errorBoundary &&
                <ReportSummary summary={summaryData}/>
            }

            {
                loaded &&
                <div style={{padding: 20}}>
                    <PaperProgress>
                        {
                            summaryRequest &&
                            <div style={{paddingLeft: 20}}>
                                Loading {state}/5
                            </div>
                        }

                    </PaperProgress>
                </div>
            }

            {
                errorBoundary &&
                <AlertWu severity="error" message={"Something went wrong... Error type: " + error}/>
            }
        </section>
    )
}


/**
 *
 * @param {object} props - input props
 * @param {JSX.Element} props.children - children JSX elements
 * @param {string} props.reportName - report name
 * @param {string} props.id - action button id
 * @param {string} props.onClick - click method
 * @param {string} props.shortText - short text/ description of report
 * @return {JSX.Element} -
 * @constructor
 */
export function InsightReportGenerationCard(props) {
    const {
        children,
        reportName,
        buttonId,
        headingComponentAvailable = false,
        headingComponent,
        buttonDisabled = false,
        parentStyle = {},
        shortText = "Analyse spend with multiple options"
    } = {...props}

    return (
        <Card className="custom-wu-report-card" variant={"outlined"} style={parentStyle}>
            <CardContent className="custom-wu-report-card-content">
                <Typography variant={"subtitle1"} color="textSecondary"
                            gutterBottom>
                    YTD Cost by
                </Typography>
                <Box display={'flex'} justifyContent={'space-between'} mr={0.4}>
                    <Typography variant="h5" component="div">
                        {reportName}
                    </Typography>
                    {headingComponentAvailable && headingComponent}
                </Box>
                <Typography className="custom-wu-report-card-pos" color="textSecondary">
                    {shortText}
                </Typography>
                <div className="custom-wu-report-card-pos">
                    {children}
                </div>
            </CardContent>
            <CardActions style={{paddingLeft: 10}}>
                <Button
                    disabled={buttonDisabled}
                    size="medium"
                    id={buttonId}
                    onClick={(e) => {
                        props.onClick(e)
                    }}>
                    Generate Report
                </Button>
            </CardActions>
        </Card>
    )
}


function SearchCondition(props) {
    const {value = 'EQUALS'} = {...props}
    return (
        <FormControl style={{marginBottom: -8}}>
            <WuSelect
                variant={'outlined'}
                placeholder={'Filter Type'}
                margin={'dense'}
                color={'secondary'}
                value={value}
                label="Filter Type"
                onChange={(e) => props.onChange(e.target.value)}
            >
                <MenuItem value={'CONTAINS'}>Contains</MenuItem>
                <MenuItem value={'EQUALS'}>Equals</MenuItem>
            </WuSelect>
        </FormControl>
    )
}

function Year({year, onChange}) {
    const currentYear = moment().format('YYYY')
    const lastYear = moment().subtract('1', 'year').format('YYYY')


    return (
        <FormControl style={{marginBottom: -8}}>
            <WuSelect
                variant={'outlined'}
                placeholder={'Filter Type'}
                margin={'dense'}
                color={'secondary'}
                value={year}
                label="Filter Type"
                onChange={(e) => onChange(e.target.value)}
            >
                <MenuItem value={currentYear}>{currentYear}</MenuItem>
                <MenuItem value={lastYear}>{lastYear}</MenuItem>
            </WuSelect>
        </FormControl>
    )
}