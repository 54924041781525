import React from 'react';
import Box from "@material-ui/core/Box";
import WuSummaryCard from "../../General/WuSummaryCard";

export default function Summary() {
    return (
        <Box display={'flex'} className={'gap'} mt={2} flexDirection={'column'}>
           {/* <WuSummaryCard
                title={'Resources Provisioned/Average'}
                value={'120/8⚡'}
                description={'Total Number resources provisioned/Average per day'}
                helpText={'Total number of resources provisioned during the selected time frame and the average number of provisions per day'}/>
            <WuSummaryCard
                title={'Total Cost/Average '}
                value={'$120/$12⚡'}
                description={'Total Cost Impact/ Average Cost Impact per day'}
                helpText={'total cost impact and the average cost impact per day'}/>*/}
        </Box>
    )
}