import React, {useEffect, useState} from 'react';
import service from "../../../../../service/service";
import {AlertWu} from "../../../../AlertWu/AlertWu";
import {PaperProgress} from "../../../../ProgressCircle/ProgressCircle";
import DataGrid from "../../../../General/DataGrid/DataGrid"
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select';
//dummy
import testData from '../../../../../assets/finthesys/reservation-recommendation.json';

const columns = [
    {
        field: "PaymentOption",
        headerName: 'Payment Option',
        resizable: true,
        sortable: true,
        suppressAutoSize: true,
    },
    {
        field: "TotalEstimatedMonthlySavingsAmount",
        headerName: 'Monthly Savings Amount',
        resizable: true,
        sortable: true,
        suppressAutoSize: true,
    },
    {
        field: "TotalEstimatedMonthlySavingsPercentage",
        headerName: 'Estimated Monthly Savings Percentage',
        resizable: true,
        sortable: true,
        suppressAutoSize: true,
    },
    {
        field: "NumberOfRecommendations",
        headerName: 'Number of recommendations',
        resizable: true,
        sortable: true,
        suppressAutoSize: true,
    }]


export default function ReservationRecommendation() {
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [errorBoundary, setErrorBoundary] = useState(false);
    const [error, setError] = useState("");
    const [ready, setReady] = useState(false);

    const [recommendations, setRecommendations] = useState([]);
    const [loopBack, setLoopBack] = useState("SEVEN_DAYS");
    const [offeringClass, setOfferingClass] = useState("STANDARD");
    const [term, setTerm] = useState("ONE_YEAR");

    function dataParser(data) {
        let payer = []
        let linked = []
        data.map((data) => {
            if (data.AccountScope === "PAYER") {
                data['NumberOfRecommendations'] = data.recommentationDetails.length
                payer.push(data)
            } else if (data.AccountScope === "LINKED") {
                data['NumberOfRecommendations'] = data.recommentationDetails.length
                linked.push(data)
            }
        })
        return ({payer: payer, linked: linked})
    }


    useEffect(() => {
        setLoaded(true)
        const data = {
            "account_id": "477419074820",
            "LookbackPeriodInDays": loopBack,
            "TermInYears": term,
            "OfferingClass": offeringClass
        }


        /*service.insights().getReservationRecommendations(data)
            .then(res => {
                if (res.data.code === 200) {
                    setRecommendations(dataParser(res.data.data))
                    setLoaded(false);
                    setReady(true);
                }
            })
            .catch(err => {
                setErrorBoundary(true);
                setLoaded(false);
                setError(err.message);

            })*/
        setRecommendations(dataParser(testData.data))
        setLoaded(false);
        setReady(true);
    }, [loading, offeringClass, term, loopBack])


    function SelectionBar() {
        return (
            <div style={{display: "flex", width: "500px", justifyContent: "space-between", marginBottom: 40}}>
                <div>
                    <p className="selection-items">Loopback period</p>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={loopBack}
                        margin={"dense"}
                        style={{width: 140}}
                        onChange={(event) => setLoopBack(event.target.value)}
                        label="Loopback"
                        variant={"outlined"}
                    >
                        <MenuItem value={'SEVEN_DAYS'}>7</MenuItem>
                        <MenuItem value={'THIRTY_DAYS'}>30</MenuItem>
                        <MenuItem value={'SIXTY_DAYS'}>60</MenuItem>
                    </Select>
                </div>
                <div>
                    <p className="selection-items">
                        Term
                    </p>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={term}
                        margin={"dense"}
                        style={{width: 140}}
                        onChange={(event) => setTerm(event.target.value)}
                        label="Loopback"
                        variant={"outlined"}
                    >
                        <MenuItem value={'ONE_YEAR'}> 1 Year</MenuItem>
                        <MenuItem value={'THREE_YEARS'}>3 Year</MenuItem>
                    </Select>
                </div>
                <div>
                    <p className="selection-items">
                        Offering Class
                    </p>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={offeringClass}
                        margin={"dense"}
                        style={{width: 140}}
                        onChange={(event) => setOfferingClass(event.target.value)}
                        label="Offering Class"
                        variant={"outlined"}
                    >
                        <MenuItem value={'STANDARD'}>STANDARD</MenuItem>
                        <MenuItem value={'CONVERTIBLE'}>CONVERTIBLE</MenuItem>
                    </Select>
                </div>
            </div>
        )
    }

    return (
        <div style={{marginBottom: 40}}>
            <SelectionBar/>
            {!errorBoundary && loaded ? <PaperProgress/> :
                ready &&
                <div className="savings-plan-4">
                    <p className="savingsplan-recommendations-summary">
                        Payer Account Type
                    </p>
                    <div className="savingsplan-recommendations-wrapper">
                        <DataGrid
                            fixedColumn={columns}
                            fileName={`reservation-recommendation-payer`}
                            gridStyle={{height: (((recommendations.payer).length + 1) * 50) + "px"}}
                            gridData={recommendations.payer}
                            gridClass="recommendations-grid"/>
                    </div>

                    <p className="savingsplan-recommendations-summary">
                        Linked Account Type
                    </p>
                    <div className="savingsplan-recommendations-wrapper">
                        <DataGrid
                            fixedColumn={columns}
                            fileName={`reservation-recommendation-linked`}
                            gridStyle={{height: (((recommendations.linked).length + 1) * 50) + "px"}}
                            gridData={recommendations.linked}
                            gridClass="recommendations-grid"/>
                    </div>

                </div>
            }

            {
                errorBoundary &&
                <AlertWu severity="error" message={"Something went wrong... Error type: " + error}/>
            }
        </div>
    );
}