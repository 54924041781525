import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import service from "../../service/service";

export default function Delete(props) {

    const {dialogOpen, data} = {...props}
    const budgetName = data.applicationName ?
        `${data.applicationName}_${data.environment}_${data.region}`
        : data.accountId;
    const accountId = data.accountId;
    const budgetId = data.BudgetId;


    const handleClose = () => {
        props.onChange(false);
    };

    const handleDelete = (event) => {
        let params = {
            id: budgetId,
            budget_name: budgetName,
            budget_account: accountId
        }
        service.budget().deleteBudgetById(params)
            .then((ref) => {
                handleClose();
            })
            .catch(err => (
                console.log(err)
            ))

    }

    return (
        <div>
            <Dialog
                open={dialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="delete-budget">{"Sure to delete the Budget?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This is permanent. The following budget will be deleted <strong>{budgetName}</strong>. Are you
                        sure to delete?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDelete} color="secondary">
                        Delete
                    </Button>
                    <Button onClick={handleClose} color="secondary" autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}