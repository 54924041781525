import React from 'react';
import {InputBase, Select, styled} from "@material-ui/core";

const CustomSelectInput = styled(InputBase)(({theme}) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),

        '&:focus': {
            borderRadius: 4,
            borderColor: '#000',
        },
    },
}));

export default function WuSelect(props) {
    return (
        <Select
            {...props}
            input={<CustomSelectInput/>}
        />
    )
}