import React, {useEffect, useState} from 'react';
import Graph from "./Graph";
import {Paper, Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import ServiceResourceWiseSpend from "./ServiceResourceWiseSpend";
import {accountLengthAdjust} from "../../../../helper/generalFunctions";
import service from "../../../../service/service";
import {PaperProgress} from "../../../ProgressCircle/ProgressCircle";
import {useOrganization} from "../../../../hooks/useOrganization";
import {accountTrendApplication, accountTrendResource} from "../../../../assets/finthesys/accountTrend";

export default function ResourceSpend({data}) {
    const {organization: masterAccountId} = useOrganization()
    const [graphData, setGraphData] = useState([])
    const [ready, setReady] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedData, setSelectedData] = useState({});

    const fetchData = async () => {
        try {
            setReady(false)
            setLoading(true)
            const params = {
                "master_account_id": masterAccountId,
                "account_id": accountLengthAdjust(data.accountId).toString(),
                "date": data.date,
                "report_type": "resource"
            }
            const {data: {graphData}} = await service.accountTrend().getAccountTrendDaily(params);
            setGraphData(graphData)
            setReady(true)
        } catch (e) {

        } finally {
            setLoading(false)
        }
    }

    const fetchDummyData = async () => {
        try {
            setLoading(true)
            setReady(false)
            const {graphData} = accountTrendResource
            setGraphData(graphData)
            setReady(true)

        } catch (e) {

        } finally {
            setLoading(false)
        }
    }

    const handleDialogOpen = (e) => {
        setSelectedData({service: e, accountId: accountLengthAdjust(data.accountId), date: data.date})
        setDialogOpen(true);
    }

    useEffect(() => {
        if (masterAccountId !== 'no org') {
            fetchDummyData().then()
        }
    }, [])
    return (
        <Paper variant={'outlined'}>
            <Box mt={2} ml={2} mr={2}>
                {dialogOpen && <ServiceResourceWiseSpend open={dialogOpen} onChange={(e) => setDialogOpen(false)}
                                                         data={selectedData}/>}
                <Typography variant={'h6'}>Service Spend</Typography>
                <Box mt={-1} mb={2}>
                    {
                        ready && <Graph graphData={graphData} onClick={handleDialogOpen}/>
                    }
                    {
                        loading && <Box mt={2} mb={2}><PaperProgress/></Box>
                    }
                </Box>
            </Box>
        </Paper>
    )
}