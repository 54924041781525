import React from "react";
import {Typography} from "@material-ui/core";


/**
 *
 * @param {number} progress
 * @param {string} bodyData
 * @param {string} color - color in HEX
 * @returns {JSX.Element}
 * @constructor
 */
export default function PowerScheduleUsageSummaryGraph({progress, bodyData, color}) {
    return (
        <div className="ps-graph-wrapper">
            <div className="ps-progress"
                 style={{width: `${progress > 100 ? 100 : progress}%`, background: color}}>
            </div>
            <Typography variant="subtitle1" style={{fontWeight: 600, letterSpacing: 0.5, zIndex: 10}}>
                {bodyData}
            </Typography>
        </div>
    )
}