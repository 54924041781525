import React, {useEffect, useRef, useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {Accounts, Region} from "../../General/WuAutoComplete";
import {Input, InputAdornment, InputLabel, OutlinedInput, TextField} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import {Add, DeleteOutlined} from "@material-ui/icons";
import service from "../../../service/service";
import Autocomplete from "@material-ui/lab/Autocomplete";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function Create(props) {
    const initialState = [{}];

    function reducer(state, action) {
        switch (action.type) {
            case 'add':
                return [...state, ...action.payload]
            case 'remove':
                let newState = [...state]
                if (newState.length > 1) {
                    newState.splice(action.payload, 1)
                } else {
                    console.log("minimum 1 budget is required")
                }
                return newState
            default:
                throw new Error();
        }
    }

    const {dialogOpen, data} = {...props}
    const [open, setOpen] = React.useState(false);
    const [state, dispatch] = React.useReducer(reducer, initialState);

    useEffect(() => {
        setOpen(dialogOpen)
    }, [props.dialogOpen])

    const handleClose = () => {
        props.onChange(false);
        //this.setState({inputVal: {}});
        //console.log(budgetInformation)
        setOpen(false);

    };

    const handleBudgetChange = (event, index) => {
        state[index] = event
    }

    const createBudgets = (state) => {

        let records = [];
        state.forEach(function (value, i) {
            if (value.amount) {
                var Bamount = value.amount;
            } else if (value.budgetAmount) {
                var Bamount = value.budgetAmount;
            }

            if (value.accountId) {
                var Baccount = value.accountId;
            } else if (value.account) {
                var Baccount = value.account;
            }
            let record = {};
            record['Amount'] = Bamount;
            record['BudgetNotificationSubscribers'] = value.notificationSubscribers;
            record['Region'] = value.region;
            record['budgetTypeId'] = 2;
            record['type'] = 2;
            record['accountId'] = Baccount;
            record['period'] = value.BudgetPeriod;
            records.push(record);

        });
        const param = {
            records
        }
        service.budget().createBudget(param)
            .then((ref) => {
                //dispatch([{}])
                handleClose();
            })
            .catch(err => (
                console.log(err)
            ))
    }

    return (
        <div>
            <Dialog fullWidth maxWidth={"lg"} aria-labelledby="customized-dialog-title"
                    open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Create New Budget(s)
                </DialogTitle>
                <DialogContent dividers>
                    {
                        state.length > 0 ?
                            state.map((budget, index) => {
                                return (
                                    <div key={index} style={{display: "flex", alignItems: "center"}}>
                                        <AccountBudget
                                            applicationName={budget.applicationName}
                                            environment={budget.environment}
                                            region={budget.region}
                                            notificationSubscribers={budget.notificationSubscribers}
                                            account={budget.account}
                                            amount={budget.amount}
                                            BudgetPeriod={budget.BudgetPeriod}
                                            onChange={(e) => handleBudgetChange(e, index)}/>
                                        <IconButton style={{marginLeft: 10}}
                                                    disabled={state.length <= 1}
                                                    onClick={() => dispatch({type: 'remove', payload: index})}
                                                    aria-label="delete" margin={{margin: 2}}>
                                            <DeleteOutlined/>
                                        </IconButton>
                                        <IconButton aria-label="add"
                                                    onClick={() => dispatch({type: 'add', payload: [{}]})}
                                                    margin={{margin: 1}}>
                                            <Add/>
                                        </IconButton>
                                    </div>
                                )
                            }) : "no budgets"
                    }

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => createBudgets(state)} autoFocus color="secondary">
                        Create budget(s)
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

function AccountBudget(props) {
    const {region, notificationSubscribers, amount, account, BudgetId, update, BudgetPeriod} = {...props}

    const [error, setError] = useState({
        region: false,
        notificationSubscribers: false,
        account: false,
        amount: false,
        BudgetPeriod: false,
        masterError: true
    });

    const [budgetInformation, setBudgetInformation] = useState({
        region: region,
        notificationSubscribers: notificationSubscribers,
        account: account,
        amount: amount,
        BudgetPeriod: BudgetPeriod,
        BudgetId: BudgetId
    })

    const handleChange = (event, field) => {
        let newValue = {...budgetInformation}
        let newError = {...error}
        let errorCount = 0;
        if (event !== undefined) {
            switch (field) {
                case 'account':
                    newValue['account'] = event
                    break;
                case 'region':
                    newValue['region'] = event
                    break;
                case 'notificationSubscribers':
                    newValue['notificationSubscribers'] = event
                    break;
                case 'amount':
                    newValue['amount'] = event
                    break;
                case 'BudgetPeriod':
                    newValue['BudgetPeriod'] = event
                    break;
                default:
                    break
            }
            newError['account'] = checkErrors(newValue.account);
            newError['region'] = checkErrors(newValue.region);
            newError['notificationSubscribers'] = checkErrors(newValue.notificationSubscribers);
            newError['amount'] = checkErrors(newValue.amount);
            newError['BudgetPeriod'] = checkErrors(newValue.BudgetPeriod);

            Object.keys(newError).map((keyName) => {
                if (newError[keyName] === true) {
                    errorCount += 1
                }
            })
            if (errorCount === 1) {
                //newError['masterError'] = false;
                props.onChange(newValue);
            }
            setError(newError);
            setBudgetInformation(newValue);
        }

    }


    function checkErrors(fieldName) {
        return !fieldName;
    }

    const periods = ["ANNUALLY", "MONTHLY", "QUARTERLY", "DAILY"];
    return (
        <form className="d-f" noValidate={false} autoComplete="off">
            {!update &&

            <React.Fragment>

                <Region defaultValue=''
                        error={error.region}
                        width={300}
                        onChange={(e) => (handleChange(e.region, 'region'))}/>

                <Accounts defaultValue=''
                          error={error.account}
                          width={300}
                          masterAccount={477419074820}
                          onChange={(e) => (handleChange(e.accountId, 'account'))}/>

                <Autocomplete
                    id="application_name"
                    size={"small"}
                    fullWidth
                    options={periods}
                    onChange={(event, value) => handleChange(value, 'BudgetPeriod')}
                    getOptionLabel={
                        (periods) =>
                            periods ? periods : "no data"
                    }
                    style={{
                        marginTop: 10,
                        marginBottom: 6,
                        minWidth: 150,
                        maxWidth: 150,
                    }}
                    renderInput={(params) => <TextField {...params} error={error.period} label="Period"
                                                        variant="outlined"/>}
                />

                <TextField id="application-notification-subscribers"
                           fullWidth
                           multiline
                           style={{minWidth: 170}}
                           error={error.notificationSubscribers}
                           onChange={(e) => (handleChange(e.target.value, 'notificationSubscribers'))}
                           margin={"dense"}
                           label="Notification Subscribers" variant="outlined"/>

                <FormControl fullWidth style={{maxWidth: 120, minWidth: 120}} margin={"dense"} variant="outlined">
                    <InputLabel error={error.amount} htmlFor="application-budget-amount">Amount</InputLabel>
                    <OutlinedInput
                        error={error.amount}
                        type={"number"}
                        onChange={(e) => (handleChange(e.target.value, 'amount'))}
                        id="application-budget-amount"
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        labelWidth={60}
                    />
                </FormControl>
            </React.Fragment>}

            {update &&
            <React.Fragment>

                <TextField id="region"
                           fullWidth
                           multiline
                           value={budgetInformation.region}
                           style={{minWidth: 170}}
                           error={error.region}
                           disabled={true}
                           margin={"dense"}
                           label="Region" variant="outlined"/>


                <TextField id="account"
                           fullWidth
                           multiline
                           value={budgetInformation.account}
                           style={{minWidth: 170}}
                           error={error.account}
                           disabled={true}
                           margin={"dense"}
                           label="Account" variant="outlined"/>

                <TextField id="period"
                           fullWidth
                           multiline
                           value={budgetInformation.BudgetPeriod}
                           style={{minWidth: 170}}
                           error={error.period}
                           disabled={true}
                           margin={"dense"}
                           label="Period" variant="outlined"/>

                <TextField id="application-notification-subscribers"
                           fullWidth
                           multiline
                           value={budgetInformation.notificationSubscribers}
                           style={{minWidth: 170}}
                           error={error.notificationSubscribers}
                           onChange={(e) => (handleChange(e.target.value, 'notificationSubscribers'))}
                           margin={"dense"}
                           label="Notification Subscribers" variant="outlined"/>

                <FormControl fullWidth style={{maxWidth: 120, minWidth: 120}} margin={"dense"} variant="outlined">
                    <InputLabel error={error.amount} htmlFor="application-budget-amount">Amount</InputLabel>
                    <OutlinedInput
                        error={error.amount}
                        type={"number"}
                        value={budgetInformation.amount}
                        onChange={(e) => (handleChange(e.target.value, 'amount'))}
                        id="application-budget-amount"
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        labelWidth={60}
                    />
                </FormControl>

            </React.Fragment>
            }


        </form>
    )
}

export {AccountBudget}