import React from 'react';
import ReactDOM from 'react-dom';
import {App} from './App';
import reportWebVitals from './reportWebVitals';
import {createMuiTheme} from "@material-ui/core/styles";
import {ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#1ea433'
        },
        secondary: {
            main: '#282828'
        },
        warning: {
            main: '#BC211D'
        },
        error: {
            main: '#BC211D'
        },
        secondaryWarning: {
            main: '#ff6f00'
        }
    },/*
    typography: {
        fontFamily: ['"Century Gothic"', 'Century Gothic'].join(',')
    }*/
});


ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <App/>
    </MuiThemeProvider>
    ,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
