import DetailAccordion from './DetailAccordion'
import {Box, IconButton, Typography} from "@material-ui/core";
import {ExpandMore} from "@material-ui/icons";
import PageTitle from "../../General/PageTitle/PageTitle";
import {useEffect, useState} from "react";
import service from "../../../service/service";
import {apiErrorResponseJsonParser} from "../../../helper/generalFunctions";
import {useOrganization} from "../../../hooks/useOrganization";
import WuLoading from "../../General/WuLoading";
import WuAlert from "../../General/WuAlert";
import Filter from "../Filter";
import moment from "moment";
import {ecsListAccountWise} from "../../../assets/finthesys/ecs";

const style = {
    freeWidth: {
        marginTop: 10,
        marginBottom: 20
    }
}

export default function ECSMain() {
    const {organization: masterAccountId} = useOrganization()
    const [apiProgress, setApiProgress] = useState({loading: false, ready: true, error: false})
    const [ecsData, setEcsData] = useState([])

    const fetchData = async (filter) => {
        try {
            const filterValue = filter ? {clusterArn: filter} : null
            setApiProgress({loading: true, ready: false, error: false})
            const params = {
                masterAccountId: masterAccountId,
                filter: filterValue || null
            }
            const {data: {data}} = await service.ecs().listEcs(params)
            setEcsData(data)
            setApiProgress({loading: false, ready: true, error: false})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setApiProgress({loading: false, ready: false, error: error})
        }
    }

    const fetchDummyData = async (filter) => {
        try {
            const filterValue = filter ? {clusterArn: filter} : null
            setApiProgress({loading: true, ready: false, error: false})
            const params = {
                masterAccountId: masterAccountId,
                filter: filterValue || null
            }
            const {data} = ecsListAccountWise
            setEcsData(data)
            setApiProgress({loading: false, ready: true, error: false})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setApiProgress({loading: false, ready: false, error: error})
        }
    }

    const handleSearch=(filterValue)=>{
        fetchData(filterValue).then()
    }

    useEffect(() => {
        if (masterAccountId !== 'no org') {
            fetchDummyData().then()
        }
    }, [masterAccountId])

    return (
        <div className={'ecs-main-container'}>
            <div style={style.freeWidth}>
                <PageTitle
                    title={'ECS Monitoring'}
                    subTitle={'Clusters/Tasks/Services monitoring'}
                />
            </div>
            <div className={'ecs-accordion-container'}>
                <Filter loading={apiProgress.loading} onSearch={handleSearch}/>
                <Box m={apiProgress.loading ? 1 : 0}>
                    <WuLoading message={'ECS information loading. Please wait...'} open={apiProgress.loading}/>
                </Box>
                <WuAlert error={Boolean(apiProgress.error)} success={false} errorMessage={apiProgress.error}/>
                {apiProgress.ready && <AccordionTitle/>}
                {
                    apiProgress.ready && ecsData.map(e => <DetailAccordion {...e} key={e.accountId}/>)
                }
                {
                    apiProgress.ready && ecsData.length < 1 && <Box m={2}>no ecs information found</Box>
                }
            </div>
        </div>
    )
}

function AccordionTitle() {
    return (
        <div className={'ecs-accordion-title'}>
            <div className={'ecs-accordion-content'}>
                <Typography variant={'body2'}
                            style={{flexBasis: '30%'}}
                            color={'textSecondary'}>
                    Account
                </Typography>
                <Typography variant={'body2'}
                            style={{flexBasis: '12%'}}
                            color={'textSecondary'}>
                    No.Of Clusters
                </Typography>
                <Typography variant={'body2'}
                            style={{flexBasis: '12%'}}
                            color={'textSecondary'}>
                    No.Of Tasks
                </Typography>
                <Typography variant={'body2'}
                            style={{flexBasis: '12%'}}
                            color={'textSecondary'}>
                    No.Of Services
                </Typography>
                <Typography variant={'body2'}
                            style={{flexBasis: '10%'}}
                            color={'textPrimary'}>
                    {moment().subtract('1', 'months').format('MMM')} Price
                </Typography>
                <Typography variant={'body2'}
                            style={{flexBasis: '10%'}}
                            color={'textPrimary'}>
                    {moment().format('MMM')} Price
                </Typography>
                <Typography variant={'body2'}
                            style={{flexBasis: '10%'}}
                            color={'textPrimary'}>
                    YTD Spend
                </Typography>
            </div>
            <IconButton disabled={true} style={{height: 0, opacity: 0, overflow: 'hidden'}}>
                <ExpandMore/>
            </IconButton>
        </div>
    )
}