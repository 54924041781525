import React from 'react';
import {UnUsedRoute} from "../../../routes/MainRoute"

export default function UnUsed() {
    return (
        <div className="title-div-wrapper">
            <UnUsedRoute/>
        </div>
    )
}

