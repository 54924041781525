import React, {useContext, useEffect, useState} from 'react'
import DataGrid from "../../General/DataGrid";
import {Paper, Typography} from "@material-ui/core";
import {useOrganization} from "../../../hooks/useOrganization";
import service from "../../../service/service";
import {tableColumns} from "./tableColumns";
import WuStack from "../../General/WuStack";
import {
    TrendingDownOutlined,
    TrendingFlatOutlined,
    TrendingUpOutlined
} from "@material-ui/icons";
import TableLegend from "./TableLegend";
import {apiErrorResponseJsonParser} from "../../../helper/generalFunctions";
import WuLoading from "../../General/WuLoading";
import WuAlert from "../../General/WuAlert";
import DailySpendData from "../DailySpendData";
import {filterResults, summaryCalculator} from "./utls";
import FilterContext from "../FilterContext";


//dummy
import {accountTrend} from '../../../assets/finthesys/accountTrend'

export default function SpendVisualization({onSpendInsightChange}) {
    const {organization: masterAccountId} = useOrganization()
    const [graphData, setGraphData] = useState([])
    const [nonIntactData, setNonIntactData] = useState([])
    const [apiProgress, setApiProgress] = useState({ready: false, loading: false, error: false})
    const [additionalInformationOpen, setAdditionalInformationOpen] = useState(false)
    const [selectedData, setSelectedData] = useState({});
    const {filter} = useContext(FilterContext)

    const fetchData = async () => {
        try {
            setApiProgress({ready: false, loading: true, error: false})
            const {data: {data}} = await service.accountTrend().getAccountTrend({master_account_id: masterAccountId});
            setNonIntactData(data)
            onSpendInsightChange(summaryCalculator(data))
            setGraphData(data)
            setApiProgress({ready: true, loading: false, error: false})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setApiProgress({ready: true, loading: false, error: error})
        }
    }

    const fetchDummyData = async () => {
        try {
            setApiProgress({ready: false, loading: true, error: false})
            setTimeout(()=>{
                const {data} = accountTrend
                setNonIntactData(data)
                onSpendInsightChange(summaryCalculator(data))
                setGraphData(data)
                setApiProgress({ready: true, loading: false, error: false})
            }, 2000)
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setApiProgress({ready: true, loading: false, error: error})
        }
    }


    const frameworkComponents = {
        'costComponent': CostComponent
    };


    const handleDailyAdditionalInformation = (e) => {
        const accountId = e['data']['AccountId'] || ''
        const colName = e['colDef']['field'] || ''
        setSelectedData({
            accountId: accountId,
            date: colName
        })
        setAdditionalInformationOpen(true)
    }

    useEffect(() => {
        const arrayCopy = JSON.parse(JSON.stringify(nonIntactData)) // prevent original data being edited
        if (apiProgress.ready) {
            const newFilteredValue = filterResults(arrayCopy, filter)
            setGraphData(newFilteredValue)
        }
    }, [filter])

    useEffect(() => {
        if (masterAccountId !== 'no org') {
            fetchDummyData().then()
        }
    }, [masterAccountId])


    return (
        <Paper variant={'elevation'} elevation={0} style={{width: '100%'}}>
            <WuLoading message={'Loading account information. Please wait...'} open={apiProgress.loading}/>
            <WuAlert error={Boolean(apiProgress.error)} successMessage={''} errorMessage={apiProgress.error}
                     success={false}/>
            {additionalInformationOpen && <DailySpendData
                onChange={() => setAdditionalInformationOpen(false)}
                open={additionalInformationOpen}
                data={selectedData}/>}
            {apiProgress.ready && <Paper variant={'outlined'} style={{width: '100%'}}>
                <DataGrid gridData={graphData}
                          gridStyle={{height: 'calc(100vh - 470px)'}}
                          suppressDataControlPanel={false}
                          onCellClicked={handleDailyAdditionalInformation}
                          frameworkComponents={frameworkComponents}
                          fixedColumn={tableColumns}/>
            </Paper>}
            <TableLegend/>
        </Paper>
    )
}

function CostComponent(props) {
    const {valueFormatted, value} = {...props}
    const {changePercentage} = {...value}

    const boxProps = {
        style: {cursor: 'pointer'}
    }

    return (
        <WuStack alignItems={'center'}
                 boxProps={boxProps}
                 spacing={2}
                 justifyContent={'space-between'}>
            {
                changePercentage > 0 && <TrendingUpOutlined fontSize={'small'}/>
            }
            {
                changePercentage < 0 && <TrendingDownOutlined fontSize={'small'}/>
            }
            {
                changePercentage === 0 && <TrendingFlatOutlined fontSize={'small'}/>
            }
            <Typography variant={'body2'} component={'div'}>
                $ {value?.spend?.toLocaleString()}
                <Typography component={'span'} variant={'caption'}>
                    &nbsp;( {valueFormatted ? valueFormatted : value} )
                </Typography>
            </Typography>
        </WuStack>
    )
}
