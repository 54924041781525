import React, {useState} from "react";
import {ComputeOptimizerRoute} from "../../../routes/MainRoute";
import "./compute_optimizer.scss";
import ComputeOptimizerFilter from "./ComputeOptimizerFilter";
import ComputeOptimizerChangeContext from "../../../context/ComputeOptimizerChangeContext";
import {Fade} from "@material-ui/core";

export default function ComputeOptimizerHome() {

    const [filter, setFilter] = useState({})
    const [recommendationType, setRecommendationType] = useState("")
    const value = {recommendationType, setRecommendationType}


    const recommendationFilterChange = (event) => {
        setFilter(event)
    }

    return (
        <Fade in={true} timeout={1000}>
            <ComputeOptimizerChangeContext.Provider value={value}>
                <ComputeOptimizerFilter
                    onClick={(event) => recommendationFilterChange(event)}
                />
                <div style={{marginTop: 20}}>
                    <ComputeOptimizerRoute
                        filter={filter}/>
                </div>
            </ComputeOptimizerChangeContext.Provider>
        </Fade>
    )
}