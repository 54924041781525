import React, {useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";


export default function ContactInformation(props) {
    const {supportContact, applicationOwner, businessOwner, applicationArchitect} = props.contactData;
    const [contactData, setContactData] = useState(
        {
            supportContact: supportContact,
            applicationOwner: applicationOwner,
            businessOwner: businessOwner,
            applicationArchitect: applicationArchitect
        }
    )

    const handleChange = (event) => {
        setContactData({...contactData, [event.target.name]: event.target.value});
    };

    useEffect(() => {
        props.onChange(contactData)
    }, [contactData])

    return (
        <form noValidate={false} autoComplete="on">
            <Typography variant={"subtitle2"}>
                This information will be used for resource tags/labels and different reports
            </Typography>
            <div className={"d-f"}>
                <TextField type={"email"}
                           name={"supportContact"}
                           onChange={handleChange}
                           fullWidth
                           value={contactData.supportContact}
                           id="supportContact"
                           label="Support Contact" margin="dense"
                           variant="outlined"/>
                <TextField type={"email"}
                           name={"applicationOwner"}
                           value={contactData.applicationOwner}
                           onChange={handleChange}
                           fullWidth
                           id="applicationOwner"
                           label="Application Owner"
                           margin="dense"
                           variant="outlined"/>
            </div>
            <div className={"d-f"}>
                <TextField type={"email"}
                           name={"businessOwner"}
                           value={contactData.businessOwner}
                           onChange={handleChange}
                           fullWidth
                           id="businessOwner"
                           label="Business Owner"
                           margin="dense"
                           variant="outlined"/>
                <TextField type={"email"}
                           name={"applicationArchitect"}
                           value={contactData.applicationArchitect}
                           onChange={handleChange}
                           fullWidth
                           id="applicationArchitect"
                           label="Application Architect"
                           margin="dense"
                           variant="outlined"/>
            </div>
        </form>
    )
}