import React, {useEffect, useState} from "react";
import WuDialog from "../../General/WuDialog";
import {Box, Button} from "@material-ui/core";
import WuTextField from "../../General/WuTextFeild";
import moment from "moment";
import {AlertWu} from "../../AlertWu/AlertWu";
import service from "../../../service/service";
import {ProgressCircleCustomSize} from "../../ProgressCircle/ProgressCircle";
import {accountLengthAdjust, jsonParser} from "../../../helper/generalFunctions";
import TimeZoneSelector from "./ReUsable";


export default function PowerScheduleUpdate(props) {
    const {open, data} = {...props};
    const [startTime, setStartTime] = useState(null);
    const [stopTime, setStopTime] = useState(null);
    const [timeZone, setTimeZone] = useState(data.TimeZone);
    const [error, setError] = useState({error: false, message: undefined});
    const [ready, setReady] = useState(false);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        setStopTime((moment(data.StopTime, ["HH"]).format("HH:mm")));
        setStartTime((moment(data.StartTime, ["HH"]).format("HH:mm")));
        setTimeZone(data.TimeZone ? data.TimeZone.toUpperCase() : null);
    }, [data])

    useEffect(() => {
        setError({error: false, message: undefined})
        setReady(false);
        setLoading(false);
    }, [props])

    const handleUpdateRequest = async () => {
        try {
            setReady(false);
            setLoading(true);
            setError({error: false, message: undefined});
            const params = {
                resource_id: data.ResourceId,
                resource: data.ResourceType,
                account_id: accountLengthAdjust(data.AccountId),
                region: data.Region,
                start_time: startTime === 'Invalid date' ? "" : startTime,
                stop_time: stopTime === 'Invalid date' ? "" : stopTime,
                timezone: timeZone
            }
            await service.automations().updatePowerScheduleV2(params);
            props.onChange("update");
            setReady(true);
            setLoading(false);
        } catch (e) {
            const errorMessage = jsonParser(e.request.response).error;
            setLoading(false);
            setLoading(false);
            setError({error: true, message: errorMessage ? errorMessage : e.message});
        }
    }
    return (
        <WuDialog open={open} title={"Update Power scheduled Resource"} onChange={(e) => props.onChange("close")}>
            <Box m={3} mb={2}>
                <WuTextField label={"Start Time"}
                             type={"time"}
                             value={startTime}
                             inputProps={{step: "3600"}}
                             onChange={(e) => setStartTime(e.target.value)}/>

                <WuTextField label={"Stop Time"}
                             value={stopTime}
                             type={"time"}
                             step={3600}
                             onChange={(e) => {
                                 setStopTime(e.target.value)
                             }}/>
                <TimeZoneSelector
                    error={false}
                    value={timeZone}
                    onChange={(e) => {
                        setTimeZone(e)
                    }}
                />
                <Box mt={2} display={"flex"} className={"gap"} alignItems={"center"}>
                    <Button onClick={handleUpdateRequest}
                            variant={"contained"}
                            disabled={loading}
                            disableElevation
                            color={"primary"}>
                        Save changes
                    </Button>
                    <Button onClick={() => props.onClick(true)}
                            variant={"contained"}
                            disabled={loading}
                            disableElevation>
                        REMOVE POWER SCHEDULE
                    </Button>
                    {
                        loading &&
                        <Box ml={1}>
                            <ProgressCircleCustomSize size={16}/>
                        </Box>
                    }
                </Box>
                <Box mt={2}>
                    <AlertWu alertOpen={{state: ready}} severity={"success"} message={"New Item Added"}/>
                    <AlertWu alertOpen={{state: error.error}} severity={"error"} message={error.message}/>
                </Box>

            </Box>
        </WuDialog>
    )
}