import React, {useContext, useEffect, useRef, useState} from 'react';
import WuStack from "../../General/WuStack";
import WuTextField from "../../General/WuTextFeild";
import WuButton from "../../General/WuButton";
import {
    Badge,
    ButtonGroup,
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
} from "@material-ui/core";
import {ArrowDropDown} from "@material-ui/icons";
import FilterContext from "./FilterContext";


export default function Filter({onFilterChange}) {
    const minStyles = {maxWidth: 200}
    const minStyles2 = {maxWidth: 250}
    const [check, setCheck] = useState({})
    const [defaultVpcOnly, setDefaultVpcOnly] = useState(false);
    const {filter, setFilter} = useContext(FilterContext)

    const disabled = false


    const handleFilterChange = () => {
        const currentSyncStatus = {range_filter: filter?.range_filter || 'noSync'}

        const key = Object.keys(check)
        if (key.length < 1) {
            setFilter({...currentSyncStatus, default_vpc_only: defaultVpcOnly})
            return
        }
        if (!check[key[0]]) {
            setFilter({...currentSyncStatus, default_vpc_only: defaultVpcOnly})
            return
        }
        setFilter({...currentSyncStatus, key: key[0], value: check[key[0]], default_vpc_only: defaultVpcOnly})
    }

    return (
        <WuStack spacing={2} alignItems={'center'} boxProps={{flexWrap: 'wrap'}}>
            <WuTextField label={'Longest prefix match'}
                         placeholder={'Longest prefix match'}
                         style={minStyles}
                         type={'search'}
                         onChange={(e) => setCheck({longestPrefix: e.target.value})}
                         value={check?.longestPrefix ? check.longestPrefix : ''}/>
            <WuTextField label={'Zone/Region search'}
                         placeholder={'Zone/Region search'}
                         style={minStyles}
                         type={'search'}
                         onChange={(e) => setCheck({zone: e.target.value})}
                         value={check?.zone ? check.zone : ''}/>
            <WuTextField label={'Subnet Name/Id, CIDR Block'}
                         placeholder={'Subnet Name/Id, CIDR Block'}
                         onChange={(e) => setCheck({subnet: e.target.value})}
                         style={minStyles2}
                         type={'search'}
                         value={check?.subnet ? check.subnet : ''}/>
            <WuTextField label={'VPC Name/Id, CIDR Block'}
                         placeholder={'VPC Name/Id, CIDR Block'}
                         onChange={(e) => setCheck({vpc: e.target.value})}
                         style={minStyles2}
                         type={'search'}
                         value={check?.vpc ? check.vpc : ''}/>
            <ButtonAction handleFilterChange={handleFilterChange} disabled={disabled}
                          defaultVpcOnly={setDefaultVpcOnly}/>

        </WuStack>
    )
}


function ButtonAction({handleFilterChange, disabled, defaultVpcOnly}) {
    const options = ['ALL VPC', 'DEFAULT VPC ONLY']
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [selectedIndex, setSelectedIndex] = useState(0);


    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        defaultVpcOnly(selectedIndex === 1)
    }, [selectedIndex])

    return (
        <React.Fragment>
            <Badge color="primary" badgeContent=" " variant="dot" style={{marginTop: 8}}
                   invisible={selectedIndex === 0}>
                <ButtonGroup variant="contained" style={{marginRight: -4, marginTop: -4}} disableElevation
                             ref={anchorRef}>
                    <WuButton style={{height: 36}}
                              color={'secondary'}
                              onClick={handleFilterChange}
                              disabled={disabled}>
                        Search Resources
                    </WuButton>

                    <WuButton style={{height: 36}}
                              onClick={handleToggle}
                              color={'secondary'}
                              disabled={disabled}>
                        <ArrowDropDown/>
                    </WuButton>
                </ButtonGroup>
            </Badge>
            <Popper
                style={{zIndex: 400}}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper style={{zIndex: 5000, width: 200}}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu">
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={option}
                                            disabled={index === 2}
                                            selected={index === selectedIndex}
                                            onClick={(event) => handleMenuItemClick(event, index)}
                                        >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    )
}