import React, {useEffect, useState} from 'react'
import DateRange from "react-date-range/dist/components/DateRange";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import moment from "moment";
import Box from "@material-ui/core/Box";
import {Collapse, Divider, FormControlLabel, Radio, RadioGroup, Typography} from "@material-ui/core";
import {ArrowBackIosRounded, AvTimerOutlined} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
import WuSpacer from "../../../General/WuSpacer";

export default function DatePicker(props) {
    const [dateRangeOption, setDateRangeOption] = useState('currentMonth');
    const [granularity, setGranularity] = useState('monthly');
    const [state, setState] = useState([
        {
            startDate: new Date(moment().startOf('month')),
            endDate: new Date(),
            key: 'selection'
        }
    ]);


    const handleChange = (e) => {
        setState([e.selection])

    }

    useEffect(() => {
        props.onChange({dateRange: state, granularity: granularity})
    }, [state, granularity])


    useEffect(() => {
        const date = {startDate: moment().startOf('month'), endDate: moment()}
        const today = moment()
        if (dateRangeOption === 'currentMonth') {
            date.startDate = moment().startOf('month');
            date.endDate = today
        } else if (dateRangeOption === 'lastMonth') {
            date.startDate = moment().subtract(1, 'month').startOf('month');
            date.endDate = moment().subtract(1, 'month').endOf('month');
        } else if (dateRangeOption === 'last90Days') {
            date.startDate = moment().subtract(90, 'days').startOf('month');
            date.endDate = today
        } else if (dateRangeOption === 'last365Days') {
            date.startDate = moment().subtract(365, 'days').startOf('month');
            date.endDate = today
        }
        setState([{
            startDate: new Date(date.startDate),
            endDate: new Date(date.endDate),
            key: 'selection'
        }])
    }, [dateRangeOption])


    return (
        <Box>
            <Box display={'flex'} gridGap={8} mb={0.5} ml={-0.4}>
                <DateRangeRoundedIcon/>
                <Typography variant={'body1'} style={{fontWeight: 600}}>Date Range</Typography>
            </Box>
            <Collapse in={dateRangeOption !== 'custom'}>
                <Box mt={1}>
                    <PreDefinedSelectors value={dateRangeOption} onChange={(e) => setDateRangeOption(e)}/>
                </Box>
            </Collapse>
            <Collapse in={dateRangeOption === 'custom'}>
                <Box mt={2} display={'flex'} flexDirection={'column'} className={'custom-date-calender'}>
                    <Box display={'flex'} className={'gap'} alignItems={'center'}>
                        <IconButton onClick={() => setDateRangeOption('currentMonth')} size={'small'}
                                    className={'icon-button'}>
                            <ArrowBackIosRounded/>
                        </IconButton>
                        <Typography variant={'subtitle1'} color={'textSecondary'}>
                            PICK A DATE RANGE
                        </Typography>
                    </Box>
                    <DateRange
                        className={'date-range-picker'}
                        maxDate={new Date()}
                        editableDateInputs={true}
                        onChange={handleChange}
                        moveRangeOnFirstSelection={false}
                        ranges={state}
                    />
                </Box>
            </Collapse>
            <WuSpacer space={1}/>
            <Divider/>
            <Granularity value={granularity} onChange={(e) => setGranularity(e)}/>
        </Box>
    )
}


function PreDefinedSelectors(props) {
    const {value} = {...props}
    return (
        <RadioGroup value={value} onChange={(e) => props.onChange(e.target.value)}>
            <FormControlLabel value="currentMonth" control={<Radio/>} label="Current Month"/>
            <FormControlLabel value="lastMonth" control={<Radio/>} label="Last Month"/>
            <FormControlLabel value="last90Days" control={<Radio/>} label="Last 90 Days"/>
            <FormControlLabel value="last365Days" control={<Radio/>} label="Last 365 Days"/>
            <FormControlLabel value="custom" control={<Radio/>} label="Custom"/>
        </RadioGroup>
    )
}

function Granularity(props) {
    const {value} = {...props}
    return (
        <Box mt={2}>
            <Box display={'flex'} gridGap={8} mb={1} ml={-0.4}>
                <AvTimerOutlined/>
                <Typography variant={'body1'} style={{fontWeight: 600}}>Granularity</Typography>
            </Box>
            <RadioGroup row value={value} onChange={(e) => props.onChange(e.target.value)}>
                <FormControlLabel value="monthly" control={<Radio/>} label="Monthly"/>
                <FormControlLabel value="daily" control={<Radio/>} label="Daily"/>
            </RadioGroup>
        </Box>
    )
}
