import React, {useEffect, useState} from "react";
import Box from "@material-ui/core/Box";
import Region from "./Region/Region";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Button} from "@material-ui/core";
import service from "../../service/service";

const tagList = [
    "ApplicationName", "Environment", "SupportContact", "ApplicationOwner", "CostCenter"
]


export default function WuTagAndRegionFilter(props) {
    const {backButtonPath, regionEnable = true, disabled} = {...props}
    const [key, setKey] = useState("");
    const [value, setValue] = useState();
    const [valueList, setValueList] = useState([]);

    useEffect(() => {
        (async function () {
            if (tagList.includes(key)) {
                setValue("")
                const params = {
                    searchKey: key
                }
                const result = await service.general().getApplicationColumns(params);
                setValueList(result.data.data);
            } else {
                setValueList([]);
            }
        })()
    }, [key])


    const handleSearchButtonClick = () => {
        if (key && value) {
            props.onClick({
                key: key,
                value: value
            })
        }
    }


    return (
        <Box display={'flex'} className={'gap'} maxWidth={"900px"}>
            {backButtonPath && <Button>BAck</Button>}
            {regionEnable && <Region disabled={disabled} onChange={(e) => console.log(e)}/>}
            <Autocomplete
                style={{
                    marginTop: 10,
                    marginBottom: 10,
                    minWidth: 200,
                }}
                freeSolo={true}
                options={tagList}
                onInputChange={(e, value) => setKey(value)}
                fullWidth
                size={"small"}
                onChange={(e, value) => setKey(value)}
                renderInput={(params) => <TextField {...params} label={"Optional Tag Key"}
                                                    variant="outlined"/>}
            />
            <Autocomplete
                disabled={!key}
                style={{
                    marginTop: 10,
                    marginBottom: 10,
                    minWidth: 200,
                }}
                options={valueList}
                freeSolo={true}
                getOptionLabel={option => option[key] ? option[key] : ""}
                onInputChange={(e, value) => setValue(value)}
                onChange={(event, value) => {
                    setValue(value ? value[key] : "")
                }}
                fullWidth
                size={"small"}
                renderInput={(params) => <TextField {...params} error={(key && !value)} label={"Optional Tag Value"}
                                                    variant="outlined"/>}
            />
            <Button color={'secondary'}
                    disableElevation
                    variant={'contained'}
                    disabled={!key && !value}
                    onClick={handleSearchButtonClick}
                    style={{height: 36, marginTop: 10, width: 200}}>
                Search
            </Button>
        </Box>
    )
}