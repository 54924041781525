import {useEffect, useState} from "react";
import {Grid, Paper, Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {DetailsPageCluster, DetailsPageService, StatusComponent} from "../GridColumComponents";
import ContainerInformation from "./ContainerInformation";
import {apiErrorResponseJsonParser} from "../../../helper/generalFunctions";
import service from "../../../service/service";
import {useOrganization} from "../../../hooks/useOrganization";
import {ecsTaskDescription} from "../../../assets/finthesys/ecs";

const fontProperties = {fontWeight: 600}


export function TaskDetails({apiProgress, setApiProgress, extraParams}) {
    const {organization: masterAccountId} = useOrganization()
    const {accountId, clusterArn, taskArn, region} = {...extraParams}
    const [taskData, setTaskData] = useState({})

    const fetchData = async () => {
        try {
            setApiProgress({loading: true, ready: false, error: false})
            const params = {
                accountId: accountId,
                clusterArn: clusterArn,
                taskArn: taskArn,
                region: region,
                masterAccountId: masterAccountId
            }
            const {data: {data}} = await service.ecs().describeEcsTask(params)
            setTaskData(data)
            setApiProgress({loading: false, ready: true, error: false})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setApiProgress({loading: false, ready: false, error: error})
        }
    }

    const fetchDummyData = async () => {
        try {
            setApiProgress({loading: true, ready: false, error: false})
            const params = {
                accountId: accountId,
                clusterArn: clusterArn,
                taskArn: taskArn,
                region: region,
                masterAccountId: masterAccountId
            }
            const {data} = ecsTaskDescription
            setTaskData(data)
            setApiProgress({loading: false, ready: true, error: false})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setApiProgress({loading: false, ready: false, error: error})
        }
    }


    useEffect(() => {
        if (masterAccountId !== 'no org') {
            fetchDummyData().then()
        }
    }, [masterAccountId])

    return (
        <div className={'cluster-additional-details'}>
            {apiProgress.ready && <div className={'cluster-additional-details-inner'}>
                <Box display={'flex'} alignItems={'center'} gridGap={8} mb={2.5}>
                    <TagInformation tagKey={'ApplicationName'} tagValue={taskData?.applicationName}/>
                    <TagInformation tagKey={'Environment'} tagValue={taskData?.environment}/>
                    <TagInformation tagKey={'CostCenter'} tagValue={taskData?.costCenter}/>
                    <TagInformation tagKey={'ApplicationOwner'} tagValue={taskData?.applicationOwner}/>
                    <TagInformation tagKey={'ApplicationCode'} tagValue={taskData?.applicationCode}/>
                </Box>
                <Box display={'flex'}
                     alignItems={'center'}
                     className={'cluster-overall-info'}
                     style={{marginBottom: 24}}
                     width={'100%'}
                     gridGap={16}>
                    <ShortSummaryCard title={'Task Status'}>
                        <StatusComponent value={taskData.status}/>
                    </ShortSummaryCard>
                    <ShortSummaryCard title={'Service'}>
                        <DetailsPageService
                            data={{
                                accountId: taskData.accountId,
                                accountAlias: taskData.accountId,
                                region: taskData.region,
                                serviceArn: taskData.serviceArn,
                                clusterArn: taskData.clusterArn,
                                identifier: taskData.serviceArn
                            }}
                            value={taskData.serviceArn}/>
                    </ShortSummaryCard>
                    <ShortSummaryCard title={'Cluster'}>
                        <DetailsPageCluster
                            data={{
                                accountId: taskData.accountId,
                                accountAlias: taskData.accountAlias,
                                clusterArn: taskData.clusterArn,
                                region: taskData.region
                            }}
                            value={taskData.clusterArn}/>
                    </ShortSummaryCard>
                    <ShortSummaryCard title={'CPU Units'}>
                        <Typography style={fontProperties}>{taskData.cpuUnits || 0} vCPU</Typography>
                    </ShortSummaryCard>
                    <ShortSummaryCard title={'Memory'}>
                        <Typography style={fontProperties}>{taskData.memoryUnits || 0} MB</Typography>
                    </ShortSummaryCard>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Paper variant={'outlined'} className={'container-summary-card-additional-info'}>
                            <Summary title={'Task Definition'} value={taskData.taskDefinitionArn || ''}/>
                            <Summary title={'Created At'} value={taskData.createdAt}/>
                            <Summary title={'Started At'} value={taskData.startedAt}/>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper variant={'outlined'} className={'container-summary-card-additional-info'}>
                            <Summary title={'Account'} value={`${taskData.accountId}(${taskData.accountAlias})`}/>
                            <Summary title={'Availability Zone'} value={taskData.availabilityZone}/>
                            <Summary title={'Started By'} value={taskData.startedBy}/>
                        </Paper>
                    </Grid>
                </Grid>
                <Box mt={2}>
                    <Typography gutterBottom style={{marginBottom: 16, fontWeight: 600}}>
                        Container Details
                    </Typography>
                    <ContainerInformation containerInformation={taskData?.containers || []}/>
                </Box>
            </div>}
        </div>
    )
}

function TagInformation({tagKey, tagValue = '-'}) {
    return (
        <div className={'container-tag'}>
            {tagKey}:
            <Typography component={'span'} style={{fontWeight: 600, paddingLeft: 8}}>
                {tagValue}
            </Typography>
        </div>
    )
}

function Summary({title, value}) {
    return (
        <div className={'container-summary-card-task'}>
            <Typography variant={'body1'} gutterBottom>
                {title}
            </Typography>
            <Typography variant={'body1'} style={fontProperties}>
                {value}
            </Typography>
        </div>
    )
}

function ShortSummaryCard({title, children}) {
    return (
        <Paper variant={'outlined'} style={{padding: 16}}>
            <Typography gutterBottom color={'textSecondary'}>{title}</Typography>
            {children}
        </Paper>
    )
}