export const tableHeaders = [
    {
        field: 'Account',
        valueGetter: function (data) {
            const {data: {AccountId, AccountAlias}} = {...data}
            return (`${AccountId}(${AccountAlias})`)
        }
    },
    {
        field: 'RegionCode',
        headerName: 'Region',
        sortable: true
    },
    {
        field: 'VpcName',
        sortable: true
    },
    {
        field: 'VpcId',
        sortable: true
    },
    {
        field: 'VpcCidrBlock',
        headerName: 'VPC CIDR Block',
        sortable: true
    },
    {
        field: 'DefaultVpc',
        headerName: 'Default VPC',
        sortable: true
    },
    {
        field: 'SubnetName',
        sortable: true
    },
    {
        field: 'SubnetId',
        sortable: true
    },
    {
        field: 'CidrBlock',
        headerName: 'Subnet CIDR Block',
        sortable: true
    },
    {
        field: 'AvailableIpAddress',
        sortable: true,
        type: "numericColumn",
        resizable: true,
        filter: "agDateColumnFilter"
    },
    {
        field: 'AvailabilityZone',
        sortable: true
    },
    {
        field: 'Route Table ID',
    }
]