import React, {useState} from 'react';
import {Box, Checkbox, FormControlLabel, FormGroup, Typography} from "@material-ui/core";
import {MoreOutlined} from "@material-ui/icons";

export default function AdditionalFeatures() {
    const [state, setState] = useState({
        ['Include Cost Symbol']: false,
        ['Include Row Total']: false,
        ['Include Column Total']: false,
        ['Add Cell Borders']: true,
    })


    return (
        <Box mt={2}>
            <Box display={'flex'} gridGap={8} ml={-0.5} mb={1} mr={0.5} style={{color: 'grey'}}>
                <MoreOutlined/>
                <Typography variant={'body1'} style={{fontWeight: 600}}>🚧 Additional Options</Typography>
            </Box>
            <Box mt={2}>
                <FormGroup>
                    {
                        additionalOptions.map(item => <FormControlLabel
                            key={item}
                            control={<Checkbox disabled={true} checked={state[item]} name={item}/>}
                            label={item}
                        />)
                    }
                </FormGroup>
            </Box>
        </Box>
    )
}

const additionalOptions = [
    'Include Cost Symbol',
    'Include Row Total',
    'Include Column Total',
    'Add Cell Borders'
]