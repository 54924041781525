import React, {useState} from "react";
import {IconButton} from "@material-ui/core";
import {BudgetSummaryCard} from "../Budget";
import TableV2 from "./TableV2";
import PageTitle from "../../General/PageTitle/PageTitle";
import Box from "@material-ui/core/Box";
import {AddOutlined} from "@material-ui/icons";

const disabled = localStorage.getItem("loginType") !== "admin";

export default function Application(props) {
    const [budgetTotal, setBudgetTotal] = useState({total: 0, count: 0})
    const [addButtonClick, setAddButtonClick] = useState(false);

    function getBudgetTotal(tot, cnt) {
        setBudgetTotal({total: tot, count: cnt})
    }

    return (
        <div className="budget-wrapper">
            <Box mt={2}>
                <PageTitle title={"Application Budgets"} subTitle={'Resource tag based budgets'}/>
            </Box>
            <div className="summary-wrapper">
                <BudgetSummaryCard totalAmount={budgetTotal['total']} numberOfBudgets={budgetTotal['count']}/>
                {!disabled && <IconButton onClick={() => setAddButtonClick(true)}>
                    <AddOutlined/>
                </IconButton>}
            </div>
            <div className="table">
                <TableV2 getBudgetTotal={getBudgetTotal} addButton={addButtonClick}
                         onClick={() => setAddButtonClick(false)}/>
            </div>
        </div>
    )
}



