import React, {useEffect, useState} from "react";
import {
    Badge,
    Box,
    Checkbox,
    Collapse,
    Divider, Fade,
    FormControlLabel,
    FormGroup, Grid,
    IconButton,
    Paper, Slide,
    Typography
} from "@material-ui/core";
import ListRoundedIcon from "@material-ui/icons/ListRounded";
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import WuTextField from "../../General/WuTextFeild";
import {Autocomplete} from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import WuInformationPopup from "../../General/WuInformationPopup";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import {DragHandleRounded, DragIndicatorRounded} from "@material-ui/icons";

const reports = [
    {alias: 'By ApplicationName', name: 'ApplicationName'},
    {alias: 'By Cost Center', name: 'CostCenter'},
    {alias: 'By Environment', name: 'Environment'},
    {alias: 'By Service', name: 'Service'},
    {alias: 'By Linked Account', name: 'LinkedAccount'},
    {alias: 'By Region', name: 'Region'},
    {alias: 'Custom', name: 'Custom'},
]

export default function Reports(props) {
    const {data} = {...props}
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [activeReports, setActiveReports] = useState(0);
    const [checkBoxState, setCheckBoxState] = useState({
        ApplicationName: false,
        CostCenter: false,
        Environment: false,
        Service: false,
        LinkedAccount: false,
        Region: false,
        Custom: false
    });

    const handleChange = (e) => {
        setCheckBoxState({...checkBoxState, [e.target.name]: e.target.checked})
    }

    useEffect(() => {
        const tempArray = []
        Object.keys(checkBoxState).forEach(item => {
            if (checkBoxState[item] === true) tempArray.push(item)
        })
        setActiveReports(tempArray.length)
        props.onChange({report: tempArray, columns: selectedColumns});
    }, [checkBoxState, selectedColumns])

    return (
        <Box display={'flex'} className={'gap'} flexDirection={'column'}>
            <Box>
                <Badge badgeContent={activeReports} color="secondary">
                    <Box display={'flex'} gridGap={8} ml={-0.4} mr={0.8}>
                        <ListRoundedIcon/>
                        <Typography variant={'body1'} style={{fontWeight: 600}}>Reports</Typography>
                    </Box>
                </Badge>
            </Box>
            <Box>
                <FormGroup>
                    {
                        reports.map(({name, alias}) => <FormControlLabel
                            key={alias}
                            control={<Checkbox onChange={handleChange} checked={checkBoxState[alias]} name={name}/>}
                            label={alias}
                        />)
                    }
                </FormGroup>
                <Columns data={data} onChange={(e) => setSelectedColumns(e)} disabled={checkBoxState.Custom}/>
            </Box>
        </Box>
    )
}


function Columns(props) {
    const {data, disabled} = {...props}
    const [columns, setColumns] = useState([])
    const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
    const checkedIcon = <CheckBoxIcon fontSize="small"/>;

    const handleRemove = (e) => {
        setColumns(columns.filter(item => item !== e))
    }

    useEffect(() => {
        props.onChange(columns)
    }, [columns])

    return (
        <Box mt={1} minWidth={'250px'}>
            <Divider/>
            <Box mt={2}>
                <Badge badgeContent={columns.length} color="secondary">
                    <Box display={'flex'} gridGap={8} ml={-0.5} mb={1} mr={0.5}
                         style={!disabled ? {color: 'grey'} : {}}>
                        <ListRoundedIcon/>
                        <Typography variant={'body1'} style={{fontWeight: 600}}>Fields</Typography>
                    </Box>
                </Badge>
                <Autocomplete
                    disabled={!disabled}
                    multiple
                    onChange={(e, value) => setColumns(value)}
                    limitTags={0}
                    options={data}
                    value={columns}
                    disableCloseOnSelect={true}
                    renderOption={(option, {selected}) => (
                        <React.Fragment>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{marginRight: 8}}
                                checked={selected}
                            />
                            <Typography variant={'body1'}>
                                {option}
                            </Typography>
                        </React.Fragment>
                    )}
                    renderTags={() => {
                    }}
                    renderInput={(params) => (
                        <WuTextField
                            {...params}
                            label="Fields"
                            placeholder="Fields"
                        />
                    )}
                />
                <Box mt={2} display={'flex'} flexDirection={'column'} gridGap={8} maxHeight={270} overflow={'auto'}>
                    {
                        columns.map(name => <SingleColumn key={name} name={name} onClick={handleRemove}/>)
                    }
                </Box>
            </Box>
        </Box>
    )
}

function SingleColumn(props) {
    const {name} = {...props}
    return (
        <Fade in={true} direction={'up'}>
            <Paper variant={'outlined'}>
                <Box m={0.5} ml={1} display={'flex'} gridGap={8} justifyContent={'space-between'} alignItems={'center'}>
                    <Box display={'flex'} gridGap={8} alignItems={'center'}>
                        <DragIndicatorRounded style={{color: 'grey'}}/>
                        <Typography>
                            {name}
                        </Typography>
                    </Box>

                    <IconButton size={'small'} onClick={() => props.onClick(name)}>
                        <CloseRoundedIcon/>
                    </IconButton>
                </Box>
            </Paper>
        </Fade>
    )
}