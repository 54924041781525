import React, {useEffect, useState, useContext} from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import service from "../../service/service";
import Error from "../General/Error/Error";
import Throbber from "../General/Throbber/Throbber";
import OrganizationContext from "../../context/OrganizationContext";
import Money from "../../assets/images/money.png";
import {Button, Typography} from "@material-ui/core";


export default function OrganizationSelect() {
    const {setOrganization} = useContext(OrganizationContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [org, setOrg] = useState(localStorage.getItem("x-org"))
    const [selectedMasterAccount, setSelectedMasterAccount] = useState(org ? org : null);
    const [organizationData, setOrganizationData] = useState([{
        OrganizationAlias: "no org",
        AccountId: "no data",
        OrganizationName: null
    }])
    const [loading, setLoading] = useState(true);
    const [ready, setReady] = useState(false);
    const [progress, setProgress] = useState(0);
    const [listDisplayName, setListDisplayName] = useState(null);
    const [error, setError] = useState({
        error: false,
        errorMessage: ""
    });

    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = ({OrganizationName}, index) => {
        setSelectedMasterAccount(OrganizationName);
        const organization = organizationData.find((master) => master.OrganizationName === OrganizationName);
        if (organization) {
            setOrganization(organization['AccountId']); //set context foe future use
            setAnchorEl(null);
            localStorage.setItem("x-org", OrganizationName); // store index to local
            setOrg(OrganizationName);
            setListDisplayName(organization['OrganizationAlias']);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const progressFunction = {
        onDownloadProgress: (progressEvent) => {
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgress(percentCompleted)
        }
    }

    useEffect(() => {
            handleRequest()
        }, []
    )


    useEffect(() => {

        if (organizationData[0].OrganizationAlias !== "no org") {
            setListDisplayName(organizationData[0].OrganizationAlias);
            if (organizationData.length > 0 && selectedMasterAccount) {
                const organization = organizationData.find((master) => master.OrganizationName === selectedMasterAccount);
                if (organization) {
                    setOrganization(organization['AccountId']);
                    setListDisplayName(organization['OrganizationAlias']);
                } else {
                    setOrg(null)
                    setOrganization("no org")
                }
            } else {
                setOrganization(organizationData[0].AccountId);
            }
        }
    }, [organizationData])


    const handleRequest = () => {
        try {
            setLoading(true);
            service.account().getOrganizationList(progressFunction)
                .then((res) => {
                    if (res.data.code === 200) {
                        setOrganizationData(res.data.organizations.reverse())
                        setReady(true);
                        setTimeout(() => {
                            setLoading(false);
                        }, 2000)
                    } else {
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    setError({
                        error: true,
                        errorMessage: err.message
                    })
                })
        } catch (e) {
            setError({
                error: true,
                errorMessage: e.message
            })
        }
    }


    return (
        <div>
            {
                ready &&
                <React.Fragment>
                    <List component="nav" aria-label="Device settings">
                        <ListItem
                            button
                            aria-haspopup="true"
                            aria-controls="organization"
                            aria-label="organization"
                            onClick={handleClickListItem}
                        >
                            <ListItemText primary={listDisplayName}/>
                        </ListItem>
                    </List>
                    <Menu
                        id="organization-list"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {organizationData.map((organization, index) => (
                            <MenuItem
                                key={organization.AccountId}
                                selected={organization['OrganizationName'] === selectedMasterAccount}
                                onClick={(event) => handleMenuItemClick(organization, index)}
                            >
                                {organization.OrganizationAlias}
                            </MenuItem>
                        ))}
                    </Menu>
                </React.Fragment>
            }
            {
                org === null &&
                <div className="organization-help-text">
                    <div className="dialog MuiPaper-elevation8 d-f-c">
                        <div className="w-100">
                            <img className="w-100" src={Money} alt={"organization-help"}/>
                            <Typography variant={"h6"}> You haven't selected any organization</Typography>
                            <Typography variant={"subtitle1"}> Please select from below dropdown.</Typography>
                            {
                                ready &&
                                <React.Fragment>
                                    <List component="nav" style={{margin: "auto", textAlign: "center"}}
                                          aria-label="Device settings">
                                        <ListItem
                                            button
                                            aria-haspopup="true"
                                            aria-controls="organization"
                                            aria-label="organization"
                                            onClick={handleClickListItem}
                                        >
                                            <ListItemText style={{textAlign: "center"}}
                                                          primary={listDisplayName}
                                            />
                                        </ListItem>
                                    </List>
                                    <Menu
                                        id="organization-list"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                    >
                                        {organizationData.map((organization, index) => (
                                            <MenuItem
                                                key={organization.AccountId}
                                                selected={organization['OrganizationName'] === selectedMasterAccount}
                                                onClick={(event) => handleMenuItemClick(organization, index)}
                                            >
                                                {organization.OrganizationAlias}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </React.Fragment>
                            }
                            <Button onClick={(e) => {
                                handleMenuItemClick(organizationData[0], 0)
                            }} disableElevation variant={"contained"} color={"primary"}>Close</Button>
                        </div>
                    </div>
                </div>
            }


            {
                loading &&
                <Throbber value={progress}/>
            }

            {
                error.error &&
                <Error message={error.errorMessage}/>
            }
        </div>
    );
}


function HelpDialog() {

    return (
        <div className="organization-help-text">
            <div className="dialog MuiPaper-elevation8 d-f-c">
                <div className="w-100">
                    <img className="w-100" src={Money}/>
                    <Typography> You haven't selected any organization</Typography>
                    <Button disableElevation variant={"contained"} color={"primary"}>Close</Button>
                </div>
            </div>
        </div>
    )
}
