import {Paper} from "@material-ui/core";
import Header from "./Header";
import {useEffect, useState} from "react";
import service from "../../../service/service";
import {apiErrorResponseJsonParser} from "../../../helper/generalFunctions";
import Landing from "./Landing";
import './index.scss'


export default function ResourceTagsV2() {

    const [application, setApplication] = useState([])
    const [progress, setProgress] = useState({loading: false, error: false, ready: false})
    const [organizationList, setOrganizationList] = useState([])
    const [organization, setOrganization] = useState(null)
    const [finopsTags, setFinopsTags] = useState(null)

    const fetchData = async () => {
        try {
            setProgress({loading: true, error: false, ready: false})
            const params = {
                masterAccountId: organization
            }
            const {data: {data}} = await service.onBoarding().getApplicationList(params)
            setApplication(data)
            setProgress({loading: false, error: false, ready: true})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setProgress({loading: false, ready: true, error: error})
        }
    }

    const fetchOrganization = async () => {
        try {
            const {data: {organizations}} = await service.account().getOrganizationList()
            const {data: {data: finopsTags}} = await service.automations().getAutoTaggingResourceTags();
            setFinopsTags(finopsTags.map(e => e.TagKey))
            setOrganizationList(organizations?.reverse())
            setOrganization(organizations[0]['AccountId'])
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setProgress({loading: false, ready: true, error: error})
        }
    }

    useEffect(() => {
        fetchOrganization().then()
    }, [])

    useEffect(() => {
        if (organization) fetchData().then()
    }, [organization])

    return (
        <Paper elevation={0}>
            <Header organizationList={organizationList}
                    organization={organization}
                    onOrganizationChange={setOrganization}/>
            <Landing onboardingData={application} loading={progress} finopsTags={finopsTags}/>
        </Paper>
    )
}