import React, {useEffect, useState} from "react";
import Paper from "@material-ui/core/Paper";


export default function Insights(props) {
    const [report, setReport] = useState([]);
    const [insightsPresent, setInsightsPresent] = useState(false);
    const [reportTitle, setReportTitle] = useState(props.title);
    useEffect(() => {
        setReport(props.Data);
        setReportTitle(props.title);

        if (report.length !== 0) {
            setInsightsPresent(false);
        }

        // remove and fix this issue

        if (props.title !== 'DailyCostReports'){
            setInsightsPresent(false);
        }

    }, [props])

    function DailyCostReport() {
        return (
            <div className="insights-daily-cost-wrapper">
                <div className="insight-title">
                    Insights
                </div>
                <div className="item">
                    <p className="title">Three Top Most Daily Spend Accounts( last 3 months )</p>
                    <div>
                        <ul>
                            {
                                report.topSpend.map((accounts, index) => {
                                    return (
                                        <li key={index}>{accounts['Account Name']}</li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div className="item" style={{border: "none"}}>
                    <p className="title">Total Daily Spend of all Accounts (last 3 months)</p>
                    <p className="content"> ${Number(report.totalSpend).toLocaleString()}</p>
                </div>
            </div>
        )
    }

    return (
        <div style={insightsPresent? {width: "100%"}: {display: "none"}}>
            {
                insightsPresent &&
                <Paper className="insights-wrapper">
                    {
                        reportTitle === "DailyCostReports" ? <DailyCostReport/> : ""
                    }
                </Paper>
            }
        </div>
    )
}