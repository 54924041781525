import {accountLengthAdjust} from "../../../../../helper/generalFunctions";

export const autoScalingGroup = [
    {
        field: "Account",
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
        valueGetter: function ({data}) {
            return (`${accountLengthAdjust(data.Account_AccountId)} (${data.AccountAlias})`)
        }
    },
    {
        field: "ApplicationName",
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
    },
    {
        field: "CostCenter",
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
    },
    {
        field: "AutoScalingGroupName",
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
    },
    {
        field: "CurrentInstanceType",
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
    },
    {
        field: "RecommendedInstanceType",
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
    },
    {
        field: "Region_RegionCode",
        headerName: "Region",
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
    },
]