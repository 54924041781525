import React, {useState} from "react";
import {Button, Typography} from "@material-ui/core";
import {Table} from "../General/Table";
import {AddOrganization} from "./AddOrganization";


export default function OrganizationSetting() {

    const [newOrganization, setNewOrganization] = useState(false);
    const rowData = [
        {
            organizationName: "organization-1",
            organizationId: "o-23sfgdfg4",
            organizationAlias: "ORGANIZATION-1",
            masterAccountId: "588566222334",
            masterAccountAlias: "organization-1",
        },
        {
            organizationName: "organization-2",
            organizationId: "o-ewdd14565",
            organizationAlias: "ORGANIZATION-2",
            masterAccountId: "477419074820",
            masterAccountAlias: "organization-2",
        },
    ]

    return (
        <div>
            <Typography>
                AWS Organizations helps you centrally manage and govern your environment as you grow and scale your AWS
                resources. Using AWS Organizations, you can programmatically create new AWS accounts and allocate
                resources, group accounts to organize your workflows, apply policies to accounts or groups for
                governance, and simplify billing by using a single payment method for all of your accounts.
            </Typography>
            <Typography>
                Once you created an Organization in your AWS Account, add the organization information here.
            </Typography>
            <Table title={"Active Environments"} tableData={rowData}/>
            <Button variant={"contained"} disableElevation onClick={() => setNewOrganization(true)} color={"primary"}>
                New Organization
            </Button>
            {
                newOrganization &&
                <AddOrganization/>
            }
        </div>
    )
}


