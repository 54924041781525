import React, {useEffect, useState} from 'react';
import DatePicker from "./DatePicker";
import './index.scss';
import {Box, Collapse, Divider, Typography} from "@material-ui/core";
import {useOrganization} from "../../../hooks/useOrganization";
import service from "../../../service/service";
import moment from "moment";
import fileExportElement from "../../../helper/fileExportElement";
import {ProgressCircleCustomSize} from "../../ProgressCircle/ProgressCircle";
import {Filter} from "./Filter";
import ReportAction from "./ReportAction";
import Reports from "./Reports";
import WuInformationPopup from "../../General/WuInformationPopup";
import AdditionalFeatures from "./AdditionalFeatures";

export default function CustomReport() {
    const {organization: masterAccountId} = useOrganization();
    const [progress, setProgress] = useState({loading: false, error: false, ready: false})
    const [data, setData] = useState([]);
    const [reportProperties, setReportProperties] = useState({reportProperties: {}, filter: {}, dateRange: {}})
    let reportData = {}
    let filter = []
    let dateRange = {startDate: '', endDate: '', granularity: 'monthly'}


    const handleReportSelectionChange = (e) => {
        setReportProperties({...reportProperties, reportProperties: e})
    }

    const handleFilterChange = (e) => {
        setReportProperties({...reportProperties, filter: e})
    }

    const handleDateChange = (e) => {
        dateRange.startDate = moment(e['dateRange'][0]['startDate']).format('YYYY-MM-DD')
        dateRange.endDate = moment(e['dateRange'][0]['endDate']).format('YYYY-MM-DD')
        dateRange.granularity = e['granularity']
        setReportProperties({...reportProperties, dateRange: dateRange})
    }

    const fetchData = async () => {
        try {
            setProgress({loading: true, error: false, ready: false})
            const params = {
                "master_account_id": masterAccountId,
                "alias_only": true,
            }
            const {data: {data: responseData}} = await service.athena().getAthenaColumns(params)
            setData(responseData)
            setProgress({loading: false, error: false, ready: true})
        } catch (e) {
            setProgress({loading: false, error: true, ready: false})
        }
    }

    useEffect(() => {
        if (masterAccountId !== 'no org') {
            fetchData().then()
        }
    }, [masterAccountId])


    return (
        <Box>
            <ReportAction reportProperties={reportProperties}/>
            <Box display={'flex'} className={'gap'}>
                <Box mt={2}>
                    <Reports onChange={handleReportSelectionChange}
                             data={data}/>
                </Box>
                <Divider flexItem={true} orientation={'vertical'} style={{marginLeft: 16, marginRight: 16}}/>
                <Box mt={2}>
                    <DatePicker onChange={handleDateChange}/>
                </Box>
                <Divider flexItem={true} orientation={'vertical'} style={{marginLeft: 16, marginRight: 16}}/>
                <Box mt={2}>
                    <Filter onChange={handleFilterChange} data={data}/>
                </Box>
                <Divider flexItem={true} orientation={'vertical'} style={{marginLeft: 16, marginRight: 16}}/>
                <AdditionalFeatures/>
            </Box>
            <Information/>
        </Box>
    )
}


function Information() {
    const [open, setOpen] = useState(true)
    return (
        <WuInformationPopup title={`What does ⚡, 🚧 symbols mean?`} open={open} onClose={() => setOpen(false)}>
            <Box>
                <ul>
                    <li>
                        <Typography>
                            Pages/UI elements marked with ⚡ symbol means, Development is incomplete or partially
                            completed. you can't expect fully functional elements
                        </Typography>
                    </li>
                    <li>
                        <Typography>
                            Items marked with 🚧 symbol means, They are not implemented yet, but it will be implemented
                            in the future
                        </Typography>
                    </li>
                </ul>
            </Box>
        </WuInformationPopup>
    )
}
