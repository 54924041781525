import React from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import WuAccordionWrapper from "../WuAccordion/WuAccordionWrapper";
import WuAccordionSummary from "../WuAccordion/WuAccordionSummary";
import WuStack from "../WuStack";
import WuAccordionDetails from "../WuAccordion/WuAccordionDetails";

export default function ResponseStatus({response = {}, total = 0, failed = 0}) {

    function InfoComponent() {
        if ((total - failed) === total) return <CheckCircleOutlineIcon style={{color: 'green'}}/>
        else if ((total - failed) === 0) return <ErrorOutlineIcon style={{color: 'red'}}/>
        else if (failed < total) return <ErrorOutlineIcon style={{color: 'orange'}}/>
    }


    return (
        <WuAccordionWrapper>
            <WuAccordionSummary>
                <WuStack direction={'row'} spacing={1} alignItems={'center'}>
                    <InfoComponent/>
                    Success
                </WuStack>
            </WuAccordionSummary>
            <WuAccordionDetails>
                <WuStack direction={'column'} spacing={2} boxProps={{m: 2}}>
                    {Object.keys(response).map((singleResource, index) => <ScheduleStatus key={singleResource}
                                                                                          resourceKey={singleResource}
                                                                                          responseValue={response[singleResource]}/>)}
                </WuStack>
            </WuAccordionDetails>
        </WuAccordionWrapper>
    )
}


function ScheduleStatus({resourceKey = '', responseValue = false}) {

    return (
        <WuStack direction={'row'} spacing={1} alignItems={'center'}>
            {responseValue ? <CheckCircleOutlineIcon style={{color: 'green'}}/> :
                <ErrorOutlineIcon style={{color: 'red'}}/>}
            {resourceKey}
        </WuStack>
    )
}