import DataGrid from "../../../General/DataGrid";
import {gridDefinition} from "./grid_definition";
import {Paper} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import service from "../../../../service/service";
import {apiErrorResponseJsonParser} from "../../../../helper/generalFunctions";

export default function AccountWiseData({accountId}) {
    const [progress, setProgress] = useState({loading: false, ready: false, error: false})
    const [result, setResult] = useState({loading: false, ready: false, error: false})

    const fetchData = async () => {
        try {
            setProgress({loading: false, ready: false, error: false})
            const params = {
                account_id: accountId
            }
            const {data: {account}} = await service.monitoring().getTagComplianceTableData(params)
            setResult(account)
            setProgress({loading: false, ready: true, error: false})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setProgress({loading: false, ready: false, error: error})
        }
    }

    useEffect(() => {
        fetchData().then()
    }, [])
    return (
        <Paper style={{width: '100%', borderRadius: 0}} variant={'outlined'}>
            <DataGrid gridData={result || []} fixedColumn={gridDefinition}/>
        </Paper>
    )
}