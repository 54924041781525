import React from 'react';
import {Paper} from "@material-ui/core";
import {AlertWu} from "../AlertWu/AlertWu";
import propTypes from "prop-types";


export default function WuAlert({success = false, successMessage = '', error = false, errorMessage, paperProps}) {
    return (
        <Paper elevation={0} {...paperProps}>
            <AlertWu alertOpen={{state: success}} severity={"success"}
                     message={successMessage || 'Success'}/>
            <AlertWu alertOpen={{state: error}} severity={"error"} message={errorMessage || 'Something went wrong'}/>
        </Paper>
    )
}

WuAlert.propTypes = {
    success: propTypes.bool.isRequired,
    paperProps: propTypes.object,
    successMessage: propTypes.oneOfType([propTypes.string, propTypes.bool, propTypes.number]).isRequired,
    error: propTypes.bool.isRequired,
    errorMessage: propTypes.oneOfType([propTypes.string, propTypes.bool, propTypes.number]).isRequired,
}