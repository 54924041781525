import React, {useContext, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import service from "../../../../service/service";
import {ComputeOptimizerRecommendation} from "../../../../helper/dataParser";
import {withStyles} from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import {AlertWu} from "../../../AlertWu/AlertWu";
import {PaperProgress} from "../../../ProgressCircle/ProgressCircle";
import {useParams} from "react-router";
import DataGrid from "../../../General/DataGrid/DataGrid";
import OrganizationContext from "../../../../context/OrganizationContext";
import ComputeOptimizerChangeContext from "../../../../context/ComputeOptimizerChangeContext";
import {instance} from "./tableColumns/instance";
import {autoScalingGroup} from "./tableColumns/autoScalingGroup";
import {ebs} from "./tableColumns/ebs";
import {useOrganization} from "../../../../hooks/useOrganization";

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .02)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '25%',
        flexShrink: 0,
    },
    secondaryHeading: {
        flexBasis: '30.33%',
        flexShrink: 0,
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));

export default function SummaryAccordion(props) {
    const {organization: masterAccountId} = useOrganization();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [recommendationData, setRecommendationData] = useState([]);
    const [loadingComplete, setLoadingComplete] = useState(false);
    const [errorBoundary, setErrorBoundary] = useState(true);
    const [error, setError] = useState("");
    const {recommendationType, setRecommendationType} = useContext(ComputeOptimizerChangeContext);
    // find the recommendation type from urlParam
    let {recommendation} = useParams();

    let columns = []
    switch (recommendation) {
        case "compute":
            recommendation = "instance";
            columns = instance
            break;
        case "ebs":
            recommendation = "ebs"
            columns = ebs
            break
        case "lambda":
            recommendation = "lambda"
            break
        case "auto-scaling-group":
            recommendation = "autoScalingGroup"
            columns = autoScalingGroup
            break
        default:
            break
    }


    useEffect(() => {
        setRecommendationType(recommendation)
        return (() => {
            setRecommendationType("")
        })
    }, [recommendation])

    useEffect(() => {
        try {
            if (masterAccountId !== 'no org' && recommendationType) {
                if (!recommendationType) return false
                const params = {
                    "account_ids": "not required at this moment",
                    "recommendation_type": recommendationType,
                    "regions": props.filter.region,
                    "tag_key": props.filter.key,
                    "tag_value": props.filter.value,
                    "master_account_id": masterAccountId
                }

                setLoading(true);
                setErrorBoundary(false);
                service.recommendations().getComputeOptimizer(params)
                    .then(ref => {
                        setRecommendationData(ComputeOptimizerRecommendation(ref.data.data))
                        setLoadingComplete(true)
                        setLoading(false);
                    })
                    .catch((err) => {
                        if (err?.response?.data?.code !== 402) {
                            setError(err.message);
                            setLoading(false);
                            setErrorBoundary(true)
                        } else {
                            setLoading(false)
                            setLoadingComplete(true)
                        }
                    })
            }
        } catch (error) {
            setLoading(false);
            setErrorBoundary(true);
        }
    }, [props.filter, masterAccountId])

    return (
        <div className={classes.root}>
            {
                !errorBoundary &&
                loadingComplete ?
                    recommendationData.length > 0 ?
                        recommendationData.map((recommendation, index) => {
                            return (
                                <Accordion key={index}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1a-content"
                                    >
                                        <InfoOutlinedIcon style={{paddingRight: 20}}/>
                                        <Typography
                                            className={classes.heading}>{`${recommendation.AccountAlias} (${recommendation.Account_AccountId})`}</Typography>
                                        <Typography className={classes.secondaryHeading}>Resources can be optimized:
                                            {recommendation.NumberOfResources}</Typography>
                                        {/* <Typography className={classes.secondaryHeading}>Total Savings:
                                        ${Math.abs(recommendation.TotalSavings.toFixed(2))}</Typography>*/}
                                    </AccordionSummary>
                                    <DataGrid
                                        fixedColumn={columns}
                                        fileName={`compute_optimizer`}
                                        gridStyle={{height: "calc(100vh - 350px)"}}
                                        gridData={recommendation.Recommendation}/>

                                </Accordion>
                            )
                        }) : "no records" : <PaperProgress/>
            }
            {
                !loading && errorBoundary &&
                <AlertWu severity="error" message={"Something went wrong... Error type: " + error}/>
            }
        </div>
    );
}