import {Link} from "react-router-dom";
import BreadCrumbNavigation from "../BreadCrumbNavigation";
import OrganizationWiseSummary from "./OrganizationWiseSummary";

export default function NoTagsDetails() {

    const navigation = [
        <Link key="1" to="/tagging">
            tagging
        </Link>,
        <Link key="2" to="/tagging/tag-compliance">
            tag compliance
        </Link>,
        <Link key="3" to={"/tagging/tag-compliance/no-tags"}>
            no-tags
        </Link>
    ]


    return (
        <div className={'tc-details-wrapper'} style={{marginLeft: -30, marginTop: -24}}>
            <div className={'tc-details-wrapper-bread-crumb'}>
                <BreadCrumbNavigation breadcrumbs={navigation}/>
            </div>
            <div className={'tc-details-container-no-tag'}>
                <OrganizationWiseSummary/>
            </div>
        </div>
    )
}