import {Paper} from "@material-ui/core";
import DataGrid from "../../General/DataGrid/DataGrid";
import Box from "@material-ui/core/Box";
import React, {useEffect, useState} from "react";
import WuLoading from "../../General/WuLoading";
import service from "../../../service/service";
import {useOrganization} from "../../../hooks/useOrganization";
import moment from "moment";
import WuAlert from "../../General/WuAlert";
import {newResources} from "../../../assets/finthesys/newResourceProvioned";

export default function DataTables({startDate, endDate, load, selectedOptions}) {
    const {organization: masterAccountId} = useOrganization();
    const [apiProgress, setApiProgress] = useState({loading: false, ready: false})
    const [newDeployments, setNewDeployments] = useState([]);
    const fetchDate = async () => {
        try {
            setApiProgress({loading: true, ready: false})
            startDate = moment(startDate, 'YYYY-MM-DD')
            endDate = moment(endDate, 'YYYY-MM-DD')
            const mode = endDate.diff(startDate, 'days') === 0 ? 'static' : 'range';
            const params = {
                "master_account_id": masterAccountId,
                "date_type": mode,
                "report_not_include": Object.keys(selectedOptions).reduce((acc, current) => {
                    if (selectedOptions[current]) acc.push(current)
                    return acc
                }, [])
            }

            if (mode === 'static') {
                params['static_date'] = startDate.format('YYYY-MM-DD')
            } else {
                params['start_date'] = startDate.format('YYYY-MM-DD')
                params['end_date'] = endDate.format('YYYY-MM-DD')
            }

            /*const {data: {data}} = await service.monitoring().getNewDeployments(params)*/
            setTimeout((
                setNewDeployments(newResources.data)), 2000)
            setApiProgress({loading: false, ready: true})
        } catch (e) {
            setApiProgress({loading: false, ready: false, error: e.message})
        }
    }

    useEffect(() => {
        if (masterAccountId !== 'no org') {
            fetchDate().then()
        }
    }, [masterAccountId, load])

    return (
        <Box width={'100%'}>
            <WuLoading open={apiProgress.loading} message={'Loading new resources. Please wait...'}/>
            {
                apiProgress.ready &&
                <Paper variant={'outlined'}>
                    <DataGrid gridData={newDeployments}
                              fileName={'new_deployments'}
                              gridStyle={{height: 'calc(100vh - 290px)'}}/>
                </Paper>
            }
            <Box>
                <WuAlert
                    error={!!apiProgress.error}
                    errorMessage={apiProgress.error}
                    success={false}
                    successMessage={'No need'}/>
            </Box>
        </Box>
    )
}


const data = [
    {
        resourceId: 'i-sdfgdfgdfgdg',
        applicationName: 'LabTestApplication',
        applicationOwner: 'nithin.puthentpurackal@wu.com',
        costCode: '1245',
        provisionedDate: '2021-09-01',
        cost: '$1200'
    },
    {
        resourceId: 'i-sdfgdfgdfgdg',
        applicationName: 'LabTestApplication',
        applicationOwner: 'nithin.puthentpurackal@wu.com',
        costCode: '1245',
        provisionedDate: '2021-09-01',
        cost: '$1200'
    },
    {
        resourceId: 'i-sdfgdfgdfgdg',
        applicationName: 'LabTestApplication',
        applicationOwner: 'nithin.puthentpurackal@wu.com',
        costCode: '1245',
        provisionedDate: '2021-09-01',
        cost: '$1200'
    },
    {
        resourceId: 'i-sdfgdfgdfgdg',
        applicationName: 'LabTestApplication',
        applicationOwner: 'nithin.puthentpurackal@wu.com',
        costCode: '1245',
        provisionedDate: '2021-09-01',
        cost: '$1200'
    },
    {
        resourceId: 'i-sdfgdfgdfgdg',
        applicationName: 'LabTestApplication',
        applicationOwner: 'nithin.puthentpurackal@wu.com',
        costCode: '1245',
        provisionedDate: '2021-09-01',
        cost: '$1200'
    },
    {
        resourceId: 'i-sdfgdfgdfgdg',
        applicationName: 'LabTestApplication',
        applicationOwner: 'nithin.puthentpurackal@wu.com',
        costCode: '1245',
        provisionedDate: '2021-09-01',
        cost: '$1200'
    },
    {
        resourceId: 'i-sdfgdfgdfgdg',
        applicationName: 'LabTestApplication',
        applicationOwner: 'nithin.puthentpurackal@wu.com',
        costCode: '1245',
        provisionedDate: '2021-09-01',
        cost: '$1200'
    },
    {
        resourceId: 'i-sdfgdfgdfgdg',
        applicationName: 'LabTestApplication',
        applicationOwner: 'nithin.puthentpurackal@wu.com',
        costCode: '1245',
        provisionedDate: '2021-09-01',
        cost: '$1200'
    },
    {
        resourceId: 'i-sdfgdfgdfgdg',
        applicationName: 'LabTestApplication',
        applicationOwner: 'nithin.puthentpurackal@wu.com',
        costCode: '1245',
        provisionedDate: '2021-09-01',
        cost: '$1200'
    },
    {
        resourceId: 'i-sdfgdfgdfgdg',
        applicationName: 'LabTestApplication',
        applicationOwner: 'nithin.puthentpurackal@wu.com',
        costCode: '1245',
        provisionedDate: '2021-09-01',
        cost: '$1200'
    },
    {
        resourceId: 'i-sdfgdfgdfgdg',
        applicationName: 'LabTestApplication',
        applicationOwner: 'nithin.puthentpurackal@wu.com',
        costCode: '1245',
        provisionedDate: '2021-09-01',
        cost: '$1200'
    },
    {
        resourceId: 'i-sdfgdfgdfgdg',
        applicationName: 'LabTestApplication',
        applicationOwner: 'nithin.puthentpurackal@wu.com',
        costCode: '1245',
        provisionedDate: '2021-09-01',
        cost: '$1200'
    },
    {
        resourceId: 'i-sdfgdfgdfgdg',
        applicationName: 'LabTestApplication',
        applicationOwner: 'nithin.puthentpurackal@wu.com',
        costCode: '1245',
        provisionedDate: '2021-09-01',
        cost: '$1200'
    },
    {
        resourceId: 'i-sdfgdfgdfgdg',
        applicationName: 'LabTestApplication',
        applicationOwner: 'nithin.puthentpurackal@wu.com',
        costCode: '1245',
        provisionedDate: '2021-09-01',
        cost: '$1200'
    },
    {
        resourceId: 'i-sdfgdfgdfgdg',
        applicationName: 'LabTestApplication',
        applicationOwner: 'nithin.puthentpurackal@wu.com',
        costCode: '1245',
        provisionedDate: '2021-09-01',
        cost: '$1200'
    },
    {
        resourceId: 'i-sdfgdfgdfgdg',
        applicationName: 'LabTestApplication',
        applicationOwner: 'nithin.puthentpurackal@wu.com',
        costCode: '1245',
        provisionedDate: '2021-09-01',
        cost: '$1200'
    },
    {
        resourceId: 'i-sdfgdfgdfgdg',
        applicationName: 'LabTestApplication',
        applicationOwner: 'nithin.puthentpurackal@wu.com',
        costCode: '1245',
        provisionedDate: '2021-09-01',
        cost: '$1200'
    },
    {
        resourceId: 'i-sdfgdfgdfgdg',
        applicationName: 'LabTestApplication',
        applicationOwner: 'nithin.puthentpurackal@wu.com',
        costCode: '1245',
        provisionedDate: '2021-09-01',
        cost: '$1200'
    },
]
