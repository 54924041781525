import {Checkbox, FormControlLabel} from "@material-ui/core";

export function AdditionalOptions(props) {
    const {value, onValueChange} = {...props}


    const handleOnChange = (e) => {
        onValueChange({...value, [e.target.name]: e.target.checked})
    }

    return (
        <div className={'at-additional-tag-option'}>
            <FormControlLabel
                control={<Checkbox name={'excludeNonFixableResource'} checked={value.excludeNonFixableResource}
                                   onChange={handleOnChange}/>}
                label="Exclude resources that are not automatically fixable"/>
            <FormControlLabel
                control={<Checkbox name={'replaceWrongValueInPreview'} checked={value.replaceWrongValueInPreview}
                                   onChange={handleOnChange}/>}
                disabled={true}
                label="Replace wrong value with valid value in preview"/>
        </div>
    )
}