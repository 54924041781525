import {Typography} from "@material-ui/core";

export default function UnderDevelopmentBadge() {

    const style = {
        wrapper: {
            position: 'fixed',
            borderRadius: '4px',
            background: 'rgba(0,0,0,0.06)',
            right: 24,
            bottom: 24,
            padding: '8px 16px',
            width: 400,
            maxWidth: '100%'
        }
    }


    return (
        <div style={style.wrapper}>
            <Typography variant={'body2'} color={'textSecondary'}>
                This is a preview version.
            </Typography>
            <Typography variant={'body2'} color={'textSecondary'}>
                If you
                encounter an issue, you can still use older auto tagging screen, and your feedback is critical before we take
                the next step.
            </Typography>
        </div>
    )
}