import React from "react";
import {SettingRoute} from "../../../routes/SettingRoute";


export default function SettingNavigation() {
    return (
        <section id="setting-navigation" className="setting-navigation">
            <SettingRoute/>
        </section>
    )
}