import React from "react";

export default function GlobalSearch(props) {
    const {value} = {...props}
    return (
        <input
            ref={(input) => {
                if (input) {
                    if (input.value) {
                        if (input.value.length >= 0) {
                            input.focus();
                        }
                    }
                }
            }}
            type="text"
            placeholder="Search here"
            value={value}
            onChange={(e) => props.onChange(e.target.value)}
            className="global-filter"
        />
    )
}