import React, {useState} from "react";
import {Button, Typography} from "@material-ui/core";
import {AddRegion} from "./AddRegion";
import {Table} from "../General/Table";


export default function RegionSetting() {

    const [newRegion, setNewRegion] = useState(false);
    const rowData = [{
        regionCode: "us-east-1",
        regionName: "N. Virginia",
        comment: "Primary Region",
        regionName2: "N. Virginia",
        comment2: "Primary Region",
        regionName1: "N. Virginia",
        comment1: "Primary Region",
        regionName4: "N. Virginia",
        comment4: "Primary Region",
        regionName5: "N. Virginia",
        comment5: "Primary Region",
        regionNam6e: "N. Virginia",
        comment7: "Primary Region",

    },
        {
            regionCode: "us-east-2",
            regionName: "Ohio",
            comment: "DR Region"
        },
        {
            regionCode: "us-east-2",
            regionName: "Ohio",
            comment: "DR Region"
        },{
            regionCode: "us-east-2",
            regionName: "Ohio",
            comment: "DR Region"
        },{
            regionCode: "us-east-2",
            regionName: "Ohio",
            comment: "DR Region"
        },{
            regionCode: "us-east-2",
            regionName: "Ohio",
            comment: "DR Region"
        },{
            regionCode: "us-east-2",
            regionName: "Ohio",
            comment: "DR Region"
        },
        {
            regionCode: "us-east-2",
            regionName: "Ohio",
            comment: "DR Region"
        }]
    return (
        <div>
            <Typography>
                Region is a physical location around the world where we cluster data
                centers. We call each group of logical data centers an Availability Zone. Each Region consists of
                multiple, isolated, and physically separate AZ's within a geographic area. Unlike other cloud providers,
                who often define a region as a single data center, the multiple AZ design of every AWS Region offers
                advantages for customers. Each AZ has independent power, cooling, and physical security and is connected
                via redundant, ultra-low-latency networks. AWS customers focused on high availability can design their
                applications to run in multiple AZ's to achieve even greater fault-tolerance. AWS infrastructure Regions
                meet the highest levels of security, compliance, and data protection.
            </Typography>
            <Table title={"Active Regions"} tableData={rowData}/>
            <Button variant={"contained"} disableElevation onClick={() => setNewRegion(true)} color={"primary"}>
                New Region
            </Button>
            {
                newRegion &&
                <AddRegion/>
            }
        </div>
    )
}


