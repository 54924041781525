import React from "react";
import PageTitle from "../General/PageTitle/PageTitle";
import CustomTab from "../General/CustomTab/CustomTab";
import {TaggingRoute} from "../../routes/MainRoute";


export default function Tagging(){
    const tabList = [
        {
            name: "Tag compliance",
            link: "/tagging/tag-compliance"
        },
        {
            name: "Tag Editor",
            link: "/tagging/auto-tagging"
        },
        {
            name: "Tag Policy",
            link: "/tagging/tag-policy"
        },
    ]
    return (
        <div>
            <div className="title-div-wrapper">
                <PageTitle title={"Manage Resource Tags"} subTitle={'Manage finops and other tags'}/>
            </div>
            <CustomTab links={tabList}/>
            <div className="title-div-wrapper">
                <TaggingRoute/>
            </div>
        </div>
    );
}