import React, {useEffect, useState} from "react";
import {Card, CardActions, CardContent, CircularProgress} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import moment from 'moment'
import Autocomplete from "@material-ui/lab/Autocomplete";
import service from "../../../../service/service";
import {useOrganization} from "../../../../hooks/useOrganization";
import WuTextField from "../../../General/WuTextFeild";
import {apiErrorResponseJsonParser} from "../../../../helper/generalFunctions";
import fileExportElement from "../../../../helper/fileExportElement";

export default function ShowbackReport() {
    const {organization: masterAccountId} = useOrganization()
    const [applicationData, setApplicationData] = useState([])
    const [apiProgress, setProgress] = useState({loading: false, ready: false, error: false})
    const [selectedApplication, setSelectedApplication] = useState('')

    const fetchApplicationName = async () => {
        try {
            const params = {searchKey: 'ApplicationName', masterAccountId: masterAccountId}
            const {data: {data}} = await service.general().getApplicationColumns(params)
            setApplicationData(data)
        } catch (e) {

        }
    }


    const generateReport = async () => {
        try {

            setProgress({loading: true, ready: false, error: false})
            const params = {
                application_name: selectedApplication,
                masterAccountId: masterAccountId,
                recipient_email: 'nithin.puthenpurackal@wu.com'
            }
            const response = await service.buReport().applicationShowbackReport(params)
            fileExportElement(response)
            setProgress({loading: false, ready: true, error: false})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setProgress({loading: false, ready: false, error: error})
        }
    }

    useEffect(() => {
        fetchApplicationName().then()
    }, [masterAccountId])

    return (
        <Card className="custom-wu-report-card" variant={"outlined"} style={{borderTop: '4px solid #ff911c'}}>
            <CardContent className="custom-wu-report-card-content">
                <Typography variant={"subtitle1"} color="textSecondary"
                            gutterBottom>
                    {moment().format('MMMM')} Showback report
                </Typography>
                <Box display={'flex'} justifyContent={'space-between'} mr={0.4}>
                    <Typography variant="h5" component="div">
                        Application Showback
                    </Typography>
                </Box>
                <Typography className="custom-wu-report-card-pos" color="textSecondary">
                    Monthly application showback report
                </Typography>
                <div className="custom-wu-report-card-pos">
                    <Autocomplete
                        freeSolo
                        options={applicationData}
                        id={"application_name"}
                        getOptionLabel={(application) => application.ApplicationName ? application.ApplicationName : 'no name'}
                        fullWidth
                        inputValue={selectedApplication ? selectedApplication : ''}
                        onInputChange={(e) => e ? setSelectedApplication(e.target.value) : ''}
                        onChange={(event, value) => {
                            setSelectedApplication(value && value.ApplicationName)
                        }}
                        size={"small"}
                        renderInput={(params) => <WuTextField {...params}
                                                              label="Application Name"
                                                              variant="outlined"/>}
                    />
                </div>
            </CardContent>
            <CardActions style={{paddingLeft: 10}}>
                <Button
                    onClick={generateReport}
                    disabled={apiProgress.loading}
                    size="medium">
                    {
                        apiProgress.loading ? <><CircularProgress size={16}
                                                                  style={{marginRight: 8}}
                                                                  color={'secondary'}/>
                                Downloading... </>
                            : "Download Report"
                    }
                </Button>
            </CardActions>
        </Card>
    )
}