import DataGrid from "../../General/DataGrid";
import {Box} from "@material-ui/core";
import {columns} from './gridProperties'
import {useEffect, useState} from "react";
import service from "../../../service/service";
import {apiErrorResponseJsonParser} from "../../../helper/generalFunctions";
import WuLoading from "../../General/WuLoading";
import WuAlert from "../../General/WuAlert";
import {useEcs} from "../../../hooks";
import {ecsInstanceList} from "../../../assets/finthesys/ecs";

export default function Instances(props) {
    const {accountId, open} = {...props}
    const {setEcs} = useEcs()
    const [apiProgress, setApiProgress] = useState({loading: false, ready: true, error: false})
    const [instanceData, setInstanceData] = useState([])

    const fetchData = async () => {
        try {
            setApiProgress({loading: true, ready: false, error: false})
            const params = {
                accountId: accountId
            }
            const {data: {data}} = await service.ecs().listEcsInstance(params)
            setInstanceData(data)
            setApiProgress({loading: false, ready: true, error: false})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setApiProgress({loading: false, ready: false, error: error})
        }
    }

    const fetchDummyData = async () => {
        try {
            setApiProgress({loading: true, ready: false, error: false})
            const params = {
                accountId: accountId
            }

            setTimeout(() => {
                const {data} = ecsInstanceList
                setInstanceData(data)
                setApiProgress({loading: false, ready: true, error: false})
            }, 2000)

        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setApiProgress({loading: false, ready: false, error: error})
        }
    }


    useEffect(() => {
        fetchDummyData().then()
        setEcs(current => ({...current, filter: null}))
    }, [accountId, open])

    return (
        <div className={'ecs-content-wrapper'}>
            <Box m={apiProgress.loading ? 1 : 0}>
                <WuLoading message={'container instance information loading. Please wait...'}
                           open={apiProgress.loading}/>
            </Box>
            <WuAlert error={Boolean(apiProgress.error)} success={false} errorMessage={apiProgress.error}/>
            {apiProgress.ready && <DataGrid
                gridStyle={{height: instanceData.length > 5 ? "250px" : instanceData.length === 1 ? "90px" : instanceData.length * 65 + "px"}}
                gridData={instanceData}
                fixedColumn={columns}
            />}
        </div>
    )
}