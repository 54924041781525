import React, {useEffect, useState} from "react";
import WuDialog from "../../General/WuDialog";
import {Box, Button, Collapse} from "@material-ui/core";
import {AlertWu} from "../../AlertWu/AlertWu";
import service from "../../../service/service";
import {ProgressCircleCustomSize} from "../../ProgressCircle/ProgressCircle";
import {accountLengthAdjust, jsonParser} from "../../../helper/generalFunctions";
import TimeZoneSelector from "./ReUsable";
import ResponseStatus from "../../General/ResponseStatus";


export default function PowerScheduleUpdate(props) {
    const {open, data} = {...props};
    const [error, setError] = useState({error: false, message: undefined});
    const [loading, setLoading] = useState(false);
    const [retention, setRetention] = useState(data.RetentionInDays);
    const [apiResponse, setApiResponse] = useState(null)
    const [ready, setReady] = useState(false)

    useEffect(() => {
        setRetention(data.RetentionInDays ? data.RetentionInDays : null);
    }, [data])
    useEffect(() => {
        setApiResponse(null)
        setLoading(false)
        setError({error: false, message: undefined})
    }, [props])

    const handleUpdateRequest = async () => {
        try {
            setLoading(true);
            setError({error: false, message: undefined});
            setApiResponse(null)
            setReady(false)
            const params = {
                accountId: accountLengthAdjust(data.Account_AccountId),
                region: data.Region_RegionCode,
                loggroup: data.LogGroupName,
                retention: retention,
            }
            const {data: {data:apiResponseData}} = await service.monitoring().setRetentionPeriodForCloudWatch(params);
            setApiResponse(apiResponseData)
            setReady(true)
            props.onChange("update");
            setLoading(false);
        } catch (e) {
            let errorMessage = ''
            try{
                errorMessage = jsonParser(e.request.response).error;
            } catch (err) {
                errorMessage = err.message
            }

            setLoading(false);
            setLoading(false);
            setError({error: true, message: errorMessage ? errorMessage : e.message});
        }
    }
    return (
        <WuDialog open={open} title={"Update Retention Days"} onChange={(e) => props.onChange("close")}>
            <Box m={3} mb={2}>

                <TimeZoneSelector
                    error={false}
                    value={retention}
                    onChange={(e) => {
                        setRetention(e)
                    }}
                />
                <Box mt={2} display={"flex"} className={"gap"} alignItems={"center"}>
                    <Button onClick={handleUpdateRequest}
                            variant={"contained"}
                            disabled={loading}
                            disableElevation
                            color={"primary"}>
                        Save changes
                    </Button>

                    {
                        loading &&
                        <Box ml={1}>
                            <ProgressCircleCustomSize size={16}/>
                        </Box>
                    }
                </Box>
                <Box mt={2}>
                    <Collapse in={ready}>
                        <ResponseStatus response={apiResponse?.status ? apiResponse.status : {}}
                                        total={apiResponse?.total ? apiResponse.total : 0}
                                        failed={apiResponse?.failed ? apiResponse.failed : 0}/>
                    </Collapse>
                    <AlertWu alertOpen={{state: error.error}} severity={"error"} message={error.message}/>
                </Box>
            </Box>
        </WuDialog>
    )
}