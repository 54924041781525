import {Typography} from "@material-ui/core";

export function TitleNote({title, subTitle}) {
    return (
        <div>
            <Typography color={'textSecondary'}>
                {title}
            </Typography>
            <Typography variant={'body2'} gutterBottom>
                {subTitle}
            </Typography>
        </div>
    )
}