import React, {useEffect, useState} from "react";
import service from "../../../service/service";
import {AlertWu} from "../../AlertWu/AlertWu";
import TagComplianceMonitoring from "./TagComplianceMonitoring";
import {PaperProgress} from "../../ProgressCircle/ProgressCircle";
import {Chip, Paper, Typography} from "@material-ui/core";
import {useOrganization} from "../../../hooks/useOrganization";
import PageTitle from "../../General/PageTitle/PageTitle";
import {axiosErrorResponseParser} from "../../../helper/generalFunctions";
import WuSummaryCard from "../../General/WuSummaryCard";
import Box from "@material-ui/core/Box";


export default function TagComplianceHeader() {
    const {organization: masterAccountId} = useOrganization();
    const [originalData, setOriginalData] = useState({});
    const [ready, setReady] = useState(false);
    const [error, setError] = useState({error: false, message: undefined});
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (masterAccountId !== 'no org') {
            setError({error: false, message: undefined})
            setLoading(true);
            setReady(false);
            service.monitoring().getTotalCount({master_account_id: masterAccountId})
                .then(res => {
                    setOriginalData(res.data);
                    setReady(true);
                })
                .catch(err => {
                    const errorObject = axiosErrorResponseParser(err);
                    setError({error: true, message: errorObject ? errorObject.error : err.message})
                })
                .finally(() => setLoading(false))
        }
    }, [masterAccountId])

    return (
        <div className="title-div-wrapper">
            <Paper elevation={0}>
                <PageTitle title={'Tag Compliance Monitoring'} subTitle={'Resource with no tags'}/>
                <div className="anomaly-wrapper" style={{marginTop: 16}}>
                    <div className="cost-anomaly-monitor">
                        {

                            ready &&
                            <div>
                                <DetectionSummary
                                    tagCount={originalData.tag_count}/>
                                <TagComplianceMonitoring/>
                            </div>
                        }
                        {
                            loading && <PaperProgress/>
                        }

                        <div style={{paddingTop: 20}}>
                            <AlertWu severity="error" alertOpen={{state: error.error}}
                                     message={error.message}/>
                        </div>
                    </div>
                </div>
            </Paper>
        </div>
    )
}

function DetectionSummary({tagCount}) {
    const tagComplianceResource = [
        "EC2 Volume", "EC2 Instance", "ElasticLoadBalancing", "LoadBalancer", "Redshift", "Cluster",
        "AutoScaling", "RDS", "DBInstance", "ElasticLoadBalancingV2", "AutoScalingGroup", "S3 Bucket"
    ]
    return (
        <Box display={'flex'} className={'gap'}>
            <WuSummaryCard title={'Untagged Resources'}
                           value={tagCount ? tagCount : "0"}
                           description={'Total number of untagged resources'}/>
            <Paper style={{flexBasis: '100%', maxWidth: 800}} variant={'outlined'}>
                <Box m={2} display={'flex'} gridGap={12} gridRowGap={16} flexWrap={'wrap'} alignItems={'center'}
                     alignContent={'center'}>
                    <Typography variant={'h6'}> Resource Types: </Typography>
                    {
                        tagComplianceResource.map((resource, index) => {
                            return (
                                <Chip
                                    key={index}
                                    variant={"outlined"}
                                    label={resource}
                                />
                            )
                        })
                    }
                </Box>
            </Paper>
        </Box>
    )
}