import {
    DetailsPageCluster,
    DetailsPageService,
    DetailsPageTask,
    LaunchType,
    StatusComponent
} from "../GridColumComponents";
import moment from "moment";

export const columns = [
    {
        headerName: 'Task ARN',
        field: 'taskArn',
        sort: true,
        width: 170,
        cellRendererFramework: DetailsPageTask,
    },
    {
        headerName: `${moment().format('MMMM')} Price`,
        field: 'price',
        autoHeight: true,
        wrapText: true,
        valueFormatter: function ({value}) {
            return Number(value || 0).toFixed(2)
        },
        sort: true,
    },
    {
        headerName: `${moment().subtract('1', 'months').format('MMMM')} Price`,
        field: 'previousPrice',
        autoHeight: true,
        wrapText: true,
        valueFormatter: function ({value}) {
            return Number(value || 0).toFixed(2)
        },
        sort: true,
    },
    {
        headerName: `YTD Price`,
        field: 'yearlySpend',
        autoHeight: true,
        wrapText: true,
        valueFormatter: function ({value}) {
            return Number(value || 0).toFixed(2)
        },
        sort: true,
    },
    {
        headerName: 'Region',
        field: 'region',
        sort: true,
    },
    {
        headerName: 'Launch Type',
        field: 'launchType',
        sort: true,
        cellRendererFramework: LaunchType
    },
    {
        headerName: 'Service Arn',
        field: 'serviceArn',
        sort: true,
        width: 170,
        cellRendererFramework: DetailsPageService
    },
    {
        headerName: 'Cluster Arn',
        field: 'clusterArn',
        sort: true,
        width: 170,
        cellRendererFramework: DetailsPageCluster
    },
    {
        headerName: 'CPU Units',
        field: 'cpuUnits',
        valueFormatter: function ({value}) {
            return `${value}vCPU`
        },
        sort: true,
    },
    {
        headerName: 'Memory Units',
        valueFormatter: function ({value}) {
            return `${value}MiB`
        },
        field: 'memoryUnits',
        sort: true,
    },
    {
        headerName: 'Started On',
        field: 'startedAt',
        sort: true,
        valueFormatter: function ({value}) {
            return moment(value, 'YYYY-MM-DD HH:mm:ss.SSS').format('DD MMMM, YYYY')
        },
    },
    {
        headerName: 'Created On',
        field: 'createdAt',
        sort: true,
        valueFormatter: function ({value}) {
            return moment(value, 'YYYY-MM-DD HH:mm:ss.SSS').format('DD MMMM, YYYY')
        },
    },
]


export const containerDataColumns = [
    {
        headerName: 'Container',
        field: 'containerArn',
        sort: true,
    },
    {
        headerName: 'Image',
        field: 'image',
        sort: true,
    },
    {
        headerName: 'Status',
        field: 'lastStatus',
        sort: true,
        cellRendererFramework: StatusComponent
    },
    {
        headerName: 'CPU Units',
        field: 'cpu',
        valueFormatter: function ({value}) {
            return `${value || 0}vCPU`
        },
        sort: true,
    },
    {
        headerName: 'Memory Reservation',
        field: 'memoryReservation',
        valueFormatter: function ({value}) {
            return `${value || 0}MB`
        },
        sort: true,
    },
    {
        headerName: 'Private IP(s)',
        field: 'networkInterfaces',
        valueGetter: function ({data}) {
            return data?.networkInterfaces?.map(e => e?.privateIpv4Address).join(', ') || ''
        },
        sort: true,
    }
]