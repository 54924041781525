import React, {useEffect, useState} from "react";
import service from "../../../service/service";
import {jsonParser} from "../../../helper/generalFunctions";
import {ProgressCircleCustomSize} from "../../ProgressCircle/ProgressCircle";
import Typography from "@material-ui/core/Typography";
import {AlertWu} from "../../AlertWu/AlertWu";
import DataGrid from "../../General/DataGrid/DataGrid";
import {Button, Paper} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Confirm from "./Confirm";

let fixedHeader =   [
    {
        field: 'Security Group ID',
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
    },
    {
        field: 'Security Group Name'
    },
    {
        field: 'IP Range',
        headerName: 'Source IP Range'
    },
    {
        field: 'Port',
    },
    {
        field: 'Protocol'
    },
    {
        field: 'Region'
    },
    {
        field: 'Status'
    }]

export default function FlaggedResources({account, data = [], filter}) {
    const [loading, setLoading] = useState(false);
    const [ready, setReady] = useState(false);
    const [error, setError] = useState({error: false, message: undefined});
    const [flaggedResources, setFlaggedResources] = useState([]);
    const [header, setHeader] = useState(undefined);
    const [selectedResource, setSelectedResource] = useState([]);
    const [updateIpOpen, setUpdateIpOpen] = useState(false);

    useEffect(() => {
        fetchData()
    }, [data])

    const fetchData = async () => {
        try {
            setReady(false);
            setLoading(true);
            setHeader(undefined)
            setError({error: false, message: undefined})
            const params = {
                account_id: data.accountId,
                check_id: data.checkId,
                filter: filter,
                recommendation_type: 'security'
            }
            const {data: {data: results}} = await service.recommendations().getTrustedAdvisorFlaggedResource(params);
            if (data.checkId === '1iG5NDGVre') {
                setHeader(fixedHeader)
            }
            setFlaggedResources(results);
            setReady(true)
        } catch (e) {
            const errorBody = jsonParser(e.request.response);
            setError({error: true, message: errorBody ? errorBody.error : e.message})
        } finally {
            setLoading(false)
        }
    }


    const handleSecurityGroupCorrection = () => {
        setUpdateIpOpen(true)
    }


    return (
        <Box>
            {updateIpOpen &&
            <Confirm open={updateIpOpen} onChange={() => setUpdateIpOpen(false)} resources={selectedResource}
                     accountId={data.accountId}/>}
            <Paper variant={'outlined'}>
                {
                    ready &&
                    <DataGrid
                        fileName={`trusted-advisor-${account}`}
                        fixedColumn={header}
                        rowClickSelection={false}
                        gridRowSelection={'multiple'}
                        onChange={(e) => setSelectedResource(e)}
                        gridStyle={{height: '70vh'}}
                        gridData={flaggedResources}/>
                }
                {loading && <Loading checkName={account}/>}
                <AlertWu alertOpen={{state: error.error}} title={"Error"} severity={'error'}
                         message={error.message}/>
            </Paper>
            <Box m={2} ml={2} display={'flex'} className={'gap'} alignItems={'center'}>
                <Button variant={'contained'} disabled={selectedResource.length < 1}
                        disableElevation
                        color={'secondary'} onClick={handleSecurityGroupCorrection}>
                    Update Source IP range
                </Button>
                <Typography variant={'subtitle1'}>
                    {selectedResource.length} resources selected of {flaggedResources.length}
                </Typography>
            </Box>
        </Box>
    )
}

function Loading(
    {
        account
    }
) {
    return (
        <Box display={'flex'} alignItems={'center'} gridGap={8}>
            <ProgressCircleCustomSize size={16}/>
            <Typography>Loading resources for {account}...</Typography>
        </Box>
    )
}