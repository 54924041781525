import React, {useEffect, useState} from "react";
import WuDialog from "../../General/WuDialog";
import {Box, Button} from "@material-ui/core";
import {AlertWu} from "../../AlertWu/AlertWu";
import service from "../../../service/service";
import {ProgressCircleCustomSize} from "../../ProgressCircle/ProgressCircle";
import {accountLengthAdjust, jsonParser} from "../../../helper/generalFunctions";
import Typography from "@material-ui/core/Typography";


export default function PowerScheduleDelete(props) {
    const {open, data} = {...props};
    const [error, setError] = useState({error: false, message: undefined});
    const [ready, setReady] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleDeleteRequest = async () => {
        try {
            setReady(false);
            setLoading(true);
            setError({error: false, message: undefined});
            const params = {
                resource_id: data.ResourceId,
                resource: data.ResourceType,
                account_id: accountLengthAdjust(data.AccountId),
                region: data.Region
            }
            await service.automations().deletePowerScheduleV2(params);
            setReady(true);
            setLoading(false);
            setTimeout(() => {
                props.onChange('update');
            }, 2000)
        } catch (e) {
            const errorMessage = jsonParser(e.request.response).error;
            setLoading(false);
            setError({error: true, message: errorMessage ? errorMessage : e.message});
        }
    }


    useEffect(() => {
        setError({error: false, message: undefined});
        setReady(false);
        setLoading(false)
    }, [props])

    return (
        <WuDialog open={open}
                  title={"Delete Power Schedule"}
                  onChange={(e) => props.onChange("close")}>
            <Box m={3} mb={2}>
                <Typography>
                    Are you sure you want to delete this power scheduled resource?
                </Typography>
                <Box mt={2} display={"flex"} className={"gap"} alignItems={"center"}>
                    <Button onClick={handleDeleteRequest}
                            variant={"contained"}
                            style={{backgroundColor: "#ef2929", color: "#fff"}}
                            disabled={loading}
                            disableElevation
                            color={"primary"}>
                        Confirm remove
                    </Button>
                    {
                        loading &&
                        <Box ml={1}>
                            <ProgressCircleCustomSize size={16}/>
                        </Box>
                    }
                </Box>
                <Box mt={2}>
                    <AlertWu alertOpen={{state: ready}} severity={"success"} message={"Power Schedule Removed"}/>
                    <AlertWu alertOpen={{state: error.error}} severity={"error"} message={error.message}/>
                </Box>
            </Box>
        </WuDialog>
    )
}