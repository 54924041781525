import {costFormatter} from "../../../../helper/generalFunctions";

export const headers = [{
    field: "Account",
    resizable: true,
    sortable: true,
},
    {
        field: "ResourceId",
        resizable: true,
        sortable: true,
    },
    {
        field: "Name",
        resizable: true,
        sortable: true,
    },
    {
        field: "ApplicationName",
        resizable: true,
        sortable: true,
    },
    {
        field: "ApplicationOwner",
        resizable: true,
        sortable: true,
    },
    {
        field: "CostCenter",
        resizable: true,
        sortable: true,
    },
    {
        field: "Environment",
        resizable: true,
        sortable: true,
    },
    {
        field: "Total",
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
        type: "numericColumn",
        filter: "agNumberColumnFilter",
        formatter: costFormatter
    },]