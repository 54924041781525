import React, {useEffect, useState} from "react";
import {WuAccordionDetails, WuAccordionSummary, WuAccordionWrapper} from "../General";
import JSONPretty from "react-json-pretty";
import {CopyContent} from "./index";

export function TerraformTemplate({finopsTags = [], appData}) {
    const [tagBlock, setTagBlock] = useState(null)


    useEffect(() => {
        appData['CostCenter'] = appData?.['CostCode'] || null
        appData['ApplicationCode'] = appData?.['AppCode'] || null
        const keysAltered = []
        const newObject = finopsTags?.reduce((acc, cur) => {
            acc[cur.toLowerCase()] = cur
            keysAltered.push(cur.toLowerCase())
            return acc
        }, {})

        const statingAcc = keysAltered.includes('environment') ? {
            "Tags": [{
                Key: 'Environment',
                Value: null
            }]
        } : {"Tags": []}

        const tags = Object.entries(appData).reduce((acc, cur) => {
            if (keysAltered.includes(cur[0].toLowerCase())) {
                acc?.Tags.push({
                    Key: newObject[cur[0].toLowerCase()],
                    Value: cur[1] ? cur[1] : null
                })
            }
            return acc
        }, statingAcc)

        keysAltered.forEach((e) => {
            if (!tags.Tags.some(item => item.Key.toLowerCase() === e)) {
                tags.Tags.push({
                    Key: newObject[e],
                    Value: null
                })
            }
        })


        setTagBlock(tags)
    }, [finopsTags, appData])

    return (
        <>
            <WuAccordionWrapper TransitionProps={{unmountOnExit: true}} defaultExpanded>
                <WuAccordionSummary>
                    <TerraformImage/> Terraform - HCL
                </WuAccordionSummary>
                <WuAccordionDetails>
                    <div style={{padding: 16, position: 'relative', width: '100%'}}>
                        <HclTerraform tagBlock={tagBlock}/>
                    </div>
                </WuAccordionDetails>
            </WuAccordionWrapper>

            <WuAccordionWrapper TransitionProps={{unmountOnExit: true}}>
                <WuAccordionSummary>
                    <TerraformImage/> Terraform - JSON
                </WuAccordionSummary>
                <WuAccordionDetails>
                    <div style={{padding: 16, position: 'relative', width: '100%'}}>
                        <JsonTerraform tagBlock={tagBlock}/>
                    </div>
                </WuAccordionDetails>
            </WuAccordionWrapper>
        </>
    )
}

function TerraformImage() {
    return <div style={{width: 30}}>
        <img
            style={{width: 14}}
            alt={'cloudformation-logo'}
            src={'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjU2cHgiIGhlaWdodD0iMjkxcHgiIHZpZXdCb3g9IjAgMCAyNTYgMjkxIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIj4KICAgIDxnPgogICAgICAgIDxwb2x5Z29uIGZpbGw9IiM0MDQwQjIiIHBvaW50cz0iMTc2LjQ4NTA1NyAxODguOTk0MTYyIDI1Ni4wMDAzMDIgMTQzLjEyNzQ0MiAyNTYuMDAwMzAyIDUxLjI0ODU0NTMgMTc2LjQ4NTA1NyA5Ny4yMTIyMzU4Ij48L3BvbHlnb24+CiAgICAgICAgPHBhdGggZD0iTTg4LjI0MjUyODMsNTEuMjQ4NTQ1MyBMMTY3Ljc1Nzc3NCw5Ny4yMTIyMzU4IEwxNjcuNzU3Nzc0LDE4OC45OTQxNjIgTDg4LjI0MjUyODMsMTQzLjA3ODk1NyBNMCw5MS44MzA0MTEzIEw3OS41MTUyNDUzLDEzNy43NDU2MTcgTDc5LjUxNTI0NTMsNDUuOTE1MjA1NyBMMCwwIE04OC4yNDI1MjgzLDI0NC45OTQyMjggTDE2Ny43NTc3NzQsMjkwLjkwOTQzNCBMMTY3Ljc1Nzc3NCwxOTkuMDc5MDIzIEw4OC4yNDI1MjgzLDE1My4xNjM4MTciIGZpbGw9IiM1QzRFRTUiPjwvcGF0aD4KICAgIDwvZz4KPC9zdmc+'}
        />
    </div>
}

function HclTerraform({tagBlock}) {
    const [state, setState] = useState('')

    useEffect(() => {
        const tagNew = tagBlock?.Tags.reduce((acc, curr) => {
            acc += `  ${curr.Key} = "${curr.Value}"\n`
            return acc
        }, 'tags = {\n')
        setState(`${tagNew}}`)
    }, [tagBlock])

    return (
        <pre>
            <CopyContent content={state}/>
            <code>
                {state}
            </code>
        </pre>
    )
}

function JsonTerraform({tagBlock}) {

    const [state, setState] = useState({})

    useEffect(() => {
        const tagNew = tagBlock?.Tags.reduce((acc, curr) => {
            acc[curr.Key] = curr.Value
            return acc
        }, {})
        setState({tags: tagNew})
    }, [tagBlock])

    return (
        <>
            <CopyContent content={state}/>
            <JSONPretty json={state}/>
        </>

    )
}
