import React, { useState } from 'react';
import ReactApexChart from "react-apexcharts";
import {Paper} from "@material-ui/core";


export default function Graph({chartData}) {

    console.log(chartData.graph)
    const options = {
        series: [75],
        chart: {
            height: 350,
            type: 'radialBar',
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            radialBar: {
                hollow: {
                    margin: 0,
                    size: '70%',
                    background: '#fff',
                    image: undefined,
                    imageOffsetX: 0,
                    imageOffsetY: 0,
                    position: 'front',
                    dropShadow: {
                        enabled: true,
                        top: 0,
                        left: 3,
                        blur: 4,
                        opacity: 0.24
                    }
                },
                track: {
                    background: '#fff',
                    strokeWidth: '67%',
                    margin: 0, // margin is in pixels
                    dropShadow: {
                        enabled: true,
                        top: 0,
                        left: 3,
                        blur: 4,
                        opacity: 0.25
                    }
                },

                dataLabels: {
                    name: {
                        show: false
                    },
                    value: {
                        offsetY: 10,
                        fontSize: '36px'
                    }
                }
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'dark',
                type: 'horizontal',
                shadeIntensity: 0.5,
                gradientToColors: ['#3ae572'],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100]
            }
        },
        stroke: {
            lineCap: 'round'
        },
        labels: ['test'],
    };



    const barOption = {
        series: [{
            data: chartData.graph,
        }],
        noData: {
            text: chartData.noData,
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0
            },
        options: {
            chart: {
                height: 350,
                type: 'bar',
                stacked:false,
                toolbar:{
                    show:false
                },
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return "$"+val;
                },
            },
            colors: ['#0074ff', '#66DA26', '#546E7A', '#E91E63', '#FF9800', '#ffd900', '#00ff7e', '#ff5f4e', '#de00ff', '#727272'],
            grid:{
                show: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: "datetime"
            },
            legend:{
                show: true,
                position:'top',
                itemMargin:{
                    horizontal:20,
                    vertical: 20
                }
            },
            yaxis: {
                labels: {
                    formatter: function (value) {
                        return "$"+value;
                    }
                }
            },
        },
    };

    return (
        <div className="graph-wrapper">
            <Paper style={{paddingTop: 30, width: "100%"}} variant={"outlined"}>
                <p style={{margin: 0, fontWeight: 600, fontSize: 20, paddingLeft: 17, textAlign: "center"}}>Budget
                    Spend</p>
                <ReactApexChart options={options} series={chartData.percentage} type={"radialBar"} height={350}/>
                <div className="budget-summary">
                    <div className="d-f">
                        <p style={{flexBasis: "70%"}}>Amount : </p>
                        <p>${chartData.BudgetedAmount} </p>
                    </div>
                    <div className="d-f">
                        <p style={{flexBasis: "70%"}}>Current Spend : </p>
                        <p>${chartData.CurrentAmount} </p>
                    </div>
                    <div className="d-f">
                        <p style={{flexBasis: "70%"}}>Budget Variance : </p>
                        <p>${chartData.varience}</p>
                    </div>
                </div>
            </Paper>
            <Paper style={{paddingTop: 30, width: "100%"}} variant={"outlined"}>
                <p style={{margin: 0, fontWeight: 600, fontSize: 20, paddingLeft: 17, textAlign: "center"}}>
                    Budget History
                </p>
                <ReactApexChart options={barOption} series={barOption.series} noData ={barOption.noData} type={"bar"} height={350}/>
            </Paper>
        </div>


    )
}