import React from 'react';


export default function TagPolicyTable({targets}) {

    return (
        <div className="policy-item">
            <div>
                <p>{targets.Name}</p>
                <span>{targets.Type} | AWS account # {targets.TargetId} | {targets.Arn} </span>
            </div>
        </div>
    );
}