import React, {useState} from "react";
import Box from "@material-ui/core/Box";
import {FormGroup, Paper} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

import profileCoverPhone from "../../../assets/images/profile_cover.jpg";
import profilePhoto from "../../../assets/images/profile.jpeg";
import WuTextField from "../../General/WuTextFeild";
import FormControl from "@material-ui/core/FormControl";

export default function Profile() {

    const coverBackGround = {
        backgroundImage: `url(${profileCoverPhone})`
    }


    return (
        <Box className="setting-profile">
            <Box className="cover-photo" style={coverBackGround}>
                <Paper className="profile-photo">
                    <img src={profilePhoto} alt="profile-image"/>
                </Paper>
                <Box className="profile-content">
                    <Box className="profile-title">
                        <Typography variant={"h5"}> Hi, Jane Doe</Typography>
                        <Typography variant={"subtitle1"}>Finops standard user</Typography>
                    </Box>
                    <Paper variant={"outlined"} className="profile-actions">
                        <ResetPassword/>
                    </Paper>
                </Box>
            </Box>
        </Box>
    )
}


function ResetPassword(props) {
    const [error, setError] = useState({
        currentPassword: true,
        newPassword: true,
        confirmPassword: true
    })

    const [passwordField, setPasswordField] = useState({
        currentPassword: undefined,
        newPassword: undefined,
        confirmPassword: undefined
    })

    const handlePasswordUpdate = (event) => {
        setPasswordField({...passwordField, [event.target.name]: event.target.value});
        errorUpdate(event.target.name, event.target.value)
    }

    const errorUpdate = (event, value) => {
        setError({...error, [event]: value.length < 0});
    }

    return (
        <FormControl fullWidth component="fieldset">
            <Typography variant={"h5"}> Reset Password</Typography>
            <FormGroup>
                <Box mt={2} mb={1} display={"flex"} style={{gap: 20}}>
                    <WuTextField name={"currentPassword"}
                                 error={error.confirmPassword}
                                 onChange={handlePasswordUpdate}
                                 label={"Current Password"}/>
                    <WuTextField name={"newPassword"}
                                 error={error.newPassword}
                                 onChange={handlePasswordUpdate}
                                 label={"New Password"}/>
                    <WuTextField name={"confirmPassword"}
                                 error={error.confirmPassword}
                                 onChange={handlePasswordUpdate}
                                 label={"Confirm Password"}/>
                </Box>
            </FormGroup>
        </FormControl>
    )
}