import {Link} from "react-router-dom";
import BreadCrumbNavigation from "../BreadCrumbNavigation";
import DetailsOrganizationWise from "./DetailsOrganizationWise";
import {useState} from "react";

export default function Details() {
    const [breadCrumb, setBreadCrumb] = useState([
        <Link key="1" to="/tagging">
            tagging
        </Link>,
        <Link key="2" to="/tagging/tag-compliance">
            tag compliance
        </Link>,
        <Link key="3" to={"/tagging/tag-compliance/partial-tag"}>
            partial-tags
        </Link>
    ])


    return (
        <div className={'tc-details-wrapper'} style={{marginLeft: -30, marginTop: -24}}>
            <div className={'tc-details-wrapper-bread-crumb'}>
                <BreadCrumbNavigation breadcrumbs={breadCrumb}/>
            </div>
            <div className={'tc-details-container'}>
                <DetailsOrganizationWise onNavigationChange={setBreadCrumb}/>
            </div>
        </div>
    )
}