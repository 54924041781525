import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CircularProgress from '@material-ui/core/CircularProgress';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {green, orange} from '@material-ui/core/colors';
import service from "../../../service/service";
import TagComplianceTable from "./TagComplianceTable"
import {AlertWu} from '../../AlertWu/AlertWu';
import Box from '@material-ui/core/Box';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {useOrganization} from "../../../hooks/useOrganization";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        flexBasis: '10',
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightBold,
    },
    subHeading: {
        flexBasis: '33.33%',
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightRegular,
    }
}));

export default function TagComplianceMonitoring() {
    const {organization: masterAccountId} = useOrganization()
    const classes = useStyles();
    const [untagged, setuntagged] = useState([]);
    const [loading, setLoading] = useState(false);
    const [statusColor, setStatusColor] = useState(orange[400]);
    const [loadStatus, setLoadStatus] = useState(0);
    const [errorBoundary, setErrorBoundary] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        try {
            if (masterAccountId !== 'no org') {
                service.monitoring().listTagAccount({master_account_id: masterAccountId})
                    .then((ref) => {
                        setuntagged(ref.data.account);
                        setLoading(true);
                        setErrorBoundary(false);
                    })
                    .catch((err) => {
                        console.log(err)
                        setLoading(true);
                        setErrorBoundary(true);
                    })
            }

        } catch {
            setLoading(true);
            setErrorBoundary(true);
        }
    }, [masterAccountId])

    function AdvisorLoading() {
        return (
            <div className="spinner-wrapper">
                <CircularProgress size={15} color="secondary"/>
                <span>
                    {loadStatus === 0 ? "Retrieving account information..." : "Collecting Tag Compliance Data..."}
                    </span>
            </div>
        )
    }


    return (
        <div className={classes.root}>
            <Box display='flex' m={3}>
                <Typography style={{flexBasis: '32.23%', fontWeight: '900'}} variant={'subtitle2'}>Account Alias
                    (Account Id)</Typography>
                <Typography style={{fontWeight: '900'}} variant={'subtitle2'}>Number of Untagged Resources</Typography>
            </Box>
            <div className={classes.root}>
                {loading && !errorBoundary ?
                    untagged.map(function (untagged, idx) {
                        return (
                            <Accordion key={untagged.account_id}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="panel1a-content"
                                    key={idx}
                                >

                                    <InfoOutlinedIcon style={{paddingRight: 20, color: statusColor}}/>
                                    <Typography
                                        className={classes.subHeading}>{untagged.account_alias}({untagged.account_id})</Typography>
                                    <Typography className={classes.subHeading}>{untagged.count}</Typography>
                                </AccordionSummary>


                                <AccordionDetails>
                                    {/* populate data to table */}
                                    <TagComplianceTable
                                        account_id={untagged.account_id}/>
                                </AccordionDetails>


                            </Accordion>
                        )
                    }) : !loading && <AdvisorLoading/>
                }

                {
                    errorBoundary &&
                    <AlertWu severity="error"
                             message={"We're having difficulties handling this request. Error: " + error}/>
                }
            </div>
        </div>
    );
}