import DataGrid from "../../General/DataGrid";
import AdditionalLandingInfo from "./AdditionalLandingInfo";
import {gridDefinition} from "./grid_definition";
import {InfoOutlined} from "@material-ui/icons";
import {IconButton} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import AdvancedInformation from "./AdvancedInformation";
import WuLoading from "../../General/WuLoading";
import Box from "@material-ui/core/Box";

export default function Landing({loading, onboardingData, finopsTags}) {
    return (
        <div className={'rt-landing-wrapper'}>
            <div className={'rt-grid-wrapper'}>
                {loading.ready && <DataGrid gridData={onboardingData}
                                            fixedColumn={gridDefinition}
                                            gridStyle={{height: 'calc(100vh - 120px)'}}
                />}
                {
                    loading.loading && <Box m={2.5}><WuLoading open={true} message={'Please wait....'}/></Box>
                }
            </div>
            <AdditionalLandingInfo/>
            {loading.ready && <AdvancedInformation applicationList={onboardingData} finopsTags={finopsTags}/>}
        </div>
    )
}


export function AdditionInfoCellRender({data}) {
    const history = useHistory()
    const handleClick = () => {
        history.push(`/find-resource-tags?whereId=${data.OnBoardingId + 6589}&&whereName=${data.ApplicationName}`)
    }

    return (
        <IconButton size={'small'} onClick={handleClick}>
            <InfoOutlined fontSize={'small'}/>
        </IconButton>
    )
}