import React, {useState} from 'react';
import Search from "../../Automation/PowerSchedule/BatchPowerSchedule/Search";
import {Paper, Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import WuToolTip from "../../General/WuToolTip";
import IconButton from "@material-ui/core/IconButton";
import {ArrowBackIosRounded} from "@material-ui/icons";
import WuStack from "../../General/WuStack";
import WuButton from "../../General/WuButton";

export default function ResourceManagement() {
    const [selectedResources, setSelectedResources] = useState([])
    const [apiProgress, setApiProgress] = useState({ready: false, loading: false, error: false})

    const handleResourceAction = () => {
        try {
            setApiProgress({loading: true, ready: false, error: false})
        } catch (e) {

        }
    }
    return (
        <Paper elevation={0} className={'resource-management-container'}>
            <Box ml={0} mt={2} mb={2}>
                <Typography variant={'h6'}>
                    Resource Management
                </Typography>
            </Box>
            <Paper variant={'outlined'}>
                <Search onChange={() => console.log('test')}
                        height={'60vh'}
                        customAction={true}
                        setSelectedResources={setSelectedResources}/>
            </Paper>
        </Paper>

    )
}

function ResourceManagementAction(props) {
    const handleOperation = (e) => {
        props.onClick(e)
    }

    return (
        <Box className={'gap'} display={'flex'}>
            <WuToolTip title={'Go Back'}>
                <IconButton size={'small'} onClick={() => props.onChange()} style={{height: 32, width: 32}}>
                    <ArrowBackIosRounded/>
                </IconButton>
            </WuToolTip>
            <WuStack spacing={2}>
                <WuButton onClick={() => handleOperation('start')}>Start</WuButton>
                <WuButton onClick={() => handleOperation('stop')} style={{background: 'red', color: 'white'}}>
                    Stop
                </WuButton>
            </WuStack>
        </Box>
    )
}

