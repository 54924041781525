import React, {useEffect, useState} from 'react';
import WuDialog from "../../../General/WuDialog";
import {useOrganization} from "../../../../hooks/useOrganization";
import Box from "@material-ui/core/Box";
import WuLoading from "../../../General/WuLoading";
import DataGrid from "../../../General/DataGrid/DataGrid";
import {Collapse} from "@material-ui/core";
import service from "../../../../service/service";
import WuAlert from "../../../General/WuAlert";
import {jsonParser} from "../../../../helper/generalFunctions";
import {headers} from './headerFields';

export default function ResourceIdBasedReport(props) {
    const {open, resourceId, resourceCondition} = {...props}
    const {organization: masterAccountId} = useOrganization();
    const [apiProgress, setApiProgress] = useState({ready: false, loading: false, error: false})
    const [responseData, setResponseData] = useState({reportData: [], header: []});

    const fetchData = async () => {
        try {
            setApiProgress({ready: false, loading: true, error: false})
            const params = {
                "master_account_id": masterAccountId,
                "resource_id": resourceId,
                "key_condition": resourceCondition
            }
            const {data: {data}} = await service.buReport().getSingleResourceDetails(params);
            const extraHeader = data.extraHeader.map(item => {
                return {
                    field: item,
                    resizable: true,
                    sortable: true,
                    suppressAutoSize: false,
                }
            })
            setResponseData({header: [...headers, ...extraHeader], reportData: data.reportData})
            setApiProgress({ready: true, loading: false, error: false})
        } catch (e) {
            const error = jsonParser(e.request.response).error;
            setApiProgress({ready: false, loading: false, error: error ? error : e.message})
        }
    }

    useEffect(() => {
        if (open) {
            if (masterAccountId !== 'no org') {
                fetchData()
            }
        }
    }, [open])

    return (
        <WuDialog open={open}
                  onChange={() => props.onChange(false)}
                  title={`YTD Report - ${resourceId}`}
                  size={apiProgress.ready ? 'xl' : 'sm'}>
            <Collapse in={apiProgress.loading}>
                <Box m={2}>
                    <WuLoading open={apiProgress.loading} message={'Loading Resources. Please wait...'}/>
                </Box>
            </Collapse>

            {
                apiProgress.ready && <DataGrid gridData={responseData.reportData} fixedColumn={responseData.header}
                                               gridStyle={{height: '75vh'}}/>
            }

            <WuAlert error={Boolean(apiProgress.error)}
                     successMessage={''}
                     errorMessage={apiProgress.error}
                     success={false}/>

        </WuDialog>
    )
}