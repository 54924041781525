export const accountTrend = {
    "code": 200,
    "success": true,
    "data":
        [{
            "AccountId": "840944450766",
            "2022-03-01": {"changePercentage": 100, "spend": 402.07},
            "2022-03-02": {"changePercentage": -22.79, "spend": 327.45},
            "2022-03-03": {"changePercentage": -2.17, "spend": 320.48},
            "2022-03-04": {"changePercentage": 1.1, "spend": 324.06},
            "2022-03-05": {"changePercentage": -5.95, "spend": 305.87},
            "2022-03-06": {"changePercentage": -2.38, "spend": 298.75},
            "2022-03-07": {"changePercentage": 8.16, "spend": 325.3},
            "2022-03-08": {"changePercentage": 0.17, "spend": 325.84},
            "2022-03-09": {"changePercentage": -0.64, "spend": 323.77},
            "2022-03-10": {"changePercentage": -0.2, "spend": 323.13},
            "2022-03-11": {"changePercentage": 0.01, "spend": 323.15},
            "2022-03-12": {"changePercentage": -1.8, "spend": 317.43},
            "2022-03-13": {"changePercentage": -6.57, "spend": 297.86},
            "2022-03-14": {"changePercentage": 9.53, "spend": 329.22},
            "2022-03-15": {"changePercentage": -2.71, "spend": 320.52},
            "2022-03-16": {"changePercentage": 4.3, "spend": 334.92},
            "2022-03-17": {"changePercentage": -0.93, "spend": 331.84},
            "2022-03-18": {"changePercentage": -1.97, "spend": 325.42},
            "2022-03-19": {"changePercentage": -6.28, "spend": 306.2},
            "2022-03-20": {"changePercentage": -1.19, "spend": 302.59},
            "2022-03-21": {"changePercentage": 8.14, "spend": 329.42},
            "2022-03-22": {"changePercentage": -1.19, "spend": 325.54},
            "AccountAlias": "acctgftest"
        }, {
            "AccountId": "418068139254",
            "2022-03-01": {"changePercentage": 100, "spend": 96.98},
            "2022-03-02": {"changePercentage": -55.44, "spend": 62.39},
            "2022-03-03": {"changePercentage": 1.55, "spend": 63.37},
            "2022-03-04": {"changePercentage": -0.38, "spend": 63.13},
            "2022-03-05": {"changePercentage": -6.21, "spend": 59.44},
            "2022-03-06": {"changePercentage": -0.75, "spend": 59},
            "2022-03-07": {"changePercentage": 3.06, "spend": 60.86},
            "2022-03-08": {"changePercentage": -2.54, "spend": 59.35},
            "2022-03-09": {"changePercentage": 0.98, "spend": 59.94},
            "2022-03-10": {"changePercentage": 0.58, "spend": 60.29},
            "2022-03-11": {"changePercentage": 0.99, "spend": 60.89},
            "2022-03-12": {"changePercentage": -2.68, "spend": 59.3},
            "2022-03-13": {"changePercentage": -0.03, "spend": 59.28},
            "2022-03-14": {"changePercentage": 1.04, "spend": 59.9},
            "2022-03-15": {"changePercentage": -2.46, "spend": 58.46},
            "2022-03-16": {"changePercentage": 2.08, "spend": 59.7},
            "2022-03-17": {"changePercentage": 2.4, "spend": 61.17},
            "2022-03-18": {"changePercentage": -0.76, "spend": 60.71},
            "2022-03-19": {"changePercentage": -0.13, "spend": 60.63},
            "2022-03-20": {"changePercentage": -1.03, "spend": 60.01},
            "2022-03-21": {"changePercentage": 2.57, "spend": 61.59},
            "2022-03-22": {"changePercentage": 2.25, "spend": 63.01},
            "AccountAlias": "acctnprd"
        }, {
            "AccountId": "923660381271",
            "2022-03-01": {"changePercentage": 100, "spend": 3513.84},
            "2022-03-02": {"changePercentage": -78.81, "spend": 1965.11},
            "2022-03-03": {"changePercentage": -0.12, "spend": 1962.68},
            "2022-03-04": {"changePercentage": 0.82, "spend": 1978.9},
            "2022-03-05": {"changePercentage": -10.43, "spend": 1791.96},
            "2022-03-06": {"changePercentage": -5.61, "spend": 1696.83},
            "2022-03-07": {"changePercentage": 10.07, "spend": 1886.79},
            "2022-03-08": {"changePercentage": 2.42, "spend": 1933.49},
            "2022-03-09": {"changePercentage": -0.8, "spend": 1918.12},
            "2022-03-10": {"changePercentage": -0.08, "spend": 1916.5},
            "2022-03-11": {"changePercentage": 1.34, "spend": 1942.47},
            "2022-03-12": {"changePercentage": -2.56, "spend": 1894.04},
            "2022-03-13": {"changePercentage": -11.68, "spend": 1695.9},
            "2022-03-14": {"changePercentage": 11.79, "spend": 1922.61},
            "2022-03-15": {"changePercentage": 0.99, "spend": 1941.89},
            "2022-03-16": {"changePercentage": -1.22, "spend": 1918.5},
            "2022-03-17": {"changePercentage": 0.04, "spend": 1919.2},
            "2022-03-18": {"changePercentage": 0.14, "spend": 1921.83},
            "2022-03-19": {"changePercentage": -12.85, "spend": 1702.96},
            "2022-03-20": {"changePercentage": -1.43, "spend": 1678.9},
            "2022-03-21": {"changePercentage": 23.04, "spend": 2181.48},
            "2022-03-22": {"changePercentage": 18.37, "spend": 2672.53},
            "AccountAlias": "acctprd"
        }, {
            "AccountId": "78542370465",
            "2022-03-01": {"changePercentage": 100, "spend": 285.28},
            "2022-03-02": {"changePercentage": -37.98, "spend": 206.75},
            "2022-03-03": {"changePercentage": -2.16, "spend": 202.37},
            "2022-03-04": {"changePercentage": 1.39, "spend": 205.22},
            "2022-03-05": {"changePercentage": -7.29, "spend": 191.28},
            "2022-03-06": {"changePercentage": -3.09, "spend": 185.54},
            "2022-03-07": {"changePercentage": 10.43, "spend": 207.14},
            "2022-03-08": {"changePercentage": -0.29, "spend": 206.54},
            "2022-03-09": {"changePercentage": 2.47, "spend": 211.77},
            "2022-03-10": {"changePercentage": -0.7, "spend": 210.29},
            "2022-03-11": {"changePercentage": -2.45, "spend": 205.27},
            "2022-03-12": {"changePercentage": -2.51, "spend": 200.24},
            "2022-03-13": {"changePercentage": -3.84, "spend": 192.84},
            "2022-03-14": {"changePercentage": 7.09, "spend": 207.55},
            "2022-03-15": {"changePercentage": -1.9, "spend": 203.69},
            "2022-03-16": {"changePercentage": 3.28, "spend": 210.59},
            "2022-03-17": {"changePercentage": 0.85, "spend": 212.39},
            "2022-03-18": {"changePercentage": -1.86, "spend": 208.51},
            "2022-03-19": {"changePercentage": -7.5, "spend": 193.96},
            "2022-03-20": {"changePercentage": -3.02, "spend": 188.28},
            "2022-03-21": {"changePercentage": 11.34, "spend": 212.35},
            "2022-03-22": {"changePercentage": -3.94, "spend": 204.3},
            "AccountAlias": "acctqa"
        }, {
            "AccountId": "259140104160",
            "2022-03-01": {"changePercentage": 100, "spend": 11114.82},
            "2022-03-02": {"changePercentage": -73.47, "spend": 6407.25},
            "2022-03-03": {"changePercentage": 2.07, "spend": 6542.77},
            "2022-03-04": {"changePercentage": -1.51, "spend": 6445.54},
            "2022-03-05": {"changePercentage": 13.59, "spend": 7459.34},
            "2022-03-06": {"changePercentage": -4.25, "spend": 7155.48},
            "2022-03-07": {"changePercentage": -18.47, "spend": 6039.84},
            "2022-03-08": {"changePercentage": -0.47, "spend": 6011.84},
            "2022-03-09": {"changePercentage": -1.7, "spend": 5911.14},
            "2022-03-10": {"changePercentage": -3.39, "spend": 5717.25},
            "2022-03-11": {"changePercentage": 1.9, "spend": 5827.75},
            "2022-03-12": {"changePercentage": -4.87, "spend": 5557.04},
            "2022-03-13": {"changePercentage": -1.75, "spend": 5461.69},
            "2022-03-14": {"changePercentage": 4, "spend": 5689.55},
            "2022-03-15": {"changePercentage": 0.58, "spend": 5722.91},
            "2022-03-16": {"changePercentage": 2.38, "spend": 5862.26},
            "2022-03-17": {"changePercentage": -0.65, "spend": 5824.6},
            "2022-03-18": {"changePercentage": -1, "spend": 5766.69},
            "2022-03-19": {"changePercentage": -2.87, "spend": 5606.04},
            "2022-03-20": {"changePercentage": -1.63, "spend": 5515.98},
            "2022-03-21": {"changePercentage": 4.57, "spend": 5779.85},
            "2022-03-22": {"changePercentage": -29.76, "spend": 4454.26},
            "AccountAlias": "acctuat"
        }]
}

export const accountTrendResource = {
    "code": 200,
    "success": true,
    "data": [{"Date": "2022-03-22", "Cost": "66.49888937139993", "Resource": "AmazonSageMaker"}, {
        "Date": "2022-03-21",
        "Cost": "63.78149011579996",
        "Resource": "AmazonSageMaker"
    }, {"Date": "2022-03-21", "Cost": "11.760000000000003", "Resource": "AmazonMWAA"}, {
        "Date": "2022-03-22",
        "Cost": "11.270000000000003",
        "Resource": "AmazonMWAA"
    }, {"Date": "2022-03-22", "Cost": "11.140960000000002", "Resource": "AWSCloudTrail"}, {
        "Date": "2022-03-21",
        "Cost": "10.396239999999999",
        "Resource": "AWSCloudTrail"
    }, {"Date": "2022-03-21", "Cost": "9.293516230899984", "Resource": "AmazonVPC"}, {
        "Date": "2022-03-22",
        "Cost": "9.291579925799985",
        "Resource": "AmazonVPC"
    }, {"Date": "2022-03-22", "Cost": "5.58730695", "Resource": "AmazonDynamoDB"}, {
        "Date": "2022-03-21",
        "Cost": "5.587306741699998",
        "Resource": "AmazonDynamoDB"
    }, {"Date": "2022-03-21", "Cost": "3.677014801100005", "Resource": "AmazonEC2"}, {
        "Date": "2022-03-22",
        "Cost": "3.0405190684",
        "Resource": "AmazonGuardDuty"
    }, {"Date": "2022-03-22", "Cost": "2.907909657300003", "Resource": "AmazonEC2"}, {
        "Date": "2022-03-21",
        "Cost": "2.641746008700001",
        "Resource": "AmazonGuardDuty"
    }, {"Date": "2022-03-21", "Cost": "2.400000000000001", "Resource": "AmazonEKS"}, {
        "Date": "2022-03-21",
        "Cost": "2.36976",
        "Resource": "AmazonECS"
    }, {"Date": "2022-03-22", "Cost": "2.3594344446000015", "Resource": "AmazonDetective"}, {
        "Date": "2022-03-22",
        "Cost": "2.3276894970999993",
        "Resource": "AmazonCloudWatch"
    }, {"Date": "2022-03-22", "Cost": "2.3000000000000007", "Resource": "AmazonEKS"}, {
        "Date": "2022-03-22",
        "Cost": "2.271020091599999",
        "Resource": "AmazonECS"
    }, {"Date": "2022-03-21", "Cost": "2.2684395026999997", "Resource": "AmazonCloudWatch"}, {
        "Date": "2022-03-21",
        "Cost": "1.9923674554000004",
        "Resource": "AmazonDetective"
    }, {"Date": "2022-03-22", "Cost": "1.9302438948999971", "Resource": "AWSSecurityHub"}, {
        "Date": "2022-03-22",
        "Cost": "1.7909999999999988",
        "Resource": "AWSConfig"
    }, {"Date": "2022-03-21", "Cost": "1.6415840589999973", "Resource": "AWSSecurityHub"}],
    "graphData": [{
        "x": "AmazonSageMaker",
        "y": 66.5,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "AmazonSageMaker",
        "y": 63.78,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "AmazonMWAA",
        "y": 11.76,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "AmazonMWAA",
        "y": 11.27,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "AWSCloudTrail",
        "y": 11.14,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "AWSCloudTrail",
        "y": 10.4,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "AmazonVPC",
        "y": 9.29,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "AmazonVPC",
        "y": 9.29,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "AmazonDynamoDB",
        "y": 5.59,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "AmazonDynamoDB",
        "y": 5.59,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "AmazonEC2",
        "y": 3.68,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "AmazonGuardDuty",
        "y": 3.04,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "AmazonEC2",
        "y": 2.91,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "AmazonGuardDuty",
        "y": 2.64,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "AmazonEKS",
        "y": 2.4,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "AmazonECS",
        "y": 2.37,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "AmazonDetective",
        "y": 2.36,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "AmazonCloudWatch",
        "y": 2.33,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "AmazonEKS",
        "y": 2.3,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "AmazonECS",
        "y": 2.27,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "AmazonCloudWatch",
        "y": 2.27,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "AmazonDetective",
        "y": 1.99,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "AWSSecurityHub",
        "y": 1.93,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "AWSConfig",
        "y": 1.79,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "AWSSecurityHub",
        "y": 1.64,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }]
}

export const accountTrendApplication = {
    "code": 200,
    "success": true,
    "data": [{"Date": "2022-03-22", "Cost": "98.29999157060007", "ApplicationName": "Tampflex"}, {
        "Date": "2022-03-21",
        "Cost": "94.48213501500014",
        "ApplicationName": "Tampflex"
    }, {
        "Date": "2022-03-21",
        "Cost": "17.49677419439999",
        "ApplicationName": "Cardguard"
    }, {
        "Date": "2022-03-22",
        "Cost": "16.7677419363",
        "ApplicationName": "Holdlamis"
    },
        {
            "Date": "2022-03-21",
            "Cost": "5.8322580647999995",
            "ApplicationName": "AI"
        },
        {
            "Date": "2022-03-22",
            "Cost": "5.5892473120999995",
            "ApplicationName": "Prodder"
        },
        {
            "Date": "2022-03-21",
            "Cost": "2.400000000000001",
            "ApplicationName": "Cookley"
        },
        {
            "Date": "2022-03-22",
            "Cost": "2.3000000000000007",
            "ApplicationName": "Job"
        }, {
            "Date": "2022-03-22",
            "Cost": "1.5901422271000005",
            "ApplicationName": "Zathin"
        }, {"Date": "2022-03-21", "Cost": "1.2644501118999996", "ApplicationName": "EMRBATCH"}, {
            "Date": "2022-03-22",
            "Cost": "1.1676686864999997",
            "ApplicationName": "Daltfresh"
        }, {
            "Date": "2022-03-21",
            "Cost": "0.8928059610999999",
            "ApplicationName": "Tres-Zap"
        }, {
            "Date": "2022-03-22",
            "Cost": "0.8928058479000001",
            "ApplicationName": "Home Ing"
        }, {"Date": "2022-03-21", "Cost": "0.32258064480000015", "ApplicationName": "Ventosanzap"}, {
            "Date": "2022-03-22",
            "Cost": "0.20161290300000004",
            "ApplicationName": "Bigtax"
        }, {"Date": "2022-03-21", "Cost": "0.1612903224", "ApplicationName": "Lotstring"}, {
            "Date": "2022-03-22",
            "Cost": "0.10080645149999996",
            "ApplicationName": "Alphazap"
        }, {"Date": "2022-03-21", "Cost": "0.09677419200000006", "ApplicationName": "Viva"}, {
            "Date": "2022-03-22",
            "Cost": "0.06048386999999997",
            "ApplicationName": "Wrapsafe"
        }, {"Date": "2022-03-21", "Cost": "0.03281006399999999", "ApplicationName": "Alphazap"}],
    "graphData": [{
        "x": "Tampflex",
        "y": 98.3,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "Cardguard",
        "y": 94.48,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "Holdlamis",
        "y": 17.5,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "AI",
        "y": 16.77,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "Cookley",
        "y": 5.83,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "Prodder",
        "y": 5.59,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "Job",
        "y": 2.4,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "Zathin",
        "y": 2.3,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "AI/ML - Data Organization",
        "y": 1.59,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "Daltfresh",
        "y": 1.26,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "Daltfresh",
        "y": 1.17,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "Daltfresh",
        "y": 0.89,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "Daltfresh",
        "y": 0.89,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "Tres-Zap",
        "y": 0.32,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "Tres-Zap",
        "y": 0.2,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "Home Ing",
        "y": 0.16,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "Home Ing",
        "y": 0.1,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "Bigtax",
        "y": 0.1,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "Bigtax",
        "y": 0.06,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }, {
        "x": "Alphazap",
        "y": 0.03,
        "goals": [{"name": "Expected", "value": 20, "strokeWidth": 5, "strokeColor": "#775DD0"}]
    }]
}

export const accountTrendDailyService = {
    "code": 200,
    "success": true,
    "data": [{
        "ResourceId": "arn:aws:sagemaker:us-east-2:840944450766:notebook-instance/test-notebook1",
        "Date": "2022-03-22",
        "Cost": "10.256639784600004",
        "ApplicationName": "Cookley",
        "Service": "AmazonSageMaker",
        "CostCenter": "8888",
        "Environment": "PROD"
    }, {
        "ResourceId": "arn:aws:sagemaker:us-east-1:840944450766:notebook-instance/test-notebook1",
        "Date": "2022-03-22",
        "Cost": "9.285198925300001",
        "ApplicationName": "Cookley",
        "Service": "AmazonSageMaker",
        "CostCenter": "8888",
        "Environment": "PROD"
    }, {
        "ResourceId": "arn:aws:sagemaker:us-east-1:840944450766:endpoint/test-endpoint",
        "Date": "2022-03-22",
        "Cost": "5.5892473121",
        "ApplicationName": "Cookley",
        "Service": "AmazonSageMaker",
        "CostCenter": "8888",
        "Environment": "UAT"
    }]
}

export const accountTrendDailyApplication = {
    "code": 200,
    "success": true,
    "data": [{
        "ResourceId": "arn:aws:airflow:us-east-1:840944450766:environment/test-poc-9",
        "Date": "2022-03-22",
        "Cost": "11.270000000000003",
        "ApplicationName": "Tampflex",
        "ProductCode": "AmazonMWAA",
        "CostCenter": "5858",
        "Environment": "PROD"
    }, {
        "ResourceId": "arn:aws:airflow:us-east-1:840944450766:environment/test-poc-12",
        "Date": "2022-03-22",
        "Cost": "11.140959999999994",
        "ApplicationName": "Tampflex",
        "ProductCode": "AWSCloudTrail",
        "CostCenter": "5858",
        "Environment": "PROD"
    }, {
        "ResourceId": "arn:aws:sagemaker:us-east-2:840944450766:notebook-instance/test-instance",
        "Date": "2022-03-22",
        "Cost": "10.256639784600003",
        "ApplicationName": "Tampflex",
        "ProductCode": "AmazonSageMaker",
        "CostCenter": "5858",
        "Environment": "PROD"
    }]
}