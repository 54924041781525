import React, {useState} from "react";
import {Box, Divider, Paper, Typography} from "@material-ui/core";
import {ErrorOutline, LocalOfferOutlined} from "@material-ui/icons";
import {DataGrid, WuButton, WuDialog, WuStack} from "../../General";
import {uploadGridColumnDefinition} from "../grid-defenition";


const rowClassRule = {
    'at-invalid-resource': function ({data}) {
        return Object.keys(data?._meta?.errors || {}).length > 0;
    },
}


export function UploadPreview(props) {
    const {
        resources,
        gridHeader,
        selectedResources,
        onSelectionChange,
        open,
        onClose,
        onTag,
        invalidResourceCount,
        totalProcessedResources
    } = {...props}

    const gridApi = null

    const handleRowRerender = ({api}) => {
        if (api && gridApi) {
            gridApi.forEachNode((node) => {
                const {data: {ResourceARN}} = {...node}
                const exist = selectedResources.some(({ResourceARN: localSelectedResource}) => localSelectedResource === ResourceARN)
                node.setSelected(exist)
            })
        }
    }

    const handleClose = () => {
        onSelectionChange([])
        onClose()
    }
    return (
        <WuDialog fullScreen={true}
                  title={'Upload preview'}
                  suppressDialogAction={true}
                  onChange={handleClose} open={open}>
            <ActionTab
                selectedResources={selectedResources}
                onRevalidateClick={false}
                invalidApplicationCount={invalidResourceCount || 0}
                gridData={resources}
                totalResources={totalProcessedResources}
                enforce={true}
                onTagClick={onTag}/>
            <Divider/>
            <DataGrid
                gridRef={gridApi}
                onSelectionChange={(e) => onSelectionChange(e)}
                gridRowSelection={'multiple'}
                gridData={resources}
                fixedColumn={uploadGridColumnDefinition(gridHeader)}
                gridStyle={{height: 'calc(100vh - 168px)'}}
                rowClassRules={rowClassRule}
                onRowDataChanged={handleRowRerender}
                rowClickSelection={false}
            />
        </WuDialog>
    )
}

function ActionTab({onTagClick, invalidApplicationCount, selectedResources, totalResources}) {
    return (
        <Box display={'flex'} justifyContent={'space-between'} mr={1}>
            <Box spacing={2} alignItems={'center'} display={'flex'} gridGap={16} m={2} my={1.5}>
                <WuButton
                    variant={'outlined'}
                    disabled={selectedResources.length < 1}
                    color={'secondary'}
                    startIcon={<LocalOfferOutlined color={'action'}/>}
                    onClick={onTagClick}
                >
                    Tag Resources
                </WuButton>
                <Paper style={{padding: 6, paddingRight: 12, paddingLeft: 12, background: '#f8e8e8'}} elevation={0}>
                    <WuStack alignItems={'center'} spacing={1}>
                        <ErrorOutline style={{color: '#BC211D'}}/>
                        <Typography>
                            {invalidApplicationCount || 0}
                            &nbsp;resources are invalid
                            &nbsp;of {totalResources || 0}
                        </Typography>
                    </WuStack>
                </Paper>
            </Box>
        </Box>
    )
}