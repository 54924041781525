import React from "react";
import {Box, Divider, FormControlLabel, Paper, Radio, RadioGroup} from "@material-ui/core";
import WuSummaryCard from "../../General/WuSummaryCard";
import FormControl from "@material-ui/core/FormControl";
import {costFormatterGeneral} from "../../../helper/generalFunctions";


export default function CostAnomalyTitleCard(props) {

    const {anomalyPeriod, numberOfAnomalies = 0, totalImpactAmount = 0, loading = false} = {...props}
    const handlePeriodChange = (e) => {
        props.onChange(e.target.value);
    }

    return (
        <Box display={'flex'} className={'gap'}>
            <Paper elevation={0}>
                <FormControl component="fieldset">
                    <RadioGroup aria-label="gender" name="gender1" value={parseInt(anomalyPeriod)}
                                onChange={handlePeriodChange}>
                        <FormControlLabel value={90} control={<Radio/>} label="Last 90 Days"/>
                        <FormControlLabel value={60} control={<Radio/>} label="Last 60 Days"/>
                        <FormControlLabel value={30} control={<Radio/>} label="Last 30 Days"/>
                    </RadioGroup>
                </FormControl>
            </Paper>
            <Divider orientation={'vertical'} flexItem={true}/>
            <Box display={'flex'} className={'gap'}>
                <WuSummaryCard title={'Cost Impact'}
                               value={`$${costFormatterGeneral(totalImpactAmount)}`}
                               loading={loading}
                               description={'Total cost impact in organization'}/>
                <WuSummaryCard title={'Number of Anomalies'} value={numberOfAnomalies}
                               loading={loading}
                               description={'Total detected Anomalies'}/>
            </Box>
        </Box>
    )
}