import React, {useEffect, useState} from "react";
import {InputLabel, MenuItem} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import moment from "moment";

export default function TimeZoneSelector(props) {
    const {value, error} = {...props}
    const [timeZone, setTimeZone] = useState(value);


    useEffect(() => {
        props.onChange(timeZone);
    }, [timeZone])

    return (
        <FormControl variant="outlined" size={"small"} style={{marginTop: 8}} fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">Time Zone</InputLabel>
            <Select
                error={error}
                margin={"dense"}
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={timeZone}
                onChange={(e) => {
                    setTimeZone(e.target.value)
                }}
                label="TimeZone"
            >
                <MenuItem value={'IST'}><TimeChange timeZone={'IST'} description={'Indian Standard Time'} gmt={'+5:30'}/></MenuItem>
                <MenuItem value={'MST'}><TimeChange timeZone={'MST/MDT'} description={'Mountain Daylight Time'} gmt={'-6:00'}/></MenuItem>
                <MenuItem value={'EST'}><TimeChange timeZone={'EST/EDT'} description={'Eastern Daylight Time'} gmt={'-4:00'}/></MenuItem>
                <MenuItem value={'CET'} disabled><TimeChange timeZone={'CET/CEST'} description={'Central European Time'} gmt={'+2:00'}/></MenuItem>
            </Select>
        </FormControl>
    )
}


function TimeChange({timeZone, gmt, description}) {
    return (
        <Box display="flex" gridGap={8}>
            <Typography>
                {timeZone}
            </Typography>
            <Typography color={'textSecondary'}>
                GMT {gmt}
            </Typography>
            <Typography color={'textSecondary'}>
                {description}
            </Typography>
        </Box>
    )
}