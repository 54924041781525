import ReactApexChart from "react-apexcharts";

export default function SummaryRadialGraph({percentage, color}) {

    const state = {

        series: [percentage],
        options: {
            chart: {
                height: 120,
                type: 'radialBar',
            },
            plotOptions: {
                radialBar: {
                    hollow: {
                        size: '40%',
                    },
                    dataLabels: {
                        name: {
                            show: false
                        },
                        value: {
                            show: false,
                            offsetY: -2,
                            fontSize: '22px'
                        }
                    },
                    track: {
                        background: "#e1e1e1",
                        strokeWidth: '97%',
                        dropShadow: {
                            enabled: true,
                            top: 2,
                            left: 0,
                            color: 'rgba(196,196,196,0.18)',
                            opacity: 1,
                            blur: 2
                        }
                    },
                },
            },
            colors:[color],
        },
    };

    return (
        <ReactApexChart options={state.options} series={state.series} type="radialBar" height={120} width={70}/>
    )
}