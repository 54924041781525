import React, {useState} from 'react';
import PageTitle from "../../General/PageTitle/PageTitle";
import Calender from "./CalenderWidget";
import './index.scss';
import Box from "@material-ui/core/Box";
import {Divider} from "@material-ui/core";
import Summary from "./Summary";
import DataTables from "./DataTables";
import moment from "moment";
import WuButton from "../../General/WuButton";
import AdditionalOptions from "./AdditionalOptions";

export default function NewlyProvisionedResources() {
    const today = moment()
    const [dateRange, setDateRange] = useState({startDate: today, endDate: today})
    const [selectedOptions, setSelectedOptions] = useState({
        autoScalingGroup: true,
        eks: true,
        snapshot: true,
        backup: true,
        ecs: true,
        emr: true,
        sagemaker: true
    })
    const [clickReg, setClickReg] = useState(0)

    return (
        <div className="title-div-wrapper">
            <PageTitle title={'New Deployments'} subTitle={'Resources provisioned under specific time'}/>
            <Box mt={5} display={'flex'} className={'gap'}>
                <Box>
                    <Calender onChange={(e) => setDateRange(e)}/>
                    <Box mt={2} display={'flex'} className={'gap'}>
                        <WuButton color={'secondary'} onClick={() => setClickReg(e => e + 1)}>Search⚡</WuButton>
                        <AdditionalOptions selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions}/>
                    </Box>
                    <Summary/>
                </Box>
                <Divider orientation={'vertical'} flexItem/>
                <DataTables startDate={dateRange.startDate} endDate={dateRange.endDate} load={clickReg}
                            selectedOptions={selectedOptions}/>
            </Box>
        </div>
    )
}