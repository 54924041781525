import React, {useState} from 'react';
import WuDialog from "../../../General/WuDialog";
import Box from "@material-ui/core/Box";
import {Button, Checkbox, Collapse, FormControlLabel, FormGroup, Paper, Typography} from "@material-ui/core";
import {jsonParser} from "../../../../helper/generalFunctions";
import {AlertWu} from "../../../AlertWu/AlertWu";
import WuTextField from "../../../General/WuTextFeild";
import TimeZoneSelector from "../ReUsable";
import useForm from "../../../../hooks/useForm";
import {ProgressCircleCustomSize} from "../../../ProgressCircle/ProgressCircle";
import moment from "moment";
import service from "../../../../service/service";
import ResponseStatus from "../../../General/ResponseStatus";

export default function ActionDialog(props) {
    const {open, selectedResources, accountId, region, selectedActionItem} = {...props}
    const [error, setError] = useState({error: false, message: undefined})
    const [ready, setReady] = useState(false)
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useForm({startTime: '', stopTime: '', timeZone: '', weekendTag: false})
    const [response, setResponse] = useState({total: 0, failed: 0, status: {}});


    const handleConfirmOperation = async (fn) => {
        try {
            setReady(false);
            setLoading(true)
            setError({error: false, message: undefined})
            const tags = {}
            Object.keys(formData).forEach(e => {
                if (e === 'stopTime') if (formData[e]) tags['STOP-HOUR'] = `${formData['timeZone']}-${formData[e]}`
                if (e === 'startTime') if (formData[e]) tags['START-HOUR'] = `${formData['timeZone']}-${formData[e]}`
                if (e === 'weekendTag') if (formData[e]) tags['WEEKEND-STOP'] = 'True'
            })

            const params = {
                account_id: accountId,
                resources: selectedResources.map(({
                                                      Arn = '',
                                                      Type = '',
                                                      ApplicationName = '',
                                                      ApplicationOwner = '',
                                                      CostCenter = '',
                                                      Environment = '',
                                                      Region = '',
                                                      State = '',
                                                      InstanceType = '',
                                                      PrivateIp = '',
                                                      Name = ''
                                                  }) => {
                    return {
                        ApplicationName: ApplicationName,
                        ApplicationOwner: ApplicationOwner,
                        CostCenter: CostCenter,
                        Environment: Environment,
                        InstanceType: InstanceType,
                        PrivateIp: PrivateIp,
                        Region: Region,
                        State: State,
                        Arn: Arn,
                        Name: Name,
                        Type: Type.substring(0, 3)
                    }
                }),
                tags: tags,
                operation_type: selectedActionItem
            }
            const {data: {data}} = await fn(params)
            setResponse(data)
            setReady(true)
        } catch (e) {
            const errorMessage = jsonParser(e?.request?.response).error || undefined
            setError({error: true, message: errorMessage ? errorMessage : e.message})
        } finally {
            setLoading(false)
        }
    }

    const operations = () => {
        switch (selectedActionItem) {
            case ('set'):
                handleConfirmOperation(service.automations().addRemovePowerScheduleTags).then()
                break
            case ('remove'):
                handleConfirmOperation(service.automations().addRemovePowerScheduleTags).then()
                break
            case ('stop'):
                handleConfirmOperation(service.automations().startStopResources).then()
                break
            case ('start'):
                handleConfirmOperation(service.automations().startStopResources).then()
                break
            default:
                alert('unknown operation')
                break

        }


    }

    return (
        <WuDialog open={open} size={'xs'}
                  title={selectedActionItem === 'set' ? 'Confirm Power Schedule' : 'Remove Power Schedule'}
                  onChange={() => props.onChange(false)}>
            <Box m={2}>
                <WeekendText numberOfResources={selectedResources.length}
                             type={selectedActionItem}
                             accountId={accountId} region={region}/>
                {selectedActionItem === 'set' && <PowerScheduleForm formData={formData} setFormData={setFormData}/>}
                {selectedActionItem === 'remove' && <RemoveTagsControl setFormData={setFormData} formData={formData}/>}
                {selectedActionItem === 'set' && <ScheduleNotificationText formData={formData}/>}
                <Box display={'flex'} className={'gap'} mt={3}>
                    <Button variant={'contained'} disableElevation
                            style={{width: 100}}
                            onClick={operations}
                            disabled={!(formData.stopTime || formData.weekendTag || selectedActionItem === 'start' || selectedActionItem === 'stop') || loading || ready}
                            color={'primary'}>
                        {loading ? <ProgressCircleCustomSize size={16}/> : 'CONFIRM'}
                    </Button>
                    <Button variant={'contained'}
                            disabled={loading}
                            disableElevation
                            onClick={() => props.onChange(false)}>
                        CANCEL
                    </Button>
                </Box>
                <Box mt={(ready || error.error) ? 2 : 0}>
                    <Collapse in={ready}>
                        <ResponseStatus response={response.status} total={response.total}
                                        failed={response.failed}/>
                    </Collapse>
                    <AlertWu alertOpen={{state: error.error}} severity={"error"} message={error.message}/>
                </Box>
            </Box>
        </WuDialog>
    )
}

function RemoveTagsControl({formData, setFormData}) {

    const handleFormChange = (key, value) => {
        setFormData(key, value)
    }

    return (
        <Paper variant={'outlined'} style={{padding: 8, marginTop: 16}}>
            <FormGroup>
                <FormControlLabel
                    style={{marginLeft: -4}}
                    control={<Checkbox checked={Boolean(formData['stopTime'])}
                                       onChange={(e) => handleFormChange('stopTime', e.target.checked)}
                                       color="secondary"/>}
                    label="Stop Hour"
                    labelPlacement="end"
                />
                <FormControlLabel
                    style={{marginLeft: -4}}
                    control={<Checkbox checked={Boolean(formData['startTime'])}
                                       onChange={(e) => handleFormChange('startTime', e.target.checked)}
                                       color="secondary"/>}
                    label="Start Hour"
                    labelPlacement="end"
                />
                <FormControlLabel
                    style={{marginLeft: -4}}
                    control={<Checkbox checked={Boolean(formData['weekendTag'])}
                                       onChange={(e) => handleFormChange('weekendTag', e.target.checked)}
                                       color="secondary"/>}
                    label="Weekend Tag"
                    labelPlacement="end"
                />
            </FormGroup>
        </Paper>

    )
}

function PowerScheduleForm({formData, setFormData}) {
    const {timeZone, startTime, stopTime, weekendTag} = {...formData}

    const handleFormDataChange = (e) => {
        const key = e.target.name
        const value = e.target.value

        setFormData(key, value)
    }

    return (
        <Paper style={{background: 'whitesmoke', marginTop: 16}} variant={'outlined'}>
            <Box m={2}>
                <FormGroup>
                    <Box display={'flex'} flexDirection={'column'} gridGap={8}>
                        <WuTextField label={'Stop Time'} type={'time'}
                                     name='stopTime'
                                     value={stopTime}
                                     onChange={handleFormDataChange}/>
                        <WuTextField label={'Start Time'}
                                     value={startTime}
                                     disabled={!stopTime}
                                     type={'time'}
                                     name='startTime'
                                     onChange={handleFormDataChange}/>
                        <TimeZoneSelector name='timeZone'
                                          value={timeZone}
                                          onChange={(e) => setFormData('timeZone', e)}/>
                    </Box>

                    <FormControlLabel
                        style={{marginTop: 8, marginLeft: -7}}
                        control={<Checkbox checked={weekendTag} name="weekend tag"
                                           onChange={(e) => setFormData('weekendTag', e.target.checked)}/>}
                        label="Weekend Stop"
                    />
                </FormGroup>
            </Box>
        </Paper>
    )
}

function WeekendText({numberOfResources, accountId, type}) {
    if (type === 'set') {
        return (
            <Typography component={'div'}>
                <strong>{numberOfResources}</strong> resource(s) will
                be power scheduled in the <strong>{accountId}</strong> account.
                <br/>
                Are you sure that you want to apply power schedule for the selected
                resources?
            </Typography>
        )
    } else if (type === 'remove') {
        return (
            <Typography component={'div'}>
                <strong>{numberOfResources}</strong> resource(s) will
                be removed from power scheduled list in the <strong>{accountId}</strong> account.
                <br/>
                Are you sure that you want to remove power schedule for the selected
                resources?
            </Typography>
        )
    } else if (type === 'start') {
        return (
            <Typography component={'div'}>
                <strong>{numberOfResources}</strong> resource(s) will
                be started in the <strong>{accountId}</strong> account.
                <br/>
                Are you sure that you want to start the selected
                resources?
            </Typography>
        )
    } else {
        return (
            <Typography component={'div'}>
                <strong>{numberOfResources}</strong> resource(s) will
                be stopped in the <strong>{accountId}</strong> account.
                <br/>
                Are you sure that you want to stop selected
                resources?
            </Typography>
        )
    }
}

function ScheduleNotificationText({formData}) {
    const {startTime, stopTime, timeZone, weekendTag} = {...formData}
    let offset = '0'
    if (timeZone === 'IST') offset = '+05:30'
    else if (timeZone === 'MST') offset = '-06:00'
    else if (timeZone === 'EST') offset = '-04:00'
    else if (timeZone === 'CET') offset = '+02:00'

    function Bolding({children}) {
        return <strong>{children}</strong>
    }

    function TimeNoteStart() {
        if (startTime) return <Bolding> started
            at {moment(startTime, 'HHmm').utcOffset(offset).format('h:mm A')}</Bolding>
        return ''
    }

    function TimeNoteStop() {
        if (stopTime) return <>The selected resource(s) will be <Bolding> stopped
            at {moment(stopTime, 'HHmm').utcOffset(offset).format('h:mm A')}</Bolding></>
        return ''
    }

    function TimeNoteWeekend() {
        if (weekendTag) return <Bolding> STOP state during WEEKENDS</Bolding>
        return ''
    }

    function TimeZone() {
        return <Bolding> {Intl.DateTimeFormat().resolvedOptions().timeZone} </Bolding>
    }

    return (
        <Box mt={2}>
            <Typography component={'div'}>
                <TimeNoteStop/>
                {startTime && ' and will be '} {startTime && <TimeNoteStart/>}
                {weekendTag && ' Resource(s) will be at'}<TimeNoteWeekend/> in <TimeZone/> timezone.
            </Typography>
        </Box>
    )

}