import React, {useEffect, useState} from "react";
import DateRange from "react-date-range/dist/components/DateRange";
import moment from "moment";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import {FormControlLabel, Radio, RadioGroup, Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";


export default function Calender(props) {

    const lastDay = moment().subtract(1, 'day').format('YYYY-MM-DD');
    const [state, setState] = useState([
        {
            startDate: new Date(lastDay),
            endDate: new Date(lastDay),
            key: 'selection'
        }
    ]);

    const handleChange = (e) => {
        setState([e.selection])
    }

    useEffect(() => {
        props.onChange({startDate: state[0].startDate, endDate: state[0].endDate})
    }, [state])

    return (
        <div>
            <Box mt={0} display={'flex'} flexDirection={'column'} className={'custom-date-calender'}>
                <Box display={'flex'} className={'gap'} alignItems={'center'} ml={1} mt={1}>
                    <Typography variant={'subtitle1'} color={'textSecondary'}>
                        PICK A DATE OR RANGE
                    </Typography>
                </Box>
                <DateRange
                    className={'date-range-picker'}
                    //dayContentRenderer={CustomDayContent}
                    maxDate={new Date()}
                    editableDateInputs={true}
                    onChange={handleChange}
                    moveRangeOnFirstSelection={false}
                    ranges={state}
                />
            </Box>
            {/*<Box mt={3}>
                <Paper variant={'outlined'}>
                    <PreDefinedSelectors onChange={(e) => console.log(e)}/>
                </Paper>
            </Box>*/}
        </div>
    )
}


function CustomDayContent(day) {

    let className = 'bubble ';

    const checkCorrectClass = () => {
        if (moment(day).format('DD') % 2 === 0) {
            className.concat('bubble-medium-intensity')
        }
    }
    checkCorrectClass()

    return (
        <div className={'calender-new-resources'}>
            {
                !moment(day).isAfter(moment()) && <div className={className}>
                </div>
            }
            {moment(day).format('DD')}
        </div>
    )
}

function PreDefinedSelectors(props) {
    const {value} = {...props}
    return (
        <Box m={3} mt={2} mb={2}>
            <Typography gutterBottom variant={'subtitle1'}>Predefined Options</Typography>
            <RadioGroup value={value} onChange={(e) => props.onChange(e.target.value)}>
                <FormControlLabel value="currentMonth" control={<Radio/>} label="Current Month"/>
                <FormControlLabel value="lastMonth" control={<Radio/>} label="Last Month"/>
                <FormControlLabel value="last90Days" control={<Radio/>} label="Last 90 Days"/>
                <FormControlLabel value="last365Days" control={<Radio/>} label="Last 365 Days"/>
                <FormControlLabel value="custom" control={<Radio/>} label="Custom"/>
            </RadioGroup>
        </Box>
    )
}



