import React from 'react';
import WuTextField from "../../../General/WuTextFeild";
import {Autocomplete} from "@material-ui/lab";

export default function InputBox(props) {
    const {value, setValue} = {...props}
    return (
        <Autocomplete
            multiple
            freeSolo
            size={'small'}
            onChange={(e, value) => setValue(value)}
            options={value}
            renderInput={(params) => <WuTextField {...params}

                                                  label="Resource Id(s)"
                                                  placeholder={'Resource Id(s)'}/>}
        />
    )
}
