import React, {useState} from 'react';
import WuFloatingDownloadButtonV2 from "../../General/WuFloatingDownloadButtonV2";
import Box from "@material-ui/core/Box";
import {AccountMultiSelectV2, RegionMultiSelect} from "../../General/WuAutoComplete";
import WuButton from "../../General/WuButton";
import WuLoading from "../../General/WuLoading";
import {Typography} from "@material-ui/core";
import fileExportElement from "../../../helper/fileExportElement";
import {jsonParser} from "../../../helper/generalFunctions";
import {useOrganization} from "../../../hooks/useOrganization";


export default function DownloadReport({downloadFunction, title, paramKey, excludeRegion= false}) {
    const {organization: masterAccountId} = useOrganization()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [account, setAccount] = useState([])
    const [region, setRegion] = useState([])

    const handleDownload = async () => {
        try {
            setLoading(true)
            const params = {
                "account_list": account.map(({AccountId}) => AccountId),
                "region_list": region,
                "master_account_id": masterAccountId
            }

            if(paramKey) params[paramKey] = account.map(({AccountId}) => AccountId)
            const response = await downloadFunction(params)
            fileExportElement(response)
            setLoading(false)
        } catch (e) {
            let errorObj = ''
            try {
                errorObj = jsonParser(e.request.response).error
            } catch (e) {
                errorObj = e.message
            }
            setError(errorObj ? errorObj : e.message)
            setLoading(false)
        }
    }

    const handleAccountChange=(e, value)=>{
        setAccount(value)
    }

    return (
        <div>
            <WuFloatingDownloadButtonV2 downloadText={'Download'} loading={loading} error={Boolean(error)}>
                <Typography variant={'subtitle1'}>
                    {title}
                </Typography>
                <Box mt={2}>
                    <AccountMultiSelectV2 onChange={handleAccountChange} addAllToOption={true} value={account}/>
                    {!excludeRegion && <RegionMultiSelect onChange={(e) => setRegion(e.regionList)} addAllToOptions={true}/>}
                    <Box mt={2}>
                        <WuButton color={'secondary'} onClick={handleDownload}>
                            Download
                        </WuButton>
                        <WuLoading message={'Downloading...'}/>
                    </Box>
                </Box>
            </WuFloatingDownloadButtonV2>
        </div>
    )
}