import React from "react";
import {BudgetRoute} from "../../routes/MainRoute";
import "./budget.scss";
import Box from "@material-ui/core/Box";
import WuSummaryCard from "../General/WuSummaryCard";

export default function Budget() {
    return (
        <section className="container">
            <BudgetRoute/>
        </section>
    )
}

function BudgetSummaryCard(props) {

    const {totalAmount, numberOfBudgets} = {...props}

    return (
        <Box display={'flex'} className={'gap'}>
            <WuSummaryCard title={"Budgeted Amount"} value={`$${totalAmount}`}
                           description={'Budgeted amount for the organization'}/>
            <WuSummaryCard title={"Number of Budgets"} value={numberOfBudgets}
                           description={'Total number of budgets configured'}/>
        </Box>
    )
}


export {BudgetSummaryCard}