import {useEffect, useState} from "react";
import {useMasterAccount} from "../../../hooks";
import {IconButton, Box, Collapse} from "@material-ui/core";
import {AddCircleRounded, DeleteOutlined} from "@material-ui/icons";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import {WuButton} from "../../General";
import {Autocomplete} from "../../../shared";
import service from "../../../service/service";
import {TransitionGroup} from "react-transition-group";

export function FilterWidget(props) {
    const {onChange, value, filterCombinator, onFilterCombinatorChange} = {...props}

    const handleChange = (source, newValue, id) => {
        onChange(value.map(e => {
            if (e.id === id) e[source] = newValue
            return e
        }))
    }

    const handleDelete = (id) => {
        onChange(value.filter(e => e.id !== id))
    }

    const handleAdd = () => {
        const newId = value.reduce((acc, curr) => {
            if (acc < curr.id) return curr.id
            return acc
        }, 0) + 1
        onChange([...value, {id: newId, tagKey: '', tagValues: ''}])
    }

    return (
        <div className={'at-filter-container at-grid'}>
            {value.length > 0 && <div className={'at-combinator-wrapper'}>
                <Combinator filterCombinator={filterCombinator} onFilterCombinatorChange={onFilterCombinatorChange}/>
            </div>}
            <TransitionGroup>
                {
                    value.map(e => <Collapse key={e.id}>
                        <SingleFilterItem  {...e}
                                           onChange={handleChange}
                                           onDelete={handleDelete}/>
                    </Collapse>)
                }
            </TransitionGroup>
            <Box mt={value.length > 0 ? 0.5 : 0}>
                <WuButton size={'small'}
                          style={{marginLeft: -4}}
                          startIcon={<AddCircleRounded fontSize={'small'} style={{marginTop: -1}}/>}
                          color={'secondary'}
                          onClick={handleAdd}
                          variant={'text'}>
                    NEW FILTER
                </WuButton>
            </Box>
        </div>
    )
}

function Combinator(props) {
    const {onFilterCombinatorChange, filterCombinator} = {...props}
    return (
        <ToggleButtonGroup size={'small'} value={filterCombinator}
                           exclusive
                           onChange={(e, newValue)=>onFilterCombinatorChange(newValue)}>
            <ToggleButton value={'OR'} style={{padding: '2px 6px'}}>OR</ToggleButton>
            <ToggleButton value={'AND'} style={{padding: '2px 6px'}}>AND</ToggleButton>
        </ToggleButtonGroup>
    )
}

function SingleFilterItem(props) {
    const {onChange, onDelete, tagKey, tagValues, id} = {...props}
    const autocompleteProps = {freeSolo: true}
    const masterAccountId = useMasterAccount()
    const [finopsTags, setFinopsTags] = useState([])
    const [tagOptions, setTagOptions] = useState([])

    const fetchFinopsTags = async () => {
        try {
            const {data: {data}} = await service.automations().getAutoTaggingResourceTags();
            setFinopsTags(data.map(e => e.TagKey))
        } catch (e) {
            console.log(e)
        }
    }

    const fetchOptions = async () => {
        try {
            setTagOptions([])
            onChange('tagValues', '', id)
            const params = {searchKey: tagKey, masterAccountId: masterAccountId}
            const {data: {data}} = await service.general().getTagValuesByKey(params)
            setTagOptions(data)
        } catch (e) {
            console.log(e)
        }
    }

    const handleChange = (source, value) => {
        onChange(source, value, id)
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (tagKey) {
                fetchOptions().then()
            }
        }, 400)
        return () => clearTimeout(delayDebounceFn)
    }, [tagKey])

    useEffect(() => {
        fetchFinopsTags().then()
    }, [])

    return (
        <div className={'at-filter-wrapper at-grid'}>
            <Autocomplete options={finopsTags}
                          size={'small'}
                          placeholder={'tag-key'}
                          onInputChange={(_, value) => handleChange('tagKey', value)}
                          value={tagKey}
                          autocompleteProps={autocompleteProps}/>
            <Autocomplete options={tagOptions} size={'small'}
                          placeholder={'tag-value'}
                          value={tagValues}
                          onInputChange={(_, value) => handleChange('tagValues', value)}
                          autocompleteProps={{...autocompleteProps, disabled: !tagKey}}/>
            <IconButton size={'small'} style={{height: 32, width: 32}} onClick={() => onDelete(id)}>
                <DeleteOutlined/>
            </IconButton>
        </div>
    )
}