import React, {useState} from 'react';
import WuButton from "../../General/WuButton";
import {Box, Divider} from "@material-ui/core";
import service from "../../../service/service";
import fileExportElement from "../../../helper/fileExportElement";
import {useOrganization} from "../../../hooks/useOrganization";
import {ProgressCircleCustomSize} from "../../ProgressCircle/ProgressCircle";
import {ArrowDownwardRounded} from "@material-ui/icons";

export default function ReportAction({reportProperties}) {
    const {organization: masterAccountId} = useOrganization();
    const [progress, setProgress] = useState({loading: false, error: false, ready: false})

    const fetchData = async (params) => {
        try {
            setProgress({loading: true, error: false, ready: false})
            const response = await service.costExplorerReport().generateCustomReport(params)
            fileExportElement(response)
            setProgress({loading: false, error: false, ready: true})
        } catch (e) {
            setProgress({loading: false, error: true, ready: false})
        }
    }

    const handleDownload = () => {
        // reformat data
        const params = {
            "master_account_id": masterAccountId,
            "report_include": reportProperties.reportProperties.report,
            "start_date": reportProperties.dateRange.startDate,
            "end_date": reportProperties.dateRange.endDate,
            "granularity": reportProperties.dateRange.granularity.toUpperCase(),
            "filter": Object.keys(reportProperties.filter).map(filter => {
                return {type: 'equal', key: filter, value: reportProperties.filter[filter]}
            }),
            "columns": reportProperties.reportProperties.columns
        }
        fetchData(params).then()
    }

    return (
        <div className={'report-action-custom'}>
            <div className={'report-action-wrapper'}>
                <Box mt={-2} display={'flex'} className={'gap'}>
                    <WuButton onClick={handleDownload} style={{width: 100}}
                              disabled={!reportProperties.reportProperties.report}>
                        {progress.loading ? <ProgressCircleCustomSize size={16}/> : 'Download'}
                    </WuButton>
                    <WuButton variant={'outlined'} color={'secondary'} disabled={true}>
                        🚧 Save & Download
                    </WuButton>
                    <WuButton variant={'outlined'} color={'secondary'} disabled={true}>
                        🚧 Save
                    </WuButton>
                </Box>
            </div>
            <Divider/>
        </div>
    )
}