import React, {useContext} from 'react'
import {Grid, Paper, Typography} from "@material-ui/core";
import WuStack from "../../General/WuStack";
import FilterContext from "../FilterContext";


export default function TableLegend() {

    return (
        <Paper variant={'outlined'} style={{marginTop: 16, padding: 16}}>
            <Grid container spacing={1}>
                {
                    legends.reverse().map((legend, idx) => <Legend key={idx} {...legend}/>)
                }
            </Grid>
        </Paper>
    )
}


function Legend({colorClass = '', categoryName = '', slug}) {
    const {filter, setFilter} = useContext(FilterContext)

    const handleOnFilterChange = (e) => {
        setFilter(e)
    }

    const gridStyle = {
        cursor: 'pointer',
        backgroundColor: slug === filter ? 'rgba(241,183,43,0.24)' : 'transparent',
        border: slug === filter ? '1px solid var(--border)': '1px solid transparent'
    }

    return (
        <Grid item xs={3} onClick={() => handleOnFilterChange(slug)} style={gridStyle} className={'legend-item'}>
            <WuStack spacing={1} boxProps={{width: 'fit-content', pl: 1}} alignItems={'center'}>
                <div className={`legend-box-account-trend ${colorClass}`}/>
                <Typography component={'legend'} variant={'body2'}>{categoryName}</Typography>
            </WuStack>
        </Grid>
    )
}


const legends = [
    {
        colorClass: 'account-warning-1',
        categoryName: 'More than 80% reduction',
        slug: 'lessThan80'
    },
    {
        colorClass: 'account-warning-2',
        categoryName: 'Cost reduced by 60% - 80%',
        slug: 'betweenNeg80AndNeg60'
    },
    {
        colorClass: 'account-warning-3',
        categoryName: 'Cost reduced by 40% - 60%',
        slug: 'betweenNeg60AndNeg40'
    },
    {
        colorClass: 'account-warning-4',
        categoryName: 'Cost reduced by 20% - 40%',
        slug: 'betweenNeg40AndNeg20'
    },
    {
        colorClass: 'account-warning-5',
        categoryName: 'Cost reduced by 0% - 20%',
        slug: 'betweenNeg20And0'
    },
    {
        colorClass: 'account-warning-6',
        categoryName: 'Cost increased by 0% - 20%',
        slug: 'between0And20'
    },
    {
        colorClass: 'account-warning-7',
        categoryName: 'Cost increased by 20% - 40%',
        slug: 'between20And40'
    },
    {
        colorClass: 'account-warning-8',
        categoryName: 'Cost increased by 40% - 60%',
        slug: 'between40And60'
    },
    {
        colorClass: 'account-warning-9',
        categoryName: 'Cost increased by 60% - 80%',
        slug: 'between60And80'
    },
    {
        colorClass: 'account-warning-10',
        categoryName: 'More than 80% increase',
        slug: 'moreThan80'
    },
]