import {Menu, MenuItem} from "@material-ui/core";
import {useRef, useState} from "react";

export default function OrganizationSelect({organizationList, organization, onOrganizationChange}) {
    const organizationSelectRef = useRef()
    const [anchorEl, setAnchorEl] = useState(null)

    const handleChange = (e) => {
        onOrganizationChange(e)
    }

    return (
        <div className={'rt-org-select-container'}>
            <div ref={organizationSelectRef}
                 onClick={(e) => setAnchorEl(e.currentTarget)}
                 className={'rt-select-wrapper'}
            >
                {organizationList?.find(e => e.AccountId === organization)?.OrganizationAlias || 'no org'}
            </div>
            <Menu open={Boolean(anchorEl)}
                  onClick={() => setAnchorEl(null)}
                  anchorEl={anchorEl}>
                {
                    organizationList.map(e => <MenuItem value={e.AccountId}
                                                        onClick={() => handleChange(e.AccountId)}
                                                        key={e.AccountId}>
                        {e.OrganizationAlias}
                    </MenuItem>)
                }
            </Menu>
        </div>
    )
}