import React from "react";
import {Box} from "@material-ui/core";
import propTypes from 'prop-types';

export default function WuSpacer({space = 0}) {
    return (
        <Box mt={space}/>
    )
}

WuSpacer.propTypes = {
    space: propTypes.number.isRequired
}