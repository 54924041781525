import React from 'react';
import './header.scss';
import Logo from '../../assets/images/logo.png';
import FinopsAvatar from "./Avatar";
import OrganizationSelect from "./OrganisationSelect";
import NotificationTray from "./NotificationTray";
import {IconButton} from "@material-ui/core";
import {SettingsOutlined} from "@material-ui/icons";
import {Link} from "react-router-dom";

export default function Header(props) {
    return (
        <header>
            <div className="header-container">
                <div className="header-left">
                    <img alt="logo" style={{width: 85, marginLeft: 16}} src={Logo}/>
                    {/*<div className="finops-title">
                        Cloud FinOps Dashboard
                    </div>*/}
                </div>
                <div style={{gap: 20}} className={"d-f-c"}>
                    <OrganizationSelect/>
                    {/*<ThemeMode/>*/}
                    <IconButton component={Link} to={'/settings'}>
                        <SettingsOutlined/>
                    </IconButton>
                    <NotificationTray/>
                    <FinopsAvatar/>
                </div>
            </div>
        </header>
    );
}