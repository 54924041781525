import React from 'react';
import Box from "@material-ui/core/Box";


export default function WuStack({
                                    children,
                                    direction = 'row',
                                    spacing = 0,
                                    alignItems = 'start',
                                    justifyContent = 'start',
                                    boxProps
                                }) {
    return (
        <Box display={'flex'} gridGap={spacing * 8}
             width={'100%'}
             flexDirection={direction}
             justifyContent={justifyContent}
             alignItems={alignItems} {...boxProps}>
            {children}
        </Box>
    )
}