import {WuAccordionDetails, WuAccordionSummary, WuAccordionWrapper} from "../../General";
import {Typography} from "@material-ui/core";
import Home from "./Home";
import IconButton from "@material-ui/core/IconButton";
import {RefreshOutlined} from "@material-ui/icons";
import {costFormatterGeneral} from "../../../helper/generalFunctions";

export default function DetailAccordion(props) {
    const {accountId, clusterCount, serviceCount, instanceCount, taskCount} = {...props}

    return (
        <WuAccordionWrapper TransitionProps={{unmountOnExit: true}}>
            <WuAccordionSummary>
                <AccountAccordionSummary {...props}/>
            </WuAccordionSummary>
            <WuAccordionDetails>
                <Home
                    accountId={accountId}
                    serviceCount={serviceCount}
                    clusterCount={clusterCount}
                    instanceCount={instanceCount}
                    taskCount={taskCount}
                />
            </WuAccordionDetails>
        </WuAccordionWrapper>
    )
}


function AccountAccordionSummary(props) {
    const {
        accountId,
        accountAlias,
        clusterCount,
        taskCount,
        serviceCount,
        price,
        previousPrice,
        yearlySpend
    } = {...props}
    return (
        <>
            <Typography variant={'body1'}
                        style={{flexBasis: '30%'}}>
                <IconButton size={'small'}
                            style={{marginRight: 8}}
                            disabled
                >
                    <RefreshOutlined/>
                </IconButton>
                {accountId || 'unknown'}
                <Typography component={'span'} variant={'body2'} color={'textSecondary'}>
                    ({accountAlias || 'unknown'})
                </Typography>
            </Typography>
            <Typography style={{flexBasis: '12%'}}>
                {clusterCount || 0}
            </Typography>
            <Typography style={{flexBasis: '12%'}}>
                {taskCount || 0}
            </Typography>
            <Typography style={{flexBasis: '12%'}}>
                {serviceCount || 0}
            </Typography>
            <Typography style={{flexBasis: '10%'}}>
                ${costFormatterGeneral(previousPrice) || 0}
            </Typography>
            <Typography style={{flexBasis: '10%'}}>
                ${costFormatterGeneral(price) || 0}
            </Typography>
            <Typography style={{flexBasis: '10%'}}>
                ${costFormatterGeneral(yearlySpend) || 0}
            </Typography>
        </>
    )
}