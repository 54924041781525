import React, {useEffect, useState} from 'react';
import Paper from "@material-ui/core/Paper";
import {Button, Typography} from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import QueryBuilder from 'react-querybuilder';
import {formatQuery} from 'react-querybuilder';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {vs} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import service from "../../../../service/service";
import {ProgressCircleCustomSize} from '../../../ProgressCircle/ProgressCircle'
import ReportPreview from "../../ReportPreview/ReportPreview";


const translations = {
    addRule: {
        label: "New Rule",
        title: "Add rule",
    },
    addGroup: {
        label: "New Group",
        title: "Add group",
    },
}

export default function CustomReport(props) {

    const [query, setQuery] = React.useState(undefined);
    const [athenaColumnNames, setAthenaColumnNames] = React.useState(undefined);
    const [finalQuery, setFinalQuery] = React.useState("SELECT * FROM Athena");
    const [columnData, setColumnData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [previewData, setPreviewData] = useState([]);
    const [preview, setPreview] = useState(false);
    const [limit, setLimit] = useState(1000);
    const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
    const checkedIcon = <CheckBoxIcon fontSize="small"/>;
    let body = {}
    try {
        body = {
            "query_type": "COL",
            "ad_hoc": "false",
            "account_id": props.masterAccountId.toString()
        }

    } catch (e) {
        alert("issues with master account id")
    }

    useEffect(() => {
        service.masterBillingReport().generateReport(body)
            .then(res => {
                let colData = []
                if (res.data.report.length > 0) {
                    res.data.report.map((data) => {
                        colData.push({"name": data.column_name, "label": data.column_name})
                    })
                }
                setColumnData(colData)
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
            })
    }, [props.masterAccountId])


    const handlePreviewClose = () => {
        setPreview(false);
    }

    const composeQuery = (event) => {
        let string = formatQuery(event, 'sql');
        setQuery(string);
        updateQuery(string, athenaColumnNames)
    }

    function handleColumnChange(columnArray) {
        let columnNames = ""
        columnArray.map((columnName) => {
            columnNames = columnNames + columnName.name + " ,"
        })
        columnNames = columnNames.slice(0, -2);
        setAthenaColumnNames(columnNames);
        updateQuery(query, columnNames)
    }


    const updateQuery = (query, columnNames) => {
        let queryString = `SELECT ${columnNames} FROM {table_name} WHERE ${query} LIMIT ${limit}`
            .replace('WHERE ()', "")
            .replace("undefined", "*")
            .replace("SELECT  FROM", "SELECT * FROM");
        setFinalQuery(queryString)
    }

    const generateReport = () => {
        setLoading(true);

        if (finalQuery && props.masterAccountId) {
            const body = {
                "query_type": finalQuery.toString(),
                "ad_hoc": "true",
                "account_id": props.masterAccountId.toString()
            }

            service.masterBillingReport().generateReport(body)
                .then(res => {
                    setPreviewData(res.data)
                    setLoading(false);
                    setPreview(true)
                })
                .catch(err => {
                    setLoading(false);
                })
        }
        else{
            alert("incorrect query")
        }

    }

    return (
        <section className="custom-report">
            {preview && <ReportPreview onClick={handlePreviewClose} title="CBR" data={previewData}/>}
            <Paper variant={"outlined"} style={{padding: 20}}>
                <div style={{display: "flex", alignItems: "center"}}>
                    <Typography style={{marginRight: 20}} variant={"h6"}> Generate Custom Report</Typography>
                    {loading && <ProgressCircleCustomSize size={16}/>}
                </div>
                <div className="d-f-j-c ">
                    <Paper className="query"
                           variant={"outlined"}>
                        <SyntaxHighlighter
                            language="sql"
                            wrapLongLines={true}
                            style={vs}>
                            {finalQuery}
                        </SyntaxHighlighter>
                    </Paper>
                    <Button
                        disabled={loading}
                        variant={"contained"}
                        disableElevation
                        onClick={generateReport}
                        color="primary"> Execute
                    </Button>
                </div>

                <div className="options-wrapper">
                    <div className="item" style={{paddingRight: 10, width: "30%"}}>
                        <Autocomplete
                            multiple
                            size="small"
                            limitTags={2}
                            id="available-fields"
                            options={columnData}
                            onChange={(event, value) => handleColumnChange(value)}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.name}
                            renderOption={(option, {selected}) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{marginRight: 8}}
                                        checked={selected}
                                    />
                                    {option.name}
                                </React.Fragment>
                            )}
                            fullWidth={true}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="Report Fields"
                                           placeholder="Favorites"/>
                            )}
                        />
                    </div>
                    <div className="item-wrapper" style={{paddingLeft: 10}}>
                        <QueryBuilder
                            translations={translations}
                            fields={columnData}
                            onQueryChange={composeQuery}/>
                    </div>
                </div>
            </Paper>
        </section>
    )
}