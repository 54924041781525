import axios from "axios";
import {apiErrorResponseJsonParser} from "../helper/generalFunctions";
import service from "./service";

axios.defaults.withCredentials = false
const axiosInstance = axios.create()

let requestingToken = false

async function refreshAccessToken() {
    requestingToken = true
    await service.authentication().refreshToken()
    const {data: {data}} = await service.authentication().refreshToken()
    window.accessToken = data
    requestingToken = false
}

// Set the AUTH token for any request
axiosInstance.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${window.accessToken}`
    return config;
});

axiosInstance.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error.config;
    const errorBody = apiErrorResponseJsonParser(error)

    if (errorBody === 'invalid token' && !originalRequest._retry) {
        originalRequest._retry = true;
        await refreshAccessToken();
        return axiosInstance(originalRequest);
    }
    return Promise.reject(error);
});


export default axiosInstance

