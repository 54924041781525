import {useEffect, useState} from "react";
import {KeyboardArrowRight, LocalOfferOutlined, MemoryOutlined} from "@material-ui/icons";
import {Box, Collapse, Typography} from "@material-ui/core";
import WuButton from "../../../General/WuButton";
import {apiErrorResponseJsonParser} from "../../../../helper/generalFunctions";
import WuLoading from "../../../General/WuLoading";
import {NoData} from "../../../General";
import WuAlert from "../../../General/WuAlert";
import service from "../../../../service/service";
import {tagComplaincePartialResource, tagCompliancePartialAccount} from "../../../../assets/finthesys/tag-compliance";

export default function DetailsAccountWise({accountId}) {

    const [progress, setProgress] = useState({loading: false, ready: false, error: false})
    const [apiResponse, setApiResponse] = useState([])

    const fetchData = async () => {
        try {
            setProgress({loading: true, ready: false, error: false})
            const params = {
                accountId: accountId
            }
            const {data: {data}} = await service.monitoring().getTagComplianceAccountWise(params)
            setApiResponse(data)
            setProgress({loading: false, ready: true, error: false})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setProgress({loading: false, ready: true, error: error})
        }
    }

    const fetchDummyData = async () => {
        try {
            setProgress({loading: true, ready: false, error: false})
            const params = {
                accountId: accountId
            }
            const {data} = tagCompliancePartialAccount
            setApiResponse(data)
            setProgress({loading: false, ready: true, error: false})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setProgress({loading: false, ready: true, error: error})
        }
    }


    useEffect(() => {
        if (accountId) {
            fetchDummyData().then()
        }
    }, [accountId])

    return (
        <div className={'tc-account-wise'}>
            <div className={'tc-account-wise-header'}>
                <div>
                    <Typography variant={'h6'}> Resources </Typography>
                    <Typography variant={'body2'}> Resources with partial tags/wrong tag values </Typography>
                </div>
            </div>
            <div className={'tc-account-wise-resource-c'}>

                {
                    progress.loading &&
                    <WuLoading message={'Please wait...'} open={progress.loading} boxProps={{my: 2}}/>
                }

                {
                    progress.ready &&
                    apiResponse.length < 1 && <Box mt={8}><NoData justifyContent={'flex-start'}/></Box>
                }
                <WuAlert error={Boolean(progress.error)} errorMessage={progress.error} success={false}/>
                {
                    progress.ready &&
                    apiResponse.map(e => <ResourceList {...e} key={e?.resourceArn}/>)
                }
            </div>
        </div>
    )
}


function ResourceList({missingTagCount, region, resourceArn, resourceType, service: serviceType}) {
    const [open, setOpen] = useState(false)
    const [progress, setProgress] = useState({loading: false, ready: false, error: false})
    const [progressFix, setProgressFix] = useState({loading: false, ready: false, error: false})
    const [apiResponse, setApiResponse] = useState([])

    const fetchData = async () => {
        try {
            setProgress({loading: true, ready: false, error: false})
            const params = {
                resourceId: resourceArn
            }
            const {data: {data}} = await service.monitoring().getTagComplianceResourceWise(params)
            setApiResponse(data)
            setProgress({loading: false, ready: true, error: false})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setProgress({loading: false, ready: true, error: error})
        }
    }

    const fetchDummyData = async () => {
        try {
            setProgress({loading: true, ready: false, error: false})
            const params = {
                resourceId: resourceArn
            }
            const {data} = tagComplaincePartialResource
            setApiResponse(data)
            setProgress({loading: false, ready: true, error: false})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setProgress({loading: false, ready: true, error: error})
        }
    }

    const handleFixResources = async (e) => {
        try {
            e.stopPropagation()
            setProgressFix({loading: true, ready: false, error: false})
            const params = {
                resourceId: resourceArn
            }
            const {data: {data}} = await service.monitoring().tagResourceAccountWise(params)
            setApiResponse(data)
            setProgressFix({loading: false, ready: true, error: false})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setProgressFix({loading: false, ready: true, error: error})
        }
    }

    useEffect(() => {
        if (resourceArn && open) {
            fetchDummyData().then()
        }
    }, [resourceArn, open])

    const handleClick = () => {
        setOpen(current => !current)
    }

    return (
        <div>
            <div className={'tc-resource-list-container'} onClick={handleClick}>
                <div className={'tc-resource-list-inner'}>
                    <KeyboardArrowRight
                        style={{transform: open ? 'rotate(90deg)' : 'rotate(0deg)', transition: '0.3s'}}
                    />
                    <MemoryOutlined fontSize={'large'} style={{color: open ? '#ff7815' : 'grey'}}/>
                </div>
                <Typography style={{flexBasis: '150px'}}
                            className={'no-select'}
                            unselectable="on"
                >
                    {serviceType || 'unknown'}
                </Typography>
                <Typography style={{flexBasis: '150px'}}
                            className={'no-select'}
                            unselectable="on"
                >
                    {region}
                </Typography>
                <Typography style={{flex: 1}}
                            className={'no-select'}
                            unselectable="on"
                >
                    {resourceArn}
                </Typography>
                <Typography
                    className={'no-select'}
                    unselectable="on"
                >
                    {missingTagCount}
                </Typography>
                <div style={{marginLeft: 16}}>
                    <WuButton style={{marginRight: 16, width: 150, height: 36}} onClick={(e) => handleFixResources(e)}
                              disabled={progressFix.loading}>
                        {progressFix.loading ? <WuLoading open={true}/> : 'FIX ALL'}
                    </WuButton>
                </div>
            </div>
            <Collapse in={open}>
                <>
                    {open && <MissingTags resourceTags={apiResponse}/>}
                    {
                        progress.loading &&
                        <WuLoading message={'Please wait...'} open={progress.loading} boxProps={{my: 2}}/>
                    }
                    <WuAlert error={Boolean(progress.error)} errorMessage={progress.error} success={false}/>
                </>

            </Collapse>
        </div>
    )
}

function MissingTags({resourceTags = [], resourceId, region, accountId}) {
    const [progress, setProgress] = useState({loading: false, ready: false, error: false})
    const [apiResponse, setApiResponse] = useState(null)

    const handleFixResources = async (e, key, value) => {
        try {
            e.stopPropagation()
            if(!key) return
            setProgress({loading: true, ready: false, error: false})
            const params = {
                resourceId: resourceId,
                tagKey: key,
                tagValue: value,
                accountId,
                region
            }
            const {data: {data}} = await service.monitoring().tagResourceAccountWise(params)
            setApiResponse(data)
            setProgress({loading: false, ready: true, error: false})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setProgress({loading: false, ready: true, error: error})
        }
    }

    return (
        <div className={'tc-resource-tag'}>
            {
                resourceTags.map(e => <div className={'tc-tag-item'} key={e?.tagKey}>
                        <span style={{flexBasis: '40px'}}>
                        <div
                            className={e?.tagExpectedValue ? 'tc-tag-icon tc-item-fixable' : 'tc-tag-icon tc-item-non-fixable'}>
                            <LocalOfferOutlined fontSize={'small'} style={{paddingLeft: 2, paddingTop: 0.5}}/>
                        </div>
                        </span>
                    <Typography style={{flexBasis: '200px'}} color={'textSecondary'} variant={'body2'}>
                        {e?.tagKey || '-'}
                    </Typography>
                    <Typography style={{
                        flexBasis: '200px',
                        textDecoration: 'line-through'
                    }}
                                variant={'body2'}
                                color={'textSecondary'}>
                        {e?.tagCurrentValue || '-'}
                    </Typography>
                    <Typography style={{flexBasis: '200px'}}>
                        {e?.tagExpectedValue || '-'}
                    </Typography>
                    <div className={'tc-tag-item-action'}>
                        <WuButton style={{marginRight: 16, width: 150, height: 36}}
                                  onClick={(e) => handleFixResources(e, e?.tagkey, e?.tagExpectedValue)}
                                  disabled={progress.loading}>
                            {progress.loading ? <WuLoading open={true}/> : 'FIX ME'}
                        </WuButton>
                    </div>
                </div>)}
        </div>
    )
}