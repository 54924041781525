export const gridDefinition = [
    {
        field: "Region_RegionCode",
        headerName: 'Region',
        resizable: true,
        sortable: true,
        suppressAutoSize: true,
    },
    {
        field: "Identifier",
        headerName: 'Identifier',
        resizable: true,
        sortable: true,
        suppressAutoSize: true,
    },
    {
        field: "ServiceName",
        headerName: 'Service Name',
        resizable: true,
        sortable: true,
        suppressAutoSize: true,
    },
    {
        field: "ServiceType",
        headerName: 'Service Type',
        resizable: true,
        sortable: true,
        suppressAutoSize: true,
    }]