import React, {useEffect, useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .01)',
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    details: {
        alignItems: 'center',
    },
    column: {
        flexBasis: '33.33%',
        paddingLeft: 10
    },
}));


/**
 *
 * @param {Object} props -  input props
 * @param {string} props.accountId -  account id
 * @param {string} props.accountAlias -  account accountAlias
 * @param {string} props.accessStatus -  access status for the account
 * @param {string} props.accountType -  account type either master or child
 * @param {string} props.onClick -  response onClick event
 * @param {JSX.Element} props.children -  children component to render/ summary
 * @param {string} props.warningLevel -  warning level for access
 * @return {JSX.Element}
 * @constructor
 */
export default function CrossAccountAccordionGeneral(props) {

    const {accountId, accountAlias, accessStatus, accountType, children, warningLevel} = {...props}
    const classes = useStyles();
    const [color, setColor] = useState("green");
    const [icon, setIcon] = useState(<ErrorOutlineOutlinedIcon/>);

    useEffect(() => {
        switch (warningLevel) {
            case "warning":
                setValues("orange", <ReportProblemOutlinedIcon/>)
                break
            case "success":
                setValues("green", <CheckCircleOutlineOutlinedIcon/>)
                break
            case "error":
                setValues("red", <ErrorOutlineOutlinedIcon/>)
                break
            default:
                setValues("black", <CheckCircleOutlineOutlinedIcon/>)
        }
    }, [props.warningLevel])


    const setValues = (color, icon) => {
        setColor(color);
        setIcon(icon)
    }
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="cross-account-status"
                id="cross-account-status"
            >
                <div style={{color: color}}>
                    {icon}
                </div>
                <div className={classes.column}>
                    <Typography className={classes.heading}>{`${accountId} ( ${accountAlias})`}</Typography>
                </div>
                <div className={classes.column}>
                    <Typography className={classes.secondaryHeading}>{accessStatus}</Typography>
                </div>
                <div className={classes.column}>
                    <Typography className={classes.secondaryHeading}>{accountType}</Typography>
                </div>
            </AccordionSummary>
            <Divider/>
            {children}
        </Accordion>
    );
}