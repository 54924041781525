import {Collapse, Paper, Typography} from "@material-ui/core";
import WuStack from "../General/WuStack";
import {CheckCircleOutlineOutlined, ErrorOutlineRounded} from "@material-ui/icons";
import React from "react";

export function RequestSubmitted({open}) {
    return (
        <Collapse in={open}>
            <WuStack direction={'column'}
                     spacing={1}
                     boxProps={{mb: 2}}
                     justifyContent={'center'}
                     alignItems={'center'}>
                <CheckCircleOutlineOutlined fontSize={"large"} style={{color: 'green'}}/>
                <Typography variant={'h6'}>
                    Your request is submitted.
                </Typography>
                <Typography align={'center'}>
                    We will review your request. Once your user is approved, will let you know!
                </Typography>

            </WuStack>
        </Collapse>
    )
}

export function RequestFailed({open, error}) {
    return (
        <Collapse in={open}>
            <Paper style={{paddingTop: 16, paddingRight: 8, paddingLeft: 16, paddingBottom: 4, marginTop: 8}}>
                <WuStack direction={'column'}
                         spacing={1}
                         boxProps={{mb: 2}}
                         justifyContent={'left'}
                         alignItems={'start'}>
                    <WuStack spacing={1} alignItems={'center'}>
                        <ErrorOutlineRounded fontSize={"large"} style={{color: 'red'}}/>
                        <Typography variant={'h6'}>
                            Request Failed!
                        </Typography>
                    </WuStack>

                    <Typography align={'left'}>
                        Something went wrong!. Consider fixing the following error or contact administrator. {error}
                    </Typography>
                </WuStack>
            </Paper>
        </Collapse>
    )
}