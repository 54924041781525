import {WuAccordionDetails, WuAccordionSummary, WuAccordionWrapper} from "../../../General";
import {Typography} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import AccountWiseData from "./AccountWiseData";

export default function AccountWiseSummary(props) {
    const {account_id, account_alias, count} = {...props}

    return (
        <WuAccordionWrapper TransitionProps={{unmountOnExit: true}}>
            <WuAccordionSummary>
                <InfoOutlinedIcon style={{paddingRight: 20, color: 'red'}}/>
                <Typography style={{flexBasis: '40%'}}>
                    {account_id || 'unknown'} ({account_alias || 'unknown'})
                </Typography>
                <Typography style={{flexBasis: '50%'}}>
                    {count?.toLocaleString() || 0}
                </Typography>
            </WuAccordionSummary>
            <WuAccordionDetails>
                <AccountWiseData accountId={account_id}/>
            </WuAccordionDetails>
        </WuAccordionWrapper>
    )
}