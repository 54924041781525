import React, {useEffect, useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import {ArrowBackRounded} from "@material-ui/icons";
import {useHistory} from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {Button} from "@material-ui/core";
import {ProgressCircleCustomSize} from "../../ProgressCircle/ProgressCircle";
import service from "../../../service/service";
import PropTypes from "prop-types";

const tagList = [
    {value: "ApplicationName", tagKey: "ApplicationName"},
    {value: "Environment", tagKey: "Environment"},
    {value: "SupportContact", tagKey: "SupportContact"},
    {value: "ApplicationOwner", tagKey: "ApplicationOwner"},
    {value: "CostCode", tagKey: "CostCenter"},
]

export default function UnUsedResourceFilter(props) {
    const {backButtonLink} = {...props}
    const history = useHistory();
    const [key, setKey] = useState("");
    const [value, setValue] = useState("");
    const [region, setRegion] = useState([]);
    const [error, setError] = useState(false);
    const [disabled, setDisabled] = useState(false)
    const [loading, setLoading] = useState(false);
    const [tagValueList, setTagValueList] = useState([]);
    const redirect = () => {
      <div>
           <unusedDownload/>
      </div>
      }

    useEffect(() => {
        if (key && !value) {
            setError(true);
            setDisabled(true);
        } else {
            setDisabled(false);
            setError(false);
        }

        if (!key) {
            setValue("");
        }
    }, [key, value])


    useEffect(() => {
        if (key) {
            setLoading(true);
            const params = {
                searchKey: key
            }
            service.general().getApplicationColumns(params)
                .then(res => {
                    setTagValueList(res.data.data);
                    setLoading(false);

                })
                .catch(err => {
                    setLoading(false)
                })
        }
    }, [key])


    const onResourceSearch = () => {
        props.onClick({
            region: region,
            key: key,
            value: value
        })
    }

    return (
        <div className="optimizer-filters">
            <IconButton onClick={() => {
                history.push(backButtonLink);

            }}
                        style={{marginLeft: "-16px", marginRight: "-15px"}}
                        aria-label="back" size="medium">
                <ArrowBackRounded fontSize="inherit"/>
            </IconButton>

            <div>


                <Autocomplete
                    id={"tag"}
                    options={tagList}
                    fullWidth
                    loading={loading}
                    getOptionLabel={
                        (tagKey) =>
                            tagKey.tagKey ? tagKey.tagKey : "empty tag"
                    }
                    onChange={(event, value) => {
                        setKey(value ? value.value : "");
                        setValue("");
                    }}
                    style={{
                        marginTop: 10,
                        marginBottom: 10,
                        minWidth: 300
                    }}
                    size={"small"}
                    renderInput={(params) => <TextField {...params} label={"Optional Tag Key"}
                                                        variant="outlined"/>}
                />
            </div>
            <div>
                <Autocomplete
                    id={"region"}
                    loading={loading}
                    options={tagValueList}
                    fullWidth
                    disabled={key === ""}
                    getOptionLabel={
                        (option) =>
                            option[key] ? option[key] : "no data"
                    }
                    onChange={(event, value) => {
                        setValue(value ? value[key] : "")
                    }}
                    style={{
                        marginTop: 10,
                        marginBottom: 10,
                        minWidth: 300
                    }}
                    size={"small"}
                    renderInput={(params) => <TextField  {...params} label={"Optional Tag Key"}
                                                         variant="outlined"/>}
                />
            </div>
            <Button onClick={() => onResourceSearch()} style={{height: 36}} disabled={disabled} disableElevation
                    color={"secondary"}
                    variant={"contained"}>
                Find Resources
            </Button>
            
            {
                loading &&
                <ProgressCircleCustomSize size={20}/>
            }

        </div>
    )
}


UnUsedResourceFilter.propTypes = {
    backButtonLink:PropTypes.string.isRequired
}