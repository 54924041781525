import React, {useEffect, useState} from 'react';
import {Divider, Paper} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import WuSummaryCard from "../../General/WuSummaryCard";
import WuButton from "../../General/WuButton";
import moment from "moment";
import {SummaryText} from "../../General/CronSummaryCard";
import Icon from "../../General/CronSummaryCard/StatusIcon";
import WuSnackBarStatus from "../../General/WuSnckBar";
import {apiErrorResponseJsonParser} from "../../../helper/generalFunctions";
import {useOrganization} from "../../../hooks/useOrganization";

export default function SummaryCard(props) {
    const {organization: masterAccountId} = useOrganization()
    const {
        downloadComponent,
        summaryCardProps,
        refreshApiFunction,
        summaryApiFunction,
        priceDisabled = false
    } = {...props}
    const {countCard} = {...summaryCardProps}
    const {itemKey, description, helpText, nameOfItem, title} = {...countCard}

    const [cronApiProgress, setCronApiProgress] = useState({loading: false, ready: false, error: false})
    const [onDemandStatus, setOnDemandStatus] = useState({ready: false, message: ''})
    const [cronStatus, setCronStatus] = useState(null)

    const runRequest = async (fn, isCronRefresh) => {
        try {
            setCronApiProgress({loading: true, error: false, ready: false})
            setOnDemandStatus({ready: false, message: ''})
            const {data: {data}} = await fn({master_account_id: masterAccountId})
            if (isCronRefresh) {
                setOnDemandStatus({ready: true, message: data})
                handleRequest(false)
            } else {
                setCronStatus(data)
                setCronApiProgress({loading: false, error: false, ready: true})
            }
        } catch (e) {
            const errorText = apiErrorResponseJsonParser(e)
            setCronApiProgress({loading: false, ready: false, error: errorText ? errorText : e.message})
            if (isCronRefresh) {
                setCronStatus({ready: false, message: errorText ? errorText : e.message})
            }
        }
    }

    const handleRequest = (isCronRefresh) => {
        if (isCronRefresh) runRequest(refreshApiFunction, true).then()
        else runRequest(summaryApiFunction, false).then()
    }

    useEffect(() => {
        if (masterAccountId !== 'no org') {
            handleRequest(false)
        }
    }, [masterAccountId])

    return (
        <Paper variant={'outlined'} style={{minWidth: 250}}>
            {downloadComponent}
            <WuSnackBarStatus open={onDemandStatus.ready}
                              severity={'info'}
                              onClose={() => setOnDemandStatus({ready: false, message: ''})}
                              message={'process running in background'}/>
            <Box m={2} className={'gap'} display={'flex'} flexDirection={'column'}>
                <WuSummaryCard
                    title={`${moment().format('MMMM Y')} Cost`}
                    value={`$${cronStatus?.totalSavings ? Math.round(cronStatus?.totalSavings).toLocaleString() : 'unknown' || 0}`}
                    loading={cronApiProgress.loading}
                    description={'Month to date total savings'}
                    borderBottom={true}
                    disabled={priceDisabled}
                    borderBottomColor={"#ff1313"}
                    helpText={`Total month to date total spend for the ${nameOfItem}`}/>
                <WuSummaryCard
                    title={title}
                    value={cronStatus && cronStatus[itemKey] ? cronStatus[itemKey] : 'unknown' || 0}
                    loading={cronApiProgress.loading}
                    description={description}
                    borderBottom={true}
                    borderBottomColor={"#4cb207"}
                    helpText={helpText}/>

                <Divider/>
                <WuButton variant={'contained'} disableElevation color={'default'} onClick={() => handleRequest(true)}>
                    Refresh Recommendations
                </WuButton>
                <Divider/>
                <Box className={'gap'} display={'flex'}
                     flexDirection={'column'}
                     ml={1}>
                    <SummaryText title={'Refresh status'}
                                 value={cronStatus?.processStatus ? cronStatus?.processStatus : 'FAILED'}
                                 node={<Icon
                                     status={cronStatus?.processStatus ? cronStatus?.processStatus : 'ERROR'}/>}/>
                    <Divider/>
                    <SummaryText title={'Last updated At'}
                                 value={cronStatus?.lastRunTime ? cronStatus.lastRunTime : 'Unknown'}/>
                </Box>
            </Box>
        </Paper>
    )
}
