import React from 'react';
import SideMainMenu from "./SideMainMenu";
import {Collapse} from "@material-ui/core";


export default function SideSubMenu({child = [], expanded = false, drawerExpanded}) {
    return (
        <ul>
            <Collapse in={expanded}>
            {
                child.map((childData, idx) => <SideMainMenu key={idx} {...childData} drawerExpanded={drawerExpanded}/>)
            }
            </Collapse>
        </ul>
    )
}
