import React, {useEffect, useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import {ArrowBackRounded} from "@material-ui/icons";
import Region from "../../General/Region/Region";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {Button, Collapse} from "@material-ui/core";
import {ProgressCircleCustomSize} from "../../ProgressCircle/ProgressCircle";
import service from "../../../service/service";
import {useHistory} from "react-router-dom";
import {useComputeOptimizer} from "../../../hooks/useComputeOptimizer";


const tagList = [
    {value: "ApplicationName", tagKey: "ApplicationName"},
    {value: "Environment", tagKey: "Environment"},
    {value: "SupportContact", tagKey: "SupportContact"},
    {value: "ApplicationOwner", tagKey: "ApplicationOwner"},
    {value: "CostCode", tagKey: "CostCenter"},
]

export default function ComputeOptimizerFilter(props) {
    const recommendation = useComputeOptimizer()
    const history = useHistory();
    const [key, setKey] = useState("");
    const [value, setValue] = useState("");
    const [region, setRegion] = useState([]);
    const [error, setError] = useState(false);
    const [disabled, setDisabled] = useState(false)
    const [loading, setLoading] = useState(false);
    const [tagValueList, setTagValueList] = useState([]);
    const [recommendationType, setRecommendationType] = useState("");

    useEffect(() => {
        setRecommendationType(recommendation.recommendationType)
    }, [recommendation.recommendationType])

    useEffect(() => {
        if (recommendationType === "") {
            setKey("");
            setValue("");
        }

    }, [recommendationType])

    useEffect(() => {
        if (key && !value) {
            setError(true);
            setDisabled(true);
        } else {
            setDisabled(false);
            setError(false);
        }

        if (!key) {
            setValue("");
        }
    }, [key, value])


    useEffect(() => {
        if (key) {
            setLoading(true);
            const params = {
                searchKey: key
            }
            service.general().getApplicationColumns(params)
                .then(res => {
                    setTagValueList(res.data.data);
                    setLoading(false);

                })
                .catch(err => {
                    setLoading(false)
                })
        }
    }, [key])


    const onResourceSearch = () => {
        props.onClick({
            region: region,
            key: key === 'CostCode' ? 'CostCenter' : key,
            value: value
        })
    }

    useEffect(() => {
        if (!key && !value && !region) {
            props.onClick({
                region: region,
                key: key,
                value: value
            })
        }
    }, [key])


    return (
        <Collapse in={recommendationType !== ''}>
            <div className="optimizer-filters">
                <IconButton onClick={() => {
                    history.push("/recommendations/compute-optimizer/summary");
                    setRecommendationType("");
                }}
                            style={{marginLeft: "-16px", marginRight: "-15px"}}
                            aria-label="back" size="medium">
                    <ArrowBackRounded fontSize="inherit"/>
                </IconButton>

                <div style={{width: 300}}>
                    <Region disabled={false} onChange={(event) => setRegion(event.regionList)}/>
                </div>
                <div>
                    <Autocomplete
                        id={"tag"}
                        options={tagList}
                        fullWidth
                        loading={loading}
                        getOptionLabel={
                            (tagKey) =>
                                tagKey.tagKey ? tagKey.tagKey : "empty tag"
                        }
                        onChange={(event, value) => {
                            setKey(value ? value.value : "");
                            setValue("");
                        }}
                        style={{
                            marginTop: 10,
                            marginBottom: 10,
                            minWidth: 300
                        }}
                        size={"small"}
                        renderInput={(params) => <TextField {...params} label={"Optional Tag Key"}
                                                            variant="outlined"/>}
                    />
                </div>
                <div>
                    <Autocomplete
                        id={"region"}
                        loading={loading}
                        options={tagValueList}
                        fullWidth
                        disabled={key === ""}
                        getOptionLabel={
                            (option) =>
                                option[key] ? option[key] : "no data"
                        }
                        onChange={(event, value) => {
                            setValue(value ? value[key] : "")
                        }}
                        style={{
                            marginTop: 10,
                            marginBottom: 10,
                            minWidth: 300
                        }}
                        size={"small"}
                        renderInput={(params) => <TextField  {...params} label={"Optional Tag Value"}
                                                             variant="outlined"/>}
                    />
                </div>

                <Button onClick={() => onResourceSearch()} style={{height: 36}} disabled={disabled} disableElevation
                        color={"secondary"}
                        variant={"contained"}>
                    Find Resources
                </Button>
                {
                    loading &&
                    <ProgressCircleCustomSize size={20}/>
                }

            </div>
        </Collapse>
    )
}