import {
    CpuUsage,
    DetailsPageCluster,
    DetailsPageService,
    LaunchType,
    MemoryUsage
} from "../GridColumComponents";

import moment from "moment";

export const columns = [
    {
        headerName: 'Service Arn',
        field: 'serviceArn',
        sort: true,
        cellRendererFramework: DetailsPageService
    },
    {
        field: 'ApplicationName',
    },
    {
        headerName: `${moment().format('MMMM')} Price`,
        field: 'price',
        autoHeight: true,
        wrapText: true,
        valueFormatter: function ({value}) {
            return Number(value || 0).toFixed(2)
        },
        sort: true,
    },
    {
        headerName: `${moment().subtract('1', 'months').format('MMMM')} Price`,
        field: 'previousPrice',
        autoHeight: true,
        wrapText: true,
        valueFormatter: function ({value}) {
            return Number(value || 0).toFixed(2)
        },
        sort: true,
    },
    {
        headerName: `YTD Price`,
        field: 'yearlySpend',
        autoHeight: true,
        wrapText: true,
        valueFormatter: function ({value}) {
            return Number(value || 0).toFixed(2)
        },
        sort: true,
    },
    {
        headerName: 'Launch Type',
        field: 'launchType',
        sort: true,
        cellRendererFramework: LaunchType
    },
    {
        headerName: 'Cluster',
        field: 'clusterArn',
        sort: true,
        cellRendererFramework: DetailsPageCluster
    },
    {
        field: 'ApplicationCode',
    },
    {
        field: 'ApplicationOwner',
    },
    {
        field: 'CostCenter',
    },
    {
        field: 'SupportContact',
    },
    {
        headerName: 'CPU Usage',
        field: 'cpuAverage',
        sort: true,
        cellClassRules: {'cell-width-adjuster-class': 'true'},
        resizable: false,
        cellRendererFramework: CpuUsage
    },
    {
        headerName: 'Memory Usage',
        field: 'memoryAverage',
        cellClassRules: {'cell-width-adjuster-class': 'true'},
        sort: true,
        cellRendererFramework: MemoryUsage
    },
    {
        headerName: 'CreatedOn',
        field: 'createdOn',
        valueFormatter: function({value}){
            return moment(value, 'YYYY-MM-DD HH:mm:ss.SSS').format('DD MMMM, YYYY')
        },
        sort: true,
    },
]
