import react, {useEffect, useState} from "react";
import "./data_grid.scss";
import {AgGridReact} from "@ag-grid-community/react";
import {AllCommunityModules} from "@ag-grid-community/all-modules";
import Pagination from "@material-ui/lab/Pagination";
import {dataGridDataProcessing} from "./DataParser";
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import {SaveOutlined, SettingsOutlined} from "@material-ui/icons";
import Setting from "./Setting";
import Box from "@material-ui/core/Box";
import {Fade, Paper} from "@material-ui/core";


function Input(props) {
    const {value} = {...props}
    return (
        <input
            ref={(input) => {
                if (input) {
                    if (input.value) {
                        if (input.value.length >= 0) {
                            input.focus();
                        }
                    }
                }
            }}
            type="text"
            placeholder="Search here"
            value={value}
            onChange={(e) => props.onChange(e.target.value)}
            className="global-filter"
        />
    )
}

/**
 *
 * @param props -{Object} - properties
 * @param props.gridData -{Object} - grid data with row and column
 * @param props.gridClass -{string} - wrapper class to be applied
 * @param props.fixedColumn -{Object} - if no dynamic columns are required pass static
 * @param props.frameworkComponents -{JSX.Element} - custom JSX content for column
 * @param props.fileName -{string} - export csv file name
 * @param props.update -{boolean} - should table need refresh?
 * @param props.gridStyle -{Object} - inline styles for outer wrapper
 * @param props.hideTopControl -{string} - hide control
 * @param props.onChange -{function} - on change function
 * @deprecated since new index method added
 * @returns {JSX.Element}
 * @constructor
 */

export default function DataGrid(props) {

    const {
        gridData,
        gridClass = "data-grid-wrapper",
        fixedColumn,
        frameworkComponents,
        components,
        fileName,
        update,
        gridStyle,
        rowClickSelection = false,
        hideTopControl = false,
        gridRowSelection = 'single'
    } = props
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [gridOption, setGridOption] = useState({});
    const [paginationSize, setPaginationSize] = useState(0);
    const [globalSearch, setGlobalSearch] = useState("");
    const [gridMetaData, setGridMetaData] = useState({
        totalRows: 0,
        currentCount: 0,
        currentPage: 1
    })

    useEffect(() => {
        setGridOption(dataGridDataProcessing(gridData));
    }, [])

    useEffect(() => {
        if (update === true) {
            if (gridData?.length >= 1 && gridApi) {
                gridApi.setRowData(dataGridDataProcessing(gridData).row)
                setPaginationSize(gridApi.paginationGetTotalPages())
            } else console.log("Oops no data")
        }
    }, [props])

    const onGridReady = params => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    }

    const onFirstDataRendered = (params) => {
        //gridApi.sizeColumnsToFit();
        setPaginationSize(params.api.paginationGetTotalPages())

        if (gridOption.column.length > 10) {
            if (fixedColumn) {
                if (fixedColumn.length > 10) handleSizeChange()
                else sizeToFit()
            } else handleSizeChange()
        } else {
            sizeToFit()
        }

        setGridMetaData({
            totalRows: params.api.paginationGetRowCount(),
            currentCount: params.api.paginationGetPageSize()
        })
    }

    const handleSizeChange = () => {
        let allColumnIds = [];
        gridColumnApi.getAllColumns().forEach(function (column) {
            allColumnIds.push(column.colId);
        });
        gridColumnApi.autoSizeColumns(allColumnIds, false);
    }

    const sizeToFit = () => {
        gridApi.sizeColumnsToFit();
    };

    const exportData = () => {
        let params = {
            fileName: `${fileName ? (fileName + "-" + globalSearch) : (globalSearch ? globalSearch : new Date().toISOString())}.csv`,
        };
        gridApi.exportDataAsCsv(params)
    }

    const onSelectionChanged = (e) => {
        let selectedRows = gridApi.getSelectedRows();
        props.onChange(selectedRows)
    }

    function GridSettingsAndAdjustments() {
        const handlePaginationChange = (pageNumber) => {
            let gridProps = {...gridMetaData}
            let currentCount = pageNumber * gridApi.paginationGetPageSize();
            gridApi.paginationGoToPage(pageNumber - 1)
            gridProps['currentPage'] = pageNumber
            if (currentCount >= gridProps.totalRows) {
                gridProps['currentCount'] = gridProps.totalRows
            } else {
                gridProps['currentCount'] = currentCount
            }
            setGridMetaData(gridProps)
        }

        return (
            <div className="data-grid-adjustments">
                <div className="d-f">
                    <Input
                        value={globalSearch}
                        onChange={(e) => {
                            gridApi.setQuickFilter(e);
                            setGlobalSearch(e);
                            setPaginationSize(gridApi?.paginationProxy?.totalPages);
                        }}/>

                </div>
                <div className="d-f grid-properties">
                    <p style={{minWidth: 50}}>{/*{gridMetaData.currentCount} /*/} Total: {gridApi?.paginationProxy?.masterRowCount}</p>
                    <Pagination
                        style={{flexWrap: "nowrap"}}
                        onChange={(e, value) => handlePaginationChange(value)}
                        count={paginationSize}
                        defaultPage={gridMetaData.currentPage}
                        hideNextButton
                        hidePrevButton/>
                    <Box display={'flex'}>
                        <Setting columnDefinition={gridColumnApi} gridApi={gridApi}/>
                        <IconButton onClick={exportData} aria-label="delete" size="medium">
                            <SaveOutlined fontSize="inherit"/>
                        </IconButton>
                    </Box>
                </div>
            </div>
        )
    }

    return (
        <Fade in={true} timeout={700}>
            <Paper elevation={0}>
                <div className="ag-theme-custom-react">
                    {!hideTopControl && <GridSettingsAndAdjustments/>}
                    <div style={gridStyle} className={gridClass}>
                        <AgGridReact
                            defaultColDef={{
                                resizable: true,
                                editable: false,
                                filter: 'agTextColumnFilter',
                                floatingFilter: false,
                                suppressAutoSize: true,
                                //multiSortKey: 'ctrl'
                            }}
                            //headerHeight={40}
                            enableCellChangeFlash={true}
                            frameworkComponents={frameworkComponents}
                            components={components}
                            animateRows={true}
                            pagination={true}
                            rowSelection={gridRowSelection}
                            paginationAutoPageSize={true}
                            enableCellTextSelection={true}
                            modules={AllCommunityModules}
                            suppressRowClickSelection={!rowClickSelection}
                            onSelectionChanged={onSelectionChanged}
                            //onCellClicked={onSelectionChanged}
                            onGridReady={onGridReady}
                            suppressPaginationPanel={true}
                            onFirstDataRendered={onFirstDataRendered}
                            columnDefs={fixedColumn ? fixedColumn : gridOption.column}
                            rowData={gridOption.row}>

                        </AgGridReact>
                    </div>
                </div>
            </Paper>
        </Fade>
    );
}