import React, {useState} from "react";
import {BudgetSummaryCard} from "../Budget";
import TableV2 from "./TableV2";
import Box from "@material-ui/core/Box";
import PageTitle from "../../General/PageTitle/PageTitle";
import {IconButton} from "@material-ui/core";
import {AddOutlined} from "@material-ui/icons";

export default function Account(props) {

    const [budgetTotal, setBudgetTotal] = useState({total: 0, count: 0})
    const [addButtonClick, setAddButtonClick] = useState(false);

    function getBudgetTotal(tot, cnt) {
        setBudgetTotal({total: tot, count: cnt})
    }

    const disabled = localStorage.getItem("loginType") !== "admin";
    return (

        <div className="budget-wrapper">
            <Box mt={2}>
                <PageTitle title={"Account Budgets"} subTitle={'Budgets for accounts'}/>
            </Box>
            <div className="summary-wrapper">
                <BudgetSummaryCard totalAmount={budgetTotal['total']} numberOfBudgets={budgetTotal['count']}/>
                {!disabled && <IconButton onClick={() => setAddButtonClick(true)}>
                    <AddOutlined/>
                </IconButton>}
            </div>

            <div className="table">
                <TableV2 getBudgetTotal={getBudgetTotal} addButton={addButtonClick}
                         onClick={() => setAddButtonClick(false)}/>
            </div>
        </div>
    )
}



