import {useEffect, useState} from "react";
import moment from "moment";
import JSONPretty from "react-json-pretty";
import {Grid, Paper, Popover, Typography} from "@material-ui/core";
import {Box} from "@material-ui/core";
import {CpuUsage, DetailsPageCluster, MemoryUsage, StatusComponent} from "../GridColumComponents";
import {apiErrorResponseJsonParser} from "../../../helper/generalFunctions";
import {CpuUtilizationGraph, MemoryUtilizationGraph} from "../Graphs";
import service from "../../../service/service";
import PriceDistributionGraph from "../Graphs/PriceDistributionGraph";
import {useOrganization} from "../../../hooks/useOrganization";
import {ecsServiceDescription} from "../../../assets/finthesys/ecs";

const fontProperties = {fontWeight: 600}

export function ServiceDetails({apiProgress, setApiProgress, extraParams}) {
    const {organization: masterAccountId} = useOrganization()
    const [serviceData, setServiceData] = useState({})
    const {accountId, clusterArn, serviceArn, region} = {...extraParams}

    const fetchData = async () => {
        try {
            setApiProgress({loading: true, ready: false, error: false})
            const params = {
                accountId: accountId,
                clusterArn: clusterArn,
                serviceArn: serviceArn,
                region: region,
                masterAccountId: masterAccountId
            }
            const {data: {data}} = await service.ecs().describeEcsService(params)
            setServiceData(data)
            setApiProgress({loading: false, ready: true, error: false})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setApiProgress({loading: false, ready: false, error: error})
        }
    }


    const fetchDummyData = async () => {
        try {
            setApiProgress({loading: true, ready: false, error: false})
            const params = {
                accountId: accountId,
                clusterArn: clusterArn,
                serviceArn: serviceArn,
                region: region,
                masterAccountId: masterAccountId
            }
            const {data} = ecsServiceDescription
            setServiceData(data)
            setApiProgress({loading: false, ready: true, error: false})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setApiProgress({loading: false, ready: false, error: error})
        }
    }

    useEffect(() => {
        if(masterAccountId !== 'no org'){
            fetchDummyData().then()
        }
    }, [masterAccountId])

    return (
        <div className={'cluster-additional-details'}>
            {apiProgress.ready && <div className={'cluster-additional-details-inner'}>
                <Box display={'flex'} alignItems={'center'} gridGap={8} mb={2.5}>
                    <TagInformation tagKey={'ApplicationName'} tagValue={serviceData?.applicationName}/>
                    <TagInformation tagKey={'Environment'} tagValue={serviceData?.environment}/>
                    <TagInformation tagKey={'CostCenter'} tagValue={serviceData?.costCenter}/>
                    <TagInformation tagKey={'ApplicationOwner'} tagValue={serviceData?.applicationOwner}/>
                    <TagInformation tagKey={'ApplicationCode'} tagValue={serviceData?.applicationCode}/>
                </Box>

                <Box display={'flex'}
                     alignItems={'center'}
                     className={'cluster-overall-info'}
                     style={{marginBottom: 24}}
                     width={'100%'}
                     gridGap={16}>

                    <ShortSummaryCard title={'Service Status'}>
                        <StatusComponent value={serviceData.status}/>
                    </ShortSummaryCard>
                    <ShortSummaryCard title={'Created At'}>
                        <Typography style={fontProperties}>
                            {moment(serviceData.createdAt).format('DD MMMM, YYYY')}
                        </Typography>
                    </ShortSummaryCard>
                    <ShortSummaryCard title={'Cluster'}>
                        <Typography style={fontProperties} component={'div'}>
                            <DetailsPageCluster
                                data={{
                                    accountId: serviceData.accountId,
                                    accountAlias: serviceData.accountAlias,
                                    clusterArn: serviceData.clusterArn,
                                    region: serviceData.region
                                }}
                                value={clusterArn}/>
                        </Typography>
                    </ShortSummaryCard>
                    <ShortSummaryCard title={'CPU Utilization'}>
                        <CpuUsage data={{cpuAverage: serviceData.cpuAverage}}/>
                    </ShortSummaryCard>
                    <ShortSummaryCard title={'Memory Utilization'}>
                        <MemoryUsage data={{
                            memoryAverage: serviceData.memoryAverage
                        }}/>
                    </ShortSummaryCard>
                </Box>

                <Grid spacing={3} container>
                    <Grid item xs={6}>
                        <PriceDistributionGraph title={'Price distribution over months'}
                                                data={serviceData?.graph?.price}
                                                height={'400px'}/>

                    </Grid>
                    <Grid item xs={6}>
                        <Grid spacing={2} container>
                            <Grid item xs={12}>
                                <CpuUtilizationGraph title={'CPU Utilization'}
                                                     data={serviceData?.graph?.cpu}
                                                     height={'150px'}/>
                            </Grid>
                            <Grid item xs={12}>
                                <MemoryUtilizationGraph title={'Memory Utilization'}
                                                        data={serviceData?.graph?.memory}
                                                        height={'180px'}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={3} style={{marginTop: 16, marginBottom: 24}}>
                    <Grid item xs={6}>
                        <Paper variant={'outlined'} className={'container-summary-card-additional-info'}>
                            <Summary title={'Task Definition'} value={serviceData.taskDefinition}/>
                            <Summary title={'Task Placement Strategy'} value={serviceData.taskPlacementStrategy}/>
                            <Summary title={'Deployment Controller'} value={serviceData.deploymentController}/>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper variant={'outlined'} className={'container-summary-card-additional-info'}>
                            <Summary title={'Account'} value={`${serviceData.accountId}(${serviceData.accountAlias})`}/>
                            <Summary title={'Availability Zone'} value={serviceData.availabilityZone}/>
                            <Summary title={'Container Instance'} value={serviceData.containerInstanceArn}/>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper variant={'outlined'} className={'container-summary-card-additional-info'}>
                            <NestedObjectConfiguration title={'Deployment configuration'}
                                                       value={serviceData?.deploymentConfiguration || []}/>
                            <NestedObjectConfiguration title={'Load balancers'}
                                                       value={serviceData?.loadBalancers || []}/>
                            <Summary title={'Scheduling Strategy'} value={serviceData.schedulingStrategy}/>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper variant={'outlined'} className={'container-summary-card-additional-info'}>
                            <Summary title={'Subnets'} value={serviceData?.subnets?.join(',') || ''}/>
                            <Summary title={'Security Groups'} value={serviceData?.securityGroups?.join(',') || ''}/>
                            <Summary title={'Assign Public IP'} value={serviceData.assignPublicIp}/>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper variant={'outlined'} className={'container-summary-card-additional-info'}>
                            <Summary title={'Auto Scaling'} value={serviceData.autoScaling}/>
                        </Paper>
                    </Grid>
                </Grid>
            </div>}
        </div>
    )
}

function Summary({title, value}) {
    return (
        <div className={'container-summary-card-task'}>
            <Typography variant={'body1'} gutterBottom>
                {title}
            </Typography>
            <Typography variant={'body1'} style={fontProperties}>
                {value}
            </Typography>
        </div>
    )
}

function TagInformation({tagKey, tagValue = '-'}) {
    return (
        <div className={'container-tag'}>
            {tagKey}:
            <Typography component={'span'} style={{fontWeight: 600, paddingLeft: 8}}>
                {tagValue}
            </Typography>
        </div>
    )
}

function ShortSummaryCard({title, children}) {
    return (
        <Paper variant={'outlined'} style={{padding: 16}}>
            <Typography gutterBottom color={'textSecondary'}>{title}</Typography>
            {children}
        </Paper>
    )
}

function NestedObjectConfiguration({title, value}) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handlePopOverOpen = (e) => {
        setAnchorEl(e.currentTarget)
    }

    return (
        <div className={'container-summary-card-task'}>
            <Typography gutterBottom color={'textSecondary'}>{title}</Typography>
            <div style={{fontWeight: 600, cursor: 'pointer'}} onMouseEnter={handlePopOverOpen}>
                {'{...}'}
            </div>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={handleClose}
                disableRestoreFocus
            >
                <JSONPretty json={value}/>
            </Popover>
        </div>
    )
}