import {useEcs} from "../../../hooks";
import DataGrid from "../../General/DataGrid";
import {Box} from "@material-ui/core";
import {columns} from './gridProperties'
import {useEffect, useState} from "react";
import service from "../../../service/service";
import {apiErrorResponseJsonParser} from "../../../helper/generalFunctions";
import WuLoading from "../../General/WuLoading";
import WuAlert from "../../General/WuAlert";
import {ecsClusterServiceList} from "../../../assets/finthesys/ecs";

export default function Services(props) {
    const {accountId, open, filter: localFilter, gridHeight = '300px'} = {...props}
    const [apiProgress, setApiProgress] = useState({loading: false, ready: false, error: false})
    const [serviceData, setServiceData] = useState({})
    const {ecs} = useEcs()

    const fetchData = async () => {
        try {
            setApiProgress({loading: true, ready: false, error: false})
            const params = {
                accountId: accountId,
                filter: localFilter?.clusterArn ? localFilter : ecs.filter || null
            }
            const {data: {data}} = await service.ecs().listEcsService(params)
            setServiceData(data)
            setApiProgress({loading: false, ready: true, error: false})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setApiProgress({loading: false, ready: false, error: error})
        }
    }

    const fetchDummyData = async () => {
        try {
            setApiProgress({loading: true, ready: false, error: false})
            const params = {
                accountId: accountId,
                filter: localFilter?.clusterArn ? localFilter : ecs.filter || null
            }
            const {data} = ecsClusterServiceList
            setServiceData(data)
            setApiProgress({loading: false, ready: true, error: false})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setApiProgress({loading: false, ready: false, error: error})
        }
    }



    useEffect(() => {
        fetchDummyData().then()
    }, [accountId, open, ecs?.filter])

    return (
        <div className={'ecs-content-wrapper'}>
            <Box m={apiProgress.loading ? 1 : 0}>
                <WuLoading message={'service information loading. Please wait...'} open={apiProgress.loading}/>
            </Box>
            <WuAlert error={Boolean(apiProgress.error)} success={false} errorMessage={apiProgress.error}/>
            {apiProgress.ready && <DataGrid
                gridStyle={{height: serviceData.length > 5 ? gridHeight : serviceData.length === 1 ? "90px" : serviceData.length * 65 + "px"}}
                gridData={serviceData}
                fixedColumn={columns}
            />}
        </div>
    )
}