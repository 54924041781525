import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import service from "../../../../../service/service";
import {AlertWu} from "../../../../AlertWu/AlertWu";
import {PaperProgress} from "../../../../ProgressCircle/ProgressCircle";
import DataGrid from "../../../../General/DataGrid/DataGrid"
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select';
import {useOrganization} from "../../../../../hooks/useOrganization";
//dummy data
import tempData from '../../../../../assets/finthesys/savings-plan-recommendation.json'

const columns = [
    {
        field: "PaymentOption",
        headerName: 'Payment Option',
        resizable: true,
        sortable: true,
        suppressAutoSize: true,
    },
    {
        field: "CurrentMonthlyOnDemandSpend",
        headerName: 'Current Monthly Spend',
        resizable: true,
        sortable: true,
        suppressAutoSize: true,
    },
    {
        field: "EstimatedMonthlySpend",
        headerName: 'Estimated Monthly Spend',
        resizable: true,
        sortable: true,
        suppressAutoSize: true,
    },
    {
        field: "EstimatedMonthlySavingsAmount",
        headerName: 'Estimated Monthly Savings',
        resizable: true,
        sortable: true,
        suppressAutoSize: true,
    }]


export default function SavingsPlanRecommendation() {
    ;
    const {organization: masterAccountId} = useOrganization();
    const [loaded, setLoaded] = useState(false);
    const [errorBoundary, setErrorBoundary] = useState(false);
    const [error, setError] = useState("");

    const [recommendations, setRecommendations] = useState([]);
    const [loopBack, setLoopBack] = useState("SEVEN_DAYS");
    const [planType, setPlanType] = useState("COMPUTE_SP");
    const [term, setTerm] = useState("ONE_YEAR");


    function dataParsing(recommendations) {
        let payer = []
        let linked = []
        recommendations.data.map((data) => {
            if (data.AccountScope === "PAYER") {
                payer.push(data)
            } else if (data.AccountScope === "LINKED") {
                linked.push(data)
            }
        })
        setRecommendations({payer: payer, linked: linked})
        setLoaded(true);
    }


    useEffect(() => {
        if (masterAccountId !== 'no org') {
            setLoaded(false)
            const data = {
                "account_id": masterAccountId,
                "LookbackPeriodInDays": loopBack,
                "TermInYears": term,
                "SavingsPlansType": planType
            }

            /*service.insights().getSavingsPlanRecommendations(data)
                .then(res => {
                    if (res.data.code === 200) {
                        setRecommendations(res.data);
                        dataParsing(res.data);
                    }
                })
                .catch(err => {
                    if (err.request.response.code !== 200) {
                        setErrorBoundary(true);
                        setLoaded(true);
                        setError(err.message);
                    }
                })*/

            setTimeout(() => {
                setLoaded(false)
                setRecommendations(tempData);
                dataParsing(tempData);
            }, 500)
        }
    }, [masterAccountId, planType, term, loopBack])


    function SelectionBar() {
        return (
            <div style={{display: "flex", width: "500px", justifyContent: "space-between", marginBottom: 40}}>
                <div>
                    <p className="selection-items">Loopback period</p>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={loopBack}
                        margin={"dense"}
                        style={{width: 140}}
                        onChange={(event) => setLoopBack(event.target.value)}
                        label="Loopback"
                        variant={"outlined"}
                    >
                        <MenuItem value={'SEVEN_DAYS'}>7</MenuItem>
                        <MenuItem value={'THIRTY_DAYS'}>30</MenuItem>
                        <MenuItem value={'SIXTY_DAYS'}>60</MenuItem>
                    </Select>
                </div>
                <div>
                    <p className="selection-items">
                        Term
                    </p>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={term}
                        margin={"dense"}
                        style={{width: 140}}
                        onChange={(event) => setTerm(event.target.value)}
                        label="Loopback"
                        variant={"outlined"}
                    >
                        <MenuItem value={'ONE_YEAR'}> 1 Year</MenuItem>
                        <MenuItem value={'THREE_YEARS'}>3 Year</MenuItem>
                    </Select>
                </div>
                <div>
                    <p className="selection-items">
                        Plan Type
                    </p>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={planType}
                        margin={"dense"}
                        style={{width: 140}}
                        onChange={(event) => setPlanType(event.target.value)}
                        label="Loopback"
                        variant={"outlined"}
                    >
                        <MenuItem value={'COMPUTE_SP'}>Compute</MenuItem>
                        <MenuItem value={'EC2_INSTANCE_SP'}>EC2</MenuItem>
                    </Select>
                </div>
            </div>
        )
    }


    return (
        <div style={{marginBottom: 40}}>

            <SelectionBar/>
            {!errorBoundary && !loaded ? <PaperProgress/> :
                loaded && !errorBoundary &&
                <div className="savings-plan-4">

                    <p className="savingsplan-recommendations-summary">
                        Payer Account Type
                    </p>

                    <div className="savingsplan-recommendations-wrapper">
                        <DataGrid
                            fixedColumn={columns}
                            fileName={`saving-plan-recommendation-payer`}
                            gridStyle={{height: (((recommendations.payer).length + 1) * 50) + "px"}}
                            gridData={recommendations.payer}
                            gridClass="recommendations-grid"/>
                    </div>
                    <p className="savingsplan-recommendations-summary">
                        Linked Account Type
                    </p>
                    <div className="savingsplan-recommendations-wrapper">
                        <DataGrid
                            fixedColumn={columns}
                            fileName={`saving-plan-recommendation-linked`}
                            gridStyle={{height: (((recommendations.payer).length + 1) * 50) + "px"}}
                            gridData={recommendations.linked}
                            gridClass="recommendations-grid"/>
                    </div>
                </div>
            }

            {
                errorBoundary &&
                <AlertWu severity="error" message={"Something went wrong... Error type: " + error}/>
            }
        </div>
    );
}