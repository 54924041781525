import {Breadcrumbs} from "@material-ui/core";
import {KeyboardArrowRight} from "@material-ui/icons";

export default function BreadCrumbNavigation({breadcrumbs}) {
    return (
        <Breadcrumbs
            separator={<KeyboardArrowRight fontSize="small"/>}
            aria-label="breadcrumb"
        >
            {breadcrumbs}
        </Breadcrumbs>
    )
}