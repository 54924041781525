import React, {useEffect, useState} from "react";
import service from "../../service/service";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import RegionMultiSelect, {EnvironmentMultiSelect} from "./Region/Region"
import {TextAreaSkeleton} from "./Skeleton/Skeleton";
import {useOrganization} from "../../hooks/useOrganization";
import {Skeleton} from "@material-ui/lab";
import {Typography} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

function ApplicationName(props) {
    const organization = useOrganization()
    const {defaultValue, disabled = false} = {...props}

    const [applicationName, setApplicationName] = useState([]);
    const [currentValue, setCurrentValue] = useState(defaultValue || "")
    const [ready, setReady] = useState(false);
    const [loadingComplete, setLoadingComplete] = useState(false);
    const [masterAccountId, setMasterAccountId] = useState(organization.organization);

    useEffect(() => {
        setMasterAccountId(organization.organization)
    }, [organization])

    useEffect(() => {
        setReady(false);
        service.general().getApplicationColumns({searchKey: 'ApplicationName', masterAccountId: masterAccountId})
            .then((res) => {
                if (res.data.code === 200) {
                    setApplicationName(res.data.data);
                    setLoadingComplete(true);
                    setReady(true);
                }
            }).catch(err => {
            console.log(err,)
        })
    }, [masterAccountId])


    const handleChange = (event) => {
        if (event) {
            setCurrentValue(event);
            props.onChange({applicationName: event})
        }
    }
    return (
        <React.Fragment>
            {
                ready ?
                    <Autocomplete
                        size={"small"}
                        fullWidth
                        loading={!loadingComplete}
                        disabled={!loadingComplete || disabled}
                        options={applicationName.map(({ApplicationName}) => {
                            return ApplicationName ? ApplicationName : ""
                        })}
                        onChange={(event, value) => handleChange(value)}
                        value={currentValue}
                        style={{
                            marginTop: 10,
                            marginBottom: 6,
                            minWidth: props.width ? props.width : 200,
                            maxWidth: props.width ? props.width : "",
                        }}
                        renderInput={(params) => <TextField {...params} error={props.error} label="Application Name"
                                                            variant="outlined"/>}
                    /> : <Skeleton height={45}/>
            }
        </React.Fragment>

    )
}

function Environment(props) {
    const {defaultValue, disabled = false} = {...props}
    const [environment, setEnvironment] = useState([]);
    const [loadingComplete, setLoadingComplete] = useState(false)
    const [ready, setReady] = useState(false);
    const [currentValue, setCurrentValue] = useState(defaultValue || "")

    useEffect(() => {
        setReady(false);
        service.general().getApplicationColumns({searchKey: 'Environment'})
            .then((res) => {
                if (res.data.code === 200) {
                    setEnvironment(res.data.data);
                    setLoadingComplete(true)
                    setReady(true)
                }
            }).catch(err => {
            console.log(err)
        })
    }, [])


    const handleChange = (event) => {
        if (event) {
            setCurrentValue(event)
            props.onChange({environment: event})
        }
    }

    return (
        <React.Fragment>
            {
                ready ?
                    <Autocomplete
                        size={"small"}
                        getOptionLabel={option => option ? option : ""}
                        fullWidth
                        loading={!loadingComplete}
                        disabled={!loadingComplete || disabled}
                        value={currentValue}
                        inputValue={currentValue}
                        options={environment.map(({Environment}) => {
                            return Environment ? Environment : ""
                        })}
                        onChange={(event, value) => handleChange(value)}
                        style={{
                            marginTop: 10,
                            marginBottom: 6,
                            minWidth: props.width ? props.width : 200,
                            maxWidth: props.width ? props.width : "",
                        }}
                        renderInput={(params) => <TextField {...params} error={props.error} label="Environment"
                                                            variant="outlined"/>}
                    /> : <Skeleton height={45}/>
            }
        </React.Fragment>
    )
}

function Region(props) {
    const {defaultValue, disabled = false} = {...props}
    const [regions, setRegions] = useState([]);
    const [ready, setReady] = useState(false);
    const [currentValue, setCurrentValue] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            await handleRequest()
        }
        fetchData();
    }, [])

    useEffect(() => {
        let tempValue = regions.find(region => region.RegionCode === defaultValue);
        setCurrentValue(tempValue ? tempValue : "")
    }, [regions])


    const handleRequest = async () => {
        try {
            setReady(false);
            const response = await service.general().getRegionList()
            if (response.data.code === 200) {
                setRegions(response.data.data);
                setReady(true)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const handleChange = (event) => {
        if (event && event.RegionCode !== undefined) {
            setCurrentValue(event || "")
            props.onChange({region: event.RegionCode})
        }
    }

    return (
        <React.Fragment>
            {
                ready ?
                    <Autocomplete
                        size={"small"}
                        fullWidth
                        options={regions}
                        value={currentValue}
                        disabled={disabled}
                        onChange={(event, value) => handleChange(value)}
                        getOptionDisabled={({RegionCode}) => RegionCode === 'Global'}
                        getOptionSelected={(option, value) => option.RegionCode === value.RegionCode}
                        getOptionLabel={({RegionCode, RegionName}) => RegionCode ? `${RegionName} (${RegionCode})` : ""}
                        style={{
                            marginTop: 10,
                            marginBottom: 6,
                            minWidth: props.width ? props.width : 200,
                            maxWidth: props.width ? props.width : "",
                        }}
                        renderInput={(params) =>
                            <TextField {...params} error={props.error} label="Region"
                                       variant="outlined"/>
                        }
                        renderOption={({RegionCode, RegionName}) => {
                            return (
                                <>
                                    <Typography>
                                        {RegionName}
                                    </Typography>
                                    <Typography color={"textSecondary"}>
                                        &nbsp;
                                        {RegionCode}
                                    </Typography>
                                </>)
                        }}
                    /> : <Skeleton height={45}/>
            }
        </React.Fragment>
    )
}

function CostCode(props) {
    const organization = useOrganization()
    const [costCode, setCostCode] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [loadingComplete, setLoadingComplete] = useState(false)
    const {defaultValue} = {...props}
    const {freeSolo} = props
    const [masterAccountId, setMasterAccountId] = useState(organization.organization);

    useEffect(() => {
        setMasterAccountId(organization.organization)
    }, [organization])

    useEffect(() => {
        setLoaded(true);
        props.onChange({loading: loaded})
        service.general().getApplicationColumns({searchKey: 'CostCode', masterAccountId: masterAccountId})
            .then((res) => {
                if (res.data.code === 200) {
                    setCostCode(res.data.data);
                    setLoaded(false);
                    setLoadingComplete(true);
                    props.onChange({loading: loaded})
                }
            }).catch(err => {
            console.log(err)
        })
    }, [masterAccountId])


    const handleChange = (event) => {
        if (event) {
            if (event.CostCode !== undefined) {
                props.onChange({loading: loaded, costCode: event.CostCode})
            }
        }
    }

    return (
        <Autocomplete
            freeSolo={freeSolo ? true : false}
            id="cost_code"
            size={"small"}
            fullWidth
            options={costCode}
            loading={!loadingComplete}
            disabled={!loadingComplete}
            onChange={(event, value) => handleChange(value)}
            getOptionLabel={
                (cost) =>
                    cost.CostCode ? cost.CostCode : "no cost center"
            }
            value={defaultValue ? costCode.find(v => v.CostCode === defaultValue) : ""}
            style={{
                marginTop: 0,
                marginBottom: 0,
                minWidth: props.width ? props.width : 200,
                maxWidth: props.width ? props.width : "",
            }}
            renderInput={(params) => <TextField {...params}
                                                error={props.error}
                                                onChange={(e) => handleChange({CostCode: e.target.value})}
                                                label="Cost Center"
                                                variant="outlined"/>}
        />
    )
}

/**
 *
 * @param props{object} - props
 * @param {string | number} [props.masterAccount=] - master account information
 * @param { string | number} props.defaultValue  - default account
 * @returns {JSX.Element}
 * @constructor
 */
function Accounts(props) {
    const organization = useOrganization()
    const {defaultValue, disabled = false, autoCompleteProps, textFieldProps} = {...props}
    const [accounts, setAccounts] = useState([]);
    const [loadingComplete, setLoadingComplete] = useState(false)
    const [currentValue, setCurrentValue] = useState("");
    const [masterAccountId, setMasterAccountId] = useState(organization.organization);

    useEffect(() => {
        setMasterAccountId(organization.organization)
    }, [organization])

    useEffect(() => {
        const fetchData = async () => {
            await handleRequest()
        }
        fetchData();
    }, [masterAccountId])

    useEffect(() => {
        let tempValue = accounts.find(account => account.AccountId === defaultValue?.toString())
        setCurrentValue(tempValue ? tempValue : "")
    }, [accounts])

    const handleRequest = async () => {
        try {
            setLoadingComplete(false);
            const response = await service.general().getAccountInformationByMasterId({master_id: masterAccountId});
            if (response.data.code === 200) {
                setAccounts(response.data.account);
                setLoadingComplete(true);
            }
        } catch (e) {
            console.log(e)
        }
    }

    const handleChange = (event) => {
        if (event && event.AccountId !== undefined) {
            setCurrentValue(event);
            props.onChange({accountId: event.AccountId})
        }
    }

    return (
        <React.Fragment>
            {
                loadingComplete ?
                    <Autocomplete
                        id="application_name"
                        size={"small"}
                        fullWidth
                        options={accounts}
                        loading={!loadingComplete}
                        disabled={!loadingComplete || disabled}
                        onChange={(event, value) => handleChange(value)}
                        getOptionSelected={(option, value) => option.AccountId === value.AccountId}
                        getOptionLabel={
                            (account) =>
                                account.AccountId ? account.AccountAlias + " (" + account.AccountId + ")" : ""
                        }
                        value={currentValue}
                        style={{
                            marginTop: 0,
                            marginBottom: 0,
                            minWidth: props.width ? props.width : 200,
                            maxWidth: props.width ? props.width : "",
                        }}
                        renderInput={(params) => <TextField {...params} error={props.error} label="Accounts"
                                                            variant="outlined" {...textFieldProps}/>}
                        renderOption={({AccountId, AccountAlias}) => {
                            return (
                                <>
                                    <Typography>
                                        {AccountAlias}
                                    </Typography>
                                    <Typography color={"textSecondary"} variant={'subtitle2'}>
                                        &nbsp;
                                        ({AccountId})
                                    </Typography>
                                </>)
                        }}
                        {...autoCompleteProps}
                    /> : <TextAreaSkeleton/>
            }
        </React.Fragment>
    )
}


function AccountMultiSelect(props) {
    const {disabled = false, autoCompleteProps} = {...props}
    const {organization: masterAccountId} = useOrganization()
    const [accounts, setAccounts] = useState([])
    const [ready, setReady] = useState(false)
    const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
    const checkedIcon = <CheckBoxIcon fontSize="small"/>;

    const handleRequest = async () => {
        try {
            setReady(false);
            const response = await service.general().getAccountInformationByMasterId({master_id: masterAccountId});
            if (response.data.code === 200) {
                setAccounts(response.data.account);
                setReady(true);
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (masterAccountId !== 'no org') {
            handleRequest().then()
        }
    }, [masterAccountId])

    return (
        <React.Fragment>
            {
                ready ?
                    <Autocomplete
                        id="application_name"
                        size={"small"}
                        multiple
                        fullWidth
                        options={accounts}
                        disableCloseOnSelect
                        loading={!ready}
                        disabled={!ready || disabled}
                        getOptionSelected={(option, value) => option.AccountId === value.AccountId}
                        getOptionLabel={
                            (account) =>
                                account.AccountId ? account.AccountAlias + " (" + account.AccountId + ")" : ""
                        }
                        renderInput={(params) =>
                            <TextField {...params} label="Accounts" variant="outlined"/>}
                        renderOption={({AccountId, AccountAlias}, {selected}) => (
                            <React.Fragment>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    checked={selected}
                                />
                                <>
                                    <Typography>
                                        {AccountAlias}
                                    </Typography>
                                    <Typography color={"textSecondary"} variant={'subtitle2'}>
                                        &nbsp;
                                        ({AccountId})
                                    </Typography>
                                </>
                            </React.Fragment>
                        )}
                        {...props}
                    /> : <TextAreaSkeleton/>
            }
        </React.Fragment>
    )
}

function AccountMultiSelectV2(props) {
    const {disabled = false, autoCompleteProps, addAllToOption = false, onChange, value} = {...props}
    const {organization: masterAccountId} = useOrganization()
    const [accounts, setAccounts] = useState([])
    const [ready, setReady] = useState(false)
    const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
    const checkedIcon = <CheckBoxIcon fontSize="small"/>;

    const handleRequest = async () => {
        try {
            setReady(false);
            const response = await service.general().getAccountInformationByMasterId({master_id: masterAccountId});
            if (response.data.code === 200) {
                setAccounts(response.data.account);
                if (addAllToOption) {
                    response.data.account.unshift({AccountId: 'All', AccountAlias: 'All'})
                }
                setReady(true);
            }
        } catch (e) {
            console.log(e)
        }
    }

    const handleChange = (e, value) => {
        onChange(e, value)
    }

    const disabledOptions = (option) => {
        if (option.AccountId === 'All') return false
        return value.some(e => e?.AccountId === 'All')
    }

    useEffect(() => {
        if (masterAccountId !== 'no org') {
            handleRequest().then()
        }
    }, [masterAccountId])

    return (
        <React.Fragment>
            {
                ready ?
                    <Autocomplete
                        id="application_name"
                        size={"small"}
                        multiple
                        fullWidth
                        value={value}
                        options={accounts}
                        disableCloseOnSelect
                        loading={!ready}
                        limitTags={1}
                        disabled={!ready || disabled}
                        getOptionSelected={(option, value) => option.AccountId === value.AccountId}
                        getOptionLabel={
                            (account) =>
                                account.AccountId ? account.AccountAlias + " (" + account.AccountId + ")" : ""
                        }
                        renderInput={(params) =>
                            <TextField {...params} label="Accounts" variant="outlined"/>}
                        getOptionDisabled={disabledOptions}
                        renderOption={({AccountId, AccountAlias}, {selected}) => (
                            <React.Fragment>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    checked={selected}
                                />
                                <>
                                    <Typography>
                                        {AccountAlias}
                                    </Typography>
                                    <Typography color={"textSecondary"} variant={'subtitle2'}>
                                        &nbsp;
                                        ({AccountId})
                                    </Typography>
                                </>
                            </React.Fragment>
                        )}
                        onChange={handleChange}
                        {...autoCompleteProps}
                    /> : <TextAreaSkeleton/>
            }
        </React.Fragment>
    )
}

export {
    ApplicationName,
    Environment,
    Region,
    CostCode,
    Accounts,
    RegionMultiSelect,
    EnvironmentMultiSelect,
    AccountMultiSelectV2,
    AccountMultiSelect
}