import React, {useState} from "react";
import WuTextField from "../../General/WuTextFeild";
import {Button} from "@material-ui/core";
import {AlertWu} from "../../AlertWu/AlertWu";

export function AddEnvironment(props) {

    const [environmentCode, setEnvironmentCode] = useState("");
    const [environmentName, setEnvironmentName] = useState("");
    const [comment, setComment] = useState("");
    const [fieldError, setFieldError] = useState({
        regionCode: false,
        regionName: false
    })

    const environmentCreate = () => {
        setFieldError({
            environmentCode: environmentCode.length < 1,
            environmentName: environmentName.length < 1,
        })

        if (environmentName && environmentCode) {
            console.log(environmentName, environmentCode, comment)
            return true
        }
    }

    return (
        <React.Fragment>
            <div className="d-f-s" style={{marginTop: 20}}>
                <WuTextField
                    value={environmentCode}
                    error={fieldError.regionCode}
                    onChange={(e) => {
                        setEnvironmentCode(e.target.value)
                    }}
                    label="Environment Code*"
                />
                <WuTextField
                    value={environmentName}
                    error={fieldError.regionName}
                    onChange={(e) => {
                        setEnvironmentName(e.target.value)
                    }}
                    label="Environment Name*"
                />
                <WuTextField
                    value={comment}
                    onChange={(e) => {
                        setComment(e.target.value)
                    }}
                    label="Comment"
                />
                <Button onClick={environmentCreate} variant={"contained"} disableElevation color={"secondary"}>
                    Save
                </Button>
            </div>
            <div style={{marginTop: 20}}>
                <AlertWu
                    alertOpen={{state: true}}
                    severity={"success"}
                    message={"new environment added."}/>
            </div>
        </React.Fragment>
    )
}