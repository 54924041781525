import React from "react";
import "./error.scss"
import CloudOffIcon from '@material-ui/icons/CloudOff';
import {Typography} from "@material-ui/core";

export default function Error(props) {
    const {message} = {...props}

    return (
        <div className="error-wrapper MuiPaper-elevation8">
            <div className={'error-container-warning'}>
                <CloudOffIcon className={"icon"}/>
                <Typography variant={'h4'} gutterBottom> Oops... Something went wrong</Typography>
                <Typography variant={'h5'} gutterBottom> Hi, our server has taken a break</Typography>
                <Typography variant={'subtitle1'}>Looks like our server is down. This usually means we're doing maintenance work or server has
                    some issues.<br/> In any case -- we're already fixing it and everything should be back to normal in few minutes</Typography>
            </div>
        </div>
    )
}