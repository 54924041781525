import {useState} from "react";
import {SearchScreen} from "./SearchScreen";
import {SearchResultPreview} from "./SearchResultPreview";
import {ConfirmTagging} from "./ConfirmTagging";
import {apiErrorResponseJsonParser} from "../../../helper/generalFunctions";
import service from "../../../service/service";

export default function Search() {
    const [selectedResources, setSelectedResources] = useState([])
    const [dialogOpen, setDialogOpen] = useState({searchResult: false, confirmTagging: false})
    const [searchParams, setSearchParams] = useState(null)
    const [apiProgress, setApiProgress] = useState({loading: false, ready: false, error: false})
    const [resourceData, setResourceData] = useState({header: [], tags: []})

    const handleSearch = async (params) => {
        setSelectedResources([])
        setSearchParams(params)
        setResourceData({header: [], tags: []})
        await fetchTags(params)
    }


    const fetchTags = async (params) => {
        try {

            const newParams = {
                accountId: params.accountId,
                region: params.region,
                resourceTypeFilters: params?.advancedOptions?.resourceTypeFilters || [],
                tagFilters: {
                    combinator: params?.advancedOptions?.filterCombinator,
                    conditions: params?.advancedOptions?.tagFilters || []
                },
                excludeResults: params?.advancedOptions?.excludeResults || null,
                batchResults: false
            }

            setApiProgress({loading: true, ready: false, error: false})
            const {data: {data}} = await service.automations().autoTaggingGetResources(newParams);
            setResourceData(data)
            setApiProgress({loading: false, ready: true, error: false})
            handleDialogStateChange('searchResult', true)
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setApiProgress({loading: false, ready: false, error: error})
        }

    }
    const handleSelectionChange = (e) => {
        setSelectedResources(e)
    }

    const handleDialogStateChange = (dialog, state) => {
        setDialogOpen({...dialogOpen, [dialog]: state})
    }

    return (
        <div className={'AutoTagging-Wrapper'}>
            {dialogOpen.searchResult && <SearchResultPreview
                open={dialogOpen.searchResult}
                onSelectionChange={handleSelectionChange}
                selectedResource={selectedResources}
                resources={resourceData?.tags || []}
                gridHeader={resourceData?.header || []}
                onTag={() => handleDialogStateChange('confirmTagging', true)}
                onClose={() => handleDialogStateChange('searchResult', false)}
            />}
            <SearchScreen onSearch={handleSearch} apiProgress={apiProgress}/>
            {dialogOpen.confirmTagging && <ConfirmTagging
                searchParams={searchParams}
                open={dialogOpen.confirmTagging}
                resources={selectedResources}
                onClose={() => handleDialogStateChange('confirmTagging', false)}
            />}
        </div>
    )
}