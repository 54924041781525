import {Typography} from "@material-ui/core";
import AccountWiseSummary from "./AccountWiseSummary";
import {useEffect, useState} from "react";
import {apiErrorResponseJsonParser} from "../../../../helper/generalFunctions";
import {useOrganization} from "../../../../hooks/useOrganization";
import service from "../../../../service/service";
import WuLoading from "../../../General/WuLoading";
import WuAlert from "../../../General/WuAlert";;

export default function OrganizationWiseSummary() {
    const {organization: masterAccountId} = useOrganization()
    const [progress, setProgress] = useState({loading: false, ready: false, error: false})
    const [result, setResult] = useState([])

    const fetchData = async () => {
        try {
            setProgress({loading: true, ready: false, error: false})
            const params = {
                master_account_id: masterAccountId
            }
            const {data: {account}} = await service.monitoring().listTagAccount(params)
            setResult(account)
            setProgress({loading: false, ready: true, error: false})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setProgress({loading: false, ready: false, error: error})
        }
    }

    useEffect(() => {
        if (masterAccountId !== 'no org') {
            fetchData().then()
        }
    }, [masterAccountId])

    return (
        <div className={'tc-org-wise'}>
            <SummaryTitle/>
            {
                progress.ready && result.map(e => <AccountWiseSummary key={e.account_id} {...e}/>)
            }
            <WuLoading message={'Compliance data loading. Please wait...'} open={progress.loading} boxProps={{m:2}}/>
            <WuAlert error={Boolean(progress.error)} errorMessage={progress.error} success={false} paperProps={{margin: 16}}/>
        </div>
    )
}

function SummaryTitle() {
    return (
        <div className={'tc-summary-title'}>
            <div style={{width: 42}}/>
            <Typography color={'textSecondary'} style={{flexBasis: '40%'}}>
                Account
            </Typography>
            <Typography color={'textSecondary'} style={{flexBasis: '50%'}}>
                Number of untagged resources
            </Typography>
        </div>
    )
}