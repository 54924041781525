export const ecsListAccountWise = {
    "code": 200,
    "success": true,
    "data": [{
        "accountId": "840944450766",
        "accountAlias": "acctgftest",
        "clusterCount": 4,
        "serviceCount": 4,
        "instanceCount": 3,
        "price": 10999.229466089999,
        "previousPrice": 19682.988585857398,
        "taskCount": 4,
        "yearlySpend": 50907.8044477422
    }, {
        "accountId": "418068139254",
        "accountAlias": "acctnprd",
        "clusterCount": 4,
        "serviceCount": 4,
        "instanceCount": 2,
        "price": 1205.7529605473,
        "previousPrice": 2431.4419204080996,
        "taskCount": 4,
        "yearlySpend": 6114.784002698299
    }, {
        "accountId": "923660381271",
        "accountAlias": "acctprd",
        "clusterCount": 4,
        "serviceCount": 4,
        "instanceCount": 2,
        "price": 194.90741484,
        "previousPrice": 304.5439169623,
        "taskCount": 2,
        "yearlySpend": 711.4513319069
    }]
}

export const ecsListSummary = {
    "code": 200,
    "success": true,
    "data": {
        "clusterCount": 401,
        "taskCount": 2755,
        "serviceCount": 3030,
        "instanceCount": 69,
        "price": 294005.6165159324,
        "previousPrice": 534977.8366669609,
        "yearlySpend": 1354604.3691879015,
        "lastCronRunTime": "Mar 23 2022, 12:38 PM"
    }
}

export const ecsClusterAccountWise = {
    "code": 200,
    "success": true,
    "data": [{
        "accountId": "840944450766",
        "accountAlias": "acctgftest",
        "region": "us-east-1",
        "cpuAverage": "45",
        "memoryAverage": "8",
        "price": 227.18806331,
        "previousPrice": 287.62236657369994,
        "clusterArn": "arn:aws:ecs:us-east-1:840944450766:cluster/test",
        "serviceCount": 9,
        "taskCount": 10,
        "yearlySpend": 372.89005225260024,
        "instanceCount": null,
        "status": "ACTIVE",
        "ApplicationName": "Tampflex",
        "ApplicationOwner": "easlen0@yelp.com",
        "CostCenter": "5865",
        "SupportContact": "easlen0@yelp.com",
        "ApplicationCode": "TF"
    }, {
        "accountId": "840944450766",
        "accountAlias": "acctgftest",
        "region": "us-east-1",
        "cpuAverage": "18",
        "memoryAverage": "64",
        "price": 28,
        "previousPrice": 60,
        "clusterArn": "arn:aws:ecs:us-east-1:840944450766:cluster/test2",
        "serviceCount": 4,
        "taskCount": null,
        "yearlySpend": 156,
        "instanceCount": null,
        "status": "ACTIVE",
        "ApplicationName": "Cardguard",
        "ApplicationOwner": "bfiridolfi1@nytimes.com",
        "CostCenter": "4586",
        "SupportContact": "bfiridolfi1@nytimes.com",
        "ApplicationCode": "CG"
    }, {
        "accountId": "840944450766",
        "accountAlias": "acctgftest",
        "region": "us-east-1",
        "cpuAverage": "25",
        "memoryAverage": "12",
        "price": 68.18355650000001,
        "previousPrice": 141.36826171759992,
        "clusterArn": "arn:aws:ecs:us-east-1:840944450766:cluster/test3",
        "serviceCount": 5,
        "taskCount": 1,
        "yearlySpend": 119.0780168951003,
        "instanceCount": null,
        "status": "ACTIVE",
        "ApplicationName": "Holdlamis",
        "ApplicationOwner": "mniece2@amazon.com",
        "CostCenter": "6859",
        "SupportContact": "mniece2@amazon.com",
        "ApplicationCode": "HLA"
    }, {
        "accountId": "840944450766",
        "accountAlias": "acctgftest",
        "region": "us-east-1",
        "cpuAverage": "80",
        "memoryAverage": "8",
        "price": 106.25632034,
        "previousPrice": 135.4673925089,
        "clusterArn": "arn:aws:ecs:us-east-1:840944450766:cluster/test3",
        "serviceCount": 9,
        "taskCount": 7,
        "yearlySpend": 43.7237129267,
        "instanceCount": null,
        "status": "ACTIVE",
        "ApplicationName": "Job",
        "ApplicationOwner": "jdye3@si.edu",
        "CostCenter": "6859",
        "SupportContact": "jdye3@si.edu",
        "ApplicationCode": "JOB"
    }]
}

export const ecsClusterDescription = {
    "code": 200, "success": true, "message": "Cluster Description", "data": {
        "accountId": "840944450766",
        "accountAlias": "acctgftest",
        "costCenter": "5865",
        "applicationName": "Tampflex",
        "applicationOwner": "easlen0@yelp.com",
        "applicationCode": "TF",
        "clusterArn": "arn:aws:ecs:us-east-1:840944450766:cluster/test",
        "status": "ACTIVE",
        "clusterName": "Test- Cluster",
        "registeredContainerInstancesCount": null,
        "runningTasksCount": 4,
        "pendingTasksCount": null,
        "activeServicesCount": 4,
        "cpuAverage": "40",
        "memoryAverage": "50",
        "graph": {
            "cpu": [
                {
                    "x": "2022-01-01T00:00:00.000Z",
                    "y": "20"
                },
                {
                    "x": "2022-01-15T00:00:00.000Z",
                    "y": "62"
                },
                {
                    "x": "2022-02-01T00:00:00.000Z",
                    "y": "23"
                },
                {
                    "x": "2022-02-15T00:00:00.000Z",
                    "y": "27"
                },
                {
                    "x": "2022-03-01T00:00:00.000Z",
                    "y": "40"
                },
                {
                    "x": "2022-03-15T00:00:00.000Z",
                    "y": "32"
                }
            ],
            "memory": [
                {
                    "x": "2022-01-01T00:00:00.000Z",
                    "y": "90"
                },
                {
                    "x": "2022-01-15T00:00:00.000Z",
                    "y": "62"
                },
                {
                    "x": "2022-02-01T00:00:00.000Z",
                    "y": "18"
                },
                {
                    "x": "2022-02-15T00:00:00.000Z",
                    "y": "80"
                },
                {
                    "x": "2022-03-01T00:00:00.000Z",
                    "y": "50"
                },
                {
                    "x": "2022-03-15T00:00:00.000Z",
                    "y": "12"
                }
            ],
            "price": [
                {
                    "x": "2022-01-01T00:00:00.000Z",
                    "y": "629.9053634488994"
                },
                {
                    "x": "2022-01-15T00:00:00.000Z",
                    "y": "750.9053634488994"
                },
                {
                    "x": "2022-02-01T00:00:00.000Z",
                    "y": "480.0499982009003"
                },
                {
                    "x": "2022-02-15T00:00:00.000Z",
                    "y": "852.37581575300044"
                },
                {
                    "x": "2022-03-01T00:00:00.000Z",
                    "y": "495.37581575300044"
                }
            ]
        },
        "serviceCount": 4,
        "instanceCount": null,
        "price": 171.18806331000002,
        "previousPrice": 167.6223665736999,
        "taskCount": 4,
        "yearlySpend": 372.8900522526003
    }
}

export const ecsClusterServiceList = {
    "code": 200,
    "success": true,
    "data": [{
        "accountId": "840944450766",
        "accountAlias": "acctgftest",
        "region": "us-east-1",
        "cpuAverage": "24",
        "memoryAverage": "77.01407588",
        "launchType": "FARGATE",
        "serviceArn": "arn:aws:ecs:us-east-1:080118055312:service/test/test",
        "clusterArn": "arn:aws:ecs:us-east-1:840944450766:cluster/test",
        "createdAt": "2021-03-08 16:06:55.745",
        "status": "ACTIVE",
        "price": "7",
        "previousPrice": "15",
        "yearlySpend": "40",
        "ApplicationName": "Tampflex",
        "ApplicationOwner": "easlen0@yelp.com",
        "CostCenter": "5865",
        "SupportContact": "easlen0@yelp.com",
        "ApplicationCode": "TF"
    }, {
        "accountId": "840944450766",
        "accountAlias": "acctgftest",
        "region": "us-east-1",
        "cpuAverage": "32",
        "memoryAverage": "58.83830584",
        "launchType": "FARGATE",
        "serviceArn": "arn:aws:ecs:us-east-1:080118055312:service/test/test-1",
        "clusterArn": "arn:aws:ecs:us-east-1:840944450766:cluster/test",
        "createdAt": "2021-05-10 23:55:35.889",
        "status": "ACTIVE",
        "price": "7",
        "previousPrice": "15",
        "yearlySpend": "40",
        "ApplicationName": "Tampflex",
        "ApplicationOwner": "easlen0@yelp.com",
        "CostCenter": "5865",
        "SupportContact": "easlen0@yelp.com",
        "ApplicationCode": "TF"
    }, {
        "accountId": "840944450766",
        "accountAlias": "acctgftest",
        "region": "us-east-1",
        "cpuAverage": "54.25",
        "memoryAverage": "19.8708291",
        "launchType": "FARGATE",
        "serviceArn": "arn:aws:ecs:us-east-1:080118055312:service/test/test-2",
        "clusterArn": "arn:aws:ecs:us-east-1:840944450766:cluster/test",
        "createdAt": "2020-05-08 21:08:01.972",
        "status": "ACTIVE",
        "price": "7",
        "previousPrice": "15",
        "yearlySpend": "40",
        "ApplicationName": "Tampflex",
        "ApplicationOwner": "easlen0@yelp.com",
        "CostCenter": "5865",
        "SupportContact": "easlen0@yelp.com",
        "ApplicationCode": "TF"
    }, {
        "accountId": "840944450766",
        "accountAlias": "acctgftest",
        "region": "us-east-1",
        "cpuAverage": "85.25",
        "memoryAverage": "96.8979275",
        "launchType": "FARGATE",
        "serviceArn": "arn:aws:ecs:us-east-1:080118055312:service/test/test-3",
        "clusterArn": "arn:aws:ecs:us-east-1:840944450766:cluster/test",
        "createdAt": "2021-11-15 19:13:27.022",
        "status": "ACTIVE",
        "price": "0",
        "previousPrice": "0",
        "yearlySpend": "0",
        "ApplicationName": "Tampflex",
        "ApplicationOwner": "easlen0@yelp.com",
        "CostCenter": "5865",
        "SupportContact": "easlen0@yelp.com",
        "ApplicationCode": "TF"
    }]
}

export const ecsTaskList = {
    "code": 200,
    "success": true,
    "data": [{
        "accountId": "840944450766",
        "AccountAlias": "acctgftest",
        "region": "us-east-1",
        "taskArn": "arn:aws:ecs:us-east-1:840944450766:task/test/15173cf7372e4cfa9d0256desdf21561",
        "serviceArn": null,
        "price": "16.6169294",
        "previousPrice": "33.26621092180001",
        "clusterArn": "arn:aws:ecs:us-east-1:840944450766:cluster/test",
        "cpuUnits": "1024",
        "memoryUnits": "2048",
        "launchType": "FARGATE",
        "startedAt": "2022-01-28 06:32:12.865",
        "createdAt": "2022-01-28 06:22:39.299",
        "status": "RUNNING",
        "yearlySpend": "54.32793268070007",
        "ApplicationName": "Tampflex",
        "ApplicationOwner": "easlen0@yelp.com",
        "CostCenter": "5865",
        "SupportContact": "easlen0@yelp.com",
        "ApplicationCode": "TF"
    }, {
        "accountId": "840944450766",
        "AccountAlias": "acctgftest",
        "region": "us-east-1",
        "taskArn": "arn:aws:ecs:us-east-1:840944450766:task/test/380ca230ad5d41f6a5460398e8dr4451",
        "serviceArn": null,
        "price": "16.6524507",
        "previousPrice": "33.1986931993",
        "clusterArn": "arn:aws:ecs:us-east-1:840944450766:cluster/test",
        "cpuUnits": "1024",
        "memoryUnits": "2048",
        "launchType": "FARGATE",
        "startedAt": "2022-01-10 13:40:54.397",
        "createdAt": "2022-01-10 13:38:29.102",
        "status": "RUNNING",
        "yearlySpend": "75.26931104070007",
        "ApplicationName": "Tampflex",
        "ApplicationOwner": "easlen0@yelp.com",
        "CostCenter": "5865",
        "SupportContact": "easlen0@yelp.com",
        "ApplicationCode": "TF"
    }, {
        "accountId": "840944450766",
        "AccountAlias": "acctgftest",
        "region": "us-east-1",
        "taskArn": "arn:aws:ecs:us-east-1:840944450766:task/test/427dad67b07b493fa7c72114sdf52",
        "serviceArn": null,
        "price": "66.5593055",
        "previousPrice": "54.9801545998999",
        "clusterArn": "arn:aws:ecs:us-east-1:840944450766:cluster/test",
        "cpuUnits": "4096",
        "memoryUnits": "8192",
        "launchType": "FARGATE",
        "startedAt": "2022-02-17 09:39:57.096",
        "createdAt": "2022-02-17 09:35:51.465",
        "status": "RUNNING",
        "yearlySpend": "121.53946019379998",
        "ApplicationName": "Tampflex",
        "ApplicationOwner": "easlen0@yelp.com",
        "CostCenter": "5865",
        "SupportContact": "easlen0@yelp.com",
        "ApplicationCode": "TF"
    }, {
        "accountId": "840944450766",
        "AccountAlias": "acctgftest",
        "region": "us-east-1",
        "taskArn": "arn:aws:ecs:us-east-1:840944450766:task/test/53ff0e6d065b444296859brf41cvc",
        "serviceArn": null,
        "price": "16.5961953",
        "previousPrice": "7.574923443699996",
        "clusterArn": "arn:aws:ecs:us-east-1:840944450766:cluster/test",
        "cpuUnits": "1024",
        "memoryUnits": "2048",
        "launchType": "FARGATE",
        "startedAt": "2022-02-22 14:46:40.626",
        "createdAt": "2022-02-22 14:43:38.372",
        "status": "RUNNING",
        "yearlySpend": "24.171118746399976",
        "ApplicationName": "Tampflex",
        "ApplicationOwner": "easlen0@yelp.com",
        "CostCenter": "5865",
        "SupportContact": "easlen0@yelp.com",
        "ApplicationCode": "TF"
    }]
}

export const ecsServiceDescription = {
    "code": 200, "success": true, "message": "Service Description", "data": {
        "accountId": "840944450766",
        "accountAlias": "acctgftest",
        "region": "us-east-1",
        "costCenter": "1317",
        "environment": "DEV",
        "applicationName": "Tampflex",
        "applicationOwner": "easlen0@yelp.com",
        "applicationCode": "TF",
        "serviceArn": "arn:aws:ecs:us-east-1:840944450766:service/test/test",
        "loadBalancers": [{
            "targetGroupArn": "arn:aws:elasticloadbalancing:us-east-1:840944450766:targetgroup/test/4f03789e5412942783f",
            "containerName": "test-con",
            "containerPort": 5508
        }],
        "clusterArn": "arn:aws:ecs:us-east-1:840944450766:cluster/CANV-1NP-01-agent-fg-qa",
        "status": "ACTIVE",
        "launchType": "FARGATE",
        "taskDefinition": "arn:aws:ecs:us-east-1:840944450766:task-definition/test-task-definition",
        "deploymentConfiguration": {
            "deploymentCircuitBreaker": {"enable": false, "rollback": false},
            "maximumPercent": 200,
            "minimumHealthyPercent": 100
        },
        "roleArn": "arn:aws:iam::840944450766:role/aws-service-role/ecs.amazonaws.com/testRoleForECS",
        "createdAt": "2020-10-15T19:12:03.223Z",
        "schedulingStrategy": "REPLICA",
        "subnets": ["subnet-05sdfsd0056sdf", "subnet-04hfghfh5sdfs0"],
        "securityGroups": ["sg-08sdfsh11dfg", "sg-0a4sdfdsf"],
        "assignPublicIp": "DISABLED",
        "taskPlacementStrategy": [],
        "cpuAverage": "24",
        "memoryAverage": "77",
        "graph": {
            "cpu": [{"x": "2022-03-08T14:13:00.000Z", "y": 0.3809211815882381}, {
                "x": "2022-03-09T14:13:00.000Z",
                "y": 0.3655078464044692
            }, {"x": "2022-03-10T14:13:00.000Z", "y": 0.40083505552065263}, {
                "x": "2022-03-11T14:13:00.000Z",
                "y": 0.36071710489378245
            }, {"x": "2022-03-12T14:13:00.000Z", "y": 0.3350381669459161}, {
                "x": "2022-03-13T14:13:00.000Z",
                "y": 0.38904341573829415
            }, {"x": "2022-03-14T14:13:00.000Z", "y": 0.3787006629125562}, {
                "x": "2022-03-15T14:13:00.000Z",
                "y": 0.40975067999282655
            }, {"x": "2022-03-16T14:13:00.000Z", "y": 0.36924670300818835}, {
                "x": "2022-03-17T14:13:00.000Z",
                "y": 0.3529425128833973
            }, {"x": "2022-03-18T14:13:00.000Z", "y": 0.3352546146195672}, {
                "x": "2022-03-19T14:13:00.000Z",
                "y": 0.327000338966184
            }, {"x": "2022-03-20T14:13:00.000Z", "y": 0.35820966616810335}, {
                "x": "2022-03-21T14:13:00.000Z",
                "y": 0.37477579437949876
            }, {"x": "2022-03-22T14:13:00.000Z", "y": 0.42075863825930443}],
            "memory": [{"x": "2022-03-08T14:13:00.000Z", "y": 27.39828671514581}, {
                "x": "2022-03-09T14:13:00.000Z",
                "y": 27.39793070431996
            }, {"x": "2022-03-10T14:13:00.000Z", "y": 27.395194654661136}, {
                "x": "2022-03-11T14:13:00.000Z",
                "y": 27.407042249002025
            }, {"x": "2022-03-12T14:13:00.000Z", "y": 27.43479756098984}, {
                "x": "2022-03-13T14:13:00.000Z",
                "y": 27.42363307486098
            }, {"x": "2022-03-14T14:13:00.000Z", "y": 27.513596816044245}, {
                "x": "2022-03-15T14:13:00.000Z",
                "y": 27.523630805866475
            }, {"x": "2022-03-16T14:13:00.000Z", "y": 27.58943052830984}, {
                "x": "2022-03-17T14:13:00.000Z",
                "y": 27.588890923394096
            }, {"x": "2022-03-18T14:13:00.000Z", "y": 27.58797137049147}, {
                "x": "2022-03-19T14:13:00.000Z",
                "y": 27.58935002411619
            }, {"x": "2022-03-20T14:13:00.000Z", "y": 27.619981589257442}, {
                "x": "2022-03-21T14:13:00.000Z",
                "y": 27.51441928864086
            }, {"x": "2022-03-22T14:13:00.000Z", "y": 27.496516610763052}],
            "price": [{
                "x": "2022-01-01T00:00:00.000Z",
                "y": "17.19621928210001"
            },
                {"x": "2022-02-01T00:00:00.000Z", "y": "15.459147873400026"},
                {"x": "2022-03-01T00:00:00.000Z", "y": "11.924212952500003"}
                ]
        }
    }
}

export const ecsTaskDescription = {
    "code": 200, "success": true, "message": "Task Description", "data": {
        "accountId": "840944450766",
        "accountAlias": "acctgftest",
        "region": "us-east-1",
        "taskArn": "arn:aws:ecs:us-east-1:840944450766:task/test/15173cf7372e4cfa9d0256desdf21561",
        "serviceArn": "arn:aws:ecs:us-east-1:080118055312:service/test/test",
        "taskDefinition": "arn:aws:ecs:us-east-1:840944450766:task-definition/test-task-definition",
        "clusterArn": "arn:aws:ecs:us-east-1:840944450766:cluster/test",
        "status": "STOPPED",
        "launchType": "FARGATE",
        "startedAt": "2022-01-28T06:32:12.865Z",
        "startedBy": "ecs-svc/15173cf7372e4cfa9d0256desdf21561",
        "availabilityZone": "us-east-1b",
        "cpuUnits": "1024",
        "createdAt": "2022-01-28T06:22:39.299Z",
        "memoryUnits": "2048",
        "group": "service:test",
        "ApplicationName": "Tampflex",
        "ApplicationOwner": "easlen0@yelp.com",
        "CostCenter": "5865",
        "SupportContact": "easlen0@yelp.com",
        "ApplicationCode": "TF",
        "containers": [{
            "containerArn": "arn:aws:ecs:us-east-1:840944450766:container/test/15173cf7372e4cfa9d0256desdf21561/26sdfsdf-76bsc-70fb-b033-vf444dfgs",
            "taskArn": "arn:aws:ecs:us-east-1:840944450766:task/test/15173cf7372e4cfa9d0256desdf21561",
            "name": "test-container-1",
            "image": "https://index.docker.io/v2/",
            "runtimeId": "15173cf7372e4cfa9d0256desdf21561",
            "lastStatus": "STOPPED",
            "exitCode": 143,
            "networkBindings": [],
            "networkInterfaces": [{
                "attachmentId": "15173-cf7372e4-cfa9d0256-desdf",
                "privateIpv4Address": "10.5.38.130"
            }],
            "healthStatus": "UNKNOWN",
            "cpu": "1024",
            "memoryReservation": "2048"
        }, {
            "containerArn": "arn:aws:ecs:us-east-1:840944450766:container/test/15173cf7372e4cfa9d0256desdf21561/3453gdfgdd-76bsc-70fb-564-vf444dfgs",
            "taskArn": "arn:aws:ecs:us-east-1:080118055312:task/test2",
            "name": "test-container-2",
            "image": "https://index.docker.io/v2/:1",
            "runtimeId": "15173cf7372e4cfa9d0256desdf21561",
            "lastStatus": "RUNNING",
            "exitCode": 0,
            "networkBindings": [],
            "networkInterfaces": [{
                "attachmentId": "0fa8d9e2-223a-415173-cf7372e4-cfa9d0256-desdf",
                "privateIpv4Address": "10.5.10.64"
            }],
            "healthStatus": "UNKNOWN",
            "cpu": "2046",
            "memoryReservation": 2014
        }],
        "tags": []
    }
}

export const ecsInstanceList = {
    "code": 200,
    "success": true,
    "data": [{
        "accountId": "840944450766",
        "accountAlias": "acctgftest",
        "regionCode": "us-east-1",
        "clusterInstanceArn": "arn:aws:ecs:us-east-1:840944450766:container-instance/5547c13d-558c-45f0-902f-94134bd984b7",
        "ec2Instance": "i-058sdfsv566hjhsresr",
        "taskCount": 0,
        "clusterArn": "arn:aws:ecs:us-east-1:840944450766:cluster/test-2",
        "registeredAt": "2020-06-15 16:09:07.044",
        "cpuAverage": "72.6297615207436875",
        "memoryAverage": "24.58",
        "Status": "ACTIVE",
        "price": "15.642105643100004",
        "prevPrice": "31.19",
        "yearlySpend": "81.366980163900010",
        "ApplicationName": "Tampflex",
        "ApplicationOwner": "easlen0@yelp.com",
        "CostCenter": "5865",
        "SupportContact": "easlen0@yelp.com",
        "ApplicationCode": "TF"
    }, {
        "accountId": "840944450766",
        "accountAlias": "acctgftest",
        "regionCode": "us-east-1",
        "clusterInstanceArn": "arn:aws:ecs:us-east-1:840944450766:container-instance/79a3f5a4-e7ac-4dec-aa12-58ec544298b2",
        "ec2Instance": "i-0bfghf442121fgh",
        "taskCount": 0,
        "clusterArn": "arn:aws:ecs:us-east-1:840944450766:cluster/test-3",
        "registeredAt": "2020-07-01 13:20:07.614",
        "cpuAverage": "52.497102322525021",
        "memoryAverage": "68.955",
        "Status": "ACTIVE",
        "price": "15.65",
        "prevPrice": "31.21986994009999",
        "yearlySpend": "81.441192063900030",
        "ApplicationName": "Tampflex",
        "ApplicationOwner": "easlen0@yelp.com",
        "CostCenter": "5865",
        "SupportContact": "easlen0@yelp.com",
        "ApplicationCode": "TF"
    },
        {
            "accountId": "840944450766",
            "accountAlias": "acctgftest",
            "regionCode": "us-east-1",
            "clusterInstanceArn": "arn:aws:ecs:us-east-1:840944450766:container-instance/79a3f5a4-e7ac-4dec-aa12-58ec544298b2",
            "ec2Instance": "i-0bfghf442121fgh",
            "taskCount": 0,
            "clusterArn": "arn:aws:ecs:us-east-1:840944450766:cluster/test-3",
            "registeredAt": "2020-07-01 13:20:07.614",
            "cpuAverage": "24.497102322525021",
            "memoryAverage": "94.568",
            "Status": "ACTIVE",
            "price": "15.65",
            "prevPrice": "31.21986994009999",
            "yearlySpend": "81.441192063900030",
            "ApplicationName": "Tampflex",
            "ApplicationOwner": "easlen0@yelp.com",
            "CostCenter": "5865",
            "SupportContact": "easlen0@yelp.com",
            "ApplicationCode": "TF"
        }]
}

export const ecsInstanceDescription = {"code": 200, "success": true, "data": {}}