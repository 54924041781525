import React, {useState} from 'react';
import {Button, Paper, Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import service from "../../../../../service/service";
import {jsonParser} from "../../../../../helper/generalFunctions";
import Box from "@material-ui/core/Box";

export default function SummaryCard() {
    const [cronStatus, setCronStatus] = useState({
        status: false,
        message: undefined,
        loading: false
    });

    const handleCronStartRequest = async () => {
        try {
            setCronStatus({
                status: false,
                message: undefined,
                loading: true
            })
            const {data: {data}} = await service.governance().iamPolicyCron({});
            setCronStatus({
                ...cronStatus, ...{
                    status: true,
                    severity: 'success',
                    message: data
                }
            })
        } catch (e) {
            const errorBody = jsonParser(e?.request?.response)
            setCronStatus({
                ...cronStatus, ...{
                    status: true,
                    severity: 'error',
                    message: errorBody ? errorBody.error : e.message
                }
            })
        } finally {
            //setCronStatus({...cronStatus, loading: false})
        }
    }
    const handleSnackBarClose = () => {
        setCronStatus({...cronStatus, status: false})
    }

    return (
        <Paper variant={'outlined'}>
            <Box m={2}>
                <SnackBarCronStatus open={cronStatus.status}
                                    onClose={handleSnackBarClose}
                                    message={cronStatus.message}
                                    severity={cronStatus.severity}/>
                <Button onClick={handleCronStartRequest} variant={'outlined'}>
                    refresh iam
                </Button>
            </Box>
        </Paper>
    )
}

function SnackBarCronStatus(props) {
    const {open, severity, message} = {...props}
    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={() => props.onClose(true)}>
            <Alert severity={severity}>
                {message}
            </Alert>
        </Snackbar>
    )
}