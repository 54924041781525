import React, {useEffect, useState} from "react";
import Box from "@material-ui/core/Box";
import service from "../../../service/service";
import DataGrid from "../../General/DataGrid/DataGrid";
import {Paper} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {DeleteOutlined} from "@material-ui/icons";
import Create from "./Create";
import BudgetInformation from "./BudgetInformation/BudgetInformation";
import Delete from "../Delete";
import {useOrganization} from "../../../hooks/useOrganization";
import {accountLengthAdjust} from "../../../helper/generalFunctions";

const disabled = localStorage.getItem("loginType") !== "admin";

export default function TableV2(props) {
    const {addButton} = {...props}
    const {organization} = useOrganization();
    const [budgetData, setBudgetData] = useState([]);
    const [addOpen, setAddOpen] = useState(false);
    const [infoOpen, setInfoOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [selectedBudget, setSelectedBudget] = useState({});
    const [ready, setReady] = useState(false);
    const [masterAccountId, setMasterAccountId] = useState(organization);


    useEffect(() => {
        setMasterAccountId(organization)
    }, [organization])

    const columns = [
        {
            field: "Account",
            resizable: true,
            sortable: true,
            suppressAutoSize: false,
            valueGetter: function (params) {
                return (`${accountLengthAdjust(params.data.accountId)} (${params.data.AccountAlias})`)
            }
        },
        {
            field: "applicationName",
            resizable: true,
            sortable: true,
            suppressAutoSize: false,
        },
        {
            field: "environment",
            headerName: "Environment",
            resizable: true,
            width: 150,
            sortable: true,
            suppressAutoSize: false,
        },
        {
            field: "notificationSubscribers",
            headerName: "Notification Subscriber",
            resizable: true,
            sortable: true,
            suppressAutoSize: false,
        },
        {
            field: "region",
            resizable: true,
            sortable: true,
            width: 150,
            suppressAutoSize: false,
        },
        {
            field: "BudgetPeriod",
            headerName: 'Period',
            resizable: true,
            sortable: true,
            width: 150,
            suppressAutoSize: false,
        },
        {
            field: "budgetAmount",
            resizable: true,
            sortable: true,
            suppressAutoSize: false,
            width: 150,
            type: "numericColumn",
            filter: "agNumberColumnFilter",
            valueFormatter: function (param) {
                return param ? `$${Math.floor(param.value).toLocaleString()}` : "0"
            }
        },
        {
            cellRenderer: "informationCellRenderer",
            sortable: false,
            resizable: false,
            filter: false,
            width: 75,
            suppressAutoSize: false,
            cellRendererParams: {
                clicked: function (field) {
                    handleApplicationUpdate(field)
                }
            },
        }
    ]

    if (!disabled) {
        columns.push({
            cellRenderer: "deleteCellRender",
            sortable: false,
            resizable: false,
            filter: false,
            width: 75,
            suppressAutoSize: false,
            cellRendererParams: {
                clicked: function (field) {
                    handleApplicationDelete(field)
                }
            },
        })
    }

    const handleApplicationUpdate = (budgetInformation) => {
        setInfoOpen(true);
        budgetInformation.update = true;
        setSelectedBudget(budgetInformation);
        setInfoOpen(!infoOpen);
    }

    const handleApplicationDelete = (e) => {
        setSelectedBudget(e)
        setDeleteOpen(true);
    }

    const handleInformationClick = (budgetInformation) => {
        budgetInformation.update = true;
        setSelectedBudget(budgetInformation);
        setInfoOpen(!infoOpen);
    }

    const handleDeleteClick = (budgetInformation) => {
        setSelectedBudget(budgetInformation);
        setDeleteOpen(!deleteOpen)
    }

    const handleAddOpen = () => {
        setAddOpen(!addOpen);
    }

    const BtnCellRenderer = (props) => {
        const btnClickedHandler = (field) => {
            props.clicked(props.data)
        }
        return (
            <IconButton onClick={btnClickedHandler} aria-label="info" size="medium">
                <InfoOutlinedIcon fontSize="inherit"/>
            </IconButton>
        )
    }

    const BtnCellDelete = (props) => {
        const btnClickedHandler = (field) => {
            props.clicked(props.data)
        }
        return (
            <IconButton onClick={btnClickedHandler} disabled={disabled} aria-label="delete" size="medium">
                <DeleteOutlined fontSize="inherit"/>
            </IconButton>
        )
    }

    const listBudgetData = async () => {
        try {
            setInfoOpen(false);
            setDeleteOpen(false);
            setReady(false);
            let bugTot = props;
            const response = await service.budget().getBudgetInformation({type: 1, master_account_id: masterAccountId})
            if (response.data.code === 200) {
                setBudgetData(response.data.budgets)
                if (response.data.budgets && response.data.budgets.length > 0) {
                    bugTot.getBudgetTotal(response.data.total, response.data.budgets.length);
                } else {
                    bugTot.getBudgetTotal(0, 0);
                }
                setReady(true)
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (masterAccountId !== 'no org') {
            listBudgetData()
        }
    }, [masterAccountId])


    return (
        <Box>
            <Create
                dialogOpen={addButton}
                onClick={handleAddOpen}
                onChange={() => {
                    listBudgetData();
                    props.onClick(false)
                }}
            />
            <BudgetInformation
                data={selectedBudget}
                dialogOpen={infoOpen}
                onClick={handleInformationClick}
                onChange={listBudgetData}
            />
            <Delete
                data={selectedBudget}
                dialogOpen={deleteOpen}
                onClick={handleDeleteClick}
                onChange={listBudgetData}
            />

            {
                ready &&
                <Paper variant={'outlined'}>
                    <DataGrid
                        fileName={"application_budget"}
                        fixedColumn={columns}
                        //update={false}
                        gridStyle={{height: "calc(100vh - 400px)"}}
                        frameworkComponents={{
                            informationCellRenderer: BtnCellRenderer,
                            deleteCellRender: BtnCellDelete
                        }}
                        gridData={budgetData || []}
                        gridClass="onboarding-grid"/>
                </Paper>
            }
        </Box>
    )
}