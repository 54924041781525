import Paper from "@material-ui/core/Paper";
import {Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import React from "react";

export function ComputeRecommendationCard(props) {
    return (
        <Paper variant={"outlined"} className="card">
            <Typography variant={"h6"}>
                {props.title}
            </Typography>
            <div>
                <p>
                    Findings Summary
                </p>
            </div>
            <div className="summary">
                <div className="title">Summary</div>
                <div className="s-r">
                    <p style={{flexBasis: "60%"}}>Total number of resources that can be optimized</p>
                    <p>: {props.numberOfInstanceOptimized ? props.numberOfInstanceOptimized : " No data"}</p>
                </div>
                <div className="s-r">
                    <p style={{flexBasis: "60%"}}>Total Savings</p>
                    <p>: {props.totalSavings ? props.totalSavings : "No data"}</p>
                </div>
            </div>
            <Link to={props.routeLink} className="more-info">View Recommendations</Link>
        </Paper>
    )
}