import React from "react";
import CustomDialog from "../CustomDialog";
import ApplicationData from "./ApplicationData";

export default function ApplicationUpdate(props) {
    const {open, applicationUpdateInformation} = {...props}
    const dialogStateChanger = (e) => {
        if (e === "close") {
            props.onChange(false);
        }
    }
    return (
        <CustomDialog
            maxWidth="lg"
            fullWidth={true}
            primayactiontext={""}
            title={applicationUpdateInformation.ApplicationName}
            onClick={(e) => dialogStateChanger(e)}
            open={open}
        >
            <div style={{margin: 20}}>
                <ApplicationData onClick={(e) => props.onClick(e)} appData={applicationUpdateInformation}/>
            </div>
        </CustomDialog>
    )
}