import React, {useEffect, useState} from 'react';
import ChangeLogFile from "../../constants/changelog.md";
import ReactMarkdown from "react-markdown";
import './about.scss';

export default function ChangeLog() {

    const [content, setContent] = useState("");

    useEffect(() => {
        fetch(ChangeLogFile)
            .then(res => res.text())
            .then(md => {
                setContent(md)
            })
    })


    return (
        <div className="about-wrapper" style={{width: "90%", fontSize: 16}}>
            <ReactMarkdown children={content}/>
        </div>
    )
}