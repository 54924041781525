import React, {memo, useEffect, useState} from "react";
import WuDialog from "../../General/WuDialog";
import Box from "@material-ui/core/Box";
import {Accounts, ApplicationName, Environment, Region} from "../../General/WuAutoComplete";
import {
    Button,
    Divider,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography
} from "@material-ui/core";
import WuTextField from "../../General/WuTextFeild";
import {ProgressCircleCustomSize} from "../../ProgressCircle/ProgressCircle";
import service from "../../../service/service";
import {AlertWu} from "../../AlertWu/AlertWu";
import {accountLengthAdjust, jsonParser} from "../../../helper/generalFunctions";
import TimeZoneSelector from "./ReUsable";
import {ReportProblemOutlined} from "@material-ui/icons";
import {Autocomplete, Skeleton} from "@material-ui/lab";

export default function PowerScheduleCreate(props) {
    const {open, masterAccountId, data} = {...props}
    const disabled = !!data;
    const [applicationName, setApplicationName] = useState(data ? data.ApplicationName : "");
    const [accountId, setAccountId] = useState(accountLengthAdjust(data ? data.AccountId : "") || "");
    const [environment, setEnvironment] = useState(data ? data.Environment : "");
    const [region, setRegion] = useState(data ? data.Region : "");
    const [startTime, setStartTime] = useState("");
    const [stopTime, setStopTime] = useState("21:00");
    const [timeZone, setTimeZone] = useState("MST");
    const [resource, setResource] = useState(data ? data.ResourceType : "EC2" || "EC2");
    const [resourceId, setResourceId] = useState(data ? data.ResourceId : "" || "");
    const [response, setResponse] = useState(null);
    const [errorObject, setErrorObject] = useState({
        applicationName: !applicationName,
        environment: !environment,
        region: !region,
        account: !accountId,
        stopTime: !stopTime,
        timeZone: !timeZone
    })

    const [ready, setReady] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({error: false, message: undefined});
    const [warningMessageOpen, setWarningMessageOpen] = useState(false);
    const [confirmCreateAfterWarning, setConfirmCreateAfterWarning] = useState(false);

    useEffect(() => {
        setLoading(false);
        setError({error: false, message: undefined});
        setWarningMessageOpen(false);
        setReady(false);
        setConfirmCreateAfterWarning(false);
        setErrorObject({
            applicationName: !applicationName,
            environment: !environment,
            region: !region,
            account: !accountId,
            stopTime: !stopTime,
            timeZone: !timeZone

        })

        setApplicationName(data ? data.ApplicationName : "");
        setAccountId(accountLengthAdjust(data ? data.AccountId : "") || "");
        setEnvironment(data ? data.Environment : "");
        setRegion(data ? data.Region : "");
        setStartTime("");
        setStopTime("21:00");
        setTimeZone("MST");
        setResource(data ? data.ResourceType : "EC2");
        setResourceId(data ? data.ResourceId : "");

    }, [open]);

    useEffect(() => {
        setErrorObject({
            applicationName: !applicationName,
            environment: false,
            region: !region,
            account: !accountId,
            stopTime: !stopTime,
            timeZone: !timeZone
        })
    }, [stopTime, timeZone, applicationName, region, accountId, environment])

    const handleCreatePowerSchedule = async () => {
        try {
            setReady(false);
            setLoading(true);
            setError({error: false, message: undefined});
            await requestCreateResource();
            setReady(true)
            setLoading(false);
        } catch (e) {
            setLoading(false);
            setReady(false);
            let errorString = jsonParser(e.request.response);
            setError({error: true, message: errorString ? errorString.error : e.message})
        }
    }

    const handleCreateRequest = async () => {
        try {
            if (!resourceId) {
                setWarningMessageOpen(true);
            } else {
                setConfirmCreateAfterWarning(true)
            }
        } catch (e) {
            throw e;
        }
    }

    const requestCreateResource = async () => {
        try {
            const params = {
                application_name: applicationName,
                environment: environment || '',
                region: region,
                account_id: accountId,
                timezone: timeZone,
                start_time: startTime,
                stop_time: stopTime,
                resource: resource,
                resource_id: resourceId
            }

            const apiResponse = await service.automations().createPowerScheduleV2(params);
            setResponse(apiResponse.data.data);
            props.onChange("update");
            props.onClick('update')
        } catch (e) {
            throw e
        }
    }

    const handleWarningClose = (e) => {
        setConfirmCreateAfterWarning(true);
        setWarningMessageOpen(false);
    }

    useEffect(() => {
        if (confirmCreateAfterWarning === true) {
            handleCreatePowerSchedule();
        }
        return (() => {
            setConfirmCreateAfterWarning(false)
        })
    }, [confirmCreateAfterWarning])

    const handleDialogClose = (e) => {
        setWarningMessageOpen(false);
    }

    return (
        <WuDialog
            size={"md"}
            onChange={() => props.onChange("close")}
            title={"Power Schedule New Resource"}
            open={open}>

            <WarningDialog
                onChange={handleDialogClose}
                onClick={handleWarningClose}
                warningMessageOpen={warningMessageOpen}
                applicationName={applicationName}/>

            <Box m={3}>
                <Box display={"flex"} className={"gap"}>
                    <Box className={"w-100"} display={"flex"} flexDirection={"column"} style={{gap: 4}}>
                        <ApplicationName
                            disabled={disabled}
                            defaultValue={applicationName || ""}
                            onChange={(e) => {
                                setApplicationName(e.applicationName);
                            }}/>
                        <Environment defaultValue={environment}
                                     disabled={disabled}
                                     onChange={(e) => {
                                         setEnvironment(e.environment);
                                     }}/>

                        <Region defaultValue={region}
                                disabled={disabled}
                                onChange={(e) => {
                                    setRegion(e.region);
                                }}/>

                        <Box mt={1.5}>
                            <Accounts defaultValue={accountId}
                                      disabled={disabled}
                                      onChange={(e) => {
                                          setAccountId(e.accountId)
                                      }}/>
                        </Box>

                    </Box>
                    <Divider orientation={'vertical'} flexItem/>
                    <Box className={"w-100"} display={"flex"} flexDirection={"column"} style={{gap: 10}}>
                        <NonScheduledResourceAutoComplete
                            disabled={disabled}
                            masterAccountId={masterAccountId}
                            defaultValue={resourceId}
                            onChange={(e) => {
                                setResourceId(e)
                            }}
                        />
                        {/*<WuDatePicker/>*/}
                        <WuTextField label={"Start Time"}
                                     type={"time"}
                                     value={startTime}
                                     inputProps={{step: "36000"}}
                                     onChange={(e) => setStartTime(e.target.value)}/>

                        <WuTextField label={"Stop Time"}
                                     error={errorObject.stopTime}
                                     value={stopTime}
                                     type={"time"}
                                     step={3600}
                                     onChange={(e) => {
                                         setStopTime(e.target.value)
                                     }}/>
                        <TimeZoneSelector
                            value={'MST'}
                            error={errorObject.timeZone}
                            onChange={(e) => {
                                setTimeZone(e)
                            }}
                        />
                    </Box>
                </Box>
                <Box mt={3} display={"flex"} className={"gap"} alignItems={"center"}>
                    <Button variant={'contained'}
                            disabled={!Object.values(errorObject).every((currentValue) => {
                                return (currentValue === false)
                            })}
                            disableElevation
                            color={"primary"}
                            onClick={handleCreateRequest}>
                        Create Power Schedule Tag
                    </Button>
                    {
                        loading &&
                        <Loading/>
                    }
                    <SourceSelectRadio onChange={(e) => setResource(e)} defaultValue={resource}
                                       disabled={!!data}/>
                </Box>
                <Box mt={1}>
                    <AlertWu alertOpen={{state: ready}} severity={"success"}
                             message={response ? response : "Power schedule added"}/>
                    <AlertWu alertOpen={{state: error.error}} severity={"error"} message={error.message}/>
                </Box>
            </Box>
        </WuDialog>
    )
}

const Loading = memo(function () {
    return (
        <Box ml={1}>
            <ProgressCircleCustomSize size={16}/>
        </Box>
    )
})

function SourceSelectRadio(props) {
    const {defaultValue, disabled} = {...props};
    const [resource, setResource] = useState(defaultValue || "RDS");

    useEffect(() => {
        props.onChange(resource);
    }, [resource])

    return (
        <RadioGroup row
                    aria-label="position"
                    name="position"
                    onChange={(e) => setResource(e.target.value)}
                    defaultValue="top"

                    style={{marginLeft: 8}}
                    value={resource}>
            <FormControlLabel
                disabled={disabled}
                value="RDS"
                control={<Radio color="secondary"/>}
                label="RDS"
                labelPlacement="end"
            />
            <FormControlLabel
                disabled={disabled}
                value="EC2"
                control={<Radio color="secondary"/>}
                label="EC2"
                labelPlacement="end"
            />
        </RadioGroup>
    )
}

function NonScheduledResourceAutoComplete(props) {
    const {masterAccountId, defaultValue, disabled = false} = {...props}
    const [resources, setResources] = useState([]);
    const [resourceId, setResourceId] = useState(defaultValue ? defaultValue : "")
    const [ready, setReady] = useState(false);
    const getResourceIds = async () => {
        try {
            const params = {master_account_id: masterAccountId}
            const response = await service.automations().getNonScheduledResources(params);
            setResources(response.data.data);
            setReady(true)
        } catch (e) {

        }
    }


    useEffect(() => {
        props.onChange(resourceId)
    }, [resourceId])

    useEffect(() => {
        getResourceIds();
    }, [masterAccountId])

    return (
        <React.Fragment>
            {
                ready ?
                    <Autocomplete
                        disabled={disabled}
                        value={resourceId}
                        freeSolo
                        options={resources.map((option) => option.resourceId)}
                        onChange={(e, value) => setResourceId(value)}
                        inputValue={resourceId}
                        onInputChange={(event, value) => setResourceId(value)}
                        renderInput={(params) => <WuTextField {...params} label="Instance Id/RDS Arn"/>}
                    /> : <Skeleton height={50}/>
            }
        </React.Fragment>
    )
}

function WarningDialog(props) {
    const {warningMessageOpen, applicationName} = {...props}
    return (
        <WuDialog open={warningMessageOpen}
                  onChange={(e) => props.onChange(false)}
                  title={'Confirm Power Schedule'}>
            <Box m={3} mt={2}>
                <Box mb={1} style={{color: '#ff813d'}}>
                    <ReportProblemOutlined fontSize={'large'}/>
                </Box>
                <Typography>
                    All resources with the following
                    Application Name <strong>{applicationName}</strong> will be widely marked for power scheduling. We
                    recommend using resource id while power
                    scheduling.
                </Typography>
                <Box mt={3}>
                    <Button variant={'contained'} disableElevation color={'primary'}
                            onClick={() => props.onClick(true)}>
                        Confirm power schedule
                    </Button>
                </Box>

            </Box>
        </WuDialog>
    )
}