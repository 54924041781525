import React from "react";
import CustomDialog from "../CustomDialog";
import ApplicationStepper from "./ApplicationStepper";

export default function ApplicationCreate(props) {
    const {open} = {...props}

    const dialogStateChanger = (e) => {
        if (e === "close") {
            props.onChange(false);
        }
    }

    return (
        <CustomDialog
            maxWidth="lg"
            fullWidth={true}
            primayactiontext={""}
            title={"Create Application"}
            onClick={(e) => dialogStateChanger(e)}
            open={open}
        >
            <ApplicationStepper/>
        </CustomDialog>
    )
}
