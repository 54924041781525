import {Box, Tooltip, Typography} from "@material-ui/core";
import {HelpOutlined} from "@material-ui/icons";
import propTypes from "prop-types";

export default function LabelWithHelpText(props) {
    const {title, helpText, titleColor = 'textSecondary', titleVariant = 'body2'} = {...props}
    return (
        <Box display={'flex'} gridGap={4} alignItems={'center'}>
            <Typography variant={titleVariant} color={titleColor}>
                {title}
            </Typography>
            <Tooltip title={<Typography variant={'body2'} component={'div'}>{helpText}</Typography>}>
                <HelpOutlined color={'action'} fontSize={'small'} style={{width: 16, height: 16}}/>
            </Tooltip>
        </Box>
    )
}

LabelWithHelpText.propTypes = {
    title: propTypes.string.isRequired,
    helpText: propTypes.oneOfType([propTypes.string, propTypes.object]).isRequired,
    titleColor: propTypes.string,
    titleVariant: propTypes.string,
}