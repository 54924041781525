import {useForm} from "../../../hooks";
import {Link} from "react-router-dom";
import {Box, Checkbox, Divider, FormControlLabel, Paper, Typography} from "@material-ui/core";
import {SearchOutlined} from "@material-ui/icons";
import {LabelWithHelpText, WuAlert, WuButton} from "../../General";
import {Accounts, Region} from "../../General/WuAutoComplete";
import {ResourceTypeSearchWidget} from "./ResourceTypeSearchWidget";
import {FilterWidget} from "./FilterWidget";
import {TitleNote} from "../TitleNode";
import WuLoading from "../../General/WuLoading";

export function SearchScreen(props) {
    const {onSearch, apiProgress = {}} = {...props}
    const INITIAL_VALUES = {
        accountId: '',
        region: '',
        advancedOptions: {
            resourceTypeFilters: [],
            tagFilters: [],
            filterCombinator: 'OR',
            excludeResults: {
                excludeTagPolicyCompliantResource: false,
                excludeFinopsCompliantResource: false
            }
        }
    }

    const [value, setValue] = useForm(INITIAL_VALUES)

    const handleFormUpdate = (key, value) => {
        setValue(key, value)
    }

    const disabled = !(value.accountId && value.region)

    return (
        <Paper className={'search-nd-tag common-wrapper-class'} variant={'outlined'}>
            <TitleNote title={'Search Resources'} subTitle={'Search resources and tag'}/>
            <Accounts onChange={(e) => handleFormUpdate('accountId', e.accountId)}/>
            <Region onChange={(e) => handleFormUpdate('region', e.region)}/>
            <AdvancedOptions
                onChange={(newValue) => handleFormUpdate('advancedOptions', newValue)}
                value={value.advancedOptions}
            />
            <Box mt={0.5} mb={1} display={'flex'} gridGap={16} alignItems={'center'}>
                <WuButton startIcon={<SearchOutlined/>}
                          onClick={() => onSearch(value)}
                          disabled={disabled}>
                    Search
                </WuButton>

                {
                    apiProgress.loading &&
                    <WuLoading message={'Please wait... Generating report.'} open={apiProgress.loading}/>
                }
            </Box>

            <WuAlert error={Boolean(apiProgress.error)}
                     success={false} errorMessage={apiProgress.error}
                     paperProps={{
                         style: {
                             marginTop: Boolean(apiProgress.error) ? '16px' : 0,
                             marginBottom: Boolean(apiProgress.error) ? '8px' : 0
                         }
                     }}
            />
        </Paper>
    )
}


function AdvancedOptions(props) {
    const {onChange, value} = {...props}
    const {excludeResults} = value

    const handleFormUpdate = (key, newValue) => {
        onChange({...value, [key]: newValue})
    }

    const handleExcludeUpdate = (e) => {
        const excludeNow = value.excludeResults
        excludeNow[e.target.name] = e.target.checked
        onChange({...value, excludeResults: excludeNow})
    }

    return (
        <div className={'at-advanced-options at-grid'}>
            <Box>
                <LabelWithHelpText title={'Resource Type(s)'}
                                   helpText={'Specifies the resource types that you want included in the response'}/>
                <ResourceTypeSearchWidget
                    onChange={(e) => handleFormUpdate('resourceTypeFilters', e)}
                    value={value.resourceTypeFilters || []}
                />
            </Box>
            <Divider/>
            <Box>
                <LabelWithHelpText title={'Exclude Results'}
                                   helpText={'Specifies whether to exclude resources that are compliant.'}
                />
                <Box mt={1}>
                    <FormControlLabel control={<Checkbox name={'excludeTagPolicyCompliantResource'}
                                                         onChange={handleExcludeUpdate}
                                                         checked={excludeResults.excludeTagPolicyCompliantResource}/>}
                                      label={
                                          <div>

                                              <span>Exclude resources that are compliant with  </span>
                                              <Link to={'/guard-rails/tag-policy'}>tag policy</Link>
                                          </div>
                                      }/>
                </Box>
                <Box mt={-1}>
                    <FormControlLabel control={<Checkbox name={'excludeFinopsCompliantResource'}
                                                         onChange={handleExcludeUpdate}
                                                         disabled
                                                         checked={excludeResults.excludeFinopsCompliantResource}/>}
                                      label="Exclude resources compliant with finops tagging standards"/>
                </Box>
            </Box>
            <Divider style={{marginTop: 8}}/>
            <Box>
                <LabelWithHelpText title={'Filters'}
                                   helpText={'Specifies a list of TagFilters (keys and values) to restrict the output to only those resources that have tags with the specified keys and values'}/>
                <FilterWidget
                    onChange={(e) => handleFormUpdate('tagFilters', e)}
                    value={value.tagFilters}
                    filterCombinator={value.filterCombinator}
                    onFilterCombinatorChange={(e) => handleFormUpdate('filterCombinator', e)}/>
            </Box>
        </div>
    )
}