import React from "react";
import Typography from "@material-ui/core/Typography";

export default function ReportType(props) {
    const {title, subTitle, numberOfReports} = {...props}

    return (
        <div>
            <Typography variant={"subtitle1"}>
                {title}
            </Typography>
            <Typography variant={"subtitle2"} gutterBottom>
                {subTitle}
                {' (' + numberOfReports + ')'}
            </Typography>
        </div>
    )
}