import React, {useEffect, useState} from "react";
import Paper from "@material-ui/core/Paper";
import TagPolicyAccordion from "./TagPolicyAccordion";
import service from "../../../service/service";
import {ProgressCircleCustomSize} from "../../ProgressCircle/ProgressCircle";
import {AlertWu} from "../../AlertWu/AlertWu";
import {jsonParser} from "../../../helper/generalFunctions";
import {useOrganization} from "../../../hooks/useOrganization";

//dummy
import tagPolicyDummy from '../../../assets/finthesys/tag-policy.json';

export default function TagPolicy() {
    const {organization: masterAccountId} = useOrganization()
    const [tagPolicies, setTagPolicies] = useState({});
    const [loading, setLoading] = useState(false);
    const [ready, setReady] = useState(false);
    const [error, setError] = useState({error: false, message: undefined});

    useEffect(() => {

        if (masterAccountId !== 'no org') {
            setReady(false);
            setError({error: false, message: undefined});
            setLoading(true)
            const params = {master_account_id: masterAccountId}
            setTimeout(() => {
                setLoading(false);
                setTagPolicies(tagPolicyDummy.data);
                setReady(true)
            }, 2000)
            /*service.guardRails().getTagPolicies(params)
                .then((ref) => {
                    setTagPolicies(ref.data.data);
                    setReady(true)
                })
                .catch((err) => {
                    const errorObject = jsonParser(err?.request?.response);
                    setError({error: true, message: errorObject ? errorObject.error : err.message})
                })
                .finally(() => {
                    setLoading(false)
                })*/
        }

    }, [masterAccountId])

    return (
        <div style={{width: "100%"}}>
            <Paper variant={"outlined"} className="guard-rails-item">
                <div className="inner-wrapper">
                    <div className="title">
                        Tag policies
                    </div>
                    <div className="tag-policies">

                        {
                            ready && <TagPolicyAccordion tagPolicies={tagPolicies}/>
                        }
                        {
                            loading && <div style={{paddingLeft: 18}}><ProgressCircleCustomSize size={16}/></div>
                        }

                        <div style={{paddingTop: 20}}>
                            <AlertWu severity="error" alertOpen={{state: error.error}} message={error.message}/>
                        </div>
                    </div>
                </div>
            </Paper>
        </div>
    )
}