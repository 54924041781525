import React from "react";
import {Paper, Typography} from "@material-ui/core";
import {NavLink} from "react-router-dom";
import {
    EcoOutlined,
    FaceOutlined, GroupWorkOutlined,
    LocalOfferOutlined,
    LocationCityOutlined, PersonAddOutlined,
    SettingsInputComponentOutlined,
    TuneRounded
} from "@material-ui/icons";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";


export default function SettingDrawer() {

    const styleIcon = {paddingRight: 10}

    return (
        <Paper elevation={0} className="drawer">
            <div id="on-boarding">
                <Typography variant="subtitle1" className="sub-heading">
                    Onboarding
                </Typography>
                <nav>
                    <ul>
                        <li>
                            <NavLink to="/settings/region"> <LocationCityOutlined style={styleIcon}/>Region </NavLink>
                        </li>
                        <li>
                            <NavLink to="/settings/environment"> <EcoOutlined style={styleIcon}/>Environment </NavLink>
                        </li>
                        <li>
                            <NavLink to="/settings/account"> <AccountTreeOutlinedIcon style={styleIcon}/>Account
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/settings/resource-tags"> <LocalOfferOutlined style={styleIcon}/>Resource Tags</NavLink>
                        </li>
                        <li>
                            <NavLink to="/settings/cross-account-role"> <SettingsInputComponentOutlined
                                style={styleIcon}/>Cross Account
                                Roles </NavLink>
                        </li>
                        <li>
                            <NavLink to="/settings/organization"> <GroupWorkOutlined style={styleIcon}/>Organization
                            </NavLink>
                        </li>
                    </ul>
                </nav>
                <Typography variant="subtitle1" className="sub-heading">
                    Administration
                </Typography>
                <nav>
                    <ul>
                        <li>
                            <NavLink to="/settings/profile"> <FaceOutlined style={styleIcon}/>Profile </NavLink>
                        </li>
                        <li>
                            <NavLink to="/settings/users"> <PersonAddOutlined style={styleIcon}/>Users </NavLink>
                        </li>
                        <li>
                            <NavLink to="/settings/resource-management"> <TuneRounded style={styleIcon}/>Resource Management </NavLink>
                        </li>
                    </ul>
                </nav>
            </div>
        </Paper>
    )
}