import React from 'react'
import {Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import PropTypes from "prop-types";


export default function WuSnackBar(props) {
    const {open, severity, message, onClose, autoHideDuration = 6000} = {...props}
    return (
        <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={onClose}>
            <Alert severity={severity}>
                {message}
            </Alert>
        </Snackbar>
    )
}

WuSnackBar.propTypes = {
    open: PropTypes.bool.isRequired,
    severity: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    onClose: PropTypes.any,
    autoHideDuration: PropTypes.number,
};