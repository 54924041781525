import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import {useHistory} from 'react-router-dom';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useAuthorization} from "../../hooks";

const useStyles = makeStyles((theme) => ({
    small: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));

export default function FinopsAvatar() {
    const classes = useStyles();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [authorization, setAuthorization] = useAuthorization()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        window.accessToken = undefined
        setAuthorization(null)
        history.replace("/login");
    }

    return (
        <div>
            <Avatar style={{background: "#9467ff", fontWeight: 600}} className={classes.small}
                    onClick={handleClick}>F</Avatar>
            <Menu
                elevation={1}
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
        </div>
    )
}