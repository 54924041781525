import React from 'react';
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {WuValidationEnabledTextField} from "../General/WuTextFeild";
import {useFormikContext} from "formik";

export default function PermissionGroupAutoComplete() {

    const {values: formValues} = useFormikContext()
    const handleRegionValueChange = (_, value) => {
        formValues.permissionGroup = value.name
    }

    return (
        <Autocomplete
            renderInput={(params) => <WuValidationEnabledTextField {...params}
                                                                   label="Permission Group"
                                                                   required
                                                                   name={'permissionGroup'}/>}
            getOptionSelected={(option, value) => option.name === value.name}
            onChange={handleRegionValueChange}
            groupBy={option => `${option.category} Group`}
            renderGroup={option => <GroupRenderer {...option}/>}
            options={groups}
            getOptionLabel={(option) => option.name}/>
    )
}

function GroupRenderer({group, children}) {
    return (
        <Box mx={2} my={1}>
            <Typography color={'textSecondary'} gutterBottom variant={'subtitle2'}>
                {group}
            </Typography>
            <Box borderLeft={1} borderColor={'var(--border)'} ml={0.5}>
                {children}
            </Box>
        </Box>
    )
}

const groups = [
    {name: 'Application Owner', category: 'Custom'},
    {name: 'BU Champion', category: 'Custom'},
    {name: 'Admin', category: 'Predefined'},
    {name: 'FinOps Admin', category: 'Predefined'},
    {name: 'Procurement', category: 'Predefined'}
]