import React, {useState} from "react";
import './dashboard.scss';
import Drawer from '../Drawer/Drawer';
import Header from '../Header/Header';
import UpdateNotification from "../UpdateNotification/UpdateNotification";
import SideDrawer from "../Navigation/SideDrawer";


function Dashboard() {
    const [toggleButton, setToggleButton] = useState(false)
    const handleToggleClick = (event) => {
        setToggleButton(event)
    }

    return (
        <div className="dashboard-wrapper">
            <Header onClick={handleToggleClick}/>
            <UpdateNotification/>
            <SideDrawer/>
            {/*<Drawer open={!toggleButton}/>*/}
        </div>
    );
}

export default Dashboard;
