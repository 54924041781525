import React, {useState} from "react";
import {Button, Typography} from "@material-ui/core";
import {AddEnvironment, AddRegion} from "./AddEnvironment";
import {Table} from "../General/Table";


export default function EnvironmentSetting() {

    const [newEnvironment, setNewEnvironment] = useState(false);
    const rowData = [
        {
            environmentCode: "DEV",
            environmentName: "Development",
            comment: "Development Environment"
        },
        {
            environmentCode: "PROD",
            environmentName: "Production",
            comment: "Production Environment"
        }
    ]

    return (
        <div>
            <Typography>
                An environment is a user-defined collection of resources that hosts an application.
                An environment is the application's mechanism for bringing together components with the agent that
                deploys them. Environments are typically modeled on some stage of the software project lifecycle, such
                as development, QA, or production. A resource is a deployment target, such as a database or Java™
                Platform, Enterprise Edition container. Usually, resources are found on the same host where the agent
                that manages them is located. A host can be a physical system, virtual machine, or be cloud-based.
            </Typography>
            <Table title={"Active Environments"} tableData={rowData}/>
            <Button variant={"contained"} disableElevation onClick={() => setNewEnvironment(true)} color={"primary"}>
                New Environment
            </Button>
            {
                newEnvironment &&
                <AddEnvironment/>
            }
        </div>
    )
}


