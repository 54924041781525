import React, {useState} from "react";
import PageTitle from "../../General/PageTitle/PageTitle";
import UnUsedResourceFilter from "../UnUsedEbs/UnUsedResourceFilter";
import UnUsedAmiAccordion from "./UnusedAmiAccordion";
import '../../../service/service';
import service from "../../../service/service";
import WuStack from "../../General/WuStack";
import SummaryCard from "../SummaryCard";
import UnusedAmiDownloadReport from "./UnusedAmiDownloadReport";


export default function UnUsedAmieHome() {
    const [filter, setFilter] = useState([]);
    const refreshFunctionApiCall = service.monitoring().onDemandCronRefreshAmi
    const summaryFunctionApiCall = service.monitoring().overAllSummaryAmi


    const summaryProps = {
        summaryCardProps: {
            countCard: {
                itemKey: 'numberOfAmi',
                description: 'Number of AMI',
                helpText: 'Total AMIs in all organization',
                nameOfItem: 'AMI Summary',
                title: '# of AMI',
            }
        },
        priceDisabled: true,
        downloadComponent: <UnusedAmiDownloadReport/>,
        refreshApiFunction: refreshFunctionApiCall,
        summaryApiFunction: summaryFunctionApiCall,
    }

    return (

        <div style={{width: '100%'}}>
            <WuStack style={{marginTop: 20}} spacing={3} direction={'column'}>
                <PageTitle
                    title={"Wastage Monitoring"}
                    subTitle={"AMI's / Images"}
                />
                <UnUsedResourceFilter
                    backButtonLink={'/monitoring/unused-resources/summary'}
                    onClick={(event) => setFilter(event)}/>
            </WuStack>
            <WuStack style={{marginTop: 20}} spacing={3}>
                <UnUsedAmiAccordion filter={filter}/>
                <SummaryCard {...summaryProps}/>
            </WuStack>
        </div>
    )
}