import {Grid, Paper, Popover, Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import moment from "moment";
import {CpuUsage, DetailsPageCluster, MemoryUsage, StatusComponent} from "../GridColumComponents";
import {useEffect, useState} from "react";
import JSONPretty from "react-json-pretty";
import {apiErrorResponseJsonParser} from "../../../helper/generalFunctions";
import {CpuUtilizationGraph, MemoryUtilizationGraph} from "../Graphs";
import service from "../../../service/service";
import PriceDistributionGraph from "../Graphs/PriceDistributionGraph";
import {useOrganization} from "../../../hooks/useOrganization";
import {ecsInstanceDescription} from "../../../assets/finthesys/ecs";

const fontProperties = {fontWeight: 600}

export function InstanceDetails({apiProgress, setApiProgress, extraParams}) {
    const {organization: masterAccountId} = useOrganization()
    const [instanceData, setInstanceData] = useState({})
    const {accountId, containerInstanceArn, region, clusterArn} = {...extraParams}

    const fetchData = async () => {
        try {
            setApiProgress({loading: true, ready: false, error: false})
            const params = {
                accountId: accountId,
                InstanceArn: containerInstanceArn,
                clusterArn: clusterArn,
                region: region,
                masterAccountId: masterAccountId
            }
            const {data: {data}} = await service.ecs().describeEcsInstance(params)
            setInstanceData(data)
            setApiProgress({loading: false, ready: true, error: false})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setApiProgress({loading: false, ready: false, error: error})
        }
    }

    const fetchDummyData = async () => {
        try {
            setApiProgress({loading: true, ready: false, error: false})
            const params = {
                accountId: accountId,
                InstanceArn: containerInstanceArn,
                clusterArn: clusterArn,
                region: region,
                masterAccountId: masterAccountId
            }
            const {data} = ecsInstanceDescription
            setInstanceData(data)
            setApiProgress({loading: false, ready: true, error: false})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setApiProgress({loading: false, ready: false, error: error})
        }
    }

    useEffect(() => {
        if(masterAccountId !== 'no org'){
            fetchDummyData().then()
        }
    }, [masterAccountId])

    return (
        <div className={'cluster-additional-details'}>
            {apiProgress.loading && <div className={'cluster-additional-details-inner'}>
                <Box display={'flex'} alignItems={'center'} gridGap={8} mb={2.5}>
                    <TagInformation tagKey={'ApplicationName'} tagValue={instanceData?.applicationName}/>
                    <TagInformation tagKey={'Environment'} tagValue={instanceData?.environment}/>
                    <TagInformation tagKey={'CostCenter'} tagValue={instanceData?.costCenter}/>
                    <TagInformation tagKey={'ApplicationOwner'} tagValue={instanceData?.applicationOwner}/>
                    <TagInformation tagKey={'ApplicationCode'} tagValue={instanceData?.applicationCode}/>
                </Box>

                <Box display={'flex'}
                     alignItems={'center'}
                     className={'cluster-overall-info'}
                     style={{marginBottom: 24}}
                     width={'100%'}
                     gridGap={16}>

                    <ShortSummaryCard title={'Service Status'}>
                        <StatusComponent value={instanceData.status}/>
                    </ShortSummaryCard>
                    <ShortSummaryCard title={'Registered At'}>
                        <Typography style={fontProperties}>
                            {moment(instanceData.registeredAt, 'YYYY-MM-DD HH:mm:ss.SSS').format('DD MMMM, YYYY')}
                        </Typography>
                    </ShortSummaryCard>
                    <ShortSummaryCard title={'Cluster'}>
                        <Typography style={fontProperties} component={'div'}>
                            <DetailsPageCluster
                                data={{
                                    accountId: instanceData.accountId,
                                    accountAlias: instanceData.accountAlias,
                                    clusterArn: instanceData.clusterArn,
                                    region: instanceData.region
                                }}
                                value={instanceData.clusterArn}/>
                        </Typography>
                    </ShortSummaryCard>
                    <ShortSummaryCard title={'CPU Utilization'}>
                        <CpuUsage data={{cpuAverage: instanceData?.cpuAverage }}/>
                    </ShortSummaryCard>
                    <ShortSummaryCard title={'Memory Utilization'}>
                        <MemoryUsage data={{
                            memoryAverage: instanceData?.memoryAverage
                        }}/>
                    </ShortSummaryCard>
                </Box>

                <Grid spacing={3} container>
                    <Grid item xs={6}>
                        <PriceDistributionGraph title={'Price distribution over months'}
                                                data={instanceData?.graph?.price}
                                                height={'400px'}/>

                    </Grid>
                    <Grid item xs={6}>
                        <Grid spacing={2} container>
                            <Grid item xs={12}>
                                <CpuUtilizationGraph title={'CPU Utilization'}
                                                     data={instanceData?.graph?.cpu}
                                                     height={'150px'}/>
                            </Grid>
                            <Grid item xs={12}>
                                <MemoryUtilizationGraph title={'Memory Utilization'}
                                                        data={instanceData?.graph?.memory}
                                                        height={'180px'}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Box mt={2}>
                    <Typography style={fontProperties}> Container Instance Details </Typography>
                </Box>
                <Grid container spacing={3} style={{marginTop: 8, marginBottom: 24}}>
                    <Grid item xs={6}>
                        <Paper variant={'outlined'} className={'container-summary-card-additional-info'}>
                            <Attribute title={'Attributes'} value={instanceData.attributes}/>
                            <Summary title={'Docker Version'} value={instanceData.dockerVersion}/>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper variant={'outlined'} className={'container-summary-card-additional-info'}>
                            <Summary title={'Agent Connected'} value={instanceData.agentConnected}/>
                            <Summary title={'Agent Version'} value={instanceData.agentVersion}/>
                        </Paper>
                    </Grid>
                </Grid>
                <Box mt={2}>
                    <Typography style={fontProperties}> EC2 instance details </Typography>
                </Box>
                <Grid container spacing={3} style={{marginTop: 8, marginBottom: 24}}>
                    <Grid item xs={6}>
                        <Paper variant={'outlined'} className={'container-summary-card-additional-info'}>
                            <Summary title={'Instance Id'} value={instanceData.instanceId}/>
                            <Summary title={'Name'} value={instanceData.instanceName}/>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper variant={'outlined'} className={'container-summary-card-additional-info'}>
                            <Summary title={'Instance Type'} value={instanceData.instanceType}/>
                            <Summary title={'Instance State'} value={instanceData.instanceState}/>
                        </Paper>
                    </Grid>
                </Grid>
            </div>}
        </div>
    )
}

function Summary({title, value}) {
    return (
        <div className={'container-summary-card-task'}>
            <Typography variant={'body1'} gutterBottom>
                {title}
            </Typography>
            <Typography variant={'body1'} style={fontProperties}>
                {value}
            </Typography>
        </div>
    )
}

function TagInformation({tagKey, tagValue = '-'}) {
    return (
        <div className={'container-tag'}>
            {tagKey}:
            <Typography component={'span'} style={{fontWeight: 600, paddingLeft: 8}}>
                {tagValue}
            </Typography>
        </div>
    )
}

function ShortSummaryCard({title, children}) {
    return (
        <Paper variant={'outlined'} style={{padding: 16}}>
            <Typography gutterBottom color={'textSecondary'}>{title}</Typography>
            {children}
        </Paper>
    )
}

function Attribute({title, value}) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handlePopOverOpen = (e) => {
        setAnchorEl(e.currentTarget)
    }

    return (
        <div className={'container-summary-card-task'}>
            <Typography gutterBottom>{title}</Typography>
            <div style={{fontWeight: 600, cursor: 'pointer'}} onMouseEnter={handlePopOverOpen}>
                {'{...}'}
            </div>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={handleClose}
                disableRestoreFocus
            >
                <JSONPretty json={value}/>
            </Popover>
        </div>
    )
}
