import React, {useEffect, useState} from "react";
import service from "../../../service/service";
import {convertArrayOfOrganizationToArrayOfAccountList} from "../utilities";
import {SpendGraph} from "./SpendGraph";
import Box from "@material-ui/core/Box";
import {Paper} from "@material-ui/core";
import {LoadingGraphs} from "../LandingPage";

//dummy data

import costExplorerMonthly from '../../../assets/finthesys/dashboard-cost-and-usage-monthly.json';

export default function SpendSummary(props) {
    const {organizationList} = {...props}
    const [monthUsage, setMonthUsage] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ready, setReady] = useState(false);
    const [error, setError] = useState({error: false, message: undefined})

    useEffect(() => {
        fetchDummyData()
        //fetchData()
    }, []);


    const fetchData = () => {
        setLoading(true);
        setError({error: false, message: undefined})
        setReady(false);
        const params = {organization_ids: convertArrayOfOrganizationToArrayOfAccountList(organizationList)}
        service.dashboard().costInformation(params)
            .then((res) => {
                setMonthUsage(res.data.data);
                props.onChange(res.data.data)
                setReady(true)
                setLoading(false);
            })
            .catch(err => {
                setError({
                    error: true,
                    message: err.message
                })
                setLoading(false);
            })
    }

    const fetchDummyData = () => {
        setLoading(true);
        setError({error: false, message: undefined})
        setReady(false);
        setTimeout(() => {
            setMonthUsage(costExplorerMonthly.data);
            props.onChange(costExplorerMonthly.data)
            setReady(true)
            setLoading(false);
        }, 2000)
    }

    return (
        <Paper variant={"outlined"} className={"w-100"} style={{paddingTop: 8}}>
            <Box m={1}>
                {ready && <SpendGraph graphData={monthUsage}/>}
                {!ready && <LoadingGraphs/>}
            </Box>
        </Paper>
    )
}


