import {useEffect, useState} from "react";
import {useEcs} from "../../../hooks";
import {useHistory} from "react-router-dom";
import {Dialog, Typography, IconButton, Breadcrumbs, LinearProgress} from "@material-ui/core";
import {CloseRounded, KeyboardArrowRight} from "@material-ui/icons";
import {ClusterDetails} from "../Clusters/ClusterDetails";
import {ServiceDetails} from "../Services/ServiceDetails";
import {TaskDetails} from "../Tasks/TaskDetails";
import {InstanceDetails} from "../Instances/InstaceDetails";
import WuAlert from "../../General/WuAlert";

export default function DetailsDialog(props) {
    const {resourceType} = {...props}
    const history = useHistory()
    const {ecs} = useEcs()
    const {detailsParam = {}} = {...ecs}
    const [apiProgress, setApiProgress] = useState({loading: false, ready: false, error: false})

    useEffect(() => {
        if (!detailsParam?.accountId) history.push('/ecs')
    }, [detailsParam?.accountId])

    return (
        <Dialog open={true} fullScreen>
            <div className={'ecs-details-container'}>
                <DialogActionBar resourceType={resourceType} {...detailsParam} />
                {apiProgress.loading && <LinearProgress color={'secondary'}/>}
                <WuAlert error={Boolean(apiProgress.error)} success={false} errorMessage={apiProgress.error}/>
                <div className={'ecs-additional-information'}>
                    {
                        resourceType === 'cluster' &&
                        <ClusterDetails extraParams={detailsParam} apiProgress={apiProgress}
                                        setApiProgress={setApiProgress}/>
                    }
                    {
                        resourceType === 'service' &&
                        <ServiceDetails extraParams={detailsParam} apiProgress={apiProgress}
                                        setApiProgress={setApiProgress}/>
                    }
                    {
                        resourceType === 'task' &&
                        <TaskDetails extraParams={detailsParam} apiProgress={apiProgress}
                                     setApiProgress={setApiProgress}/>
                    }
                    {
                        resourceType === 'instance' &&
                        <InstanceDetails extraParams={detailsParam} apiProgress={apiProgress}
                                         setApiProgress={setApiProgress}/>
                    }
                </div>

            </div>
        </Dialog>
    )
}


function DialogActionBar(props) {
    const {resourceType, identifier, accountId, accountAlias} = {...props}
    const history = useHistory()

    const handleDialogClose = () => {
        history.push('/ecs')
    }

    return (
        <div className={'ecs-dialog-action-bar'}>
            <div className={'ecs-dialog-title'}>
                <Breadcrumbs separator={<KeyboardArrowRight fontSize="small"/>}>
                    <Typography>
                        {`${accountId}(${accountAlias})`}
                    </Typography>
                    <Typography>
                        {resourceType || 'unknown'}
                    </Typography>
                    <Typography>
                        additional details
                    </Typography>
                </Breadcrumbs>
                <Typography variant={'h6'}>
                    {identifier || 'unknown'}
                </Typography>
            </div>
            <IconButton onClick={handleDialogClose}>
                <CloseRounded/>
            </IconButton>
        </div>
    )
}
