import React, {useEffect, useState} from "react";
import './notificaiton.scss';
import {IconButton, Paper, Slide, Typography} from "@material-ui/core";
import {CloseOutlined} from "@material-ui/icons";
import service from "../../service/service";

export default function UpdateNotification() {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");

    const handleCloseEvent = () => {
        setOpen(false);
    }


    const handleRequest = async () => {
        try {
            const response = await service.general().getUpdateNotification();
            setMessage(response.data.data.message);
            setOpen(response.data.data.update);
        } catch (e) {
            console.log(e)
            //throw e
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await handleRequest();
        }
        fetchData();
    }, [])

    return (
        <Slide in={open} timeout={500} direction="up" mountOnEnter unmountOnExit>
            <Paper square elevation={7} className={"notification-wrapper"}>
                <Typography>
                    {message}
                </Typography>
                <IconButton onClick={handleCloseEvent}>
                    <CloseOutlined/>
                </IconButton>
            </Paper>
        </Slide>
    )
}