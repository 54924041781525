import React, {useEffect, useState} from "react";
import ReactApexChart from "react-apexcharts";

export default function SpendCurrentMonthTrend({spendTrend}) {
    const [graphState, setGraphState] = useState({});
    const [ready, setReady] = useState(false);
    let state = {
        options: {
            chart: {
                type: 'area',
                height: 100,
                stacked: false,
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: false
                }
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                labels: {
                    show: false
                },
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false
                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    inverseColors: false,
                    opacityFrom: 0.45,
                    opacityTo: 0.05,
                    stops: [20, 100, 100, 100]
                },
            },
            yaxis: {
                labels: {
                    show: false
                }
            },
            grid: {
                show: false
            }
        },
    };

    useEffect(() => {
        setReady(false);
        let xAxis = []
        let yAxis = []
        let forecastSum = 0
        spendTrend.forEach((data) => {
            forecastSum = forecastSum + Number(data.forecast);
            data.failSafe.forEach((monthData) => {
                if (xAxis.indexOf(monthData.month) === -1) {
                    xAxis.push(monthData.month);
                    yAxis.push(Number(monthData.amount))
                } else {
                    yAxis[xAxis.indexOf(monthData.month)] = yAxis[xAxis.indexOf(monthData.month)] + Number(monthData.amount)
                }
            });
        })
        yAxis[yAxis.length - 1] = forecastSum;
        state['series'] = [{data: yAxis}];
        setGraphState(state);
        console.log(state)
        setReady(true)
    }, [])
    return (
        <React.Fragment>
            {
                ready &&
                <ReactApexChart options={graphState.options} series={graphState.series} type="area" height={140}/>
            }
        </React.Fragment>
    )
}