import DataGrid from "../../General/DataGrid";
import {Paper} from "@material-ui/core";
import {containerDataColumns} from "./gridProperties";

export default function ContainerInformation({containerInformation = []}) {
    return (
        <Paper variant={'outlined'}>
            <DataGrid gridData={containerInformation}
                      fixedColumn={containerDataColumns}
                      gridStyle={{height: 'calc(100vh - 550px)'}}
                      suppressDataControlPanel={true}/>
        </Paper>
    )
}