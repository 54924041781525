import {useState} from "react";
import {UploadHome} from "./UploadHome";
import {UploadPreview} from "./UploadPreview";
import {useForm} from "../../../hooks";
import {ConfirmTagging} from "./ConfirmTagging";
import {apiErrorResponseJsonParser} from "../../../helper/generalFunctions";
import service from "../../../service/service";


export default function Upload() {
    const [selectedResources, setSelectedResources] = useState([])
    const [apiProgress, setApiProgress] = useState({loading: false, ready: false, error: false})
    const [uploadProgress, setUploadProgress] = useState(null)
    const [resourceData, setResourceData] = useState(null)

    const [state, setState] = useForm({
        previewOpen: false,
        confirmTaggingOpen: false,
        accountId: "",
        additionalOptions: {
            excludeNonFixableResource: false,
            replaceWrongValueInPreview: false
        }
    })

    const handleChange = (source, newValue) => {
        setState(source, newValue)
    }

    const uploadFile = async (files) => {
        try {
            setUploadProgress(0)
            if (files[0]) {
                setApiProgress({loading: true, ready: false, error: false})
                let bodyFormData = new FormData();
                bodyFormData.append('files', files[0]);
                bodyFormData.append('accountId', state.accountId);
                bodyFormData.append('excludeNonFixableResources', state.additionalOptions.excludeNonFixableResource ? '1' : '0');
                const {data: {data}} = await service.automations().autoTaggingReportVerify(bodyFormData, options);
                setResourceData(data)
                setApiProgress({loading: false, ready: true, error: false})
                setState('previewOpen', true)
            }
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setApiProgress({loading: false, ready: false, error: error})
        }
    }

    const options = {
        onUploadProgress: (progressEvent) => {
            const {loaded, total} = progressEvent;
            let percent = Math.floor(loaded * 100 / total);
            setUploadProgress({
                percentage: percent,
                fileTotalSize: total,
                fileUploadProgressSize: loaded
            })
        }
    }

    const handlePreviewClose = () => {
        setUploadProgress(null)
        handleChange('previewOpen', false)
    }

    return (
        <div>
            <UploadHome
                value={state.additionalOptions}
                onValueChange={handleChange}
                onUpload={uploadFile}
                apiProgress={apiProgress}
                uploadProgress={uploadProgress}
            />
            {
                state.previewOpen && resourceData?.header && <UploadPreview
                    open={state.previewOpen}
                    resources={resourceData?.validatedData || []}
                    gridHeader={resourceData?.header || []}
                    selectedResources={selectedResources}
                    onSelectionChange={setSelectedResources}
                    onClose={handlePreviewClose}
                    invalidResourceCount={resourceData?.resourcesWithErrors}
                    totalProcessedResources={resourceData?.totalProcessedResources}
                    onTag={() => handleChange('confirmTaggingOpen', true)}
                />
            }
            {
                state.confirmTaggingOpen && <ConfirmTagging
                    resources={selectedResources}
                    accountId={state.accountId}
                    onChange={(e) => handleChange('accountId', e)}
                    onClose={() => handleChange('confirmTaggingOpen', false)}
                    open={state.confirmTaggingOpen}
                />
            }
        </div>
    )
}