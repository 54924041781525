import {urls} from "./urls";
import {
    AccountTreeOutlined,
    AirlineSeatIndividualSuiteOutlined,
    AppsOutlined,
    AssessmentOutlined,
    DashboardOutlined,
    DescriptionOutlined,
    EmojiObjectsOutlined,
    LockOutlined,
    MonetizationOnOutlined,
    CategoryOutlined,
    ScatterPlotRounded,
    SettingsOutlined,
    TimelineOutlined,
    TrendingUpOutlined,
    WbIncandescentOutlined,
    FiberNewRounded,
    AssignmentTurnedInOutlined,
    InfoOutlined,
    DnsOutlined,
    SecurityOutlined,
    DeleteSweepOutlined,
    FlightTakeoffOutlined, AccountBalanceWalletOutlined
} from "@material-ui/icons";

const admin = localStorage.getItem('loginType') === "admin"
const superAdmin = localStorage.getItem('loginPriority') === "super-admin"

const configuration = [
    {
        name: 'Home',
        url: urls.dashboard,
        svgIcon: <DashboardOutlined/>,
    },
    {
        name: 'Onboarding',
        url: urls.applicationOnboardding,
        svgIcon: <FlightTakeoffOutlined/>,
    },
    {
        name: 'Run Budgets',
        url: '',
        svgIcon: <MonetizationOnOutlined/>,
        child: [
            {
                name: 'Application',
                url: urls.applicationBudget,
                svgIcon: <AppsOutlined/>,
            },
            {
                name: 'Account',
                url: urls.accountBudget,
                svgIcon: <AccountTreeOutlined/>,
            }
        ]
    },
    {
        name: 'Reports & Trend',
        url: urls.report,
        svgIcon: <AssessmentOutlined/>,
    },
/*    {
        name: 'Guardrails',
        url: urls.guardRails,
        svgIcon: <LockOutlined/>,
    },*/
    /*{
        name: 'Cost Optimization',
        url: '',
        svgIcon: <EmojiObjectsOutlined/>,
        child: [

        ]
    },*/
    {
        name: 'Cost Optimization',
        url: urls.costRecommendation,
        svgIcon: <EmojiObjectsOutlined/>,
    },
    /*{
        name: 'Power Scheduling',
        url: urls.automations,
        svgIcon: <SettingsOutlined/>,
    },*/
    {
        name: 'Wastage Monitoring',
        url: '',
        svgIcon: <TimelineOutlined/>,
        child: [
            {
                name: 'Cost Anomalies',
                url: urls.costAnomalies,
                svgIcon: <ScatterPlotRounded/>,
            },
            /*{
                name: 'Storage',
                url: urls.unusedEfs,
                svgIcon: <DnsOutlined/>,
            },*/
            {
                name: 'Compute & Storage',
                url: urls.unusedResources,
                svgIcon: <AirlineSeatIndividualSuiteOutlined/>,
            },
            {
                name: 'Containers',
                url: urls.ecs,
                svgIcon: <AccountBalanceWalletOutlined/>,
            },
            /*{
                name: 'Wastage Monitoring',
                url: urls.costAnomalies,
                svgIcon: <DeleteSweepOutlined/>,
                child: [
                ]
            },*/
            {
                name: 'Log Tracking',
                url: urls.logTracking,
                svgIcon: <DescriptionOutlined/>,
            },
            {
                name: 'New Deployments',
                url: urls.newDeployments,
                svgIcon: <FiberNewRounded/>,
            },
        ]
    },
    {
        name: 'Showback',
        url: urls.wuReport,
        svgIcon: <WbIncandescentOutlined/>,
        child: [
            /*{
                name: 'Showback Reports',
                url: urls.wuReport,
                svgIcon: <AssessmentOutlined/>,
            },
            {
                name: 'Cost Trend',
                url: urls.accountTrend,
                svgIcon: <TrendingUpOutlined/>,
            },*/
            /*{
                name: 'Savings Recommendations',
                url: urls.tagCompliance,
                svgIcon: <EmojiObjectsOutlined/>,
            },*/
        ]
    },
    {
        name: 'Purchase Insights',
        url: urls.savingsPlan,
        svgIcon: <EmojiObjectsOutlined/>,

    },
]
const managementConfiguration = []
const staticMenuConfiguration = [
   /* {
        name: 'About',
        url: urls.about,
        svgIcon: <InfoOutlined/>,
    },*/
]

function setMenusBasedOnPermission() {
    if (admin) {
        const index = configuration.findIndex(({name}) => name === 'Insights')
        /*configuration[index]['child'].push()*/
        if (superAdmin) {
            managementConfiguration.push({
                    name: 'Security',
                    url: urls.management,
                    svgIcon: <SecurityOutlined/>,
                }, {
                    name: 'Network',
                    url: urls.network,
                    svgIcon: <AccountTreeOutlined/>,
                },
                {
                    name: 'Tag Compliance',
                    url: urls.tagging,
                    svgIcon: <AssignmentTurnedInOutlined/>,
                },)

            /*staticMenuConfiguration.push(
                {
                    name: 'Settings',
                    url: urls.settings,
                    svgIcon: <SettingsOutlined/>,
                },)*/
        }
    }
}

setMenusBasedOnPermission()

export const sideDrawerMenuConfiguration = configuration
export const sideDrawerManagementConfiguration = managementConfiguration
export const sideDrawerStaticMenuConfiguration = staticMenuConfiguration
export default sideDrawerMenuConfiguration