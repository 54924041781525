import React, {useState} from 'react';
import WuButton from "../../General/WuButton";
import WuDialog from "../../General/WuDialog";
import {Checkbox, FormControlLabel, FormGroup, Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";

export default function AdditionalOptions({selectedOptions, setSelectedOptions}) {
    const [open, setOpen] = useState(false);

    const handleOptionChange = (e) => {
        setSelectedOptions({...selectedOptions, [e.target.name]: e.target.checked})
    }

    return (
        <div>
            <WuButton color={'default'} onClick={() => setOpen(true)}>
                Advanced Options
            </WuButton>
            <WuDialog open={open} onChange={() => setOpen(false)} title={'Excluded Resource Types'} size={'xs'}>
                <Box m={2}>
                    <AdditionOptions value={selectedOptions} onChange={handleOptionChange}/>
                </Box>
            </WuDialog>
        </div>
    )
}

function AdditionOptions(props) {
    const {value} = {...props}
    return (
        <FormGroup>
            {
                options.map(({name, alias}) => <FormControlLabel
                    key={alias}
                    control={<Checkbox
                        onChange={(e) => props.onChange(e)}
                        checked={value[name]}
                        name={name}/>}
                    label={alias}
                />)
            }
        </FormGroup>
    )
}


const options = [
    {alias: 'Auto Scaling Groups', name: 'autoScalingGroup'},
    {alias: 'EKS', name: 'eks'},
    {alias: 'ECS', name: 'ecs'},
    {alias: 'Backup', name: 'backup'},
    {alias: 'Snapshot', name: 'snapshot'},
    {alias: 'EMR', name: 'emr'},
    {alias: 'Sagemaker', name: 'sagemaker'},
]
