import React, {useState} from 'react';
import Box from "@material-ui/core/Box";
import {Collapse, Paper} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import JSONPretty from "react-json-pretty";
import JSONPrettyMon from "react-json-pretty/dist/monikai";

export default function PolicyInformation(props) {
    const {data: {PolicyContent}} = {...props}
    const [activeTab, setActiveTab] = useState(0);
    return (
        <Box m={2}>
            <Paper>
                <Tabs
                    value={activeTab}
                    onChange={(e, newValue) => setActiveTab(newValue)}
                    indicatorColor="secondary"
                    textColor="secondary"
                >
                    <Tab label="Policy Content"/>
                    <Tab label="Roles"/>
                </Tabs>
            </Paper>
            <Box mt={2}>
                    <Collapse in={activeTab === 0}>
                        <JSONPretty
                            theme={JSONPrettyMon}
                            data={PolicyContent}
                        />
                    </Collapse>
                <Collapse in={activeTab === 1}>
                    Available Soon
                </Collapse>
            </Box>


        </Box>
    )
}