import React from 'react';
import './index.scss'
import {IconButton, Paper, Slide, Typography} from "@material-ui/core";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import propTypes from "prop-types";

export default function WuInformationPopup(props) {
    const {open, title, children, onClose} = {...props}
    return (
        <Slide in={open} timeout={600} direction={'up'}>
            <Paper className={'wu-information-popup'} elevation={3}>
                <div className='information-wrapper'>
                    <IconButton className={'close-icon'} onClick={onClose}>
                        <CloseRoundedIcon/>
                    </IconButton>
                    <Typography variant={'h6'} gutterBottom align={'left'}>
                        {title}
                    </Typography>
                    {children}
                </div>
            </Paper>
        </Slide>
    )
}


WuInformationPopup.propTypes = {
    children: propTypes.any,
    title: propTypes.oneOfType(
        [propTypes.string,
            propTypes.element]
    ),
    open: propTypes.bool.isRequired,
    onClose: propTypes.func

}