import {useEffect, useState} from "react";
import service from "../../../service/service";
import {apiErrorResponseJsonParser} from "../../../helper/generalFunctions";

export function Environment() {
    const [progress, setProgress] = useState({loading: false, error: false, ready: false})
    const [state, setState] = useState([])

    const fetchData = async () => {
        try {
            setProgress({loading: true, error: false, ready: false})
            const {data: {data}} = await service.general().getApplicationColumns({searchKey: 'Environment'})
            setState(data.map(e => e.Environment))
            setProgress({loading: false, error: false, ready: true})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setProgress({loading: false, ready: true, error: error})
        }
    }


    useEffect(() => {
        fetchData().then()
    }, [])

    return (
        <ol>
            {
                state.map(e => <li key={e}>{e}</li>)
            }
        </ol>
    )
}