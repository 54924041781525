import React from "react";
import ReactApexChart from "react-apexcharts";
import {Paper} from "@material-ui/core";


function AccountCostMonth({graph,title}){
    const accountMonthlyList = {
        series: graph,
        options: {
            chart: {
                height: 350,
                type: 'area',
                stacked:false,
                toolbar:{
                    show:false
                },
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return "$"+val;
                },
            },
            colors: ['#0074ff', '#66DA26', '#546E7A', '#E91E63', '#FF9800', '#ffd900', '#00ff7e', '#ff5f4e', '#de00ff', '#727272'],
            grid:{
                show: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: "datetime"
            },
            legend:{
                show: true,
                position:'top',
                itemMargin:{
                    horizontal:20,
                    vertical: 20
                }
            },
            yaxis: {
                labels: {
                    formatter: function (value) {
                        return "$"+value;
                    }
                }
            },
        },

    };
    return(
        <Paper style={{width: "100%", paddingRight: 20, paddingTop: 30}} variant={"outlined"}>
            <p style={{margin: 0, fontWeight: 600, fontSize: 20, paddingLeft: 17, textAlign:"center"}}>{title}</p>
            <ReactApexChart options={accountMonthlyList.options} series={accountMonthlyList.series} type={"area"} height={350} />
        </Paper>
    )
}

function ConsolidatedSpend({graph,title}){
    const accountConsolidated = {
        series:graph,
        options: {
            chart: {
                height: 350,
                type: 'area',
                toolbar:{
                    show:false
                },
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return "$"+val;
                },
            },
            colors: ['#ff5945', '#66DA26', '#546E7A', '#E91E63', '#FF9800'],
            grid:{
                show: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: "datetime"
            },
            legend:{
                show: true,
                itemMargin:{
                    horizontal:20,
                    vertical: 20
                }
            },
            yaxis: {
                labels: {
                    formatter: function (value) {
                        return "$"+value;
                    }
                }
            },
        },

    };
    return(
        <Paper style={{width: "100%", paddingRight: 20, paddingTop: 30}} variant={"outlined"}>
            <p style={{margin: 0, fontWeight: 600, fontSize: 20, paddingLeft: 17, textAlign:"center"}}>{title}</p>
            <ReactApexChart options={accountConsolidated.options} series={accountConsolidated.series} type={"area"} height={350} />
        </Paper>
    )
}

export {AccountCostMonth, ConsolidatedSpend}