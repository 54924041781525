import React, {useState} from "react";
import PageTitle from "../../General/PageTitle/PageTitle";
import UnUsedResourceFilter from "./UnUsedResourceFilter";
import UnUsedEbsAccordion from "./UnUsedEbsAccordion";
import WuStack from "../../General/WuStack";
import SummaryCard from "../SummaryCard";
import service from "../../../service/service";
import UnusedEbsDownloadReport from "./UnusedEbsDownloadReport";

export default function UnUsedEbsHome() {
    const [filter, setFilter] = useState([]);
    const refreshFunctionApiCall = service.monitoring().onDemandCronRefreshEbs
    const summaryFunctionApiCall = service.monitoring().overAllSummaryEbs

    const summaryProps = {
        summaryCardProps:{
            countCard:{
                itemKey:'numberOfEbs',
                description:'number of available EBS',
                helpText:'EBS in available state',
                nameOfItem:'EBS Summary',
                title:'# of available EBS',
            }
        },
        downloadComponent: <UnusedEbsDownloadReport/>,
        refreshApiFunction: refreshFunctionApiCall,
        summaryApiFunction: summaryFunctionApiCall,

    }

    return (
        <div style={{width: '100%'}}>
            <WuStack style={{marginTop: 20}} spacing={3} direction={'column'}>
                <PageTitle
                    title={"Wastage Monitoring"}
                    subTitle={"Unused EBS Volumes"}
                />
                <UnUsedResourceFilter
                    backButtonLink={'/monitoring/unused-resources/summary'}
                    onClick={(event) => setFilter(event)}/>
            </WuStack>
            <WuStack style={{marginTop: 20}} spacing={3}>
                <UnUsedEbsAccordion filter={filter}/>
                <SummaryCard {...summaryProps}/>
            </WuStack>
        </div>
    )
}