import {Collapse, Paper, Typography} from "@material-ui/core";
import {useQuery} from "../../../hooks";
import {TransitionGroup} from "react-transition-group";
import React, {useEffect, useState} from "react";
import {CloudFormation} from "../CloudFormationTemplate";
import {TerraformTemplate} from "../TerraformTemplate";
import WuDialog from "../../General/WuDialog";
import {useHistory} from "react-router-dom";

export default function AdvancedInformation({applicationList, finopsTags}) {
    const query = useQuery()
    const history = useHistory()
    const applicationId = query.get('whereId') - 6589

    const handleClose = () => {
        history.push('/find-resource-tags')
    }

    return (
        applicationId > -1 &&
        <WuDialog open={applicationId > -1}
                  title={'Application Information'}
                  size={'lg'}
                  onChange={handleClose}>
            <AdvancedComponent
                id={applicationId}
                finopsTags={finopsTags}
                applicationList={applicationList}/>
        </WuDialog>
    )
}


function AdvancedComponent({id, applicationList, finopsTags}) {
    const [selectedApp, setSelectedApp] = useState({})

    useEffect(() => {
        const applicationSelected = applicationList.find(e => e.OnBoardingId === id)
        const final = Object.entries(applicationSelected || {}).reduce((acc, cur) => {
            if (typeof (cur[1]) == 'object' || cur[0].includes('_') || cur[0] === 'OnBoardingId' || cur[0] === 'Active') return acc
            if(cur[0] === 'CostCode') {
                acc['CostCenter'] = cur[1]
                return acc
            }
            if(cur[0] === 'AppCode') {
                acc['ApplicationCode'] = cur[1]
                return acc
            }
            acc[cur[0]] = cur[1]
            return acc
        }, {})
        final['Environment'] = '-'
        setSelectedApp(final)
    }, [id, applicationList])

    return (
        <Paper className={'rt-pop-over-additional-info'}>
            <Paper style={{padding: 16}} variant={'outlined'} className={'rt-info-card application-data'}>
                {
                    Object.entries(selectedApp).length < 1 && 'No application selected.'
                }
                <TransitionGroup>
                    {
                        Object.entries(selectedApp).map(e =>
                            <Collapse key={e[0]} timeout={200}>
                                <ApplicationOptions value={e[1]}
                                                    property={e[0]}/>
                            </Collapse>
                        )
                    }
                </TransitionGroup>
            </Paper>

            <Paper style={{padding: 0}} elevation={0} className={'rt-info-card rt-content'}>
                <CloudFormation finopsTags={finopsTags} appData={selectedApp}/>
                <TerraformTemplate finopsTags={finopsTags} appData={selectedApp}/>
            </Paper>
        </Paper>
    )
}

function ApplicationOptions({property, value}) {
    return (
        <div className={'rt-application-option'}>
            <Typography variant={'body2'}
                        style={{marginBottom: -4}}
                        color={'textSecondary'}>
                {property}
            </Typography>
            <Typography style={{fontWeight: 500}}>
                {value?.toString()}
            </Typography>
        </div>
    )
}