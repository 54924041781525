import React, {useEffect, useState} from 'react';
import Box from "@material-ui/core/Box";
import WuAccordionWrapper from "../../General/WuAccordion/WuAccordionWrapper";
import WuAccordionSummary from "../../General/WuAccordion/WuAccordionSummary";
import {Collapse, Typography} from "@material-ui/core";
import WuStack from "../../General/WuStack";
import AccountWiseSummary from "./AccountWiseSummary";
import service from "../../../service/service";
import {apiErrorResponseJsonParser, jsonParser} from "../../../helper/generalFunctions";
import {useOrganization} from "../../../hooks/useOrganization";
import WuAlert from "../../General/WuAlert";
import WuLoading from "../../General/WuLoading";
import NoData from "../../General/WuNoData";

export default function EFSData() {
    const {organization: masterAccountId} = useOrganization()
    const [apiProgress, setApiProgress] = useState({ready: false, loading: false, error: ''})
    const [responseData, setResponseData] = useState([])

    const fetchData = async () => {
        try {
            if (masterAccountId !== 'no org') {
                setApiProgress({ready: false, loading: true, error: ''})
                const params = {master_account_id: masterAccountId}
                const {data: {data}} = await service.monitoring().getOrganizationWiseSummaryEfs(params)
                setResponseData(data)
                setApiProgress({ready: true, loading: false, error: ''})
            }
        } catch (e) {
            const errorObj = apiErrorResponseJsonParser(e)
            setApiProgress({ready: false, loading: false, error: errorObj})
        }
    }

    useEffect(() => {
        fetchData().then()
    }, [masterAccountId])

    return (
        <Box mt={2} width={'100%'}>
            <WuAlert error={Boolean(apiProgress.error)} successMessage={''} errorMessage={apiProgress.error}
                     success={false}/>
            {
                apiProgress.ready && responseData.map((e) => <Accordion accountData={e} key={e.accountId}/>)
            }
            {
                (responseData.length < 1 && apiProgress.ready) && <div className={'no-data'}><NoData/></div>
            }
            <Collapse in={apiProgress.loading}>
                <WuLoading message={'Please wait.. subnets loading...'} open={apiProgress.loading}/>
            </Collapse>
        </Box>
    )
}

function Accordion({accountData}) {
    const {accountId, accountAlias, totalNumberOfEfs} = {...accountData}
    const [expanded, setExpanded] = useState(false)

    const handleAccordionChange = (e) => {
        setExpanded(current => !current)
    }

    return (
        <WuAccordionWrapper expanded={expanded} onChange={handleAccordionChange}>
            <WuAccordionSummary>
                <WuStack alignItems={'center'}>
                    <Box style={{flexBasis: '50%'}}>
                        <Typography>
                            {`${accountId}(${accountAlias})`}
                        </Typography>
                    </Box>
                    <Typography>
                        # of EFS-{totalNumberOfEfs}
                    </Typography>
                </WuStack>
            </WuAccordionSummary>
            <AccountWiseSummary accountId={accountId} expanded={expanded}/>
        </WuAccordionWrapper>
    )
}
