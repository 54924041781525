import {Fade, Paper, Typography} from "@material-ui/core";
import PropTypes from 'prop-types';
import Box from "@material-ui/core/Box";
import React from "react";
import {ProgressCircleCustomSize} from "../ProgressCircle/ProgressCircle";
import {Skeleton} from "@material-ui/lab";
import {HelpOutlineOutlined} from "@material-ui/icons";
import WuToolTip from "./WuToolTip";

export default function WuSummaryCard(props) {
    const {
        title,
        value,
        description,
        loading = false,
        helpText,
        borderBottom,
        borderBottomColor,
        disabled = false,
        width = null
    } = {...props}

    const borderClass = borderBottom ? {
        borderBottom: `4px solid ${borderBottomColor}`,
    } : {}

    const style = {
        backgroundColor: "var(--secondary-background)",
        position: 'relative',
        width: width ? width : 'inherit'
    }

    const disabledOverlay = {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: '#00000038'
    }

    return (
        <Paper variant={"outlined"}
               style={{...style, ...borderClass}}>
            {disabled && <div style={disabledOverlay}/>}
            <Box m={2} position={'relative'}>
                <Typography gutterBottom style={{marginLeft: 4}} color={disabled ? 'textSecondary' : 'textPrimary'}>
                    {title}
                </Typography>
                <Typography variant={"h4"} style={{marginLeft: 2}} component={'div'}
                            color={disabled ? 'textSecondary' : 'textPrimary'}>
                    {loading ? <Skeleton height={40}/> : value}
                </Typography>
                <Typography color={"textSecondary"} style={{marginLeft: 4, marginTop: 4}} variant={"subtitle2"}>
                    {description}
                </Typography>
                {
                    loading &&
                    <Box position={'absolute'} top={0} right={0}>
                        <ProgressCircleCustomSize size={16}/>
                    </Box>
                }
                {
                    helpText &&
                    <Box position={'absolute'} top={0} right={0} style={{color: 'var(--secondary-highlight)'}}>
                        <WuToolTip title={helpText}>
                            <HelpOutlineOutlined size={16}/>
                        </WuToolTip>
                    </Box>
                }
            </Box>
        </Paper>
    )
}

WuSummaryCard.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node
    ]).isRequired,
    description: PropTypes.string,
    loading: PropTypes.bool,
    borderBottom: PropTypes.bool,
    borderBottomColor: PropTypes.string,
    helpText: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string
    ]),
    disabled: PropTypes.bool
}