import React from "react";
import {GuardRailRoute} from "../../routes/MainRoute";
import './guard_rail.scss';
import PageTitle from "../General/PageTitle/PageTitle";
import CustomTab from "../General/CustomTab/CustomTab";


const tabList = [
    {
        link: "/guard-rails/tag-policy",
        name: "Tag Policy"
    }
]


export default function GuardRail() {
    return (
        <div>
            <div className="title-div-wrapper">
                <PageTitle title={"Guardrails"} subTitle={"finOps guardrails"}/>
            </div>
            <CustomTab links={tabList}/>
            <div className="title-div-wrapper">
                <GuardRailRoute/>
            </div>
        </div>
    )
}