import React, {useEffect, useState} from 'react';
import {
    Checkbox,
    ClickAwayListener,
    Fade,
    FormControlLabel, FormGroup,
    Paper,
    Popper, Typography
} from "@material-ui/core";
import {FormatAlignJustify, SettingsOutlined, ViewModuleOutlined, ViewWeekOutlined} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(2),
        marginRight: 0
    },
}));

export default function Setting(props) {
    const classes = useStyles();
    const {columnDefinition, gridApi} = {...props}
    const [anchorEl, setAnchorEl] = useState(null);
    const [columns, setColumns] = useState([]);
    const [size, setSize] = useState('');

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const open = Boolean(anchorEl);

    useEffect(() => {
        if (columnDefinition !== null) {
            setColumns(columnDefinition.getAllColumns());
        }
    }, [])

    const handleVisibilityChange = (visibility, id) => {
        columnDefinition.setColumnVisible(id, visibility)
    }

    const sizeToFit = () => {
        gridApi.sizeColumnsToFit();
    };

    const autoSizeAll = (skipHeader) => {
        let allColumnIds = [];
        columnDefinition.getAllColumns().forEach((e) => {
            allColumnIds.push(e.colId);
        });
        columnDefinition.autoSizeColumns(allColumnIds, skipHeader);
    };

    const handleGridSizeChange = (e) => {
        if (e === 'sizeToFit') sizeToFit();
        else if (e === 'autoSizeWithHeader') autoSizeAll(false);
        else if (e === 'autoSize') autoSizeAll(true);
        setSize(e);
    }

    const closePopper = () => {
        setAnchorEl(null)
    }

    return (
        <ClickAwayListener onClickAway={closePopper}>
            <div>
                <IconButton onClick={handleClick} aria-label="delete" size="medium">
                    <SettingsOutlined fontSize="inherit"/>
                </IconButton>
                <Popper open={open} anchorEl={anchorEl} transition style={{zIndex: 2000}}>
                    {({TransitionProps}) => (
                        <Fade {...TransitionProps}>
                            <Paper elevation={3}>
                                <Box display={'flex'} flexDirection={'column'}>
                                    <Box ml={2} mt={2} mb={0}>
                                        <Typography gutterBottom>Adjust Column Size</Typography>
                                        <SizeAdjust selected={size} onChange={handleGridSizeChange}/>
                                    </Box>
                                    <FormControl component="fieldset" className={classes.formControl}>
                                        <Typography>Column Visibility</Typography>
                                        <Box maxHeight={600} overflow={'auto'} paddingRight={2}>
                                            <FormGroup>
                                                {
                                                    columns.map(column => <ColumnHeaderMenu key={column.colId}
                                                                                            onClick={handleVisibilityChange}
                                                                                            header={column.userProvidedColDef.headerName}
                                                                                            label={column.colId}/>)
                                                }
                                            </FormGroup>
                                        </Box>
                                    </FormControl>
                                </Box>
                            </Paper>
                        </Fade>
                    )}
                </Popper>
            </div>
        </ClickAwayListener>
    )
}


function ColumnHeaderMenu(props) {
    const {label, Id, header} = {...props}
    const [state, setState] = useState(true);
    const handleClick = (e) => {
        setState(e.target.checked);
        props.onClick(e.target.checked, label)
    }
    return (
        <FormControlLabel
            control={<Checkbox checked={state} name={label} onClick={handleClick}/>}
            label={header ? header : label}
        />
    )
}

function SizeAdjust(props) {
    const {selected} = {...props}
    return (
        <Box mt={2}>
            <ToggleButtonGroup size="small" value={selected} exclusive onChange={(e, value) => props.onChange(value)}>
                <ToggleButton value="sizeToFit">
                    <FormatAlignJustify fontSize="small" style={{transform: 'rotate(90deg)'}}/>
                </ToggleButton>
                <ToggleButton value="autoSize ">
                    <ViewModuleOutlined fontSize="small"/>
                </ToggleButton>
                <ToggleButton value="autoSizeWithHeader">
                    <ViewWeekOutlined fontSize="small"/>
                </ToggleButton>
            </ToggleButtonGroup>
        </Box>
    )
}