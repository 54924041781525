import React, {useEffect, useState} from "react";
import {Grid, Paper} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ReportPreview from "../../../Report/ReportPreview/ReportPreview";
import service from "../../../../service/service";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";

import {AccountCostMonth, ConsolidatedSpend} from "../Graphs/Graphs";
import {costFormatter} from "../../../../helper/generalFunctions";
import DataGrid from "../../../General/DataGrid/DataGrid";
import WuSummaryCard from "../../../General/WuSummaryCard";
import moment from "moment";
import WuSpacer from "../../../General/WuSpacer";
import {postProcessData} from "./utls";


let summaryListColumn = [
    {field: "AccountID", headerName: "Account Id", resizable: true, sortable: true},
    {field: "AccountAlias", resizable: true, sortable: true},
    {field: "ApplicationName", resizable: true, sortable: true},
    {field: "ProductCode", resizable: true, sortable: true},
    {field: "Environment", resizable: true, sortable: true},
    {
        field: "Cost",
        resizable: true,
        valueFormatter: costFormatter,
        type: "numericColumn",
        filter: "agNumberColumnFilter",
        sortable: true,
        width: 120,
        suppressAutoSize: true
    },
]


export default function ReportSummary({summary}) {
    const [loading, setLoading] = useState(false);
    const [activeReport, setActiveReport] = useState("");
    const [preview, setPreview] = useState(false);
    const [previewData, setPreviewData] = useState([]);
    const [masterAccountId, setMasterAccountId] = useState(477419074820);
    const [adHoc, setAdHoc] = useState(false);
    const [errorBoundary, setErrorBoundary] = useState(false);


    if (summary.dataCategory === "account") {
        summaryListColumn = [
            {field: "CostCenter", resizable: true, sortable: true, pinned: "left", width: 150, suppressAutoSize: true},
            {field: "ApplicationOwner", resizable: true, sortable: true, pinned: "left"},
            {field: "ApplicationName", resizable: true, sortable: true, pinned: "left"},
            {field: "ProductCode", resizable: true, sortable: true, pinned: "left"},
            {field: "Environment", resizable: true, sortable: true, pinned: "left"},
        ]
    } else if (summary.dataCategory === "owner") {
        summaryListColumn = [
            {field: "AccountID", headerName: "Account Id", resizable: true, sortable: true, pinned: "left"},
            {field: "AccountAlias", resizable: true, sortable: true, pinned: "left"},
            {field: "ApplicationName", resizable: true, sortable: true, pinned: "left"},
            {
                field: "CostCenter", resizable: true, type: "numericColumn",
                filter: "agNumberColumnFilter", sortable: true, pinned: "left", width: 150, suppressAutoSize: true
            },
            {field: "ProductCode", resizable: true, sortable: true, pinned: "left"},
            {field: "Environment", resizable: true, sortable: true, pinned: "left"}
        ]
    } else if (summary.dataCategory === "name") {
        summaryListColumn = [
            {field: "AccountID", headerName: "Account Id", resizable: true, sortable: true, pinned: "left"},
            {field: "AccountAlias", resizable: true, sortable: true, pinned: "left"},
            {field: "ApplicationOwner", resizable: true, sortable: true, pinned: "left"},
            {
                field: "CostCenter", resizable: true, sortable: true, type: "numericColumn",
                filter: "agNumberColumnFilter", pinned: "left", width: 150, suppressAutoSize: true
            },
            {field: "ProductCode", resizable: true, sortable: true, pinned: "left"},
            {field: "Environment", resizable: true, sortable: true, pinned: "left"}
        ]
    } else if (summary.dataCategory === "costCode") {
        summaryListColumn = [
            {field: "AccountID", headerName: "Account Id", resizable: true, sortable: true, pinned: "left"},
            {field: "AccountAlias", resizable: true, sortable: true, pinned: "left"},
            {field: "ApplicationName", resizable: true, sortable: true, pinned: "left"},
            {field: "ApplicationOwner", resizable: true, sortable: true, pinned: "left"},
            {field: "ProductCode", resizable: true, sortable: true, pinned: "left"},
            {field: "Environment", resizable: true, sortable: true, pinned: "left"}
        ]
    } else {
        summaryListColumn = [
            {field: "AccountID", headerName: "Account Id", resizable: true, sortable: true, pinned: "left"},
            {field: "AccountAlias", resizable: true, sortable: true, pinned: "left"},
            {field: "ApplicationName", resizable: true, sortable: true, pinned: "left"},
            {field: "ApplicationOwner", resizable: true, sortable: true, pinned: "left"},
            {
                field: "CostCenter", resizable: true, sortable: true, type: "numericColumn",
                filter: "agNumberColumnFilter", pinned: "left", width: 150, suppressAutoSize: true
            },
            {field: "ProductCode", resizable: true, sortable: true, pinned: "left"},
            {field: "Environment", resizable: true, sortable: true, pinned: "left"}
        ]
    }


    const handleReportGeneration = (event) => {
        setLoading(true);
        setActiveReport(summary.reportName);
        let body = {}
        if (summary.dataCategory === "name") {
            body = {
                "query_type": event.currentTarget.id,
                "ad_hoc": adHoc.toString(),
                "account_id": masterAccountId.toString(),
                "application_name": summary.dataValue
            }
        } else if (summary.dataCategory === "owner") {
            body = {
                "query_type": event.currentTarget.id,
                "ad_hoc": adHoc.toString(),
                "account_id": masterAccountId.toString(),
                "application_owner": summary.dataValue
            }
        } else if (summary.dataCategory === "account") {
            body = {
                "query_type": event.currentTarget.id,
                "ad_hoc": adHoc.toString(),
                "account_id": masterAccountId.toString(),
                "account_param": summary.dataValue
            }
        } else if (summary.dataCategory === "costCode") {
            body = {
                "query_type": event.currentTarget.id,
                "ad_hoc": adHoc.toString(),
                "account_id": masterAccountId.toString(),
                "cost_code": summary.dataValue
            }
        }
        service.masterBillingReport().generateReport(body)
            .then(res => {
                setPreviewData(postProcessData(res.data))
                setLoading(false);
                setPreview(true);
            })
            .catch(err => {
                setErrorBoundary(true)
                setLoading(false);
            })
    }

    const handlePreviewClose = () => {
        setPreview(false);
    }


    function SummaryCards({title, value}) {
        return (
            <Paper className="summary-card" variant={"outlined"}>
                <p className="title">
                    {title}
                </p>
                <p className="content">
                    {value}
                </p>
            </Paper>
        )
    }


    function LoadingSnackBar(message) {
        return (
            <Snackbar
                message={message.message}
                open={loading}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                action={
                    <React.Fragment>
                        <CircularProgress style={{marginRight: 10}} size={24} color="primary"/>
                    </React.Fragment>
                }
            />
        )
    }


    return (
        <div className="wu-report-insight">
            {!errorBoundary ? preview &&
                <ReportPreview onClick={handlePreviewClose}
                               customTitle={true}
                               reportTitle={activeReport}
                               title={activeReport}
                               accountId={masterAccountId}
                               data={previewData}/> : ""}
            <LoadingSnackBar message={"Generating detailed report for " + summary.dataValue + "..."}/>

            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <WuSummaryCard title={summary.name} value={summary.dataValue} description={'Selected values'}/>
                </Grid>
                <Grid item xs={3}>
                    <WuSummaryCard title={`${moment().format('MMMM')} Total Spend`}
                                   value={'$' + Math.floor(Number(summary.monthlySpendAccount.currentMonthSpend)).toLocaleString() || '⚠️'}
                                   description={'Total spend of current month'}/>
                </Grid>
                <Grid item xs={3}>
                    <WuSummaryCard title={"Total Spend"}
                                   value={'$' + Math.floor(Number(summary.totalCost)).toLocaleString()}
                                   description={'YTD Spend'}/>
                </Grid>
                <Grid item xs={3}>
                    <WuSummaryCard title={'Total Number of Accounts'}
                                   value={summary.monthlySpendAccount.accountArray.length}
                                   description={'Total number of accounts'}/>
                </Grid>
            </Grid>
            <WuSpacer space={3}/>
            <div className="report-summary-summary">
                <ConsolidatedSpend graph={summary.monthlySpendAccount.accountConsolidated} title={"YTD Spend"}/>
                <AccountCostMonth graph={summary.monthlySpendAccount.graph}
                                  title={"YTD Spend Across Accounts"}/>
            </div>

            <GeneratedReportSummary
                fileName={`service-cost-${summary.name}-${summary.dataValue}`}
                gridData={summary.monthlyAccountWiseSpend.row}
                title={"YTD Spend Across Accounts"}
                fixedColumn={summary.monthlyAccountWiseSpend.column}
            />

            {
                summary.dataCategory !== "name" &&
                <GeneratedReportSummary
                    fileName={`application-cost-${summary.name}-${summary.dataValue}`}
                    gridData={summary.applicationNameReport.row}
                    title={"Application Cost"}
                    fixedColumn={summary.applicationNameReport.column}
                />
            }

            <GeneratedReportSummary
                fileName={`service-cost-${summary.name}-${summary.dataValue}`}
                gridData={summary.serviceSpend.row}
                title={"Service Cost"}
                fixedColumn={summary.serviceSpend.column}
            />

            {
                summary.dataCategory !== "owner" &&
                summary.dataCategory !== "costCode" &&
                <GeneratedReportSummary
                    fileName={`environment-cost-${summary.name}-${summary.dataValue}`}
                    gridData={summary.accountService.row}
                    title={"Environment Cost"}
                    fixedColumn={summary.accountService.column}
                />
            }

            <GeneratedReportSummary
                fileName={`spend-summary-${summary.name}-${summary.dataValue}`}
                gridData={summary.summaryView.row}
                title={"Spend Summary"}
                fixedColumn={summaryListColumn.concat(summary.summaryView.column)}
            />

            <div className="detail-report">
                <Button style={{width: 150, height: 36}} id={summary.keyId}
                        variant="contained" color="primary"
                        onClick={handleReportGeneration}>
                    View Report
                </Button>
            </div>
        </div>
    )
}

/**
 *
 * @param {object} props - input props
 * @param {string} props.title - title of the table
 * @param {array} props.gridData - array of data to be filled in grid
 * @param {string} props.fileName - file name for export option
 * @param {array} props.fixedColumn - fixed title column
 * @return {JSX.Element} - returns JSX element
 * @constructor
 */
function GeneratedReportSummary(props) {
    const {title, gridData, fileName, fixedColumn} = {...props}
    const [height, setHeight] = useState("350px");
    useEffect(() => {
        if (gridData.length < 8) {
            setHeight(`${gridData.length * 65}px`)
        }
        if (gridData.length === 1) {
            setHeight("107px");
        }

    }, [])

    return (
        <div className="report-summary-table-wrapper">
            <p className="table-heading">
                {title}
            </p>
            <div className="onboarding-grid-wrapper">
                <DataGrid
                    fileName={fileName}
                    fixedColumn={fixedColumn}
                    gridStyle={{height: height}}
                    gridData={gridData}
                    gridClass="onboarding-grid"/>
            </div>
        </div>
    )
}