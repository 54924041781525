import React, {useEffect, useState} from "react";
import ReactApexChart from "react-apexcharts";

export default function MostSpendAccountGraph({responseGraphData, title}) {
    const [ready, setReady] = useState(false);
    const [graphData, setGraphData] = useState({});
    let state = {
        series: [],
        options: {
            labels: [],
            plotOptions: {
                pie: {
                    expandOnClick: true,
                    donut: {
                        size: '60%'
                    }
                }
            },
            chart: {
                fontFamily: 'Roboto',
                toolbar: {
                    show: false
                }
            },
            dataLabels: {
                enabled: true,
                offsetY: 10,
                textAnchor: 'middle',
                style: {
                    fontSize: '11px',
                    fontWeight: 'medium',
                    colors: ['#000']
                },
                dropShadow: {
                    enabled: false
                }
            },
            background: {
                enabled: false,
                foreColor: '#000',
            },
            tooltip: {
                enabledOnSeries: false,
                y: {
                    formatter: function (val, opt) {
                        return "- $" + val
                    },
                },
            },
            legend: {
                position: 'left',
                offsetY: 8,
                offsetX: -10,
                markers: {
                    offsetX: -8,
                    offsetY: 2
                },
                itemMargin: {
                    horizontal: 8,
                    vertical: 8
                },
                tooltip: {
                    enabled: false
                },
                onItemHover: {
                    highlightDataSeries: true
                },
            },
        },
    };

    useEffect(() => {
        setReady(false);
        state.series = responseGraphData.graphData.yAxis;
        state.options.labels = responseGraphData.graphData.xAxis;
        setGraphData(state);
        setReady(true);
    }, [])

    return (
        <>
            {
                ready &&
                <ReactApexChart options={graphData.options} series={graphData.series} type="donut" height={250}/>
            }
        </>
    )
}