import React from 'react';
import Alert from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Snackbar from "@material-ui/core/Snackbar";
import Fade from "@material-ui/core/Fade";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles((theme) => ({
    buttonProgress: {
        color: "#000",
        position: 'absolute',
        marginTop: 13,
        marginLeft: -73,
    },

    buttonProgress2: {
        color: "#000",
    },
}));


function PaperProgress({children}) {
    return (
        <Fade in={true} timeout={600}>
            <div className="spinner-wrapper">
                <CircularProgress size={15} color="secondary"/>
                <span>
                    Please wait. We're working on it...
                </span>
                {children}
            </div>
        </Fade>
    )
}

function ProgressCircleCustomSize(props) {
    const classes = useStyles();

    return (
        <Fade in={true} timeout={600}>
            <CircularProgress size={props.size} className={classes.buttonProgress2}/>
        </Fade>
    )
}


function ProgressCircle(props) {
    const classes = useStyles();

    return (
        <Fade in={true} timeout={600}>
            <CircularProgress size={24} className={classes.buttonProgress}/>
        </Fade>
    )
}

function SnackbarProgress(props) {
    return (
        <Fade in={true} timeout={600}>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={true}
                message="Please wait. We're working on it"
                action={
                    <React.Fragment>
                        <CircularProgress size={20} style={{marginRight: 5}} color="primary"/>
                    </React.Fragment>
                }
            />
        </Fade>
    )
}

function SnackbarCustomProgress(props) {
    return (
        <Fade in={true} timeout={600}>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={true}
                message={props.message}
                action={
                    <React.Fragment>
                        <CircularProgress size={20} style={{marginRight: 5}} color="primary"/>
                    </React.Fragment>
                }
            />
        </Fade>
    )
}

function CircularProgressWithLabel(props) {
    const {size = 16, value = 0} = {...props}
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress size={size} variant="determinate" {...props} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                    value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
    size: PropTypes.number.isRequired,
};

export {
    PaperProgress,
    ProgressCircle,
    SnackbarProgress,
    SnackbarCustomProgress,
    ProgressCircleCustomSize,
    CircularProgressWithLabel
}