import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DataGrid from "../../General/DataGrid/DataGrid";
import {green, orange} from '@material-ui/core/colors';
import service from "../../../service/service";


const useStyles = makeStyles({
    table: {
        minWidth: 650,
        
    },
});

const columns = [
    {
        field: "Region_RegionCode",
        headerName: 'Region',
        resizable: true,
        sortable: true,
        suppressAutoSize: true,
    },
    {
        field: "Identifier",
        headerName: 'Identifier',
        resizable: true,
        sortable: true,
        suppressAutoSize: true,
    },
    {
        field: "ServiceName",
        headerName: 'Service Name',
        resizable: true,
        sortable: true,
        suppressAutoSize: true,
    },
    {
        field: "ServiceType",
        headerName: 'Service Type',
        resizable: true,
        sortable: true,
        suppressAutoSize: true,
    }]
export default function TagComplianceTable(params) {
    const classes = useStyles();
    const [statusColor, setStatusColor] = useState(orange[400]);
    const [loadStatus, setLoadStatus] = useState(0);
    const [tableDataValues, setTableDataValues] = useState([]);
    
    const [error, setError] = useState({
        error: false,
        errorMessage: undefined
    })
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(false)

    useEffect(() => {
        setLoading(true)

        service.monitoring().getTagComplianceTableData(params)
            .then((ref) => {
               setTableDataValues(ref.data.account)
               setProgress(true);
               setLoading(false);
               
            })
            .catch((err) => {
                console.log(err)
                setProgress(true);
                setError({
                    error: true,
                    errorMessage: err.message
                })
                })
    },[])
    return(
        <div style={{width:'100%'}}>
            {
                !loading ? 
                    
                    <DataGrid
                        fixedColumn={columns}
                        fileName={`tag_compliance`}
                        gridStyle={{height: "calc(100vh - 350px)"}}
                        gridData={tableDataValues}/>
                    : "loading..."
            }
            {
                error.error &&
                `something went wrong. Error message: ${error.errorMessage}`
            }
        </div>
    )
}


