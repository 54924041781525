import React, {useEffect, useState} from 'react';
import './report_preview.scss';
import {makeStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import {Button} from "@material-ui/core";
import DataGrid from "../../General/DataGrid/DataGrid"
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FileDownload from 'js-file-download';
import Insights from "../Insights/Insights";
import service from "../../../service/service";
import {AlertWu} from "../../AlertWu/AlertWu";
import {reportTitle} from "./reportTitles";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export default function ReportPreview(props) {
    const classes = useStyles();
    const [previewData, setPreviewData] = useState([]);
    const [objectInfo, setObjectInfo] = useState([]);
    const [ready, setReady] = useState(false);
    const [title, setTitle] = useState("preparing...");
    const [select, setSelect] = useState("Amazon Elastic Compute Cloud - Compute");
    const [loading, setLoading] = useState(false);
    const [selectShow, setSelectShow] = useState(false);
    const [masterAccount, setMasterAccount] = useState();
    const [errorBoundary, setErrorBoundary] = useState(false);
    const [error, setError] = useState("");


    useEffect(() => {
        setErrorBoundary(false);
    }, [])

    useEffect(() => {
        setMasterAccount(props.accountId);
        try {
            if (props.customTitle === true) {
                setTitle(props.reportTitle)
            }

            if (props.data.report.length <= 0 && (props.title !== "RICoverage")) {
                setTitle("No data to display");
            } else {
                if (props.title !== "RICoverage") {
                    setLoading(false);
                    setPreviewData(props.data.report);
                    setObjectInfo(props.data.storage);
                    setReady(true);
                    if (!props.customTitle) {
                        setTitle(reportTitle(props.title))
                    }
                } else {
                    setSelectShow(true);
                    setLoading(true)
                    setTitle("RI Coverage Report");
                    const body = {
                        "button": "RICoverage",
                        "service": select.toString(),
                        "account_id": props.accountId.toString()
                    }
                    service.costExplorerReport().generateCostExplorerReport(body)
                        .then(res => {
                            setLoading(false)
                            if (!res.data.errors) {
                                setPreviewData(res.data.report)
                                setObjectInfo(res.data.storage);
                                let values = [];
                                let innerValues = [];
                                setReady(true);
                            } else {
                                setErrorBoundary(true);
                                setError("Error in service layer");
                            }
                        })
                        .catch(err => {
                            setLoading(false);
                            setError(err.Message);
                        })
                }
            }
        } catch (e) {
            setErrorBoundary(true);
            setError(e.Message);
        }
    }, [props.data.report, select]);


    function downloadReport() {
        const download_param = {
            "bucket_name": props.data.storage.Bucket,
            "key_name": props.data.storage.Key,
            "downloadable": "true",
            "data_type": "csv",
            "file_name": "TagReport"
        }

        const fileNameArray = props.data.storage.Key.split('/');
        const fileName = fileNameArray[fileNameArray.length - 1];

        service.general().dataParser(download_param)
            .then((ref) => {
                FileDownload(ref.data, fileName);
                setReady(true);
            })
            .catch((err) => {
                alert("Oops! something went wrong.")
            })
    }

    const handleClose = (event) => {
        props.onClick(event);
    }

    // account select arrow function
    const handleSelectChange = (event) => {
        setSelect(event.target.value);
    }

    //const path = objectInfo.Bucket + "/" + objectInfo.Key

    return (
        <div className="preview-wrapper">
            <div className="report-preview-title">
                <div className="preview-title">
                    <div className="title-subheadings">
                        <h2 style={{margin: 0}}> {title} </h2>
                        <Button
                            variant="contained"
                            disableElevation
                            onClick={downloadReport}
                            color="secondary">
                            Download
                        </Button>
                        {
                            selectShow &&
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="demo-simple-select-outlined-label">Service</InputLabel>
                                <Select
                                    style={{minWidth: 250}}
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={select}
                                    onChange={handleSelectChange}
                                    margin="dense"
                                    label="Service"
                                >
                                    <MenuItem value={"Amazon Elastic Compute Cloud - Compute"}>Amazon Elastic Compute
                                        Cloud - Compute </MenuItem>
                                    <MenuItem value={"Amazon Relational Database Service"}>Amazon Relational Database
                                        Service </MenuItem>
                                    <MenuItem value={"Amazon ElastiCache"}>Amazon Elastic Cache </MenuItem>
                                    <MenuItem value={"Amazon Redshift"}>Amazon Redshift</MenuItem>
                                    <MenuItem value={"Amazon Elasticsearch Service"}>Amazon Elasticsearch
                                        Service</MenuItem>
                                </Select>
                            </FormControl>
                        }
                    </div>
                    <Insights Data={props.data} title={props.title}/>
                </div>
                <IconButton
                    style={{marginLeft: 2, padding: 0, width: 34, height: 34}}
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={handleClose}
                >
                    <CloseIcon/>
                </IconButton>

            </div>
            <div className="report-preview-view">
                {
                    !errorBoundary ? ready &&
                        <div style={{width: '100%'}}>
                            <div className="onboarding-grid-wrapper">
                                <DataGrid
                                    fileName={`preview-download`}
                                    gridStyle ={{height: "calc(100vh - 210px)"}}
                                    gridData={previewData}/>
                            </div>

                        </div> : <div style={{paddingTop: 20}}>
                        <AlertWu severity="error" message={"Something went wrong... Error type: " + error}/>
                    </div>
                }
            </div>
        </div>
    )
}