import React, {useEffect, useState} from "react";
import {Checkbox, Chip, Box} from "@material-ui/core";
import service from "../../../service/service";
import {Autocomplete, createFilterOptions} from "@material-ui/lab";
import {CheckBoxOutlineBlank, CheckBoxOutlined, CloseRounded} from "@material-ui/icons";
import {WuTextField} from "../../General";

export function ResourceTypeSearchWidget(props) {
    const {onChange, value} = {...props}
    const [resourceList, setResourceList] = useState([]);

    const fetchData = async () => {
        try {
            const {data: {data}} = await service.automations().autoTaggingGetResourceList()
            setResourceList(data)
        } catch (e) {
            setResourceList([])
        }
    }

    useEffect(() => {
        fetchData().then()
    }, [])

    return (

        <MultiSelectDropDown
            options={resourceList}
            value={value}
            onChange={onChange}
        />
    )
}


function MultiSelectDropDown({options, onChange, value}) {
    const icon = <CheckBoxOutlineBlank fontSize="small"/>;
    const checkedIcon = <CheckBoxOutlined fontSize="small"/>;

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        limit: 100,
    });

    const handleOnDelete = (deleteValue) => {
        onChange(value.filter(e => e !== deleteValue))
    }

    return (
        <div>
            <Autocomplete
                multiple
                options={options}
                value={value}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                size={'small'}
                onChange={(_, newValue) => onChange(newValue)}
                freeSolo={true}
                filterOptions={filterOptions}
                renderTags={() => null}
                renderOption={(option, {selected}) => (
                    <React.Fragment>
                        <Checkbox
                            icon={icon}
                            size={'small'}
                            checkedIcon={checkedIcon}
                            style={{marginRight: 8}}
                            checked={selected}
                        />
                        {option}
                    </React.Fragment>
                )}
                renderInput={(params) => (
                    <WuTextField
                        {...params}
                        placeholder="service:resourceType (Example: ec2:instance)"/>
                )}

            />
            <Box className={'at-selected-resources'} mt={value.length > 0 ? 1 : 0}>
                {
                    value.map(e => <SelectedChip key={e} label={e} onDelete={() => handleOnDelete(e)}/>)
                }
            </Box>
        </div>
    )
}

function SelectedChip({label, onDelete}) {
    return (
        <Chip label={label}
              deleteIcon={<DeleteIcon/>}
              className={'at-selected-resource'}
              onDelete={onDelete}/>
    )
}


function DeleteIcon({onClick}) {
    return (
        <div className={'at-selected-delete'} onClick={onClick}>
            <CloseRounded fontSize={'small'}/>
        </div>
    )
}