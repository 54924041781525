import React, {useEffect, useState} from 'react';
import {LogHomeCard} from "../LogHome/LogHomeCard";
import PageTitle from "../../General/PageTitle/PageTitle";
import Box from "@material-ui/core/Box"
import service from "../../../service/service";
import {useOrganization} from "../../../hooks/useOrganization";

export default function LogHome() {
    const {organization: masterAccountId} = useOrganization()
    const [loading, setLoading] = useState(false);
    const [ready, setReady] = useState(false);
    const [watchData, setUnUsedWatchData] = useState([])
    const [elbData, setUnUsedElbData] = useState([])

    const [error, setError] = useState({
        error: false,
        errorMessage: undefined
    })
    useEffect(() => {
        if (masterAccountId !== 'no org') {
            setLoading(true)
            setReady(false);
            setError({
                error: false,
                errorMessage: undefined
            })
            const params = {master_account_id: masterAccountId}
            service.monitoring().getElbHomeCount(params)
                .then((res1) => {
                    setLoading(false);
                    setReady(true);
                    setUnUsedElbData(res1.data.elb_count)
                })
                .catch((err1) => {

                    if (!err1.response.data?.code === 402) {
                        setError({
                            error: true,
                            errorMessage: err1.message
                        })
                    } else {
                        setUnUsedElbData([])
                    }
                    setLoading(false);

                })

            service.monitoring().getWatchHomeCount(params)
                .then((res1) => {
                    setLoading(false);
                    setReady(true);
                    setUnUsedWatchData(res1.data.watch_count)
                })
                .catch((err1) => {

                    if (!err1.response.data?.code === 402) {
                        setError({
                            error: true,
                            errorMessage: err1.message
                        })
                    } else {
                        setUnUsedWatchData([])
                    }
                    setLoading(false);

                })
        }
    }, [masterAccountId])

    return (

        <div>
            <Box mb={5} style={{paddingTop: '5%'}}>
                <PageTitle
                    title={"Logs Monitoring"}
                />
            </Box>
            <div className="c-r-o">
                <LogHomeCard
                    numberOfInstanceOptimized={elbData.count}
                    totalPrev={elbData.prev_price}
                    totalSavings={elbData.price}
                    title="LoadBalancer"
                    routeLink={"/monitoring/log-tracking/elb-monitoring"}
                />
                <LogHomeCard
                    numberOfInstanceOptimized={watchData.count}
                    totalPrev={watchData.prev_price}
                    totalSavings={watchData.price}
                    title="Cloud Watch Logs"
                    routeLink={"/monitoring/log-tracking/cloudwatchlog-monitoring"}
                />
            </div>
        </div>

    )
}