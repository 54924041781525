export const tableHeaders = [
    {
        field: 'Account',
        resizable: true,
        valueGetter: function (data) {
            const {data: {Account_AccountId, AccountAlias}} = {...data}
            return (`${Account_AccountId}(${AccountAlias})`)
        }
    },
    {
        field: 'Region_RegionCode',
        headerName: 'Region',
        sortable: true,
        resizable: true,
    },
    {
        field: 'AvailabilityZone',
        sortable: true,
        resizable: true,
    },
    {
        field: 'EfsId',
        sortable: true,
        resizable: true,
    },
    {
        field: 'Name',
        sortable: true,
        resizable: true,
    },
    {
        field: 'PerformanceMode',
        resizable: true,
        sortable: true
    },
    {
        field: 'ThroughputMode',
        sortable: true,
        resizable: true,
    },
    {
        field: 'ProvisionedThroughput',
        sortable: true,
        resizable: true,
    },
    {
        field: 'Size',
        headerName: 'Storage Size',
        sortable: true,
        type: "numericColumn",
        resizable: true,
        filter: "agDateColumnFilter"
    },
    {
        field: 'LifeCycleState',
        sortable: true,
        type: "numericColumn",
        resizable: true,
    },
    {
        field: 'NumberOfMountTargets',
        headerName: 'Subnet CIDR Block',
        sortable: true,
        type: "numericColumn",
        resizable: true,
        filter: "agDateColumnFilter"
    },
]