import {AdditionInfoCellRender} from "./Landing";

export const gridDefinition = [
    {
        cellRendererFramework: AdditionInfoCellRender,
        suppressAutoSize: true,
        pinned: 'left',
        width: 60,
    },
    {
        field: "ApplicationName",
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
    },
    {
        field: "BusinessGroup",
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
    },
    {
        field: "CostCode",
        headerName: "Cost Center",
        resizable: true,
        sortable: true,
        width: 120,
        suppressAutoSize: true,
        type: "numericColumn",
        filter: "agNumberColumnFilter"
    },
    {
        field: "BusinessOwner",
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
    },
    {
        field: "AppCode",
        headerName: "Application Code",
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
    },
    {
        field: "ApplicationOwner",
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
    },
    {
        field: "SupportContact",
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
    }
]