import {WuButton, WuDialog} from "../../General";
import {DataGrid} from "../../General";
import {Box, Divider, Typography} from "@material-ui/core";
import {searchGridColumnDefinition} from "../grid-defenition";


export function SearchResultPreview(props) {
    const {onSelectionChange, selectedResource, resources, gridHeader, onTag, open, onClose} = {...props}
    return (
        <WuDialog open={open}
                  title={'Search Results'}
                  fullScreen={true}
                  onChange={onClose}
                  suppressDialogAction={true}>
            <Box m={2} display={'flex'} gridGap={16} alignItems={'center'}>
                <WuButton
                    disabled={selectedResource.length === 0}
                    onClick={onTag}
                >
                    TAG SELECTED RESOURCES
                </WuButton>
                <Box>
                    <Typography>
                        <span>
                            {selectedResource.length}
                        </span> selected of {resources.length}&nbsp;
                        ({percentage(resources.length, selectedResource.length)}%)
                    </Typography>
                </Box>

            </Box>
            <Divider/>
            <DataGrid
                onSelectionChange={onSelectionChange}
                gridRowSelection={'multiple'}
                gridData={resources}
                fixedColumn={searchGridColumnDefinition(gridHeader)}
                gridStyle={{height: 'calc(100vh - 175px)'}}
            />
        </WuDialog>
    )
}


function percentage(total, current) {
    return ((current / total) * 100).toFixed(2) || 0
}

