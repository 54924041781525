import React from "react";
import CustomTab from "../General/CustomTab/CustomTab";
import PageTitle from "../General/PageTitle/PageTitle";
import {ManagementAndGovernanceRoute} from '../../routes/MainRoute';

const style = {
    freeWidth: {
        marginLeft: 40,
        marginTop: 30,
        marginBottom: 50,
        marginRight: 65
    }
}

const links = [
    {
        link: "/mg/security-groups",
        name: "Security Recommendations"
    },
    {
        link: "/mg/iam",
        name: "IAM"
    },/*
    {
        link: "/mg/ip-range",
        name: "IP Address Governance"
    }*/
]

export default function ManagementAndGovernance() {
    return (
        <div>
            <div style={style.freeWidth}>
                <PageTitle title={"Management and Governance"}/>
            </div>
            <CustomTab links={links}/>
            <div style={style.freeWidth}>
                <ManagementAndGovernanceRoute/>
            </div>
        </div>
    )
}