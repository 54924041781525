import {CpuUsage, DetailsPageCluster, DetailsPageInstance, MemoryUsage, StatusComponent} from "../GridColumComponents";
import moment from "moment";

export const columns = [
    {
        headerName: 'Cluster Instance',
        field: 'clusterInstanceArn',
        sort: true,
        cellRendererFramework: DetailsPageInstance
    },

    {
        field: 'ApplicationName',
    },
    {
        headerName: 'EC2 Instance',
        field: 'ec2Instance',
        sort: true
    },
    {
        headerName: 'Cluster',
        field: 'clusterArn',
        sort: true,
        cellRendererFramework: DetailsPageCluster
    },
    {
        headerName: 'Tasks',
        field: 'taskCount',
        autoHeight: true,
        wrapText: true,
        sort: true,
    },
    {
        headerName: 'CPU Usage',
        field: 'cpuAverage',
        sort: true,
        cellClassRules: {'cell-width-adjuster-class': 'true'},
        resizable: false,
        cellRendererFramework: CpuUsage
    },
    {
        headerName: 'Memory Usage',
        field: 'memoryAverage',
        cellClassRules: {'cell-width-adjuster-class': 'true'},
        sort: true,
        cellRendererFramework: MemoryUsage
    },
    {
        headerName: 'Registered At',
        field: 'registeredAt',
        sort: true,
        valueFormatter: function ({value}) {
            return moment(value).format('DD MMMM, YYYY')
        }
    },
    {
        headerName: 'Price',
        field: 'price',
        sort: true,
        valueFormatter: function ({value}) {
            return `$${value || 0}`
        }
    },
    {
        field: 'ApplicationCode',
    },
    {
        field: 'ApplicationOwner',
    },
    {
        field: 'CostCenter',
    },
    {
        field: 'SupportContact',
    },
]
