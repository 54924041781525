import {Paper, Typography} from "@material-ui/core";
import DataGrid from "../../General/DataGrid";
import React from "react";

/**
 *
 * @param {Object} tableData - data to be displayed
 * @param {string} title - table title name
 * @return {JSX.Element}
 * @constructor
 */
export function Table({tableData, title}) {
    let height = {height: 200};
    if (tableData?.length <= 6) {
        height = {height: tableData.length * 64.5}
    }

    return (
        <div style={{marginTop: 20, marginBottom: 20}}>
            <Typography variant={"subtitle1"} className="region-table-title">
                {title}
            </Typography>
            <Paper variant={"outlined"}>
                <DataGrid
                    fileName={"region-list"}
                    gridStyle={height}
                    gridData={tableData}
                />
            </Paper>
        </div>
    )
}