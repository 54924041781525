import {useParams} from "react-router-dom";
import {TagIssuesDetails} from "./TagIssues";
import {NoTagsDetails} from "./NoTags";
import Home from "./Home";

export default function TagComplianceV2() {
    const {source} = useParams()
    if (source === 'no-tags') return <NoTagsDetails/>
    if (source === 'partial-tag') return <TagIssuesDetails/>
    return <TagComplianceHome/>
}


function TagComplianceHome() {
    return (
        <div className="title-div-wrapper" style={{marginLeft: 0}}>
            <Home/>
        </div>
    )
}