import React, {useEffect, useState} from "react";
import service from "../../../service/service";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {Skeleton} from "@material-ui/lab";

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;
export default function Region(props) {
    const {disabled, addAllToOptions = false} = {...props}
    const [region, setRegion] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [currentInputValue, setCurrentInputValue] = useState("");
    const [ready, setReady] = useState(false);

    useEffect(() => {
        setLoaded(true);
        setReady(false);
        props.onChange({loading: loaded})
        service.general().getRegionList()
            .then((res) => {
                if (res.data.code === 200) {
                    if (addAllToOptions) {
                        res.data.data.unshift({RegionCode: 'All', RegionName: 'All'})
                    }
                    setRegion(res.data.data);
                    setLoaded(false);
                    props.onChange({loading: loaded})
                    setReady(true);
                }
            }).catch(err => {
            console.log(err)
        })
    }, [])



    const handleChange = (event) => {
        let regionList = []
        event.forEach((region) => {
            regionList.push(region.RegionCode)
        })
        props.onChange({loading: loaded, regionList: regionList})
    }

    return (
        <React.Fragment>
            {
                ready ?
                    <Autocomplete
                        multiple
                        size="small"
                        limitTags={1}
                        style={{
                            marginTop: 10,
                            marginBottom: 10,
                            minWidth: 270,
                        }}
                        disabled={disabled}
                        getOptionDisabled={({RegionCode}) => RegionCode.toUpperCase() === 'GLOBAL'}
                        options={region}
                        loading={loaded}
                        onChange={(event, value) => handleChange(value)}
                        inputValue={currentInputValue}
                        disableCloseOnSelect={true}
                        getOptionLabel={({RegionCode, RegionName}) => `${RegionName} ${RegionCode}` || ""}
                        renderOption={(region, {selected}) => (
                            <React.Fragment>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                />
                                {region.RegionCode ? region.RegionName : ""}
                            </React.Fragment>
                        )}
                        fullWidth={true}
                        renderInput={(params) => (
                            <TextField {...params} error={props.error} variant="outlined" label="Regions"
                                       onChange={(e) => setCurrentInputValue(e.target.value)}
                                       placeholder="Regions"/>
                        )}
                    /> : <Skeleton height={45} width={270}/>
            }
        </React.Fragment>
    )
}


function EnvironmentMultiSelect(props) {

    const [environment, setEnvironment] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(true);
        props.onChange({loading: loaded})
        service.general().getApplicationColumns({searchKey: 'Environment'})
            .then((res) => {
                console.log(res.data.data[0])
                if (res.data.code === 200) {
                    setEnvironment(res.data.data);
                    setLoaded(false);
                    props.onChange({loading: loaded})
                }
            }).catch(err => {
            console.log(err)
        })
    }, [])

    const handleChange = (event) => {
        let environmentTemp = []
        event.forEach((environment) => {
            environmentTemp.push(environment.Environment)
        })
        props.onChange({loading: loaded, environment: environmentTemp})
    }


    return (
        <Autocomplete
            multiple
            size="small"
            limitTags={3}
            style={{
                minWidth: 200,
            }}
            id="available-fields"
            options={environment}
            loading={loaded}
            onChange={(event, value) => handleChange(value)}
            disableCloseOnSelect
            getOptionLabel={
                (environment) =>
                    environment.Environment ? environment.Environment : "no data"
            }
            renderOption={(environment, {selected}) => (
                <React.Fragment>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={selected}
                    />
                    {environment.Environment ? environment.Environment : "no data"}
                </React.Fragment>
            )}
            fullWidth={true}
            renderInput={(params) => (
                <TextField {...params} error={props.error} margin={"dense"} variant="outlined" label="Environment"
                           placeholder="Environment"/>
            )}
        />
    )
}

export
{
    EnvironmentMultiSelect, Region
}