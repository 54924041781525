import React, {useEffect, useState} from 'react';
import ReactApexChart from "react-apexcharts";

export default function Graph(props) {
    const {graphData = []} = {...props}
    const [ready, setReady] = useState(false);
    const [graphSeriesData, setGraphSeriesData] = useState([{
        data: []
    }])

    const state = {
        series: [
            {
                data: []
            }
        ],
        options: {
            grid: {
                show: false
            },
            legend: {
                show: true,
                showForSingleSeries: true,
                customLegendItems: ['Actual', 'Expected'],
                markers: {
                    fillColors: ['#00E396', '#775DD0']
                }
            },
            colors: ['#00E396'],
            dataLabels: {
                enabled: true,
                enabledOnSeries: [1]
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 4
                }
            },
            chart: {
                height: 400,
                type: 'bar',
                stroke: {
                    width: [0, 4]
                },
                events: {
                    dataPointSelection: function (event, chartContext, config) {
                        clickItem(config)
                    }
                },
                toolbar: {
                    show: false
                },
                fontFamily: 'Century Gothic',
            },
            xaxis: {
                labels: {
                    formatter: function (value) {
                        return `$${value}`
                    }
                }
            }
        },
    };

    const clickItem = (e) => {
        const applicationName = graphSeriesData[0]["data"][e.dataPointIndex]['x']
        props.onClick(applicationName)
    }

    useEffect(() => {
        setReady(false);
        setGraphSeriesData([{
            name: 'Actual',
            data: graphData
        }]);
        setReady(true)
    }, [])

    return (
        <>
            {
                ready && <ReactApexChart options={state.options} series={graphSeriesData} type="bar" height={500}/>
            }
        </>
    )
}
