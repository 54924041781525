import Paper from "@material-ui/core/Paper";
import {Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import React from "react";
import moment from "moment";

export function UnusedCard(props) {
    const {numberOfInstanceOptimized, totalPrev, totalSavings} ={...props}
    const currentMonth = moment().format('MMMM')
    const lastMonth = moment().subtract(1, 'months').format('MMMM')

    return (
        <Paper variant={"outlined"} className="card" >
            <Typography variant={"h6"}>
                {props.title}
            </Typography>
           
            <div className="summary">
                <div className="title">Summary</div>
                <div className="s-r">
                    <p style={{flexBasis: "60%"}}>Total number of resources</p>
                    <p>: {numberOfInstanceOptimized ? numberOfInstanceOptimized : "$0.00"}</p>
                </div>
                <div className="s-r">
                    <p style={{flexBasis: "60%"}}>Total Cost for the Month {lastMonth}</p>
                    <p>: {totalPrev ? "$"+ Number(totalPrev).toFixed(2) : "$0.00"}</p>
                </div>
                <div className="s-r">
                    <p style={{flexBasis: "60%"}}>Total Cost for the Month {currentMonth}</p>
                    <p>: {totalSavings ? "$"+ Number(totalSavings).toFixed(2) : "$0.00"}</p>
                </div>
            </div>
            <Link to={props.routeLink} className="more-info">View Resources</Link>
        </Paper>
    )
}