import Box from "@material-ui/core/Box";
import {Grid, Paper} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import service from "../../../../../service/service";
import WuDialog from "../../../../General/WuDialog";
import DataGrid from "../../../../General/DataGrid";
import {PaperProgress} from "../../../../ProgressCircle/ProgressCircle";
import {costFormatter} from "../../../../../helper/generalFunctions";
import {useOrganization} from "../../../../../hooks/useOrganization";
import {accountTrendDailyService, accountTrendResource} from "../../../../../assets/finthesys/accountTrend";

export default function ServiceResourceWiseSpend(props) {
    const {open, data} = {...props}
    const {accountId, date, service: serviceName} = {...data}
    const {organization: masterAccountId} = useOrganization()
    const [gridData, setGridData] = useState([])
    const [ready, setReady] = useState(false);
    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        try {
            setReady(false)
            setLoading(true)
            const params = {
                "master_account_id": masterAccountId,
                "account_id": accountId,
                "date": date,
                "service": serviceName
            }
            const {data: {data: responseData}} = await service.accountTrend().getAccountTrendDailyService(params);
            setGridData(responseData || [])
            setReady(true)
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    const fetchDummyData = async () => {
        try {
            setLoading(true)
            setReady(false)
            const {data: responseData} = accountTrendDailyService
            setGridData(responseData || [])
            setReady(true)

        } catch (e) {

        } finally {
            setLoading(false)
        }
    }




    useEffect(() => {
        fetchDummyData().then()
    }, [])

    return (
        <WuDialog open={open}
                  title={serviceName ? serviceName : 'Unknown service'}
                  onChange={(e) => props.onChange(e)}
                  size={'lg'}>
            <Box display={'flex'} width={'100%'}>
                <Paper variant={'outlined'} className={'w-100'}>
                    {
                        ready && <DataGrid
                            fileName={`${serviceName}-${date}-${accountId}`}
                            gridStyle={{height: '600px'}}
                            gridData={gridData}
                            fixedColumn={column}
                            gridClass="onboarding-grid"/>
                    }
                    {
                        loading && <Box m={2}> <PaperProgress/></Box>
                    }
                </Paper>
            </Box>
        </WuDialog>
    )
}


const column = [
    {
        field: 'ResourceId',
        width: '300'
    },
    {
        field: 'ApplicationName'
    },
    {
        field: 'CostCenter'
    },
    {
        field: 'Environment'
    },
    {
        field: 'Service',
        headerName: 'Service'
    },
    {
        field: 'Cost',
        valueFormatter: function (params) {
            return `$${costFormatter(params)}`
        },
        type: "numericColumn",
        resizable: true,
        sortable: true,
        filter: "agNumberColumnFilter"
    },
    {
        field: 'Date'
    },
]