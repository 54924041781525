import React, {useEffect, useState} from 'react';
import Box from "@material-ui/core/Box";
import DataGrid from "../../../General/DataGrid/DataGrid";
import {Grid, Paper, Typography} from "@material-ui/core";
import {DeleteOutlined} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import WuDialog from "../../../General/WuDialog";
import PolicyInformation from "./PolicyInformation";
import {accountLengthAdjust} from "../../../../helper/generalFunctions";
import service from "../../../../service/service";
import {useOrganization} from "../../../../hooks/useOrganization";
import {AlertWu} from "../../../AlertWu/AlertWu";
import {ProgressCircleCustomSize} from "../../../ProgressCircle/ProgressCircle";
import SummaryCard from "./SummaryCard";


const data = [{
    PolicyName: 'test',
    PolicyArn: 'cccccccccccccccc',
    PolicyContent: {"test": "test", "test1": "test"}
}]

export default function PolicyWithAdminPrivilege() {
    const {organization: masterAccountId} = useOrganization()
    const [selectedPolicy, setSelectedPolicy] = useState({});
    const [policyInfoDialogOpen, setPolicyInfoDialogOpen] = useState(false);
    const [policyData, setPolicyData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ready, setReady] = useState(false);
    const [error, setError] = useState({error: false, message: undefined});

    const columns = [
        {
            field: "Account",
            resizable: true,
            sortable: true,
            suppressAutoSize: false,
            valueGetter: function ({data}) {
                return `${accountLengthAdjust(data.AccountId)} (${data.AccountAlias})`
            }
        },
        {
            field: "PolicyName",
            resizable: true,
            sortable: true,
            suppressAutoSize: false,
        },
        {
            field: "PolicyArn",
            resizable: true,
            sortable: true,
            suppressAutoSize: false,
        },
        {
            cellRenderer: "policyInformationRenderer",
            sortable: false,
            resizable: false,
            filter: false,
            width: 75,
            suppressAutoSize: false,
            cellRendererParams: {
                clicked: function (field) {
                    handlePolicyInformationOpen(field)
                }
            },
        },
    ]

    const fetchData = async () => {
        try {
            if (masterAccountId !== 'no org') {
                setLoading(true);
                setReady(false);
                setError({error: false, message: undefined})
                const param = {master_account_id: masterAccountId}
                const {data: {data}} = await service.governance().getIamPolicies(param);
                setPolicyData(data);
                setReady(true)
            }
        } catch (e) {
            setError({error: true, message: e.message})
        } finally {
            setLoading(false)
        }
    }
    const handlePolicyInformationOpen = (e) => {
        setSelectedPolicy(e);
        setPolicyInfoDialogOpen(true)
    }
    const policyCellRenderer = (props) => {
        const btnClickedHandler = (field) => {
            props.clicked(props.data)
        }
        return (
            <IconButton onClick={btnClickedHandler} aria-label="delete" size="medium">
                <InfoOutlinedIcon fontSize="inherit"/>
            </IconButton>
        )
    }
    const handleDialogClose = () => {
        setPolicyInfoDialogOpen(false);
        setSelectedPolicy({})
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={10}>
                    <WuDialog size={'md'} open={policyInfoDialogOpen} onChange={handleDialogClose}
                              title={selectedPolicy.PolicyName}>
                        <PolicyInformation data={selectedPolicy}/>
                    </WuDialog>
                    <Paper variant={'outlined'}>
                        {ready && <DataGrid
                            gridStyle={gridStyle}
                            fixedColumn={columns}
                            frameworkComponents={{
                                policyInformationRenderer: policyCellRenderer,
                            }}
                            gridData={policyData}/>}

                        {
                            loading &&
                            <Box m={1} display={'flex'} className={'gap'}>
                                <ProgressCircleCustomSize size={16}/>
                                <Typography>
                                    Loading policies...
                                </Typography>
                            </Box>
                        }
                        <Box m={1}>
                            <AlertWu alertOpen={{state: error.error}} severity={"error"} message={error.message}/>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={2}>
                    <SummaryCard/>
                </Grid>
            </Grid>
        </Box>
    )
}

const gridStyle = {
    height: 'calc(100vh - 400px)'
}