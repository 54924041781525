import React, {useState} from "react";
import PageTitle from "../../General/PageTitle/PageTitle";
import UnUsedResourceFilter from "../UnUsedEbs/UnUsedResourceFilter";
import SnapshotAccordion from "./SnapshotAccordion";
import '../../../service/service';
import service from "../../../service/service";
import UnusedSnapshotDownloadReport from "./UnusedSnapshotDownloadReport";
import WuStack from "../../General/WuStack";
import SummaryCard from "../SummaryCard";

export default function UnUsedSnapshotHome() {
    const [filter, setFilter] = useState([]);
    const refreshFunctionApiCall = service.monitoring().onDemandCronRefreshSnapshot
    const summaryFunctionApiCall = service.monitoring().overAllSummarySnapshot

    const summaryProps = {
        summaryCardProps:{
            countCard:{
                itemKey:'numberOfSnapshot',
                description:'number of snapshots',
                helpText:'Snapshot not attached with AMIs',
                nameOfItem:'Snapshot Summary',
                title:'# of Snapshots ',
            }
        },
        downloadComponent: <UnusedSnapshotDownloadReport/>,
        refreshApiFunction: refreshFunctionApiCall,
        summaryApiFunction: summaryFunctionApiCall,

    }

    return (
        <div style={{width: '100%'}}>
            <WuStack style={{marginTop: 20}} spacing={3} direction={'column'}>
                <PageTitle
                    title={"Wastage Monitoring"}
                    subTitle={" Unused Snapshot Instances"}
                />
                <UnUsedResourceFilter
                    backButtonLink={'/monitoring/unused-resources/summary'}
                    onClick={(event) => setFilter(event)}/>
            </WuStack>
            <WuStack style={{marginTop: 20}} spacing={3}>
                <SnapshotAccordion filter={filter}/>
                <SummaryCard {...summaryProps}/>
            </WuStack>
        </div>
    )
}