import React, {useEffect, useState} from "react";
import './cost_explorer.scss';
import Button from '@material-ui/core/Button';
import {makeStyles} from "@material-ui/core/styles";
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReportPreview from "../ReportPreview/ReportPreview";

import service from "../../../service/service";
import {useOrganization} from "../../../hooks/useOrganization";
import ReportGenerateCard from "../ReportGenerateCard/ReportGenerateCard";
import Box from "@material-ui/core/Box";


const useStyles = makeStyles({
    root: {
        marginBottom: 23,
        flexGrow: 0,
    },
});

export default function CostExplorer() {
    const organization = useOrganization()
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [loading, setLoading] = useState(false);
    const [activeReport, setActiveReport] = useState("");
    const [preview, setPreview] = useState(false);
    const [previewData, setPreviewData] = useState([]);
    const [masterAccountId, setMasterAccountId] = useState(organization.organization);
    const [errorBoundary, setErrorBoundary] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        setMasterAccountId(organization.organization)
    }, [organization])


    const handleClick = (event) => {
        setActiveReport(event.currentTarget.id);
        if (event.currentTarget.id !== "RICoverage") {
            const body = {
                "button": event.currentTarget.id,
                "account_id": masterAccountId.toString()
            }

            setLoading(true);
            service.costExplorerReport().generateCostExplorerReport(body)
                .then(res => {
                    setPreviewData(res.data)
                    setLoading(false);
                    setPreview(true);
                    if (res.data.errors) {
                        setErrorBoundary(true);
                        setError("service layer failure");
                    }

                })
                .catch(err => {
                    setErrorBoundary(true)
                    setError(err.Message);
                    setLoading(false);
                })
        } else {
            setPreviewData({"report": [{"test": "test"}]})
            setLoading(false);
            setPreview(true);
        }
    }


    const handlePreviewClose = () => {
        setPreview(false);
    }

    function LoadingSnackBar(message) {
        return (
            <Snackbar
                message={message.message}
                open={loading}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                action={
                    <React.Fragment>
                        <CircularProgress style={{marginRight: 10}} size={24} color="primary"/>
                    </React.Fragment>
                }
            />
        )
    }

    return (
        <div className="main_header">
            {!errorBoundary ? preview &&
                <ReportPreview onClick={handlePreviewClose} title={activeReport} accountId={masterAccountId}
                               data={previewData}/> : ""}
            <LoadingSnackBar message={"Generating " + activeReport + "..."}/>
            <Box className={loading ? "report-loading" : ""}>
                <Box display={"flex"} className={"gap"} flexWrap={'wrap'}>
                    <ReportGenerateCard subTitle={'spend'}
                                        id="DailyCostReports"
                                        title={'Daily Cost'}
                                        type={'cost'}
                                        disabled={loading}
                                        onClick={handleClick}
                                        buttonName={'Download'}/>
                    <ReportGenerateCard subTitle={'spend'}
                                        id="MonthlyCostsByLinkedAccount"
                                        title={'Monthly Cost By Linked Account'}
                                        type={'cost'}
                                        disabled={loading}
                                        onClick={handleClick}
                                        buttonName={'Download'}/>
                    <ReportGenerateCard subTitle={'service'}
                                        id="MonthlyCostsByService"
                                        title={'Monthly Cost By Service'}
                                        type={'service'}
                                        disabled={loading}
                                        onClick={handleClick}
                                        buttonName={'Download'}/>
                    <ReportGenerateCard subTitle={'savings plan'}
                                        id="CoverageReport"
                                        title={'Savings Plan Coverage'}
                                        type={'cost'}
                                        disabled={loading}
                                        onClick={handleClick}
                                        buttonName={'Download'}/>
                    <ReportGenerateCard subTitle={'ec2'}
                                        id={'MonthlyEC2RunningHoursCostsAndUsage'}
                                        title={'Monthly EC2 Running Hours Costs And Usage'}
                                        type={'service'}
                                        disabled={loading}
                                        onClick={handleClick}
                                        buttonName={'Download'}/>
                    <ReportGenerateCard subTitle={'service cost'}
                                        id={'RICoverage'}
                                        title={'RI Coverage'}
                                        type={'cost'}
                                        disabled={true}
                                        onClick={handleClick}
                                        buttonName={'Download'}/>
                </Box>
            </Box>
        </div>
    )
}