import React, {useState} from 'react';
import {accountLengthAdjust, costFormatter} from "../../../helper/generalFunctions";
import WuDialog from "../../General/WuDialog";
import DataGrid from "../../General/DataGrid/DataGrid";
import {Paper} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import FlaggedResources from "./FlaggedResources";


export default function CheckSummary({data = [], checkId, checkName, filter}) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedRecommendation, setSelectedRecommendation] = useState({});
    const [dialogTitle, setDialogTitle] = useState("unknown account...")

    const columns = [
        {
            field: "Account",
            resizable: true,
            sortable: true,
            suppressAutoSize: false,
            valueGetter: function (params) {
                return `${accountLengthAdjust(params.data.AccountId)}(${params.data.AccountAlias})`
            }
        },
        {
            field: "ResourcesProcessed",
            headerName: "No. Resource Processed",
            resizable: true,
            sortable: true,
            suppressAutoSize: true,
            type: "numericColumn",
            valueFormatter: costFormatter,
            filter: "agNumberColumnFilter"
        },
        {
            field: "ResourcesFlagged",
            headerName: "No. Resources Can Be Optimized",
            resizable: true,
            sortable: true,
            suppressAutoSize: true,
            type: "numericColumn",
            valueFormatter: costFormatter,
            filter: "agNumberColumnFilter"
        },
        /* {
             field: "EstimatedMonthlySaving",
             headerName: "Estimated Savings",
             resizable: true,
             sortable: true,
             valueFormatter: function (params) {
                 return (`$${Math.round(params.value).toLocaleString()}`)
             },
             suppressAutoSize: true,
             type: "numericColumn",
             filter: "agNumberColumnFilter"
         },
         {
             field: "EstimatedMonthlySavingPercentage",
             headerName: "Estimated Saving Percentage",
             resizable: true,
             sortable: true,
             valueFormatter: function (params) {
                 if (params.value === '-') return params.value
                 return (`${params.value.toFixed(2)}%`)
             },
             suppressAutoSize: true,
             type: "numericColumn",
             filter: "agNumberColumnFilter"
         },*/
        {
            cellRenderer: "informationCellRenderer",
            field: "Resources",
            sortable: false,
            width: 95,
            resizable: false,
            filter: false,
            suppressAutoSize: false,
            cellRendererParams: {
                clicked: function (field) {
                    setDialogOpen(true);
                    setDialogTitle(checkName + " - " + accountLengthAdjust(field.AccountId) + "(" + field.AccountAlias + ")");
                    setSelectedRecommendation({checkId: checkId, accountId: accountLengthAdjust(field.AccountId)})
                }
            },
        }
    ]

    const BtnCellRenderer = (props) => {
        const btnClickedHandler = (field) => {
            props.clicked(props.data)
        }
        return (
            <IconButton onClick={btnClickedHandler} aria-label="info" size="medium">
                <InfoOutlinedIcon fontSize="inherit"/>
            </IconButton>
        )
    }

    return (
        <div className="w-100">
            <WuDialog
                size={"xl"}
                open={dialogOpen}
                onChange={() => {
                    setDialogOpen(false)
                }}
                title={dialogTitle}
            >
                <FlaggedResources account={dialogTitle} data={selectedRecommendation} filter={filter}/>
            </WuDialog>
            <Paper>
                <DataGrid
                    fixedColumn={columns}
                    fileName={`trusted-advisor`}
                    gridStyle={{height: data.length > 5 ? "250px" : data.length === 1 ? "90px" : data.length * 65 + "px"}}
                    frameworkComponents={{
                        informationCellRenderer: BtnCellRenderer
                    }}
                    gridData={data}/>
            </Paper>
        </div>
    );
}