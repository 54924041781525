import React, {useEffect, useState} from 'react';
import Box from "@material-ui/core/Box";
import WuDialog from "../../../General/WuDialog";
import Search from "./Search";
import WuButton from "../../../General/WuButton";
import SettingsPowerIcon from '@material-ui/icons/SettingsPower';

export default function BatchPowerSchedule() {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [size, setSize] = useState('xs');
    const [dialogTitle, setDialogTitle] = useState('Weekend power schedule')

    const handleButtonChange = (e) => {
        setSize('xs')
        setDialogOpen(!dialogOpen);
    }

    const handleDialogChange = (e) => {
        setSize(e.size)
        if (e.accountId === null) {
            setDialogTitle('Power schedule')
        } else {
            setDialogTitle(`Power schedule-${e.accountId}(${e.accountAlias})`)
        }
    }

    useEffect(() => {
        setDialogTitle('Weekend power schedule');
    }, [dialogOpen])

    return (
        <Box>
            <WuButton
                onClick={handleButtonChange}
                startIcon={<SettingsPowerIcon/>}>
                ADD/Update
            </WuButton>
            {dialogOpen && <WuDialog onChange={() => setDialogOpen(false)}
                                     size={size}
                                     open={dialogOpen}
                                     title={dialogTitle}>
                <Search onChange={handleDialogChange}/>
            </WuDialog>}
        </Box>
    )
}