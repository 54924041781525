import React from 'react';
import './purchase-recommendation.scss';
import {NavLink} from "react-router-dom";
import {DateSelector, DateRangeSelector} from "../DateSelector/DateSelector";
import {PurchaseRecommendationSubRoute} from "../../../routes/MainRoute";
import CustomTab from "../../General/CustomTab/CustomTab";

const links = [
    {
        link: "/insights/purchase-recommendations/savings-plan",
        name: "Savings Plan"
    },
    {
        link: "/insights/purchase-recommendations/reservations",
        name: "Reservations"
    }
]


export default function PurchaseRecommendations() {

    return (
        <div>

            <span className="span-header main-margin">
                Purchase Insights
            </span>
            <div className="tab-header">
                <div className="header-wrapper">
                    <CustomTab links={links}/>
                    {/*<CustomTab/>*/}
                    {
/*
                        <div style={{transform: "translateY(-20px)"}}>
                            <DateSelector/>
                        </div>
*/
                    }

                </div>
            </div>

            <div>
                <PurchaseRecommendationSubRoute/>
            </div>
        </div>
    )
}