import React, {useContext, useEffect, useState} from 'react';
import Box from "@material-ui/core/Box";
import WuAccordionWrapper from "../../General/WuAccordion/WuAccordionWrapper";
import WuAccordionSummary from "../../General/WuAccordion/WuAccordionSummary";
import {CircularProgress, Collapse, IconButton, Typography} from "@material-ui/core";
import WuStack from "../../General/WuStack";
import AccountWiseSummary from "./AccountWiseSummary";
import service from "../../../service/service";
import {jsonParser} from "../../../helper/generalFunctions";
import {useOrganization} from "../../../hooks/useOrganization";
import WuAlert from "../../General/WuAlert";
import WuLoading from "../../General/WuLoading";
import {UpdateRounded} from "@material-ui/icons";
import WuToolTip from "../../General/WuToolTip";
import NoData from "../../General/WuNoData";
import WuInformationPopup from "../../General/WuInformationPopup";
import FilterContext from "./FilterContext";

export default function IpRangeDetails() {
    const {organization: masterAccountId} = useOrganization()
    const [apiProgress, setApiProgress] = useState({ready: false, loading: false, error: ''})
    const [responseData, setResponseData] = useState([])
    const {filter} = useContext(FilterContext)

    const fetchData = async () => {
        try {
            if (masterAccountId !== 'no org') {
                setApiProgress({ready: false, loading: true, error: ''})
                const params = {master_account_id: masterAccountId, filter: filter}
                const {data: {data}} = await service.governance().getSubnetOrganizationSummary(params)
                setResponseData(data)
                setApiProgress({ready: true, loading: false, error: ''})
            }
        } catch (e) {
            let errorObj
            try {
                errorObj = jsonParser(e.request.response).error
            } catch (e) {
                errorObj = e.message
            }
            setApiProgress({ready: false, loading: false, error: errorObj})
        }
    }

    useEffect(() => {
        fetchData().then()
    }, [filter, masterAccountId])

    return (
        <Box mt={2}>
            <WuAlert error={Boolean(apiProgress.error)} successMessage={''} errorMessage={apiProgress.error}
                     success={false}/>
            <ColorInformation/>
            {
                apiProgress.ready && responseData.map((e) => <Accordion accountData={e} key={e.AccountId}
                                                                        filter={filter}/>)
            }
            {
                (responseData.length < 1 && apiProgress.ready) && <div className={'no-data'}><NoData/></div>
            }
            <Collapse in={apiProgress.loading}>
                <WuLoading message={'Please wait.. subnets loading...'} open={apiProgress.loading}/>
            </Collapse>
        </Box>
    )
}

function Accordion({accountData, filter}) {
    const {
        AccountId: accountId,
        AccountAlias: accountAlias,
        CriticalSubnetCount: numberOfCriticalSubnets,
        SubnetCount: totalCount
    } = {...accountData}
    const boxStyle = {
        background: numberOfCriticalSubnets > 0 ? '#ffdbdb' : '#d1fbbf',
        padding: 4,
        paddingLeft: 16,
        paddingRight: 16,
        width: 'fit-content',
        borderRadius: 4,
        maxWidth: 200,
        minWidth: 200
    }
    const [expanded, setExpanded] = useState(false)
    const [loading, setLoading] = useState(false)
    const [externalDataRefreshRequest, setExternalDataRefreshRequest] = useState(false)


    const fetchData = async () => {
        try {
            setLoading(true)
            const {data: {data}} = await service.governance().runSubnetOnDemandCron({account_id: accountId})
            setLoading(false)
            setExternalDataRefreshRequest(false)
        } catch (e) {
            setLoading(false)
        }
    }

    const handleAccordionChange = (e) => {
        setExpanded(current => !current)
    }

    const handleRefresh = async (e) => {
        e.stopPropagation()
        setExpanded(false);
        await fetchData()
        setExternalDataRefreshRequest(true)
    }

    return (
        <WuAccordionWrapper expanded={expanded} onChange={handleAccordionChange} disabled={loading}>
            <WuAccordionSummary>
                <WuStack alignItems={'center'}>
                    <Box style={{flexBasis: '50%'}}>
                        <Typography>
                            {`${accountId}(${accountAlias})`}
                        </Typography>
                    </Box>
                    <WuStack spacing={1} alignItems={'center'} boxProps={{style: boxStyle}}>
                        <Typography variant={'body1'}>
                            {numberOfCriticalSubnets} of {totalCount} Subnet(s)
                        </Typography>
                        <Typography variant={'body2'} color={'textSecondary'}>
                            in critical
                        </Typography>
                    </WuStack>
                </WuStack>
                <UpdateAccountWiseRecommendations handleRefresh={handleRefresh}
                                                  loading={loading}
                                                  accountId={accountId}
                                                  accountAlias={accountAlias}/>
            </WuAccordionSummary>
            <AccountWiseSummary accountId={accountId}
                                expanded={expanded}
                                filter={filter}
                                externalDataRefresh={externalDataRefreshRequest}/>
        </WuAccordionWrapper>
    )
}


function UpdateAccountWiseRecommendations({handleRefresh, accountId, accountAlias, loading}) {
    return (
        <WuToolTip
            title={`Refresh subnet info for ${accountId}(${accountAlias}).Experimental, sometimes you need to refresh the browser window to see changes`}>
            <Box style={{position: 'relative'}}>
                <Box style={{position: 'relative'}}>
                    <IconButton onClick={handleRefresh} disabled={loading}>
                        <UpdateRounded/>
                    </IconButton>
                </Box>
                {loading && <CircularProgress size={32} style={{position: 'absolute', top: 6, left: 6, color: 'gray'}}
                                              thickness={4}/>}
            </Box>
        </WuToolTip>
    )
}


function ColorInformation() {
    const notAgreed = !localStorage.getItem('ipGovernanceInfo')
    const [open, setOpen] = useState(true)

    const handleClose = () => {
        setOpen(false)
        localStorage.setItem('ipGovernanceInfo', 'true')
    }
    return (
        <div>
            <WuInformationPopup open={(open && notAgreed)} title={'Table Color Guide'} onClose={handleClose}>
                <WuStack direction={'column'} spacing={2}>
                    <WuStack spacing={2} alignItems={'center'}>
                        <span className={'no-subnet-card'}/>
                        <div> VPC CIDR block with no subnet</div>
                    </WuStack>
                    <WuStack spacing={2} alignItems={'center'}>
                        <span className={'default-vpc-card'}/>
                        <div> Default VPC</div>
                    </WuStack>
                </WuStack>
            </WuInformationPopup>
        </div>
    )
}