import React, {useEffect, useRef, useState} from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import service from "../../../../service/service";
import EnvironmentAccountRelation from "./EnvironmentAccountRelation";
import {environmentMapper} from "../../../../helper/generalFunctions";
import {AlertWu} from "../../../AlertWu/AlertWu";
import Paper from "@material-ui/core/Paper";
import {Button, Chip, Collapse, Switch} from "@material-ui/core";
import {ProgressCircleCustomSize} from "../../../ProgressCircle/ProgressCircle";
import IconButton from "@material-ui/core/IconButton";
import {AddOutlined, DeleteOutlined} from "@material-ui/icons";
import Box from "@material-ui/core/Box";
import AddNewAccount from "./AddNewAccount";

export default function ApplicationData(props) {
    const coreAppData = props.appData;
    const [loading, setLoading] = useState(false);
    const [errorBoundary, setErrorBoundary] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [ready, setReady] = useState(false);
    const [accountInformation, setAccountInformation] = useState([])
    const disabled = localStorage.getItem("loginType") !== "admin";

    const [applicationData, setApplicationData] = useState({
        active: coreAppData.Active,
        applicationName: coreAppData.ApplicationName,
        appCode: coreAppData.AppCode,
        businessGroup: coreAppData.BusinessGroup,
        costCode: coreAppData.CostCode,
        supportContact: coreAppData.SupportContact,
        applicationOwner: coreAppData.ApplicationOwner,
        businessOwner: coreAppData.BusinessOwner,
        applicationArchitect: coreAppData.ApplicationArchitect,
        applicationAccounts: Array.isArray(coreAppData.ApplicationAccounts) ? coreAppData.ApplicationAccounts : [] || []
    });

    async function accountReformatting(data) {
        let tempArray = []
        try {
            await data.forEach((item) => {
                environmentMapper(item.environments)
                    .then((res) => {
                        item['Environments'] = res
                    })
                tempArray.push(item)
            })
        } catch (e) {
            console.log(e)
        }
        return tempArray
    }


    useEffect(() => {
        accountReformatting(coreAppData.AccountInformation)
            .then(res => setAccountInformation(res))
    }, [])

    const handleChange = (event) => {
        setApplicationData({...applicationData, [event.target.name]: event.target.value});
    };

    const handleSwitchChange = (event) => {
        setApplicationData({...applicationData, [event.target.name]: event.target.checked});
    }

    const handleAccountChange = (e) => {
        let tempAccountArray = [...accountInformation]
        tempAccountArray[e.idx] = e
        setAccountInformation(tempAccountArray)
    }

    function environmentProcessing(data) {
        let environments = {}
        if (data) {
            Object.keys(data).forEach((item) => {
                if (data[item] === true) {
                    environments[item.toUpperCase()] = true;
                }
            })
        }
        return environments
    }

    function applicationDataParser(accountArray) {
        let accounts = []
        let budgets = []
        accountArray.forEach((account) => {
            accounts.push(
                {
                    Account_AccountId: account.Account_AccountId,
                    Region_RegionCode: account.Region_RegionCode
                }
            )

            budgets.push({
                Account_AccountId: account.Account_AccountId,
                Region_RegionCode: account.Region_RegionCode,
                Environments: environmentProcessing(account.Environments),
                BudgetAmount: account.BudgetAmount,
                BudgetNotificationSubscribers: account.BudgetNotificationSubscribers
            })
        })

        return {accounts, budgets}
    }

    function accountChange(event, accountId, accountAlias) {
        if (event === 'delete') {
            setApplicationData(current => {
                return {
                    ...current,
                    applicationAccounts: current.applicationAccounts.filter(e => e.AccountId !== accountId)
                }
            })
        } else if (event === 'new') {
            setApplicationData(current => {
                return {
                    ...current,
                    applicationAccounts: [...current?.applicationAccounts || [], {
                        AccountId: accountId,
                        AccountAlias: accountAlias
                    }]
                }
            })
        } else {
            alert('unknown operation')
        }
    }

    const handleApplicationUpdate = () => {
        try {
            setLoading(true);
            setErrorBoundary(false);
            setReady(false);
            const accountInfo = applicationDataParser(accountInformation)
            const data = {
                OnBoardingId: coreAppData.OnBoardingId,
                Active: applicationData.active,
                AppCode: applicationData.appCode,
                ApplicationName: applicationData.applicationName,
                CostCode: applicationData.costCode,
                BusinessGroup: applicationData.businessGroup,
                ApplicationOwner: applicationData.applicationOwner,
                SupportContact: applicationData.supportContact,
                ApplicationArchitect: applicationData.applicationArchitect,
                BusinessOwner: applicationData.businessOwner,
                ApplicationAccounts: applicationData?.applicationAccounts.map(e => e.AccountId),
                Accounts: accountInfo.accounts,
                AccountInformation: accountInfo.budgets,
                WorkStreamName: applicationData.workStreamName
            }
            service.onBoarding().updateApplication(data)
                .then(ref => {
                    setLoading(false);
                    setReady(true);
                    props.onClick(true)

                })
                .catch(err => {
                    setErrorMessage(err.message);
                    setErrorBoundary(true);
                    setLoading(false);
                    setReady(false);
                })
        } catch (e) {
            console.log(e)
        }
    }


    const handleApplicationDelete = () => {

    }

    /**
     *
     * @param type {string} - type of event delete or add
     * @param event {object} - event with idx init
     * @param event.idx {number} - index of create
     */
    const accountAddDeleteHandler = (type, event) => {
        if (type === "add") {
            let tempAccountInformation = [...accountInformation]
            tempAccountInformation.push({
                Environments: {
                    DEV: false,
                    QA: false,
                    STAGE: false,
                    UAT: false,
                    PROD: false,
                }
            })
            setAccountInformation(tempAccountInformation)
        } else if (type === "delete") {
            let tempAccountInformation = [...accountInformation];
            tempAccountInformation.splice(-1, 1);
            setAccountInformation(tempAccountInformation)
        }
    }

    return (
        <div>
            <div className="d-f">
                <Typography style={{marginTop: 10, marginBottom: 10}}> Application Information </Typography>
                <div style={{width: "fit-content"}} className="d-f">
                    <Typography>{applicationData.active ? "Active" : "Inactive"}</Typography>
                    <Switch
                        checked={applicationData.active}
                        onChange={handleSwitchChange}
                        name="active"
                        inputProps={{'aria-label': 'secondary checkbox'}}
                    />
                </div>
            </div>

            <div className={"d-f"}>
                <TextField id="application_name"
                           name={"applicationName"}
                           onChange={handleChange}
                           fullWidth
                           value={applicationData.applicationName}
                           label="Application Name"
                           disabled={disabled}
                           margin="dense"
                           variant="outlined"/>
                <TextField id="app_code"
                           fullWidth
                           label="Application Code"
                           name={"appCode"}
                           onChange={handleChange}
                           disabled={disabled}
                           value={applicationData.appCode}
                           margin="dense"
                           variant="outlined"/>
            </div>
            <div className={"d-f"}>
                <TextField id="cost_code"
                           label="Cost Center"
                           name={"costCode"}
                           onChange={handleChange}
                           disabled={disabled}
                           fullWidth
                           value={applicationData.costCode}
                           margin="dense"
                           variant="outlined"/>
                <TextField id="business_group"
                           name={"businessGroup"}
                           onChange={handleChange}
                           disabled={disabled}
                           fullWidth
                           value={applicationData.businessGroup}
                           label="Business Group"
                           margin="dense"
                           variant="outlined"/>
            </div>
{/*            <div className={"d-f"} >
                <TextField id="work_stream_name"
                           style={{width: 'calc(50% - 10px)'}}
                           name={"workStreamName"}
                           onChange={handleChange}
                           disabled={disabled}
                           fullWidth
                           value={applicationData.workStreamName}
                           label="Work Stream Name"
                           margin="dense"
                           variant="outlined"/>
            </div>*/}
            <Box mt={1.5} gridGap={8} display={'flex'} flexWrap={'wrap '} alignItems={'center'}>
                {
                    Array.isArray(applicationData.applicationAccounts) ? applicationData.applicationAccounts?.map(e =>
                        <Chip label={`${e.AccountId}(${e.AccountAlias})`}
                              key={e.AccountId}
                              onDelete={() => accountChange('delete', e.AccountId)}/>) : null
                }
                <AddNewAccount onAccountChange={(e) => accountChange('new', e.AccountId, e.AccountAlias)}/>
            </Box>
            <Typography style={{marginTop: 16, marginBottom: 10}}> Contact Information </Typography>
            <div className={"d-f"}>
                <TextField type={"email"}
                           name={"supportContact"}
                           onChange={handleChange}
                           fullWidth
                           value={applicationData.supportContact}
                           disabled={disabled}
                           id="supportContact"
                           label="Support Contact" margin="dense"
                           variant="outlined"/>
                <TextField type={"email"}
                           name={"applicationOwner"}
                           value={applicationData.applicationOwner}
                           onChange={handleChange}
                           fullWidth
                           id="applicationOwner"
                           disabled={disabled}
                           label="Application Owner"
                           margin="dense"
                           variant="outlined"/>
            </div>
            <div className={"d-f"}>
                <TextField type={"email"}
                           name={"businessOwner"}
                           value={applicationData.businessOwner}
                           onChange={handleChange}
                           fullWidth
                           id="businessOwner"
                           label="Business Owner"
                           disabled={disabled}
                           margin="dense"
                           variant="outlined"/>
                <TextField type={"email"}
                           name={"applicationArchitect"}
                           value={applicationData.applicationArchitect}
                           onChange={handleChange}
                           fullWidth
                           id="applicationArchitect"
                           disabled={disabled}
                           label="Application Architect"
                           margin="dense"
                           variant="outlined"/>
            </div>
            {
                accountInformation.length >= 1 &&
                <Typography style={{marginTop: 16, marginBottom: 10}}> Budget and Environment </Typography>
            }

            {
                accountInformation.length >= 1 &&
                <div>
                    {
                        accountInformation?.map((accountEnvironment, index) => {
                            return (
                                <EnvironmentAccountRelation
                                    key={index}
                                    idx={index}
                                    onChange={(e) => handleAccountChange(e)}
                                    application={accountEnvironment}/>

                            )
                        })
                    }
                </div>
            }
            <Paper square elevation={0}>
                <Box mt={2}>
                    <div>
                        <div className="d-f">
                            <div style={{display: "flex"}}>
                                <Button disabled={loading || disabled} variant={"contained"} color={"secondary"}
                                        disableElevation
                                        onClick={() => {
                                            handleApplicationUpdate()
                                        }}>
                                    Save
                                </Button>
                                {/*                            <Box ml={2}>
                                    <Button disabled={loading || disabled} variant={"contained"}
                                            disableElevation
                                            onClick={() => {
                                                handleApplicationDelete()
                                            }}>
                                        Delete record
                                    </Button>
                                </Box>*/}

                                {
                                    loading &&
                                    <div style={{marginLeft: 20}}>
                                        <ProgressCircleCustomSize size={20}/>
                                    </div>
                                }
                            </div>
                            <div className="left-style" style={{marginRight: 20}}>
                                <IconButton
                                    onClick={() => {
                                        accountAddDeleteHandler("delete", "")
                                    }}
                                    disabled={!accountInformation.length >= 1}
                                    aria-label="delete">
                                    <DeleteOutlined/>
                                </IconButton>
                                <IconButton
                                    onClick={() => {
                                        accountAddDeleteHandler("add", "")
                                    }}
                                    aria-label="delete">
                                    <AddOutlined/>
                                </IconButton>
                            </div>
                        </div>


                        <div style={{marginTop: 20, marginBottom: 20}}>

                            <AlertWu severity={"success"}
                                     alertOpen={{state: ready}}
                                     message={`onboarding process for ${applicationData.applicationName}
                                 completed. you can close the window now.`}/>

                            <AlertWu alertOpen={{state: errorBoundary}} severity={"error"} message={errorMessage}/>

                        </div>
                    </div>
                </Box>
            </Paper>
        </div>
    )
}
