import React from "react";
import {Badge, Chip, Divider, Typography} from "@material-ui/core";
import WuStack from "../General/WuStack";
import Box from "@material-ui/core/Box";

export default function NotificationSingleItem(props) {
    const {title, description, tags, createdTime, read, onClick} = {...props}
    return (
        <>
            <div className={'notification-single-item'} onClick={onClick}>
                <WuStack>
                    <Box>
                        <Badge anchorOrigin={{
                                   vertical: 'top',
                                   horizontal: 'left',
                               }}
                               invisible={read}
                               variant={'dot'}
                               color="primary">
                            <Typography variant={'subtitle1'}>
                                {title}
                            </Typography>
                        </Badge>

                        <Typography variant={'subtitle2'}
                                    style={{marginBottom: 8, marginRight: 12}}
                                    color={'textSecondary'}
                                    gutterBottom>
                            {description}
                        </Typography>
                        <WuStack spacing={0.5} justifyContent={'space-between'}>
                            <WuStack spacing={0.5}>
                                {tags.map((tag, i) => <Chip label={tag} size={'small'} key={i}/>)}
                            </WuStack>
                            <Box width={'100px'}>
                                <Typography color={'textSecondary'} variant={'body2'}> {createdTime}</Typography>
                            </Box>
                        </WuStack>
                    </Box>
                </WuStack>
            </div>
            <Divider/>
        </>

    )
}