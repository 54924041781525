import {accountLengthAdjust} from "../../../../../helper/generalFunctions";

export const instance = [
    {
        field: "Account",
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
        valueGetter: function ({data}) {
            return (`${accountLengthAdjust(data.Account_AccountId)} (${data.AccountAlias})`)
        }
    },
    {
        field: "ApplicationName",
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
    },
    {
        field: "CostCenter",
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
    },
    {
        field: "InstanceId",
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
    },
    {
        field: "InstanceName",
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
    },
    {
        field: "CurrentInstanceType",
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
    },
    {
        field: "RecommendedInstanceType",
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
    },
    {
        field: "CurrentOnDemandPrice",
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
        valueFormatter: function ({value}) {
            return `$${value}`
        }
    },
    {
        field: "RecommendedOnDemandPrice1",
        headerName: "RecommendedOnDemandPrice",
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
        valueFormatter: function ({value}) {
            return `$${value}`
        }
    },
    {
        field: "Region_RegionCode",
        headerName: "Region",
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
    },
]