import moment from "moment";
import {costFormatter} from "../../../helper/generalFunctions";

/**
 *
 * @param data {object} -  the data to be converted into row and column with actual type added
 */
function dataGridDataProcessing(data) {
    if (data === null || !Array.isArray(data) || data.length < 1) return {row: [], column: [], error: []}
    let tempRow = []
    let tempColumn = []
    let rearrangedColumn = []
    let error = {}
    const ignoreFilterColumns = ['OLDVALUE', 'NEWVALUE', 'ACCOUNT', 'COSTCODE', 'COSTCENTER', 'PORT', 'PORTS', 'IP', 'ACCOUNTID']

    try {
        let filterParams = {
            comparator: function (filterLocalDateAtMidnight, cellValue) {
                if (cellValue == null) {
                    return 0;
                }
                let dateParts = cellValue.split('/');
                let year = Number(dateParts[2]);
                let month = Number(dateParts[1]) - 1;
                let day = Number(dateParts[0]);
                let cellDate = new Date(year, month, day);
                if (cellDate < filterLocalDateAtMidnight) {
                    return -1;
                } else if (cellDate > filterLocalDateAtMidnight) {
                    return 1;
                } else {
                    return 0;
                }
            },
            browserDatePicker: true,
        };

        // HELPER FOR DATE COMPARISON
        function monthToNum(date) {
            if (date === undefined || date === null || date.length !== 10) {
                return null;
            }

            let yearNumber = date.substring(6, 10);
            let monthNumber = date.substring(3, 5);
            let dayNumber = date.substring(0, 2);

            // 29/08/2004 => 20040829
            return yearNumber * 10000 + monthNumber * 100 + dayNumber;
        }

        function dateComparator(date1, date2) {
            let date1Number = monthToNum(date1);
            let date2Number = monthToNum(date2);

            if (date1Number === null && date2Number === null) {
                return 0;
            }
            if (date1Number === null) {
                return -1;
            }
            if (date2Number === null) {
                return 1;
            }

            return date1Number - date2Number;
        }


        Object.keys(data[0]).forEach((key) => {
            let newValue = data[0][key];
            let headerName = key;
            if (typeof data[0][key] !== "object") {
                if (!key.toUpperCase().includes("REGION")) {
                    newValue = dateParser(data[0][key]);
                    headerName = dateParser(key);
                }
                if (newValue.dateType !== true) {
                    if (data[0][key]) {
                        if (!isNaN(Number(data[0][key]))) {
                            if (!ignoreFilterColumns.includes(key.toUpperCase().replaceAll(" ", ""))) {
                                if (!key.toUpperCase().includes('ACCOUNT')) {
                                    if (headerName.dateType === true) {
                                        tempColumn.push({
                                            field: key.replaceAll(".", ""),
                                            headerName: headerName.newDate,
                                            type: "numericColumn",
                                            resizable: true,
                                            sortable: true,
                                            valueFormatter: costFormatter,
                                            filter: "agDateColumnFilter"
                                        })
                                    } else {
                                        tempColumn.push({
                                            field: key.replaceAll(".", ""),
                                            type: "numericColumn",
                                            resizable: true,
                                            sortable: true,
                                            valueFormatter: costFormatter,
                                            filter: "agNumberColumnFilter"
                                        })
                                    }
                                } else {
                                    tempColumn.push({
                                        field: key.replaceAll(".", ""),
                                        valueFormatter: function (param) {
                                            if (!Boolean(param.value)) return '-'
                                            return param.value
                                        },
                                        resizable: true,
                                        sortable: true,
                                    })
                                }
                            } else {
                                tempColumn.push({
                                    field: key.replaceAll(".", ""),
                                    valueFormatter: function (param) {
                                        if (!Boolean(param.value)) return '-'
                                        return param.value
                                    },
                                    resizable: true,
                                    sortable: true,
                                })
                            }

                        } else {
                            if (headerName.dateType === true) {
                                tempColumn.push({
                                    field: key.replaceAll(".", ""),
                                    resizable: true,
                                    headerName: headerName.newDate,
                                    suppressAutoSize: true,
                                    width: 200,
                                    sortable: true,
                                    filter: true
                                })
                            } else {
                                tempColumn.push({
                                    field: key.replaceAll(".", ""),
                                    resizable: true,
                                    suppressAutoSize: true,
                                    width: 200,
                                    sortable: true,
                                    filter: true
                                })
                            }

                        }
                    } else {
                        tempColumn.push({
                            field: key.replaceAll(".", ""),
                            headerName: headerName.newDate,
                            resizable: true,
                            sortable: true,
                            filter: "agDateColumnFilter"
                        })
                    }
                } else {
                    tempColumn.push({
                        field: key.replaceAll(".", ""),
                        filter: 'agDateColumnFilter',
                        comparator: dateComparator,
                        resizable: true,
                        filterParams: filterParams,
                        sortable: true,
                    })
                }
            }


        })

        // rearrange column order in case account id is present
        tempColumn.forEach((column, idx) => {
            try {
                if (column.field.toUpperCase() === "ACCOUNTID") {
                    rearrangedColumn.push({
                        field: column.field,
                        resizable: true,
                        sortable: true,
                        filter: "agNumberColumnFilter"
                    })
                    tempColumn.splice(idx, 1)
                } else if (column.field.toUpperCase() === "ACCOUNTALIAS") {
                    rearrangedColumn.push({
                        field: column.field,
                        resizable: true,
                        sortable: true,
                    })
                    tempColumn.splice(idx, 1)
                }
            } catch (e) {
                console.error(e)
                rearrangedColumn = tempColumn
            }

        })

        //join columns together
        rearrangedColumn = [...rearrangedColumn, ...tempColumn]

        data.forEach((dataItem) => {
            Object.keys(dataItem).forEach((key) => {
                if (dataItem[key] && dataItem[key] !== "" && dataItem[key] !== undefined && dataItem[key] !== null) {
                    let newValue = {newDate: dataItem[key]}
                    // if key includes region ignore the datetime processing otherwise the value will be converted to date
                    if (!key.toUpperCase().includes("REGION")) {
                        newValue = dateParser(dataItem[key]);
                    }
                    if (!isNaN(Number(newValue.newDate))) {
                        dataItem[key.replaceAll(".", "")] = newValue.newDate;
                    } else {
                        dataItem[key.replaceAll(".", "")] = newValue.newDate;
                    }
                } else {
                    dataItem[key.replaceAll(".", "")] = dataItem[key];
                }
            })
            tempRow.push(dataItem)
        })
    } catch (e) {
        error = e
        console.error(e)
    }
    return {row: tempRow, column: rearrangedColumn, error: error}
}

function dateParser(date) {
    // check whether the input is date or some other data
    // date must contains min 6 character
    // should include - or / or space
    let dateType = false;
    let newDate = date;
    if (date === null || date === undefined || typeof date === "object") {
        return {newDate, dateType}
    }

    date = date.toString();

    const dateRegex1 = /^\d{2}\/\d{2}\/\d{4}$/;
    const dateRegex2 = /^\d{2}-\d{2}-\d{4}$/;
    const dateRegex3 = /^\d{4}\/\d{2}\/\d{2}$/;
    const dateRegex4 = /^\d{4}-\d{2}-\d{2}$/;
    const dateRegex5Iso = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/;
    if (dateRegex1.test(date) || dateRegex2.test(date) || dateRegex3.test(date) || dateRegex4.test(date) || dateRegex5Iso.test(date)) {
        newDate = moment(newDate).format('MM/DD/YYYY')
        dateType = true
    }
    /*if ((date.includes(" ") || date.includes("/") || date.includes("-")) && moment(date).isValid() && date.length > 8) {
        newDate = moment(newDate).format('MM/DD/YYYY')
        dateType = true
    }*/
    /*if (date.length > 6) {
        if (date.includes("-") || date.includes("/") || date.includes(" ")) {
            if (regex.test(date)) {
                try {
                    newDate = new Date(date)
                    if (Object.prototype.toString.call(newDate) === "[object Date]") {
                        // it is a date
                        if (isNaN(newDate.getTime())) {
                            newDate = date;
                        } else {
                            newDate = moment(newDate).format('MM/DD/YYYY')
                            dateType = true
                        }
                    } else {
                        newDate = date;
                    }
                } catch (e) {
                    console.error(e)
                }
            }
        }
    }*/
    return {newDate, dateType};
}

function numberFormatting(data) {
    if (data == null) return '-';
    const newNumber = Number(data);
    if (isNaN(newNumber)) return '-'
    return newNumber
}

function removeEmptyToHypen(data) {
    if (data === null) return ''
    if (!data) return ''
    return data
}

export {dataGridDataProcessing}