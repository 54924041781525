import React, {useEffect, useState} from "react";
import DataGrid from "../../General/DataGrid/DataGrid";
import service from "../../../service/service";
const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const d = new Date();
let prev_month = monthNames[d.getMonth()-1];
let curr_month = monthNames[d.getMonth()]

const columns = [
    {
        field: "Account_AccountId",
        headerName: 'Account Id',
        resizable: true,
        sortable: true,
        suppressAutoSize: true,
    },
    {
        field: "AccountAlias",
        headerName: 'Account Alias',
        resizable: true,
        sortable: true,
        suppressAutoSize: true,
    }
    ,
  
    {
        field: "ApplicationName",
        headerName: 'ApplicationName',
        resizable: true,
        sortable: true,
        suppressAutoSize: true,
    },
    {
        field: "Region_RegionCode",
        headerName: 'Region',
        resizable: true,
        sortable: true,
        suppressAutoSize: true,
    },
    {
        field: "SnapshotId",
        headerName: 'Snapshot Id',
        resizable: true,
        sortable: true,
        suppressAutoSize: true,
    },
   
    {
        field: "StartTime",
        headerName: 'Start Time',
        resizable: true,
        sortable: true,
        suppressAutoSize: true,
    },
    {
        field: 'PreviousPrice',
        headerName:  "Price" + '(' + prev_month + ')',
        resizable: true,
        sortable: true,
        suppressAutoSize: true,
        valueFormatter: function (param) {
            return param ? "$ "+((parseFloat(param.value)).toFixed(2)).toLocaleString() : ""
}
    },
    {
        field: 'Price',
        headerName:  "Price" + '(' + curr_month + ')',
        resizable: true,
        sortable: true,
        suppressAutoSize: true,
        valueFormatter: function (param) {
            return param ? "$ "+((parseFloat(param.value)).toFixed(2)).toLocaleString() : ""
}
    }
]
export default function SnapshotAccountSummary({accountId, filter, expanded ,id}) {
    const [loading, setLoading] = useState(false);
    const [ready, setReady] = useState(false);
    const [accountWiseData, setAccountWiseData] = useState([]);
    const [error, setError] = useState({
        error: false,
        errorMessage: undefined
    })

    useEffect(() => {
        // trigger on account id change
        if(expanded === id){
        let param = filter
        if (!param.region){
            param = {
                region: '',
                key: '',
                value: ''
            }
        }

        setLoading(true)
        setReady(false);
        setError({
            error: false,
            errorMessage: undefined
        })
        // run the api call for loading account wise recommendation
        setAccountWiseData([])
        service.monitoring().getListUnusedSnapshot({account_id: accountId,  filter : param}) 
            .then((res) => {
                console.log(res)
                setAccountWiseData(res.data.instance) 
                setReady(true);
                setLoading(false);
            })
            .catch((err) => {
                setError({
                    error: true,
                    errorMessage: err.message
                })
            })
        }
    }, [expanded])

    return (
        <div style={{width:'100%'}}>
            {
                !loading ? 
                    ready &&
                    <DataGrid
                        fixedColumn={columns}
                        fileName={`Snapshot`}
                        gridStyle={{height: "calc(100vh - 350px)"}}
                        gridData={(accountWiseData.length > 0) ? accountWiseData:[]}/>
                    : "loading..."
            }
            {
                error.error &&
                `something went wrong. Error message: ${error.errorMessage}`
            }
        </div>
    )
}