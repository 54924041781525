import React from 'react'
import './index.scss'
import lostImg from '../../../assets/images/lost.jpg'
import {Typography} from "@material-ui/core";
import {Link} from "react-router-dom";

class ErrorBoundary extends React.Component {
    state = {hasError: false};

    static getDerivedStateFromError(error) {
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        console.log({error, errorInfo});
    }

    render() {
        if (this.state.hasError) {
            return <ErrorComponent onPageRefresh={() => this.setState({ hasError: false })}/>
        }
        return this.props.children;
    }

}


function ErrorComponent({onPageRefresh}) {
    return (
        <div className={'error-container'}>
            <div className={'error-wrapper-local'}>
                <img src={lostImg} alt={'something off'}/>
                <Typography variant={'h4'} gutterBottom> Something gone wrong!</Typography>
                <Typography variant={'h5'} gutterBottom> Hi, page not functional</Typography>
                <Typography variant={'subtitle1'}>Looks like something is off. This usually means you are trying to
                    use a feature that is under development or something that is released for testing.In any case
                    -- we're already fixing it and everything should be back to normal in
                    few hours</Typography>
                <div className={'error-quick-actions'}>
                    <Link to={'/dashboard'}>
                        Go to dashboard
                    </Link>
                    <div className={'error-page-action-button'} onClick={onPageRefresh}>
                        reload the page
                    </div>
                    <div className={'error-page-action-button'}>
                        report this incident
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ErrorBoundary