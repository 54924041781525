import React, {useState} from "react";
import WuTextField from "../../General/WuTextFeild";
import {Button, Paper} from "@material-ui/core";
import {AlertWu} from "../../AlertWu/AlertWu";
import Box from "@material-ui/core/Box";

export function AddOrganization(props) {

    const [organizationName, setOrganizationName] = useState("");
    const [organizationId, setOrganizationId] = useState("");
    const [organizationAlias, setOrganizationAlias] = useState("");
    const [masterAccount, setMasterAccount] = useState("");
    const [masterAccountAccountAlias, setMasterAccountAlias] = useState("");
    const [masterAccountName, setMasterAccountName] = useState("");
    const [comment, setComment] = useState("");

    const [fieldError, setFieldError] = useState({
        organizationName: false,
        organizationId: false,
        masterAccount: false,
        masterAccountAccountAlias: false,
    })

    const organizationCreate = () => {
        setFieldError({
            organizationName: organizationName.length < 1,
            organizationId: organizationId.length < 1,
            masterAccount: masterAccount.length < 1,
            masterAccountAccountAlias: masterAccountAccountAlias.length < 1,
        })

        if (organizationName && organizationId && masterAccount && masterAccountAccountAlias) {
            console.log(organizationName, organizationId, masterAccount)
            return true
        }
    }

    return (
        <React.Fragment>
            <div className="d-f-s" style={{marginTop: 20}}>
                <Box>
                    <WuTextField
                        value={organizationId}
                        error={fieldError.organizationId}
                        onChange={(e) => {
                            setOrganizationId(e.target.value)
                        }}
                        label="Organization Id*"
                    />
                    <WuTextField
                        value={organizationName}
                        error={fieldError.organizationName}
                        onChange={(e) => {
                            setOrganizationName(e.target.value)
                        }}
                        label="Organization Name*"
                    />
                    <WuTextField
                        value={organizationAlias}
                        onChange={(e) => {
                            setOrganizationAlias(e.target.value)
                        }}
                        label="Organization Alias"
                    />
                </Box>
                <Box>
                    <WuTextField
                        value={masterAccount}
                        error={fieldError.masterAccount}
                        onChange={(e) => {
                            setMasterAccount(e.target.value)
                        }}
                        label="Master Account Id*"
                    />
                    <WuTextField
                        value={masterAccountAccountAlias}
                        error={fieldError.masterAccountAccountAlias}
                        onChange={(e) => {
                            setMasterAccountAlias(e.target.value)
                        }}
                        label=" Master Account Alias*"
                    />
                    <WuTextField
                        value={masterAccountName}
                        onChange={(e) => {
                            setMasterAccountName(e.target.value)
                        }}
                        label="Master Account Name"
                    />
                </Box>
            </div>
            <Button style={{marginTop: 15}} onClick={organizationCreate} variant={"contained"} disableElevation
                    color={"secondary"}>
                Save
            </Button>
            <div style={{marginTop: 20}}>
                <AlertWu
                    alertOpen={{state: true}}
                    severity={"success"}
                    message={"new region added. Region "}/>
            </div>
        </React.Fragment>
    )
}