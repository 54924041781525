import React, {useState, useEffect} from "react";
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {ProgressCircleCustomSize} from "../../ProgressCircle/ProgressCircle";
import '../../../service/service';
import service from "../../../service/service";
import {AlertWu} from "../../AlertWu/AlertWu";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Typography from "@material-ui/core/Typography";
import TagReportKeyValue from "./TagReportKeyValue";
import IconButton from "@material-ui/core/IconButton";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import Tooltip from "@material-ui/core/Tooltip";
import {tagReportKey} from "../../../helper/dataParser";
import Region from "../../General/Region/Region";
import {useOrganization} from "../../../hooks/useOrganization";
import {MenuItem, Select} from "@material-ui/core";
import {jsonParser} from "../../../helper/generalFunctions";


const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: 23,
        flexGrow: 0,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const tagList = [
    {value: "ApplicationName", tagKey: "ApplicationName"},
    {value: "Environment", tagKey: "Environment"},
    {value: "SupportContact", tagKey: "SupportContact"},
    {value: "ApplicationOwner", tagKey: "ApplicationOwner"},
    {value: "CostCode", tagKey: "CostCenter"},
]
export default function TagReport() {
    const organization = useOrganization()
    const classes = useStyles();
    const [accountId, setAccountId] = useState('');
    const [masterAccountId, setMasterAccountId] = useState(organization.organization);
    const [accountList, setAccountList] = useState([]);
    const [errorBoundary, setErrorBoundary] = useState(false);
    const [errorBoundaryAccount, setErrorBoundaryAccount] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState("");
    const [downloadProgress, setDownloadProgress] = useState(false);
    const [downloadMessage, setDownloadMessage] = useState("Generating Tag Report");
    const [accountSearchString, setAccountSearchString] = useState("");
    const [region, setRegion] = useState([]);
    const [fields, setFields] = useState([{}]);
    const [reportType, setReportType] = useState(0);
    const [generateError, setGenerateError] = useState([]);

    const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
    const checkedIcon = <CheckBoxIcon fontSize="small"/>;

    useEffect(() => {
        setMasterAccountId(organization.organization)
    }, [organization])

    useEffect(() => {
        setLoaded(true);
        setErrorBoundaryAccount(false);
        const param = {master_id: masterAccountId}
        service.general().getAccountInformationByMasterId(param)
            .then((ref) => {
                let data = ref.data.account;
                data.unshift({AccountId: 'All', AccountAlias: 'All'})
                setAccountList(data);
                setLoaded(false);
            })
            .catch((err) => {
                setLoaded(false);
                setErrorBoundaryAccount(true);
                setError(err.message)
            })

    }, [masterAccountId])

    const handleAccountChange = (tempAccountIds) => {
        let accountTemp = []
        accountTemp = tempAccountIds.map((tempAccounts) => {
            return ({accountId: tempAccounts.AccountId.toString(), accountAlias: tempAccounts.AccountAlias})
        })

        if (accountTemp.length === 1) {
            if (accountTemp[0]['accountId'] === 'All') {
                accountTemp = 'All'
            }
        }
        setAccountId(accountTemp);
    }

    const handleReportGeneration = async () => {
        setGenerateError([]);

        const filter = fields.reduce((acc, curr) => {
            if (curr?.Key && curr?.Values.length > 0) acc.push(curr)
            return acc
        }, [])

        const param = {
            lambda_action: "invoke_lambda",
            master_account_id: masterAccountId.toString(),
            accounts: accountId,
            region: region.toString(),
            finops_report: reportType ? "False" : "True",
            tag_list: filter
        }

        setErrorBoundary(false);
        setDownloadProgress(true);
        setLoaded(true);
        setDownloadMessage("Generating Tag Report");
        service.resourceTagReport().tagReport(param)
            .then((ref) => {
                const download_param = {
                    bucket_name: ref.data.bucketName,
                    key_name: ref.data.file,
                    downloadable: "true",
                    data_type: "zip",
                    file_name: "TagReport"
                }

                setGenerateError(ref.data.error ? ref.data.error['permissionDeniedAccounts'] : []);
                setDownloadMessage("Report Generated. Preparing file for download");
                const fileNameArray = ref.data.file.split('/');
                const fileName = fileNameArray[fileNameArray.length - 1];

                service.general().dataParser(download_param)
                    .then((parserRef) => {
                        const url = window.URL.createObjectURL(
                            new Blob([parserRef.data])
                        );
                        const link = document.createElement("a");
                        link.href =
                            `http://10.39.2.48:443/get-file?bucket_name=${download_param.bucket_name.toString()}&key_name=${download_param.key_name.toString()}&data_type=zip&file_name=${fileName}`;
                        link.setAttribute("download", "file"); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        setDownloadMessage("Download will start soon..");
                        setDownloadProgress(false);
                        setDownloadMessage("Download will start soon..")
                        setDownloadProgress(false);
                        setLoaded(false)
                    })
                    .catch((err) => {
                        setDownloadProgress(false);
                        setErrorBoundary(true);
                        const errorMessage = JSON.parse(err.request.response).error;
                        setError(errorMessage ? errorMessage : err.message)
                        setError(err.message);
                        setLoaded(false)
                    })
            })
            .catch(err => {
                setDownloadProgress(false);
                setErrorBoundary(true);
                console.log(err.request.response)
                setError(jsonParser(err.request.response).error)
                setLoaded(false)
            })
    }


    function handleAdd() {
        const values = [...fields];
        if (values.length < 5) {
            values.push({});
        }
        setFields(values);
    }

    function handleRemove(i) {
        const values = [...fields];
        if (values.length > 1) {
            values.splice(i, 1);
        }
        setFields(values);
    }

    function handleChange(i, event) {
        const values = [...fields];
        values[i] = event;
        setFields(values);
    }


    return (
        <div className="main_header">
            <Paper style={{padding: 20}} variant={"outlined"}>
                <div className="a-i-c">
                    <Typography variant={"h6"}>
                        Resource tag report
                    </Typography>
                    {loaded && <ProgressCircleCustomSize size={16}/>}
                </div>

                <div className="tag-report-wrapper">
                    Region and Accounts
                    <div className="tag-report-filter">
                        <div style={{
                            marginTop: 10,
                            marginBottom: 6,
                            width: "100%"
                        }}>
                            <Region onChange={(e) => setRegion(e.regionList)}/>
                        </div>


                        <Autocomplete
                            multiple
                            size="small"
                            limitTags={2}
                            style={{
                                marginTop: 20,
                                marginBottom: 6,
                                minWidth: 200
                            }}
                            inputValue={accountSearchString}
                            options={accountList}
                            loading={loaded}
                            onChange={(event, value) => handleAccountChange(value)}
                            disableCloseOnSelect={true}
                            getOptionLabel={
                                (account) =>
                                    account.AccountId ? account.AccountAlias + " (" + account.AccountId + ")" : ""
                            }
                            renderOption={(option, {selected}) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{marginRight: 8}}
                                        checked={selected}
                                    />
                                    {option.AccountId ? option.AccountAlias + " (" + option.AccountId + ")" : ""}
                                </React.Fragment>
                            )}
                            fullWidth={true}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="Accounts"
                                           onChange={(e) => setAccountSearchString(e.target.value)}
                                           placeholder="Accounts"/>
                            )}
                        />
                    </div>
                </div>
                <div style={{marginTop: 0}} className="tag-report-wrapper">
                    Tags - Optional

                    {fields.map((field, idx) => {
                            return (
                                <div key={idx} style={{paddingTop: 20, maxWidth: 815}} className="a-i-c">
                                    <TagReportKeyValue
                                        tagList={tagList}
                                        idx={idx}
                                        onChange={e => handleChange(idx, e)}
                                        onClick={() => handleRemove(idx)}
                                    />
                                    <Tooltip title="New tag value pair" placement="right">
                                        <IconButton
                                            style={{marginLeft: 2, padding: "10px 10px"}}
                                            edge="start"
                                            color="inherit"
                                            aria-label="menu"
                                            onClick={handleAdd}
                                        >
                                            <AddRoundedIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            );
                        }
                    )}
                </div>
                <div className="d-flex j-c-c" style={{marginTop: 30}}>
                    <Select
                        value={reportType}
                        variant={"outlined"}
                        margin={"dense"}
                        style={{margin: 0, width: 200}}
                        onChange={(e) => setReportType(e.target.value)}
                        displayEmpty
                        className={classes.selectEmpty}>
                        <MenuItem value={0}>Finops Tags</MenuItem>
                        <MenuItem value={1}>All Tags</MenuItem>
                    </Select>
                    <Button style={{width: 120, height: 35}} disableElevation id={'tag'}
                            onClick={handleReportGeneration}
                            disabled={!(region && accountId)}
                            variant="contained" color="primary">
                        Download
                    </Button>
                </div>
            </Paper>

            {
                errorBoundary &&
                <ErrorAlert message={"Something went wrong...Error type: " + error}/>
            }

            {
                generateError.length > 0 &&
                generateError.map(({AccountId, AccountAlias}) => {
                    return (
                        <ErrorAlert key={AccountId} title={"No Permission"}
                                    message={`Permission Denied for Account: ${AccountId} (${AccountAlias})`}/>
                    )
                })
            }
        </div>
    )
}

function ErrorAlert({message, title}) {
    return (
        <div style={{paddingTop: 10}}>
            <AlertWu severity="error"
                     title={title}
                     message={message}/>
        </div>
    )
}