import React from 'react';
import SideMainMenu from "./SideMainMenu";
import {sideDrawerStaticMenuConfiguration} from "../../../constants/sideDrawerMenuConfiguration";
import {Divider} from "@material-ui/core";

export default function StaticBottomMenu({drawerExpand}) {
    return (
        <div className={'bottom-menu'}>
            <Divider/>
            <ul style={{marginTop: 8}}>
                {
                    sideDrawerStaticMenuConfiguration.map((menu, idx) => <SideMainMenu
                        key={idx}
                        drawerExpanded={drawerExpand}
                        {...menu}
                    />)
                }
            </ul>
        </div>
    )
}