import React from "react";
import WuStack from "../../General/WuStack";
import WuSummaryCard from "../../General/WuSummaryCard";
import Filter from "../Filter";
import {Paper, Typography} from "@material-ui/core";
import {TrendingDownOutlined, TrendingFlatOutlined, TrendingUpOutlined} from "@material-ui/icons";

export default function SpendSummary({spendInsight}) {
    const {loading, today, yesterday} = {...spendInsight}
    return (
        <Paper variant={'outlined'} style={{padding: 8, width: 310}}>
            <WuStack spacing={2} boxProps={{width: '100%'}} direction={'column'}>
                <WuSummaryCard
                    width={'100%'}
                    loading={loading}
                    title={"Today's Spend"}
                    helpText={'Cost data is not real time, could be slightly higher than displayed here. The refresh strategy for cost data is 1 hour. '}
                    value={<SpendValueAndPercentageOfChange {...today}/>}
                    description={'Total spend today from account(s)'}
                />
                <WuSummaryCard
                    width={'100%'}
                    loading={loading}
                    title={"Yesterday's Spend"}
                    value={<SpendValueAndPercentageOfChange {...yesterday}/>}
                    description={'Total spend today from account(s)'}
                />
                <Filter />
            </WuStack>
        </Paper>
    )
}


function SpendValueAndPercentageOfChange({spend, percentageChange}) {
    return (
        <WuStack alignItems={'center'} spacing={1} justifyContent={'space-between'}>
            {`$${Math.round(spend).toLocaleString()}`}
            <WuStack alignItems={'center'} spacing={0.5}>
                {
                    percentageChange > 0 && <TrendingUpOutlined style={{color: 'red'}}/>
                }
                {
                    percentageChange === 0 && <TrendingFlatOutlined style={{color: 'green'}}/>
                }
                {
                    percentageChange < 0 && <TrendingDownOutlined style={{color: 'green'}}/>
                }
                <Typography color={'textSecondary'} variant={'caption'}>
                    {percentageChange > 100 && '+100%'}
                    {percentageChange < -1000 && '-100%'}
                    {(percentageChange > -100 && percentageChange < 100) && percentageChange + '%'}
                </Typography>
            </WuStack>
        </WuStack>
    )
}