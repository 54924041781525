import {Divider, Paper, Typography} from "@material-ui/core";
import './index.scss'
import {Link} from "react-router-dom";
import SummaryRadialGraph from "./SummaryRadialGraph";

export default function Summary({summary, loading}) {
    const {totalResources = 0, resourcesWithNoTags = 0, resourcesWithPartialTags = 0} = {...summary}

    const calculatePercentage = (calculateAgainst = 0) => {
        return (100 / Number(totalResources)) * Number(calculateAgainst)
    }

    return (
        <div className={'tc-summary-container'}>
            <Typography variant={'h6'}
                        style={{fontWeight: 600}}
                        color={'textSecondary'}>
                Tag Compliance Dashboard
            </Typography>
            <Typography style={{marginBottom: 16}}> Overall tag compliance status </Typography>
            <div className={'tc-summary-wrapper'}>
                <div className={'tc-summary-card'}>
                    <div className={'tc-summary-card-content'}>
                        <Typography variant={'h5'} className={'tc-bold'}>
                            {totalResources?.toLocaleString() || 0}
                        </Typography>
                        <Typography>
                            Total number of non-compliant resources
                        </Typography>
                    </div>
                </div>
                <SummaryCard percentage={calculatePercentage(resourcesWithNoTags)}
                             color={'#ff4040'}
                             description={'Number of resources with no tags'}
                             value={resourcesWithNoTags?.toLocaleString() || 0}/>
                <SummaryCard percentage={calculatePercentage(resourcesWithPartialTags)}
                             color={'#8640ff'}
                             description={'Number of resources with partial tags'}
                             value={resourcesWithPartialTags?.toLocaleString() || 0}/>
                <UsefulLinks/>
            </div>
        </div>

    )
}

function UsefulLinks() {
    return (
        <Paper variant={'outlined'} className={'tc-summary-card tc-useful-link'}>
            <ol>
                <li>
                    <Link to={'/report/tag'}>Tag report</Link>
                </li>
                <li>
                    <Link to={'/tagging/auto-tagging'}>Auto tagging</Link>
                </li>
                <li>
                    <Link to={'/find-resource-tags'}>List of standard tags</Link>
                </li>
            </ol>
        </Paper>
    )
}


function SummaryCard({title, value = 0, description, percentage, color}) {
    return (
        <Paper className={'tc-summary-card'} variant={'outlined'}>
            <div className={'tc-summary-card-graph'}>
                <SummaryRadialGraph percentage={percentage} color={color}/>
            </div>
            <Divider flexItem orientation={'vertical'} style={{marginRight: 4}}/>
            <div className={'tc-summary-card-content'}>
                <Typography variant={'h5'} className={'tc-bold'}>
                    {value}
                </Typography>
                <Typography>
                    {description}
                </Typography>
            </div>
        </Paper>
    )
}