import {Collapse, Paper, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {otherLinks} from "./other_links";
import {TransitionGroup} from "react-transition-group";
import {CloudFormation} from "./CloudFormationTemplate";
import {TerraformTemplate} from "./TerraformTemplate";
import {Environment} from "./Environment";

export default function ApplicationPreview({selectedApplication = [], finopsTags}) {
    const [selectedApp, setSelectedApp] = useState({})

    useEffect(() => {
        const final = Object.entries(selectedApplication[0] || {}).reduce((acc, cur) => {
            if (typeof (cur[1]) == 'object' || cur[0].includes('_') || cur[0] === 'OnBoardingId' || cur[0] === 'Active') return acc
            acc[cur[0]] = cur[1]
            return acc
        }, {})
        setSelectedApp(final)
    }, [selectedApplication])

    return (
        <div className={'rt-application-preview'}>
            <div>
                <Typography variant={'body2'} color={'textSecondary'}>
                    ApplicationName
                </Typography>
                <Typography variant={'h6'}>
                    {selectedApplication[0]?.ApplicationName}
                </Typography>
            </div>
            <div className={'rt-content'}>
                <div className={'rt-application-data rt-raw-data'}>
                    <Paper style={{padding: 16}} variant={'outlined'} className={'rt-background-color'}>
                        {
                            Object.entries(selectedApp).length < 1 && 'No application selected.'
                        }
                        <TransitionGroup>
                            {
                                Object.entries(selectedApp).map(e =>
                                    <Collapse key={e[0]} timeout={200}>
                                        <ApplicationOptions value={e[1]}
                                                            property={e[0]}/>
                                    </Collapse>
                                )
                            }
                        </TransitionGroup>
                    </Paper>
                    <Paper style={{flex: 0, padding: 20, paddingRight: 34, height: 'fit-content'}}
                           variant={'outlined'}
                           className={'rt-background-color'}
                    >
                        <Typography color={'textSecondary'} gutterBottom>Environments</Typography>
                        <Environment/>
                    </Paper>
                </div>

                <div className={'rt-application-data'}>
                    <Paper style={{padding: 0}} elevation={0} className={'rt-background-color'}>
                        <CloudFormation finopsTags={finopsTags} appData={selectedApp}/>
                        <TerraformTemplate finopsTags={finopsTags} appData={selectedApp}/>
                    </Paper>

                    <Paper style={{flex: 0, padding: 20, paddingRight: 34, height: 'fit-content'}} variant={'outlined'} className={'rt-background-color'}>
                        <Typography color={'textSecondary'} gutterBottom>Guidance: </Typography>
                        <div className={'rt-use-full-links'}>
                            {
                                otherLinks.map((e, index) => <UsefulLinks key={index} {...e}/>)
                            }
                        </div>

                    </Paper>
                </div>
            </div>
        </div>

    )
}

function UsefulLinks({title, link}) {
    return (
        <a href={link} target={'_blank'} rel={'noreferrer'}> {title}</a>
    )
}

function ApplicationOptions({property, value}) {
    return (
        <div className={'rt-application-option'}>
            <Typography variant={'body2'}
                        style={{marginBottom: -4}}
                        color={'textSecondary'}>
                {property}
            </Typography>
            <Typography style={{fontWeight: 500}}>
                {value?.toString()}
            </Typography>
        </div>
    )
}
