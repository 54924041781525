import React from 'react';
import PropTypes from 'prop-types';
import DataGrid from "../../General/DataGrid/DataGrid";
import {costFormatter} from "../../../helper/generalFunctions";
import {Paper} from "@material-ui/core";

const columns = [
    {field: 'AccountId', headerName: 'Account Id', resizable: true, width: 170, suppressAutoSize: true, pinned: "left"},
    {field: 'AccountAlias', headerName: 'Account Alias', resizable: true, width: 170, suppressAutoSize: true, pinned: "left"},
    {field: 'Duration', headerName: 'Duration'},
    {field: 'Service', headerName: 'Service Name', resizable: true},
    {field: 'UsageType', headerName: 'Usage Type', resizable: true},
    {
        field: 'TotalImpact',
        headerName: 'Total Cost Impact ',
        valueFormatter: costFormatter,
        type: "numericColumn",
        filter: "agNumberColumnFilter",
        resizable: true,
        sortable: true,
        width: 200
    },
    {
        field: 'MaxCostImpact',
        name: 'Maximum Cost Impact ',
        resizable: true,
        width: 180,
        valueFormatter: costFormatter,
        type: "numericColumn",
        filter: "agNumberColumnFilter",
        sortable: true
    },
    {field: 'AnomalyId', headerName: 'Anomaly Id', resizable: true}
];

export default function CostAnomalyTable({costAnomaly = []}) {

    return (
        <Paper variant={'outlined'}>
            <DataGrid
                fileName={`cost-anomaly`}
                fixedColumn={columns}
                gridData={costAnomaly}
                gridStyle={{height: "calc(100vh - 400px)"}}
                gridClass="onboarding-grid"
            />
        </Paper>
    );
}

CostAnomalyTable.propTypes = {
    costAnomaly: PropTypes.array.isRequired
}