import React, {useEffect, useState} from 'react';
import {NotificationsOutlined} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import {Badge, ClickAwayListener, Grow, Paper, Popper} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Notification from "../Notification";
import NewUserRequestForm from "../NewUserRequestForm";


export default function NotificationTray() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [newUser, setNewUser] = useState(false)

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickAway = () => {
        setAnchorEl(null)
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div>
                <IconButton onClick={handleClick} style={{marginLeft: -16,}}>
                    <Badge badgeContent={2} color={'secondary'} variant={'dot'}>
                        <NotificationsOutlined/>
                    </Badge>
                </IconButton>
                <NotificationPopover
                    open={Boolean(anchorEl)}
                    setAnchorEl={setAnchorEl}
                    anchorEl={anchorEl}
                    setNewUser={setNewUser}/>
                {newUser && <NewUserRequestForm/>}
            </div>
        </ClickAwayListener>
    )
}


function NotificationPopover({open, anchorEl, setNewUser, setAnchorEl}) {

    const newUser = () => {
        setNewUser(true)
        setAnchorEl(null)
    }

    return (
        <Popper open={open} anchorEl={anchorEl} transition>
            {({TransitionProps}) => (
                <Grow {...TransitionProps} timeout={350}>
                    <Paper style={{maxWidth: 350, width: '85vw', marginRight: '80px'}} elevation={1}>
                        <Box p={1}>
                            <Notification onClick={newUser}/>
                        </Box>
                    </Paper>
                </Grow>
            )}
        </Popper>
    )
}