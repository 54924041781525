import Paper from "@material-ui/core/Paper";
import {Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import React from "react";
import moment from "moment";

let prev_month = moment().subtract('1', 'months').format("MMMM");
let curr_month = moment().format("MMMM");
export function LogHomeCard(props) {
    return (
        <Paper variant={"outlined"} className="card" >
            <Typography variant={"h6"}>
                {props.title}
            </Typography>

            <div className="summary">
                <div className="title">Summary</div>
                <div className="s-r">
                    <p style={{flexBasis: "60%"}}>Total number of resources</p>
                    <p>: {props.numberOfInstanceOptimized ? props.numberOfInstanceOptimized : "0"}</p>
                </div>
                <div className="s-r">
                    <p style={{flexBasis: "60%"}}>Total Cost for the Month {prev_month}</p>
                    <p>: {props.totalSavings ? "$"+ Number(props.totalPrev).toFixed(2) : "$0.00"}</p>
                </div>
                <div className="s-r">
                    <p style={{flexBasis: "60%"}}>Total Cost for the Month {curr_month}</p>
                    <p>: {props.totalSavings ? "$"+ Number(props.totalSavings).toFixed(2) : "$0.00"}</p>
                </div>
            </div>
            <Link to={props.routeLink} className="more-info">View Resources</Link>
        </Paper>
    )
}