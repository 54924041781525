import React, {useEffect, useState} from "react";
import {Fade, Paper} from "@material-ui/core";
import GranularityPicker from '../../GranularityPicker/GranularityPicker';
import service from "../../../../../service/service";
import {AlertWu} from "../../../../AlertWu/AlertWu";
import {Title} from "devextreme-react/pie-chart";
import Chart, {ArgumentAxis, Export, Label, Legend, Series, Tick, ValueAxis} from "devextreme-react/chart";
import {PaperProgress} from "../../../../ProgressCircle/ProgressCircle";
import {useOrganization} from "../../../../../hooks/useOrganization";

//dummy
import testData from '../../../../../assets/finthesys/savings-plan-coverage.json';

let coverageDataParsed = []
export default function SavingsPlanCoverage() {
    const {organization: masterAccountId} = useOrganization()
    const [error, setError] = useState("");
    const [errorBoundary, setErrorBoundary] = useState(true);
    const [coverage, setCoverage] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const handleChange = (event) => {
        if (masterAccountId !== 'no org') {
            setLoaded(false);
            setErrorBoundary(true);
            const body = {
                account_id: masterAccountId,
                Granularity: event.granularity.toUpperCase(),
                endDate: event.endDate,
                startDate: event.startDate
            }
            if (body.Granularity && body.endDate && body.startDate) {
                /*service.insights().getSavingsPlanCoverage(body)
                    .then(res => {
                        if (res.data.code === 200) {
                            setCoverage(res.data);
                            coverageDataParsed = [];
                            res.data.data.graph.map((coverage) => {
                                let tempTest = coverage.Coverage;
                                tempTest['StartDate'] = coverage.TimePeriod.Start;
                                tempTest['CoveragePercentage'] = Math.floor(Number(coverage.Coverage.CoveragePercentage))
                                coverageDataParsed.push(tempTest);
                            })
                            setErrorBoundary(false);
                            setLoaded(true);
                        }
                    })
                    .catch(err => {

                        setLoaded(true);
                        setErrorBoundary(true);
                        setError(err.message);
                    })*/

                setCoverage(testData);
                coverageDataParsed = [];
                testData.data.graph.forEach((coverage) => {
                    let tempTest = coverage.Coverage;
                    tempTest['StartDate'] = coverage.TimePeriod.Start;
                    tempTest['CoveragePercentage'] = Math.floor(Number(coverage.Coverage.CoveragePercentage))
                    coverageDataParsed.push(tempTest);
                })
                setErrorBoundary(false);
                setLoaded(true);
            }
        }
    }


    const CoverageRates = (props) => {
        return (
            <article>
                <div className="cov-percentage">
                    {props.value}
                </div>
                <div>
                    {props.description}
                </div>
            </article>
        )
    }

    function CoverageGraph() {
        return (
            <Paper style={{marginTop: 20, padding: 20}} variant={"outlined"}>
                <Chart
                    dataSource={coverageDataParsed}
                    id="coverage"
                    className="chart-class"
                >
                    <Title
                        font={{
                            size: 20
                        }}
                        text={"Savings plan coverage(% of dollars spend)"}
                    >
                    </Title>
                    <ValueAxis grid={false} defaultVisualRange={[0, 80]}>
                        <Tick visible={true}/>
                        <Label visible={true}/>
                        <Label
                            visible={true}
                            backgroundColor="#00000"
                        />
                    </ValueAxis>

                    <ArgumentAxis argumentType="datetime"/>
                    <Series
                        valueField="CoveragePercentage"
                        argumentField="StartDate"
                        type="spline"
                        color={"#000"}
                        ignoreEmptyPoints={true}
                    >
                    </Series>

                    <Legend visible={false}/>
                    <Export enabled={false}/>
                </Chart>
            </Paper>
        )
    }


    function CoverageResponse() {
        return (
            <Paper style={{marginTop: 20}} variant={"outlined"}>
                {!errorBoundary &&
                <div className="result-item-recommendation">
                    <CoverageRates
                        value={Math.floor(Number(coverage.data.averageCoverage)) + "%"}
                        description="Average Coverage"/>
                    <CoverageRates
                        value={"$" + Math.floor(Number(coverage.data.potentialMonthlySaving)).toLocaleString()}
                        description="Potential monthly saving vs On-Demand"/>
                    <CoverageRates
                        value={"$" + Math.floor(Number(coverage.data.onDemandSpendNotCovered)).toLocaleString()}
                        description="On-Demand spend not covered"/>
                </div>
                }
            </Paper>
        )
    }


    return (
        <div style={{marginBottom: 40}}>
            <Paper variant={"outlined"}>
                <GranularityPicker
                    onChange={handleChange}
                />
            </Paper>

            {
                errorBoundary && loaded &&
                <div style={{marginTop: 20}}>
                    <AlertWu severity="error" message={"Something went wrong... Error type: " + error}/>
                </div>

            }
            {
                !loaded &&
                <div style={{marginTop: 20}}><PaperProgress/></div>
            }

            {!errorBoundary && loaded &&
            <React.Fragment>
                <CoverageResponse/>
                <CoverageGraph/>
            </React.Fragment>
            }
        </div>
    )
}