import React, {useState} from 'react';
import {
    Fade,
    Paper,
    Popper,
    Typography
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import {ProgressCircleCustomSize} from "../ProgressCircle/ProgressCircle";
import PropTypes from "prop-types";

export default function WuFloatingDownloadButtonV2(props) {
    const {children, loading, downloadText, error} = {...props}

    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };


    return (
        <Box className={'power-scheduled-wrapper'}>
            <Popper open={open} anchorEl={anchorEl} transition placement={'top-end'}>
                {({TransitionProps}) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper className={error ? 'power-schedule-download error-ps' : 'power-schedule-download'}
                               elevation={2}>
                            <IconButton className={'close-icon'} onClick={() => setOpen(false)}>
                                <CloseIcon/>
                            </IconButton>
                            <Box>
                                {children}
                            </Box>
                        </Paper>
                    </Fade>
                )}
            </Popper>
            <Paper elevation={3}
                   style={open ? {opacity: 0.01, zIndex: 0} : {opacity: 1}}
                   className="ob-download-button"
                   onClick={(e) => handleClick(e)}>
                <Box display={'flex'} alignItems={"center"} className={"gap"} justifyContent={'center'}>
                    <Box display={'flex'} gridGap={16}>
                        {!loading && <CloudDownloadOutlinedIcon/>}
                        <Typography>
                            {downloadText}
                        </Typography>
                    </Box>
                    {loading && <ProgressCircleCustomSize size={16}/>}
                </Box>
            </Paper>
        </Box>
    )
}


WuFloatingDownloadButtonV2.propTypes = {
    children: PropTypes.element.isRequired,
    loading: PropTypes.bool,
    downloadText: PropTypes.string.isRequired,
    error: PropTypes.bool,
};