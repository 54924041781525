import React, {useEffect, useState} from 'react';
import {useOrganization} from "../../../hooks/useOrganization";
import service from "../../../service/service";
import {jsonParser} from "../../../helper/generalFunctions";
import WuTagAndRegionFilter from "../../General/WuTagAndRegionFilter";
import WuToolTip from "../../General/WuToolTip";
import {HelpOutlineOutlined} from "@material-ui/icons";
import Box from "@material-ui/core/Box";
import RecommendationCheckDetails from "./RecommendationCheckDetails";

export default function SecurityGroup() {

    const {organization} = useOrganization();
    const [loading, setLoading] = useState(false);
    const [ready, setReady] = useState(false);
    const [error, setError] = useState({error: false, message: undefined});
    const [checkData, setCheckData] = useState([]);
    const [filter, setFilter] = useState({});

    const fetchData = async () => {
        try {
            setReady(false);
            setLoading(true);
            setError({error: false, message: undefined})
            const params = {master_account_id: organization, filter: filter, recommendation_type: 'security'}
            const {data: {data}} = await service.recommendations().getTrustedAdvisorChecks(params);
            setCheckData(data);
            setReady(true)
        } catch (e) {
            const errorBody = jsonParser(e.request.response);
            setError({error: true, message: errorBody ? errorBody.error : e.message})
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (organization !== 'no org') {
            fetchData()
        }
    }, [organization, filter])

    return (
        <Box>
            <Box mt={0} mb={2} className={'gap'} display={'flex'} alignItems={'center'}>
                <WuTagAndRegionFilter disabled={false} regionEnable={false} onClick={(e) => setFilter(e)}/>
                <WuToolTip
                    title={'Tag filter supported for Security Groups'}>
                    <HelpOutlineOutlined style={{color: 'var(--secondary-highlight)'}}/>
                </WuToolTip>
            </Box>

            <div>
                {
                    ready &&
                    checkData.map(({CheckId, CheckName, EstimatedMonthlySaving}) => <RecommendationCheckDetails
                        key={`${organization}${CheckId}`}
                        filter={filter}
                        EstimatedMonthlySaving={EstimatedMonthlySaving}
                        masterAccountId={organization}
                        checkId={CheckId}
                        checkName={CheckName}/>)
                }
            </div>
        </Box>
    )
}