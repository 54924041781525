import React from 'react';
import service from "../../../service/service";
import DownloadReport from "../DownloadReport";

export default function DownloadReportEfs() {
    const apiCallFunction = service.monitoring().downloadReportEfs
    return <DownloadReport downloadFunction={apiCallFunction}
                           title={'Download EFS report'}
                           excludeRegion={true}
                           paramKey={'account_ids'}/>
}