import React from "react";
import CustomTab from "../General/CustomTab/CustomTab";
import PageTitle from "../General/PageTitle/PageTitle";
import {RecommenderRoute} from "../../routes/MainRoute";
import "./recommendations.scss";

const style = {
    freeWidth: {
        marginLeft: 40,
        marginTop: 30,
        marginBottom: 50,
        marginRight: 65
    }
}

const links = [
    {
        link: "/recommendations/trusted-advisor",
        name: "Trusted Advisor"
    },
    {
        link: "/recommendations/compute-optimizer",
        name: "Compute Optimizer"
    },
    {
        link: "/recommendations/power-schedule",
        name: "Power Schedules"
    }
]

export default function Recommendations() {
    return (
        <div>
            <div style={style.freeWidth}>
                <PageTitle title={"Cost Optimization"}/>
            </div>
            <CustomTab links={links}/>
            <div style={style.freeWidth}>
                <RecommenderRoute/>
            </div>
        </div>
    )
}