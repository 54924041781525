import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import {Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import React from "react";

export default function Icon({status}) {
    if (status === 'UPDATING') return <ErrorOutlineIcon style={{color: "orange"}}/>
    if (status === 'FAILED') return <ErrorOutlineIcon style={{color: "red"}}/>
    if (status === 'READY') return <CheckCircleOutlineOutlinedIcon style={{color: "green"}}/>
    else return <ErrorOutlineIcon style={{color: "red"}}/>
}