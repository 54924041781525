import React, {useState} from "react";
import {Button, InputLabel, MenuItem, Select, Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import WuTextField from "../../General/WuTextFeild";
import {AlertWu} from "../../AlertWu/AlertWu";
import FormControl from "@material-ui/core/FormControl";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function UserCreate() {
    const classes = useStyles();
    const [newUser, setNewUser] = useState({
        username: "",
        password: "",
        emailId: "",
        role: "READ"
    })

    const handleUserCreate = (event) => {
        setNewUser({...newUser, [event.target.name]: event.target.value});
    }


    return (
        <div>
            <Box mb={2}>
                <Typography variant={"h6"}>Create New User</Typography>
                <Typography variant={"subtitle1"}> Create new user with desired access levels</Typography>
            </Box>
            <Box mb={2}>
                <form>
                    <Box display={"flex"} style={{gap: 16}}>
                        <Box>
                            <WuTextField label={"Username"} value={newUser.username} name={"username"}
                                         onChange={handleUserCreate}/>
                            <WuTextField label={"Password"} valuee={newUser.password} name={"password"}
                                         onChange={handleUserCreate}/>
                            <WuTextField label={"Email id"} valuee={newUser.emailId} name={"emailId"}
                                         onChange={handleUserCreate}/>
                        </Box>
                        <Box display={"flex"}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="user-permission">Access Level</InputLabel>
                                <Select
                                    value={newUser.role}
                                    onChange={handleUserCreate}
                                    labelId="user-permission"
                                    label={"Access Level"}
                                    displayEmpty
                                    name={"role"}
                                    variant={"outlined"}
                                    margin={"dense"}
                                    inputProps={{'aria-label': 'user-role'}}
                                >
                                    <MenuItem value={"READ"}>Read</MenuItem>
                                    <MenuItem value={"WRITE"}>Write</MenuItem>
                                    <MenuItem value={"ADMIN"}>Admin</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>

                    <Button variant={"contained"} color={"secondary"} disableElevation
                            style={{marginTop: 16, marginLeft: 4}}> Create </Button>
                </form>
            </Box>
            <AlertWu
                alertOpen={{state: true}}
                severity={"success"}
                message={"new environment added."}/>
        </div>
    )
}