import moment from "moment";

const cellClassRules = {
    'account-warning-1': cellRuleMaker(-100000, -80),
    'account-warning-2': cellRuleMaker(-80, -60),
    'account-warning-3': cellRuleMaker(-60, -40),
    'account-warning-4': cellRuleMaker(-40, -20),
    'account-warning-5': cellRuleMaker(-20, -0),
    'account-warning-6': cellRuleMaker(0, 20),
    'account-warning-7': cellRuleMaker(20, 40),
    'account-warning-8': cellRuleMaker(40, 60),
    'account-warning-9': cellRuleMaker(60, 80),
    'account-warning-10': cellRuleMaker(80, 100000),
}

function cellRuleMaker(startValue, endValue) {
    return `x?.changePercentage <= ${endValue} && x?.changePercentage >= ${startValue}`
}

function changePercentage({value}) {
    return `${value?.changePercentage || '0'}%`
}

function tableCellMaker() {
    const startDate = moment('01-03-2022', 'DD-MM-YYYY');
    const endDate = moment('22-03-2022', 'DD-MM-YYYY');
    const now = startDate.clone(), dates = [];

    while (now.isSameOrBefore(endDate)) {
        dates.push({
            field: now.format('YYYY-MM-DD'),
            headerName: now.format('MM/DD/YYYY'),
            valueFormatter: changePercentage,
            cellRenderer: "costComponent",
            cellClassRules: cellClassRules,
            width: 150
        });
        now.add(1, 'days');
    }

    return dates.reverse()
}

export const tableColumns = [
    {
        field: 'AccountId',
        headerName: 'Account',
        valueGetter: function ({data}) {
            return (`${data.AccountId} (${data.AccountAlias})`)
        },
        pinned: 'left'
    },
    ...tableCellMaker()
]


