import React, {useState} from 'react';
import PageTitle from "../General/PageTitle/PageTitle";
import SpendVisualization from "./SpendVisualization";
import SpendSummary from "./SpendSummary";
import WuStack from "../General/WuStack";
import './index.scss'
import FilterContext from "./FilterContext";

const spendInsightInitialValues = {
    loading: true,
    today: {},
    tomorrow: {}
}


export default function AccountTrend() {
    const [spendInsight, setSpendInsight] = useState(spendInsightInitialValues)
    const [filter, setFilter] = useState(null);
    const value = {filter, setFilter}

    return (
        <div className={'title-div-wrapper'} style={{marginLeft: 0}}>
            <FilterContext.Provider value={value}>
                {/*<PageTitle title={'Daily Cost Trend'} subTitle={'Daily Spend Variation in all accounts'}/>*/}
                <WuStack spacing={3} boxProps={{marginTop: 3}} justifyContent={'start'}>
                    <SpendSummary spendInsight={spendInsight}/>
                    <SpendVisualization onSpendInsightChange={setSpendInsight}/>
                </WuStack>
            </FilterContext.Provider>
        </div>
    )
}