import React from 'react';
import './drawer.scss';
import {NavLink} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';

import FlightTakeoffRoundedIcon from '@material-ui/icons/FlightTakeoffRounded';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import TimelineOutlinedIcon from '@material-ui/icons/TimelineOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import WbIncandescentOutlinedIcon from '@material-ui/icons/WbIncandescentOutlined';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import AirlineSeatIndividualSuiteOutlinedIcon from '@material-ui/icons/AirlineSeatIndividualSuiteOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import FiberNewRounded from "@material-ui/icons/FiberNewRounded";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {MoneyOffOutlined, TrendingUpOutlined} from "@material-ui/icons";

const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        marginBottom: -1,
        minHeight: 50,
        maxHeight: 50,
        '&$expanded': {
            minHeight: 50
        },
    },
    content: {
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
    root: {
        padding: 0,
        paddingLeft: 10
    }
})(MuiAccordionDetails)

export default function SideMenu(open) {

    const styleIcon = {paddingRight: 15}

    const insights = [
        {
            "navItemName": "Business Unit Reports",
            "navItemUrl": "/insights/wu-reports",
            "icon": <AssessmentOutlinedIcon style={styleIcon}/>
        },
        /*{
            "navItemName": "Account Trend ⚡",
            "navItemUrl": "/insights/account-trend",
            "icon": <TrendingUpOutlined style={styleIcon}/>
        }*/
    ]

    if (localStorage.getItem('loginType') === "admin") {
        insights.push(
            {
                "navItemName": "Savings Recommendations",
                "navItemUrl": "/insights/purchase-recommendations",
                "icon": <EmojiObjectsOutlinedIcon style={styleIcon}/>
            }
        )
    }


    function ExpansionNavItem(props) {
        return (
            <Accordion className="expandable-nav-bar">
                <AccordionSummary
                    style={{display: "flex", alignItem: "center", padding: '0 12px'}}
                    expandIcon={<ExpandMoreIcon/>}
                >
                    {props.mainMenu.icon} {props.mainMenu.navItemName}
                </AccordionSummary>
                <AccordionDetails
                    style={{display: "block"}}>
                    {
                        props.items.map((navItem, index) => {
                            return (
                                <NavLink key={index}
                                         to={navItem.navItemUrl}> {navItem.icon} {navItem.navItemName} </NavLink>
                            )
                        })
                    }
                </AccordionDetails>

            </Accordion>
        )
    }


    return (
        <div className={open.open ? "sidebar-wrapper" : "sidebar-translation-close sidebar-wrapper"}>
            <nav>
                <ul>
                    <li><NavLink to="/dashboard"> <DashboardOutlinedIcon style={styleIcon}/> Dashboard </NavLink></li>
                    <li><NavLink to="/on-boarding/application"> <FlightTakeoffRoundedIcon style={styleIcon}/>Application
                        Onboarding </NavLink></li>
                    <ExpansionNavItem
                        mainMenu={
                            {
                                "navItemName": "Budget",
                                "icon": <MonetizationOnOutlinedIcon style={styleIcon}/>
                            }
                        }
                        items={
                            [
                                {
                                    "navItemName": "Application",
                                    "navItemUrl": "/budget/application",
                                    "icon": <AppsOutlinedIcon style={styleIcon}/>
                                },
                                {
                                    "navItemName": "Account",
                                    "navItemUrl": "/budget/account",
                                    "icon": <AccountTreeOutlinedIcon style={styleIcon}/>
                                },
                            ]

                        }/>
                    <li><NavLink to="/report"> <AssessmentOutlinedIcon style={styleIcon}/>Reports </NavLink></li>
                    <li><NavLink to="/guard-rails"> <LockOutlinedIcon style={styleIcon}/>Guardrails </NavLink></li>

                    <ExpansionNavItem
                        mainMenu={
                            {
                                "navItemName": "Cost Optimization",
                                "icon": <EmojiObjectsOutlinedIcon style={styleIcon}/>
                            }
                        }
                        items={
                            [
                                {
                                    "navItemName": "Recommendations",
                                    "navItemUrl": "/recommendations",
                                    "icon": <EmojiObjectsOutlinedIcon style={styleIcon}/>
                                },
                                {
                                    "navItemName": "Automations",
                                    "navItemUrl": "/automations",
                                    "icon": <SettingsOutlinedIcon style={styleIcon}/>
                                }
                            ]

                        }/>
                    <ExpansionNavItem
                        mainMenu={
                            {
                                "navItemName": "Monitoring",
                                "icon": <TimelineOutlinedIcon style={styleIcon}/>
                            }
                        }
                        items={
                            [
                                {
                                    "navItemName": "Cost Anomalies",
                                    "navItemUrl": "/monitoring/cost-anomalies",
                                    "icon": <MoneyOffOutlined style={styleIcon}/>
                                },
                                /*{
                                    "navItemName": "Unused Resources",
                                    "navItemUrl": "/monitoring/unused-resources",
                                    "icon": <AirlineSeatIndividualSuiteOutlinedIcon style={styleIcon}/>
                                },*/
                                {
                                    "navItemName": "Tag Compliance",
                                    "navItemUrl": "/monitoring/tag-compliance",
                                    "icon": <AssignmentTurnedInOutlinedIcon style={styleIcon}/>
                                },
                                /*{
                                    "navItemName": "New Deployments ⚡",
                                    "navItemUrl": "/monitoring/newly-provisioned-resources",
                                    "icon": <FiberNewRounded style={styleIcon}/>
                                },*/

                                /*{
                                    "navItemName": "Log Tracking",
                                    "navItemUrl": "/monitoring/log-tracking",
                                    "icon": <DescriptionOutlinedIcon style={styleIcon}/>
                                }*/
                            ]

                        }/>
                    <ExpansionNavItem
                        mainMenu={
                            {
                                "navItemName": "Insights",
                                "icon": <WbIncandescentOutlinedIcon style={styleIcon}/>
                            }
                        }
                        items={insights}/>
                    {
                        localStorage.getItem('loginType') === "admin" &&
                        localStorage.getItem('loginPriority') === "super-admin" &&
                        <li className="about">
                            <NavLink to="/settings"> <SettingsOutlinedIcon style={styleIcon}/>Settings</NavLink>
                        </li>
                    }
                </ul>
            </nav>
        </div>
    )
}