import React from "react";
import "./throbber.scss"
import {LinearProgress} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

export default function Throbber({value}) {
    return (
        <div className="throbber-wrapper">
            <div style={{width: 400}}>
                <LinearProgressWithLabel value={value}/>
            </div>
        </div>
    )
}


function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress color={"secondary"} variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}