import WuTextField from "../../General/WuTextFeild";
import WuButton from "../../General/WuButton";
import {useState} from "react";
import {SearchOutlined} from "@material-ui/icons";

export default function Filter(props) {
    const {onSearch, loading} = {...props}
    const [searchKeyword, setSearchKeyword] = useState('')

    const handleChange = (e) => {
        setSearchKeyword(e.target.value)
    }

    const handleClick = () => {
        onSearch(searchKeyword)
    }

    return (
        <div className={'ecs-filter'}>
            <WuTextField label={'Cluster Arn'}
                         placeholder={'cluster arn/cluster name'}
                         onChange={handleChange}
                         type={'search'}/>
            <div className={'ecs-filter-button-wrapper'}>
                <WuButton onClick={handleClick}
                          disabled={loading || !searchKeyword}
                          startIcon={<SearchOutlined/>}>
                    {loading ? 'Searching': 'Search'}
                </WuButton>
            </div>
        </div>
    )
}