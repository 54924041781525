import React from 'react';
import {LogTrackingRoute} from "../../../routes/MainRoute"

export default function Log() {
    return (
        <div className="title-div-wrapper">
            <LogTrackingRoute/>
        </div>
    )
}

