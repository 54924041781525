import React, {useEffect, useState} from "react";
import {Collapse, Divider, FormControlLabel, Paper, Radio, RadioGroup, Typography} from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import {useOrganization} from "../../hooks/useOrganization";
import fileExportElement from "../../helper/fileExportElement";
import service from "../../service/service";
import {ProgressCircleCustomSize} from "../ProgressCircle/ProgressCircle";


export default function WuFloatingDownloadButton(props) {
    const {serviceFn} = {...props}
    const {organization: masterAccountId} = useOrganization();
    const [open, setOpen] = useState(false);
    const [radio, setRadio] = useState(null);
    const [downloadText, setDownloadText] = useState('DOWNLOAD');
    const [ready, setReady] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [organizationData, setOrganizationData] = useState([]);
    const [organizationName, setOrganizationName] = useState('Current Organization');
    const disabled = localStorage.getItem("loginType") !== "admin";

    const onboardingDataDownload = async () => {
        try {
            setReady(false);
            setLoading(true);
            setDownloadText('DOWNLOADING...')
            setError(false);
            const params = {master_account_id: radio}
            const response = await serviceFn(params)
            fileExportElement(response);
            setReady(true);
            setDownloadText("DOWNLOAD");
            setRadio(null)
        } catch (e) {
            setError(true);
            setDownloadText("DOWNLOAD");
            setRadio(null)
        } finally {
            setLoading(false);
        }
    }

    const handleOrganizationLoad = async () => {
        try {
            const {data: {organizations}} = await service.account().getOrganizationList({}) || [];
            setOrganizationData(organizations)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (!loading && radio) {
            onboardingDataDownload().then();
        }
    }, [radio])

    useEffect(() => {
        handleOrganizationLoad().then()
    }, [])

    useEffect(() => {
        if (masterAccountId !== 'no org') {
            const {OrganizationAlias} = organizationData.find(organization => organization.AccountId === masterAccountId) || organizationName;
            if (OrganizationAlias) setOrganizationName(OrganizationAlias)
        }
    }, [masterAccountId, organizationData])

    return (
        <Paper elevation={3}
               onMouseLeave={() => setOpen(false)}
               className="ob-download-button"
               onMouseOver={() => setOpen(true)}>
            <Collapse in={open} timeout={300}>
                <Box mb={0.5} mt={1}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend" style={{marginBottom: 8}}>Select Organization</FormLabel>
                        <RadioGroup aria-label="gender" name="gender1" value={radio}
                                    onChange={(e) => setRadio(e.target.value)}>
                            <FormControlLabel value={masterAccountId} control={<Radio/>} label={organizationName}/>
                            {!disabled && <FormControlLabel value="all" control={<Radio/>} label="All Organizations"/>}
                        </RadioGroup>
                    </FormControl>
                </Box>
                <Divider/>
                <Box mb={1.5}/>
            </Collapse>

            <Box display={'flex'} alignItems={"center"} className={"gap"} justifyContent={'center'}>
                <Box display={'flex'} gridGap={16}>
                    {!loading && <CloudDownloadOutlinedIcon/>}
                    <Typography>
                        {downloadText}
                    </Typography>
                </Box>
                {loading && <ProgressCircleCustomSize size={16}/>}
            </Box>
        </Paper>
    )
}