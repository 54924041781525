export const newResources = {
    "code": 200,
    "success": true,
    "data": [{
        "AccountID": "353463536366",
        "Region": "us-east-1",
        "ResourceId": "arn:aws:rds:us-east-1:353463536366:db:database-2-instance-1-us-east-1b",
        "NAME": "",
        "ApplicationName": "",
        "UsageStartDate": "2021-10-29",
        "ApplicationOwner": "",
        "CostCenter": "",
        "Environment": "",
        "ServiceName": "Amazon Relational Database Service",
        "ServiceCode": "AmazonRDS",
        "Cost": "7.27"
    },  {
        "AccountID": "353463536366",
        "Region": "us-east-2",
        "ResourceId": "arn:aws:rds:us-east-2:353463536366:db:database-1-instance-1",
        "NAME": "",
        "ApplicationName": "",
        "UsageStartDate": "2021-10-19",
        "ApplicationOwner": "",
        "CostCenter": "",
        "Environment": "",
        "ServiceName": "Amazon Relational Database Service",
        "ServiceCode": "AmazonRDS",
        "Cost": "0.23"
    }, {
        "AccountID": "353463536366",
        "Region": "us-east-1",
        "ResourceId": "i-047f66f5fc39e33c2",
        "NAME": "FinopsTest1",
        "ApplicationName": "",
        "UsageStartDate": "2021-10-22",
        "ApplicationOwner": "",
        "CostCenter": "",
        "Environment": "",
        "ServiceName": "Amazon Elastic Compute Cloud",
        "ServiceCode": "AmazonEC2",
        "Cost": "0.12"
    }, {
        "AccountID": "353463536366",
        "Region": "us-east-1",
        "ResourceId": "arn:aws:rds:us-east-1:353463536366:db:database-1-instance-1-us-east-1b",
        "NAME": "",
        "ApplicationName": "",
        "UsageStartDate": "2021-10-29",
        "ApplicationOwner": "",
        "CostCenter": "",
        "Environment": "",
        "ServiceName": "Amazon Relational Database Service",
        "ServiceCode": "AmazonRDS",
        "Cost": "0.11"
    }],
    "totalcost": "36.47",
    "averagecost": "1.22"
}