import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";


/**
 * Compute Optimizer recommendation tread graph
 * @return {JSX.Element}
 * @constructor
 */
export default function ComputeRecommendationTrend() {
    return (
        <Paper variant={"outlined"} className="card">
            <Typography variant={"h6"}> Cost Savings Trend</Typography>
            <Typography variant={"subtitle1"}> This is an upcoming feature</Typography>
        </Paper>
    )
}