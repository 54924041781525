import React, {useEffect, useState} from 'react';
import ReactApexChart from "react-apexcharts";

export default function Graph(props) {
    const {graphData = []} = {...props}
    const [ready, setReady] = useState(false);
    const [graphSeriesData, setGraphSeriesData] = useState([{
        data: []
    }])

    const state = {
        series: [
            {
                data: []
            }
        ],
        options: {
            legend: {
                show: false
            },
            colors: ['#e30048'],
            plotOptions: {
                bar: {
                    horizontal: true,
                    borderRadius: 4
                }
            },
            grid: {
                show: false
            },
            xaxis: {
                labels: {
                    formatter: function (value) {
                        return `$${value.toFixed(2)}`
                    }
                }
            },
            chart: {
                height: 400,
                type: 'bar',
                toolbar: {
                    show: false
                },
                events: {
                    dataPointSelection: function (event, chartContext, config) {
                        clickItem(config)
                    }
                },
                fontFamily: 'Century Gothic',
            },
        },
    };

    const clickItem = (e) => {
        const serviceName = graphSeriesData[0]["data"][e.dataPointIndex]['x']
        props.onClick(serviceName)
    }


    useEffect(() => {
        setReady(false);
        setGraphSeriesData([{
            data: graphData
        }]);
        setReady(true)
    }, [])

    return (
        <>
            {
                ready && <ReactApexChart options={state.options} series={graphSeriesData} type="bar" height={500}/>
            }
        </>
    )
}