import {accountLengthAdjust} from "../../../../../helper/generalFunctions";

export const ebs = [
    {
        field: "Account",
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
        valueGetter: function ({data}) {
            return (`${accountLengthAdjust(data.Account_AccountId)} (${data.AccountAlias})`)
        }
    },
    {
        field: "ApplicationName",
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
    },
    {
        field: "CostCenter",
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
    },
    {
        field: "CurrentSize",
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
    },
    {
        field: "CurrentIops",
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
    },
    {
        field: "RecommendedType",
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
    },
    {
        field: "RecommendedSize",
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
    },
    {
        field: "RecommendedIops",
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
    },
    {
        field: "AttachmentInformation",
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
    },
    {
        field: "Region_RegionCode ",
        headerName: "Region",
        resizable: true,
        sortable: true,
        suppressAutoSize: false,
    },
]