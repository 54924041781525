import React, {useEffect, useState} from 'react';
import {Paper} from "@material-ui/core";
import DataGrid from "../../General/DataGrid";
import WuAccordionDetails from "../../General/WuAccordion/WuAccordionDetails";
import service from "../../../service/service";
import WuLoading from "../../General/WuLoading";
import {jsonParser} from "../../../helper/generalFunctions";
import {tableHeaders} from './tableHeaders'

export default function AccountWiseSummary({expanded, accountId, filter, externalDataRefresh}) {
    const [apiProgress, setApiProgress] = useState({ready: false, loading: false, error: ''})
    const [responseData, setResponseData] = useState([])

    const fetchData = async () => {
        try {
            setApiProgress({ready: false, loading: true, error: ''})
            const params = {
                account_id: accountId,
                filter: filter
            }
            const {data: {data}} = await service.governance().getSubnetAccountWiseSummary(params)
            setResponseData(data)
            setApiProgress({ready: true, loading: false, error: ''})
        } catch (e) {
            let errorObj
            try {
                errorObj = jsonParser(e.request.response).error
            } catch (e) {
                errorObj = e.message
            }
            setApiProgress({ready: false, loading: false, error: errorObj})
        }
    }

    useEffect(() => {
        if (expanded === true && (responseData.length < 1 || externalDataRefresh)) {
            fetchData().then()
        }
    }, [expanded])

    return (
        <WuAccordionDetails>
            <Paper style={{width: '100%'}}>
                {apiProgress.loading && <WuLoading message={`Loading ${accountId} subnet data`}/>}
                {apiProgress.ready &&
                <DataGrid
                    rowClassRules={{
                        'vpc-only': function (params) {
                            return !params.data.SubnetId;
                        },
                        'default-vpc': function (params) {
                            return params.data.DefaultVpc === 'true';
                        },
                        'critical-subnet': function (params) {
                            return params.data.CriticalSubnet === 1;
                        },
                    }}
                    gridData={responseData}
                    gridStyle={{height: '30vh'}}
                    fixedColumn={tableHeaders}/>}
            </Paper>
        </WuAccordionDetails>
    )
}
