import React, {useState} from "react";
import WuTextField from "../../General/WuTextFeild";
import {Button} from "@material-ui/core";
import {AlertWu} from "../../AlertWu/AlertWu";

export function AddResourceTag(props) {

    const [resourceTagKey, setResourceTagKey] = useState("CostCode");
    const [resourceTagType, setResourceTagType] = useState("String");
    const [comment, setComment] = useState("this is the cost center information ");
    const [fieldError, setFieldError] = useState({
        resourceTagKey: false,
        resourceTagType: false
    })

    const resourceTagCreate = () => {
        setFieldError({
            resourceTagKey: resourceTagKey.length < 1,
            resourceTagType: resourceTagType.length < 1,
        })

        if (resourceTagKey && resourceTagType) {
            console.log(resourceTagKey, resourceTagType)
            return true
        }
    }

    return (
        <React.Fragment>
            <div className="d-f-s" style={{marginTop: 20}}>
                <WuTextField
                    value={resourceTagKey}
                    error={fieldError.resourceTagKey}
                    onChange={(e) => {
                        setResourceTagKey(e.target.value)
                    }}
                    label="Tag Key*"
                />
                <WuTextField
                    value={resourceTagType}
                    error={fieldError.resourceTagType}
                    onChange={(e) => {
                        setResourceTagType(e.target.value)
                    }}
                    label="Tag Key Type*"
                />
                <WuTextField
                    value={comment}
                    onChange={(e) => {
                        setComment(e.target.value)
                    }}
                    label="Comment"
                />
                <Button onClick={resourceTagCreate} variant={"contained"} disableElevation color={"secondary"}>
                    Save
                </Button>
            </div>
            <div style={{marginTop: 20}}>
                <AlertWu
                    alertOpen={{state: true}}
                    severity={"success"}
                    message={"new tag key added"}/>
            </div>
        </React.Fragment>
    )
}