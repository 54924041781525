import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import service from "../../../../service/service";
import {ProgressCircleCustomSize} from "../../../ProgressCircle/ProgressCircle";
import {AlertWu} from "../../../AlertWu/AlertWu";
import ApplicationData from "../GeneralComponents/ApplicationData";
import ContactInformation from "../GeneralComponents/ContactData";
import AccountInformation from "../GeneralComponents/AccountData";
import {useOrganization} from "../../../../hooks/useOrganization";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

function getSteps() {
    return ['Application information', 'Contact information', 'Account, Budget and Environment'];
}


export default function ApplicationStepper() {
    const classes = useStyles();
    const steps = getSteps();
    const organization = useOrganization()

    const [masterAccountId, setMasterAccountId] = useState(organization.organization);
    const [activeStep, setActiveStep] = useState(0);
    const [contactData, setContactData] = useState({
        supportContact: "",
        applicationOwner: "",
        businessOwner: "",
        applicationArchitect: ""
    });

    const [applicationData, setApplicationData] = useState({
        applicationName: "",
        appCode: "",
        businessGroup: "",
        costCode: "",
        accounts: [],
        workStreamName: ""
    });

    const [accountData, setAccountData] = useState([])
    const [loading, setLoading] = useState(false);
    const [errorBoundary, setErrorBoundary] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [ready, setReady] = useState(false);

    useEffect(() => {
        setMasterAccountId(organization.organization)
    }, [organization])


    function getStepContent(step) {
        switch (step) {
            case 0:
                return <ApplicationData onChange={(e) => setApplicationData(e)} applicationData={applicationData}/>;
            case 1:
                return <ContactInformation onChange={(e) => setContactData(e)} contactData={contactData}/>;
            case 2:
                return <AccountInformation onChange={(e) => setAccountData(e)}/>;
            default:
                return 'Unknown step';
        }
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);

        /*        if(activeStep === steps.length - 1)*/

    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    function environmentProcessing(data) {
        let environments = {}
        if (data) {
            data.forEach((item) => {
                environments[item.toUpperCase()] = true;
            })
        }
        return environments
    }


    function applicationDataParser(accountArray) {
        let accounts = []
        let budgets = []
        accountArray.forEach((account) => {
            accounts.push(
                {
                    Account_AccountId: account.account,
                    Region_RegionCode: account.region
                }
            )

            budgets.push({
                Account_AccountId: account.account,
                Region_RegionCode: account.region,
                Environments: environmentProcessing(account.environment),
                BudgetAmount: account.budgetAmount,
                BudgetNotificationSubscribers: account.notificationSubscribers
            })
        })

        return {accounts, budgets}
    }


    const handleApplicationCreate = () => {
        setLoading(true);
        setErrorBoundary(false);
        setReady(false);
        const accountInfo = applicationDataParser(accountData)
        const data = {
            Active: true,
            AppCode: applicationData.appCode,
            ApplicationName: applicationData.applicationName,
            CostCode: applicationData.costCode,
            BusinessGroup: applicationData.businessGroup,
            WorkStreamName: applicationData.workStreamName,
            ApplicationOwner: contactData.applicationOwner,
            SupportContact: contactData.supportContact,
            ApplicationArchitect: contactData.applicationArchitect,
            BusinessOwner: contactData.businessOwner,
            ApplicationAccounts: applicationData?.accounts.map(e => e.AccountId),
            Accounts: accountInfo.accounts,
            AccountInformation: accountInfo.budgets,
            MasterAccountId: masterAccountId
        }
        service.onBoarding().createApplication(data)
            .then(ref => {
                setLoading(false);
                setReady(true);
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            })
            .catch(err => {
                setErrorMessage(err.message);
                setErrorBoundary(true);
                setLoading(false);
                setReady(false);
            })
    }


    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                        <StepContent>
                            <div>{getStepContent(index)}</div>
                            <div className={classes.actionsContainer}>
                                <div style={{display: "flex", alignItems: "center", alignContent: "center"}}>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.button}
                                    >
                                        Back
                                    </Button>
                                    {
                                        activeStep === steps.length - 1 ?
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disableElevation
                                                disabled={loading}
                                                onClick={handleApplicationCreate}
                                                className={classes.button}
                                            > finish </Button> :
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={loading}
                                                disableElevation
                                                onClick={handleNext}
                                                className={classes.button}
                                            >NEXT</Button>
                                    }
                                    {
                                        loading &&
                                        <div className={classes.button} style={{marginLeft: 20}}>
                                            <ProgressCircleCustomSize size={20}/>
                                        </div>
                                    }
                                </div>


                                <div style={{marginTop: 20}}>
                                    <AlertWu severity={"error"} alertOpen={{state: errorBoundary}}
                                             message={errorMessage}/>
                                </div>

                            </div>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === steps.length && (
                <Paper square elevation={0} className={classes.resetContainer}>
                    <Typography>All steps completed - you&apos;re finished</Typography>
                    <div style={{marginTop: 20}}>
                        <AlertWu alertOpen={{state: true}} severity={"success"}
                                 message={`onboarding process for ${applicationData.applicationName}
                                 completed. you can close the window now.`}/>
                    </div>
                </Paper>
            )}
        </div>
    );
}