import React from "react";
import './report.scss';
import {ReportRoute} from "../../routes/MainRoute";
import CustomTab from "../General/CustomTab/CustomTab";
import PageTitle from "../General/PageTitle/PageTitle";

function Report() {
    const tabList = [
        {
            name: "Cost Explorer",
            link: "/report/cost-explorer"
        },
        {
            name: "Tag Report",
            link: "/report/tag"
        },
        {
            name: "Cost and Usage",
            link: "/report/billing"
        },
        {
            name: "Daily Cost Trend",
            link: "/report/account-trend"
        }
    ]
    return (
        <div>
            <div className="title-div-wrapper">
                <PageTitle title={"Generate cost explorer, tag and cost and usage report"}/>
            </div>
            <CustomTab links={tabList}/>
            <div className="title-div-wrapper">
                <ReportRoute/>
            </div>
        </div>
    );
}

export default Report;
