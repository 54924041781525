import React, {useEffect, useState} from 'react';
import SideSubMenu from "./SideSubMenu";
import {NavLink} from "react-router-dom";
import WuStack from "../../General/WuStack";
import {ExpandMoreRounded} from "@material-ui/icons";
import {Typography} from "@material-ui/core";

export default function SideMainMenu(props) {
    const {svgIcon = '', name, child = [], drawerExpanded} = {...props}
    const [expanded, setExpanded] = useState(false);
    const [previousState, setPreviousState] = useState(false);

    const handleExpandCollapseAction = () => {
        setExpanded(current => !current)
    }

    useEffect(() => {
        if (!drawerExpanded) {
            setPreviousState(expanded)
            setExpanded(false)
        } else {
            setExpanded(previousState)
        }
    }, [drawerExpanded])

    return (
        <li>
            <NavigationHigherOrderComponent onClick={handleExpandCollapseAction} {...props}>
                <WuStack justifyContent={'space-between'} alignItems={'center'}>
                    <WuStack spacing={1} alignItems={'center'}>
                        {svgIcon}
                        <div style={drawerExpanded ? {display: 'block'} : {display: 'none'}}>
                            <Typography variant={'subtitle1'} style={{fontWeight: 600}}>{name}</Typography>
                        </div>
                    </WuStack>
                    {child.length > 0 && drawerExpanded && <ExpandCollapse expanded={expanded}/>}
                </WuStack>
            </NavigationHigherOrderComponent>
            {
                child.length > 0 && <SideSubMenu child={child} expanded={expanded} drawerExpanded={drawerExpanded}/>
            }
        </li>
    )
}


function NavigationHigherOrderComponent(props) {
    const {url, child = [], onClick, children} = {...props}
    if (child.length <= 0) return <NavLink to={url} onClick={onClick}>{children}</NavLink>
    else return <div className={'navigation-no-submenu'} onClick={onClick}>{children}</div>
}


function ExpandCollapse({expanded}) {
    const style = {
        transform: 'rotate(180deg)',
        transition: '0.3s'
    }

    const general = {
        transition: '0.3s'
    }
    return <ExpandMoreRounded style={expanded ? style : general}/>
}