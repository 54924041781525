import React from 'react';
import {Box, Paper} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import MostSpendServiceGraph from "./MostSpendServiceGraph";


export default function MostSpendService({serviceSpend}) {
    return (
        <Box m={2}>
            <Paper variant={'outlined'} className={'total-spend-card-wrapper'}>
                <Box m={2}>
                    <Box>
                        <Typography>
                            Top spend 5 Services for organization
                        </Typography>
                    </Box>
                    <Box display={'flex'} className={'gap'}>
                        {
                            serviceSpend.map((service, index) => {
                                return <MostSpendServiceItem serviceData={service} key={index}/>
                            })
                        }
                    </Box>
                </Box>
            </Paper>
        </Box>
    )
}


function MostSpendServiceItem({serviceData}) {
    return (
        <Box className={'w-100'} mt={2}>
            <Paper variant="outlined" className={'w-100'} style={{background: 'white'}}>
                <Box m={2} mt={2}>
                    <Box>
                        <Box ml={2}>
                            <Typography gutterBottom variant={'body1'}>
                                {serviceData.organizationAlias ? serviceData.organizationAlias : serviceData.organizationName}
                            </Typography>
                        </Box>

                        <MostSpendServiceGraph graphDataItem={serviceData}/>
                    </Box>
                </Box>
            </Paper>
        </Box>
    )
}



