import React, {useEffect, useState} from "react";
import "./landing_page.scss";
import SpendSummary from "./SpendSummary/SpendSummary";
import service from "../../service/service";
import {convertArrayOfOrganizationToArrayOfAccountList} from "./utilities";

import SpendCurrentMonth from "./SpendCurrentMonth/SpendCurrentMonth";
import Box from "@material-ui/core/Box";
import {Savings} from "./Savings/Savings";
import MostSpend from "./MostSpend/MostSpend";
import {Collapse} from "@material-ui/core";
import graphLoading from '../../assets/images/loading_spinner.gif';

//dummy data
import costExplorerSummary from "../../assets/finthesys/dashboard-cost-and-usage.json";

export default function LandingPage() {
    const [costSummary, setCostSummary] = useState([]);
    const [trendGraph, setTrendGraph] = useState([]);
    const [organizationList, setOrganizationList] = useState([])
    const [currentSpend, setCurrentSpend] = useState([]);
    const [savingPercentage, setSavingPercentage] = useState(undefined);

    const [loading, setLoading] = useState(false);
    const [ready, setReady] = useState(false);
    const [organizationReady, setOrganizationReady] = useState(false);
    const [error, setError] = useState({error: false, message: undefined})

    useEffect(() => {
        fetchData()
    }, []);


    const fetchData = () => {
        setOrganizationReady(false)
        setLoading(true);
        setError({error: false, message: undefined})
        setReady(false);
        service.account().getOrganizationList()
            .then((res) => {
                setOrganizationList(res.data.organizations)
                setOrganizationReady(true)
                return res.data.organizations
            })
            /*.then((organizationData) => {
                let params = {organization_ids: convertArrayOfOrganizationToArrayOfAccountList(organizationData)}
                service.dashboard().spendSummary(params)
                    .then((res) => {
                        setCurrentSpend(res.data.data)
                        setReady(true)
                        setLoading(false);
                    });
            })*/
            .then((organizationData) => {
                setTimeout(() => {
                    setCurrentSpend(costExplorerSummary.data)
                    setReady(true)
                    setLoading(false);
                }, 2500)

            })
            .catch((err) => {
                setError({
                    error: true,
                    message: err.message
                })
                setLoading(false);
            })
    }

    return (
        <Box className="landing-container">
            <Collapse in={ready} timeout={1000}>
                {ready &&
                <SpendCurrentMonth costOptimizationPercentage={savingPercentage} organizationSpendData={currentSpend}
                                   graph={trendGraph}/>}
            </Collapse>

            {organizationReady &&
            <React.Fragment>
                <Box m={2} className={"gap"} display={"flex"}>
                    <SpendSummary organizationList={organizationList}
                                  onChange={(e) => setTrendGraph(e)}/>
                    <Savings organizationList={organizationList}
                             onChange={e => setSavingPercentage(e)}
                             totalSpendAmount={ready ? currentSpend.currentMonthForecastedAmount : 0}/>
                </Box>
                <MostSpend organizationList={organizationList}/>
            </React.Fragment>
            }
        </Box>
    );
}


function LoadingGraphs() {
    return (
        <Box display={'flex'} alignContent={'center'} justifyContent={'center'}>
            <img style={{height: 380, opacity: '0.5'}} src={graphLoading} alt={'wait-graphics-finops'}/>
        </Box>
    )

}

export {LoadingGraphs}