import {useState} from 'react';


export default function useForm(initialValues) {
    const [state, setState] = useState(initialValues)
    return ([
            state, (key, value) => setState({
                ...state, [key]: value
            })
        ]
    )
}