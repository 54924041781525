import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from "@material-ui/core/Box";

/**
 *
 * @param props
 * @param {string} props.subTitle - subtitle
 * @param {string} props.title - title
 * @param {string} props.buttonName - name of the action button
 * @param {string} props.type - type of report
 * @param {string} props.name - name of button
 * @param {boolean} props.disabled - name of button
 * @returns {JSX.Element}
 * @constructor
 */
export default function ReportGenerateCard(props) {
    const {subTitle, title, buttonName, type, id, name = "", disabled} = {...props}
    let color = '#fff'
    switch (type) {
        case 'cost':
            color = '#ff6767'
            break;
        case 'tag':
            color = '#67ffab'
            break;
        case 'service':
            color = '#7167ff'
            break;
        case 'custom':
            color = '#ff67ae'
            break;
        default:
            color = '#fff'
    }
    const borderProperty = {borderLeft: '5px solid ' + color, height: '100%', position: 'relative'}

    const handleClick = (event) => {
        props.onClick(event)
    }

    return (
        <Card className='report-card-main' variant="outlined">
            <Box style={borderProperty}>
                <CardContent>
                    <Typography color="textSecondary" variant={"body2"} gutterBottom>
                        {subTitle.toUpperCase()}
                    </Typography>
                    <Typography variant="h6" component="h2">
                        {title}
                    </Typography>
                </CardContent>
                <Box style={{marginBottom: 40}}>
                </Box>
                <CardActions style={{bottom: 0, position: 'absolute'}} className={'action-button'}>
                    <Button disabled={disabled} name={name} id={id} size="small"
                            onClick={handleClick}>{buttonName}</Button>
                </CardActions>
            </Box>
        </Card>
    );
}