import React, {useEffect, useRef, useState} from "react";
import TextField from "@material-ui/core/TextField";
import {Checkbox, FormControlLabel, FormGroup, InputAdornment, InputLabel, OutlinedInput} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import {Accounts, Region} from "../../../General/WuAutoComplete";
import Typography from "@material-ui/core/Typography";

export default function EnvironmentAccountRelation(props) {

    const {application, idx} = {...props}

    const [isMount, setIsMount] = useState(true);
    const [state, setState] = useState(application.Environments);
    const [accountRegionBudget, setAccountRegionBudget] = useState({
        account: application.Account_AccountId,
        region: application.Region_RegionCode,
        budgetAmount: application.BudgetAmount,
        notificationSubscribers: application.BudgetNotificationSubscribers

    });

    const handleEnvChange = (event) => {
        setState({...state, [event.target.name]: event.target.checked});
    };
    const handleChange = (e, name) => {
        if (e !== undefined) {
            setAccountRegionBudget({...accountRegionBudget, [name]: e});
        }
    }


    useEffect(() => {
        if (isMount) {
            setIsMount(false);
            return;
        }

        props.onChange({
            Account_AccountId: accountRegionBudget.account,
            Region_RegionCode: accountRegionBudget.region,
            BudgetAmount: accountRegionBudget.budgetAmount,
            BudgetNotificationSubscribers: accountRegionBudget.notificationSubscribers,
            Environments: state,
            idx: idx
        })
    }, [state, accountRegionBudget])

    return (
        <div className="environment-account-wrapper">
            <Typography variant={"subtitle2"}> Account and Environment(*) </Typography>
            <div className={"d-f"}>
                <Region defaultValue={accountRegionBudget.region} onChange={(e) => handleChange(e.region, "region")}/>
                <Accounts
                    masterAccount={477419074820}
                    defaultValue={accountRegionBudget.account}
                    onChange={(e) => handleChange(e.accountId, "account")}/>

                <FormGroup row style={{flexWrap: "nowrap"}}>
                    <FormControlLabel
                        control={<Checkbox checked={state.DEV} onChange={handleEnvChange}
                                           name="DEV"/>}
                        label="DEV"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.QA}
                                onChange={handleEnvChange}
                                name="QA"
                            />
                        }
                        label="QA"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={state.STAGE} onChange={handleEnvChange}
                                           name="STAGE"/>}
                        label="STAGE"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={state.UAT} onChange={handleEnvChange}
                                           name="UAT"/>}
                        label="UAT"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={state.PROD} onChange={handleEnvChange}
                                           name="PROD"/>}
                        label="PROD"
                    />
                </FormGroup>
            </div>
            <Typography variant={"subtitle2"}> Budget (Optional) </Typography>
            <div className={"d-f"}>
                <FormControl fullWidth margin={"dense"} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                    <OutlinedInput
                        value={accountRegionBudget.budgetAmount}
                        id="outlined-adornment-amount"
                        onChange={(e) => handleChange(e.target.value, "budgetAmount")}
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        labelWidth={60}
                    />
                </FormControl>
                <TextField type={"email"}
                           name={"budgetNotificationSubscribers"}
                           value={accountRegionBudget.notificationSubscribers}
                           onChange={(e) => handleChange(e.target.value, "notificationSubscribers")}
                           fullWidth
                           id={`${idx}budgetNotificationSubscribers`}
                           label="Notification Subscribers"
                           margin="dense"
                           variant="outlined"/>
            </div>
        </div>
    )
}