import ReactApexChart from "react-apexcharts";

export default function DonutGraph({colorPalette = 'palette1', labels = [], series = []}) {

    const state = {

        series: series,
        options: {
            labels: labels,
            chart: {
                type: 'donut',
                fontFamily: 'Century Gothic, sans-serif'
            },
            theme: {
                palette: colorPalette,
            },
            dataLabels: {
                enabled: true,
                style: {
                    fontWeight: 'normal',
                    fontSize: '10px'
                }
            }
        },
    }

    return (
        <ReactApexChart options={state.options} series={state.series} type="donut"/>
    )
}