import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {DialogActions} from "@material-ui/core";
import Box from "@material-ui/core/Box";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6" component={'div'}>
                <Box display={'flex'} gridGap={16} alignItems={'center'}>
                    {children}
                </Box>
            </Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(0),
    },
}))(MuiDialogContent);

/**
 *
 * @param props
 * @param {string | JSX.Element} props.title - dialog title
 * @param {JSX.Element} props.children -  inner html element
 * @param {React.ReactNode} props.titleNode -  inner react node in header
 * @param {string} props.size - dialog size
 * @param {function} props.onChange - change event
 * @param {boolean} [props.fullScreen = false] - full screen or not
 * @param {boolean} props.open - dialog state
 * @param {boolean} props.suppressDialogAction - remove the empty space reserved for dialog action
 * @return {JSX.Element}
 * @constructor
 */
export default function WuDialog(props) {
    const {
        title,
        children,
        size,
        open,
        titleNode,
        fullScreen = false,
        suppressDialogAction = false,
        onChange
    } = {...props}

    const handleClose = () => {
        onChange(false);
    };

    return (
        <Dialog fullWidth maxWidth={size} open={open} scroll={'paper'} fullScreen={fullScreen}>
            <DialogTitle onClose={handleClose}>
                {title} {titleNode}
            </DialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
            {!suppressDialogAction && <DialogActions style={{height: 30}}/>}
        </Dialog>
    );
}