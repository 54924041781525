import {LabelWithHelpText, WuButton, WuTextField} from "../../General";
import {Box, IconButton, Typography} from "@material-ui/core";
import {AddCircleRounded, DeleteOutlined} from "@material-ui/icons";
import {useEffect} from "react";

export function AdditionalTagAdd(props) {
    const {value, onChange, onApply} = {...props}

    const handleChange = (source, newValue, id) => {
        onChange(value.map(e => {
            if (e.id === id) e[source] = newValue
            return e
        }))
    }

    const handleDelete = (id) => {
        onChange(value.filter(e => e.id !== id))
    }

    const handleAdd = () => {
        const newId = value.reduce((acc, curr) => {
            if (acc < curr.id) return curr.id
            return acc
        }, 0) + 1
        onChange([...value, {id: newId, tagKey: '', tagValue: ''}])
    }

    useEffect(() => {
        // reset all finops tags in confirm tag
        if (value.length === 0) {
            onApply()
        }
    }, [value])

    return (
        <div className={'at-additional-tag-option'}>
            <LabelWithHelpText
                title={'Additional tags (optional)'}
                helpText={'Add additional tag and values for tagging'}/>
            <Typography>
                Tags can help you manage, identify, organize, search for, and filter resources.
                You can create/update non-finops tags from here
            </Typography>
            {
                value.map(e => <SingleOption {...e} onChange={handleChange} onDelete={handleDelete} key={e.id}/>)
            }
            <Box mt={1} ml={-0.5}>
                <WuButton size={'small'}
                          startIcon={<AddCircleRounded fontSize={'small'} style={{marginTop: -1}}/>}
                          color={'secondary'}
                          onClick={handleAdd}
                          variant={'text'}>
                    NEW TAG VALUE
                </WuButton>
                {value.length > 0 && <WuButton
                    size={'small'}
                    style={{marginLeft: 16}}
                    variant={'outlined'}
                    onClick={onApply}
                    color={'secondary'}>
                    Apply
                </WuButton>}
            </Box>
        </div>
    )
}

function SingleOption({tagKey, tagValue, id, onChange, onDelete}) {

    const handleChange = (e) => {
        onChange(e.target.name, e.target.value, id)
    }

    return (
        <div className={'at-ato-single'}>
            <WuTextField label={'Tag Key'} value={tagKey} name={'tagKey'} onChange={handleChange}/>
            <WuTextField label={'Tag Value'} value={tagValue} name={'tagValue'} onChange={handleChange}
                         disabled={!tagKey}/>
            <IconButton size={'small'} style={{height: 32, width: 32}} onClick={() => onDelete(id)}>
                <DeleteOutlined/>
            </IconButton>
        </div>
    )
}