import React from "react";
import './automation.scss';
import {AutomationRoute} from "../../routes/MainRoute";
import PageTitle from "../General/PageTitle/PageTitle";
import CustomTab from "../General/CustomTab/CustomTab";

const tabList = [
    {
        link: "/automations/power-schedule",
        name: "Power Schedules"
    },
    /*{
        link: "/automations/auto-tagging",
        name: "Auto tagging"
    }*/
]

export default function Automation() {
    return (
        <div>
            <div className="page-title-wrapper">
                <PageTitle title={"Power Schedules"}/>
            </div>
            <CustomTab links={tabList}/>
            <div className="title-div-wrapper">
                <AutomationRoute/>
            </div>
        </div>
    )
}