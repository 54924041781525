import React, {useEffect, useState} from "react";
import {Button, Popover} from "@material-ui/core";
import {Accounts} from "../../../General/WuAutoComplete";
import {AddOutlined} from "@material-ui/icons";
import {Box} from "@material-ui/core";
import WuButton from "../../../General/WuButton";

export default function AddNewAccount({onAccountChange}) {
    const [anchorEl, setAnchorEl] = useState(null)
    const [account, setAccount] = useState(null)
    const [error, setError] = useState(false)


    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleChange = (_, value) => {
        setAccount(value)
        setError(false)
    }

    const onApplyChanges = () => {
        if (account) {
            onAccountChange(account)
            setAnchorEl(null)
        } else {
            setError(true)
        }
    }

    useEffect(() => {
        if (anchorEl) {
            setAccount(null)
            setError(null)
        }
    }, [anchorEl])

    return (
        <div>
            <Button size={'small'} startIcon={<AddOutlined/>}
                    variant={'outlined'}
                    onClick={(e) => setAnchorEl(e.currentTarget)}>
                New account
            </Button>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box p={2} width={'300px'}>
                    <Accounts autoCompleteProps={{onChange: handleChange}} error={error}/>
                    <Box mt={1} display={'flex'} gridGap={8}>
                        <WuButton size={'small'}
                                  variant={'contained'}
                                  onClick={onApplyChanges}
                                  color={'secondary'}>
                            Add
                        </WuButton>
                        <WuButton size={'small'}
                                  variant={'outlined'}
                                  onClick={handleClose}
                                  color={undefined}>
                            Cancel
                        </WuButton>
                    </Box>
                </Box>
            </Popover>
        </div>

    )
}