export function autoSizeColumnsBasedOnData(gridApi, gridColumnApi) {
    let allColumnIds = [];
    gridColumnApi.getAllColumns().forEach(function ({colId}) {
        allColumnIds.push(colId);
    });
    gridColumnApi.autoSizeColumns(allColumnIds, false);
}

export function autoSizeGridColumns(gridApi, gridColumnApi) {
    let gridWidth = document.getElementById('grid-wrapper').offsetWidth;
    let columnsToShow = [];
    let columnsToHide = [];
    let totalColsWidth = 0;

    let allColumns = gridColumnApi.getAllColumns();
    for (const {actualWidth, colId} of allColumns) {
        totalColsWidth += actualWidth;
        if (totalColsWidth > gridWidth) {
            columnsToHide.push(colId);
        } else {
            columnsToShow.push(colId);
        }
    }

    if (totalColsWidth < gridWidth) {
        gridColumnApi.setColumnsVisible(columnsToShow, true);
        gridColumnApi.setColumnsVisible(columnsToHide, false);
        gridApi.sizeColumnsToFit();
    }
}

export function paginationChange(gridApi){
    if (gridApi) {
        console.log('pagenation Change')
/*        setText('#lbLastPageFound', gridApi.paginationIsLastPageFound());
        setText('#lbPageSize', gridApi.paginationGetPageSize());
        setText('#lbCurrentPage', gridApi.paginationGetCurrentPage() + 1);
        setText('#lbTotalPages', gridApi.paginationGetTotalPages());
        setLastButtonDisabled(!gridApi.paginationIsLastPageFound());*/
    }
}