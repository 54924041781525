import React from "react";
import {withStyles} from "@material-ui/core/styles";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import propTypes from "prop-types";
import WuAccordionWrapper from "./WuAccordionWrapper";

const AccordionDetails = withStyles((theme) => ({
    root: {
        overflow: 'auto',
        //width: '100%',
        margin: 0,
        padding: 0
    },
}))(MuiAccordionDetails);

export default function WuAccordionDetails(props) {
    const {children} = {...props}
    return (
        <AccordionDetails>
            {children}
        </AccordionDetails>
    )
}

WuAccordionWrapper.propTypes = {
    children: propTypes.node
}
