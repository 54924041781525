import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {withStyles} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import service from "../../../service/service";
import JSONPretty from "react-json-pretty";
import JSONPrettyMon from "react-json-pretty/dist/monikai";
import {AlertWu} from "../../AlertWu/AlertWu";
import {ProgressCircleCustomSize} from "../../ProgressCircle/ProgressCircle";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import {useOrganization} from "../../../hooks/useOrganization";
//dummy data
import test from '../../../assets/finthesys/tag-policy-content.json';

const useStyles = makeStyles({
    root: {
        flexGrow: 0,
        marginBottom: 20,
    },
});

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});


const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        marginBottom: -1,
        minHeight: 50,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);


export default function PolicySummary(props) {
    const {organization: masterAccountId} = useOrganization();
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [errorBoundary, setErrorBoundary] = React.useState(false);
    const [error, setError] = React.useState("");
    const [policySummary, setPolicySummary] = React.useState(test);
    const [policyContent, setPolicyContent] = React.useState(JSON.parse(test.data.Policy.Content));
    const [value, setValue] = useState(0);
    const classes = useStyles();

    const handleClickOpen = () => {
        setOpen(true);
        const params = {
            policyId: props.policyId,
            master_account_id: masterAccountId
        };
        setLoading(true);
        setError("");
        setErrorBoundary(false);
        /*service.guardRails().getTagPolicyDescription(params)
            .then((ref) => {
                setLoading(false);
                setPolicySummary(ref.data);
                setPolicyContent(JSON.parse(ref?.data?.data?.Policy?.Content));
            })
            .catch((err) => {
                setLoading(false);
                setErrorBoundary(true);
                setError(err.message);
            });*/
        setTimeout(() => {
            setLoading(false);
            setPolicySummary(test);
            setPolicyContent(JSON.parse(test.data.Policy.Content));
        }, 2000)
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function TagValues(props) {
        if (props.tagKeys !== undefined) {
            if (props.tagKeys['@@assign']) {
                return (
                    <ul>
                        {props?.tagKeys['@@assign']?.map((tagValues) => {
                            return (
                                <li key={tagValues}>
                                    {tagValues}
                                </li>
                            )
                        })
                        }
                    </ul>
                )
            } else {
                return "no values assigned"
            }
        } else {
            return "no values assigned"
        }
    }

    function JsonSummary() {
        return (
            <article className="tag-value">
                <div style={{width: '100%'}}>
                    <p>Enforced tag keys</p>
                    {
                        Object.keys(policyContent?.tags).map(function (tagKeys, index) {
                            return (
                                <Accordion
                                    key={index}>
                                    <AccordionSummary>
                                        {tagKeys}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {
                                            <TagValues tagKeys={policyContent?.tags[tagKeys]?.tag_value}/>
                                        }
                                    </AccordionDetails>
                                </Accordion>
                            );
                        })
                    }
                </div>
            </article>
        );
    }

    return (
        <div>
			<span className="summary" onClick={handleClickOpen}>
				Policy Summary
			</span>
            <Dialog
                fullWidth
                maxWidth={"lg"}
                onClose={handleClose}
                aria-labelledby="tag-value"
                open={open}
            >
                <DialogTitle id="tag-policy" onClose={handleClose}>
                    Policy Summary
                </DialogTitle>
                <DialogContent dividers>
                    {!errorBoundary ? (
                        !loading ? (
                            <div>
                                <div className="item-list">
                                    <span>Name</span>:{" "}
                                    {
                                        policySummary?.data?.Policy?.PolicySummary?.Name
                                    }
                                </div>
                                <div className="item-list">
                                    <span>Id</span> :{" "}
                                    {
                                        policySummary.data?.Policy?.PolicySummary?.Id
                                    }
                                </div>
                                <div className="item-list">
                                    <span>Description</span> :{" "}
                                    {
                                        policySummary.data?.Policy?.PolicySummary?.Description
                                    }
                                </div>
                                <div className="item-list">
                                    <span>Policy Arn</span> :{" "}
                                    {
                                        policySummary?.data?.Policy?.PolicySummary?.Arn
                                    }
                                </div>
                                <div className="policy-preview">
                                    <Paper
                                        className={classes.root}
                                        style={{width: "100%"}}
                                    >
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            indicatorColor="secondary"
                                            textColor="secondary"
                                        >
                                            <Tab label="Summary"/>
                                            <Tab label="JSON"/>
                                        </Tabs>
                                    </Paper>

                                    <strong>Policy Content</strong>

                                    {value === 1 ? (
                                        <JSONPretty
                                            id="json-pretty"
                                            theme={JSONPrettyMon}
                                            data={
                                                policySummary?.data?.Policy?.Content
                                            }
                                        />
                                    ) : (
                                        <div style={{paddingTop: 10, width: '100%'}}>
                                            <JsonSummary/>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <ProgressCircleCustomSize size={16}/>
                        )
                    ) : (
                        ""
                    )}

                    {
                        errorBoundary && (
                            <div style={{paddingTop: 20}}>
                                <AlertWu
                                    severity="error"
                                    message={
                                        "Something went wrong... Error type: " +
                                        error
                                    }
                                />
                            </div>
                        )
                    }
                </DialogContent>
            </Dialog>
        </div>
    );
}
