import React from "react";
import {withStyles} from "@material-ui/core/styles";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import propTypes from "prop-types";
import WuAccordionWrapper from "./WuAccordionWrapper";


const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

export default function WuAccordionSummary(props) {
    const {children} = {...props}
    return (
        <AccordionSummary expandIcon={<ExpandMoreIcon/>} {...props}>
            {children}
        </AccordionSummary>
    )
}


WuAccordionWrapper.propTypes = {
    children: propTypes.node
}
