import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import AlertTitle from "@material-ui/lab/AlertTitle";
import './alert_wu.scss';
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

function AlertWu(props) {
    const {alertOpen, children, title, severity, message} = {...props}
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Collapse in={alertOpen ? alertOpen.state : true}>
                <Alert
                    severity={severity}
                    action={children}>
                    <AlertTitle>{title ? title : severity.toUpperCase()}</AlertTitle>
                    {message}
                </Alert>
            </Collapse>
        </div>
    );
}

AlertWu.propTypes = {
    alertOpen: PropTypes.shape({
        state: PropTypes.bool.isRequired
    }),
    severity: PropTypes.oneOf(['error', 'success', 'warning', 'info']),
    children: PropTypes.element,
    title: PropTypes.string,
    message: PropTypes.string
}

function FullScreenAlert(props) {
    return (
        <section className="f-s-a">
            <div className="f-s-a-wrapper">
                Test
            </div>
        </section>
    )
}

export {AlertWu, FullScreenAlert}