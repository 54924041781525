import ECSMain from "./Main";
import Summary from "./Summary";
import './index.scss'

export default function ECS() {
    return (
        <div className={'ecs-container'}>
            <ECSMain/>
            <Summary/>
        </div>
    )
}