import finOpsLogo from '../../../assets/images/finops_logo.png'
import OrganizationSelect from "./OrganizationSelect";

export default function Header(props) {

    return (
        <header className={'rt-header rt-standard-padding'}>
            <div className={'rt-organization-title'}>
                <img src={finOpsLogo} alt={'finops-brand-log'}/>
            </div>
            <OrganizationSelect {...props}/>
        </header>
    )
}