import {Paper, Typography} from "@material-ui/core";
import ReactApexChart from "react-apexcharts";

export default function PriceDistributionGraph({title, data, height = '400px'}) {

    const state = {
        series: [
            {
                name: title,
                data: data
            }
        ],
        grid: {
            show: false
        },
        options: {
            colors:['#ff9a00'],
            chart: {
                fontFamily: 'Century Gothic',
                fontSize: '16px',
                toolbar: {
                    show: false
                },
                type: 'line',
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: "datetime"
            },
            yaxis: {
                labels: {
                    formatter: (value) => {
                        return `$${Number(value || 0).toFixed(2)}`
                    }
                }
            },
        },
    };

    return (
        <Paper variant={'outlined'}
               style={
                   {
                       paddingLeft: 8,
                       paddingRight: 16,
                       paddingTop: 16,
                       width: 'auto',
                       height: '100%',
                       boxSizing: 'border-box'
                   }
               }>
            <Typography style={{marginLeft: 20}}>{title}</Typography>
            <ReactApexChart options={state.options} series={state.series} type="line" height={height}/>
        </Paper>
    )
}