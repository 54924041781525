import Box from "@material-ui/core/Box";
import {ProgressCircleCustomSize} from "../ProgressCircle/ProgressCircle";
import {Collapse, Fade, Typography} from "@material-ui/core";
import React from "react";

export default function WuLoading({message, open, boxProps}) {
    return (
        <Collapse in={open}>
            <Fade in={open} timeout={600}>
                <Box className={'gap'} display={'flex'} alignItems={'center'} {...boxProps}>
                    <ProgressCircleCustomSize size={16}/>
                    <Typography>
                        {message}
                    </Typography>
                </Box>
            </Fade>
        </Collapse>
    )
}