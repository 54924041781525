import React, {useState} from "react";
import {Button, Typography} from "@material-ui/core";
import {Table} from "../General/Table";
import {AddResourceTag} from "./AddResourceTag";


export default function ResourceTagSetting() {

    const [newTagKey, setNewTagKey] = useState(false);
    const rowData = [
        {
            tagKey: "ApplicationName",
            tagType: "String",
            comment: "Application Name information"
        },
        {
            tagKey: "CostCode",
            tagType: "Number",
            comment: "Cost Center Information"
        }
    ]

    return (
        <div>
            <Typography>
                A tag is a label that you assign to an Cloud resource. Each tag consists of a key and an optional value,
                both of which you define.
            </Typography>
            <Typography style={{fontWeight: 900}}>
                The Tag keys added here will be reflected all over in the AWS resource with the onboarding information
                provided
            </Typography>
            <Typography>
                Tags enable you to categorize your AWS resources in different ways, for example, by purpose, owner, or
                environment. For example, you could define a set of tags for your account's Amazon EC2 instances that
                helps you track each instance's owner and stack level.
            </Typography>

            <Table title={"Active Resource Tags"} tableData={rowData}/>
            <Button variant={"contained"} disableElevation onClick={() => setNewTagKey(true)} color={"primary"}>
                New Tag Key
            </Button>
            {
                newTagKey &&
                <AddResourceTag/>
            }
        </div>
    )
}


