import React, {useEffect, useState} from 'react';
import AdhocQuery from "../../AdHocQuery/AdHocQuery";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import "./cost_and_usage.scss";
import service from "../../../service/service";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReportPreview from "../ReportPreview/ReportPreview";
import CustomReport from "./CustomReport/CustomReport";
import {useOrganization} from "../../../hooks/useOrganization";
import ReportType from "./ReportType";
import Box from "@material-ui/core/Box";
import ReportGenerateCard from "../ReportGenerateCard/ReportGenerateCard";

const useStyles = makeStyles({
    root: {
        flexGrow: 0,
    },
});


export default function CostAndUsage() {
    const organization = useOrganization()

    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [activeReport, setActiveReport] = useState("");
    const [preview, setPreview] = useState(false);
    const [previewData, setPreviewData] = useState([]);
    const [masterAccountId, setMasterAccountId] = useState(organization.organization);
    const [openAdhoc, setOpenAdhoc] = useState(false);
    const [activeReportCategory, setActiveReportCategory] = useState('cost');

    useEffect(() => {
        setMasterAccountId(organization.organization)
    }, [organization])

    const handleClick = (event) => {
        if (event.currentTarget.id === "adHoc") {
            setOpenAdhoc(true);
        } else {
            setLoading(true);
            setActiveReport(event.currentTarget.id)
            const body = {
                "query_type": event.currentTarget.id,
                "ad_hoc": "false",
                "account_id": masterAccountId.toString()
            }
            service.masterBillingReport().generateReport(body)
                .then(res => {
                    setPreviewData(res.data)
                    setLoading(false);
                    setPreview(true);
                })
                .catch(err => {
                    setLoading(false);
                })
        }
    }

    function ReportItem(props) {
        return (
            <div className="heading">
                <div className="a-i-c">
                    <span className="points">{props.reportPriority}</span>
                    {props.reportName}
                </div>
                <Button disableElevation style={{width: 100}} id={props.buttonId} variant="contained" color="primary"
                        onClick={handleClick}>
                    {props.buttonName}
                </Button>
            </div>
        )
    }


    const handleBillingInventoryClick = (event) => {
        setLoading(true);
        service.billingInventoryReport().generateBillingInventoryReport({"account_id": masterAccountId.toString()})
            .then(res => {
                setPreviewData(res.data);
                setActiveReport('BillingInventory');
                setLoading(false);
                setPreview(true);
            })
            .catch(err => {
                setLoading(false);
            })
    }

    const handlePreviewClose = () => {
        setPreview(false);
    }

    function LoadingSnackBar(message) {
        return (
            <Snackbar
                message={message.message}
                open={loading}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                action={
                    <React.Fragment>
                        <CircularProgress style={{marginRight: 10}} size={24} color="primary"/>
                    </React.Fragment>
                }
            />
        )
    }


    return (
        <div>
            {preview && <ReportPreview onClick={handlePreviewClose} title={activeReport} data={previewData}/>}
            <LoadingSnackBar message={"Generating report..."}/>
            <div className="main_header">
                <div className="report-list">
                    {/*<div className="heading">
                        <div className="a-i-c">
                            <span className="points">{1}</span>
                            Linked Account Billing
                        </div>
                        <Button disableElevation style={{width: 100}} id="billing_inventory" variant="contained"
                                color="primary"
                                onClick={handleBillingInventoryClick}>
                            Generate
                        </Button>
                    </div>

                    <ReportItem
                        reportName="Usage By Account"
                        buttonName="Generate"
                        buttonId="UBA"
                        reportPriority={2}
                    />
                    <ReportItem
                        reportName="Usage By Service"
                        buttonName="Generate"
                        buttonId="UBS"
                        reportPriority={3}
                    />
                    <ReportItem
                        reportName="Usage By Account/Service"
                        buttonName="Generate"
                        buttonId="UBAS"
                        reportPriority={4}
                    />
                    <ReportItem
                        reportName="Most Expensive 25 Resources"
                        buttonName="Generate"
                        buttonId="TER"
                        reportPriority={5}
                    />
                    <ReportItem
                        reportName="Most Expensive 25 Lambda"
                        buttonName="Generate"
                        buttonId="TEL"
                        reportPriority={6}
                    />
                    <ReportItem
                        reportName="Most Expensive 25 EC2"
                        buttonName="Generate"
                        buttonId="TEEC"
                        reportPriority={7}
                    />
                    <ReportItem
                        reportName="Most Expensive 25 RDS"
                        buttonName="Generate"
                        buttonId="TERDS"
                        reportPriority={8}
                    />
                    <ReportItem
                        reportName="Most Expensive 25 CloudWatch Log Group"
                        buttonName="Generate"
                        buttonId="TECWG"
                        reportPriority={9}
                    />
                    <ReportItem
                        reportName="Most Expensive 25 Dynamo Tables"
                        buttonName="Generate"
                        buttonId="TED"
                        reportPriority={10}
                    />
                    <ReportItem
                        reportName="Most Expensive 25 S3 Buckets"
                        buttonName="Generate"
                        buttonId="TES"
                        reportPriority={11}
                    />
                    <ReportItem
                        reportName="Most Expensive 25 S3 Bucket Operations"
                        buttonName="Generate"
                        buttonId="TESO"
                        reportPriority={12}
                    />
                    <ReportItem
                        reportName="Most Expensive 25 VPC"
                        buttonName="Generate"
                        buttonId="TEV"
                        reportPriority={13}
                    />
                    <ReportItem
                        reportName="Most Expensive 25 Config"
                        buttonName="Generate"
                        buttonId="TEC"
                        reportPriority={14}
                    />

                    <Paper className={classes.root + " adhoc-class"} style={{width: "100%"}}>
                        <p>
                            Need something else? Build custom report
                        </p>
                    </Paper>

                    <ReportItem
                        reportName="Custom Report"
                        buttonName="BUILD"
                        buttonId="adHoc"
                        reportPriority={15}
                    />*/}

                    <Box display={"flex"} className={"gap"} flexWrap={'wrap'}>
                        <div
                            onClick={() => setActiveReportCategory('cost')}
                            className={activeReportCategory === 'cost' ? 'report-type-cur report-type-cur-cost' : 'report-type-cur'}>
                            <ReportType
                                title={'Cost Reports'}
                                subTitle={'Different cost reports'}
                                numberOfReports={4}/>
                        </div>

                        <div
                            onClick={() => setActiveReportCategory('most-spend')}
                            className={activeReportCategory === 'most-spend' ? 'report-type-cur report-type-cur-service' : 'report-type-cur'}>
                            <ReportType
                                title={'Top Spend Resources'}
                                subTitle={'Top costing Resources'}
                                numberOfReports={10}/>
                        </div>

                        <div
                            onClick={() => setActiveReportCategory('custom')}
                            className={activeReportCategory === 'custom' ? 'report-type-cur report-type-cur-custom' : 'report-type-cur'}>
                            <ReportType
                                title={'Custom Report'}
                                subTitle={'Build your custom report'}
                                numberOfReports={1}/>
                        </div>
                    </Box>

                    <Box display={"flex"} mt={4} className={"gap"} flexWrap={'wrap'}>
                        {
                            activeReportCategory === 'cost' &&
                            <>
                                <ReportGenerateCard subTitle={'detailed'}
                                                    id="billing_inventory"
                                                    title={'Top Consumed Resources In Linked Account'}
                                                    type={'cost'}
                                                    disabled={loading}
                                                    onClick={handleBillingInventoryClick}
                                                    buttonName={'Generate'}/>

                                <ReportGenerateCard subTitle={'SUMMARY'}
                                                    id="UBA"
                                                    title={'Linked Account Billing'}
                                                    type={'cost'}
                                                    disabled={loading}
                                                    onClick={handleClick}
                                                    buttonName={'Generate'}/>

                                <ReportGenerateCard subTitle={'SUMMARY'}
                                                    id="UBS"
                                                    title={'Service Billing'}
                                                    type={'cost'}
                                                    disabled={loading}
                                                    onClick={handleClick}
                                                    buttonName={'Generate'}/>

                                <ReportGenerateCard subTitle={'SUMMARY'}
                                                    id="UBAS"
                                                    title={'Service/Account Grouped '}
                                                    type={'cost'}
                                                    disabled={loading}
                                                    onClick={handleClick}
                                                    buttonName={'Generate'}/>
                            </>
                        }
                        {
                            activeReportCategory === 'most-spend' &&
                            <>
                                <ReportGenerateCard subTitle={'SUMMARY'}
                                                    id="TER"
                                                    title={'Most Expensive 25 Resources'}
                                                    type={'service'}
                                                    disabled={loading}
                                                    onClick={handleClick}
                                                    buttonName={'Generate'}/>

                                <ReportGenerateCard subTitle={'SUMMARY'}
                                                    id="TEL"
                                                    title={'Most Expensive 25 Lambda Functions'}
                                                    type={'service'}
                                                    disabled={loading}
                                                    onClick={handleClick}
                                                    buttonName={'Generate'}/>

                                <ReportGenerateCard subTitle={'SUMMARY'}
                                                    id="TEEC"
                                                    title={'Most Expensive 25 EC2'}
                                                    type={'service'}
                                                    disabled={loading}
                                                    onClick={handleClick}
                                                    buttonName={'Generate'}/>

                                <ReportGenerateCard subTitle={'SUMMARY'}
                                                    id="TERDS"
                                                    title={'Most Expensive 25 RDS'}
                                                    type={'service'}
                                                    disabled={loading}
                                                    onClick={handleClick}
                                                    buttonName={'Generate'}/>

                                <ReportGenerateCard subTitle={'SUMMARY'}
                                                    id="TECWG"
                                                    title={'Most Expensive 25 CloudWatch Log Group'}
                                                    type={'service'}
                                                    disabled={loading}
                                                    onClick={handleClick}
                                                    buttonName={'Generate'}/>

                                <ReportGenerateCard subTitle={'SUMMARY'}
                                                    id="TED"
                                                    title={'Most Expensive 25 Dynamo Tables'}
                                                    type={'service'}
                                                    disabled={loading}
                                                    onClick={handleClick}
                                                    buttonName={'Generate'}/>

                                <ReportGenerateCard subTitle={'SUMMARY'}
                                                    id="TES"
                                                    title={'Most Expensive 25 S3 Buckets'}
                                                    type={'service'}
                                                    disabled={loading}
                                                    onClick={handleClick}
                                                    buttonName={'Generate'}/>

                                <ReportGenerateCard subTitle={'SUMMARY'}
                                                    id="TESO"
                                                    title={'Most Expensive 25 S3 Bucket Operations'}
                                                    type={'service'}
                                                    disabled={loading}
                                                    onClick={handleClick}
                                                    buttonName={'Generate'}/>

                                <ReportGenerateCard subTitle={'SUMMARY'}
                                                    id="TEV"
                                                    title={'Most Expensive 25 VPC'}
                                                    type={'service'}
                                                    disabled={loading}
                                                    onClick={handleClick}
                                                    buttonName={'Generate'}/>

                                <ReportGenerateCard subTitle={'SUMMARY'}
                                                    id="TEC"
                                                    title={'Most Expensive 25 Config'}
                                                    type={'service'}
                                                    disabled={loading}
                                                    onClick={handleClick}
                                                    buttonName={'Generate'}/>
                            </>
                        }
                        {
                            activeReportCategory === 'custom' &&
                            <>
                                <CustomReport masterAccountId={masterAccountId}/>
                            </>
                        }
                    </Box>
                </div>
            </div>
        </div>
    )
}