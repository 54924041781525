import axios from 'axios'
import axiosInstance from './interceptor'

/**
 * API End Points
 * Author: Nithin PB
 * */

let BASE_URL = ''
if (process.env.NODE_ENV === 'production') {
	BASE_URL = 'https://demo.finthesys.com/api'
} else {
	//BASE_URL = "http://127.0.0.1:8000";
	BASE_URL = 'https://demo.finthesys.com/api'
}

const ELB_BASE_URL = 'http://internal-finops-ui-27769396.us-east-1.elb.amazonaws.com'

/* Reports */
const BILLING_INVENTORY_REPORT = BASE_URL + '/api/v1/billInventory'
const TAG_REPORT = BASE_URL + '/api/v1/zipReport'
const COST_EXPLORER = BASE_URL + '/v2/api/v2/costAndUsageReport'
const COVERAGE_REPORT = BASE_URL + '/v2/api/v2/coverageReport'
const CUSTOM_REPORT = BASE_URL + '/custom-report'

/* Billing Information */
const BILLING_INFORMATION = ELB_BASE_URL + '/billing_info'
const SPEND_BY_SERVICE = ELB_BASE_URL + '/spend_by_service'
const COST_INFORMATION = ELB_BASE_URL + '/total_spend'

/* Recommendations */
const COMPUTE_OPTIMIZER = BASE_URL + '/compute-optimizer-recommendations'
const COMPUTE_OPTIMIZER_V1 = BASE_URL + '/compute-optimizer-recommendations'
const COMPUTE_OPTIMIZER_RESOURCE_TAG = BASE_URL + '/compute-optimizer-get-resource-tags'
const COMPUTE_OPTIMIZER_RESOURCE_TAG_VALUES = BASE_URL + '/compute-optimizer-get-resource-values'
const COMPUTE_OPTIMIZER_SUMMARY = BASE_URL + '/compute-optimizer-summary'
const TRUSTED_ADVISOR_CHECK = BASE_URL + '/trusted-advisor-checks'
const TRUSTED_ADVISOR_CHECK_SUMMARY = BASE_URL + '/trusted-advisor-check-summary'
const TRUSTED_ADVISOR_FLAGGED_RESOURCE = BASE_URL + '/trusted-advisor-flagged-resources'
const TRUSTED_ADVISOR_SUMMARY = BASE_URL + '/trusted-advisor-summary'
const TRUSTED_ADVISOR_CRON = BASE_URL + '/trusted-advisor-cron'
const TRUSTED_ADVISOR_DOWNLOAD = BASE_URL + '/v2/api/v2/trustedAdvisorReport'

// Automations
const AUTO_TAGGING = BASE_URL + '/v2/api/v2/autoTagging'
const AUTO_TAGGING_HISTORY = BASE_URL + '/v2/api/v2/getAutoTagHistory'
const AUTO_TAGGING_RESOURCE_TAGS = BASE_URL + '/v2/api/v2/getAutoTaggingResourceTags'
const AUTO_TAGGING_RESOURCE_TAG_SEARCH = BASE_URL + '/v2/api/v2/searchAutoTagging'
const AUTO_TAGGING_RESOURCE_HISTORY_META = BASE_URL + '/v2/api/v2/autoTaggingHistory'
const AUTO_TAGGING_REVERT = BASE_URL + '/v2/api/v2/revertAutoTagging'
const AUTO_TAGGING_UPLOAD = BASE_URL + '/v2/api/v2/autoTaggingFromCsvPreview'
const AUTO_TAGGING_REVERIFY = BASE_URL + '/v2/api/v2/autoTaggingFromCsvPreviewReverify'
const AUTO_TAGGING_PROCESS_TAGGING = BASE_URL + '/v2/api/v2/autoTaggingCsv'
const AUTO_TAGGING_RESOURCE_LISTING = BASE_URL + '/v2/api/v2/listResources'
const POWER_SCHEDULE_ORCHESTRATION = BASE_URL + '/orchestration/power-schedule'
const POWER_SCHEDULE_ORCHESTRATION_SUMMARY = BASE_URL + '/orchestration/power-schedule/summary'
const POWER_SCHEDULE_ORCHESTRATION_RESOURCE = BASE_URL + '/orchestration/power-schedule/resource-id'
const POWER_SCHEDULE_RESOURCE_SEARCH = BASE_URL + '/api/v1/searchPowerScheduleResources'
const POWER_SCHEDULE_RESOURCE_TAG_SET = BASE_URL + '/api/v1/setPowerSchedule'
const POWER_SCHEDULE_DOWNLOAD = BASE_URL + '/api/v1/downloadPowerScheduleReport'
const POWER_SCHEDULE_RESOURCE_STOP_RESOURCE = BASE_URL + '/api/v1/performStartStop'

// auto-tagging new

const AUTO_TAGGING_REPORT_VERIFICATION = BASE_URL + '/autoTaggingV2/tagging-preview'
const AUTO_TAGGING_TAGGING = BASE_URL + '/autoTaggingV2/tagging'
const AUTO_TAGGING_GET_RESOURCES = BASE_URL + '/autoTaggingV2/tagging-get-resources'

// guard rails
const LIST_TAG_POLICY = BASE_URL + '/api/v1/listTagPolicy'
const TAG_DESCRIPTION = BASE_URL + '/api/v1/describeTagPolicy'

//Monitoring
const COST_ANOMALY = BASE_URL + '/api/v1/costAnomaly'
const TAG_COMPLIANCE = BASE_URL + '/api/v1/tagCompliance'
const TAG_ACCOUNT_COMPLIANCE = BASE_URL + '/api/v1/listAccountCount'
const TAG_COMPLIANCE_TABLE_DATA = BASE_URL + '/api/v1/listTagCompliance'
const TAG_COMPLIANCE_HEADER_DATA = BASE_URL + '/api/v1/listAccountTotalCount'
const NEW_DEPLOYMENTS = BASE_URL + '/api/v1/athenaResource'
const CLOUD_WATCH_MONITOR_SET_LOG = BASE_URL + '/api/v1/cloudLogRetentionSet'
const CLOUD_WATCH_MONITOR_REFRESH_CRON = BASE_URL + '/api/v1/listCloudWatchLogs'
const CLOUD_WATCH_MONITOR_DOWNLOAD = BASE_URL + '/api/v1/generateLogReport'
const CLOUD_WATCH_CRON_STATUS = BASE_URL + '/api/v1/getClouwatchLogOverAllSummary'

//Unused EBS
const EBS_DATA = BASE_URL + '/api/v1/unUsedEBS'
const EBS_TABLE_DATA = BASE_URL + '/api/v1/listUnusedEBS'
const EBS_HOME_DATA = BASE_URL + '/api/v1/EBSHome'
const EBS_DATA_DOWNLOAD = BASE_URL + '/api/v1/generateReport'
const EBS_CRON_ONDEMAND = BASE_URL + '/api/v1/runEbsCronOnDemand'
const EBS_SUMMARY = BASE_URL + '/api/v1/getUnusedEbsOverAllSummary'

//Snapshot
const SNAP_HOME_DATA = BASE_URL + '/api/v1/snapshotHome'
const Snapshot_DATA = BASE_URL + '/api/v1/unUsedSnapshot'
const Snapshot_TABLE_DATA = BASE_URL + '/api/v1/listUnusedSnapshot'
const Snapshot_DATA_DOWNLOAD = BASE_URL + '/api/v1/generateSnapshotReport'
const SNAPSHOT_CRON_ONDEMAND = BASE_URL + '/api/v1/runSnapshotCronOnDemand'
const SNAPSHOT_SUMMARY = BASE_URL + '/api/v1/getUnusedSnapshotOverAllSummary'

//Unused EC2
const EC2_DATA = BASE_URL + '/api/v1/unUsedEC2'
const EC2_TABLE_DATA = BASE_URL + '/api/v1/listUnusedEC2'
const EC2_HOME_DATA = BASE_URL + '/api/v1/EC2Home'
const EC2_DATA_DOWNLOAD = BASE_URL + '/api/v1/generateEc2Report'
const EC2_CRON_ONDEMAND = BASE_URL + '/api/v1/runEc2CronOnDemand'
const EC2_SUMMARY = BASE_URL + '/api/v1/getUnusedEc2OverAllSummary'

//Unused AMI
const AMI_DATA = BASE_URL + '/api/v1/unUsedAMI'
const AMI_TABLE_DATA = BASE_URL + '/api/v1/listUnusedAMI'
const AMI_HOME_DATA = BASE_URL + '/api/v1/AMIHome'
const AMI_DATA_DOWNLOAD = BASE_URL + '/api/v1/generateAMIReport'
const AMI_CRON_ONDEMAND = BASE_URL + '/api/v1/runAmiCronOnDemand'
const AMI_SUMMARY = BASE_URL + '/api/v1/getUnusedAmiOverAllSummary'

// ELB with S3
const ELB_HOME = BASE_URL + '/api/v1/ELBHome'
const ELB_DATA = BASE_URL + '/api/v1/unUsedELB'
const ELB_TABLE_DATA = BASE_URL + '/api/v1/listEbsS3'

const WATCH_HOME = BASE_URL + '/api/v1/listlogTotalCount'
const WATCH_DATA = BASE_URL + '/api/v1/cloudLogCount'
const WATCH_TABLE_DATA = BASE_URL + '/api/v1/listCloudLogs'
const WATCH_UPDATE = BASE_URL + '/api/v1/cloudLogRetentionSet'

//EFS
const EFS_OVERALL_SUMMARY = BASE_URL + '/v2/api/v2/getEfsInfo'
const EFS_ACCOUNT_WISE_SUMMARY = BASE_URL + '/v2/api/v2/getEfsThroughput'
const EFS_DOWNLOAD_REPORT = BASE_URL + '/v2/api/v2/downloadEfsInfo'
const EFS_ONDEMAND_REFRESH = BASE_URL + '/v2/api/v2/efsThroughput'
const EFS_OVERALL_CRON_SUMMARY = BASE_URL + '/v2/api/v2/efsCronSummary'

//insights
const SP_INVENTORY = BASE_URL + '/api/v1/savingPlanInventory'
const SP_RECOMMENDATIONS = BASE_URL + '/api/v1/recommentations'
const SP_COVERAGE = BASE_URL + '/api/v1/coverageSPReport'
const RE_RECOMMENDATIONS = BASE_URL + '/api/v1/reservationRecommentations'
const RE_COVERAGE = BASE_URL + '/api/v1/coverageReserveReport'

// General
const ACCOUNT_INFO = BASE_URL + '/api/v1/get_accounts'
const ACCOUNT_INFO_BY_ID = BASE_URL + '/api/v1/get_account_by_master_id'
const S3_PARSER = ELB_BASE_URL + '/S3Parser' // deprecation
const DATA_PARSER = BASE_URL + '/get-file'
const APPLICATION_TAG_REPORT = BASE_URL + '/tag-report-key-value'
const TAG_VALUE_BY_KEY = BASE_URL + '/tag-key-value-search'
const REGION_LIST = BASE_URL + '/region'
const ACCOUNT_CATEGORY_LIST = BASE_URL + '/api/v1/get_account_category'
const UPDATE_NOTIFICATION = BASE_URL + '/notification'

//Accounts
const ORGANIZATION_LIST = BASE_URL + '/api/v1/get_organization_list'

// MANAGEMENT AND GOVERNANCE
const SECURITY_GROUP_UNRSTRICTED_ACCESS = BASE_URL + '/api/v1/securitygrouprules'
const IAM_POLICY_LISTING = BASE_URL + '/v2/api/v2/getAuthPolicies'
const IAM_POLICY_RUN_CRON = BASE_URL + '/v2/api/v2/checkPolicy'

//ACCOUNT Trend
const ACCOUNT_TREND = BASE_URL + '/account-trend'
const ACCOUNT_TREND_DAILY = BASE_URL + '/account-trend-daily'
const ACCOUNT_TREND_DAILY_APPLICATION = BASE_URL + '/account-trend-daily-application'
const ACCOUNT_TREND_DAILY_SERVICE = BASE_URL + '/account-trend-daily-service'
const ACCOUNT_TREND_DAILY_DOWNLOAD = BASE_URL + '/account-trend-daily-download'

//athena
const ATHENA_COLUMN = BASE_URL + '/get-athena-column'

//bu report
const SINGLE_RESOURCE_DETAILS = BASE_URL + '/resource-report'
const APPLICATION_SHOWBACK_REPORT = BASE_URL + '/v2/api/v2/showBackReport'

// resource tag
const RESOURCE_TAG = BASE_URL + '/resource_tags/resource-tag'

//subnet
const SUBNET_ORGANIZATION_SUMMARY = BASE_URL + '/organization-wise-summary'
const SUBNET_ACCOUNT_SUMMARY = BASE_URL + '/account-wise-summary'
const SUBNET_OVERALL_SUMMARY = BASE_URL + '/overall-summary'
const SUBNET_CRON_ONDEMAND = BASE_URL + '/refresh-ip-governance'

// user
const REQEST_TEMPORARY_USER = BASE_URL + '/user-new-request'

//billing landing
const ORGANIZATION_SPEND_SUM = BASE_URL + '/cost-and-usage'
const ORGANIZATION_SPEND_YTD_SUM = BASE_URL + '/cost-and-usage-year-to-date'
const TREND = BASE_URL + '/trend'
const MOST_SPEND = BASE_URL + '/most-spend'

//ecs
const ECS_CRON = BASE_URL + '/ecsMonitoring'
const ECS_SUMMARY = BASE_URL + '/ecsList'
const ECS_LIST = BASE_URL + '/listEcsAccountWise'
const ECS_CLUSTER_LIST = BASE_URL + '/ecsListAccountWiseCluster'
const ECS_SERVICE_LIST = BASE_URL + '/ecsListAccountWiseService'
const ECS_TASK_LIST = BASE_URL + '/ecsListAccountWiseTask'
const ECS_INSTANCE_LIST = BASE_URL + '/listInstanceAccountWise'
const ECS_CLUSTER_DESCRIBE = BASE_URL + '/ecsClusterDescription'
const ECS_SERVICE_DESCRIBE = BASE_URL + '/ecsServiceDescription'
const ECS_TASK_DESCRIBE = BASE_URL + '/ecsTaskDescription'
const ECS_INSTANCE_DESCRIBE = BASE_URL + '/ecsClusterInstanceDescription'

//Tag compliance
const TAG_COMPLIANCE_SUMMARY = BASE_URL + '/tagComplianceV2/getTagComplianceReport'
const TAG_COMPLIANCE_ORGANIZATION_WISE = BASE_URL + '/tagComplianceV2/getResourceList'
const TAG_COMPLIANCE_ACCOUNT_WISE = BASE_URL + '/tagComplianceV2/getResourceTagCount'
const TAG_COMPLIANCE_RESOURCE_WISE = BASE_URL + '/tagComplianceV2/getResourceTags'
const TAG_COMPLIANCE_RESOURCE_WISE_FIX = BASE_URL + '/tagComplianceV2/tagResourcesByResource'
const TAG_COMPLIANCE_ORGANIZATION_WISE_FIX = BASE_URL + '/tagComplianceV2/tagResourcesByOrganization'
const TAG_COMPLIANCE_ACCOUNT_WISE_FIX = BASE_URL + '/tagComplianceV2/tagResourcesByAccount'
const TAG_COMPLIANCE_TAG_WISE_FIX = BASE_URL + '/tagComplianceV2/tagResourcesByTagWise'

//auth
const AUTH_LOGIN = BASE_URL + '/auth/authorization/login'
const AUTH_REFRESH_TOKEN = BASE_URL + '/auth/authorization/issue-token'

export default {
	account() {
		return {
			getOrganizationList: (progressFunction) => axiosInstance.get(ORGANIZATION_LIST, progressFunction),
		}
	},

	budget() {
		return {
			getBudgetInformation: (params) => axiosInstance.get(BASE_URL + '/api/v1/getAllBudgets', { params }),
			createBudget: (toCreate) => axiosInstance.post(BASE_URL + '/api/v1/multipleInsertBudget', toCreate),
			updateBudget: (params) => axiosInstance.post(BASE_URL + '/api/v1/updateBudget', params),
			deleteBudgetById: (params) => axiosInstance.post(BASE_URL + '/api/v1/deleteBudgetById', params),
			BudgetHistoryById: (params) => axiosInstance.post(BASE_URL + '/api/v1/budgetHistory/', params),
		}
	},

	masterBillingReport() {
		return {
			//generateReport:(toCreate)=>axiosInstance.get(TEST_GET, toCreate),
			generateReport: (toCreate) => axiosInstance.post(BASE_URL + '/api/v1/costUsageReport', toCreate),
			getColumnName: (toCreate) => axiosInstance.post(BASE_URL + '/api/v1/costUsageReport', toCreate),
		}
	},

	costExplorerReport() {
		return {
			generateCostExplorerReport: (generateReport) => axiosInstance.post(COST_EXPLORER, generateReport),
			generateCoverageReport: (generateReport) => axiosInstance.post(COVERAGE_REPORT, generateReport),
			generateCustomReport: (params) => axiosInstance.post(CUSTOM_REPORT, params, { responseType: 'blob' }),
		}
	},

	billingInventoryReport() {
		return {
			generateBillingInventoryReport: (params) => axiosInstance.get(BILLING_INVENTORY_REPORT, { params: params }),
		}
	},

	resourceTagReport() {
		return {
			tagReport: (params) => axiosInstance.post(TAG_REPORT, params),
		}
	},

	dashboard() {
		return {
			spendSummary: (toGetData) => axios.post(ORGANIZATION_SPEND_SUM, toGetData),
			spendByService: (toGetData) => axios.post(SPEND_BY_SERVICE, { params: toGetData }),
			costInformation: (toGetData) => axios.post(ORGANIZATION_SPEND_YTD_SUM, toGetData),
			trendInformation: (toGetData) => axios.post(TREND, toGetData),
			mostUsedInformation: (toGetData) => axios.post(MOST_SPEND, toGetData),
			/*            spendSummary: (toGetData) => axiosInstance.get(BILLING_INFORMATION, {params: toGetData}),
            spendByService: (toGetData) => axiosInstance.get(SPEND_BY_SERVICE, {params: toGetData}),
            costInformation: (toGetData) => axiosInstance.get(COST_INFORMATION, {params: toGetData})*/
		}
	},

	recommendations() {
		return {
			getComputeOptimizerRecommendations: (params) => axiosInstance.get(COMPUTE_OPTIMIZER_V1, { params: params }),
			getComputeOptimizer: (params) => axiosInstance.post(COMPUTE_OPTIMIZER, params),
			getComputeOptimizerSummary: (params) => axiosInstance.get(COMPUTE_OPTIMIZER_SUMMARY, { params }),
			getResourceTags: (params) => axiosInstance.get(COMPUTE_OPTIMIZER_RESOURCE_TAG, { params: params }),
			getResourceTagValues: (params) =>
				axiosInstance.get(COMPUTE_OPTIMIZER_RESOURCE_TAG_VALUES, { params: params }),
			getTrustedAdvisorChecks: (params) => axiosInstance.post(TRUSTED_ADVISOR_CHECK, params),
			getTrustedAdvisorCheckSummary: (params) => axiosInstance.post(TRUSTED_ADVISOR_CHECK_SUMMARY, params),
			getTrustedAdvisorFlaggedResource: (params) => axiosInstance.post(TRUSTED_ADVISOR_FLAGGED_RESOURCE, params),
			getTrustedAdvisorSummary: (params) => axiosInstance.post(TRUSTED_ADVISOR_SUMMARY, params),
			getTrustedAdvisorCron: () => axiosInstance.get(TRUSTED_ADVISOR_CRON),
			getTrustedAdvisorReportDownload: (params) =>
				axiosInstance.get(TRUSTED_ADVISOR_DOWNLOAD, {
					params: params,
					responseType: 'blob',
				}),
		}
	},

	onBoarding() {
		return {
			getApplicationList: (params) => axiosInstance.get(BASE_URL + '/api/v1/Onboard', { params }),
			updateApplication: (toUpdate) => axiosInstance.put(BASE_URL + '/api/v1/Onboard_by_id', toUpdate),
			createApplication: (toCreate) => axiosInstance.post(BASE_URL + '/api/v1/Onboard', toCreate),
			deleteApplication: (params) => axiosInstance.delete(BASE_URL + '/api/v1/Onboard', { params: params }),
			onBoardingExport: (params) =>
				axiosInstance.get(BASE_URL + '/onboarding-download', {
					params: params,
					responseType: 'blob',
				}),
		}
	},

	automations() {
		return {
			getPowerSchedule: (params) => axiosInstance.get(BASE_URL + '/api/v1/listTags', { params }),
			setPowerScheduleTags: (toCreate) => axiosInstance.post(BASE_URL + '/api/v1/setTag', toCreate),
			getPowerScheduleSummary: (params) => axiosInstance.get(POWER_SCHEDULE_ORCHESTRATION_SUMMARY, { params }),
			getPowerScheduleV2: (params) => axiosInstance.get(POWER_SCHEDULE_ORCHESTRATION, { params }),
			createPowerScheduleV2: (params) => axiosInstance.post(POWER_SCHEDULE_ORCHESTRATION, params),
			updatePowerScheduleV2: (params) => axiosInstance.put(POWER_SCHEDULE_ORCHESTRATION, params),
			deletePowerScheduleV2: (data) => axiosInstance.delete(POWER_SCHEDULE_ORCHESTRATION, { data }),
			getNonScheduledResources: (params) => axiosInstance.get(POWER_SCHEDULE_ORCHESTRATION_RESOURCE, { params }),
			autoTagResource: (toCreate) => axiosInstance.post(AUTO_TAGGING, toCreate),
			autoTaggingHistory: () => axiosInstance.get(AUTO_TAGGING_HISTORY),
			autoTaggingHistoryMeta: (params) => axiosInstance.post(AUTO_TAGGING_RESOURCE_HISTORY_META, params),
			getAutoTaggingResourceTags: (params) => axiosInstance.get(AUTO_TAGGING_RESOURCE_TAGS, { params }),
			autoTaggingResourceSearch: (params) => axiosInstance.post(AUTO_TAGGING_RESOURCE_TAG_SEARCH, params),
			autoTaggingRevert: (params) => axiosInstance.post(AUTO_TAGGING_REVERT, params),
			autoTaggingUpload: (params, options) => axiosInstance.post(AUTO_TAGGING_UPLOAD, params, options),
			autoTaggingReverifyResources: (params) => axiosInstance.post(AUTO_TAGGING_REVERIFY, params),
			autoTaggingProcessFileUploadResource: (params, options) =>
				axiosInstance.post(AUTO_TAGGING_PROCESS_TAGGING, params, options),
			autoTaggingGetResourceList: () => axiosInstance.get(AUTO_TAGGING_RESOURCE_LISTING),
			// weekend tags
			searchPowerScheduleResources: (params) => axiosInstance.post(POWER_SCHEDULE_RESOURCE_SEARCH, params),
			addRemovePowerScheduleTags: (params) => axiosInstance.post(POWER_SCHEDULE_RESOURCE_TAG_SET, params),
			downloadPowerScheduleReport: (params) =>
				axiosInstance.post(POWER_SCHEDULE_DOWNLOAD, params, { responseType: 'blob' }),
			startStopResources: (params) => axiosInstance.post(POWER_SCHEDULE_RESOURCE_STOP_RESOURCE, params),

			//auto-tagging new
			autoTaggingReportVerify: (params, options) =>
				axiosInstance.post(AUTO_TAGGING_REPORT_VERIFICATION, params, options),
			autoTaggingPerformTagging: (params) => axiosInstance.post(AUTO_TAGGING_TAGGING, params),
			autoTaggingGetResources: (params) =>
				axiosInstance.post(AUTO_TAGGING_GET_RESOURCES, params, { responseType: 'stream' }),
		}
	},

	general() {
		return {
			getAccountInformation: () => axiosInstance.get(ACCOUNT_INFO),
			s3DownloadFile: (params) => axiosInstance.get(S3_PARSER, { params: params }),
			s3Read: (params) => axiosInstance.get(S3_PARSER, { params: params }),
			dataParser: (params) => axiosInstance.get(DATA_PARSER, { params: params }),
			getAccountInformationByMasterId: (params) => axiosInstance.get(ACCOUNT_INFO_BY_ID, { params: params }),
			getApplicationColumns: (params) => axiosInstance.get(APPLICATION_TAG_REPORT, { params: params }),
			getTagValuesByKey: (params) => axiosInstance.get(TAG_VALUE_BY_KEY, { params: params }),
			getRegionList: () => axiosInstance.get(REGION_LIST),
			getAccountCategoryList: () => axiosInstance.get(ACCOUNT_CATEGORY_LIST),
			getUpdateNotification: () => axiosInstance.get(UPDATE_NOTIFICATION),
		}
	},

	guardRails() {
		return {
			getTagPolicies: (params) => axiosInstance.get(LIST_TAG_POLICY, { params }),
			getTagPolicyDescription: (params) => axiosInstance.get(TAG_DESCRIPTION, { params: params }),
		}
	},

	monitoring() {
		return {
			getCostAnomaly: (param) => axiosInstance.post(COST_ANOMALY, param),

			getTagCompliance: (toCreate) => axiosInstance.post(TAG_COMPLIANCE, toCreate),
			listTagAccount: (params) => axiosInstance.post(TAG_ACCOUNT_COMPLIANCE, params),
			getTagComplianceTableData: (params) => axiosInstance.get(TAG_COMPLIANCE_TABLE_DATA, { params: params }),
			getTotalCount: (params) => axiosInstance.get(TAG_COMPLIANCE_HEADER_DATA, { params: params }),

			//EBS
			getEBSTableData: (params) => axiosInstance.get(EBS_TABLE_DATA, { params: params }),
			getTotalEBS: (params) => axiosInstance.get(EBS_DATA, { params: params }),
			getEbsHomeCount: (params) => axiosInstance.get(EBS_HOME_DATA, { params: params }),
			downloadEbs: (params) => axiosInstance.post(EBS_DATA_DOWNLOAD, params, { responseType: 'blob' }),
			onDemandCronRefreshEbs: (params) => axiosInstance.get(EBS_CRON_ONDEMAND, params),
			overAllSummaryEbs: (params) => axiosInstance.get(EBS_SUMMARY, { params }),

			//Snapshot
			getUnusedSnapshot: (params) => axiosInstance.get(Snapshot_DATA, { params: params }),
			getListUnusedSnapshot: (params) => axiosInstance.get(Snapshot_TABLE_DATA, { params: params }),
			getSnpHomeCount: (params) => axiosInstance.get(SNAP_HOME_DATA, { params }),
			downloadSnapshot: (params) => axiosInstance.post(Snapshot_DATA_DOWNLOAD, params, { responseType: 'blob' }),
			onDemandCronRefreshSnapshot: (params) => axiosInstance.get(SNAPSHOT_CRON_ONDEMAND, params),
			overAllSummarySnapshot: (params) => axiosInstance.get(SNAPSHOT_SUMMARY, { params }),

			//EC2
			getUnusedEC2: (params) => axiosInstance.get(EC2_DATA, { params: params }),
			getListUnusedEC2: (params) => axiosInstance.get(EC2_TABLE_DATA, { params: params }),
			getEc2HomeCount: (params) => axiosInstance.get(EC2_HOME_DATA, { params: params }),
			downloadEc2: (params) => axiosInstance.post(EC2_DATA_DOWNLOAD, params, { responseType: 'blob' }),
			onDemandCronRefreshEc2: (params) => axiosInstance.get(EC2_CRON_ONDEMAND, params),
			overAllSummaryEc2: (params) => axiosInstance.get(EC2_SUMMARY, { params }),

			//AMI
			getUnusedAMI: (params) => axiosInstance.get(AMI_DATA, { params: params }),
			getListUnusedAMI: (params) => axiosInstance.get(AMI_TABLE_DATA, { params: params }),
			getAmiHomeCount: (params) => axiosInstance.get(AMI_HOME_DATA, { params: params }),
			downloadAmi: (params) => axiosInstance.post(AMI_DATA_DOWNLOAD, params, { responseType: 'blob' }),
			onDemandCronRefreshAmi: (params) => axiosInstance.get(AMI_CRON_ONDEMAND, params),
			overAllSummaryAmi: (params) => axiosInstance.get(AMI_SUMMARY, { params }),

			// ELB with S3
			getElbHomeCount: (params) => axiosInstance.get(ELB_HOME, { params: params }),
			getUnusedElb: (params) => axiosInstance.get(ELB_DATA, { params: params }),
			getListUnusedElb: (params) => axiosInstance.get(ELB_TABLE_DATA, { params: params }),

			getWatchHomeCount: (params) => axiosInstance.get(WATCH_HOME, { params: params }),
			getUnusedWatch: (params) => axiosInstance.get(WATCH_DATA, { params: params }),
			getListUnusedWatch: (params) => axiosInstance.get(WATCH_TABLE_DATA, { params: params }),
			updateRetention: (params) => axiosInstance.post(WATCH_UPDATE, params),

			// new deployments
			getNewDeployments: (params) => axiosInstance.post(NEW_DEPLOYMENTS, params),

			// cloud watch
			setRetentionPeriodForCloudWatch: (params) => axiosInstance.post(CLOUD_WATCH_MONITOR_SET_LOG, params),
			downLoadCloudWatchReport: (params) =>
				axiosInstance.post(CLOUD_WATCH_MONITOR_DOWNLOAD, params, { responseType: 'blob' }),
			onDemandCronRefreshCloudWatch: (params) => axiosInstance.get(CLOUD_WATCH_MONITOR_REFRESH_CRON, params),
			cronStatusCloudWatch: (params) => axiosInstance.get(CLOUD_WATCH_CRON_STATUS, { params }),

			//efs
			getOrganizationWiseSummaryEfs: (params) => axiosInstance.post(EFS_OVERALL_SUMMARY, params),
			getAccountWiseSummaryEfs: (params) => axiosInstance.post(EFS_ACCOUNT_WISE_SUMMARY, params),
			downloadReportEfs: (params) => axiosInstance.post(EFS_DOWNLOAD_REPORT, params, { responseType: 'blob' }),
			onDemandRefreshEfs: (params) => axiosInstance.post(EFS_ONDEMAND_REFRESH, params),
			overAllSummaryEfs: (params) => axiosInstance.get(EFS_OVERALL_CRON_SUMMARY, { params }),

			// tag compliance
			getTagComplianceSummary: (params) => axiosInstance.get(TAG_COMPLIANCE_SUMMARY, { params }),
			getTagComplianceOrganizationWise: (params) =>
				axiosInstance.get(TAG_COMPLIANCE_ORGANIZATION_WISE, { params }),
			getTagComplianceAccountWise: (params) => axiosInstance.get(TAG_COMPLIANCE_ACCOUNT_WISE, { params }),
			getTagComplianceResourceWise: (params) => axiosInstance.get(TAG_COMPLIANCE_RESOURCE_WISE, { params }),
			tagResourceOrganizationWise: (params) => axiosInstance.post(TAG_COMPLIANCE_ORGANIZATION_WISE_FIX, params),
			tagResourceAccountWise: (params) => axiosInstance.post(TAG_COMPLIANCE_ACCOUNT_WISE, params),
			tagResourceResourceWise: (params) => axiosInstance.post(TAG_COMPLIANCE_RESOURCE_WISE_FIX, params),
			tagResourceTagWise: (params) => axiosInstance.post(TAG_COMPLIANCE_TAG_WISE_FIX, params),
		}
	},

	insights() {
		return {
			getSavingsPlanRecommendations: (params) => axiosInstance.post(SP_RECOMMENDATIONS, params),
			getSavingsPlanInventory: (params) => axiosInstance.get(SP_INVENTORY, { params: params }),
			getSavingsPlanCoverage: (params) => axiosInstance.post(SP_COVERAGE, params),
			getReservationRecommendations: (params) => axiosInstance.post(RE_RECOMMENDATIONS, params),
			getReservationCoverage: (params) => axiosInstance.post(RE_COVERAGE, params),
		}
	},

	governance() {
		return {
			updateSecurityGroupSourceIp: (params) => axiosInstance.post(SECURITY_GROUP_UNRSTRICTED_ACCESS, params),
			getIamPolicies: (params) => axiosInstance.post(IAM_POLICY_LISTING, params),
			iamPolicyCron: (params) => axiosInstance.post(IAM_POLICY_RUN_CRON, params),

			// subnet
			getSubnetOrganizationSummary: (params) => axiosInstance.post(SUBNET_ORGANIZATION_SUMMARY, params),
			getSubnetAccountWiseSummary: (params) => axiosInstance.post(SUBNET_ACCOUNT_SUMMARY, params),
			getSubnetOverAllSummary: (params) => axiosInstance.post(SUBNET_OVERALL_SUMMARY, params),
			runSubnetOnDemandCron: (params) => axiosInstance.get(SUBNET_CRON_ONDEMAND, { params }),
		}
	},
	accountTrend() {
		return {
			getAccountTrend: (params) => axiosInstance.post(ACCOUNT_TREND, params),
			getAccountTrendDaily: (params) => axiosInstance.post(ACCOUNT_TREND_DAILY, params),
			getAccountTrendDailyApplication: (params) => axiosInstance.post(ACCOUNT_TREND_DAILY_APPLICATION, params),
			getAccountTrendDailyService: (params) => axiosInstance.post(ACCOUNT_TREND_DAILY_SERVICE, params),
			downloadDailyTrendForApplication: (params) =>
				axiosInstance.post(ACCOUNT_TREND_DAILY_DOWNLOAD, params, { responseType: 'blob' }),
		}
	},
	athena() {
		return {
			getAthenaColumns: (params) => axiosInstance.get(ATHENA_COLUMN, { params }),
		}
	},
	buReport() {
		return {
			getSingleResourceDetails: (params) => axiosInstance.post(SINGLE_RESOURCE_DETAILS, params),
			applicationShowbackReport: (params) =>
				axiosInstance.get(APPLICATION_SHOWBACK_REPORT, {
					params,
					responseType: 'blob',
				}),
		}
	},
	resourceTag() {
		return {
			setResourceTag: (params) => axiosInstance.post(RESOURCE_TAG, params),
			deleteResourceTag: (params) => axiosInstance.put(RESOURCE_TAG, params),
		}
	},

	user() {
		return {
			requestNewUser: (params) => axiosInstance.post(REQEST_TEMPORARY_USER, params),
		}
	},

	ecs() {
		return {
			summaryEcs: (params) => axiosInstance.post(ECS_SUMMARY, params),
			onDemandEcsCron: (params) => axiosInstance.post(ECS_CRON, params),
			listEcs: (params) => axiosInstance.post(ECS_LIST, params),
			listEcsCluster: (params) => axiosInstance.post(ECS_CLUSTER_LIST, params),
			listEcsService: (params) => axiosInstance.post(ECS_SERVICE_LIST, params),
			listEcsTask: (params) => axiosInstance.post(ECS_TASK_LIST, params),
			listEcsInstance: (params) => axiosInstance.post(ECS_INSTANCE_LIST, params),
			describeEcsCluster: (params) => axiosInstance.post(ECS_CLUSTER_DESCRIBE, params),
			describeEcsService: (params) => axiosInstance.post(ECS_SERVICE_DESCRIBE, params),
			describeEcsTask: (params) => axiosInstance.post(ECS_TASK_DESCRIBE, params),
			describeEcsInstance: (params) => axiosInstance.post(ECS_INSTANCE_DESCRIBE, params),
		}
	},
	authentication() {
		return {
			login: (options) => axios.post(AUTH_LOGIN, {}, options),
			refreshToken: () => axiosInstance.get(AUTH_REFRESH_TOKEN),
		}
	},
}
