import React, {useEffect, useState} from "react";
import './ad_hoc_query.scss';
import Filter from "../Filter/Filter"
import ReportPreview from "../Report/ReportPreview/ReportPreview";
import {ProgressCircle} from "../ProgressCircle/ProgressCircle";

import AddRoundedIcon from '@material-ui/icons/AddRounded';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {Button} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import service from "../../service/service";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function AdhocQuery(props) {
    const [column, setColumn] = useState([]);
    const [fields, setFields] = useState([{ value: null }]);
    const [query, setQuery] = useState("SELECT `name` FROM `test_table");
    const [tabSwitch, setTabSwitch] = useState(0);
    const [preview, setPreview] = useState(false);
    const [loading, setLoading] = useState(false);
    const [snackBar, setSnackBar] = useState(false);
    const [columnData, setColumnData] = useState([]);
    const [limit, setLimit] = useState(1000);
    const [previewData, setPreviewData] = useState([]);

    let columnSelected = []

    const body = {
        "query_type": "COL",
        "ad_hoc": "false",
        "account_id": props.masterAccountId.toString()
    }
    useEffect(()=>{
        service.masterBillingReport().generateReport(body)
            .then(res => {
                let colData = []
                if(res.data.report.length > 0 ){
                    res.data.report.map(function(data){
                        colData.push({"columnName":data.column_name})
                    })
                }
                setColumnData(colData)
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
            })
    }, [props.masterAccountId])


    function handleChange(i, event) {
        const values = [...fields];
        values[i].value = buildQuery(event);
        setFields(values);
        setColumn(event.columnSelected)
    }

    function buildQuery(event){
        return(
            " "+ event.column+" " + event.condition + " " + event.value + " " + event.operator + " "
        )
    }

    function handleColumnChange(columnTest){
        columnSelected = ""
        let columnName = ""
        let  columns = columnTest.map(function (value){
            columnName = columnName + value.columnName + ", "
        })
        columnName = columnName.slice(0, -2);
        columnSelected = columnName
    }

    const buildFinalQuery = ()=>{
        const condition = handleAddFilter();
        setLoading(true)

        let selColumn = []
        if(column.length <=0){
            selColumn = columnSelected;
        }
        else{
            selColumn = column;
        }
        //setQuery('SELECT '+ selColumn +' FROM "{table_name}" WHERE '+ condition)
        setQuery('SELECT '+ selColumn +' FROM "{table_name}" LIMIT '+ limit );


        const body2 = {
            "query_type": 'SELECT '+ selColumn +' FROM "{table_name}" LIMIT '+ limit,
            "ad_hoc": "true",
            "account_id": props.masterAccountId.toString()
        }

        setLoading(true);
        service.masterBillingReport().generateReport(body2)
            .then(res => {
                setPreviewData(res.data)
                setLoading(false);
                setPreview(true)
            })
            .catch(err => {
                setLoading(false);
            })
    }

    function columnValue(){
        let selColumn = []
        if(column.length <=0){
            selColumn = columnSelected;
        }
        else{
            selColumn = column;
        }
        return selColumn
    }

    function handleAddFilter(){
        let condition = ""
        fields.map(function(name){
            condition = condition + "'"+name.value+"'";

        })
        condition = condition.slice(0, -4);
        return condition;
    }

    function handleAdd() {
        const values = [...fields];
        values.push({ value: null });
        setFields(values);
    }

    function handleRemove(i) {
        const values = [...fields];
        values.splice(i, 1);
        setFields(values);
    }



    const handlePreviewClose = () =>{
        setPreview(false);
    }

    function ColumnName() {
        return (
            <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={columnData}
                margin="dense"
                size="medium"
                style={{"marginTop": 10, "marginBottom": 6, width: 300}}
                disableCloseOnSelect
                onChange={(event, value) => handleColumnChange(value)}
                getOptionLabel={(option) => option.columnName}
                renderOption={(option, { selected }) => (
                    <React.Fragment>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={selected}
                        />
                        {option.columnName}
                    </React.Fragment>
                )}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Checkboxes" placeholder="Favorites" />
                )}
            />
        )
    }


    return (
        <div className="adhoc-report">
            {preview && <ReportPreview onClick ={handlePreviewClose} title = "CBR" data = {previewData}/> }
            <div className="sub-wrapper">

                <div className="adhoc-title">
                    <h2>Master Billing Custom Report Generator</h2>
                    <p>Built your report using custom filters and fields</p>
                </div>

                <div className="query-filter">
                    <div className="query-preview">
                        {query}
                    </div>


                    <div>
                        <p> Select Column Names</p>
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                            <ColumnName/>
                            <div>
                                <Button
                                    style = {{marginLeft: 20, width: 130, height: 50}}
                                    variant="contained"
                                    disabled={loading}
                                    onClick={buildFinalQuery}
                                    color ="primary"> Execute
                                </Button>
                                {loading&&<ProgressCircle/>}
                            </div>
                        </div>
                        <p className="filter-title"> Filter Conditions</p>

                        <Tooltip title="add new filter" placement="right">
                            <IconButton
                                style={{marginLeft: 2}}
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                onClick={handleAdd}
                            >
                                <AddRoundedIcon/>
                            </IconButton>
                        </Tooltip>
                        {/*
                        <div className="filter-box">
                            {fields.map((field, idx) => {
                                return (
                                    <div key={`${field}-${idx}`}>
                                        <Filter columnSelected = {columnValue} data = {columnData}
                                                onChange={e => handleChange(idx, e)}
                                                onClick={() => handleRemove(idx)}/>
                                    </div>
                                );
                            })}
                        </div>
                        */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdhocQuery;
