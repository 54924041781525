import React from 'react';
import WuStack from "../../General/WuStack";
import EFSData from "./EfsData";
import PageTitle from "../../General/PageTitle/PageTitle";
import SummaryCard from "../SummaryCard";
import service from "../../../service/service";
import DownloadReportEfs from "./DownloadReport";

export default function EfsMonitoring() {
    const boxProps = {
        width: '100%'
    }

    const refreshFunctionApiCall = service.monitoring().onDemandRefreshEfs
    const summaryFunctionApiCall = service.monitoring().overAllSummaryEfs

    const summaryProps = {
        summaryCardProps: {
            countCard: {
                itemKey: 'numberOfEfs',
                description: 'Number of EFS',
                helpText: 'Total Efs in all organization',
                nameOfItem: 'EFS Summary',
                title: '# of EFS',
            }
        },
        downloadComponent: <DownloadReportEfs/>,
        refreshApiFunction: refreshFunctionApiCall,
        summaryApiFunction: summaryFunctionApiCall,
    }

    return (
        <div className="title-div-wrapper">
            <WuStack direction={'column'} boxProps={boxProps} spacing={2}>
                <PageTitle title={'EFS Usage Data'} subTitle={'Provisioned EFS and storage details'}/>
                <WuStack spacing={3}>
                    <EFSData/>
                    <SummaryCard {...summaryProps}/>
                </WuStack>
            </WuStack>
        </div>
    )
}