import React, {useEffect, useRef, useState} from 'react';
import WuStack from "../WuStack";
import {Box, IconButton, Popover, Tooltip, Typography} from "@material-ui/core";
import WuTextField from "../WuTextFeild";
import WuButton from "../WuButton";
import {ProgressCircleCustomSize} from "../../ProgressCircle/ProgressCircle";
import {DeleteOutlined, LaunchOutlined} from "@material-ui/icons";
import service from "../../../service/service";
import {apiErrorResponseJsonParser, dataFromArn} from "../../../helper/generalFunctions";
import {useSnackbar} from "notistack";

export default function CustomCellEditor(props) {
    const {value, setValue, column: {colId}, data: {resourceArn}} = {...props}
    const {enqueueSnackbar} = useSnackbar();
    const [tagValue, setTagValue] = useState(value);
    const [loading, setLoading] = useState(false);

    const setNewTagValueToResource = async (event) => {
        try {
            setLoading(true)
            const {accountId, region} = dataFromArn(resourceArn)
            let response = {}
            if (event === 'delete') {
                response = await deleteTags(accountId, region)
                enqueueSnackbar('Tag deleted', {variant: 'success'});
            }
            if (event === 'apply') {
                if (value === tagValue) return
                response = await setTags(accountId, region)
                enqueueSnackbar('Tag added/updated', {variant: 'success'});
            }
            const {data: {error}} = response
            if (Object.keys(error).length < 1) {
                if (event === 'delete') setValue('')
                else setValue(tagValue)
            }
            setLoading(false);
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setLoading(false);
            enqueueSnackbar(error, {variant: 'error'});
        }
    }

    const deleteTags = async (accountId, region) => {
        const params = {account_id: accountId, region: region, tag_keys: [colId], resource_ids: [resourceArn]}
        return await service.resourceTag().deleteResourceTag(params)
    }

    const setTags = async (accountId, region) => {
        const params = {account_id: accountId, region: region, tags: {[colId]: tagValue}, resource_ids: [resourceArn]}
        return await service.resourceTag().setResourceTag(params)
    }


    return (
        <WuStack spacing={1} direction={'row'} boxProps={{className: 'cell-editor'}} alignItems={'center'}>
            {value}
            {
                loading && <ProgressCircleCustomSize size={12}/>
            }
            <EditPopper tagValue={tagValue}
                        setTagValue={setTagValue}
                        col={colId}
                        onClick={setNewTagValueToResource}
                        actionButtonDisabled={value === tagValue}
            />
        </WuStack>
    )
}


function EditPopper(props) {
    const {tagValue, setTagValue, col, actionButtonDisabled} = {...props}
    const currentRef = useRef();
    const [anchorEl, setAnchorEl] = useState(currentRef.current);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        setAnchorEl(currentRef.current)
    }, [currentRef])

    const open = Boolean(anchorEl);

    const onClickEvent = (e) => {
        props.onClick(e)
        setAnchorEl(null)
    }

    return (
        <div>
            <div onClick={handleClick} className={'icon-wrapper'}>
                <LaunchOutlined style={{color: 'grey'}} className={'edit-icon'} fontSize={'small'}/>
            </div>
            <Popover
                style={{marginLeft: '-1rem'}}
                open={open}
                anchorEl={anchorEl}
                PaperProps={{variant: 'outlined'}}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
            >
                <WuStack spacing={1} boxProps={{m: 2, width: '200px'}} direction={'column'}>
                    <Typography color={'textSecondary'}> Modify <strong>{col}</strong> </Typography>
                    <WuTextField color={'secondary'} label={col} value={tagValue}
                                 onChange={(e) => setTagValue(e.target.value)}/>
                    <WuStack spacing={1} justifyContent={'space-between'}>
                        <Box display={'flex'} gridGap={8}>
                            <WuButton size={'small'}
                                      color={'secondary'}
                                      data-type={'apply'}
                                      disabled={actionButtonDisabled}
                                      onClick={() => onClickEvent('apply')}>
                                Apply
                            </WuButton>
                            <WuButton size={'small'}
                                      variant={'text'}
                                      color={'secondary'}
                                      onClick={() => setAnchorEl(null)}>
                                Cancel
                            </WuButton>
                        </Box>
                        <Tooltip title={
                            <>
                                <Typography variant={'body2'}>Not recommend! ⚠️ </Typography>
                                <Typography variant={'body2'}>Tag will be removed</Typography>
                            </>
                        }>
                            <IconButton size={'small'}
                                        style={{color: '#ff5d5d', marginRight: -4}}
                                        color={'action'}
                                        data-type={'delete'}
                                        onClick={() => onClickEvent('delete')}>
                                <DeleteOutlined/>
                            </IconButton>
                        </Tooltip>
                    </WuStack>
                </WuStack>
            </Popover>
        </div>
    )
}
