import {useHistory} from "react-router-dom";
import {Grid, Paper, Typography, Box, Tabs, Tab, Divider} from "@material-ui/core";
import {CpuUsage, MemoryUsage, StatusComponent} from "../GridColumComponents";
import {CpuUtilizationGraph, MemoryUtilizationGraph} from "../Graphs";
import {useEffect, useState} from "react";
import service from "../../../service/service";
import {apiErrorResponseJsonParser} from "../../../helper/generalFunctions";
import {useEcs} from "../../../hooks";
import Tasks from "../Tasks";
import Services from "../Services";
import moment from "moment";
import PriceDistributionGraph from "../Graphs/PriceDistributionGraph";
import {useOrganization} from "../../../hooks/useOrganization";
import {ecsClusterDescription} from "../../../assets/finthesys/ecs";

export function ClusterDetails({apiProgress, setApiProgress, extraParams}) {
    const {organization: masterAccountId} = useOrganization()
    const [clusterData, setClusterData] = useState({})
    const [activeTab, setActiveTab] = useState(0)
    const {accountId, clusterArn} = {...extraParams}

    const fetchData = async () => {
        try {
            setApiProgress({loading: true, ready: false, error: false})
            const params = {
                accountId: accountId,
                clusterArn: clusterArn,
                masterAccountId: masterAccountId
            }
            const {data: {data}} = await service.ecs().describeEcsCluster(params)
            setClusterData(data)
            setApiProgress({loading: false, ready: true, error: false})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setApiProgress({loading: false, ready: false, error: error})
        }
    }

    const fetchDummyData = async () => {
        try {
            setApiProgress({loading: true, ready: false, error: false})
            const params = {
                accountId: accountId,
                clusterArn: clusterArn,
                masterAccountId: masterAccountId
            }
            const {data} = ecsClusterDescription
            setClusterData(data)
            setApiProgress({loading: false, ready: true, error: false})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setApiProgress({loading: false, ready: false, error: error})
        }
    }


    useEffect(() => {
        if (masterAccountId !== 'no org') {
            fetchDummyData().then()
        }
    }, [masterAccountId])

    return (
        <div className={'cluster-additional-details'}>
            {apiProgress.ready && <div className={'cluster-additional-details-inner'}>
                <Box display={'flex'}
                     alignItems={'center'}
                     gridGap={8}
                     style={{marginBottom: 24, marginLeft: -16}}>
                    <Box display={'flex'} alignItems={'center'} gridGap={8} ml={2}>
                        <TagInformation tagKey={'ApplicationName'} tagValue={clusterData?.applicationName}/>
                        <TagInformation tagKey={'Environment'} tagValue={clusterData?.environment}/>
                        <TagInformation tagKey={'CostCenter'} tagValue={clusterData?.costCenter}/>
                        <TagInformation tagKey={'ApplicationOwner'} tagValue={clusterData?.applicationOwner}/>
                        <TagInformation tagKey={'ApplicationCode'} tagValue={clusterData?.applicationCode}/>
                    </Box>
                </Box>

                <Box display={'flex'}
                     alignItems={'center'}
                     className={'cluster-overall-info'}
                     style={{marginBottom: 24}}
                     width={'100%'}
                     gridGap={16}>
                    <ClusterCard title={'Status'} extraStyle={{maxWidth: 100}}>
                        <StatusComponent value={clusterData?.status}/>
                    </ClusterCard>
                    <ClusterCard title={`${moment().format('MMMM')} Price`} extraStyle={{maxWidth: 200}}>
                        <Typography variant={'h6'} style={{lineHeight: '16px'}}>
                            ${Number(clusterData?.price || 0).toFixed(2)}
                        </Typography>
                    </ClusterCard>
                    <ClusterCard title={`${moment().subtract('1', 'months').format('MMMM')} Price`}
                                 extraStyle={{maxWidth: 200}}>
                        <Typography variant={'h6'} style={{lineHeight: '16px'}}>
                            ${Number(clusterData?.price || 0).toFixed(2)}
                        </Typography>
                    </ClusterCard>
                    <ClusterCard title={`YTD Price`} extraStyle={{maxWidth: 200}}>
                        <Typography variant={'h6'} style={{lineHeight: '16px'}}>
                            ${Number(clusterData?.yearlySpend || 0).toFixed(2)}
                        </Typography>
                    </ClusterCard>
                    <ClusterCard title={'CPU Average'}>
                        <CpuUsage data={{cpuAverage: clusterData.cpuAverage}}/>
                    </ClusterCard>
                    <ClusterCard title={'Memory Average'}>
                        <MemoryUsage data={{
                            memoryAverage: clusterData.memoryAverage
                        }}/>
                    </ClusterCard>
                </Box>

                <Grid spacing={3} container>
                    <Grid item xs={6}>
                        <PriceDistributionGraph title={'Price distribution over months'}
                                                data={clusterData?.graph?.price}
                                                height={'400px'}/>

                    </Grid>
                    <Grid item xs={6}>
                        <Grid spacing={2} container>
                            <Grid item xs={12}>
                                <CpuUtilizationGraph title={'CPU Utilization'}
                                                     data={clusterData?.graph?.cpu}
                                                     height={'150px'}/>
                            </Grid>
                            <Grid item xs={12}>
                                <MemoryUtilizationGraph title={'Memory Utilization'}
                                                        data={clusterData?.graph?.memory}
                                                        height={'180px'}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Box mt={3} width={'100%'} mb={3}>
                    <Tabs value={activeTab} onChange={(_, newValue) => setActiveTab(newValue)}>
                        <Tab label={`Service (${clusterData.serviceCount || 0})`}/>
                        <Tab label={`Tasks (${clusterData.taskCount || 0})`}/>
                    </Tabs>
                    <Divider/>
                    <Box mt={2}>
                        <Paper variant={'outlined'}>
                            {
                                activeTab === 0 &&
                                <Services gridHeight={'500px'} accountId={accountId} filter={{clusterArn: clusterArn}}/>
                            }
                            {
                                activeTab === 1 &&
                                <Tasks gridHeight={'500px'} accountId={accountId} filter={{clusterArn: clusterArn}}/>
                            }
                        </Paper>
                    </Box>
                </Box>
            </div>}
        </div>
    )
}

function ClusterCard({title, children, extraStyle}) {
    return (
        <Paper variant={'outlined'} style={{padding: 16, paddingLeft: 24, ...extraStyle}}>
            <Typography gutterBottom style={{marginBottom: 8}}>{title}</Typography>
            {children}
        </Paper>
    )
}

function TagInformation({tagKey, tagValue = '-'}) {
    return (
        <div className={'container-tag'}>
            {tagKey}:
            <Typography component={'span'} style={{fontWeight: 600, paddingLeft: 8}}>
                {tagValue}
            </Typography>
        </div>
    )
}

function Summary({title, value, tab, description, clusterArn}) {
    const {setEcs} = useEcs()
    const history = useHistory()
    const handleClick = () => {
        setEcs(current => {
            return {
                ...current, activeTab: tab, filter: {
                    clusterArn: clusterArn
                }
            }
        })
        history.push('/ecs')
    }

    return (
        <div className={'container-summary-card'}>
            <Typography variant={'body1'} gutterBottom>
                {title}
            </Typography>
            <Typography variant={'h4'}>
                {value}
            </Typography>
            <Typography>
                {description}
            </Typography>
            <Box mt={1} className={'ecs-link'} onClick={handleClick}>
                Explore
            </Box>
        </div>
    )
}