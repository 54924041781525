import {NavLink} from "react-router-dom";
import React from "react";
import "./custom_tab.scss"

/**
 *
 * @param links {array} - objects for tab item
 * @param links.link {string} - url to the link
 * @param links.name {string} - link will be displayed as provided name
 * @returns {JSX.Element}
 * @constructor
 */
export default function CustomTab({links}) {
    return (
        <nav className="custom-tab">
            <ul>
                {
                    links.map((link, index) => {
                        return (
                            <li key={index}>
                                <NavLink to={link.link}>
                                    {link.name}
                                </NavLink>
                            </li>
                        )
                    })
                }
            </ul>
        </nav>
    )
}