import {Environment} from "./Environment";
import {otherLinks} from "../other_links";
import React from "react";
import {Typography} from "@material-ui/core";

export default function AdditionalLandingInfo() {
    return (
        <div className={'rt-additional-landing-info'}>
            <div className={'rt-info-card'}>
                <Typography>
                    Approved Environment Values
                </Typography>
                <Environment/>
            </div>
            <div className={'rt-info-card rt-use-full-links'}>
                <Typography>
                    Guidance:
                </Typography>
                {
                    otherLinks.map((e, index) => <UsefulLinks key={index} {...e}/>)
                }
            </div>
        </div>
    )
}

function UsefulLinks({title, link}) {
    return (
        <a href={link} target={'_blank'} rel={'noreferrer'}> {title}</a>
    )
}