import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';
import {Radio, RadioGroup} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(3),
    },
}));

let coverageSelectData = {
    startDate: moment().subtract(28, 'days').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    granularity: "DAILY",
}

export default function GranularityPicker(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState('female');

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const [timePeriod, setTimePeriod] = React.useState('last28Days')
    const [granularity, setGranularity] = React.useState('daily')

/*
    const handleChange = (event) => {
        setState({...state, [event.target.name]: event.target.checked});

        if (event.target.checked && event.target.name === "thisMonth") {
            dates.endDate = moment().format('YYYY-MM-DD');
            dates.startDate = moment().subtract(28, 'days').format('YYYY-MM-DD');
        } else if (event.target.checked && event.target.name === "lastMonth") {
            dates.endDate = moment().format('YYYY-MM-DD');
            dates.startDate = moment().format('YYYY') + '-01-01';
        } else if (event.target.checked && event.target.name === "thisYear") {
            dates.endDate = moment().subtract(1, 'years').format('YYYY') + '-12-31';
            dates.startDate = moment().subtract(1, 'years').format('YYYY') + '-01-01';
        } else if (event.target.name === "daily" || event.target.name === "monthly" || event.target.name === "hourly") {
            dates.granularity = event.target.name
        }
        console.log(event.target.checked)
        if (dates.startDate && dates.endDate && dates.granularity && event.target.checked) {
            props.onChange(dates, event);
        }
    };
*/

    const handleTimePeriodChange=(event)=>{
        setTimePeriod(event.target.value)
    }

    const handleGranularityChange=(event)=> {
        setGranularity(event.target.value)
    }


    useEffect(()=>{
        switch (timePeriod){
            case "last28Days":
                coverageSelectData.endDate = moment().format('YYYY-MM-DD');
                coverageSelectData.startDate = moment().subtract(28, 'days').format('YYYY-MM-DD');
                break;
            case "last3Months":
                coverageSelectData.endDate = moment().format('YYYY-MM-DD');
                coverageSelectData.startDate = moment().subtract(3, 'months').format('YYYY-MM-DD');
                break;
            case "lastYear":
                coverageSelectData.endDate = moment().format('YYYY-MM-DD');
                coverageSelectData.startDate = moment().subtract(1, 'years').format('YYYY-MM-DD');
                break;
            default:
                break
        }

        coverageSelectData.granularity = granularity.toUpperCase();
        if(coverageSelectData.startDate && coverageSelectData.endDate && coverageSelectData.granularity){
            props.onChange(coverageSelectData)
        }
    }, [timePeriod, granularity])


    return (
        <div className={classes.root}>
            <FormControl className={classes.formControl} style={{marginRight: 50}}  component="fieldset">
                <FormLabel color={"primary"} style={{marginBottom: 10}} component="legend">Time Period</FormLabel>
                <RadioGroup aria-label="gender" name="timePeriod" value={timePeriod} onChange={handleTimePeriodChange}>
                    <FormControlLabel value="last28Days" control={<Radio />} label="Last 28 Days" />
                    <FormControlLabel value="last3Months" control={<Radio />} label="Last 3 Months" />
                    <FormControlLabel value="lastYear" control={<Radio />} label="One Year" />
                </RadioGroup>
                <FormHelperText>Select one from the checkbox </FormHelperText>
            </FormControl>

            <FormControl className={classes.formControl} style={{marginBottom: 10}}  component="fieldset">
                <FormLabel color={"primary"} style={{marginBottom: 10}} component="legend">Granularity</FormLabel>
                <RadioGroup aria-label="gender" name="granularity" value={granularity} onChange={handleGranularityChange}>
                    <FormControlLabel value="daily" control={<Radio />} label="Daily" />
                    <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
                    <FormControlLabel value="hourly" disabled control={<Radio />} label="Hourly" />
                </RadioGroup>
                <FormHelperText>Select one from the checkbox </FormHelperText>
            </FormControl>
        </div>
    );
}