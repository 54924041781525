import React, {useState} from 'react';
import WuButton from "../../../General/WuButton";
import WuDialog from "../../../General/WuDialog";
import Box from "@material-ui/core/Box";
import {Accounts, Environment, Region} from "../../../General/WuAutoComplete";
import {Autocomplete} from "@material-ui/lab";
import WuTextField from "../../../General/WuTextFeild";
import {Collapse, Typography} from "@material-ui/core";
import WuStack from "../../../General/WuStack";
import WuLoading from "../../../General/WuLoading";
import ResponseStatus from "../../../General/ResponseStatus";
import service from "../../../../service/service";
import {jsonParser} from "../../../../helper/generalFunctions";
import WuAlert from "../../../General/WuAlert";

export default function CloudWatchGroupRetentionSet({onClick}) {
    const [open, setOpen] = useState(false)
    return (
        <div>
            <WuDialog open={open} title={'Manage Lifecycle'} onChange={() => setOpen(false)} size={'xs'}>
                <BulkRetentionSet/>
            </WuDialog>
            <WuButton color={'secondary'} style={{width: '100%'}} onClick={() => setOpen(true)}>
                MANAGE LIFE CYCLE
            </WuButton>
        </div>
    )
}


function BulkRetentionSet() {
    const [loading, setLoading] = useState(false)
    const [ready, setReady] = useState(false)
    const [responseData, setResponseData] = useState(null);
    const [error, setError] = useState({error: false, message: null})
    const [data, setData] = useState({
        accountId: '',
        region: '',
        retention: '',
        environment: ''
    })

    const handleChange = (e, value) => {
        setData({...data, [e]: value})
    }

    const setRetentionPeriod = async () => {
        try {
            setLoading(true)
            setReady(false)
            setError({error: false, message: null})
            const params = {
                "accountId": data.accountId,
                "region": data.region,
                "loggroup": "",
                "retention": data.retention,
                "tagkey": "Environment",
                "tagvalue": data.environment
            }

            const {data: {data: apiResponse}} = await service.monitoring().setRetentionPeriodForCloudWatch(params)
            setResponseData(apiResponse)
            setReady(true)
        } catch (e) {
            let errorResponseBody = ''
            try{
                errorResponseBody = jsonParser(e?.request?.response).error
            } catch (err) {
                errorResponseBody = e.message
            }

            setError({error: true, message: errorResponseBody})
        } finally {
            setLoading(false)
        }
    }


    return (
        <Box m={2}>
            <Accounts onChange={(e) => handleChange('accountId', e.accountId)}/>
            <Environment onChange={(e) => handleChange('environment', e.environment)}/>
            <Region onChange={(e) => handleChange('region', e.region)}/>
            <Autocomplete
                options={retentionPeriod}
                onChange={(event, value) => handleChange('retention', value)}
                getOptionLabel={option => `${option} Days`}
                renderOption={option => <DateTimeAutoCompleteRenderer option={option}/>}
                renderInput={(params) => <WuTextField {...params} label="Retention Period"/>}
            />
            <Box mt={2} width={'100%'}>
                <WuStack spacing={2} alignItems={'center'} boxProps={{width: '100%'}}>
                    <WuButton onClick={setRetentionPeriod}
                              width={300}
                              disabled={loading || !(data.accountId && data.region && data.retention)}>
                        Apply Retention
                    </WuButton>
                    <WuLoading message={'Please wait...'} open={loading}/>
                </WuStack>
            </Box>
            <Box mt={2}>
                <Collapse in={ready}>
                    <ResponseStatus response={responseData ? responseData.status : {}}
                                    total={responseData ? responseData.total : 0}
                                    failed={responseData ? responseData.failed : 0}/>
                </Collapse>
                <WuAlert error={error.error} successMessage={''} errorMessage={error.message} success={false}/>
            </Box>
        </Box>
    )
}

function DateTimeAutoCompleteRenderer({option}) {
    return (
        <WuStack>
            <Typography>
                {option}
            </Typography>
            <Typography color={"textSecondary"}>
                &nbsp;
                Days
            </Typography>
        </WuStack>
    )
}

const retentionPeriod = ["3", "5", "7", "14", "30", "60", "90", "120", "150", "180", "365", "400", "545", "731", "1827", "3653"]