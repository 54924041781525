import React, {useState} from 'react';
import SideMainMenu from "./SideMainMenu";
import {sideDrawerMenuConfiguration,} from "../../../constants";
import {Collapse, Divider, IconButton, Paper, Typography} from "@material-ui/core";
import {ArrowBackIosOutlined, SearchRounded} from "@material-ui/icons";
import {sideDrawerManagementConfiguration} from "../../../constants/sideDrawerMenuConfiguration";
import StaticBottomMenu from "./StaticBottomMenu";
import './index.scss'

export default function SideDrawer() {
    const [drawerExpand, setDrawerExpand] = useState(true)

    return (
        <nav className={drawerExpand ? 'sidedrawer-class side-expand' : 'sidedrawer-class side-collapse'}>
            <Paper elevation={0} className={'sidedrawer-container'}>
                <SearchUI drawerExpand={drawerExpand}/>
                <ExpandCollapse setDrawerExpand={setDrawerExpand} expanded={drawerExpand}/>
                <Divider/>
                <ul>
                    <MenuGroup open={drawerExpand} groupName={'Finops'}/>
                    {
                        sideDrawerMenuConfiguration.map((menu, idx) => <SideMainMenu
                            key={idx}
                            drawerExpanded={drawerExpand}
                            {...menu}
                        />)
                    }
                </ul>
                {
                    sideDrawerManagementConfiguration.length > 0 &&
                    <React.Fragment>
                        <Divider/>
                        <ul>
                            <MenuGroup open={drawerExpand} groupName={'Governance & Compliance'}/>
                            {
                                sideDrawerManagementConfiguration.map((menu, idx) => <SideMainMenu
                                    key={idx}
                                    drawerExpanded={drawerExpand}
                                    {...menu}
                                />)
                            }
                        </ul>
                    </React.Fragment>
                }
                <StaticBottomMenu drawerExpand={drawerExpand}/>
            </Paper>
        </nav>
    )
}

function SearchUI({drawerExpand}) {
    const className = drawerExpand ? 'search-wrapper' : 'search-wrapper  search-collapsed'
    return (
        <div className={className}>
            <SearchRounded className={'search-icon'}/>
            <input placeholder={'Search here'} type={'search'} className={'menu-search'}
                   disabled={true}
                   style={drawerExpand ? {} : {background: 'transparent'}}/>
        </div>
    )
}

function ExpandCollapse({setDrawerExpand, expanded}) {

    const handleChange = () => {
        setDrawerExpand(current => !current)
    }

    const collapsedStyle = {
        color: 'red',
        transform: 'rotate(180deg)'
    }

    return (
        <div className={'sidedrawer-collapse'} style={expanded ? {} : collapsedStyle}>
            {expanded}
            <IconButton onClick={handleChange}>
                <ArrowBackIosOutlined fontSize={'small'} style={{color: 'white'}}/>
            </IconButton>
        </div>
    )
}

function MenuGroup({groupName, open}) {
    return (
        <Collapse in={open}>
            <Typography color={'textSecondary'} style={{marginLeft: 8}} gutterBottom>{groupName}</Typography>
        </Collapse>
    )
}