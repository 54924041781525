import React, {useEffect, useState} from "react";
import {Button, Divider, Paper, Snackbar, Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import WuSummaryCard from "../../General/WuSummaryCard";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import service from "../../../service/service";
import {costFormatterGeneral, jsonParser} from "../../../helper/generalFunctions";
import {useOrganization} from "../../../hooks/useOrganization";
import {Alert} from "@material-ui/lab";

const sumHelp = 'Total Monthly savings possible in selected organization';
const countHelp = 'Total Number of resources with optimization possiblities';

export default function SummaryCard({filter}) {
    const {organization} = useOrganization();
    const [loading, setLoading] = useState(false);
    const [summary, setSummary] = useState([]);

    const [cronStatus, setCronStatus] = useState({
        status: false,
        message: undefined,
        loading: false
    });

    const fetchData = async () => {
        try {
            setLoading(true);
            const params = {master_account_id: organization, filter: filter}
            const {data: {data}} = await service.recommendations().getTrustedAdvisorSummary(params);
            setSummary(data);
        } catch (e) {
            const errorBody = jsonParser(e.request.response);
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (organization !== 'no org') {
            fetchData()
        }
    }, [organization, filter])


    const handleCronStartRequest = async () => {
        try {
            setCronStatus({
                status: false,
                message: undefined,
                loading: true
            })
            const {data: {data}} = await service.recommendations().getTrustedAdvisorCron();
            setCronStatus({
                ...cronStatus, ...{
                    status: true,
                    severity: 'success',
                    message: data
                }
            })
            await fetchData();
        } catch (e) {
            const errorBody = jsonParser(e?.request?.response)
            setCronStatus({
                ...cronStatus, ...{
                    status: true,
                    severity: 'error',
                    message: errorBody ? errorBody.error : e.message
                }
            })
            await fetchData();
        } finally {
            //setCronStatus({...cronStatus, loading: false})
        }
    }
    const handleSnackBarClose = () => {
        setCronStatus({...cronStatus, status: false})
    }

    useEffect(() => {
        console.log(cronStatus)
    }, [cronStatus])

    return (
        <Paper variant={'outlined'}>
            <SnackBarCronStatus open={cronStatus.status}
                                onClose={handleSnackBarClose}
                                message={cronStatus.message}
                                severity={cronStatus.severity}/>
            <Box m={2} className={'gap'} display={'flex'} flexDirection={'column'}>
                <WuSummaryCard
                    title={'Total Monthly Savings'}
                    value={`$${costFormatterGeneral(summary.estimatedMonthlySavings)}`}
                    loading={loading}
                    description={'Total monthly savings'}
                    borderBottom={true}
                    borderBottomColor={"#ff0404"}
                    helpText={sumHelp}/>
                <WuSummaryCard
                    title={'Resources'}
                    value={summary.resourceFlagged||'unknown'}
                    loading={loading}
                    description={'Number of resources'}
                    borderBottom={true}
                    borderBottomColor={"#24c400"}
                    helpText={countHelp}/>
                <Divider/>
                <Button variant={'contained'}
                        disabled={summary.processStatus === "UPDATING"}
                        disableElevation
                        onClick={handleCronStartRequest}> Refresh Recommendations </Button>
                <Divider/>
                <Box className={'gap'} display={'flex'}
                     flexDirection={'column'}
                     ml={1}>
                    <SummaryText title={'Refresh status'}
                                 value={summary.processStatus}
                                 node={<Icon status={summary.processStatus ? summary.processStatus : 'ERROR'}/>}/>
                    <Divider/>
                    <SummaryText title={'Last updated At'}
                                 value={summary.lastRunTime}/>

                    <Divider/>
                    <SummaryText title={'Next Update On '}
                                 value={summary.nextRunTime}/>
                </Box>
            </Box>
        </Paper>
    )
}

function SummaryText({title, value, node}) {
    return (
        <Box>
            <Typography variant={'subtitle2'}> {title}</Typography>
            <Box display={'flex'} gridGap={8} alignItems={'center'}>
                <Typography variant={'subtitle1'}> {value}</Typography>
                {node}
            </Box>
        </Box>
    )
}

export function Icon({status}) {
    if (status === 'UPDATING') return <ErrorOutlineIcon style={{color: "orange"}}/>
    if (status === 'FAILED') return <ErrorOutlineIcon style={{color: "red"}}/>
    if (status === 'READY') return <CheckCircleOutlineOutlinedIcon style={{color: "green"}}/>
    else return <ErrorOutlineIcon style={{color: "red"}}/>
}


function SnackBarCronStatus(props) {
    const {open, severity, message} = {...props}
    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={() => props.onClose(true)}>
            <Alert severity={severity}>
                {message}
            </Alert>
        </Snackbar>
    )
}