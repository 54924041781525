import React, {useState} from "react";
import {Link, useHistory, Redirect, useLocation} from 'react-router-dom';
import Paper from "@material-ui/core/Paper";
import '../user.scss';
import {Typography, TextField, Button} from "@material-ui/core";

import {makeStyles} from '@material-ui/core/styles';
import Logo from "../../../assets/images/logo.png";
import green from "@material-ui/core/colors/green";
import CircularProgress from "@material-ui/core/CircularProgress";
import {SnackbarCustomProgress} from "../../ProgressCircle/ProgressCircle";
import {loginFunction, validateEmail} from "../../../authentication/Authentication";
import FormHelperText from "@material-ui/core/FormHelperText";
import Box from "@material-ui/core/Box";
import GlobeImage from "../../../assets/images/western_union_globe2.svg"
import {useAuthorization} from "../../../hooks";

const useStyles = makeStyles((theme) => ({
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    buttonProgress: {
        position: 'absolute',
        top: '64%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export default function Login() {
    const history = useHistory();
    const classes = useStyles();
    const [userName, setUserName] = useState("xxxxxxxx@test.com");
    const [password, setPassword] = useState("******************");
    const [error, setError] = useState(false);
    const [authorization, setAuthorization] = useAuthorization()
    const [userInfo, setUserInfo] = useState({
        userName: "xxxxxxxx@test.com",
        password: "***********************"
    })

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    function loginHandler() {
        setLoading(true);
        setError(false);
        const authentication = loginFunction(userName, password)
        if (authentication.login) {
            if (authentication.logonType === "admin") {
                localStorage.setItem('loginType', 'admin')
                if (authentication.loginPriority) {
                    localStorage.setItem('loginPriority', 'super-admin')
                }
            } else if (authentication.logonType === "bu_champion") {
                localStorage.setItem('loginType', 'bu_champion')
            }
            localStorage.setItem('x-auth', 'true');
            setMessage("logging in...");
            setLoading(false);
            setAuthorization({userData: null, authenticated: true})
            history.push('/dashboard')
            //window.location.reload();
        } else {
            setError(true);
            localStorage.setItem('x-auth', 'false');
            setLoading(false);
        }
    }

    return (

        <div className="user-wrapper" style={{backgroundImage: `url(${GlobeImage})`}}>
            <Box className="user-container" style={{backdropFilter: `blur(5px)`}}>
                <div className="user-login-wrapper">
                    <img
                        alt={'logo'}
                        src={Logo}
                        style={{width: 125}}
                    />
                    <form className={classes.root} noValidate={false} autoComplete="off">
                        <TextField
                            margin={"dense"}
                            error={error}
                            style={{marginTop: 30, marginBottom: 20}}
                            autoFocus
                            fullWidth
                            type={"email"}
                            required={true}
                            variant={"outlined"}
                            value={userName}
                            onChange={(event) => {
                                setError(false);
                                setUserName(event.target.value)
                            }}
                            label={"Email"}
                        />
                        <TextField
                            margin={"dense"}
                            error={error}
                            style={{marginBottom: 25}}
                            fullWidth
                            required={true}
                            type={"password"}
                            variant={"outlined"}
                            value={password}
                            onChange={(event) => {
                                setError(false);
                                setPassword(event.target.value)
                            }}
                            label={"Password"}
                        />
                        <div style={{position: 'relative'}}>
                            <Button
                                fullWidth
                                style={{height: 35, marginBottom: 25}}
                                variant="contained"
                                disableElevation
                                disabled={loading}
                                onClick={loginHandler}
                                color="secondary">
                                Login
                            </Button>
                            {loading &&
                            <CircularProgress color={"secondary"} size={24} className={classes.buttonProgress}/>}
                        </div>

                        <Button
                            fullWidth
                            style={{height: 35, marginBottom: 25}}
                            variant="outlined"
                            disableElevation
                            disabled={true}
                            onClick={loginHandler}
                            color="secondary">
                            Login with third party
                        </Button>
                        <FormHelperText error={error} style={{textAlign: "center", marginTop: 20}} id="my-helper-text">
                            {error &&
                            <>{message}</>
                            }
                        </FormHelperText>
                    </form>

                </div>
            </Box>
        </div>
    )
}