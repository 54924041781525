import React, {useState} from "react";
import PageTitle from "../../General/PageTitle/PageTitle";
import UnUsedResourceFilter from "../UnUsedEbs/UnUsedResourceFilter";
import ElbAccordion from "./ElbAccordion";
import service from "../../../service/service";
import UnusedEc2DownloadReport from "../UnusedEc2/UnusedEc2DownloadReport";
import WuStack from "../../General/WuStack";
import SummaryCard from "../SummaryCard";


export default function ElbHome() {
    const [filter, setFilter] = useState([]);
    const refreshFunctionApiCall = service.monitoring().onDemandCronRefreshEc2
    const summaryFunctionApiCall = service.monitoring().overAllSummaryEc2

    const summaryProps = {
        summaryCardProps: {
            countCard: {
                itemKey: 'numberOfEc2',
                description: 'number of unused ec2 instances',
                helpText: 'Ec2 instances with CPU utilization less than 10%',
                nameOfItem: 'EC2 Summary',
                title: '# of Low utilization EC2',
            }
        },
        downloadComponent: <UnusedEc2DownloadReport/>,
        refreshApiFunction: refreshFunctionApiCall,
        summaryApiFunction: summaryFunctionApiCall,
    }

    return (
        <div style={{width: '100%'}}>
            <WuStack style={{marginTop: 20}} spacing={3} direction={'column'}>
                <PageTitle
                    title={"Wastage Monitoring"}
                    subTitle={" Unused EC2 Instances"}
                />
                <UnUsedResourceFilter
                    backButtonLink={'/monitoring/log-tracking/summary'}
                    onClick={(event) => setFilter(event)}/>
            </WuStack>
            <WuStack style={{marginTop: 20}} spacing={3}>
                <ElbAccordion filter={filter}/>
                <SummaryCard {...summaryProps}/>
            </WuStack>
        </div>
    )
}