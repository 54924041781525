import React from 'react';
import {Box, Paper} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import MostSpendAccountGraph from "./MostSpendAccountGraph";


export default function MostSpendAccount({accountSpend}) {
    return (
        <Box m={2} mt={4} mb={5} className={'w-100 gap'}>
            <Paper variant={"outlined"}>
                <Box m={2} ml={3}>
                    <Typography>
                        Account Wise Spend for Organizations
                    </Typography>
                </Box>
                <Box m={2} mt={1} mb={2} display='flex' className={'w-100 gap'}>
                    {
                        accountSpend.map((spend, index) => {
                            return <MostSpendItem key={index} graphData={spend}/>
                        })
                    }
                </Box>
            </Paper>
        </Box>
    )
}

function MostSpendItem({graphData}) {
    return (
        <Paper variant="outlined" className={'w-100 total-spend-card-wrapper'}>
            <Box m={2} mt={3}>
                <Box ml={2}>
                    <Typography gutterBottom variant={'body1'}> {graphData.organizationAlias} Accounts </Typography>
                </Box>
                <MostSpendAccountGraph responseGraphData={graphData}/>
            </Box>
        </Paper>
    )
}

