import {useEcs} from "../../../hooks";
import DataGrid from "../../General/DataGrid";
import {Box} from "@material-ui/core";
import {columns} from './gridProperties'
import {useEffect, useState} from "react";
import {apiErrorResponseJsonParser} from "../../../helper/generalFunctions";
import WuLoading from "../../General/WuLoading";
import WuAlert from "../../General/WuAlert";
import service from "../../../service/service";
import {ecsTaskList} from "../../../assets/finthesys/ecs";

export default function Tasks(props) {
    const {accountId, open, filter: localFilter, gridHeight = '300px'} = {...props}
    const [apiProgress, setApiProgress] = useState({loading: false, ready: true, error: false})
    const [taskData, setTaskData] = useState([])
    const {ecs} = useEcs()

    const fetchData = async () => {
        try {
            setApiProgress({loading: true, ready: false, error: false})
            const params = {
                accountId: accountId,
                filter: localFilter?.clusterArn ? localFilter : ecs.filter || null
            }
            const {data: {data}} = await service.ecs().listEcsTask(params)
            setTaskData(data)
            setApiProgress({loading: false, ready: true, error: false})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setApiProgress({loading: false, ready: false, error: error})
        }
    }

    const fetchDummyData = async () => {
        try {
            setTaskData([])
            setApiProgress({loading: true, ready: false, error: false})
            const params = {
                accountId: accountId,
                filter: localFilter?.clusterArn ? localFilter : ecs.filter || null
            }
            setTimeout(()=>{
                const {data} = ecsTaskList
                setTaskData(data)
                setApiProgress({loading: false, ready: true, error: false})
            }, 2000)

        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setApiProgress({loading: false, ready: false, error: error})
        }
    }




    useEffect(() => {
        fetchDummyData().then()
    }, [accountId, open, ecs?.filter])


    return (
        <div className={'ecs-content-wrapper'}>
            <Box m={apiProgress.loading ? 1 : 0}>
                <WuLoading message={'task information loading. Please wait...'} open={apiProgress.loading}/>
            </Box>
            <WuAlert error={Boolean(apiProgress.error)} success={false} errorMessage={apiProgress.error}/>
            {apiProgress.ready && <DataGrid
                gridStyle={{height: taskData.length > 5 ? gridHeight : taskData.length === 1 ? "90px" : taskData.length * 65 + "px"}}
                gridData={taskData}
                fixedColumn={columns}
            />}
        </div>
    )
}