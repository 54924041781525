import moment from "moment";

/**
 *
 * @param searchItem - item to search inside the array
 * @param iterationKey - item to be cross match in the input array
 * @returns {integer}
 */
function indexCalculator(inputArray, searchItem, iterationKey) {
    const index = inputArray.findIndex((item, localIndex) => {
        if (item[iterationKey] === searchItem) {
            return true
        }
    })
    return index
}

/**
 *
 * @param number {string} - number or string to be converted to non decimal number
 * @returns {number} - non decimal number
 */
function removeDecimals(number) {
    return Math.floor(Number(number))
}

function numberToMonth(data) {
    return moment().month(Number(data)).format("MMMM")
}

/**
 *
 * @param {array} organizationList - list of organization
 * @param {string} name  Account Alias
 * @returns {string} converted name
 */
function accountNameMapper(organizationList = [], name) {

    if (organizationList.length >= 1) {
        return organizationList.filter(v => {
            return (v.OrganizationName === name)
        })[0]['OrganizationAlias']
    }


    let newName = ""
    switch (name) {
        case "wulzmasterbilling":
            newName = "GREENFIELD"
            break;
        case "wutestlandingzone":
            newName = "GREENFIELD LAB"
            break
        default:
            newName = name;
    }
    return newName
}

/**
 *
 * @param  number {string, number} - number to convert
 * @returns {string}
 */
function removeCommaAndDecimals(number) {
    return removeDecimals(number).toLocaleString()
}


function removeDecimalsAddCommaToArray(array) {
    let tempArray = [];
    array.forEach((item) => {
        Object.keys(item).forEach((key) => {
            if (!key.toUpperCase().includes("ACCOUN")) {
                if (!isNaN(Number(item[key])) && item[key] && item[key] === " ") {
                    item[key] = Math.floor(Number(item[key])).toLocaleString()
                }
            }

        })
        tempArray.push(item)
    })
    return tempArray
}

/**
 *
 * @param environments {array} - array of environments
 * @returns {{QA: boolean, PROD: boolean, DEV: boolean, STAGE: boolean, UAT: boolean}}
 */
async function environmentMapper(environments) {
    let environmentList = {
        DEV: false,
        QA: false,
        STAGE: false,
        UAT: false,
        PROD: false,
    }

    if (environments.length > 0) {
        await environments.forEach((environment) => {
            switch (environment) {
                case 1:
                    environmentList["DEV"] = true
                    break;
                case 2:
                    environmentList["QA"] = true
                    break;
                case 3:
                    environmentList["STAGE"] = true
                    break;
                case 4:
                    environmentList["UAT"] = true
                    break;
                case 5:
                    environmentList["PROD"] = true
                    break;
                default:
                    break
            }
        })
    }

    return environmentList
}

function costFormatter(param) {
    if (param.value === null) return 0
    if (!param.value) return '-'
    if (isNaN(param.value)) return '-';
    return param ? parseFloat(Number(param.value).toFixed(2)).toLocaleString() : ""
}

function costFormatterGeneral(data) {
    if (!data) return 0
    return Math.floor(data).toLocaleString()
}

function jsonParser(data) {
    try {
        return JSON.parse(data)
    } catch (e) {
        return false
    }
}


function apiErrorResponseJsonParser(error) {
    try {
        return JSON.parse(error.request.response).error
    } catch (e) {
        return error.message
    }
}

function axiosErrorResponseParser(data) {
    try {
        return JSON.parse(data.request.response)
    } catch (e) {
        return false
    }
}

/**
 * @description - adjust account id length to 12 length
 * @param accountId
 */
function accountLengthAdjust(accountId) {
    if (!accountId) return "";
    let accountIdNew = accountId.toString();
    let accountLength = accountIdNew.length;
    if (accountLength < 12) {
        let difference = 12 - accountLength;
        //accountIdNew = accountIdNew.padStart(2, '0'); for some reason not working

        for (let i = 0; i < difference; i++) {
            accountIdNew = "0" + accountIdNew;
        }
    }
    return accountIdNew;
}

function dataFromArn(arn) {
    if (!arn) return ''
    const dataArray = arn.split(':')
    return {
        serviceName: dataArray[2],
        region: dataArray[3],
        accountId: dataArray[4],
    }

}

export {
    indexCalculator,
    removeDecimals,
    numberToMonth,
    accountNameMapper,
    removeCommaAndDecimals,
    removeDecimalsAddCommaToArray,
    environmentMapper,
    costFormatter,
    jsonParser,
    accountLengthAdjust,
    costFormatterGeneral,
    axiosErrorResponseParser,
    dataFromArn,
    apiErrorResponseJsonParser
}