import React from "react";
import {TextField} from "@material-ui/core";
import {useField} from "formik";


export default function WuTextField(props) {
    return (
        <TextField
            variant={"outlined"}
            margin={"dense"}
            {...props}
            fullWidth
        />
    )
}


export function WuValidationEnabledTextField(props) {
    const {name} = {...props}
    const [field, meta] = useField(name);

    const configTextField = {
        ...props,
        ...field,
        size: 'small',
    };

    if (meta && meta.touched && meta.error) {
        configTextField.error = true;
        configTextField.helperText = meta.error;
    }

    return <WuTextField {...configTextField} />
}