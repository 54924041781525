import React, {useEffect, useState} from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {DeleteOutlined} from "@material-ui/icons";
import service from "../../../service/service";
import {useOrganization} from "../../../hooks/useOrganization";

export default function TagReportKeyValue(props) {
    const {organization: masterAccountId} = useOrganization()
    const {tagList, onChange, onClick} = props
    const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
    const checkedIcon = <CheckBoxIcon fontSize="small"/>;

    const [tagValue, setTagValue] = useState([]);
    const [tagKey, setTagKey] = useState("");
    const [finopsTags, setFinopsTags] = useState([]);
    const [tagOptions, setTagOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingComplete, setLoadingComplete] = useState(false);

    const fetchFinopsTags = async () => {
        try {
            const {data: {data}} = await service.automations().getAutoTaggingResourceTags();
            setFinopsTags(data.map(e => e.TagKey))
        } catch (e) {
            console.log(e)
        }
    }

    const fetchOptions = async () => {
        try {
            setLoading(true)
            setTagOptions([])
            const params = {searchKey: tagKey, masterAccountId: masterAccountId}
            const {data: {data}} = await service.general().getTagValuesByKey(params)
            setTagOptions(data)
            setLoadingComplete(true)
            setLoading(false)
        } catch (e) {
            console.log(e)
        }
    }

    const handleRemove = (event) => {
        onClick(event)
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (tagKey) {
                fetchOptions().then()
            }
        }, 400)
        return () => clearTimeout(delayDebounceFn)
    }, [tagKey])

    useEffect(() => {
        handleChange();
    }, [tagValue,tagKey])

    function handleChange() {
        console.log(tagKey, )
        onChange({
            Key: tagKey,
            Values: tagValue
        })
    }

    useEffect(() => {
        fetchFinopsTags().then()
    }, [])

    return (
        <div style={{width: "100%"}} className="a-i-c">
            <div style={{width: "100%", maxWidth: 762}} className="tag-report-filter">
                <Autocomplete
                    options={finopsTags}
                    fullWidth
                    freeSolo
                    getOptionLabel={(tagKey) => tagKey}
                    onInputChange={(event, value) => setTagKey(value)}
                    style={{minWidth: 200}}
                    size={"small"}
                    renderInput={(params) => <TextField {...params} label={"Tag- key"}
                                                        variant="outlined"/>}
                />

                <Autocomplete
                    multiple
                    size="small"
                    freeSolo
                    getOptionLabel={(tagValue) => tagValue}
                    limitTags={2}
                    style={{minWidth: 200}}
                    options={tagOptions || []}
                    disableCloseOnSelect={true}
                    loading={loading}
                    onChange={(event, value) => setTagValue(value)}
                    renderOption={(tagValue, {selected}) => (
                        <React.Fragment>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{marginRight: 8}}
                                checked={selected}
                            />
                            {tagValue}
                        </React.Fragment>
                    )}
                    fullWidth={true}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Tag-value"
                                   placeholder="Tag-value"/>
                    )}
                />


                <Tooltip title="Delete entry" placement="right">
                    <IconButton
                        style={{marginLeft: 2, padding: "10px 10px"}}
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={handleRemove}
                    >
                        <DeleteOutlined/>
                    </IconButton>
                </Tooltip>
            </div>
        </div>
    )
}