import React, {useEffect, useState} from "react";
import {Redirect, Route, Switch, useHistory} from "react-router-dom";

import Budget from '../components/Budget/Budget';
import OnBoarding from '../components/OnBoarding/OnBoarding';
import Report from '../components/Report/Report'
import LandingPage from "../components/LandingPage/LandingPage";
import Recommendations from "../components/Recommendations/Recommendations";
import Automation from "../components/Automation";
import TrustedAdvisorRecommendations
    from "../components/Recommendations/TrustedAdvisorRecommendations/TrustedAdvisorRecommendations";
import CostAndUsage from "../components/Report/CostAndUsage/CostAndUsage";
import CostExplorer from "../components/Report/CostExplorer/CostExplorer";
import TagReport from "../components/Report/TagReport/TagReport";
import Login from "../components/User/Login/Login";
import {MainApp} from "../App";
import ApplicationOnBoarding from "../components/OnBoarding/ApplicationOnBoarding/ApplicationOnBoarding";
import AccountTable from "../components/Setting/Account/AccountListing/Account";
import CostAnomaly from "../components/Monitoring/CostAnomaly/CostAnomaly";
import TagCompliance from "../components/Monitoring/TagCompliance/TagComplianceHeader";
//import TagCompliance from "../components/Monitoring/TagCompliance/TagCompliance"
import Monitoring from "../components/Monitoring/Monitoring";
import ShowbackReport from "../components/Report/ShowbackReport/ShowbackReport";
import PowerSchedule from "../components/Automation/PowerSchedule/PowerSchedule";
import GuardRail from "../components/GuardRail/GuardRail";
import TagPolicy from "../components/GuardRail/TagPolicy/TagPolicy";
import ProtectedRoute from "./ProtectedRoute";
import ComputeRecommendation
    from "../components/Recommendations/ComputeOptimizer/ComputeOptimizerRecommendation/ComputeRecommendation";
import CustomWuReport from "../components/Insights/CustomWuReport/CustomWuReport";
import PurchaseRecommendations from "../components/Insights/SavingsRecommendations/PurchaseRecommendations";
import Insights from "../components/Insights/Insights";
import SavingsPlan from "../components/Insights/SavingsRecommendations/SavingsPlan/SavingPlan";
import InsightTable from "../components/Insights/SavingsRecommendations/SavingsPlan/Insight Table/InsightTable";
import SavingsPlanRecommendation
    from "../components/Insights/SavingsRecommendations/SavingsPlan/SavingsPlanRecommendation/SavingsPlanRecommendation";
import SavingsPlanCoverage
    from "../components/Insights/SavingsRecommendations/SavingsPlan/SavingsPlanCoverageReport/SavingsPlanCoverage";
import Reservations from "../components/Insights/SavingsRecommendations/Reservations/Reservations";
import UpcomingFeature from "../components/General/UpcomingFeature/UpcomingFeature";
import ReservationCoverage
    from "../components/Insights/SavingsRecommendations/Reservations/ReservationCoverageReport/ReservationCoverageReport";
import ChangeLog from "../components/About/ChangeLog";
import ReservationRecommendation
    from "../components/Insights/SavingsRecommendations/Reservations/ReservationRecommendation/ReservationRecommendation";
import ComputeOptimizerHome from "../components/Recommendations/ComputeOptimizer/ComputeOptimizerHome";
import SummaryAccordion
    from "../components/Recommendations/ComputeOptimizer/ComputeOptimizerRecommendation/SummaryAccordion";
import Application from "../components/Budget/Application/Application";
import Account from "../components/Budget/Account/Account";
import Setting from "../components/Setting/Setting";
import UnUsedEbsHome from "../components/Monitoring/UnUsedEbs/UnUsedEbsHome";
import UnusedHome from "../components/Monitoring/UnusedHome/UnusedHome"
import Unused from "../components/Monitoring/UnusedHome/UnUsed"
import UnUsedEC2Home from "../components/Monitoring/UnusedEc2/UnusedEc2Home"
import UnUsedSnapshotHome from "../components/Monitoring/UnusedSnapshot/SnapshotHome"
import UnUsedAmiHome from "../components/Monitoring/UnusedAmi/UnusedAmiHome";
import ElbHome from "../components/Monitoring/UnUsedElb/ElbHome"
import CloudWatchLogHome from "../components/Monitoring/CloudWatchLog/CloudWatchLogHome"
import Log from "../components/Monitoring/LogHome/Log"
import LogHome from "../components/Monitoring/LogHome/LogHome"
import ManagementAndGovernance from "../components/ManagementAndGovernance";
import PolicyWithAdminPrivilege from "../components/ManagementAndGovernance/IAM/PolilcyWithAdminPrivilege";
import SecurityGroup from "../components/ManagementAndGovernance/SecurityGroup";
import CustomReport from "../components/Report/CustomReport";
import NewlyProvisionedResources from "../components/Monitoring/NewlyProvisonedResources";
import IpRangeMonitor from "../components/ManagementAndGovernance/IpRangeMonitor";
import EFSData from "../components/Monitoring/EfsMonitoring/EfsData";
import EfsMonitoring from "../components/Monitoring/EfsMonitoring";
import AccountTrend from "../components/AccountTrend";
import ECS from "../components/ECS";
import ResourceTags, {ResourceTagsV2} from "../components/ResourceTags";
import TagComplianceV2 from "../components/Monitoring/TagComplianceV2";
import {AutoTagging} from '../components'

import Tagging from "../components/Tagging";

function MainRoute() {
    return (
        <Switch>
            <Route exact path="/">
                <Redirect to="/dashboard"/>
            </Route>
            <Route path="/mg" component={ManagementAndGovernance}/>
            <Route path="/dashboard" component={LandingPage}/>
            <Route path="/budget" component={Budget}/>
            <Route path="/report" component={Report}/>
            <Route path="/on-boarding" component={OnBoarding}/>
            <Route path="/recommendations" component={Recommendations}/>
            <Route path="/automations" component={Automation}/>
            <Route path="/monitoring" component={Monitoring}/>
            <Route path="/guard-rails" component={GuardRail}/>
            <Route path="/insights" component={Insights}/>
            <Route path="/about" component={ChangeLog}/>
            <Route path="/settings" component={Setting}/>
            <Route path="/network" component={IpRangeMonitor}/>
            <Route path="/tagging" component={Tagging}/>

            <Route path="/ecs/:resourceType" component={ECS}/>
            <Route path="/ecs" component={ECS}/>
        </Switch>
    );
}

function ParentRoute() {
    const history = useHistory();
    const [isAuth, setIsAuth] = useState(false);
    const [loading, setLoading] = useState(false);
    let requestUrl = window.location.pathname;
    const auth = localStorage.getItem('x-auth');

    useEffect(() => {
        if (auth === "true") {
            setIsAuth(true)
            if (requestUrl !== "/login") {
                history.push(requestUrl);
            } else {
                history.push("/dashboard")
            }
        }
    }, [loading])

    return (
        <Switch>
            <Route path="/login" component={Login}/>
            <Route path="/find-resource-tags/old" component={ResourceTags}/>
            <Route path="/find-resource-tags" component={ResourceTagsV2}/>
            <ProtectedRoute
                path="/"
                component={MainApp}
                isAuth={isAuth}
            />
        </Switch>
    );
}

function RecommenderRoute() {
    return (
        <Switch>
            <Route exact path="/recommendations">
                <Redirect to="/recommendations/trusted-advisor"/>
            </Route>
            <Route path='/recommendations/trusted-advisor' component={TrustedAdvisorRecommendations}/>
            <Route path='/recommendations/compute-optimizer' component={ComputeOptimizerHome}/>
            <Route path='/recommendations/power-schedule' component={PowerSchedule}/>
        </Switch>
    );
}

function ComputeOptimizerRoute(props) {
    return (
        <Switch>
            <Route exact path="/recommendations/compute-optimizer">
                <Redirect to="/recommendations/compute-optimizer/summary"/>
            </Route>
            <Route path='/recommendations/compute-optimizer/summary'
                   component={() => <ComputeRecommendation filter={props.filter}
                                                           onChange={event => props.onChange(event)}/>}/>
            <Route path='/recommendations/compute-optimizer/type/:recommendation'
                   component={() => <SummaryAccordion filter={props.filter}
                                                      onChange={event => props.onChange(event)}/>}/>
        </Switch>
    )
}

function OnBoardingRoute(props) {
    return (
        <Switch>
            <Route exact path="/on-boarding">
                <Redirect to="/on-boarding/application"/>
            </Route>
            <Route path='/on-boarding/application' component={ApplicationOnBoarding}/>
            <Route path='/on-boarding/account' component={AccountTable}/>
        </Switch>
    );
}

function ReportRoute() {
    return (
        <Switch>
            <Route exact path="/report">
                <Redirect to="/report/cost-explorer"/>
            </Route>
            <Route path='/report/cost-explorer' component={CostExplorer}/>
            <Route path='/report/tag' component={TagReport}/>
            <Route path='/report/billing' component={CostAndUsage}/>
            <Route path='/report/showback-reports' component={ShowbackReport}/>
            <Route path='/report/custom' component={CustomReport}/>
            <Route path='/report/account-trend' component={AccountTrend}/>
        </Switch>
    );
}

function MonitorRoute() {
    return (
        <Switch>
            <Route exact path="/monitoring">
                <Redirect to="/monitoring/cost-anomalies"/>
            </Route>
            <Route path='/monitoring/cost-anomalies' component={CostAnomaly}/>
            <Route path='/monitoring/efs-usage' component={EfsMonitoring}/>
            <Route path='/monitoring/tag-compliance' component={TagCompliance}/>
            <Route path='/monitoring/tag-compliance-v2/:source' component={TagComplianceV2}/>
            <Route path='/monitoring/tag-compliance-v2/' component={TagComplianceV2}/>
            <Route path='/monitoring/unused-resources' component={Unused}/>
            <Route path='/monitoring/log-tracking' component={Log}/>
            <Route path='/monitoring/newly-provisioned-resources' component={NewlyProvisionedResources}/>
        </Switch>
    );
}

function UnUsedRoute() {
    return (
        <Switch>
            <Route exact path="/monitoring/unused-resources">
                <Redirect to="/monitoring/unused-resources/summary"/>
            </Route>
            <Route path='/monitoring/unused-resources/unused-ebs' component={UnUsedEbsHome}/>
            <Route path='/monitoring/unused-resources/summary' component={UnusedHome}/>
            <Route path='/monitoring/unused-resources/unused-snapshot' component={UnUsedSnapshotHome}/>
            <Route path='/monitoring/unused-resources/unused-ec2' component={UnUsedEC2Home}/>
            <Route path='/monitoring/unused-resources/unused-ami' component={UnUsedAmiHome}/>
            <Route path='/monitoring/unused-resources/efs' component={EfsMonitoring}/>
            <Route path='/monitoring/unused-resources' component={UnUsedEbsHome}/>
        </Switch>
    );
}

function LogTrackingRoute() {
    return (
        <Switch>
            <Route exact path="/monitoring/log-tracking">
                <Redirect to="/monitoring/log-tracking/summary"/>
            </Route>
            <Route path='/monitoring/log-tracking/cloudwatchlog-monitoring' component={CloudWatchLogHome}/>
            <Route path='/monitoring/log-tracking/summary' component={LogHome}/>
            <Route path='/monitoring/log-tracking/elb-monitoring' component={ElbHome}/>

        </Switch>
    );
}

function GuardRailRoute() {
    return (
        <Switch>
            <Route exact path="/guard-rails">
                <Redirect to="/guard-rails/tag-policy"/>
            </Route>
            <Route path='/guard-rails/tag-policy' component={TagPolicy}/>
        </Switch>
    );
}

function AutomationRoute() {
    return (
        <Switch>
            <Route exact path="/automations">
                <Redirect to="/automations/power-schedule"/>
            </Route>
            <Route path='/automations/power-schedule' component={PowerSchedule}/>
            {/*<Route path='/automations/auto-tagging-v1' component={AutoTaggingv1}/>*/}
            <Route path='/automations/auto-tagging' component={AutoTagging}/>
        </Switch>
    );
}

function InsightRoute() {
    return (
        <Switch>
            <Route exact path="/insights">
                <Redirect to="/insights/wu-reports"/>
            </Route>
            <Route path='/insights/wu-reports' component={CustomWuReport}/>
            <Route path='/insights/account-trend' component={AccountTrend}/>
            <Route path='/insights/purchase-recommendations' component={PurchaseRecommendations}/>
        </Switch>
    );
}

function PurchaseRecommendationSubRoute() {
    return (
        <Switch>
            <Route exact path="/insights/purchase-recommendations">
                <Redirect to="/insights/purchase-recommendations/savings-plan"/>
            </Route>
            <Route path='/insights/purchase-recommendations/savings-plan' component={SavingsPlan}/>
            <Route path='/insights/purchase-recommendations/reservations' component={Reservations}/>
        </Switch>
    );
}

function SavingsPlanRoute() {
    return (
        <Switch>
            <Route exact path="/insights/purchase-recommendations/savings-plan">
                <Redirect to="/insights/purchase-recommendations/savings-plan/inventory"/>
            </Route>
            <Route path='/insights/purchase-recommendations/savings-plan/inventory' component={InsightTable}/>
            <Route path='/insights/purchase-recommendations/savings-plan/recommendations'
                   component={SavingsPlanRecommendation}/>
            <Route path='/insights/purchase-recommendations/savings-plan/coverage' component={SavingsPlanCoverage}/>
            <Route path='/insights/purchase-recommendations/savings-plan/utilization' component={UpcomingFeature}/>
        </Switch>
    );
}

function ReservationRoute() {
    return (
        <Switch>
            <Route exact path="/insights/purchase-recommendations/reservations">
                <Redirect to="/insights/purchase-recommendations/reservations/recommendations"/>
            </Route>
            <Route path='/insights/purchase-recommendations/reservations/recommendations'
                   component={ReservationRecommendation}/>
            <Route path='/insights/purchase-recommendations/reservations/coverage' component={ReservationCoverage}/>
            <Route path='/insights/purchase-recommendations/reservations/utilization' component={UpcomingFeature}/>
        </Switch>
    );
}

function BudgetRoute() {
    return (
        <Switch>
            <Route exact path="/budget">
                <Redirect to="/budget/application"/>
            </Route>
            <Route path='/budget/application' component={Application}/>
            <Route path='/budget/account' component={Account}/>
        </Switch>
    );
}

function ManagementAndGovernanceRoute() {
    return (
        <Switch>
            <Route exact path="/mg">
                <Redirect to="/mg/security-groups"/>
            </Route>
            <Route path='/mg/security-groups' component={SecurityGroup}/>
            <Route path='/mg/iam' component={PolicyWithAdminPrivilege}/>
            <Route path='/mg/ip-range' component={IpRangeMonitor}/>
        </Switch>
    );
}

function TaggingRoute() {
    return (
        <Switch>
            <Route exact path="/tagging">
                <Redirect to="/tagging/tag-compliance"/>
            </Route>
            <Route path='/tagging/tag-compliance/:source' component={TagComplianceV2}/>
            <Route path='/tagging/tag-compliance/' component={TagComplianceV2}/>
            <Route path='/tagging/auto-tagging' component={AutoTagging}/>
            <Route path='/tagging/tag-policy' component={TagPolicy}/>
        </Switch>
    );
}


export {
    MainRoute,
    RecommenderRoute,
    ReportRoute,
    ParentRoute,
    OnBoardingRoute,
    MonitorRoute,
    AutomationRoute,
    GuardRailRoute,
    InsightRoute,
    PurchaseRecommendationSubRoute,
    SavingsPlanRoute,
    ReservationRoute,
    ComputeOptimizerRoute,
    BudgetRoute,
    UnUsedRoute,
    LogTrackingRoute,
    ManagementAndGovernanceRoute,
    TaggingRoute,
};
