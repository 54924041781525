import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import propTypes from "prop-types";

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        padding: 8,
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        maxWidth: 175,
        fontSize: 13,
        letterSpacing: 0.5,
        zIndex: 1001,
    },
}))(Tooltip);

/**
 * @description - custom tool tip
 * @param {JSX.Element} children
 * @param {string} title - text/node to displayed on tool tip
 * @returns {JSX.Element}
 * @constructor
 */
export default function WuToolTip({children, title}) {
    return (
        <CustomTooltip title={title} interactive={true}>
            {children}
        </CustomTooltip>
    );
}


WuToolTip.propTypes = {
    children: propTypes.element.isRequired,
    title: propTypes.oneOfType(
        [propTypes.string,
            propTypes.element]
    )
}