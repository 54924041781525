import React from "react";
import {NavLink} from "react-router-dom";
import {ReservationRoute} from "../../../../routes/MainRoute";


function ReservationNavigation(){
    return(
        <nav className="savings-navigation">
            <ul>
                <li>
                    <NavLink to="/insights/purchase-recommendations/reservations/recommendations">
                        Recommendations
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/insights/purchase-recommendations/reservations/utilization">
                        Utilization Reports
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/insights/purchase-recommendations/reservations/coverage">
                        Coverage Report
                    </NavLink>
                </li>
            </ul>
        </nav>
    )
}


export default function Reservations(){
    return(
        <div className="savings-plan">
            <div className="content">
                <ReservationRoute/>
            </div>
            <ReservationNavigation/>
        </div>
    )
}