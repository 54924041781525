import React from "react";
import {SettingAccountRoute} from "../../../routes/SettingRoute";
import AccountSettingNavigation from "./Navigation/AccountSettingNavigation";


export default function AccountSetting() {
    return (
        <section id="account-setting" className="account-setting">
            <AccountSettingNavigation/>
            <SettingAccountRoute/>
        </section>
    )
}
