import React, {useEffect, useState} from "react";
import DataGrid from "../../../General/DataGrid/DataGrid";
import {Paper, Typography} from "@material-ui/core";
import service from "../../../../service/service";
import {jsonParser} from "../../../../helper/generalFunctions";
import {AlertWu} from "../../../AlertWu/AlertWu";
import Box from "@material-ui/core/Box";
import {ProgressCircleCustomSize} from "../../../ProgressCircle/ProgressCircle";

export default function FlaggedResources({account, data = [], filter}) {
    const [loading, setLoading] = useState(false);
    const [ready, setReady] = useState(false);
    const [error, setError] = useState({error: false, message: undefined});
    const [flaggedResources, setFlaggedResources] = useState([]);


    useEffect(() => {
        fetchData()
    }, [data])

    const fetchData = async () => {
        try {
            setReady(false);
            setLoading(true);
            setError({error: false, message: undefined})
            const params = {account_id: data.accountId, check_id: data.checkId, filter: filter}
            const {data: {data: results}} = await service.recommendations().getTrustedAdvisorFlaggedResource(params);
            setFlaggedResources(results);
            setReady(true)
        } catch (e) {
            const errorBody = jsonParser(e.request.response);
            setError({error: true, message: errorBody ? errorBody.error : e.message})
        } finally {
            setLoading(false)
        }
    }

    return (
        <Paper>
            {
                ready &&
                <DataGrid
                    fileName={`trusted-advisor-${account}`}
                    gridStyle={{height: "calc(100vh - 350px)"}}
                    gridData={flaggedResources}/>
            }
            {loading && <Loading checkName={account}/>}
            <AlertWu alertOpen={{state: error.error}} title={"Error"} severity={'error'}
                     message={error.message}/>

        </Paper>
    )
}

function Loading({account}) {
    return (
        <Box display={'flex'} alignItems={'center'} gridGap={8}>
            <ProgressCircleCustomSize size={16}/>
            <Typography>Loading resources for {account}...</Typography>
        </Box>
    )
}