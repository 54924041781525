import './index.scss'
import {Collapse, IconButton, MenuItem, Paper, Select, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {apiErrorResponseJsonParser} from "../../helper/generalFunctions";
import finOpsLogo from '../../assets/images/finops_logo.png'
import service from "../../service/service";
import ApplicationPreview from "./ApplicationPreview";
import {TransitionGroup} from "react-transition-group";
import WuLoading from "../General/WuLoading";
import {FileCopyOutlined} from "@material-ui/icons";
import WuToolTip from "../General/WuToolTip";
import ResourceTagsV2 from "./v2";

export default function ResourceTags() {
    const [application, setApplication] = useState([])
    const [filteredApplication, setFilteredApplication] = useState([])
    const [progress, setProgress] = useState({loading: false, error: false, ready: false})
    const [organizationList, setOrganizationList] = useState([])
    const [organization, setOrganization] = useState(null)
    const [selectedApplication, setSelectedApplication] = useState(null)
    const [finopsTags, setFinopsTags] = useState(null)

    const fetchData = async () => {
        try {
            setProgress({loading: true, error: false, ready: false})
            const params = {
                masterAccountId: organization
            }
            const {data: {data}} = await service.onBoarding().getApplicationList(params)
            setApplication(data)
            setFilteredApplication(data)
            setProgress({loading: false, error: false, ready: true})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setProgress({loading: false, ready: true, error: error})
        }
    }

    const fetchOrganization = async () => {
        try {
            const {data: {organizations}} = await service.account().getOrganizationList()
            const {data: {data: finopsTags}} = await service.automations().getAutoTaggingResourceTags();
            setFinopsTags(finopsTags.map(e => e.TagKey))
            setOrganizationList(organizations?.reverse())
            setOrganization(organizations[0]['AccountId'])
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setProgress({loading: false, ready: true, error: error})
        }
    }

    const handleClick = (e) => {
        setSelectedApplication(e)
    }

    const handleSearchChange = (newValue) => {
        if (application.length > 0) {
            if (newValue) {
                const filteredResults = application.filter(e => e?.ApplicationName?.toLowerCase().includes(newValue?.toLowerCase()))
                setFilteredApplication(JSON.parse(JSON.stringify(filteredResults)))
            } else {
                setFilteredApplication(application)
            }
        }
    }

    useEffect(() => {
        fetchOrganization().then()
    }, [])

    useEffect(() => {
        if (organization) fetchData().then()
    }, [organization])

    return (
        <Paper elevation={0}>
            <div className={'resource-tag-selector'}>
                <div className={'resource-tag-selector-wrapper'}>
                    <div className={'rt-organization-title'}>
                        <img src={finOpsLogo} alt={'finops-brand-log'}/>
                    </div>
                    <div className={'rt-organization-list'}>
                        <Typography color={'textSecondary'} gutterBottom style={{marginBottom: 12}}>
                            Select Organization
                        </Typography>
                        <Select variant={'outlined'}
                                fullWidth
                                value={organization}
                                onChange={(e) => setOrganization(e.target.value)}>
                            {
                                organizationList.map(e => <MenuItem value={e.AccountId}
                                                                    key={e.AccountId}>
                                    {e.OrganizationAlias}
                                </MenuItem>)
                            }
                        </Select>
                    </div>

                    <div className={'rt-application-wrapper'}>
                        <ApplicationSearch onChange={handleSearchChange}/>
                        <TransitionGroup>
                            {progress.loading && <Collapse in={progress.loading}>
                                <div style={{padding: '16px 12px'}}>
                                    <WuLoading message={'Please Wait...'} open={progress.loading}/>
                                </div>
                            </Collapse>}
                            {
                                filteredApplication.map(e => <Collapse timeout={200}
                                                                       key={e.OnBoardingId}>
                                    <ApplicationList
                                        {...e}
                                        onClick={handleClick}
                                        selectedApplication={selectedApplication}
                                    />
                                </Collapse>)
                            }
                        </TransitionGroup>
                    </div>
                </div>
                <div className={'rt-application-container'}>
                    <ApplicationPreview
                        finopsTags={finopsTags}
                        selectedApplication={application.filter(e => e.OnBoardingId === selectedApplication)}/>
                </div>
            </div>
        </Paper>
    )
}


function ApplicationSearch({onChange}) {
    const [searchKeyword, setSearchKeyword] = useState('')

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            onChange(searchKeyword)
        }, 400)

        return () => clearTimeout(delayDebounceFn)
    }, [searchKeyword])
    return (
        <div className={'rt-input'}>
            <input placeholder={'Search Application here'} type={'search'}
                   value={searchKeyword}
                   onChange={(e) => setSearchKeyword(e.target.value)}/>
        </div>
    )
}

function ApplicationList(props) {
    const {ApplicationName, OnBoardingId, onClick, selectedApplication} = {...props}

    return (
        <div className={selectedApplication === OnBoardingId ? 'rt-application rt-active' : 'rt-application'}
             onClick={() => onClick(OnBoardingId)}>
            {ApplicationName}
        </div>
    )
}

export function CopyContent({content}) {
    const [state, setState] = useState('click to copy')

    const onCopy = () => {
        if (typeof (content) === 'object') content = JSON.stringify(content, null, 4)
        navigator.clipboard.writeText(content).then()
        setState('copied')
        setTimeout(() => {
            setState('click to copy')
        }, 3000)
    }

    return (
        <div className={'rt-content-copy'}>
            <WuToolTip title={state}>
                <IconButton onClick={onCopy}>
                    <FileCopyOutlined/>
                </IconButton>
            </WuToolTip>
        </div>
    )
}

export {default as ResourceTagsV2} from './v2'