import React, {useEffect, useState} from "react";
import {
    Box,
    Checkbox,
    Chip,
    CircularProgress, Collapse,
    Divider,
    Fade,
    FormControlLabel,
    LinearProgress,
    Typography
} from "@material-ui/core";
import {LocalOfferOutlined} from "@material-ui/icons";
import {AdditionalTagAdd} from "./AdditionalTagAdd";
import {LabelWithHelpText, WuAlert, WuButton, WuDialog} from "../../General";
import {apiErrorResponseJsonParser} from "../../../helper/generalFunctions";
import service from "../../../service/service";
import {useForm} from "../../../hooks";
import CsvDownload from "react-json-to-csv";

export function ConfirmTagging(props) {
    const {open, resources, onClose, searchParams} = {...props}
    const {accountId = '', region = ''} = searchParams || {}
    const [additionalTags, setAdditionalTags] = useState([])
    const [finopsTags, setFinopsTags] = useState([])
    const [finalTagList, setFinalTagList] = useState([])
    const [apiProgress, setApiProgress] = useState({loading: false, ready: false, error: false})
    const [additionalTagsOnly, setAdditionalTagsOnly] = useState(false)
    const [failedBatches, setFailedBatches] = useState([])
    const [taggingStatus, setTaggingStatus] = useState([])
    const [batchConfig, setBatchConfig] = useState({
        batchSize: 0,
        currentBatch: 0,
    })
    const [advancedOptions, setAdvancedOptions] = useForm({
        tagDependedResources: true,
        enforceDefaultTagValues: true,
        tagUnFixableResources: false
    })

    const fetchFinopsTags = async () => {
        try {
            const {data: {data}} = await service.automations().getAutoTaggingResourceTags();
            setFinalTagList(data.map(e => ({tagKey: e.TagKey})))
            setFinopsTags(JSON.parse(JSON.stringify(data.map(e => ({tagKey: e.TagKey})))))
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            alert('request is not processable')
        }
    }
    const handleAdditionalTagChange = () => {
        const newTagMap = {}
        if (!additionalTagsOnly) {
            finopsTags.forEach(({tagKey, tagValue}) => {
                newTagMap[tagKey] = tagValue
            })
        }
        additionalTags.forEach(({tagKey, tagValue}) => {
            newTagMap[tagKey] = tagValue
        })
        const finalFinopsTagArray = Object.entries(newTagMap).map(e => ({tagKey: e[0], tagValue: e[1]}))
        setFinalTagList(finalFinopsTagArray)
    }


    const tagResources = async () => {
        try {
            const batchError = []
            setFailedBatches([])
            if (resources.length > 0) {
                setApiProgress({loading: true, ready: false, error: false})

                const BATCH_MAX = 50
                const resourceSmallChunk = []
                resources.forEach((item) => {
                    if (!resourceSmallChunk.length
                        || resourceSmallChunk[resourceSmallChunk.length - 1].length === BATCH_MAX) resourceSmallChunk.push([]);
                    resourceSmallChunk[resourceSmallChunk.length - 1].push(item);
                });
                let counter = 0
                setBatchConfig({
                    batchSize: resourceSmallChunk.length,
                    currentBatch: counter
                })
                let processId = null
                for (const batch of resourceSmallChunk) {
                    try {
                        counter += 1
                        setBatchConfig({batchSize: resourceSmallChunk.length, currentBatch: counter})
                        const params = {
                            accountId: accountId,
                            data: batch,
                            enforceOnlyForKeys: finalTagList,
                            ...advancedOptions
                        }
                        if (processId) {
                            params['processId'] = processId
                        }
                        const {data: {data}} = await service.automations().autoTaggingPerformTagging(params);
                        processId = data?.processId
                        setTaggingStatus(current => ({...current, ...data?.taggingStatus}))
                    } catch (e) {
                        const error = apiErrorResponseJsonParser(e)
                        batchError.push({batch: counter, error})
                        setFailedBatches(current => [...current, {batch: counter, error}])
                    }
                }
                setBatchConfig({batchSize: 0, currentBatch: counter})
                setApiProgress({loading: false, ready: true, error: false})
            }
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setApiProgress({loading: false, ready: false, error: error})
        }
    }

    useEffect(() => {
        fetchFinopsTags().then()
    }, [])

    useEffect(() => {
        handleAdditionalTagChange()
    }, [additionalTagsOnly])

    return (
        <WuDialog open={open}
                  title={'Confirm Tagging'}
                  size={'xs'}
                  titleNode={<DialogTitleBatchSize batchSize={batchConfig.batchSize}
                                                   failedBatches={failedBatches}
                                                   currentProcessingBatch={batchConfig.currentBatch}/>}
                  suppressDialogAction={true}
                  onChange={onClose}>
            <div>
                <Collapse in={apiProgress.loading}>
                    <LinearProgress color={'secondary'} variant={'determinate'}
                                    value={((100 / batchConfig.batchSize) * batchConfig.currentBatch) || 0}/>
                </Collapse>
                <Box m={2}>
                    <Typography>
                        Auto-Tagging will change tags for {resources.length || 0} resources in the
                        account(s) {accountId || 'undefined'}, in the region(s) {region || 'undefined'} for the
                        Application .
                    </Typography>
                </Box>
                <AdditionalTagAdd onChange={setAdditionalTags}
                                  value={additionalTags}
                                  onApply={handleAdditionalTagChange}/>
                <Box m={1} mb={0} ml={2}>
                    <FormControlLabel control={<Checkbox name={'applyAdditionalTagsOnly'}
                                                         onChange={(e) => setAdditionalTagsOnly(e.target.checked)}
                                                         disabled={additionalTags.length < 1}
                                                         checked={additionalTagsOnly}/>}
                                      label="Apply additional tags only"/>
                </Box>

                <Box m={2} mt={1}>
                    <TagValuePreview tags={finalTagList}/>
                </Box>
                <AdditionalOptions value={advancedOptions} onChange={setAdvancedOptions}/>
                {(Boolean(apiProgress.error) || apiProgress.ready) && <Box m={2}>
                    <WuAlert error={Boolean(apiProgress.error)}
                             success={apiProgress.ready}
                             errorMessage={apiProgress.error}
                             successMessage={'Tagging completed'}/>
                </Box>}
                <Divider/>
                <Box m={2} display={'flex'} gridGap={16}>
                    <WuButton startIcon={apiProgress.loading ? <CircularProgress size={16} color={'secondary'}/> : <LocalOfferOutlined/>}
                              onClick={tagResources}
                              disabled={apiProgress.loading}
                              color={'secondary'}>
                        CONFIRM TAGGING
                    </WuButton>
                    {Object.keys(taggingStatus).length > 0 &&
                    <WuButton variant={'text'}>
                        <CsvDownload
                            filename={'tagging_status.csv'}
                            data={objectToArray(taggingStatus)}
                            style={
                                {
                                    cursor: 'pointer',
                                    opacity: 0,
                                    left: 0,
                                    right: 0,
                                    top: 0,
                                    bottom: 0,
                                    position: 'absolute'
                                }
                            }/>
                        VIEW LOGS
                    </WuButton>
                    }
                </Box>
            </div>
        </WuDialog>
    )
}


function TagValuePreview(props) {
    const {tags} = {...props}
    return (
        <div className={'at-tag-value-preview'}>
            {tags.map(e => <Chip label={`${e.tagKey} ${e.tagValue ? '|' + e.tagValue : ''}`}
                                 key={e.tagKey}
                                 avatar={<LocalOfferOutlined
                                     fontSize={'small'}
                                     style={{marginLeft: 6, marginTop: 2}}
                                 />}
                                 size={'small'}/>
            )}

        </div>
    )
}

function AdditionalOptions({value, onChange}) {

    const handleChange = (e) => {
        onChange(e.target.name, e.target.checked)
    }

    return (
        <div className={'at-additional-tag-option'}>
            <Typography variant={'body2'} color={'textSecondary'} gutterBottom>Additional options</Typography>
            <FormControlLabel
                control={<Checkbox name={'tagDependedResources'} checked={value.tagDependedResources}
                                   onChange={handleChange}/>}
                label={
                    <LabelWithHelpText
                        title={'Tag Depended Resources'}
                        helpText={'Tag related resources, example tags for ec2 will be applied to volume and eni'}
                        titleColor={'textPrimary'}
                        titleVariant={'body1'}/>
                }/>
            <FormControlLabel
                control={<Checkbox name={'enforceDefaultTagValues'}
                                   disabled={true}
                                   checked={value.enforceDefaultTagValues}
                                   onChange={handleChange}/>}
                label={
                    <LabelWithHelpText
                        title={'Enforce Default values'}
                        helpText={'Values in the uploaded report will be overwritten by finops data'}
                        titleColor={'textSecondary'}
                        titleVariant={'body1'}/>
                }/>
            <FormControlLabel
                control={<Checkbox name={'tagUnFixableResources'}
                                   disabled={true}
                                   checked={value.tagUnFixableResources}
                                   onChange={handleChange}/>}
                label={
                    <LabelWithHelpText
                        title={'Tag unfixable resources'}
                        helpText={<UnfixableHelpText/>}
                        titleColor={'textSecondary'}
                        titleVariant={'body1'}/>
                }/>
        </div>
    )
}

function UnfixableHelpText() {
    return (
        <ul style={{padding: '8 px 16px'}}>
            <li>
                Enable this option, if you want to tag resources which have error(s)
            </li>
            <li>
                If this option enabled, the value shown in the preview for the resources will be used for tagging
            </li>
        </ul>
    )
}

function DialogTitleBatchSize({batchSize, currentProcessingBatch, failedBatches = []}) {
    return (
        <>
            {batchSize ? <div className={'at-tagging-batch-status'}>
                <div className={'at-confirm-tagging-title-loading'}>
                    <Typography>
                        {currentProcessingBatch} of {batchSize} Batches
                    </Typography>
                </div>
                <div className={'at-failed-batches'}>
                    <Typography align={'center'}>{failedBatches.length}</Typography>
                </div>
            </div> : null}
        </>

    )
}

function objectToArray(newObject) {
    return Object.entries(newObject).reduce((acc, curr) => {
        acc.push({ResourceArn: curr[0], Status: curr[1]})
        return acc
    }, [])
}