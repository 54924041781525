import React, {useState} from 'react';
import Filter from "./Filter";
import Summary from "./Summary";
import Box from "@material-ui/core/Box";
import WuStack from "../../General/WuStack";
import IpRangeDetails from "./IpRangeDetails";
import FilterContext from './FilterContext'

import './index.scss'
import PageTitle from "../../General/PageTitle/PageTitle";
import CustomTab from "../../General/CustomTab/CustomTab";


export default function IpRangeMonitor() {
    const [filter, setFilter] = useState({range_filter:'noSync'})
    const value = {filter, setFilter};

    return (
        <FilterContext.Provider value={value}>

            <div>
                <div className="title-div-wrapper">
                    <PageTitle title={"IP Address Management"} subTitle={'Manage critical subnets'}/>
                </div>
                {/*<CustomTab links={tabList}/>*/}
                <div className="title-div-wrapper">
                    <WuStack spacing={2} justifyContent={'space-between'}>
                        <Box width={'calc(100% - 250px)'}>
                            <Filter/>
                            <IpRangeDetails/>
                        </Box>
                        <Summary/>
                    </WuStack>
                </div>
            </div>
        </FilterContext.Provider>
    )
}