import React from "react";

/**
 *
 * @param {string} title - title for the page
 * @param {subtitle} subTitle - subtitle for the page
 * @return {JSX.Element}
 * @constructor
 */
export default function PageTitle({title, subTitle}) {
    const style = {
        heading: {
            fontSize: 20,
            fontWeight: 600,
            margin: 0,
            marginBottom: 10
        },
        subHeading: {
            fontSize: 14,
            margin: 0,
            fontWeight: 400
        }

    }


    return (
        <div>
            <h2 style={style.heading}>{title}</h2>
            <h5 style={style.subHeading}> {subTitle}</h5>
        </div>
    )
}