import React, {useEffect, useState} from "react";
import service from "../../../service/service";
import DataGrid from "../../General/DataGrid/DataGrid";
import IconButton from "@material-ui/core/IconButton";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import PageTitle from "../../General/PageTitle/PageTitle";
import {AddOutlined, DeleteOutlined} from "@material-ui/icons";
import ApplicationCreate from "./ApplicationCreate/ApplicationCreate";
import ApplicationUpdate from "./ApplicationUpdate/ApplicationUpdate";
import Fab from "@material-ui/core/Fab";
import ReplayOutlinedIcon from '@material-ui/icons/ReplayOutlined';
import {AlertWu} from "../../AlertWu/AlertWu";
import {PaperProgress} from "../../ProgressCircle/ProgressCircle";
import "./application_onboarding.scss";
import ApplicationDelete from "./ApplicationDelete/ApplicationDelete";
import {useOrganization} from "../../../hooks/useOrganization";
import {jsonParser} from "../../../helper/generalFunctions";
import WuFloatingDownloadButton from "../../General/WuFloatingDownloadButton";

const disabled = localStorage.getItem("loginType") !== "admin";
export default function ApplicationOnBoarding() {
    const organization = useOrganization()
    const [masterAccountId, setMasterAccountId] = useState(organization.organization);

    const [applicationData, setApplicationData] = useState({});
    const [pageReady, setPageReady] = useState(false);
    const [createOpen, setCreateOpen] = useState(false);
    const [updateOpen, setUpdateOpen] = useState(false);
    const [applicationLoading, setApplicationLoading] = useState(false);
    const [selectedApplicationData, setSelectedApplicationData] = useState({});
    const [update, setUpdate] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [error, setError] = useState({
        error: false,
        message: ""
    })

    useEffect(() => {
        setMasterAccountId(organization.organization);
    }, [organization])


    useEffect(() => {
        if (masterAccountId !== "no org") {
            request("update");
        }
    }, [masterAccountId])

    const columns = [
        {
            field: "ApplicationName",
            resizable: true,
            sortable: true,
            suppressAutoSize: false,
        },
        {
            field: "BusinessGroup",
            resizable: true,
            sortable: true,
            suppressAutoSize: false,
        },
        {
            field: "CostCode",
            headerName: "Cost Center",
            resizable: true,
            sortable: true,
            width: 120,
            suppressAutoSize: true,
            type: "numericColumn",
            filter: "agNumberColumnFilter"
        },
        {
            field: "BusinessOwner",
            resizable: true,
            sortable: true,
            suppressAutoSize: false,
        },
        {
            field: "AppCode",
            headerName: "Application Code",
            resizable: true,
            sortable: true,
            suppressAutoSize: false,
        },
        {
            field: "ApplicationOwner",
            resizable: true,
            sortable: true,
            suppressAutoSize: false,
        },
        {
            field: "SupportContact",
            resizable: true,
            sortable: true,
            suppressAutoSize: false,
        },
        {
            cellRenderer: "informationCellRenderer",
            sortable: false,
            resizable: false,
            filter: false,
            width: 75,
            suppressAutoSize: false,
            cellRendererParams: {
                clicked: function (field) {
                    handleApplicationUpdate(field)
                }
            },
        },
        {
            cellRenderer: "deleteCellRender",
            sortable: false,
            resizable: false,
            filter: false,
            width: 75,
            suppressAutoSize: false,
            cellRendererParams: {
                clicked: function (field) {
                    handleApplicationDelete(field)
                }
            },
        }
    ]

    const handleApplicationUpdate = (e) => {
        setSelectedApplicationData(e)
        setUpdateOpen(true);
    }

    const handleApplicationDelete = (e) => {
        setSelectedApplicationData(e)
        setDeleteOpen(true);
    }

    const BtnCellRenderer = (props) => {
        const btnClickedHandler = (field) => {
            props.clicked(props.data)
        }
        return (
            <IconButton onClick={btnClickedHandler} aria-label="info" size="medium">
                <InfoOutlinedIcon fontSize="inherit"/>
            </IconButton>
        )
    }

    const BtnCellDelete = (props) => {
        const btnClickedHandler = (field) => {
            props.clicked(props.data)
        }
        return (
            <IconButton onClick={btnClickedHandler} disabled={disabled} aria-label="delete" size="medium">
                <DeleteOutlined fontSize="inherit"/>
            </IconButton>
        )
    }

    useEffect(() => {
        if (masterAccountId !== "no org") {
            request("init");
        }
    }, [])


    const request = (event) => {
        setError({
            error: false,
            message: ""
        });
        setApplicationLoading(true);
        const params = {masterAccountId: masterAccountId}
        service.onBoarding().getApplicationList(params)
            .then((ref) => {
                setApplicationData(ref.data.data);
                setApplicationLoading(false);
                setPageReady(true);
                if (event === "update") {
                    setUpdate(true);
                }
            })
            .catch(err => {
                setPageReady(false);

                const errorBody = jsonParser(err.request.response)
                setError({
                    error: true,
                    title: errorBody.code === 402 ? "No Data" : undefined,
                    message: errorBody.error ? errorBody.error : err.message
                });
                setApplicationLoading(false);
            })
    }

    const handleCreateDialogOpen = () => {
        setCreateOpen(true)
    }

    return (
        <div className="">
            <div className={'title-div-wrapper'} style={{marginBottom: 20}}>
                <div className={"d-f"}>
                    <PageTitle title={"Application Onboarding"}/>
                    <IconButton disabled={disabled} onClick={handleCreateDialogOpen} style={{marginRight: 20}}
                                aria-label="create"
                                size="medium">
                        <AddOutlined fontSize="inherit"/>
                    </IconButton>
                </div>
            </div>
            <div className="title-div-wrapper" style={{marginTop: 0}}>
                <ApplicationCreate
                    onChange={(e) => {
                        setCreateOpen(e);
                        request("update")
                    }}
                    open={createOpen}/>
                <ApplicationUpdate
                    onChange={(e) => {
                        setUpdateOpen(e);
                    }}
                    onClick={(e) => request("update")}
                    applicationUpdateInformation={selectedApplicationData}
                    open={updateOpen}/>
                <ApplicationDelete
                    onClick={(e) => {
                        setDeleteOpen(e);
                        request("update")
                    }}
                    applicationInformation={selectedApplicationData}
                    open={deleteOpen}/>
                {
                    applicationLoading &&
                    <div style={{paddingTop: 20, paddingBottom: 20}}>
                        <PaperProgress/>
                    </div>

                }
                {
                    pageReady && applicationData.length > 0 &&
                    <div className="onboarding-grid-wrapper">
                        <DataGrid
                            fileName={"MigrationDashboard"}
                            fixedColumn={columns}
                            update={update}
                            frameworkComponents={{
                                informationCellRenderer: BtnCellRenderer,
                                deleteCellRender: BtnCellDelete
                            }}
                            gridData={applicationData}
                            gridClass="onboarding-grid"/>
                    </div>
                }

                <AlertWu
                    title={error.title ? error.title : null}
                    alertOpen={{state: error.error}}
                    severity={error.title ? "info" : "error"}
                    message={error.message}>
                    <div>
                        <IconButton onClick={() => request("init")} color="secondary"
                                    aria-label="add to shopping cart">
                            <ReplayOutlinedIcon/>
                        </IconButton>
                    </div>
                </AlertWu>
                <WuFloatingDownloadButton serviceFn={service.onBoarding().onBoardingExport}/>
            </div>
        </div>
    )
}