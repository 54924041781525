import React, {useState} from "react";
import PageTitle from "../../General/PageTitle/PageTitle";
import UnUsedResourceFilter from "../UnUsedEbs/UnUsedResourceFilter";
import UnusedEc2Accordion from "./UnusedEc2Accordion";
import '../../../service/service';
import SummaryCard from "../SummaryCard";
import WuStack from "../../General/WuStack";
import service from "../../../service/service";
import UnusedEc2DownloadReport from "./UnusedEc2DownloadReport";


export default function UnusedEc2Home() {
    const [filter, setFilter] = useState([]);
    const refreshFunctionApiCall = service.monitoring().onDemandCronRefreshEc2
    const summaryFunctionApiCall = service.monitoring().overAllSummaryEc2

    const summaryProps = {
        summaryCardProps:{
            countCard:{
                itemKey:'numberOfEc2',
                description:'number of unused ec2 instances',
                helpText:'Ec2 instances with CPU utilization less than 10%',
                nameOfItem:'EC2 Summary',
                title:'# of Low utilization EC2',
            }
        },
        downloadComponent: <UnusedEc2DownloadReport/>,
        refreshApiFunction: refreshFunctionApiCall,
        summaryApiFunction: summaryFunctionApiCall,

    }

    return (
        <div style={{width: '100%'}}>
            <WuStack style={{marginTop: 20}} spacing={3} direction={'column'}>
                <PageTitle
                    title={"Wastage Monitoring"}
                    subTitle={" Unused EC2 Instances"}
                />
                <UnUsedResourceFilter
                    backButtonLink={'/monitoring/unused-resources/summary'}
                    onClick={(event) => setFilter(event)}/>
            </WuStack>
            <WuStack style={{marginTop: 20}} spacing={3}>
                <UnusedEc2Accordion filter={filter}/>
                <SummaryCard {...summaryProps}/>
            </WuStack>
        </div>
    )
}