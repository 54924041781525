import React, {useState} from "react";
import {Button} from "@material-ui/core";
import WuTextField from "../../../General/WuTextFeild";
import {AlertWu} from "../../../AlertWu/AlertWu";

export function AddAccountCategory(props) {

    const [categoryName, setCategoryName] = useState("");
    const [comment, setComment] = useState("");

    const organizationCreate = () => {

        if (categoryName) {
            console.log(categoryName, comment)
            return true
        }
    }

    return (
        <React.Fragment>
            <div className="d-f-s" style={{marginTop: 20}}>
                <WuTextField
                    value={categoryName}
                    error={categoryName.length < 1}
                    onChange={(e) => {
                        setCategoryName(e.target.value)
                    }}
                    label="Category Name*"
                />
                <WuTextField
                    value={comment}
                    onChange={(e) => {
                        setComment(e.target.value)
                    }}
                    label="Comment"
                />
                <Button onClick={organizationCreate} variant={"contained"} disableElevation color={"secondary"}>
                    Save
                </Button>
            </div>
            <div style={{marginTop: 20}}>
                <AlertWu
                    alertOpen={{state: true}}
                    severity={"success"}
                    message={"new category added."}/>
            </div>
        </React.Fragment>
    )
}