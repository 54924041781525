export const urls = {
    dashboard: '/dashboard',
    applicationOnboardding: '/on-boarding/application',
    applicationBudget: '/budget/application',
    accountBudget: '/budget/account',
    report: '/report',
    guardRails: '/guard-rails',
    costRecommendation: '/recommendations',
    automations: '/automations',
    costAnomalies: '/monitoring/cost-anomalies',
    unusedResources: '/monitoring/unused-resources',
    tagCompliance: '/monitoring/tag-compliance',
    newDeployments: '/monitoring/newly-provisioned-resources',
    logTracking: '/monitoring/log-tracking',
    unusedEfs: '/monitoring/efs-usage',
    wuReport: '/insights/wu-reports',
    accountTrend: '/insights/account-trend',
    savingsPlan: '/insights/purchase-recommendations',
    about: '/about',
    settings: '/settings',
    management: '/mg',
    network: '/network',
    tagging: '/tagging',
    ecs: '/ecs'
}