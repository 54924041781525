import {costFormatter, indexCalculator, numberToMonth, removeDecimals} from "./generalFunctions";
import moment from "moment";

function MonthlySpendAllAccounts(data) {
    let masterAccountSumArray = [{data: []}]
    data.forEach((account) => {
        if (masterAccountSumArray[0]['data'].some(obj => obj.name === account.Month)) {
            const index = indexCalculator(masterAccountSumArray[0]['data'], account.Month, "name");
            if (index >= 0) {
                masterAccountSumArray[0]['data'][index]['y'] += removeDecimals(account.Cost)
            }
        } else {
            masterAccountSumArray[0]['data'].push({
                    name: account.Month,
                    x: `${account.Month}-15-${account.Year}`,
                    y: removeDecimals(account.Cost)
                }
            )
        }
    })
    masterAccountSumArray[0]['name'] = "Monthly YTD Spend"
    masterAccountSumArray[0]['data'] = sortDate(masterAccountSumArray[0]['data'])
    return masterAccountSumArray
}

function accountSpendGraph(data) {
    let tempAccountArray = []
    let graphData = []
    data.forEach((account) => {
        if (tempAccountArray.some(obj => obj.AccountID === account.AccountID)) {
            indexCalculator(tempAccountArray, account.AccountID, "AccountID")
            const accountIndex = indexCalculator(tempAccountArray, account.AccountID, "AccountID")
            if (accountIndex >= 0) {
                tempAccountArray[accountIndex].Cost += removeDecimals(account.Cost);

                const graphIndex = indexCalculator(graphData, account.AccountID, "name")
                if (graphIndex >= 0) {
                    graphData[graphIndex]['data'].push({
                        y: removeDecimals(account.Cost),
                        x: `${account.Month}-15-${account.Year}`
                    })
                }
            }

        } else {
            tempAccountArray.push({
                AccountID: account.AccountID,
                AccountAlias: account.AccountAlias,
                Cost: removeDecimals(account.Cost),
            });

            graphData.push({
                name: account.AccountID,
                data: [{
                    y: removeDecimals(account.Cost),
                    x: `${account.Month}-15-${account.Year}`
                }]
            })

        }
    })


    graphData.forEach((graph) => {
        graph['data'] = sortDate(graph['data'])
    })

    return {
        accountArray: tempAccountArray,
        graph: graphData,
        accountConsolidated: MonthlySpendAllAccounts(data),
        currentMonthSpend: MonthlySpendAllAccounts(data)[0]['data'][moment().format('M')] || 0
    }
}

function replaceMonthNumberWithName(data) {
    let tempData = []
    data.forEach((account) => {
        account.Month = numberToMonth(Number(account.Month) - 1);
        tempData.push(account);
    })
    return tempData
}

function tagReportKey(data) {
    let tempArray = []
    data.forEach((tagKeys) => {
        const tempKey = Object.keys(tagKeys)
        let values = []
        tagKeys[tempKey].forEach((keyValue) => {
            values.push(keyValue[tempKey])
        })
        if (values.length > 0) {
            tempArray.push({
                Key: tempKey[0] === 'CostCode' ? 'CostCenter' : tempKey[0],
                Values: values
            })
        }

    })
    return tempArray
}

function axisShiftMonthlyCostReport(data) {
    let tempArray = []
    let monthNames = []
    let columnName = [
        {field: 'AccountID', headerName: 'Account Id', resizable: true, pinned: "left"},
        {field: 'AccountAlias', headerName: 'Account Alias', resizable: true, pinned: "left"}
    ]
    data.forEach((account) => {
        const monthName = numberToMonth(Number(account.Month) - 1)
        const monthCost = Math.floor(account.Cost)
        if (!monthNames.includes(monthName)) {
            monthNames.push(monthName)
        }

        const status = tempArray.some((obj, index) => {
            if (obj.AccountID === account.AccountID.toString()) {
                tempArray[index][monthName] = monthCost
                tempArray[index]['Total'] += monthCost
                return true;
            }
        })
        if (!status) {
            tempArray.push({
                Total: monthCost,
                AccountID: account.AccountID,
                AccountAlias: account.AccountAlias,
                [monthName]: monthCost
            })
        }
    })
    monthNames.forEach((name) => {
        columnName.push(
            {
                field: name,
                valueFormatter: costFormatter,
                type: "numericColumn",
                filter: "agNumberColumnFilter",
                sortable: true,
                resizable: true
            }
        )
    })

    columnName.push({
        field: "Total",
        resizable: true,
        width: 140, suppressAutoSize: true,
        valueFormatter: costFormatter,
        type: "numericColumn",
        filter: "agNumberColumnFilter",
        sortable: true,
        pinned: "left"
    })
    return ({
        column: columnName,
        row: tempArray
    })
}

function axisShift(data) {
    // required format
    /*
    {
        ProductCode: "ec2",
        January: "45551:,
        February: "5643",
        March: "534535"
    },
    {
        ProductCode: "EBS",
        January: "45551:,
        February: "5643",
        March: "534535"
    }
     */
    let tempArray = []
    let monthNames = []
    let columnName = [
        {field: 'ProductCode', headerName: 'Service Name', resizable: true, 'width': 150, pinned: "left"},
    ]

    data.forEach((product) => {
        const monthName = numberToMonth(Number(product.Month) - 1)
        const monthCost = Math.floor(product.Cost)
        if (!monthNames.includes(monthName)) {
            monthNames.push(monthName)
        }

        const status = tempArray.some((obj, index) => {
            if (obj.ProductCode === product.ProductCode.toString()) {
                tempArray[index][monthName] = monthCost
                tempArray[index]['Total'] += monthCost
                return true;
            }
        })
        if (!status) {
            tempArray.push({
                Total: monthCost,
                ProductCode: product.ProductCode,
                [monthName]: monthCost
            })
        }
    })
    monthNames.forEach((name) => {
        columnName.push(
            {
                field: name,
                valueFormatter: costFormatter,
                type: "numericColumn",
                filter: "agNumberColumnFilter",
                sortable: true,
                resizable: true
            }
        )
    })

    columnName.push({
        field: "Total",
        resizable: true,
        width: 140, suppressAutoSize: true,
        valueFormatter: costFormatter,
        type: "numericColumn",
        filter: "agNumberColumnFilter",
        sortable: true,
        pinned: "left"
    })
    return ({
        column: columnName,
        row: tempArray
    })
}

function axisShiftApplication(data) {
    let tempArray = []
    let monthNames = []
    let columnName = [
        {field: 'ApplicationName', resizable: true, pinned: "left"},
    ]

    data.forEach((application) => {
        const monthName = numberToMonth(Number(application.Month) - 1)
        const monthCost = Math.floor(application.Cost)
        if (!monthNames.includes(monthName)) {
            monthNames.push(monthName)
        }

        const status = tempArray.some((obj, index) => {
            if (obj.ApplicationName === application.ApplicationName.toString()) {
                tempArray[index][monthName] = monthCost
                tempArray[index]['Total'] += monthCost
                return true;
            }
        })
        if (!status) {
            tempArray.push({
                Total: monthCost,
                ApplicationName: application.ApplicationName,
                [monthName]: monthCost
            })
        }
    })
    monthNames.forEach((name) => {
        columnName.push(
            {
                field: name,
                valueFormatter: costFormatter,
                type: "numericColumn",
                filter: "agNumberColumnFilter",
                sortable: true,
                resizable: true
            }
        )
    })

    columnName.push({
        field: "Total",
        resizable: true,
        width: 140, suppressAutoSize: true,
        valueFormatter: costFormatter,
        type: "numericColumn",
        filter: "agNumberColumnFilter",
        sortable: true,
        pinned: "left"
    })
    return ({
        column: columnName,
        row: tempArray
    })
}

function axisShiftEnvironment(data) {

    let tempArray = []
    let monthNames = []
    let columnName = [
        {field: 'AccountID', headerName: 'Account Id', 'width': 120, resizable: true, sortable: true, pinned: "left"},
        {field: 'AccountAlias', resizable: true, sortable: true, pinned: "left"},
        {field: 'Environment', resizable: true, sortable: true, pinned: "left"},
        {
            field: "Total",
            resizable: true,
            width: 140, suppressAutoSize: true,
            valueFormatter: costFormatter,
            type: "numericColumn",
            filter: "agNumberColumnFilter",
            sortable: true,
            pinned: "left"
        },
    ]

    data.forEach((item) => {
        const monthName = numberToMonth(Number(item.Month) - 1)
        const monthCost = Math.floor(item.Cost)
        if (!monthNames.includes(monthName)) {
            monthNames.push(monthName)
        }

        const status = tempArray.some((obj, index) => {
            if (obj.AccountID === item.AccountID.toString() && obj.Environment === item.Environment.toString()) {
                tempArray[index][monthName] = monthCost
                tempArray[index]['Total'] += monthCost
                return true;
            }
        })

        if (!status) {
            tempArray.push({
                Total: monthCost,
                AccountID: item.AccountID,
                AccountAlias: item.AccountAlias,
                Environment: item.Environment,
                [monthName]: monthCost
            })
        }
    })

    monthNames.forEach((name) => {
        columnName.push(
            {
                field: name,
                valueFormatter: costFormatter,
                type: "numericColumn",
                filter: "agNumberColumnFilter",
                sortable: true,
                resizable: true
            }
        )
    })
    return {column: columnName, row: tempArray}
}

function axisShiftSummary(data) {

    let tempArray = []
    let monthNames = []
    let columnName = [
        {
            field: "Total",
            resizable: true,
            width: 140, suppressAutoSize: true,
            valueFormatter: costFormatter,
            type: "numericColumn",
            filter: "agNumberColumnFilter",
            sortable: true,
            pinned: "left"
        }
    ]

    data.forEach((item) => {
        const monthName = numberToMonth(Number(item.Month) - 1)
        const monthCost = Math.floor(item.Cost)
        if (!monthNames.includes(monthName)) {
            monthNames.push(monthName)
        }

        const status = tempArray.some((obj, index) => {
            if (obj.AccountID === item.AccountID.toString()
                && obj.Environment === item.Environment.toString()
                && obj.CostCenter === item.CostCenter.toString()
                && obj.ApplicationOwner === item.ApplicationOwner.toString()
                && obj.ProductCode === item.ProductCode.toString()
                && obj.ApplicationName === item.ApplicationName.toString()) {
                tempArray[index][monthName] = monthCost
                tempArray[index]['Total'] += monthCost
                return true;
            }
        })

        if (!status) {
            tempArray.push({
                Total: monthCost,
                AccountID: item.AccountID,
                AccountAlias: item.AccountAlias,
                Environment: item.Environment,
                ApplicationName: item.ApplicationName,
                ProductCode: item.ProductCode,
                ApplicationOwner: item.ApplicationOwner,
                CostCenter: item.CostCenter,
                [monthName]: monthCost
            })
        }
    })

    monthNames.forEach((name) => {
        columnName.push(
            {
                field: name,
                valueFormatter: costFormatter,
                type: "numericColumn",
                filter: "agNumberColumnFilter",
                sortable: true,
                resizable: true
            }
        )
    })
    return {column: columnName, row: tempArray}
}

function ComputeOptimizerRecommendation(data) {
    let tempArray = []
    data.forEach((recommendation) => {
        if (tempArray.some(obj => obj.Account_AccountId === recommendation.Account_AccountId)) {
            const index = indexCalculator(tempArray, recommendation.Account_AccountId, "Account_AccountId");
            if (index >= 0) {
                tempArray[index]['Recommendation'].push(recommendation);
                tempArray[index]['NumberOfResources'] += 1;

                if (recommendation.RecommendedOnDemandPrice1) {
                    tempArray[index]['TotalSavings'] += (Number(recommendation.RecommendedOnDemandPrice1) - Number(recommendation.CurrentOnDemandPrice).toFixed(2))
                } else {
                    tempArray[index]['TotalSavings'] += (Number(recommendation.currentPrice) - Number(recommendation.recommendedPrice).toFixed(2))
                }
            }
        } else {

            let testArray = {
                Account_AccountId: recommendation.Account_AccountId,
                AccountAlias: recommendation.AccountAlias,
                NumberOfResources: 1,
                Recommendation: [recommendation]
            }
            if (recommendation.RecommendedOnDemandPrice1) {
                testArray['TotalSavings'] = (Number(recommendation.RecommendedOnDemandPrice1) - Number(recommendation.CurrentOnDemandPrice).toFixed(2))
            } else {
                testArray['TotalSavings'] = (Number(recommendation.currentPrice) - Number(recommendation.recommendedPrice).toFixed(2))
            }
            tempArray.push(testArray)
        }
    })
    return tempArray
}

function dateParser(date) {
    // check whether the input is date or some other data
    // date must contains min 6 character
    // should include - or / or space
    date = date.toString();
    let newDate = date
    if (date.length > 6) {
        if (date.includes("-") || date.includes("/") || date.includes(" ")) {
            try {
                newDate = new Date(date)
                if (Object.prototype.toString.call(newDate) === "[object Date]") {
                    // it is a date
                    if (isNaN(newDate.getTime())) {
                        newDate = date;
                    } else {
                        newDate = moment(newDate).format('MM/DD/YYYY')
                    }
                } else {
                    newDate = date;
                }
            } catch (e) {
                console.error(e)
            }
        }
    }
    return newDate;
}

function sortDate(array) {
    return array.sort(function (a, b) {
        return moment(a.x).diff(moment(b.x))
    });
}

export {
    accountSpendGraph,
    indexCalculator,
    replaceMonthNumberWithName,
    tagReportKey,
    axisShiftMonthlyCostReport,
    ComputeOptimizerRecommendation,
    dateParser,
    axisShift,
    axisShiftApplication,
    axisShiftEnvironment,
    axisShiftSummary,
    sortDate
}
