import makeStyles from "@material-ui/core/styles/makeStyles";
import {Button, Card, CardActions, CardContent} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {NavLink} from "react-router-dom";
import React, {useEffect, useState} from "react";
import service from "../../../service/service";
import Box from "@material-ui/core/Box";
import {convertArrayOfOrganizationToArrayOfAccountList} from "../utilities";

const useStyles = makeStyles({
    root: {
        minWidth: '400px',
        maxWidth: '400px'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

export function Savings(props) {
    const {totalSpendAmount, organizationList} = {...props}
    const classes = useStyles();
    const [trendInformation, setTrendInformation] = useState([]);
    const [savingsPercentage, setSavingPercentage] = useState(0)

    const [loading, setLoading] = useState(false);
    const [ready, setReady] = useState(false);
    const [error, setError] = useState({error: false, message: undefined})

    useEffect(() => {
        setReady(false);
        setLoading(true);
        setError({error: false, message: undefined})

        let params = {organization_ids: convertArrayOfOrganizationToArrayOfAccountList(organizationList)}
        service.dashboard().trendInformation(params)
            .then(res => {
                setTrendInformation(res.data.data);
                setLoading(false);
                setReady(true);
            })
            .catch(err => {
                setError({error: true, message: err.message});
                setLoading(false);
            })

    }, [])

    useEffect(() => {
        if (totalSpendAmount && trendInformation && ready) {
            let savings = (trendInformation.main[0].savingAmount / (totalSpendAmount.summary.totalAmount / 100))
            setSavingPercentage(savings)
            props.onChange(savings)
        }
    }, [totalSpendAmount, trendInformation, ready])


    return (
        <Card className={classes.root} variant="outlined">
            <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Save {savingsPercentage.toFixed(2)}% cost
                </Typography>
                <Typography variant="h4" component="h2" gutterBottom>
                    {ready ? '$' + trendInformation.main[0].savingAmount : 'loading'}
                </Typography>
                <Typography className={classes.pos} color="textSecondary">
                    Cost Savings
                </Typography>
                <Typography variant="body1" component="p">
                    Cost Saving Recommendation from Trusted Advisor
                </Typography>
            </CardContent>
            <CardActions>
                <Button>
                    <NavLink to="/recommendations/trusted-advisor">
                        Learn More
                    </NavLink>
                </Button>
            </CardActions>
            <CardContent>

                <Typography className={classes.pos} variant={"body1"} color="textSecondary">
                    Other saving options
                </Typography>
                {
                    ready &&
                    trendInformation.others.map((trend, index) => {
                        return <SavingSingleItem name={trend.summary} key={index} count={trend.count.toLocaleString()}/>
                    })
                }
            </CardContent>
        </Card>
    )
}


function SavingSingleItem({name, count}) {
    return (
        <Box display="flex">
            <Typography variant="body1" component="p" gutterBottom style={{flexBasis: '80%'}}>
                {name}
            </Typography>
            <Typography variant="subtitle1" component="p" gutterBottom>
                - {count}
            </Typography>
        </Box>
    )
}