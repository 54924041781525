function loginFunction(userName, password) {
	if (userName === 's.manu@cloudfinish.io' && password === 'x6^6@CTvbY*k2[zk') {
		return {
			login: true,
			logonType: 'admin',
			loginPriority: 'super-admin',
		}
	} else if (userName === 'demo@finthesys.com' && password === 'f~swr7HC#Buvg=z,') {
		return {
			login: true,
			logonType: 'admin',
			loginPriority: 'super-admin',
		}
	} else if (userName === 'troy@cloudfinish.io' && password === '<?Ly]"bku(U7dHmk') {
		return {
			login: true,
			logonType: 'admin',
			loginPriority: 'super-admin',
		}
	} else if (userName === 'sundaresh' && password === 'gneFv5#Nd4xzpOp00W1') {
		return {
			login: true,
			logonType: 'admin',
			loginPriority: 'super-admin',
		}
	} else if (userName === 'adam' && password === '14Ah$1lLf$D1o*bJPu') {
		return {
			login: true,
			logonType: 'admin',
			loginPriority: 'super-admin',
		}
	} else {
		return {
			login: false,
			logonType: undefined,
		}
	}
}

export { loginFunction }
