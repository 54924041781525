export const tagComplianceHome = {
    "code": 200, "success": true, "data": {
        "summary": {"totalResources": 649048, "resourcesWithNoTags": 295893, "resourcesWithPartialTags": 353155},
        "noTags": {
            "resourcesWithNoTagsAccounts": 44,
            "firstThreeAccountsNoTags": [
                {
                    "account": "acctgftest",
                    "numberOfResourcesAffected": 3497
                },
                {
                    "account": "acctqa",
                    "numberOfResourcesAffected": 4020
                },
                {
                    "account": "accttempnprd",
                    "numberOfResourcesAffected": 3217
                }
            ],
            "firstThreeServicesFromNoTags": [
                {
                    "service": "ec2",
                    "numberOfService": 122218
                },
                {
                    "service": "ssm",
                    "numberOfService": 95124
                },
                {
                    "service": "volume",
                    "numberOfService": 93062
                }
            ]
        },
        "partialTags": {
            "resourcesWithPartialTagsAccounts": 44,
            "firstThreeAccountsPartialTags": [
                {
                    "account": "acctgftest",
                    "numberOfResourcesAffected": 10050
                },
                {
                    "account": "acctqa",
                    "numberOfResourcesAffected": 34641
                },
                {
                    "account": "accttempnprd",
                    "numberOfResourcesAffected": 34263
                }
            ],
            "firstThreeServicesFromPartialTags": [
                {
                    "service": "ec2",
                    "numberOfService": 21475
                },
                {
                    "service": "rds",
                    "numberOfService": 8039
                },
                {
                    "service": "volume",
                    "numberOfService": 6089
                }
            ],
            "automaticallyFixableResources": 140160,
            "manualActionRequiredResources": 226663
        }
    }
}


export const tagCompliancePartialOrganization = {
    "code": 200,
    "success": true,
    "data": [
        {
            "accountId": "840944450766",
            "accountAlias": "acctgftest",
            "resourceNumber": 3
        }]
}

export const tagCompliancePartialAccount = {
    "code": 200,
    "success": true,
    "data": [
        {
            "resourceArn": "arn:aws:config:us-east-1-1:936923369150:aggregation-authorization/936923369150/us-east-1",
            "service": "config",
            "region": "ap-southeast-1",
            "missingTagCount": 2
        },
        {
            "resourceArn": "arn:aws:lambda:us-east-1:936923369150:function:aws-controltower-NotificationForwarder",
            "service": "lambda",
            "region": "ap-southeast-1",
            "missingTagCount": 2
        },
        {
            "resourceArn": "arn:aws:cloudformation:us-east-1:936923369150:stack/test-stacj/sdf-sdfds-sdfsvdhfgh-yttcbFfgdfhjcb-",
            "service": "cloudformation",
            "region": "ap-southeast-1",
            "missingTagCount": 2
        }]
}

export const tagComplaincePartialResource = {
    "code": 200,
    "success": true,
    "data": [{"tagKey": "ApplicationName", "tagCurrentValue": "", "tagExpectedValue": ""}, {
        "tagKey": "Environment",
        "tagCurrentValue": "",
        "tagExpectedValue": ""
    }, {"tagKey": "CostCenter", "tagCurrentValue": "", "tagExpectedValue": ""}, {
        "tagKey": "SupportContact",
        "tagCurrentValue": "",
        "tagExpectedValue": ""
    }, {"tagKey": "ApplicationOwner", "tagCurrentValue": "", "tagExpectedValue": ""}, {
        "tagKey": "ApplicationCode",
        "tagCurrentValue": "",
        "tagExpectedValue": ""
    }]
}