import React, {useEffect, useState} from "react";
import service from "../../../service/service";
import {AlertWu} from "../../AlertWu/AlertWu";
import CostAnomalyTable from "./CostAnomalyTable";
import {Collapse, Typography} from "@material-ui/core";
import {useOrganization} from "../../../hooks/useOrganization";
import CostAnomalyTitleCard from "./CostAnomalyTitleCard";
import PageTitle from "../../General/PageTitle/PageTitle";
import {ProgressCircleCustomSize} from "../../ProgressCircle/ProgressCircle";
import Box from "@material-ui/core/Box";
import {jsonParser} from "../../../helper/generalFunctions";
import WuSpacer from "../../General/WuSpacer";
//dummy data
import dummy from '../../../assets/finthesys/cost-anomaly.json'

export default function CostAnomaly() {
    const {organization} = useOrganization();
    const [masterAccountId, setMasterAccountId] = useState(organization);
    const [anomalyData, setAnomalyData] = useState([]);
    const [anomalyPeriod, setAnomalyPeriod] = useState(90);
    const [loading, setLoading] = useState(false);
    const [ready, setReady] = useState(false);
    const [error, setError] = useState({error: false, message: undefined});

    useEffect(() => {
        setMasterAccountId(organization)
    }, [organization])


    useEffect(() => {
        fetchData();
    }, [anomalyPeriod, masterAccountId])


    const fetchData = async () => {
        try {
            setReady(false);
            setLoading(true);
            setError({error: false, message: undefined})
            setAnomalyData([])
            if (masterAccountId && masterAccountId !== 'no org') {
                /*const param = {
                    priorDate: anomalyPeriod,
                    accountId: masterAccountId
                }
                const response = await service.monitoring().getCostAnomaly(param);
                if (response.data.code === 200) {
                    setAnomalyData(response.data.data);
                    setReady(true);
                } else {
                    throw new Error('No response data')
                }*/

                setTimeout(() => {
                    setAnomalyData(dummy)
                    setReady(true);
                }, 1000)
            }
        } catch (e) {
            const errorMessage = jsonParser(e?.request?.response)
            setError({error: true, message: errorMessage ? errorMessage.error : e.message})
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="title-div-wrapper">
            <div style={{width: "100%"}}>
                <div className="automation-wrapper">
                    <PageTitle title={'Cost Anomaly Monitor'}/>
                </div>
                <div className="anomaly-wrapper">
                    <div className="cost-anomaly-monitor">
                        <CostAnomalyTitleCard anomalyPeriod={anomalyPeriod}
                                              onChange={(e) => setAnomalyPeriod(e)}
                                              numberOfAnomalies={anomalyData?.anomalyInfo?.numberOfAnomalies}
                                              totalImpactAmount={anomalyData?.anomalyInfo?.totalImpactAmount}
                                              loading={loading}/>

                        <WuSpacer space={3}/>
                        {
                            ready &&
                            <CostAnomalyTable costAnomaly={anomalyData.responseData}/>
                        }
                        {
                            loading &&
                            <Loading/>
                        }

                        <AlertWu severity="error" alertOpen={{state: error.error}}
                                 message={"Something went wrong... Error: " + error.message}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

function Loading() {
    return (
        <Box display={'flex'} gridGap={10} alignContent={'center'} alignItems={'center'}>
            <ProgressCircleCustomSize size={16}/>
            <Typography>
                Loading anomalies...
            </Typography>
        </Box>
    )
}