export const reportTitle = (title) => {
    if (title === "DailyCostReports") {
        return ("Daily Cost Report");
    } else if (title === "MonthlyCostsByLinkedAccount") {
        return ("Monthly Cost By Linked Account");
    } else if (title === "MonthlyCostsByService") {
        return ("Monthly Cost By Service");
    } else if (title === "CoverageReport") {
        return ("Coverage");
    } else if (title === "MonthlyEC2RunningHoursCostsAndUsage") {
        return ("Monthly EC2 Running Hour/Cost and Usage");
    } else if (title === "BillingInventory") {
        return ("Linked Account Billing (Top Consumed 10 Resources)");
    } else if (title === "CBR") {
        return ("Consolidated Billing Report");
    } else if (title === "UBA") {
        return ("YTD Usage by Account");
    } else if (title === "UBS") {
        return ("YTD Usage by Service");
    } else if (title === "UBAS") {
        return ("YTD Usage by Account/Service");
    } else if (title === "TER") {
        return ("YTD Most Expensive 25 Resources");
    } else if (title === "TEL") {
        return ("YTD Most Expensive 25 Lambda");
    } else if (title === "TEEC") {
        return ("YTD Most Expensive 25 EC2");
    } else if (title === "TERDS") {
        return ("YTD Most Expensive 25 RDS");
    } else if (title === "TECWG") {
        return ("YTD Most Expensive 25 CloudWatch Log Group");
    } else if (title === "TED") {
        return ("YTD Most Expensive 25 Dynamo Tables");
    } else if (title === "TES") {
        return ("YTD Most Expensive 25 S3 Buckets");
    } else if (title === "TESO") {
        return ("YTD Most Expensive 25 S3 Bucket Operations");
    } else if (title === "TEV") {
        return ("YTD Most Expensive 25 VPC");
    } else if (title === "TEC") {
        return ("YTD Most Expensive 25 Config");
    } else if (title === "WUAAYTD") {
        return ("YTD Application Name");
    } else if (title === "WUAAOYTD") {
        return ("YTD Application Owner");
    } else if (title === "AwsMarketPlace") {
        return ("YTD AWS Market Place");
    } else if (title === "WUCR") {
        return ("YTD Cost Code");
    } else if (title === "WUAR") {
        return ("YTD Account");
    } else {
        return ("untitled")
    }
}