import React, {memo, useState} from "react";
import {useParams} from "react-router-dom";
import {useEcs} from "../../../hooks";
import {EcsContext} from "../../../context";
import Clusters from "../Clusters";
import Services from "../Services";
import Tasks from "../Tasks";
import Instances from "../Instances";
import DetailsDialog from "./DetailsDialog";
import {Typography} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import WuToolTip from "../../General/WuToolTip";


export default function Home(props) {
    const [ecs, setEcs] = useState({activeTab: 'cluster'})
    return (
        <EcsContext.Provider value={{ecs, setEcs}}>
            <MemorisedHome {...props}/>
        </EcsContext.Provider>
    )
}

const MemorisedHome = memo(function MemorisedHome({accountId, taskCount, serviceCount, clusterCount, instanceCount}) {
    const {resourceType} = useParams()
    const {ecs, setEcs} = useEcs()

    const handleTabChange = (newValue) => {
        setEcs(current => {
            return {...current, activeTab: newValue}
        })
    }

    const tabItem =  [
        {
            name: 'Clusters',
            tabId: 'cluster',
            count: clusterCount
        },
        {
            name: 'Services',
            tabId: 'service',
            count: serviceCount
        },
        {
            name: 'Tasks',
            tabId: 'task',
            count: taskCount
        },
        {
            name: 'Instances',
            tabId: 'instance',
            count: instanceCount
        }
    ]

    return (
        <div className={'ecs-home-container'}>
            {resourceType && <DetailsDialog resourceType={resourceType}/>}
            <Tab onTabChange={handleTabChange} activeTab={ecs?.activeTab ? ecs?.activeTab : 'cluster'} tabItems={tabItem}/>
            {ecs?.activeTab === 'cluster' && <Clusters accountId={accountId}/>}
            {ecs?.activeTab === 'service' && <Services accountId={accountId}/>}
            {ecs?.activeTab === 'task' && <Tasks accountId={accountId}/>}
            {ecs?.activeTab === 'instance' && <Instances accountId={accountId}/>}
        </div>
    )
})


function FilterOn() {
    const {ecs, setEcs} = useEcs()
    const enabledFilter = ecs?.filter || {}

    const handleClearFilter = () => {
        setEcs(current => {
            return {...current, filter: null}
        })
    }

    if (Object.keys(enabledFilter).length > 0) {
        return (
            <div className={'active-ecs-filter'}>
                <div>
                    <Typography color={'textSecondary'}
                                style={{marginBottom: 1}}
                                variant={'caption'}
                                align={'right'}>
                        {Object.keys(enabledFilter)[0]}
                    </Typography>
                    <Typography align={'right'}
                                style={{marginTop: -3}}>
                        {Object.values(enabledFilter)[0]}
                    </Typography>
                </div>
                <WuToolTip title={'clear filter'}>
                    <IconButton onClick={handleClearFilter}>
                        <CloseIcon/>
                    </IconButton>
                </WuToolTip>
            </div>
        )
    }
    return null
}

function Tab(props) {
    const {onTabChange, activeTab, tabItems = []} = {...props}

    return (
        <div className={'tab-container'}>
            <div className={'tab-container-wrapper'}>
                {
                    tabItems.map(e => <TabItem {...e} key={e.tabId}
                                                 onClick={onTabChange}
                                                 activeTab={activeTab}/>
                    )
                }
            </div>
            <FilterOn/>
        </div>
    )
}

function TabItem(props) {
    const {activeTab = null, onClick, tabId, name, count} = {...props}
    return (
        <div className={activeTab === tabId ? 'tab-item active-tab' : 'tab-item'}
             onClick={() => onClick(tabId)}
        >
            {name}
            <span className={'tab-inline-count-container'}>
                  <div className={'tab-inline-count'}>{count || 0}</div>
            </span>
        </div>
    )
}