import React, {useEffect, useState} from 'react';
import {Paper} from "@material-ui/core";
import DataGrid from "../../General/DataGrid";
import WuAccordionDetails from "../../General/WuAccordion/WuAccordionDetails";
import service from "../../../service/service";
import WuLoading from "../../General/WuLoading";
import {jsonParser} from "../../../helper/generalFunctions";
import './index.scss'
import {tableHeaders} from './tableHeaders'

export default function AccountWiseSummary({expanded, accountId}) {
    const [apiProgress, setApiProgress] = useState({ready: false, loading: false, error: ''})
    const [responseData, setResponseData] = useState([])

    const fetchData = async () => {
        try {
            setApiProgress({ready: false, loading: true, error: ''})
            const params = {
                account_id: accountId
            }
            const {data: {data}} = await service.monitoring().getAccountWiseSummaryEfs(params)
            setResponseData(data)
            setApiProgress({ready: true, loading: false, error: ''})
        } catch (e) {
            let errorObj
            try {
                errorObj = jsonParser(e.request.response).error
            } catch (e) {
                errorObj = e.message
            }
            setApiProgress({ready: false, loading: false, error: errorObj})
        }
    }

    useEffect(() => {
        if (expanded === true && (responseData.length < 1)) {
            fetchData().then()
        }
    }, [expanded])

    return (
        <WuAccordionDetails>
            <Paper style={{width: '100%'}}>
                {apiProgress.loading && <WuLoading message={`Loading ${accountId} subnet data`}/>}
                {apiProgress.ready &&
                <DataGrid
                    fixedColumn={tableHeaders}
                    fileName={'efs-monitoring'}
                    rowClassRules={{
                        'busting-efs': function (params) {
                            return params.data.ProvisionedThroughput === 'bursting';
                        }
                    }}
                    gridData={responseData}
                    gridStyle={{height: '30vh'}}/>}
            </Paper>
        </WuAccordionDetails>
    )
}