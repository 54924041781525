import {Route, Redirect} from "react-router-dom";
import {useAuthorization} from "../hooks";

function ProtectedRoute({component: Component, ...rest}) {
    const [authorization] = useAuthorization()
    return (
        <Route
            {...rest}
            render={(props) => {
                if (authorization?.authenticated) {
                    return <Component/>;
                } else {
                    return (
                        <Redirect to={{pathname: "/login", state: {from: props.location}}}/>
                    );
                }
            }}
        />
    );
}

export default ProtectedRoute;
