import React, {useEffect, useState} from "react";
import DataGrid from "../../General/DataGrid/DataGrid";
import service from "../../../service/service";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import IconButton from "@material-ui/core/IconButton";
import CloudWatchLogUpdate from "./CloudWatchLogUpdate"
const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

const d = new Date();
let prev_month = monthNames[d.getMonth()-1];
let curr_month = monthNames[d.getMonth()]
export default function CloudWatchAccountSummary({accountId, filter, expanded ,id}) {

    const columns = [
        {
            field: "Account_AccountId",
            headerName: 'Account Id',
            resizable: true,
            //width: 170,
            sortable: true,
            suppressAutoSize: true,
        },
        {
            field: "AccountAlias",
            headerName: 'AccountAlias',
            // width: 180,
            resizable: true,
            sortable: true,
            suppressAutoSize: true,
        },
        {
            field: "ApplicationName",
            headerName: 'ApplicationName',
            //width: 180,
            resizable: true,
            sortable: true,
            suppressAutoSize: true,
        },
        {
            field: "Region_RegionCode",
            headerName: 'Region',
            // width: 140,
            resizable: true,
            sortable: true,
            suppressAutoSize: true,
        },
        {
            field: "LogGroupName",
            headerName: 'LogGroup Name',
            resizable: true,
            sortable: true,
            suppressAutoSize: true,
        },

        {
            field: "CreationTime",
            headerName: 'Creation Time',
            resizable: true,
            //width: 150,
            sortable: true,
            suppressAutoSize: true,
        },
        {
            field: "LastEventTime",
            headerName: 'LastEvent Time',
            resizable: true,
            //width: 150,
            sortable: true,
            suppressAutoSize: true,
        },
        {
            field: "RetentionInDays",
            headerName: 'Retention Days',
            resizable: true,
            // width: 130,
            sortable: true,
            suppressAutoSize: true,
        },
        {
            field: "Environment",
            headerName: 'Environment',
            resizable: true,
            sortable: true,
            suppressAutoSize: true,
        },
        {
            field: "Memory",
            headerName: 'Memory Used',
            resizable: true,
            sortable: true,
            suppressAutoSize: true,
        },
        {
            field: 'PreviousPrice',
            headerName:  "Price" + '(' + prev_month + ')',
            resizable: true,
            sortable: true,
            suppressAutoSize: true,
            valueFormatter: function (param) {
                return param ? "$ "+((parseFloat(param.value)).toFixed(2)).toLocaleString() : ""
            }
        },
        {
            field: 'Price',
            headerName:  "Price" + '(' + curr_month + ')',
            resizable: true,
            sortable: true,
            suppressAutoSize: true,
            valueFormatter: function (param) {
                return param ? "$ "+((parseFloat(param.value)).toFixed(2)).toLocaleString() : ""
            }
        },
        {
            cellRenderer: "informationCellRenderer",
            sortable: false,
            resizable: false,
            filter: false,
            width: 110,
            suppressAutoSize: false,
            cellRendererParams: {
                clicked: function (field) {
                    handleApplicationUpdate(field)
                }
            },
        }
    ]
    const [loading, setLoading] = useState(false);
    const [ready, setReady] = useState(false);
    const [accountWiseData, setAccountWiseData] = useState([]);
    const [update, setUpdate] = useState(false);
    const [updateOpen, setUpdateOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState([false]);
    const [error, setError] = useState({
        error: false,
        errorMessage: undefined
    })
    const handleApplicationUpdate = (e) => {

        setSelectedItem(e.data);
        setUpdateOpen(true);
    }

    useEffect(() => {
        // trigger on account id change
        if(expanded === id){
            let param = filter
            if (!param.region){
                param = {
                    region: '',
                    key: '',
                    value: ''
                }
            }

            setLoading(true)
            setReady(false);
            setError({
                error: false,
                errorMessage: undefined
            })
            // run the api call for loading account wise recommendation
            setAccountWiseData([])
            service.monitoring().getListUnusedWatch({account_id: accountId,  filter : param})
                .then((res) => {
                    //console.log(res)
                    setAccountWiseData(res.data.instance)
                    setReady(true);
                    setLoading(false);
                })
                .catch((err) => {
                    setError({
                        error: true,
                        errorMessage: err.message
                    })
                })
        }
    }, [expanded])

    const BtnCellRenderer = (accountWiseData) => {
        const btnClickedHandler = (field) => {
            accountWiseData.clicked(accountWiseData)
        }
        return (
            <IconButton onClick={btnClickedHandler} aria-label="info" size="medium" color="orange" >
                <InfoOutlinedIcon fontSize="inherit"/>
            </IconButton>
        )
    }
    const handleUpdateSchedule = async (e) => {
        if (e === "update") {
            //await getApplicationSummary();
            //selectedItem.onChange("update");
            setUpdate(true);
        } else if (e === "close") {
            setUpdateOpen(false);
        }
    }



    return (
        <div style={{width:'100%'}}>
            <CloudWatchLogUpdate onChange={(e) => handleUpdateSchedule(e)} open={updateOpen} data={selectedItem}
            />
            {
                !loading ?
                    ready &&
                    <DataGrid
                        fixedColumn={columns}
                        fileName={`cloud_watch_log`}
                        gridStyle={{height: "calc(100vh - 350px)"}}
                        gridData={(accountWiseData.length > 0) ? accountWiseData:[]}
                        frameworkComponents={{
                            informationCellRenderer: BtnCellRenderer
                        }}/>
                    : "loading..."
            }
            {
                error.error &&
                `something went wrong. Error message: ${error.errorMessage}`
            }
        </div>
    )
}