import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ElbAccountSummary from "./ElbAccountSummary";
import service from "../../../service/service";
import {AlertWu} from "../../AlertWu/AlertWu";
import Box from '@material-ui/core/Box';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {withStyles} from '@material-ui/core/styles';
import {useOrganization} from "../../../hooks/useOrganization";

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .02)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        flexBasis: '33.33%',
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

export default function ElbAccordion(props) {
    const {organization: masterAccountId} = useOrganization()
    const classes = useStyles();
    const {filter} = {...props}
    const [loading, setLoading] = useState(false);
    const [ready, setReady] = useState(false);
    const [unUsedResourceData, setUnUsedResourceData] = useState([])
   
    const [expanded, setExpanded] = React.useState();
    const [error, setError] = useState({
        error: false,
        errorMessage: undefined
    })
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
      };

    useEffect(() => {
        // trigger on filter change
        if(masterAccountId!=='no org'){
            let param = filter
            if (!param.region){
                param = {
                    region: '',
                    key: '',
                    value: ''
                }
            }
            setLoading(true)
            setReady(false);
            setError({
                error: false,
                errorMessage: undefined
            })
            setUnUsedResourceData([])
            service.monitoring().getUnusedElb({filter : param, master_account_id: masterAccountId})
                .then((res) => {
                    setLoading(false);
                    setReady(true);
                    setUnUsedResourceData(res.data.instance)
                })
                .catch((err) => {

                    if (!err.response.data?.code === 402){
                        setError({
                            error: true,
                            errorMessage: err.message
                        })
                    }
                    else{
                        setUnUsedResourceData([])
                    }
                    setLoading(false);

                })
        }
    }, [filter, masterAccountId])

    
    return (
        <div className={classes.root}>
            <Box display = 'flex' m={2}> 
                <Typography style={{flexBasis: '31.23%', fontWeight: '900'}} variant = {'subtitle2'}>Account Alias (Account Id)</Typography> 
                <Typography style={{flexBasis: '33.23%', fontWeight: '900'}} variant = {'subtitle2'}>Number of unused Resources</Typography>
                <Typography style={{fontWeight: '900'}} variant = {'subtitle2'}>Total Cost</Typography>
            </Box>

            { 
                ready &&
                unUsedResourceData.length >=1 ?
                unUsedResourceData.map((unusedResourceSingleItem, id) => {
                    console.log(unusedResourceSingleItem)
                    return (
                        
                        <React.Fragment key={id}>
                        <Accordion key={unusedResourceSingleItem.Account_AccountId}  onChange = {handleChange(id)}
                               >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                            >
                                <InfoOutlinedIcon style={{paddingRight: 20}}/>
                                <Typography className={classes.heading}>
                                    {unusedResourceSingleItem.AccountAlias}({unusedResourceSingleItem.Account_AccountId})
                                    </Typography>
                                <Typography className={classes.heading}>{unusedResourceSingleItem.count}</Typography>
                                <Typography className={classes.heading}>{'$'+(unusedResourceSingleItem.price).toFixed(2)}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {/* populate data to table */}
                                <ElbAccountSummary 
                                accountId={unusedResourceSingleItem.Account_AccountId} filter= {filter} expanded={expanded} id={id}/>
                            </AccordionDetails>
                        </Accordion>
                        </React.Fragment>
                        )
                    }) : !loading && <Box m={2}>No data</Box>
            }
            {
                loading && <Box m={2}>"Please wait. We're working on it..."</Box>
            }
            <AlertWu
                alertOpen={{state: error.error}}
                severity={"error"}
                message={error.message}/>
        </div>
    );
}