import React, {useEffect, useState} from "react";
import CrossAccountAccordionGeneral from "./CrossAccountAccordionGeneral";
import {AccordionDetails, InputBase, Paper, TextField, Typography} from "@material-ui/core";
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import {makeStyles} from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import ShareIcon from '@material-ui/icons/Share';
import {CallSplitOutlined, MenuOpenOutlined, SaveAltOutlined, SaveOutlined} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import WuTextField from "../../General/WuTextFeild";

const useStyles = makeStyles((theme) => ({
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    heading: {
        flexBasis: "30.33%"
    },
    details: {
        alignItems: 'center',
        padding: "8px 16px",
        borderBottom: '1px solid rgba(0, 0, 0, .05)',
    },
}));

export default function CrossAccountRoleSetting() {
    return (
        <div>
            <CrossAccountRoleStatus/>
            <CrossAccountAccordionGeneral
                accountId={"546351321312"}
                accountAlias={"shared services"}
                warningLevel={"warning"}
                accountType={"child account"}
                accessStatus={"22 Issues found"}
                onClick={(id) => console.log(id)}>
                <AccessItem warningLevel={"success"} status={"OK"} permissionItem={"Cross Account Access"}/>
                <AccessItem warningLevel={"success"} status={"OK"} permissionItem={"S3 Read"}/>
                <AccessItem warningLevel={"error"} status={"NOT OK"} permissionItem={"S3 Put"}/>
            </CrossAccountAccordionGeneral>
            <CrossAccountAccordionGeneral
                accountId={"546351321312"}
                accountAlias={"shared services"}
                warningLevel={"error"}
                accountType={"child account"}
                accessStatus={"22 Issues found"}
                onClick={(id) => console.log(id)}>
                <AccessItem warningLevel={"error"} status={"NOT OK"} permissionItem={"Cross Account Access"}/>
            </CrossAccountAccordionGeneral>
        </div>
    )
}


function CaRoleName(props) {
    const IconStyle = {marginRight: -10}
    const [open, setOpen] = useState(false);
    const [roleName, setRoleName] = useState("CA-Rol-FinopsLambda");
    const [sharedServiceAccountId, setSharedServiceAccountId] = useState("545666456456");

    return (
        <div className="d-f-s" style={{padding: "10px 10px 10px 20px"}}>
            <div className="d-f-s j-s" style={{width: "100%"}}>
                <CallSplitOutlined style={IconStyle}/>
                <Typography style={{width: "100%"}} variant={"subtitle2"}> Cross account role name - </Typography>
                {
                    open ?
                        <WuTextField value={roleName} onChange={(e) => setRoleName(e.target.value)}/> :
                        <Typography style={{width: "100%"}} variant={"subtitle1"}> {roleName} </Typography>
                }
                <Divider orientation="vertical" flexItem/>
                <ShareIcon style={IconStyle}/>
                <Typography style={{width: "100%"}} variant={"subtitle2"}> Shared Service Account - </Typography>
                {
                    open ?
                        <WuTextField value={sharedServiceAccountId}
                                     onChange={(e) => setSharedServiceAccountId(e.target.value)}/> :
                        <Typography style={{width: "100%"}}
                                    variant={"subtitle1"}> {sharedServiceAccountId} </Typography>
                }

            </div>
            {
                open ?
                    <IconButton
                        onClick={() => {
                            setOpen(false)
                        }}
                        size={"medium"}>
                        <SaveOutlined/>
                    </IconButton> :
                    <IconButton
                        onClick={() => {
                            setOpen(true)
                        }}
                        size={"medium"}>
                        <MenuOpenOutlined/>
                    </IconButton>
            }
        </div>
    )
}


function AccessItem(props) {
    const classes = useStyles();
    const {warningLevel, status, permissionItem} = {...props}
    const [statusColor, setStatusColor] = useState("green");
    const [statusIcon, setStatusIcon] = useState(<ErrorOutlineOutlinedIcon/>);

    useEffect(() => {
        switch (warningLevel) {
            case "warning":
                setValues("orange", <ReportProblemOutlinedIcon/>)
                break
            case "success":
                setValues("green", <CheckCircleOutlineOutlinedIcon/>)
                break
            case "error":
                setValues("red", <ErrorOutlineOutlinedIcon/>)
                break
            default:
                setValues("black", <CheckCircleOutlineOutlinedIcon/>)
        }
    }, [props.warningLevel])

    const setValues = (color, icon) => {
        setStatusColor(color);
        setStatusIcon(icon)
    }

    return (
        <AccordionDetails style={{gap: 10}} className={classes.details}>
            <div style={{display: "flex", gap: 10, flexBasis: "33.33%"}}>
                <div style={{color: statusColor}}>
                    {statusIcon}
                </div>
                <Typography> {status} </Typography>
            </div>
            <Typography className={classes.heading}> {permissionItem} </Typography>
            <Typography className={classes.secondaryHeading}> Access Denied for user
                arn:fsdfs:sdgsaggafgfa:gag </Typography>
        </AccordionDetails>
    )
}


function CrossAccountRoleStatus(props) {
    const IconStyle = {marginTop: 4}

    return (
        <Paper variant="outlined" style={{marginBottom: 20}}>
            <div className="d-f-s"
                 style={{padding: 20}}>
                <div>
                    <Typography variant={"h6"}>
                        Overall Status
                    </Typography>
                    <Typography variant={"h5"}>
                        Critical Issues Found!
                    </Typography>
                </div>

                <div className="d-f-s" style={{gap: 20, marginLeft: 20}}>
                    <PermissionStatus
                        color={"red"}
                        text={"No Access"}
                        total={42}
                        count={12}
                        icon=<ErrorOutlineOutlinedIcon style={IconStyle}/>
                    />
                    <Divider orientation="vertical" flexItem/>
                    <PermissionStatus
                        color={"orange"}
                        text={"Partial Access"}
                        total={42}
                        count={8}
                        icon=<ReportProblemOutlinedIcon style={IconStyle}/>
                    />
                    <Divider orientation="vertical" flexItem/>
                    <PermissionStatus
                        color={"green"}
                        text={"Full Access"}
                        total={42}
                        count={24}
                        icon=<CheckCircleOutlineOutlinedIcon style={IconStyle}/>
                    />
                </div>
            </div>
            <Divider/>
            <CaRoleName/>
        </Paper>
    )
}


/**
 *
 * @param {object} props - props
 * @param {JSX.Element} icon - icon to be shown
 * @param {string} color - color
 * @param {string} text - message to display
 * @param {number} total - total number of resource
 * @param {number} count - out of total
 * @return {JSX.Element}
 * @constructor
 */
function PermissionStatus(props) {
    const {color, text, total, count} = {...props}
    const wrapperStyle = {color: color, gap: 10, minWidth: 100, textAlign: "end", justifyContent: "flex-end"}
    return (
        <div className="d-f a-c-s"
             style={wrapperStyle}>
            <div>
                <Typography
                    style={{marginLeft: "-3px"}}
                    variant={"h5"}>
                    {count}/{total}
                </Typography>
                <Typography variant={"subtitle2"}>
                    {text}
                </Typography>
            </div>

        </div>
    )
}