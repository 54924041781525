import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import service from "../../../../../service/service";
import {AlertWu} from "../../../../AlertWu/AlertWu";
import {PaperProgress} from "../../../../ProgressCircle/ProgressCircle";
import DataGrid from "../../../../General/DataGrid/DataGrid"
import {useOrganization} from "../../../../../hooks/useOrganization";
import {axiosErrorResponseParser} from "../../../../../helper/generalFunctions";

//dummy
import testData from '../../../../../assets/finthesys/savings-plan-inventory.json'

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});


let testData2 = []


export default function InsightTable() {
    const {organization: masterAccountId} = useOrganization()
    const [loading, setLoading] = useState(false);
    const [ready, setReady] = useState(false);
    const [error, setError] = useState({error: false, message: undefined})

    const columns = [
        {field: 'Status'},
        {field: 'AccountId', sortable: true, resizable: true, width: 170, suppressAutoSize: true,},
        {field: 'AccountName', sortable: true, resizable: true, width: 220, pinned: "left", suppressAutoSize: true,},
        {
            field: 'EndDateTime',
            sortable: true,
            headerName: 'End Date',
            resizable: true,
            width: 220,
            suppressAutoSize: true,
        },
        {
            field: 'StartDateTime',
            sortable: true,
            headerName: 'Start Date',
            resizable: true,
            width: 220,
            suppressAutoSize: true,
        },
        {field: 'Region', sortable: true, headerName: 'Region', resizable: true, width: 220, suppressAutoSize: true,},
        {
            field: 'PurchaseTerm',
            sortable: true,
            headerName: 'Term',
            resizable: true,
            width: 70,
            suppressAutoSize: true,
        },
        {
            field: 'RecurringHourlyFee',
            sortable: true,
            headerName: 'Recurring Hourly Fee',
            resizable: true,
            width: 200,
            suppressAutoSize: true,
        },
        {
            field: 'PaymentOption',
            sortable: true,
            headerName: 'Payment Option',
            resizable: true,
            width: 170,
            suppressAutoSize: true,
        },
    ];

    useEffect(() => {
        if (masterAccountId !== 'no org') {
            setLoading(true);
            setReady(false);
            setError({error: false, message: undefined})
            /*service.insights().getSavingsPlanInventory({account_id: 477419074820})
                .then(res => {
                    if (res.data.code === 200) {
                        testData2 = []
                        res.data.data.map((attribute) => {
                            testData2.push(attribute.Attributes)
                        })
                        setReady(true);
                    }
                })
                .catch(err => {
                    const errorObject = axiosErrorResponseParser(err)
                    setError({error: true, message: errorObject ? errorObject.error : err.message})
                })
                .finally(() => setLoading(false))*/

            testData2 = []
            //setInventory(testData);
            testData.data.map((attribute) => {
                testData2.push(attribute.Attributes)
            })
            setReady(true)
            setLoading(false);
        }

    }, [masterAccountId])


    return (
        <div>
            {loading && <PaperProgress/>}
            {ready &&

            <div className="onboarding-grid-wrapper">
                <DataGrid
                    fileName={"saving-plan-insight"}
                    fixedColumn={columns}
                    gridData={testData2}
                    gridStyle={{height: ((testData2.length + 1) * 50) + "px"}}
                    gridClass="onboarding-grid"/>
            </div>
            }
            {
                <AlertWu severity="error" alertOpen={{state: error.error}} message={error.message}/>
            }
        </div>
    );
}