import Summary from "../Summary";
import './index.scss'
import {Divider, Grid, Paper, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import DonutGraph from "./DonutGraph";
import Box from "@material-ui/core/Box";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import {DonutSmallOutlined, TableChartOutlined} from "@material-ui/icons";
import React, {useEffect, useState} from "react";
import {apiErrorResponseJsonParser} from "../../../../helper/generalFunctions";
import {useOrganization} from "../../../../hooks/useOrganization";
import service from "../../../../service/service";
import WuLoading from "../../../General/WuLoading";
import WuAlert from "../../../General/WuAlert";
import {tagComplianceHome} from "../../../../assets/finthesys/tag-compliance";

export default function Home() {
    const {organization: masterAccountId} = useOrganization()
    const [activeViewType, setActiveViewType] = useState('chart')
    const [progress, setProgress] = useState({loading: false, ready: false, error: false})
    const [apiResponse, setApiResponse] = useState({})

    const fetchData = async () => {
        try {
            setProgress({loading: true, ready: false, error: false})
            const params = {
                masterAccountId: masterAccountId
            }
            const {data: {data}} = await service.monitoring().getTagComplianceSummary(params)
            setApiResponse(data)
            setProgress({loading: false, ready: true, error: false})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setProgress({loading: false, ready: true, error: error})
        }
    }

    const fetchDummyData = async () => {
        try {
            setProgress({loading: true, ready: false, error: false})
            const params = {
                masterAccountId: masterAccountId
            }
            //const {data: {data}} = await service.monitoring().getTagComplianceSummary(params)
            const {data} = tagComplianceHome
            setApiResponse(data)
            setProgress({loading: false, ready: true, error: false})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setProgress({loading: false, ready: true, error: error})
        }
    }

    useEffect(() => {
        if (masterAccountId !== 'no org') {
            fetchDummyData().then()
        }
    }, [masterAccountId])

    return (
        <div>
            <Summary summary={apiResponse?.summary || {}} loading={progress.loading}/>
            {
                progress.loading && <WuLoading open={true} boxProps={{my: 2}}/>
            }
            <WuAlert error={Boolean(progress.error)} success={false} errorMessage={progress.error}/>
            <div className={'tc-home-wrapper'}>
                <PartialTagsSummary onViewChange={setActiveViewType} view={activeViewType}
                                    data={apiResponse?.partialTags || {}}/>
                <Divider flexItem orientation={'vertical'}/>
                <NoTagsSummary view={activeViewType} data={apiResponse?.noTags || {}}/>
            </div>
        </div>
    )
}


function PartialTagsSummary({onViewChange, view, data, loading}) {
    const {
        resourcesWithPartialTagsAccounts = 0,
        firstThreeAccountsPartialTags = [],
        firstThreeServicesFromPartialTags = [],
        automaticallyFixableResources = 0,
        manualActionRequiredResources = 0
    } = {...data}
    return (
        <div className={'tc-home-item-container'}>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <div>
                    <Typography variant={'h6'}>
                        Partial/non-compliant tags
                    </Typography>
                    <Typography variant={'body2'} color={'textSecondary'}>
                        Resources with partial/non-compliant tags
                    </Typography>
                </div>
                <div>
                    <ToggleButtonGroup onChange={(_, value) => onViewChange(value)}
                                       value={view} exclusive>
                        <ToggleButton value={'table'}>
                            <TableChartOutlined fontSize={'small'}/>
                        </ToggleButton>
                        <ToggleButton value={'chart'}>
                            <DonutSmallOutlined fontSize={'small'}/>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
            </Box>
            <Grid container style={{marginTop: 16}} spacing={3}>
                <Grid item xs={12}>
                    <Typography gutterBottom color={'textSecondary'}>
                        Additional Information
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <SummaryCard value={automaticallyFixableResources || 0}
                                         description={'Resources than can be fixed automatically'}/>
                        </Grid>
                        <Grid item xs={6}>
                            <SummaryCard value={manualActionRequiredResources || 0}
                                         description={'Resources that can be fixed manually'}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SummaryCard value={resourcesWithPartialTagsAccounts || 0}
                                         description={'Number of accounts which are non-compliant'}/>
                        </Grid>
                        <Grid item xs={6}>
                            <SummaryCardLink link={'/tagging/tag-compliance/partial-tag'} text={'More Info'}/>
                        </Grid>
                    </Grid>
                </Grid>
                {view === 'chart' &&
                <React.Fragment>
                    <Grid item xs={6}>
                        <Typography gutterBottom color={'textSecondary'}>
                            Most errors from (Accounts)
                        </Typography>
                        <DonutGraph colorPalette={'palette1'}
                                    series={firstThreeAccountsPartialTags.map(e => e?.numberOfResourcesAffected || 0)}
                                    labels={firstThreeAccountsPartialTags.map(e => e?.account || '')}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography gutterBottom color={'textSecondary'}>
                            Most errors from (Service)
                        </Typography>
                        <DonutGraph colorPalette={'palette2'}
                                    series={firstThreeServicesFromPartialTags?.map(e => e?.numberOfService || 0)}
                                    labels={firstThreeServicesFromPartialTags?.map(e => e?.service || '')}
                        />
                    </Grid>
                </React.Fragment>}
                {view === 'table' &&
                <React.Fragment>
                    <Grid item xs={12}>
                        <Typography gutterBottom color={'textSecondary'}>
                            Most errors from (Accounts)
                        </Typography>
                        <MostErrorSummaryCard content={firstThreeAccountsPartialTags}/>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography gutterBottom color={'textSecondary'}>
                            Most errors from (Service)
                        </Typography>
                        <MostErrorSummaryCard content={firstThreeServicesFromPartialTags}/>
                    </Grid>
                </React.Fragment>
                }
            </Grid>
        </div>
    )
}

function NoTagsSummary({view, data}) {

    const {
        resourcesWithNoTagsAccounts = 0,
        firstThreeAccountsNoTags = [],
        firstThreeServicesFromNoTags = [],
    } = {...data}

    return (
        <div className={'tc-home-item-container'}>
            <Typography variant={'h6'}>
                No tags
            </Typography>
            <Typography variant={'body2'} color={'textSecondary'}>
                Resources with no tags
            </Typography>
            <Grid container style={{marginTop: 16}} spacing={3}>
                <Grid item xs={12}>
                    <Typography gutterBottom color={'textSecondary'}>
                        Additional Information
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <SummaryCard value={resourcesWithNoTagsAccounts || 0}
                                         description={'Number of accounts which are non-compliant'}/>
                        </Grid>
                        <Grid item xs={6}>
                            <SummaryCardLink link={'/tagging/tag-compliance/no-tags'} text={'More Info'}/>
                        </Grid>
                    </Grid>
                </Grid>
                {view === 'chart' &&
                <React.Fragment>
                    <Grid item xs={6}>
                        <Typography gutterBottom color={'textSecondary'}>
                            Most errors from (Accounts)
                        </Typography>
                        <DonutGraph colorPalette={'palette1'}
                                    series={firstThreeAccountsNoTags.map(e => e?.numberOfResourcesAffected || 0)}
                                    labels={firstThreeAccountsNoTags.map(e => e?.account || '')}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography gutterBottom color={'textSecondary'}>
                            Most errors from (Service)
                        </Typography>
                        <DonutGraph colorPalette={'palette2'}
                                    series={firstThreeServicesFromNoTags?.map(e => e?.numberOfService || 0)}
                                    labels={firstThreeServicesFromNoTags?.map(e => e?.service || '')}
                        />
                    </Grid>
                </React.Fragment>}

                {view === 'table' &&
                <React.Fragment>
                    <Grid item xs={12}>
                        <Typography gutterBottom color={'textSecondary'}>
                            Most errors from (Accounts)
                        </Typography>
                        <MostErrorSummaryCard content={firstThreeAccountsNoTags}/>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography gutterBottom color={'textSecondary'}>
                            Most errors from (Service)
                        </Typography>
                        <MostErrorSummaryCard content={firstThreeServicesFromNoTags}/>
                    </Grid>
                </React.Fragment>
                }
            </Grid>
        </div>
    )
}

function MostErrorSummaryCard({content = []}) {
    return (
        <table>
            <tbody>
            {
                content.map((e = {}, index) => (
                    <tr>
                        <td> {index + 1}</td>
                        {Object.values(e).map(item => <td key={item}> {item}</td>)}
                    </tr>
                ))
            }
            </tbody>
        </table>
    )
}

function SummaryCard({title, value, description}) {
    return (
        <Paper className={'tc-summary-card'} variant={'outlined'}>
            {/*<Typography>
                {title}
            </Typography>*/}
            <Typography variant={'h5'} gutterBottom style={{fontWeight: 600}}>
                {value}
            </Typography>
            <Typography variant={'body2'} align={'left'}>
                {description}
            </Typography>
        </Paper>
    )
}

function SummaryCardLink({link, text}) {
    return (
        <Link to={link}>
            <Paper className={'tc-summary-card-link'} variant={'outlined'}>
                {text}
            </Paper>
        </Link>
    )
}