import * as Yup from 'yup';

export const newUserRequestFormValidation = Yup.object().shape({
    firstName: Yup.string()
        .min(3, 'Minimum 3 characters required')
        .max(50, 'Maximum 50 characters allowed')
        .required('First Name is required'),
    lastName: Yup.string()
        .min(1, 'Minimum 3 characters required')
        .max(50, 'Maximum 50 characters allowed'),
    permissionGroup: Yup.string()
        .required('Group is required field'),
    password: Yup.string()
        .min(8, 'Minimum 8 Characters required')
        .required('password is required')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ),
    confirmPassword: Yup.string()
        .test('passwords-match', 'Passwords must match', function (value) {
            return this.parent.password === value
        }),
    email: Yup.string()
        .email('Incorrect Email address provided')
        .matches(/^((?!\.)[\w\-_.]*[^.])(@cloudfinish\.com)$/, "emails are only allowed (...@cloudfinish.com)")
        .required('Email is required')
})