import WuSummaryCard from "../General/WuSummaryCard";
import {Divider, Snackbar, Typography} from "@material-ui/core";
import service from "../../service/service";
import {apiErrorResponseJsonParser, costFormatterGeneral} from "../../helper/generalFunctions";
import {useEffect, useState} from "react";
import {useOrganization} from "../../hooks/useOrganization";
import WuButton from "../General/WuButton";
import moment from "moment";
import Box from "@material-ui/core/Box";
import {Alert} from "@material-ui/lab";
import {ecsListSummary} from "../../assets/finthesys/ecs";

export default function Summary() {
    const {organization: masterAccountId} = useOrganization()
    const [apiProgress, setApiProgress] = useState({loading: false, ready: true, error: false})
    const [apiProgressCron, setApiProgressCron] = useState({loading: false, ready: true, error: false})
    const [snackBarOpen, setSnackBarOpen] = useState(false)
    const [summaryData, setSummaryData] = useState({})

    const fetchData = async () => {
        try {
            setApiProgress({loading: true, ready: false, error: false})
            const params = {
                masterAccountId: masterAccountId
            }
            const {data: {data}} = await service.ecs().summaryEcs(params)
            setSummaryData(data)
            setApiProgress({loading: false, ready: true, error: false})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setApiProgress({loading: false, ready: false, error: error})
        }
    }

    const fetchDummyData = async () => {
        try {
            setApiProgress({loading: true, ready: false, error: false})
            const params = {
                masterAccountId: masterAccountId
            }
            const {data} =  ecsListSummary
            setSummaryData(data)
            setApiProgress({loading: false, ready: true, error: false})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setApiProgress({loading: false, ready: false, error: error})
        }
    }

    const executeCronOnDemand = async () => {
        try {
            setApiProgressCron({loading: true, ready: false, error: false})
            const params = {
                masterAccountId: masterAccountId
            }
            const {data: {data}} = await service.ecs().onDemandEcsCron(params)
            setApiProgressCron({loading: false, ready: data, error: false})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setApiProgressCron({loading: false, ready: false, error: error})
        } finally {
            setSnackBarOpen(true)
        }
    }

    useEffect(() => {
        if (apiProgressCron.ready) fetchDummyData().then()
    }, [apiProgressCron])


    useEffect(() => {
        if (masterAccountId !== 'no org') fetchDummyData().then()
    }, [masterAccountId])

    return (
        <div className={'summary-block'}>
            <SnackBar2 open={snackBarOpen}
                       handleClose={setSnackBarOpen}
                       severity={apiProgressCron.error ? 'error' : 'success'}
                       message={apiProgressCron.error ? apiProgressCron.error : apiProgressCron.ready}
            />
            <div className={'summary-block-inner'}>
                <Typography style={{fontWeight: 600}}>
                    ECS Overview
                </Typography>
                <WuSummaryCard
                    loading={apiProgress.loading}
                    borderBottomColor={'red'}
                    borderBottom={true}
                    description={'Price for clusters'}
                    title={'Price'}
                    value={`$${costFormatterGeneral(summaryData?.price) || 0}`}/>
                <WuSummaryCard
                    loading={apiProgress.loading}
                    borderBottomColor={'orange'}
                    borderBottom={true}
                    description={'Total number of clusters'}
                    title={'Clusters'}
                    value={summaryData.clusterCount || 0}/>
                <WuSummaryCard
                    loading={apiProgress.loading}
                    borderBottomColor={'orange'}
                    borderBottom={true}
                    description={'Total number of services'}
                    title={'Services'}
                    value={summaryData.serviceCount || 0}/>
                <WuSummaryCard
                    loading={apiProgress.loading}
                    borderBottomColor={'orange'}
                    borderBottom={true}
                    description={'Total number of Tasks'}
                    title={'Tasks'}
                    value={summaryData.taskCount || 0}/>

                <Divider/>
                <Box ml={1}>
                    <Typography color={'textSecondary'}>
                        last data refresh on
                    </Typography>
                    <Typography variant={'h6'}>
                        {summaryData.lastCronRunTime}
                    </Typography>
                </Box>
                <Divider/>
                <WuButton onClick={executeCronOnDemand} disabled={apiProgressCron.loading}>REFRESH DATA</WuButton>
            </div>
        </div>
    )
}


function SnackBar2({open, handleClose, severity, message}) {

    const handleCloseSnackbar = () => {
        handleClose(false)
    }
    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity={severity} sx={{width: '100%'}}>
                {message}
            </Alert>
        </Snackbar>
    )
}