import React, {useState} from "react";
import WuTextField from "../../General/WuTextFeild";
import {Button} from "@material-ui/core";
import {AlertWu} from "../../AlertWu/AlertWu";

export function AddRegion(props) {

    const [regionCode, setRegionCode] = useState("");
    const [regionName, setRegionName] = useState("");
    const [fieldError, setFieldError] = useState({
        regionCode: false,
        regionName: false
    })

    const regionCreate = () => {
        setFieldError({
            regionCode: regionCode.length < 1,
            regionName: regionName.length < 1,
        })

        if (regionName && regionCode) {
            console.log(regionCode, regionName)
            return true
        }
    }

    return (
        <React.Fragment>
            <div className="d-f-s" style={{marginTop: 20}}>
                <WuTextField
                    value={regionCode}
                    error={fieldError.regionCode}
                    onChange={(e) => {
                        setRegionCode(e.target.value)
                    }}
                    label="Region Code*"
                />
                <WuTextField
                    value={regionName}
                    error={fieldError.regionName}
                    onChange={(e) => {
                        setRegionName(e.target.value)
                    }}
                    label="Region Name*"
                />
                <Button onClick={regionCreate} variant={"contained"} disableElevation color={"secondary"}>
                    Save
                </Button>
            </div>
            <div style={{marginTop: 20}}>
                <AlertWu
                    alertOpen={{state: true}}
                    severity={"success"}
                    message={"new region added. From now on the new region will reflected all over the dashboard"}/>
            </div>
        </React.Fragment>
    )
}