import React, {useEffect, useState} from 'react';
import {UnusedCard} from "./UnusedHomeCard";
import PageTitle from "../../General/PageTitle/PageTitle";
import Box from "@material-ui/core/Box"
import service from "../../../service/service";
import {useOrganization} from "../../../hooks/useOrganization";

export default function UnUsedHome() {
    const {organization: masterAccountId} = useOrganization();
    const [loading, setLoading] = useState(false);
    const [ready, setReady] = useState(false);
    const [unUsedSnapData, setUnUsedSnapData] = useState([])
    const [ebsData, setUnUsedEbsData] = useState([])
    const [amiData, setUnUsedAmiData] = useState([])
    const [ec2Data, setUnUsedEc2Data] = useState([])
    const [error, setError] = useState({
        error: false,
        errorMessage: undefined
    })
    useEffect(() => {
        if (masterAccountId !== 'no org') {
            setLoading(true)
            setReady(false);
            setError({
                error: false,
                errorMessage: undefined
            })

            service.monitoring().getSnpHomeCount({master_account_id: masterAccountId})
                .then((res) => {
                    setLoading(false);
                    setReady(true);
                    setUnUsedSnapData(res.data.snp_count)
                })
                .catch((err) => {

                    if (!err?.response?.data?.code === 402) {
                        setError({
                            error: true,
                            errorMessage: err.message
                        })
                    } else {
                        setUnUsedSnapData([])
                    }
                    setLoading(false);

                })

            service.monitoring().getEbsHomeCount({master_account_id: masterAccountId})
                .then((res1) => {
                    setLoading(false);
                    setReady(true);
                    setUnUsedEbsData(res1.data.ebs_count)
                })
                .catch((err1) => {

                    if (!err1?.response?.data?.code === 402) {
                        setError({
                            error: true,
                            errorMessage: err1.message
                        })
                    } else {
                        setUnUsedEbsData([])
                    }
                    setLoading(false);

                })

            service.monitoring().getAmiHomeCount({master_account_id: masterAccountId})
                .then((res2) => {
                    setLoading(false);
                    setReady(true);
                    setUnUsedAmiData(res2.data.ami_count)
                })
                .catch((err2) => {

                    if (!err2?.response?.data?.code === 402) {
                        setError({
                            error: true,
                            errorMessage: err2.message
                        })
                    } else {
                        setUnUsedAmiData([])
                    }
                    setLoading(false);

                })

            service.monitoring().getEc2HomeCount({master_account_id: masterAccountId})
                .then((res3) => {
                    setLoading(false);
                    setReady(true);
                    setUnUsedEc2Data(res3.data.ec2_count)
                })
                .catch((err3) => {

                    if (!err3?.response?.data?.code === 402) {
                        setError({
                            error: true,
                            errorMessage: err3.message
                        })
                    } else {
                        setUnUsedEc2Data([])
                    }
                    setLoading(false);

                })
        }
    }, [masterAccountId])

    return (

        <div>
            <Box mb={3}>
                <PageTitle
                    title={"Wastage Monitoring"}
                />
            </Box>
            <div className="c-r-o">
                <UnusedCard
                    numberOfInstanceOptimized={ec2Data.count}
                    totalPrev={ec2Data.prev_price}
                    totalSavings={ec2Data.price}
                    title="EC2 instance"
                    routeLink={"/monitoring/unused-resources/unused-ec2"}
                />
                <UnusedCard
                    numberOfInstanceOptimized={amiData.count}
                    totalPrev={0.00}
                    totalSavings={0.00}
                    title="Amazon Machine Images (AMI)"
                    routeLink={"/monitoring/unused-resources/unused-ami"}
                />
                <UnusedCard
                    numberOfInstanceOptimized={ebsData.count}
                    totalPrev={ebsData.prev_price}
                    totalSavings={ebsData.price}
                    title="EBS Volumes"
                    routeLink={"/monitoring/unused-resources/unused-ebs"}
                />
                <UnusedCard
                    numberOfInstanceOptimized={unUsedSnapData.count}
                    totalPrev={unUsedSnapData.prev_price}
                    totalSavings={unUsedSnapData.price}
                    title="Snapshots"
                    routeLink={"/monitoring/unused-resources/unused-snapshot"}
                />

                <UnusedCard
                    numberOfInstanceOptimized={unUsedSnapData.count}
                    totalPrev={unUsedSnapData.prev_price}
                    totalSavings={unUsedSnapData.price}
                    title="EFS Storage"
                    routeLink={"/monitoring/unused-resources/efs"}
                />

            </div>
        </div>

    )
}