import React, {useState} from 'react';
import WuDialog from "../../General/WuDialog";
import Box from "@material-ui/core/Box";
import {Button, Typography} from "@material-ui/core";
import WuTextField from "../../General/WuTextFeild";
import {AlertWu} from "../../AlertWu/AlertWu";
import {ProgressCircleCustomSize} from "../../ProgressCircle/ProgressCircle";
import service from "../../../service/service";

export default function Confirm(props) {
    const {open, resources = [], accountId} = {...props}
    const [ipRange, setIpRange] = useState('')
    const [loading, setLoading] = useState(false);
    const [ready, setReady] = useState(false);
    const [error, setError] = useState({error: false, message: undefined});

    const handleClose = () => {
        setReady(false);
        setError({error: false, message: undefined});
        props.onChange(false)
    }
    const updateSecurityGroup = async () => {
        try {
            setLoading(true);
            setReady(false);
            setError({error: false, message: undefined})
            const params = {
                security_group_id: resources.map(resource => {
                    return {
                        id: processResourceId(resource['Security Group ID']),
                        region: resource['Region']
                    }
                }),
                ip: ipRange,
                account_id: accountId
            }
            const response = await service.governance().updateSecurityGroupSourceIp(params);
            setReady(true)
        } catch (e) {
            setError({error: true, message: e.message})
        } finally {
            setLoading(false);
        }
    }
    const processResourceId = (groupId) => {
        const groupIdFinal = groupId.split(" ");
        return groupIdFinal[0]
    }

    return (
        <WuDialog open={open} onChange={handleClose} title={'Update Security Group(s)'} size={'xs'}>
            <Box m={2}>
                <Typography>
                    IP Range for <strong>{resources.length} </strong> security group(s) will be updated for
                    the account <strong>{accountId}</strong>
                </Typography>
                <Box mt={1}>
                    <WuTextField label={'Source IP Range'} placeholder={'x.x.x.x/x'}
                                 onChange={(e) => setIpRange(e.target.value)}/>
                </Box>
                <Box mt={2}>
                    <Button variant={'contained'} color={'secondary'} disableElevation disabled={!ipRange}
                            onClick={updateSecurityGroup}>
                        Update Security Group(s)
                    </Button>
                </Box>
            </Box>
            <Box mt={2} mb={2}>
                {loading && <Loading/>}
                <AlertWu alertOpen={{state: ready}} severity={"success"}
                         message={"Auto Tagging Completed"}/>
                <AlertWu alertOpen={{state: error.error}} severity={"error"} message={error.message}/>
            </Box>
        </WuDialog>
    )
}

function Loading() {
    return (
        <Box display={'flex'} className={'gap'} alignItems={'center'} m={2} mb={0}>
            <ProgressCircleCustomSize size={16}/>
            <Typography>
                Updating Source IP range. Please wait...
            </Typography>
        </Box>
    )
}