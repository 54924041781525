import React, {useState, useEffect} from "react";
import PageTitle from "../../General/PageTitle/PageTitle";
import UnUsedResourceFilter from "../UnUsedEbs/UnUsedResourceFilter";
import CloudWatchAccordion from "./CloudWatchLogAccordion";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import WuDialog from "../../General/WuDialog";
import Region from "../../General/Region/Region";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {useOrganization} from "../../../hooks/useOrganization";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import '../../../service/service';
import service from "../../../service/service";
import {AlertWu} from "../../AlertWu/AlertWu";
import {ProgressCircleCustomSize} from "../../ProgressCircle/ProgressCircle";
import { jsonParser} from "../../../helper/generalFunctions";
import fileExportElement from  "../../../helper/fileExportElement"
import WuButton from "../../General/WuButton";
import WuStack from "../../General/WuStack";
import CloudWatchGroupRetentionSet from "./CloudWatchGroupRetentionSet";
import CloudWatchSummary from "./CloudWatchSummary";

export default function CloudWatchHome() {
    const [filter, setFilter] = useState([]);
    const [update, setUpdate] = useState(false);
    const [updateOpen, setUpdateOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const buttonClick=()=>{setOpen(true)}
    const onClose=()=>{setOpen(false)}
    const [region, setRegion] = useState([]);
    const [accountList, setAccountList] = useState([]);
    const [accountSearchString, setAccountSearchString] = useState("");
    const organization = useOrganization();
    const [accountId, setAccountId] = useState('');
    const [masterAccountId, setMasterAccountId] = useState(organization.organization);
    const [errorBoundary, setErrorBoundary] = useState(false);
    const [errorBoundaryAccount, setErrorBoundaryAccount] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState({error: false, message: undefined});
    const [ready, setReady] = useState(false);
    const [loading, setLoading] = useState(false);

    const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
    const checkedIcon = <CheckBoxIcon fontSize="small"/>;

    useEffect(() => {
        setMasterAccountId(organization.organization)
    }, [organization])

    const handleAccountChange = (tempAccountIds) => {
        let accountTemp = []
        accountTemp = tempAccountIds.map(account=>account.AccountId)
        if (accountTemp.length === 1) {
            if (accountTemp[0]['accountId'] === 'All') {
                accountTemp = 'All'
            }
        }
        setAccountId(accountTemp);
    }

    useEffect(() =>{
        setLoaded(false);
        setError("");
        setLoading(false);
        setReady(false);
        setAccountSearchString("");

    }, [open])
    useEffect(() => {
        setLoaded(true);
        setErrorBoundaryAccount(false);
        const param = {master_id: masterAccountId}
        service.general().getAccountInformationByMasterId(param)
            .then((ref) => {
                let data = ref.data.account;
                data.unshift({AccountId: 'All', AccountAlias: 'All'})
                setAccountList(data);
                setLoaded(false);
            })
            .catch((err) => {
                setLoaded(false);
                setErrorBoundaryAccount(true);
                setError(err.message)
            })

    }, [masterAccountId])
    const handleUpdateRequest = async () => {
        try {
            setReady(false);
            setLoading(true);
            setError({error: false, message: undefined})

            const params = {
                account_list: accountId,
                region_list: region,
                master_account_id: masterAccountId
            }

            await service.monitoring().downloadEbs(params)
                .then((response) => {
                    fileExportElement(response);
                    setReady(true);
                    setLoading(false);
                })
                .catch((err) => {
                    //setLoaded(false);
                    //setErrorBoundaryAccount(true);

                    const errorMessage = jsonParser(err.request.response).error;
                    console.log(err.request.response, errorMessage)
                    setLoading(false);

                    setError({error: true, message: errorMessage ? errorMessage : err.message});
                })

        } catch (e) {

            const errorMessage = jsonParser(e.request.response).error;
            setLoading(false);

            setError({error: true, message: errorMessage ? errorMessage : e.message});
        }
    }
    const handleSetRequest = async () => {
        try {
            setReady(false);
            setLoading(true);
            setError({error: false, message: undefined})

            const params = {
                account_list: accountId,
                region_list: region,
                master_account_id: masterAccountId
            }

            await service.monitoring().downloadEbs(params)
                .then((response) => {
                    fileExportElement(response);
                    setReady(true);
                    setLoading(false);
                })
                .catch((err) => {
                    //setLoaded(false);
                    //setErrorBoundaryAccount(true);

                    const errorMessage = jsonParser(err.request.response).error;
                    console.log(err.request.response, errorMessage)
                    setLoading(false);

                    setError({error: true, message: errorMessage ? errorMessage : err.message});
                })

        } catch (e) {

            const errorMessage = jsonParser(e.request.response).error;
            setLoading(false);

            setError({error: true, message: errorMessage ? errorMessage : e.message});
        }
    }
    return (
        <div style={{width: '100%'}}>
            <Box mb={3}>
                <PageTitle
                    title={"Cloud Watch Log Monitoring"}
                    subTitle={"Cloud Watch Logs"}
                />
            </Box>
            <Box>
                <WuStack alignItems={'center'}>
                    <UnUsedResourceFilter
                        backButtonLink={'/monitoring/log-tracking/summary'}
                        onClick={(event) => setFilter(event)}/>

                    <WuDialog open={open} title={'Generate Report'} onChange={onClose}>
                        <Autocomplete
                            multiple
                            size="small"
                            limitTags={2}
                            style={{
                                marginTop: 10,
                                marginBottom: 10,
                                marginLeft: "22%",
                                width: "60%"
                            }}
                            inputValue={accountSearchString}
                            options={accountList}
                            loading={loaded}
                            onChange={(event, value) => handleAccountChange(value)}
                            disableCloseOnSelect={true}
                            getOptionLabel={
                                (account) =>
                                    account.AccountId ? account.AccountAlias + " (" + account.AccountId + ")" : ""
                            }
                            renderOption={(option, {selected}) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{marginRight: 8}}
                                        checked={selected}
                                    />
                                    {option.AccountId ? option.AccountAlias + " (" + option.AccountId + ")" : ""}
                                </React.Fragment>
                            )}
                            fullWidth={true}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="Accounts"
                                           onChange={(e) => setAccountSearchString(e.target.value)}
                                           placeholder="Accounts"/>
                            )}
                        />
                        <div style={{
                            marginTop: 10,
                            marginBottom: 10,
                            marginLeft: "22%",
                            width: "60%"
                        }}>
                            <Region onChange={(e) => setRegion(e.regionList)}/>
                        </div>
                        <Button style={{width: 150, height: 35, marginLeft: "40%",marginTop: 10,
                            marginBottom: 10,}}
                                onClick={handleUpdateRequest}
                                variant="contained" color="primary">
                            Download
                        </Button>

                        {
                            loading &&
                            <Box ml={1}>
                                <ProgressCircleCustomSize size={16}/>
                            </Box>
                        }

                        <Box mt={2}>
                            <AlertWu alertOpen={{state: ready}} severity={"success"} message={"Downloaded"}/>
                            <AlertWu alertOpen={{state: error.error}} severity={"error"} message={error.message}/>
                        </Box>
                    </WuDialog>
                </WuStack>
            </Box>
            <WuStack style={{marginTop: 20}} spacing={3}>
                <CloudWatchAccordion filter={filter}/>
                <CloudWatchSummary onDownloadButtonClick={buttonClick}/>
            </WuStack>
        </div>
    )
}