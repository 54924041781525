import React from "react";
import NoDataImage from '../../assets/images/no money.png'
import {Fade, Typography} from "@material-ui/core";
import WuStack from "./WuStack";

export default function NoData({justifyContent = 'center'}) {
    return (
        <Fade in={500} timeout={500}>
            <WuStack alignItems={'center'} justifyContent={justifyContent}>
                <img src={NoDataImage} alt={'no data'} style={{width: 350, transform: 'translateX(50px)'}}/>
                <div>
                    <Typography variant={'h5'} gutterBottom>
                        Oops! We Couldn't Find the Data You Looking for.
                    </Typography>
                    <Typography variant={'h6'} align={'left'}>
                        This could be because,
                    </Typography>
                    <ul>
                        <Typography variant={'subtitle1'} component={"li"} align={'left'}>
                            You're not in the right organization
                        </Typography>
                        <Typography variant={'subtitle1'} component={"li"} align={'left'}>
                            Your filters are incorrect!
                        </Typography>
                        <Typography variant={'subtitle1'} component={"li"} align={'left'}>
                            May be its a good thing to know, no data to worry for.
                        </Typography>
                    </ul>

                </div>
            </WuStack>
        </Fade>
    )
}