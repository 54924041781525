import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TagPolicyTable from "./TagpolicyTable";
import PolicySummary from "./PolicySummary";
import {WuAccordionDetails, WuAccordionSummary, WuAccordionWrapper} from "../../General";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightRegular,
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        flexBasis: '33.33%'
    },
    thirdHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    subHeading: {
        fontSize: theme.typography.pxToRem(18),
        paddingLeft: 15,
        paddingBottom: 20,
        fontWeight: theme.typography.fontWeightBold,
    }
}));

export default function TagPolicyAccordion(props) {
    const classes = useStyles();

    return (
        <div>
            {
                props.tagPolicies.map((tagPolicy, index) => {
                    return (
                        <WuAccordionWrapper key={index}>
                            <WuAccordionSummary>
                                <Typography className={classes.heading}>{tagPolicy.PolicyName}</Typography>
                                <Typography className={classes.secondaryHeading}>Policy
                                    Id: {tagPolicy.PolicyId}</Typography>
                                <Typography className={classes.thirdHeading}> Number of Targets
                                    : {tagPolicy.NumberOfTargets}</Typography>
                            </WuAccordionSummary>
                            <WuAccordionDetails>
                                <div style={{width: '100%'}}>
                                    <div className="accord-details">
                                        Account/ OU Details
                                        <span className="summary">
                                    <PolicySummary policyId={tagPolicy.PolicyId}/>
                                </span>
                                    </div>
                                    {
                                        tagPolicy?.Targets?.map((target, index) => {
                                            return (
                                                <TagPolicyTable
                                                    key={index}
                                                    policyId={tagPolicy.PolicyId}
                                                    targets={target}/>
                                            )
                                        })
                                    }
                                </div>
                            </WuAccordionDetails>
                        </WuAccordionWrapper>
                    )
                })
            }
        </div>
    );
}