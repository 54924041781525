import React, {useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";
import {Accounts, EnvironmentMultiSelect, Region} from "../../../General/WuAutoComplete";
import IconButton from "@material-ui/core/IconButton";
import {DeleteOutlined} from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import {Collapse, InputAdornment, InputLabel, OutlinedInput} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";


export default function AccountInformation(props) {

    const [accountArray, setAccountArray] = useState([{}]);

    const handleChanges = (e) => {
        if (e.action === "add") {
            let tempAccountArray = [...accountArray]
            tempAccountArray.push({})
            setAccountArray(tempAccountArray)
        } else if (e.action === "delete") {
            setAccountArray([...accountArray.slice(0, e.id), ...accountArray.slice(e.id + 1)]);
        }
    }

    const handleAccountChange = (e) => {
        let tempAccountArray = [...accountArray]
        tempAccountArray[e.idx] = e
        setAccountArray(tempAccountArray)
    }


    useEffect(() => {
        props.onChange(accountArray)
    }, [accountArray])

    return (
        <div>
            <div className="d-f">
                <Typography variant={"subtitle2"}>
                    Relation between different account, region and environment. <br/>
                    Budget details will be immediately reflected in your public cloud account after completing the
                    onboarding
                </Typography>
            </div>
            {
                accountArray.map((accountData, index) => {
                    return <AccountFormData clicked={handleChanges} onChange={handleAccountChange} key={index}
                                            accountData={accountData} idx={index}
                                            propDisabled={index === accountArray.length - 1 ? false : true}/>
                })
            }

        </div>

    )
}

function AccountFormData(props) {
    const {idx, propDisabled} = {...props}
    const [disabled, setDisabled] = useState(true);
    const [account, setAccount] = useState(props.account);
    const [region, setRegion] = useState(props.region);
    const [budgetAmount, setBudgetAmount] = useState(props.budgetAmount ? props.budgetAmount : 0);
    const [notificationSubscribers, setNotificationSubscribers] = useState(props.notificationSubscribers);
    const [environment, setEnvironment] = useState(props.environment);


    useEffect(() => {
        if (account && region) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
        props.onChange({account, region, budgetAmount, notificationSubscribers, environment, idx})

    }, [account, region, budgetAmount, notificationSubscribers, environment])


    return (
        <Collapse in={true}>
            <form className="account-environment" noValidate={false} autoComplete="on">
                <div className={"d-f"}>
                    <Accounts
                        masterAccount={477419074820}
                        defaultValue={account}
                        onChange={(e) => setAccount(e.accountId)}/>
                    <Region
                        defaultValue={region}
                        onChange={(e) => setRegion(e.region)}
                    />
                    {!propDisabled &&
                    <React.Fragment>
                        <IconButton
                            onClick={() => {
                                props.clicked({action: 'delete', id: idx})
                            }}
                            disabled={propDisabled}
                            aria-label="delete">
                            <DeleteOutlined/>
                        </IconButton>
                        <Button
                            style={{width: 140}}
                            variant="contained"
                            disabled={disabled}
                            color="secondary"
                            disableElevation
                            onClick={() => {
                                props.clicked({action: 'add', id: null})
                            }}
                        >
                            ADD
                        </Button>
                    </React.Fragment>

                    }

                </div>

                <div className={"d-f"}>
                    <FormControl fullWidth margin={"dense"} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                        <OutlinedInput
                            value={budgetAmount}
                            id="outlined-adornment-amount"
                            onChange={(e) => setBudgetAmount(e.target.value)}
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            labelWidth={60}
                        />
                    </FormControl>
                    <TextField type={"email"}
                               value={notificationSubscribers}
                               fullWidth
                               onChange={(e) => setNotificationSubscribers(e.target.value)}
                               id="notification_subscribers"
                               label="Notification Subscribers"
                               margin="dense"
                               variant="outlined"/>
                    <EnvironmentMultiSelect onChange={(e) => setEnvironment(e.environment)}/>
                </div>
            </form>
        </Collapse>
    )
}