import {useEffect, useState} from "react";
import {apiErrorResponseJsonParser} from "../helper/generalFunctions";
import service from "../service/service";
import {useAuthorization} from "../hooks";

export function PermissionProvider({children}) {
    const [apiProgress, setApiProgress] = useState({loading: false, ready: false, error: false})
    const [authorization, setAuthorization] = useAuthorization()

    async function refreshAccessToken() {
        try {
            setApiProgress({loading: true, ready: false, error: false})
            await service.authentication().refreshToken()
            const {data: {data}} = await service.authentication().refreshToken()
            window.accessToken = data
            setAuthorization({
                userData: {},
                authenticated: true
            })
            setApiProgress({loading: false, ready: true, error: false})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setApiProgress({loading: false, ready: false, error: error})
        }
    }

    function loadLocalCredentials() {
        const authorized = localStorage.getItem('x-auth')
        if (authorized) {
            setAuthorization({userData: null, authenticated: true})
        }
    }

    useEffect(() => {
        loadLocalCredentials()
        //refreshAccessToken().then()
    }, [])

    return <>
        {/*{apiProgress.loading && 'authenticating...'}
        {apiProgress.ready && children}*/}
        {children}
    </>
}