import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import service from "../../../../service/service";
import {AlertWu} from "../../../AlertWu/AlertWu";
import {ProgressCircleCustomSize} from "../../../ProgressCircle/ProgressCircle";

export default function ApplicationDelete(props) {
    const {open, applicationInformation} = {...props}
    const [ready, setReady] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({
        error: false,
        errMessage: undefined
    })
    const handleClose = () => {
        setReady(false);
        setLoading(false);
        setError({
            error: false,
            errMessage: undefined
        })
        props.onClick(false)
    };


    const handleApplicationDelete = () => {
        setLoading(true);
        setReady(false);
        const param = {OnBoardingId: applicationInformation.OnBoardingId}
        service.onBoarding().deleteApplication(param)
            .then((ref) => {
                setLoading(false);
                setReady(true);
            })
            .catch((err) => {
                setLoading(false)
                setError({
                    error: true,
                    errMessage: err.message
                })
            })
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="alert-dialog-title">
                    {`Delete - ${applicationInformation.ApplicationName}?`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Deleting the Application will, delete
                        <strong>'BUDGETS'</strong> and
                        <strong> ALL OTHER DATA.</strong>
                    </DialogContentText>
                </DialogContent>
                <AlertWu
                    alertOpen={{state: ready}}
                    severity={"success"}
                    message={"Deleted successfully"}>
                </AlertWu>

                <AlertWu
                    alertOpen={{state: error.error}}
                    severity={"error"}
                    message={error.message}>
                </AlertWu>
                <DialogActions>
                    {loading && <ProgressCircleCustomSize size={16}/>}
                    <Button onClick={handleClose} color="secondary">
                        CANCEL
                    </Button>
                    <Button onClick={handleApplicationDelete} disabled={ready} color="secondary" autoFocus>
                        DELETE
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}