import React from "react";
import {NavLink} from "react-router-dom";
import {SavingsPlanRoute} from "../../../../routes/MainRoute";


function SavingsPlanNavigation(){
    return(
        <nav className="savings-navigation">
            <ul>
                <li>
                    <NavLink to="/insights/purchase-recommendations/savings-plan/inventory">
                        Inventory
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/insights/purchase-recommendations/savings-plan/recommendations">
                        Recommendations
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/insights/purchase-recommendations/savings-plan/utilization">
                        Utilization Reports
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/insights/purchase-recommendations/savings-plan/coverage">
                        Coverage Report
                    </NavLink>
                </li>
            </ul>
        </nav>
    )
}


export default function SavingsPlan(){
    return(
        <div className="savings-plan">
           <div className="content">
                <SavingsPlanRoute/>
           </div>
            <SavingsPlanNavigation/>
        </div>
    )
}