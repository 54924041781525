export const otherLinks = [
    {
        title: 'Mandatory FinOps Tags',
        link: 'https://confluence.corpprod.awswuintranet.net/display/CCOE/Standard%3A+WU-O106+Mandatory+Tags+for+FinOps'
    },
    {
        title: 'RDS Optimization',
        link: 'https://confluence.corpprod.awswuintranet.net/display/CCOE/Standard%3A+WU-O105+FinOps+RDS+optimization+guide'
    },
    {
        title: 'EC2 Right Sizing',
        link: 'https://confluence.corpprod.awswuintranet.net/display/CCOE/Standard%3A+WU-O107+Recommendations+for+EC2+Rightsizing'

    }, {
        title: 'Tag reports & Remediation',
        link: 'https://confluence.corpprod.awswuintranet.net/pages/viewpage.action?pageId=417491044'

    },
    {
        title: 'Power Scheduling',
        link: 'https://confluence.corpprod.awswuintranet.net/display/CCOE/Standard%3A+WU-O104+Power+Scheduling'

    },
    {
        title: 'FinOps Process for ARB',
        link: 'https://confluence.corpprod.awswuintranet.net/display/CCOE/Standard%3A+WU-O108+FinOps+Process+for+ARB'

    }
]
