import React, {useEffect, useState} from 'react';
import Box from "@material-ui/core/Box";
import {Collapse, Divider, Grid, Typography} from "@material-ui/core";
import {Accounts} from "../../../General/WuAutoComplete";
import WuSpacer from "../../../General/WuSpacer";
import DataGrid from "../../../General/DataGrid/DataGrid";
import ActionDialog from "./ActionDialog";
import {AlertWu} from "../../../AlertWu/AlertWu";
import {jsonParser} from "../../../../helper/generalFunctions";
import service from "../../../../service/service";
import IconButton from "@material-ui/core/IconButton";
import {ArrowBackIosRounded} from "@material-ui/icons";
import WuToolTip from "../../../General/WuToolTip";
import WuButton from "../../../General/WuButton";
import WuLoading from "../../../General/WuLoading";
import WuStack from "../../../General/WuStack";
import CustomCellEditor from "../../../General/DataGrid/CustomCellEditor";
import {mainAwsTags} from "../../../../helper/mainTags";

const fixedHeader = [{
    field: "Arn",
    headerName: 'Amazon Resource Name',
    resizable: true,
    sortable: true,
    suppressAutoSize: false,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    width: 250
}]

export default function Search(props) {
    const {height, customAction = undefined, setSelectedResources} = {...props}
    const [activeComponent, setActiveComponent] = useState(0);
    const [error, setError] = useState({error: false, message: undefined})
    const [ready, setReady] = useState(false)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({});

    const handleComponentChange = async (e) => {
        await fetchData(e)
        setActiveComponent(activeComponent + 1)
    }

    const fetchData = async (e) => {
        try {
            setData({});
            setReady(false);
            setLoading(true)
            setError({error: false, message: undefined})
            const params = {
                account_id: e.accountId
            }
            const {data: {data}} = await service.automations().searchPowerScheduleResources(params);
            setData({
                gridData: prepareData(data),
                accountId: e.accountId
            })
            props.onChange({size: 'xl', accountId: e.accountId, accountAlias: data.accountAlias})
            setReady(true)
        } catch (e) {
            const errorMessage = jsonParser(e.request.response).error || undefined
            setError({error: true, message: errorMessage ? errorMessage : e.message})
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (activeComponent === 0) {
            props.onChange({size: 'xs', accountId: null})
        }
    }, [activeComponent])


    const prepareData = (data) => {
        const newHeaderArray = []
        data.header.forEach(e => {
            if (e !== 'Arn') {
                let newObject = {
                    field: e,
                    resizable: true,
                    sortable: true,
                }
                if (mainAwsTags.includes(e)) newObject['cellRenderer'] = 'editCellRender'
                newHeaderArray.push(newObject)


            }
        })
        data.header = [...fixedHeader, ...newHeaderArray]
        return data
    }


    return (
        <Box>
            {activeComponent === 0 && <SearchUi onClick={handleComponentChange}/>}
            <Collapse in={ready}>
                {(activeComponent === 1 && ready) && <SearchResult height={height}
                                                                   setSelectedResources={setSelectedResources}
                                                                   customAction={customAction}
                                                                   onChange={() => setActiveComponent(0)}
                                                                   data={data}/>}
            </Collapse>
            <Collapse in={loading}>
                <Box ml={2} display={'flex'} className={'gap'} alignItems={'center'} mb={2}
                     mt={(activeComponent === 1) ? 2 : 0}>
                    <WuLoading message={'Loading resources... Please wait...'} open={loading}/>
                </Box>
            </Collapse>
            <Box m={error.error ? 2 : 0}>
                <AlertWu alertOpen={{state: error.error}} severity={"error"} message={error.message}/>
            </Box>
        </Box>
    )
}

function SearchUi(props) {
    const [accountId, setAccountId] = useState('');

    const handleSearch = () => {
        props.onClick({accountId})
    }

    return (
        <Box m={2} mt={3} mb={2}>
            <Grid container spacing={2}>
                <WuSpacer space={5}/>
                <Grid item xs={12}>
                    <Accounts onChange={(e) => setAccountId(e.accountId)}/>
                </Grid>
                <Grid item xs={12}>
                    <Box display={'flex'} className={'gap'} mt={1}>
                        <WuButton
                            disabled={!accountId}
                            color={'secondary'}
                            onClick={handleSearch}>
                            SEARCH RESOURCES
                        </WuButton>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export function SearchResult(props) {
    const {data, height, customAction, setSelectedResources: selectedResourceCustomComponentFn} = {...props}
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [selectedResources, setSelectedResources] = useState([]);
    const [selectedAction, setSelectedAction] = useState('');

    const handleOperation = (e) => {
        setSelectedAction(e)
        setConfirmDialogOpen(true);
    }

    const handleSelectionChange = (e) => {
        setSelectedResources(e)
        if (customAction) {
            selectedResourceCustomComponentFn(e)
        }
    }

    return (
        <Box mb={2}>
            {confirmDialogOpen && <ActionDialog open={confirmDialogOpen}
                                                selectedResources={selectedResources}
                                                accountId={data.accountId}
                                                region={data.region}
                                                resourceType={data.resourceType}
                                                onChange={() => setConfirmDialogOpen(false)}
                                                selectedActionItem={selectedAction}/>}
            <Box mb={2}>
                <DataGrid gridData={data.gridData.report || []}
                          rowClickSelection={false}
                          gridStyle={{height: height ? height : '70vh'}}
                          frameworkComponents={{
                              editCellRender: CustomCellEditor,
                          }}
                          fileName={`rds_ec2_${data.accountId}`}
                          fixedColumn={data.gridData.header || []}
                          gridRowSelection={'multiple'}
                          onChange={handleSelectionChange}/>
            </Box>
            <Box className={'gap'} display={'flex'} ml={2} mr={3} justifyContent={'space-between'}
                 alignItems={'center'}>
                {
                    <SearchActions customAction={customAction}
                                   onChange={() => props.onChange()}
                                   onClick={(e) => handleOperation(e)}/>
                }

                <Box>
                    <Typography><strong>{selectedResources.length || 0}</strong> of <strong>{data.gridData.report.length || 0}</strong> resources
                        selected</Typography>
                </Box>
            </Box>
        </Box>
    )
}

function SearchActions(props) {

    const handleOperation = (e) => {
        props.onClick(e)
    }

    return (
        <Box className={'gap'} display={'flex'}>
            <WuToolTip title={'Go Back'}>
                <IconButton size={'small'} onClick={() => props.onChange()} style={{height: 32, width: 32}}>
                    <ArrowBackIosRounded/>
                </IconButton>
            </WuToolTip>
            <WuStack spacing={2}>
                <WuButton onClick={() => handleOperation('set')}>Power Schedule</WuButton>
                <WuButton onClick={() => handleOperation('remove')}
                          style={{background: 'red', color: 'white'}}>
                    Remove Power Schedule
                </WuButton>

                {
                    localStorage.getItem('loginType') === "admin" &&
                    localStorage.getItem('loginPriority') === "super-admin" && <React.Fragment>
                        <Divider orientation={'vertical'} flexItem/>
                        <WuButton onClick={() => handleOperation('start')} color={'default'}>START</WuButton>
                        <WuButton onClick={() => handleOperation('stop')} color={'default'}> STOP </WuButton>
                    </React.Fragment>

                }
            </WuStack>
        </Box>
    )
}