import {Redirect, Route, Switch} from "react-router-dom";
import RegionSetting from "../components/Setting/Region/RegionSetting";
import EnvironmentSetting from "../components/Setting/Environment/EnvironmentSetting";
import AccountSetting from "../components/Setting/Account/AccountSetting";
import AccountCategorySetting from "../components/Setting/Account/AccountCategory/AccountCategorySetting";
import Account from "../components/Setting/Account/AccountListing/Account";
import ResourceTagSetting from "../components/Setting/ResourceTag/ResourceTagSetting";
import OrganizationSetting from "../components/Setting/Organization/OrganizationSetting";
import CrossAccountRoleSetting from "../components/Setting/CrossAccountRole/CrossAccountRoleSetting";
import Profile from "../components/Setting/Profile/Profile";
import User from "../components/Setting/User/User";
import ResourceManagement from "../components/Setting/ResourceManagement";

function SettingRoute() {
    return (
        <Switch>
            <Route exact path="/settings">
                <Redirect to="/settings/region"/>
            </Route>
            <Route path='/settings/region' component={RegionSetting}/>
            <Route path='/settings/environment' component={EnvironmentSetting}/>
            <Route path='/settings/resource-tags' component={ResourceTagSetting}/>
            <Route path='/settings/account' component={AccountSetting}/>
            <Route path='/settings/cross-account-role' component={CrossAccountRoleSetting}/>
            <Route path='/settings/profile' component={Profile}/>
            <Route path='/settings/organization' component={OrganizationSetting}/>
            <Route path='/settings/users' component={User}/>
            <Route path='/settings/resource-management' component={ResourceManagement}/>
        </Switch>
    );
}


function SettingAccountRoute() {
    return (
        <Switch>
            <Route exact path="/settings/account/">
                <Redirect to="/settings/account/listing"/>
            </Route>
            <Route path='/settings/account/listing' component={Account}/>
            <Route path='/settings/account/category' component={AccountCategorySetting}/>
        </Switch>
    );
}

export {SettingRoute, SettingAccountRoute};