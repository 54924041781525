import React, {useEffect, useState} from "react";
import DataGrid from "../../../General/DataGrid/DataGrid";
import {useOrganization} from "../../../../hooks/useOrganization";
import service from "../../../../service/service";
import {AlertWu} from "../../../AlertWu/AlertWu";

let accountColumn = [
    {field: "AccountId", headerName: "Account Id2", resizable: true, sortable: true},
    {field: "AccountAlias", resizable: true, sortable: true},
    {field: "AccountCategory", resizable: true, sortable: true},
    {field: "AccountName", resizable: true, sortable: true},

]

export default function Account() {
    const organization = useOrganization()
    const [loading, setLoading] = useState(false);
    const [ready, setReady] = useState(false);
    const [accountList, setAccountList] = useState([]);
    const [error, setError] = useState({
        error: false,
        errorMessage: ""
    })

    useEffect(() => {
        if (organization.organization !== "no org") {
            setLoading(true);
            setReady(false);
            const param = {master_id: organization.organization}
            service.general().getAccountInformationByMasterId(param)
                .then((ref) => {
                    let data = ref.data.account;
                    setAccountList(data);
                    setLoading(false);
                    setReady(true);
                })
                .catch((err) => {
                    setLoading(false);
                    setError({error: true, errorMessage: err.message})
                })
        }
    }, [organization.organization])

    return (
        <div>
            <div className="account-setting-list">
                {
                    !loading ?
                        <div className="onboarding-grid-wrapper">
                            <DataGrid
                                fileName={`accounts - ${organization.organization}`}
                                fixedColumn={accountColumn}
                                gridStyle={{height: "calc(100vh - 350px)"}}
                                gridData={accountList}
                                gridClass="onboarding-grid"/></div> : "loading..."

                }

            </div>

            <AlertWu
                alertOpen={{state: error.error}}
                severity={"error"}
                message={error.message}/>

        </div>
    )
}