import React from 'react'
import RequestForm from "./NewUserRequestForm";


export default function NewUserRequestForm() {
    //const displayForm = localStorage.getItem('userRequestSubmitted')
    const displayForm = 'false'

    return (
        <>
            {displayForm !== 'true' && <RequestForm/>}
        </>
    )
}