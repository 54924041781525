import React from "react";
import Box from "@material-ui/core/Box";
import {Paper, Typography} from "@material-ui/core";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SpendCurrentMonthTrend from "./SpendCurrentMonthTrend";
import {costFormatterGeneral} from "../../../helper/generalFunctions";
import {ArrowDownwardOutlined} from "@material-ui/icons";

export default function SpendCurrentMonth({organizationSpendData, graph, costOptimizationPercentage}) {
    return (
        <Box className={'gap'} display={"flex"} m={2}>
            <TotalSpendCard spendData={organizationSpendData.currentMonthSpend}
                            title={'Current Month Spend'}
                            spendTrend={graph}
                            costOptimizationPercentage={costOptimizationPercentage}/>
            <TotalSpendCard spendData={organizationSpendData.currentMonthForecastedAmount}
                            title={'Forecasted Amount'}/>
        </Box>
    )
}


/**
 *
 * @param {number} spendData - spendData
 * @param {number} costOptimizationPercentage - percentage of savings
 * @param {string} title - title
 * @param {Object} spendTrend - trend graph
 * @return {JSX.Element}
 * @constructor
 */
function TotalSpendCard({spendData, costOptimizationPercentage, title, spendTrend}) {
    const {summary, organizationWise} = {...spendData}
    const {totalAmount, rateOfVariation} = {...summary}
    return (
        <Paper variant={"outlined"} className="total-spend-card-wrapper w-100">
            <Paper variant={"outlined"} className="total-spend-card-content w-100">
                <div className="graph-spend-summary">
                    {spendTrend && <SpendCurrentMonthTrend spendTrend={spendTrend}/>}
                </div>
                <div className="mask">
                    <Typography color="textSecondary" gutterBottom>
                        {title}
                    </Typography>
                    <Box display={"flex"} alignItems={"center"} mb={1}>
                        <Typography variant={"h4"}>
                            $ {costFormatterGeneral(totalAmount)}
                        </Typography>
                        <SpendTrend percentage={rateOfVariation}/>
                    </Box>
                    {
                        costOptimizationPercentage ?
                            <Typography variant={"subtitle1"}>
                                {costOptimizationPercentage.toFixed(2)}% - Cost optimization possibilities
                            </Typography>
                            : rateOfVariation > 0 ?
                            <Typography variant={"subtitle1"}>
                                Trend is upwards. Please consider optimizations
                            </Typography>
                            : <Typography variant={"subtitle1"}>
                                Trend is downwards. You're good!
                            </Typography>
                    }

                </div>
            </Paper>
            <Box display={"flex"} className={"w-100"}>
                {
                    organizationWise &&
                    organizationWise.map((organizationSpend, index) => {
                        const {amount, percentageOfChange, organizationAlias} = {...organizationSpend}
                        return (
                            <SingleAccountSpendCard key={index} organizationName={organizationAlias}
                                                    spendAmount={amount}
                                                    changePercentage={percentageOfChange}/>
                        )
                    })
                }
            </Box>
        </Paper>
    )
}

function SingleAccountSpendCard({organizationName = "", spendAmount = 0, changePercentage = 0}) {
    return (
        <Paper variant={"outlined"} className="single-account-spend" style={{width: "99%"}}>
            <Typography color="textSecondary" gutterBottom>
                {organizationName.toUpperCase()}
            </Typography>
            <Box display={"flex"} alignItems={"center"}>
                <Typography variant={"h6"} gutterBottom>
                    ${costFormatterGeneral(spendAmount)}
                </Typography>
                <SpendTrend percentage={changePercentage}/>
            </Box>
        </Paper>
    )
}


function SpendTrend({percentage}) {
    return (
        <Box alignItems={"center"} display={"flex"} ml={1}>
            (
            {
                percentage >= 0 ?
                    <ArrowUpwardIcon fontSize={"small"} style={{color: "red"}}/> :
                    <ArrowDownwardOutlined fontSize={"small"} style={{color: "green"}}/>
            }
            <Typography variant={"subtitle2"}>
                {Math.abs(percentage).toFixed(2)}%
            </Typography>
            )
        </Box>
    )
}