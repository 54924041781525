import react, {useEffect, useState} from "react";
import "./data_grid.scss";
import {AgGridReact} from "@ag-grid-community/react";
import {AllCommunityModules} from "@ag-grid-community/all-modules";
import Pagination from "@material-ui/lab/Pagination";
import {dataGridDataProcessing} from "./DataParser";
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import {SaveOutlined, SettingsOutlined} from "@material-ui/icons";
import Setting from "./Setting";
import Box from "@material-ui/core/Box";
import {Fade, Paper} from "@material-ui/core";
import {autoSizeColumnsBasedOnData, autoSizeGridColumns, paginationChange} from "./utilites";
import GridSettingsAndAdjustments from "./DataControlPanel";
import DataControlPanel from "./DataControlPanel";


export default function DataGrid(props) {

    const {
        gridData,
        gridClass = "data-grid-wrapper",
        fixedColumn,
        gridRef = undefined,
        frameworkComponents,
        components,
        onCellClicked = undefined,
        onRowDataChanged= undefined,
        fileName,
        update,
        rowClassRules = {},
        onCellValueChanged,
        gridStyle,
        onSelectionChange = () => {
        },
        rowClickSelection = false,
        suppressDataControlPanel = false,
        gridRowSelection = 'single'
    } = props
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [rowData, setRowData] = useState(null);
    const [columnData, setColumn] = useState(null);
    const [paginationControls, setPaginationControls] = useState({currentPage: 0, totalPage: 0})


    const onSelectionChanged = () => {
        onSelectionChange(gridApi.getSelectedRows())
    }

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        if(gridRef){
            gridRef(params.api)
        }
        makeRowColumnData()
    }

    const onGridSizeChanged = () => {
        //autoSizeColumnsBasedOnData(gridApi, gridColumnApi)
        autoSizeGridColumns(gridApi, gridColumnApi)
    }

    const onPaginationChanged = () => {
        if (gridApi) {
            setPaginationControls({
                currentPage: gridApi.paginationGetCurrentPage() + 1,
                totalPage: gridApi.paginationGetTotalPages()
            })
        }
        paginationChange(gridApi)
    };

    const makeRowColumnData = () => {
        const {row, column} = dataGridDataProcessing(gridData)
        setRowData(row)
        setColumn(column)
    }

    useEffect(() => {
        if (gridApi) {
            makeRowColumnData()
            gridApi.redrawRows();
        }
    }, [gridData])


    return (
        <Fade in={true} timeout={700}>
            <Paper elevation={0}>
                <div className="ag-theme-custom-react">
                    {
                        !suppressDataControlPanel &&
                        <DataControlPanel gridApi={gridApi}
                                          totalPages={paginationControls.totalPage}
                                          currentPage={paginationControls.currentPage}/>
                    }
                    <div style={gridStyle} className={gridClass} id={'grid-wrapper'}>
                        <AgGridReact
                            defaultColDef={{
                                resizable: true,
                                editable: false,
                                filter: 'agTextColumnFilter',
                                floatingFilter: false,
                                suppressAutoSize: true,
                                valueFormatter:function ({value}) {
                                    if(value === undefined || value === null || value === ' ') return '-'
                                    return value
                                }
                            }}
                            rowClassRules={rowClassRules}
                            onRowDataChanged={onRowDataChanged}
                            headerHeight={40}
                            enableCellChangeFlash={true}
                            frameworkComponents={frameworkComponents}
                            components={components}
                            animateRows={true}
                            pagination={true}
                            rowSelection={gridRowSelection}
                            paginationAutoPageSize={true}
                            enableCellTextSelection={true}
                            onCellValueChanged={onCellValueChanged}
                            onCellClicked={onCellClicked}
                            modules={AllCommunityModules}
                            suppressRowClickSelection={!rowClickSelection}
                            onGridSizeChanged={onGridSizeChanged}
                            onSelectionChanged={onSelectionChanged}
                            onPaginationChanged={onPaginationChanged}
                            onGridReady={onGridReady}
                            suppressPaginationPanel={true}
                            columnDefs={fixedColumn ? fixedColumn : columnData}
                            rowData={rowData}>
                        </AgGridReact>
                    </div>
                </div>
            </Paper>
        </Fade>
    );
}