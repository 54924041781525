import React from 'react';
import Box from "@material-ui/core/Box";
import ApplicationSpend from "./ApplicationSpend";
import ResourceSpend from "./ResourceSpend";
import {Grid} from "@material-ui/core";
import ReportDownload from "./ReportDownload";
import WuDialog from "../../General/WuDialog";
import {accountLengthAdjust} from "../../../helper/generalFunctions";

export default function DailySpendData(props) {
    const {open, data} = {...props}


    return (
        <WuDialog open={open}
                  title={`${accountLengthAdjust(data.accountId)}-(${data.date})`}
                  titleNode={<ReportDownload data={data}/>}
                  onChange={(e) => props.onChange(e)}
                  size={'xl'}>
            <Box m={2} display={'flex'}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <ApplicationSpend data={data}/>
                    </Grid>
                    <Grid item xs={6}>
                        <ResourceSpend data={data}/>
                    </Grid>
                </Grid>
            </Box>
        </WuDialog>
    )
}