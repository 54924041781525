import './index.scss'
import Search from "./Search";
import Upload from "./Upload";
import {UnderDevelopmentBadge} from "../General";

export default function AutoTagging() {
    return (
        <div className={'auto-tagging-wrapper'}>
            <UnderDevelopmentBadge/>
            <Search/>
            <Upload/>
        </div>
    )
}