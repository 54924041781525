import React, {useEffect, useState} from "react";
import service from "../../../service/service";
import {costFormatterGeneral, jsonParser} from "../../../helper/generalFunctions";
import WuAccordionWrapper from "../../General/WuAccordion/WuAccordionWrapper";
import WuAccordionSummary from "../../General/WuAccordion/WuAccordionSummary";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import WuAccordionDetails from "../../General/WuAccordion/WuAccordionDetails";
import {ProgressCircleCustomSize} from "../../ProgressCircle/ProgressCircle";
import {AlertWu} from "../../AlertWu/AlertWu";
import CheckSummaryTable from "./CheckSummary/CheckSummaryTable";
import {Icon} from "./SummaryCard";
import {LocalOfferOutlined} from "@material-ui/icons";
import WuToolTip from "../../General/WuToolTip";

export default function RecommendationCheckDetails(props) {
    const {
        checkName,
        checkId,
        masterAccountId,
        EstimatedMonthlySaving = 0,
        filter
    } = {...props}

    const [loading, setLoading] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [ready, setReady] = useState(false);
    const [error, setError] = useState({error: false, message: undefined});
    const [tagSupport, setTagSupport] = useState(false);
    const [checkSummaryData, setCheckSummaryData] = useState([]);

    useEffect(() => {
        if ((checkId === 'Qch7DwouX1') || (checkId === 'Ti39halfu8') || (checkId === 'hjLMh88uM8') || (checkId === 'DAvU99Dc4C')) {
            setTagSupport(true)
        }
    }, [checkId])


    useEffect(() => {
        if ((expanded && checkSummaryData.length < 1)) {
            fetchData();
        }
    }, [expanded])

    useEffect(() => {
        setCheckSummaryData([])
    }, [filter])

    const fetchData = async () => {
        try {
            setReady(false);
            setLoading(true);
            setError({error: false, message: undefined})
            const params = {master_account_id: masterAccountId, check_id: checkId, filter: filter}
            const {data: {data}} = await service.recommendations().getTrustedAdvisorCheckSummary(params);
            setCheckSummaryData(data);
            setReady(true)
        } catch (e) {
            const errorBody = jsonParser(e.request.response);
            setError({error: true, message: errorBody ? errorBody.error : e.message})
        } finally {
            setLoading(false)
        }
    }

    return (
        <WuAccordionWrapper >
            <WuAccordionSummary onClick={() => setExpanded(!expanded)}>
                <Box display={'flex'} width={'100%'}>
                    <Box style={{flexBasis: '50%'}} display={'flex'} className={'gap'} alignItems={'center'}
                         alignContent={'center'}>
                        {EstimatedMonthlySaving === 0 ? <Icon status={'READY'}/> : <Icon status={'UPDATING'}/>}
                        <Typography>
                            {checkName}
                        </Typography>
                        {
                            tagSupport &&
                            <WuToolTip title={'Attached Tags will be listed'}>
                                <LocalOfferOutlined style={{marginBottom: -4, color: 'rgba(0,0,0,0.41)'}}/>
                            </WuToolTip>
                        }
                    </Box>

                    <Typography>
                        {`$${costFormatterGeneral(EstimatedMonthlySaving)}`}
                    </Typography>
                </Box>
            </WuAccordionSummary>
            <WuAccordionDetails>
                <Box className={'w-100'}>
                    {loading && <Loading checkName={checkName}/>}
                    {(ready && !loading) &&
                    <CheckSummaryTable data={checkSummaryData}
                                       checkId={checkId}
                                       checkName={checkName}
                                       filter={filter}/>}
                    <AlertWu alertOpen={{state: error.error}} title={"Error"} severity={'error'}
                             message={error.message}/>
                </Box>
            </WuAccordionDetails>
        </WuAccordionWrapper>
    )
}

function Loading({checkName}) {
    return (
        <Box display={'flex'} alignItems={'center'} gridGap={20} m={2} mt={2} mb={2}>
            <ProgressCircleCustomSize size={16}/>
            <Typography>Loading recommendation for {checkName}...</Typography>
        </Box>
    )
}