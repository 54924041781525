import {useState} from "react";
import {AuthorizationContext, OrganizationContext} from '../context'
import {SnackbarProvider} from "notistack";
import {PermissionProvider} from './PermissionProvider'

export default function GlobalProvider({children}) {

    const [user, setUser] = useState({
        userInfo: null,
        authenticated: false
    });
    const [organization, setOrganization] = useState("no org");

    return (
        <AuthorizationContext.Provider value={[user, setUser]}>
            <PermissionProvider>
                <SnackbarProvider maxSnack={5}>
                    <OrganizationContext.Provider value={{organization, setOrganization}}>
                        {children}
                    </OrganizationContext.Provider>
                </SnackbarProvider>
            </PermissionProvider>
        </AuthorizationContext.Provider>
    )
}