import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    ClickAwayListener,
    Divider, FormControlLabel,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper, Radio,
    RadioGroup,
    Typography
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import WuSummaryCard from "../../General/WuSummaryCard";
import WuButton from "../../General/WuButton";
import SummaryText from "../../General/CronSummaryCard/SummaryText";
import {useOrganization} from "../../../hooks/useOrganization";
import service from "../../../service/service";
import {apiErrorResponseJsonParser} from "../../../helper/generalFunctions";
import Icon from "../../General/CronSummaryCard/StatusIcon";
import WuSnackBar from "../../General/WuSnckBar";
import FilterContext from "./FilterContext";
import {SyncDisabledRounded, SyncRounded} from '@material-ui/icons'


export default function Summary() {
    const {organization: masterAccountId} = useOrganization()
    const [apiProgress, setApiProgress] = useState({ready: false, loading: false, error: ''})
    const [onDemandRefreshProgress, setOnDemandRefreshProgress] = useState({message: '', loading: false, error: ''})
    const [rangeText, setRangeText] = useState('noSync');
    const [responseData, setResponseData] = useState([])
    const {filter} = useContext(FilterContext)

    const fetchSummaryData = async () => {
        try {
            if (masterAccountId !== 'no org') {
                setApiProgress({ready: false, loading: true, error: ''})
                const params = {master_account_id: masterAccountId, filter: filter}
                const {data: {data}} = await service.governance().getSubnetOverAllSummary(params)
                if (data['status'] === 'UPDATING') setTimeout(() => {
                    fetchSummaryData().then()
                }, 10000)

                setResponseData(data)
                setApiProgress({ready: true, loading: false, error: ''})
            }
        } catch (e) {
            let errorObj = apiErrorResponseJsonParser(e)
            setApiProgress({ready: false, loading: false, error: errorObj})
        }
    }

    const onDemandRefresh = async (e) => {
        try {
            if (masterAccountId !== 'no org') {
                setOnDemandRefreshProgress({message: '', loading: true, error: ''})
                const {data: {data}} = await service.governance().runSubnetOnDemandCron({master_account_id: masterAccountId})
                setOnDemandRefreshProgress({message: data, loading: false, error: ''})
                fetchSummaryData().then()
            }
        } catch (e) {
            let errorObj = apiErrorResponseJsonParser(e)
            setOnDemandRefreshProgress({message: errorObj, loading: false, error: errorObj})
        }
    }


    useEffect(() => {
        fetchSummaryData().then()
        rangeTextSwitcher()
    }, [filter, masterAccountId])

    const handleClose = () => {
        setOnDemandRefreshProgress({message: '', loading: false, error: ''})
    }


    const rangeTextSwitcher = () => {
        const {range_filter: rangeFilter} = {...filter}
        switch (rangeFilter) {
            case 'noSync':
                setRangeText('between 50 and 100')
                break
            case 'below20':
                setRangeText('between 0 and 20')
                break
            case 'below50':
                setRangeText('between 0 and 50')
                break
            case 'between50and100':
                setRangeText('between 50 and 100')
                break
            case 'between100and500':
                setRangeText('between 100 and 500')
                break
            case 'between500and1500':
                setRangeText('between 500 and 1500')
                break
            default:
                setRangeText('between 50 and 100')
                break
        }

    }


    return (
        <Paper variant={'outlined'} style={{maxWidth: 275}}>
            <WuSnackBar open={Boolean(onDemandRefreshProgress.message)}
                        onClose={handleClose}
                        severity={'info'}
                        message={onDemandRefreshProgress.message}/>
            <Box m={2} className={'gap'} display={'flex'} flexDirection={'column'}>
                <WuSummaryCard
                    borderBottom={true}
                    loading={apiProgress.loading}
                    borderBottomColor={"red"}
                    title={'IP exhaustion'}
                    description={'Available IPs less than 50'}
                    helpText={'available IPs less than 50'}
                    value={responseData?.ipExhaustedSubnetCount ? responseData.ipExhaustedSubnetCount : '⚠️'}/>
                <UpdateUiWithChangeInCardCount>
                    <WuSummaryCard
                        borderBottom={true}
                        loading={apiProgress.loading}
                        borderBottomColor={"orange"}
                        title={filter?.range_filter === 'noSync' ? 'Running out soon' : 'Total Subnet Count'}
                        description={`Subnets with available IP's ${rangeText}`}
                        helpText={`subnets with available IP's ${rangeText}`}
                        value={responseData?.ipRunningOutSoonSubnets ? responseData.ipRunningOutSoonSubnets : '⚠️'}/>
                </UpdateUiWithChangeInCardCount>
                <Divider/>
                <WuButton disabled={responseData?.status === 'UPDATING'} onClick={onDemandRefresh}>REFRESH</WuButton>
                <Divider/>
                <Box className={'gap'} display={'flex'}
                     flexDirection={'column'}
                     ml={1}>
                    <SummaryText title={'Refresh status'}
                                 value={responseData?.status ? responseData?.status : 'FAILED'}
                                 node={<Icon status={responseData?.status ? responseData?.status : 'ERROR'}/>}/>
                    <Divider/>
                    <SummaryText title={'Last updated At'}
                                 value={responseData?.lastRunTime ? responseData.lastRunTime : '⚠️'}/>
                </Box>
            </Box>
        </Paper>
    )
}


function UpdateUiWithChangeInCardCount({children}) {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const {filter, setFilter} = useContext(FilterContext)
    const {range_filter: syncValue} = {...filter}

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleSelectionChange = (e) => {
        setFilter({...filter, 'range_filter': e.target.value})
    }

    return (
        <React.Fragment>
            <div onClick={(e) => setOpen(true)} ref={anchorRef} style={{cursor: 'pointer', position: 'relative'}}>
                {children}
                <div className={'table-syc-status'}>
                    {
                        filter?.range_filter === 'noSync' ? <SyncDisabledRounded/> : <SyncRounded/>
                    }
                </div>
            </div>

            <Popper
                style={{zIndex: 400}}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                placement={'bottom-start'}
            >
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper style={{zIndex: 5000, width: '242px', border: '1px solid var(--border)'}}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <Box m={2}>
                                    <Typography component={'legend'} variant={'subtitle1'}>
                                        Synchronise tables with IP range selected
                                    </Typography>
                                    <RadioGroup
                                        value={syncValue}
                                        aria-label="gender"
                                        defaultValue="noSynchronisation"
                                        name="radio-buttons-group"
                                        onChange={handleSelectionChange}
                                    >
                                        <FormControlLabel value={"noSync"} control={<Radio/>} label="No Sync"/>
                                        <FormControlLabel value="below20"
                                                          control={<Radio style={{color: 'red'}}/>}
                                                          label="Below 20"/>
                                        <FormControlLabel value="below50"
                                                          control={<Radio style={{color: 'red'}}/>}
                                                          label="Below 50"/>
                                        <FormControlLabel value="between50and100"
                                                          control={<Radio style={{color: 'orange'}}/>}
                                                          label="Between 50 and 100"/>
                                        <FormControlLabel value="between100and500"
                                                          control={<Radio style={{color: 'orange'}}/>}
                                                          label="Between 100 and 500"/>
                                        <FormControlLabel value="between500and1500"
                                                          control={<Radio style={{color: 'green'}}/>}
                                                          label="Between 500 and 1500"/>
                                    </RadioGroup>
                                </Box>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    )
}