import React, {useEffect, useState} from "react";
import WuDialog from "../../General/WuDialog";
import DataGrid from "../../General/DataGrid/DataGrid";
import Box from "@material-ui/core/Box";
import service from "../../../service/service";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import {AlertWu} from "../../AlertWu/AlertWu";
import {accountLengthAdjust, jsonParser} from "../../../helper/generalFunctions";
import {
    CheckCircleOutlined,
} from "@material-ui/icons";
import PowerScheduleDelete from "./PowerScheduleDelete";
import PowerScheduleUpdate from "./PowerScheduleUpdate";
import {ProgressCircleCustomSize} from "../../ProgressCircle/ProgressCircle";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import WuToolTip from "../../General/WuToolTip";

export default function PowerScheduleApplicationInformation(props) {
    const {open, data, masterAccountId, admin, updateStatus} = {...props}
    const [update, setUpdate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [ready, setReady] = useState(false);
    const [error, setError] = useState({error: false, message: undefined});
    const [gridData, setGridData] = useState([]);
    const [updateOpen, setUpdateOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState([false]);

    const BtnCellRenderer = (props) => {
        const btnClickedHandler = (field) => {
            props.data['event'] = "update";
            props.clicked(props.data)
        }
        return (
            <IconButton onClick={btnClickedHandler} aria-label="info" size="medium" style={{color: "green"}}>
                <CheckCircleOutlined fontSize="inherit"/>
            </IconButton>
        )
    }
    const WarningCellRenderer = (props) => {
        const btnClickedHandler = (field) => {
            props.data['event'] = "create";
            props.clicked(props.data)
        }
        return (
            <IconButton onClick={btnClickedHandler} aria-label="info" size="medium" style={{color: "orange"}}>
                <ErrorOutlineOutlinedIcon fontSize="inherit"/>
            </IconButton>
        )
    }
    const CriticalError = (props) => {
        const btnClickedHandler = (field) => {
            props.data['event'] = "create";
            props.clicked(props.data)
        }
        return (
            <IconButton aria-label="info" onClick={btnClickedHandler} size="medium" style={{color: "red"}}>
                <WuToolTip title={'Tags are removed'}>
                    <ErrorOutlineOutlinedIcon fontSize="inherit"/>
                </WuToolTip>

            </IconButton>
        )
    }

    const columns = [
        {
            field: "AccountId",
            resizable: true,
            sortable: true,
            width: 200,
            suppressAutoSize: false,
            valueGetter: function ({data}) {
                return `${accountLengthAdjust(data.AccountId)} (${data.AccountAlias})`
            }
        },
        {
            field: "ResourceType",
            headerName: "Resource Type",
            resizable: true,
            sortable: true,
            width: 150,
            suppressAutoSize: true,
        },
        {
            field: "ApplicationName",
            resizable: true,
            sortable: true,
            width: 200,
            suppressAutoSize: true,
        },
        {
            field: "Environment",
            resizable: true,
            sortable: true,
            width: 200,
            suppressAutoSize: true
        },
        {
            field: "ResourceName",
            resizable: true,
            sortable: true,
            suppressAutoSize: false
        },
        {
            field: "ResourceId",
            resizable: true,
            sortable: true,
            suppressAutoSize: false
        },
        {
            field: "State",
            resizable: true,
            sortable: true,
            suppressAutoSize: false
        },
        {
            field: "TimeZone",
            resizable: true,
            sortable: true,
            width: 150,
            suppressAutoSize: false,
            valueFormatter: function ({data}) {
                return data.TimeZone ? data.TimeZone.toUpperCase() : "MST";
            }
        },
        {
            field: "StartTime",
            resizable: true,
            sortable: true,
            width: 150,
            valueFormatter: function ({data}) {
                let time = moment(data.StartTime, ["HH.mm"]).format("hh:mm A")
                return (time === 'Invalid date' ? 'Not Scheduled' : time)
            },
            suppressAutoSize: false,
            type: "numericColumn",

        },
        {
            field: "StopTime",
            resizable: true,
            sortable: true,
            width: 150,
            valueFormatter: function ({data}) {
                let time = moment(data.StopTime, ["HH.mm"]).format("hh:mm A")
                return (time === 'Invalid date' ? 'Not Scheduled' : time)
            },
            suppressAutoSize: true,
            type: "numericColumn",

        },
    ]
    if (admin) {
        columns.push({
            cellRendererSelector: function (params) {
                let infoComponent = {
                    component: 'informationCellRenderer'
                }
                let warningComponent = {
                    component: 'warningCellRenderer'
                }

                let errorComponent = {
                    component: 'errorCellRenderer'
                }

                if ((params.data.StopTime === '-' && params.data.ChangeValue !== '-')) {
                    return errorComponent
                }

                if ((params.data.StopTime === '-' || params.data.StopTime === null)) {
                    return warningComponent
                } else {
                    return infoComponent
                }
            },
            sortable: false,
            resizable: false,
            filter: false,
            width: 80,
            suppressAutoSize: false,
            cellRendererParams: {
                clicked: function (field) {
                    handlePowerScheduleChange(field)
                }
            },
        })
    }

    const handlePowerScheduleChange = (e) => {
        setSelectedItem(e);
        if (e.event === "update") {
            setUpdateOpen(true);
            return
        }
        props.onChange({event: "create", operation: true, data: e})
    }
    const handleUpdateSchedule = async (e) => {
        if (e === "update") {
            await getApplicationSummary();
            props.onChange("update");
            setUpdate(true);
            setReady(true);
        } else if (e === "close") {
            setUpdateOpen(false);
        }
    }
    const handleDeleteOpen = async (e) => {
        if (e === "update") {
            await getApplicationSummary();
            props.onChange("update");
            setReady(true);
            setUpdate(true);
            setDeleteOpen(false);
            setUpdateOpen(false);
        } else if (e === "close") {
            setDeleteOpen(false);
        }
    }
    useEffect(() => {
        if (open === true) {
            setReady(false);
            setUpdate(false);
            getApplicationSummary()
                .then(() => {
                    setReady(true)
                });
        }
    }, [open])
    useEffect(() => {
        if (gridData.length > 0) {
            const fetch = async () => {
                await getApplicationSummary();
                props.onChange("update");
                setReady(true);
            }
            fetch()
        }
    }, [updateStatus])
    const getApplicationSummary = async () => {
        try {
            setLoading(true);
            setReady(false);
            setGridData([])
            setError({error: false, message: undefined})
            const params = {application_name: data.ApplicationName, master_account_id: masterAccountId}
            const response = await service.automations().getPowerScheduleV2(params);
            setGridData(response.data.data);
            setLoading(false);
        } catch (e) {
            const errMessage = jsonParser(e.request.response)
            setError({error: true, message: errMessage ? errMessage.error : e.message})
        }
    }

    return (
        <WuDialog title={"Application Information"} open={open} onChange={(e) => props.onChange("close")} size={"xl"}>
            <PowerScheduleUpdate onChange={(e) => handleUpdateSchedule(e)} open={updateOpen} data={selectedItem}
                                 onClick={() => setDeleteOpen(true)}/>

            <PowerScheduleDelete onChange={(e) => handleDeleteOpen(e)} open={deleteOpen} data={selectedItem}/>
            <Box width={"100%"}>
                {
                    loading &&
                    <Box m={3}>
                        <ProgressCircleCustomSize size={16}/>
                    </Box>
                }
                {
                    ready &&
                    <DataGrid
                        update={update}
                        gridStyle={{height: "650px"}}
                        gridData={gridData}
                        fileName={'power_schedule_application'}
                        frameworkComponents={{
                            informationCellRenderer: BtnCellRenderer,
                            warningCellRenderer: WarningCellRenderer,
                            errorCellRenderer: CriticalError,
                        }}
                        fixedColumn={columns}/>
                }
            </Box>
            {
                error.error &&
                <Box m={3}>
                    <AlertWu alertOpen={{state: error.error}} severity={"error"} message={error.message}/>
                </Box>
            }
        </WuDialog>
    )
}