import React, {useEffect, useState} from "react";
import Pagination from "@material-ui/lab/Pagination";
import Box from "@material-ui/core/Box";
import Setting from "./Setting";
import IconButton from "@material-ui/core/IconButton";
import {SaveOutlined} from "@material-ui/icons";
import GlobalSearch from "./GlobalSearch";
import {Typography} from "@material-ui/core";

export default function DataControlPanel({gridApi, totalPages, currentPage}) {

    const gotoPageNumber = (pageNumber) => {
        gridApi.paginationGoToPage(pageNumber - 1);
    }

    const exportData = () => {
        let params = {
            fileName: `${new Date().toISOString()}.csv`,
        };
        gridApi.exportDataAsCsv(params)
    }

    return (
        <div className="data-grid-adjustments">
            <div className="d-f">
                <GlobalSearch
                    onChange={(e) => {
                        gridApi.setQuickFilter(e);
                    }}/>

            </div>
            <div className="d-f grid-properties">
                <Typography style={{minWidth: 50}}>Total: {gridApi?.paginationProxy?.masterRowCount}</Typography>
                {totalPages && <Pagination
                    style={{flexWrap: "nowrap"}}
                    onChange={(e, value) => gotoPageNumber(value)}
                    count={totalPages}
                    page={currentPage}/>}
                <Box display={'flex'}>
                    {/* <Setting columnDefinition={gridColumnApi} gridApi={gridApi}/>*/}
                    <IconButton onClick={exportData} aria-label="delete" size="medium">
                        <SaveOutlined fontSize="inherit"/>
                    </IconButton>
                </Box>
            </div>
        </div>
    )
}