import React from "react";
import {NavLink} from "react-router-dom";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import {CategoryOutlined} from "@material-ui/icons";


export default function AccountSettingNavigation() {
    const styleIcon = {paddingRight: 10}
    return (
        <nav>
            <ul>
                <li>
                    <NavLink to="/settings/account/listing">
                        <AccountTreeOutlinedIcon style={styleIcon}/>
                        Active Accounts
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/settings/account/category">
                        <CategoryOutlined style={styleIcon}/>
                        Account Category
                    </NavLink>
                </li>
            </ul>
        </nav>
    )
}