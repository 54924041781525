import {useEffect, useState} from "react";
import {columns} from './gridProperties'
import {Box} from "@material-ui/core";
import DataGrid from "../../General/DataGrid";
import {apiErrorResponseJsonParser} from "../../../helper/generalFunctions";
import {useEcs} from "../../../hooks";
import WuLoading from "../../General/WuLoading";
import WuAlert from "../../General/WuAlert";
import service from "../../../service/service";
import {ecsClusterAccountWise} from "../../../assets/finthesys/ecs";

export default function Clusters(props) {
    const {accountId, open} = {...props}
    const {setEcs} = useEcs()
    const [apiProgress, setApiProgress] = useState({loading: false, ready: false, error: false})
    const [clusterData, setClusterData] = useState({})


    const fetchData = async () => {
        try {
            setApiProgress({loading: true, ready: false, error: false})
            const params = {
                accountId: accountId
            }
            const {data: {data}} = await service.ecs().listEcsCluster(params)
            setClusterData(data)
            setApiProgress({loading: false, ready: true, error: false})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setApiProgress({loading: false, ready: false, error: error})
        }
    }

    const fetchDummyData = async () => {
        try {
            setApiProgress({loading: true, ready: false, error: false})
            const params = {
                accountId: accountId
            }
            const {data} = ecsClusterAccountWise
            setClusterData(data)
            setApiProgress({loading: false, ready: true, error: false})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setApiProgress({loading: false, ready: false, error: error})
        }
    }

    useEffect(() => {
        fetchDummyData().then()
        setEcs(current => ({...current, filter: null}))
    }, [accountId, open])


    return (
        <div className={'ecs-content-wrapper '}>
            <Box m={apiProgress.loading ? 1 : 0}>
                <WuLoading message={'cluster information loading. Please wait...'} open={apiProgress.loading}/>
            </Box>
            <WuAlert error={Boolean(apiProgress.error)} success={false} errorMessage={apiProgress.error}/>
            {apiProgress.ready && <DataGrid
                gridStyle={{height: clusterData.length > 5 ? "250px" : clusterData.length === 1 ? "90px" : clusterData.length * 65 + "px"}}
                gridData={clusterData}
                fixedColumn={columns}
            />}
        </div>
    )
}
