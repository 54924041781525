import React, {useEffect, useState} from "react";
import ReactApexChart from "react-apexcharts";


export function SpendGraph({graphData}) {
    const [ready, setReady] = useState(false);
    const [graphState, setGraphState] = useState({});

    let state = {
        options: {
            chart: {
                type: 'bar',
                height: 350,
                fontFamily: 'Roboto',
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '50%',
                    borderRadius: 4,
                    endingShape: 'rounded',
                    dataLabels: {
                        position: 'top'
                    },
                },
            },
            title: {
                text: 'Spend Across Organizations',
                align: 'left',
                offsetX: 14,
                style: {
                    fontSize: '16px',
                    fontWeight: 'medium',
                    color: '#263238'
                },
            },
            dataLabels: {
                enabled: true,
                textAnchor: 'middle',
                style: {
                    fontSize: '12px',
                    fontWeight: 'medium',
                    colors: ['#000'],
                },
                background: {
                    enabled: false,
                    foreColor: '#000',
                },
                formatter: function (val, opt) {
                    return "$" + Math.round(val)
                },
                offsetX: 0,
                offsetY: -20,
                dropShadow: {
                    enabled: false
                }
            },
            legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                offsetY: 10,
                itemMargin: {
                    horizontal: 10,
                    vertical: 10
                },
                onItemHover: {
                    highlightDataSeries: true
                },
            },
            stroke: {
                show: true,
                width: 4,
                colors: ['transparent']
            },
            xaxis: {
                categories: [],
            },
            yaxis: {
                title: {
                    text: 'Spend ($)',
                    style: {
                        fontSize: '14px',
                        fontWeight: 'medium',
                        color: '#263238'
                    },
                },
                labels: {
                    show: true,
                    formatter: function (val, opt) {
                        return "$" + Math.round(val)
                    },
                }
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return "$ " + val
                    }
                }
            }
        },
    }

    useEffect(() => {
        let xAxis = []
        let yAxis = []
        graphData.forEach((graph) => {
            yAxis.push({
                name: graph.organizationAlias ? graph.organizationAlias : graph.organizationName,
                data: graph.graphData.yAxis
            })
            xAxis = graph.graphData.xAxis
        })
        state['series'] = yAxis;
        state.options.xaxis.categories = xAxis;
        setGraphState(state);
        setReady(true)
    }, [graphData])

    return (
        <React.Fragment>
            {ready &&
            <ReactApexChart options={graphState.options} series={graphState.series} type={'bar'} height={'390'}/>}
        </React.Fragment>
    )
}