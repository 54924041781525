import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {Divider, IconButton, Typography, Box} from "@material-ui/core";
import {KeyboardArrowRight} from "@material-ui/icons";
import DetailsAccountWise from "./DetailsAccountWise";
import {apiErrorResponseJsonParser} from "../../../../helper/generalFunctions";
import {useOrganization} from "../../../../hooks/useOrganization";
import WuLoading from "../../../General/WuLoading";
import WuAlert from "../../../General/WuAlert";
import {NoData} from "../../../General";
import service from "../../../../service/service";
import {useQuery} from "../../../../hooks";
import WuButton from "../../../General/WuButton";
import {tagCompliancePartialOrganization} from "../../../../assets/finthesys/tag-compliance";

export default function DetailsOrganizationWise({onNavigationChange}) {
    const history = useHistory()
    const urlParams = useQuery()
    const accountIdPresent = urlParams.get('accountId')
    const {organization: masterAccountId} = useOrganization()
    const [openAccountDetails, setAccountDetails] = useState(null)
    const [progress, setProgress] = useState({loading: false, ready: false, error: false})
    const [apiResponse, setApiResponse] = useState([])

    const fetchData = async () => {
        try {
            setProgress({loading: true, ready: false, error: false})
            const params = {
                masterAccountId: masterAccountId
            }
            const {data: {data}} = await service.monitoring().getTagComplianceOrganizationWise(params)
            setApiResponse(data)
            setProgress({loading: false, ready: true, error: false})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setProgress({loading: false, ready: true, error: error})
        }
    }

    const fetchDummyData = async () => {
        try {
            setProgress({loading: true, ready: false, error: false})
            const params = {
                masterAccountId: masterAccountId
            }
            const {data} = tagCompliancePartialOrganization
            setApiResponse(data)
            setProgress({loading: false, ready: true, error: false})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setProgress({loading: false, ready: true, error: error})
        }
    }

    useEffect(() => {
        if (masterAccountId !== 'no org') {
            fetchDummyData().then()
        }
    }, [masterAccountId])

    const handleClick = (accountId) => {
        history.push(`?accountId=${btoa(accountId)}`)
        onNavigationChange(current => [...current, <Typography variant={'body2'} key={'4'}>{accountId}</Typography>])
        setAccountDetails(accountId)
    }

    useEffect(() => {
        if (!accountIdPresent && openAccountDetails) {
            setAccountDetails(null)
            onNavigationChange(current => current.slice(0, -1))
        }
    }, [accountIdPresent])


    return (
        <div className={'details-organization-wise-wrapper-container'}>
            {Boolean(openAccountDetails) && <DetailsAccountWise accountId={openAccountDetails}/>}
            <div className={`details-organization-wise-wrapper ${openAccountDetails ? ' hide' : ' show'}`}>
                <div style={{marginBottom: 24}}>
                    <Typography variant={'h6'}> Resources </Typography>
                    <Typography variant={'body2'}> Resources with partial tags/wrong tag values </Typography>
                </div>
                <Title/>
                <Divider/>
                {
                    apiResponse.map(e => <SummaryWrapper {...e} key={e.accountId} handleClick={handleClick}/>)
                }
                {
                    progress.loading &&
                    <WuLoading message={'Please wait...'} open={progress.loading} boxProps={{my: 2}}/>
                }

                {
                    apiResponse.length < 1 && <Box mt={8}><NoData justifyContent={'flex-start'}/></Box>
                }
                <WuAlert error={Boolean(progress.error)} errorMessage={progress.error} success={false}/>
            </div>
        </div>
    )
}


function SummaryWrapper({accountId, accountAlias, resourceNumber, handleClick}) {
    const [progress, setProgress] = useState({loading: false, ready: false, error: false})
    const [apiResponse, setApiResponse] = useState([])

    const handleFixResources = async (e) => {
        try {
            e.stopPropagation()
            setProgress({loading: true, ready: false, error: false})
            const params = {
                accountId: accountId
            }
            const {data: {data}} = await service.monitoring().tagResourceAccountWise(params)
            setApiResponse(data)
            setProgress({loading: false, ready: true, error: false})
        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setProgress({loading: false, ready: true, error: error})
        }
    }

    return (
        <div className={'summary-wid-wrapper'} onClick={() => handleClick(accountId)}>
            <div className={'details-org-container'}>
                <Typography style={{flexBasis: '30%'}}>
                    {accountId || 'unknown'}
                    <Typography color={'textSecondary'} component={'span'}>
                        &nbsp;({accountAlias || 'unknown'})
                    </Typography>
                </Typography>
                <Typography style={{flex: 1}}>
                    {resourceNumber}
                </Typography>
                <WuButton style={{marginRight: 16, width: 150, height: 36}} onClick={(e) => handleFixResources(e)}
                          disabled={progress.loading}>
                    {progress.loading ? <WuLoading open={true}/> : 'FIX POSSIBLE'}
                </WuButton>
                <IconButton size={'small'}>
                    <KeyboardArrowRight/>
                </IconButton>
            </div>
        </div>
    )
}

function Title() {
    return (
        <div className={'summary-wid-title'}>
            <Typography style={{flexBasis: '30%'}} color={'textSecondary'} variant={'body2'}>
                Account
            </Typography>
            <Typography style={{flexBasis: '45%'}} color={'textSecondary'} variant={'body2'}>
                Number Of Resources
            </Typography>
            <Typography/>
        </div>
    )
}


