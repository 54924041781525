import React, {useEffect, useState} from "react";
import NotificationSingleItem from "./NotificationSingleItem";
import {Badge, Collapse} from "@material-ui/core";
import WuStack from "../General/WuStack";
import {notifications} from "./notifications";
import noNotification from '../../assets/images/noNotification.png'
import './index.scss'
import {ClearAllRounded} from "@material-ui/icons";
import WuButton from "../General/WuButton";

export default function Notification({onClick}) {
    const [activeTab, setActiveTab] = useState('FINOPS');
    const [activeNotifications, setActiveNotifications] = useState([])


    useEffect(() => {
        const fn = notifications.filter(({category}) => category.toUpperCase() === activeTab.toUpperCase())
        setActiveNotifications(fn)
    }, [activeTab])

    return (
        <div>
            <NotificationTab activeTab={activeTab} onTabChange={setActiveTab}/>
            {
                activeNotifications.map(notification => <NotificationSingleItem
                    onClick={onClick}
                    key={notification.id} {...notification}/>)
            }
            <NoNotifications open={activeNotifications.length < 1}/>
            <WuButton style={{marginTop: 8, marginBottom: 2, marginLeft: 16}}
                      color={'secondary'}
                      size={'small'}
                      variant={'text'}>
                View All
            </WuButton>
        </div>
    )
}


function NotificationTab({activeTab, onTabChange}) {
    const handleTabChange = (e) => {
        onTabChange(e)
    }

    return (
        <div className={'notification-tab-sticky'}>
            <WuStack spacing={1}>
                <Badge variant={'dot'} badgeContent={2} color={'secondary'}>
                    <p className={activeTab === 'FINOPS' ? 'active-tab' : ''}
                       onClick={() => handleTabChange('FINOPS')}>
                        Finops
                    </p>
                </Badge>
                <p className={activeTab === 'DIRECT' ? 'active-tab' : ''}
                   onClick={() => handleTabChange('DIRECT')}>Direct </p>
            </WuStack>
            <ClearAllRounded/>
        </div>
    )
}


function NoNotifications({open}) {
    return (
        <Collapse in={open}>
            <div className={'no-notification'}>
                <p className={'no-notification-text'}>
                    No notifications
                </p>
                <WuStack alignItems={'center'} justifyContent={'center'}>
                    <img alt={'no notification'} src={noNotification}/>
                </WuStack>

            </div>
        </Collapse>
    )
}