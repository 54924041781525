import React, {useState} from 'react';
import {
    Checkbox,
    Divider,
    FormControlLabel, FormGroup,
    Typography
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {ProgressCircleCustomSize} from "../../ProgressCircle/ProgressCircle";
import {useOrganization} from "../../../hooks/useOrganization";
import {AccountMultiSelect} from "../../General/WuAutoComplete";
import FormControl from "@material-ui/core/FormControl";
import WuButton from "../../General/WuButton";
import useForm from "../../../hooks/useForm";
import service from "../../../service/service";
import fileExportElement from "../../../helper/fileExportElement";
import WuFloatingDownloadButtonV2 from "../../General/WuFloatingDownloadButtonV2";
import WuSwitch from "../../General/WuSwitch";
import WuStack from "../../General/WuStack";
import WeekendScheduleReport from "./WeekendScheduleReport";

export default function DownloadReport() {

    const {organization: masterAccountId} = useOrganization();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const [downloadProps, setDownloadProps] = useForm({accountIds: [], DAILY: false, WEEKEND: false, NOT: false})
    const handleAccountChange = (e, value) => {
        setDownloadProps('accountIds', value.map(({AccountId}) => AccountId))
    }

    const handleReportTypeChange = (e) => {
        setDownloadProps(e.target.name, e.target.checked)
    }


    const handleDownload = async () => {
        try {
            setError(false);
            setLoading(true)
            const params = {
                account_list: downloadProps.accountIds,
                master_account_id: masterAccountId,
                report_include: Object.keys(downloadProps).filter(item => downloadProps[item] === true)
            }
            const response = await service.automations().downloadPowerScheduleReport(params)
            fileExportElement(response)
            setLoading(false)
        } catch (e) {
            setError(e.message)
            setLoading(false)
        }
    }

    const buttonDisabled = Object.keys(downloadProps).filter(item => downloadProps[item] === true).length < 1 || downloadProps.accountIds.length < 1

    return (
        <Box className={'power-scheduled-wrapper'}>
            <WuFloatingDownloadButtonV2 downloadText={'DOWNLOAD'} error={error} loading={loading}>
                <Box>
                    <FormControl component="fieldset" fullWidth>
                        <Typography variant={'h6'} style={{marginBottom: 8, marginTop: -8}}>
                            Power schedule report
                        </Typography>
                        <Divider style={{marginBottom: 16}}/>
                        <AccountMultiSelect onChange={handleAccountChange}/>
                        <Typography style={{marginTop: 16}}> Power schedule type</Typography>
                        <FormGroup row>
                            <FormControlLabel
                                control={<Checkbox checked={downloadProps.WEEKEND} name="WEEKEND"
                                                   onChange={handleReportTypeChange}/>}
                                label="WEEKEND"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={downloadProps.DAILY} name="DAILY"
                                                   onChange={handleReportTypeChange}/>}
                                label="DAILY"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={downloadProps.NOT} name="NOT"
                                                   color={'primary'}
                                                   onChange={handleReportTypeChange}/>}
                                label="NOT"
                            />
                        </FormGroup>
                    </FormControl>
                    <Box mt={2} display={'flex'} gridGap={16}>
                        <WuButton style={{width: 100, height: 36}}
                                  onClick={handleDownload}
                                  disabled={loading || buttonDisabled}>
                            {loading ? <ProgressCircleCustomSize size={16}/> :
                                'Download'}
                        </WuButton>
                    </Box>
                </Box>
            </WuFloatingDownloadButtonV2>
        </Box>
    )
}