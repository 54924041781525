import React, {useEffect, useState} from 'react';
import {ComputeRecommendationCard} from "./ComputeRecommendationCard";
import ComputeRecommendationTrend from "./ComputeRecommendationTrend";
import {useOrganization} from "../../../../hooks/useOrganization";
import service from "../../../../service/service";

export default function ComputeRecommendation() {
    const {organization: masterAccountId} = useOrganization()
    const [data, setData] = useState({});
    const [ready, setReady] = useState(false);
    const fetchData = async () => {
        try {
            if (masterAccountId !== 'no org') {
                setReady(false)
                const {data: {data: result}} = await service.recommendations().getComputeOptimizerSummary({master_account_id: masterAccountId});
                setData(result)
                setReady(true)
            }

        } catch (e) {

        }
    }

    useEffect(() => {
        fetchData()
    }, [masterAccountId])


    return (
        <div>
            <div className="c-r-o">
                <ComputeRecommendationCard
                    numberOfInstanceOptimized={ready ? data?.Instance?.count : 'loading'}
                    totalSavings={`$${ready ? data?.Instance?.savings : 'loading'}`}
                    title="EC2 instance"
                    routeLink={"/recommendations/compute-optimizer/type/compute"}
                />
                <ComputeRecommendationCard
                    numberOfInstanceOptimized={ready ? data?.AutoScalingGroup?.count : 'loading'}
                    totalSavings={`$${ready ? data?.AutoScalingGroup?.savings : 'loading'}`}
                    title="Auto Scaling Group"
                    routeLink={"/recommendations/compute-optimizer/type/auto-scaling-group"}
                />
                <ComputeRecommendationCard
                    numberOfInstanceOptimized={"upcoming feature"}
                    totalSavings={"upcoming feature"}
                    title="EBS Volumes"
                    routeLink={"/recommendations/compute-optimizer/type/ebs"}
                />
                <ComputeRecommendationTrend/>
            </div>
        </div>
    )
}