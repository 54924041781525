import React, {useEffect, useState} from "react";
import Box from "@material-ui/core/Box";
import {Collapse} from "@material-ui/core";
import service from "../../../service/service";
import MostSpendAccount from "./MostSpendAccount";
import MostSpendService from "./MostSpendService";
import {convertArrayOfOrganizationToArrayOfAccountList} from "../utilities";

// dummy data
import costAndUSageMostSpend from '../../../assets/finthesys/dashboard-cost-and-usage-most-spend.json';

export default function MostSpend({organizationList}) {

    const [loading, setLoading] = useState(false);
    const [ready, setReady] = useState(false);
    const [error, setError] = useState({error: false, message: undefined})
    const [accountSpend, setAccountSpend] = useState([])
    const [serviceSpend, setServiceSpend] = useState([])

    useEffect(() => {
        //fetchData()
        fetchDummyData()
    }, []);


    const fetchData = () => {
        setLoading(true);
        setError({error: false, message: undefined})
        setReady(false);
        const params = {organization_ids: convertArrayOfOrganizationToArrayOfAccountList(organizationList)}
        service.dashboard().mostUsedInformation(params)
            .then((res) => {
                setAccountSpend(res.data.data.account);
                setServiceSpend(res.data.data.service);
                setReady(true)
                setLoading(false);
            })
            .catch(err => {
                setError({
                    error: true,
                    message: err.message
                })
                setLoading(false);
            })
    }

    const fetchDummyData = () => {
        setLoading(true);
        setError({error: false, message: undefined})
        setReady(false);

        setTimeout(() => {
            setAccountSpend(costAndUSageMostSpend.data.account);
            setServiceSpend(costAndUSageMostSpend.data.service);
            setReady(true)
            setLoading(false);
        }, 1000)
    }

    return (
        <Collapse in={ready} timeout={2000}>
            {
                ready &&
                <Box>
                    <MostSpendAccount accountSpend={accountSpend}/>
                    <MostSpendService serviceSpend={serviceSpend}/>
                </Box>
            }
        </Collapse>
    )
}

