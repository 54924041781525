import React, {useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import {AccountMultiSelect} from "../../../General/WuAutoComplete";
import {Grid} from "@material-ui/core";
import Box from "@material-ui/core/Box";


export default function ApplicationData(props) {

    const {applicationName, appCode, costCode, businessGroup, accounts, workStreamName} = props.applicationData;
    const [applicationData, setApplicationData] = useState(
        {
            applicationName: applicationName,
            appCode: appCode,
            costCode: costCode,
            businessGroup: businessGroup,
            accounts: accounts,
            workStreamName: workStreamName
        }
    )

    const handleChange = (event) => {
        setApplicationData({...applicationData, [event.target.name]: event.target.value});
    };

    const accountChange=(_, value)=>{
        setApplicationData({...applicationData, accounts: value});
    }

    useEffect(() => {
        props.onChange(applicationData)
    }, [applicationData])

    return (
        <form className={"onboard-form"} noValidate={false} autoComplete="on">
            <Typography variant={"subtitle2"}>Application Information is going to the core data for
                finops data management. Please make sure that all data is correct and validated.
                <br/>This is further used for automated tag population, tag policy update, budget creation and etc...
            </Typography>
            <div className={"d-f"}>
                <Grid container spacing={2}>
                    <Grid item xs={5}>
                        <TextField id="application_name"
                                   name={"applicationName"}
                                   onChange={handleChange}
                                   fullWidth
                                   value={applicationData.applicationName}
                                   label="Application Name"
                                   margin="dense"
                                   variant="outlined"/>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField id="app_code"
                                   fullWidth
                                   label="Application Code"
                                   name={"appCode"}
                                   onChange={handleChange}
                                   value={applicationData.appCode}
                                   margin="dense"
                                   variant="outlined"/>
                    </Grid>
                    <Grid item xs={5}>
                        <Box mt={1}>
                            <AccountMultiSelect onChange={accountChange} value={applicationData.accounts}/>
                        </Box>
                    </Grid>
                </Grid>
            </div>
            <div className={"d-f"}>
                <TextField id="cost_code"
                           label="Cost Center"
                           name={"costCode"}
                           onChange={handleChange}
                           fullWidth
                           value={applicationData.costCode}
                           margin="dense"
                           variant="outlined"/>
                <TextField id="business_group"
                           name={"businessGroup"}
                           onChange={handleChange}
                           fullWidth
                           value={applicationData.businessGroup}
                           label="Business Group"
                           margin="dense"
                           variant="outlined"/>
{/*                <TextField id="work_stream_name"
                           name={"workStreamName"}
                           onChange={handleChange}
                           fullWidth
                           value={applicationData.workStreamName}
                           label="Work Stream Name"
                           margin="dense"
                           variant="outlined"/>*/}
            </div>
        </form>
    )
}