import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import React from "react";

export default function SummaryText({title, value, node}) {
    return (
        <Box>
            <Typography variant={'subtitle2'}> {title}</Typography>
            <Box display={'flex'} gridGap={8} alignItems={'center'}>
                <Typography variant={'subtitle1'}> {value}</Typography>
                {node}
            </Box>
        </Box>
    )
}