import React, {useContext, useState} from 'react'
import {Collapse, FormControlLabel, Paper, Radio, RadioGroup, Typography} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import {KeyboardArrowDown, KeyboardArrowUp} from "@material-ui/icons";
import WuStack from "../../General/WuStack";
import WuButton from "../../General/WuButton";
import FilterContext from "../FilterContext";

export default function Filter({onFilterChange}) {
    const [moreOption, setMoreOption] = useState(false);
    const {filter, setFilter} = useContext(FilterContext)
    const handleMoreOption = () => {
        setMoreOption(current => !current)
    }

    const handleFilterChange = (e) => {
        setFilter(e.target.value)
    }

    return (
        <Paper variant={'outlined'} style={{padding: 9, paddingLeft: 16, paddingRight: 16}} className={'w-100'}>
            <FormControl component="fieldset">
                <FormLabel component="legend">Quick Filters</FormLabel>
                <RadioGroup
                    onChange={handleFilterChange}
                    value={filter}
                    aria-label="filter"
                >
                    <FormControlLabel value="above0" control={<Radio/>} label="Above 0% increment"/>
                    <FormControlLabel value="above20" control={<Radio/>} label="Above 20% increment"/>
                    <FormControlLabel value="above50" control={<Radio/>} label="Above 50% increment"/>
                    <FormControlLabel value="above100" control={<Radio/>} label="Above 100% increment"/>
                </RadioGroup>
            </FormControl>
            <Collapse in={moreOption}>
                <WuStack boxProps={{mt: 1, mb: 1}} direction={'column'}>
                    <Paper style={{padding: 8}} variant={'outlined'}>
                        <Typography>
                            Advanced options are not available at this moment. Please use legends more filters
                        </Typography>
                    </Paper>
                </WuStack>
            </Collapse>
            <WuStack spacing={1} boxProps={{mt: 1}}>
                <WuButton variant={'text'}
                          endIcon={moreOption ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                          color={'secondary'}
                          size={'small'}
                          style={{width: 50}}
                          onClick={handleMoreOption}>
                    {moreOption ? 'Less' : 'More'}
                </WuButton>
                <WuButton variant={'outlined'}
                          color={'secondary'}
                          size={'small'}
                          onClick={()=>setFilter(null)}>
                    Clear Filter
                </WuButton>
            </WuStack>
        </Paper>
    )
}