import {useHistory} from 'react-router-dom'
import useEcs from "../../hooks/useEcs";
import {accountLengthAdjust} from "../../helper/generalFunctions";

export const StatusComponent = ({value = ""}) => {
    const activeStatus = ['RUNNING', 'ACTIVE']
    if (activeStatus.includes(value.toUpperCase())) return <div className={'cluster-status'}>
        <div className={'active-dot'}/>
        {value}
    </div>
    else return <div className={'cluster-status'}>
        <div className={'active-dot'} style={{background: 'red'}}/>
        {value}
    </div>
}

export const DetailsPageCluster = ({value, data}) => {
    const {setEcs} = useEcs()
    const history = useHistory()

    const handleClick = () => {
        const apiParams = {
            accountId: accountLengthAdjust(data.accountId),
            accountAlias: data.accountAlias,
            region: data.region,
            clusterArn: data.clusterArn,
            identifier: data.clusterArn
        }
        setEcs(current => {
            return {...current, detailsParam: apiParams}
        })
        history.push('/ecs/cluster')
    }

    return <div className={'ecs-link'} onClick={handleClick}>{value}</div>
}

export const DetailsPageService = ({value, data}) => {
    const {setEcs} = useEcs()
    const history = useHistory()

    const handleClick = () => {
        const apiParams = {
            accountId: accountLengthAdjust(data.accountId),
            accountAlias: data.accountAlias,
            region: data.region,
            serviceArn: data.serviceArn,
            clusterArn: data.clusterArn,
            identifier: data.serviceArn
        }
        setEcs(current => {
            return {...current, detailsParam: apiParams}
        })
        history.push('/ecs/service')
    }

    return <div className={'ecs-link'} onClick={handleClick}>{value}</div>
}

export const DetailsPageTask = ({value, data}) => {
    const {setEcs} = useEcs()
    const history = useHistory()

    const handleClick = () => {
        const apiParams = {
            accountId: accountLengthAdjust(data.accountId),
            accountAlias: data.accountAlias,
            region: data.region,
            taskArn: data.taskArn,
            clusterArn: data.clusterArn,
            identifier: data.taskArn
        }
        setEcs(current => {
            return {...current, detailsParam: apiParams}
        })
        history.push('/ecs/task')
    }

    return <div className={'ecs-link'} onClick={handleClick}>{value}</div>
}

export const DetailsPageInstance = ({value, data}) => {
    const {setEcs} = useEcs()
    const history = useHistory()

    const handleClick = () => {
        const apiParams = {
            accountId: accountLengthAdjust(data.accountId),
            accountAlias: data.accountAlias,
            region: data.region,
            containerInstanceArn: data.containerInstanceArn,
            clusterArn: data.clusterArn,
            identifier: data.containerInstanceArn
        }
        setEcs(current => {
            return {...current, detailsParam: apiParams}
        })
        history.push('/ecs/instance')
    }

    return <div className={'ecs-link'} onClick={handleClick}>{value}</div>
}

export const CpuUsage = ({data}) => {
    return (
        <div className={'container-usage-container'}>
            <div className={'container-usage-wrapper usage-wrapper-cpu'}>
                <div className={'container-usage-progress progress-cpu'}
                     style={{width: `${Number(data.cpuAverage)}%`}}/>
                <p className={'container-progress-text'}>{Number(data?.cpuAverage || 0).toFixed(2)}%</p>
            </div>
        </div>
    )
}

export const MemoryUsage = ({data}) => {
    return (
        <div className={'container-usage-container'}>
            <div className={'container-usage-wrapper usage-wrapper-memory'}>
                <div className={'container-usage-progress progress-memory'}
                     style={{width: `${Number(data.memoryAverage)}%`}}/>
                <p className={'container-progress-text'}>{Number(data?.memoryAverage || 0).toFixed(2)}%</p>
            </div>
        </div>
    )
}


export const LaunchType = ({value = ""}) => {
    if (value.toUpperCase() === 'EC2') {
        return (
            <div className={'container-launch-type ec2-launch-type'}>
                {value}
            </div>
        )
    }
    return (
        <div className={'container-launch-type'}>
            {value}
        </div>
    )
}