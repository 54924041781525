import moment from "moment";

export function postProcessData(data) {
    const {report = [] || []} = {...data}
    if (!Array.isArray(report)) return data
    if (report.length < 1) return data
    const tempArray = []

    const monthNames = [12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1].reduce((acc, cur) => {
        acc[moment(cur, 'MM').format('MMMM')] = 0
        return acc
    }, {})

    try {
        report.forEach((item) => {
            const checkFunction = (e) => e.Resource === item.Resource
            const monthString = moment(item.Month, 'MM').format('MMMM')
            const existIndex = tempArray.findIndex(checkFunction)
            delete item['Month']
            if (existIndex !== -1) {
                tempArray[existIndex][monthString] = item['Cost']
            } else {
                const localObj = {...item, ...monthNames}
                localObj[monthString] = item['Cost']
                tempArray.push(localObj)
            }
        })

        data.report = tempArray
        return data
    } catch (e) {
        return data
    }
}
