import {
    Badge,
    Box, Button, Fade, Grid, IconButton,
    Paper,
    Popover,
    Typography
} from "@material-ui/core";
import React, {useEffect, useRef, useState} from "react";
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import WuTextField from "../../General/WuTextFeild";
import {Autocomplete} from "@material-ui/lab";
import ListRoundedIcon from "@material-ui/icons/ListRounded";

export function Filter(props) {
    const {data} = {...props}
    const [activeFilterNumber, setActiveFilterNumber] = useState(1);
    const handleFilterNumber = (e) => {
        setActiveFilterNumber(2 + Object.keys(e).length);
        props.onChange(e)
    }
    return (
        <Box>
            <Badge badgeContent={activeFilterNumber} color="primary">
                <Box display={'flex'} gridGap={8} ml={-0.5} mr={0.5}>
                    <FilterListIcon/>
                    <Typography variant={'body1'} style={{fontWeight: 600}}>Filter</Typography>
                </Box>
            </Badge>
            <FilterBody onChange={handleFilterNumber} data={data}/>
        </Box>
    )
}


function FilterBody(props) {
    const {data} = {...props}
    const [filter, setFilter] = useState({});
    const [activeValue, setActiveValue] = useState('');

    const handleFilterSelectChange = (e, value) => {
        if (value) {
            setActiveValue(value);
            setFilter({...filter, [value]: ''})
            setActiveValue('')
        }
    }

    const filterChange = (e) => {
        setFilter({...filter, ...e})
    }

    const removeFilter = (e) => {
        delete filter[e];
        setFilter({...filter})
    }

    useEffect(() => {
        props.onChange(filter)
    }, [filter])

    return (
        <Box mt={1} maxWidth={'250px'} minWidth={'250px'}>
            <div className={'filer-input-box'}>
                <Autocomplete
                    fullWidth
                    options={data}
                    value={activeValue}
                    onChange={handleFilterSelectChange}
                    style={{width: 250}}
                    renderInput={(params) => <WuTextField color={'secondary'} {...params} label="Add Filter"/>}
                />
                <SearchIcon style={{color: 'grey'}} className={'filter-icon'}/>
            </div>
            <Box mt={1}>
                <Grid container spacing={2}>
                    {Object.keys(filter).map((key) => <FilterSingleItem key={key} keyName={key}
                                                                        onClick={removeFilter}
                                                                        onChange={filterChange}/>)}
                </Grid>
            </Box>
        </Box>
    )
}


function FilterSingleItem(props) {
    const {keyName} = {...props}
    const currentRef = useRef();
    const [anchorEl, setAnchorEl] = useState(currentRef.current);
    const [currentFilterValue, setCurrentFilterValue] = useState('');
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        props.onChange({[keyName]: currentFilterValue})
    }, [currentFilterValue])

    useEffect(() => {
        setAnchorEl(currentRef.current)
    }, [currentRef])

    const open = Boolean(anchorEl);
    return (
        <Fade in={true} timeout={700}>
            <Grid item xs={12}>
                <div className={'filter-single-item'}>
                    <div onClick={handleClick} style={{width: '250px', overflow: 'auto'}} ref={currentRef}>
                        <Typography variant={'body2'}>{keyName}</Typography>
                        <Box display={'flex'} gridGap={8} alignItems={'center'}>

                            <Typography variant={'body1'} color={'textSecondary'}>equals</Typography>
                            <Typography variant={'body1'}
                                        style={{overflowWrap: 'anywhere'}}>{currentFilterValue || '" "'}</Typography>
                        </Box>
                    </div>
                    <IconButton size={'small'} onClick={() => props.onClick(keyName)}>
                        <DeleteOutlineOutlinedIcon/>
                    </IconButton>
                </div>
                <Popover
                    style={{marginLeft: '2em'}}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                >
                    <Paper variant={'outlined'}>
                        <Box m={2} width={'200px'}>
                            <Typography gutterBottom>Filter By {keyName}</Typography>
                            <WuTextField label={keyName}
                                         onKeyDown={(e) => {
                                             if (e.code === "Enter") {
                                                 handleClose()
                                             }
                                         }}
                                         autoFocus
                                         value={currentFilterValue}
                                         onChange={(e) => setCurrentFilterValue(e.target.value)}/>
                            <Box display={'flex'} gridGap={8} mt={1}>
                                <Button size={'small'} color={'secondary'} variant={'contained'}
                                        onClick={handleClose}
                                        disableElevation>Apply</Button>
                                <Button size={'small'} color={'default'} variant={'contained'}
                                        onClick={handleClose}
                                        disableElevation>Cancel</Button>
                            </Box>

                        </Box>
                    </Paper>
                </Popover>
            </Grid>
        </Fade>
    )
}