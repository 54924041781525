import React, {useEffect, useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Graph from "./Graph";
import {AccountBudget} from "../Create";
import service from "../../../../service/service";
import moment from 'moment';
import {accountLengthAdjust} from "../../../../helper/generalFunctions";
import {Collapse} from "@material-ui/core";
import {ProgressCircleCustomSize} from "../../../ProgressCircle/ProgressCircle";
import Box from "@material-ui/core/Box";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const disabled = localStorage.getItem("loginType") !== "admin";

export default function BudgetInformation(props) {

    const {dialogOpen, data} = {...props}

    const [open, setOpen] = React.useState(false);
    const [budgetInformation, setBudgetInformation] = React.useState(props.data);
    const {budgetId} = {...data}
    const [graphLoad, setGraphLoad] = useState(false);
    const [chartData, setChartData] = useState({
        BudgetedAmount: '', CurrentAmount: '', varience: '', percentage: '', graph: [], noData: "There's no data"
    });

    useEffect(() => {
        setOpen(dialogOpen);
        if (dialogOpen === true) {
            setGraphLoad(!graphLoad)
        }
        setBudgetInformation(props.data)
    }, [props.dialogOpen])


    useEffect(() => {
        setChartData({
            BudgetedAmount: undefined,
            CurrentAmount: '',
            varience: '',
            percentage: '',
            graph: [],
            noData: "There's no data"
        })

        let acct_id = budgetInformation.accountId;
        const param = {
            budget_name: accountLengthAdjust(budgetInformation.accountId),
            account_id: accountLengthAdjust(budgetInformation.accountId),
        }
        if (acct_id && budgetInformation.BudgetPeriod !== 'ANNUALLY') {
            service.budget().BudgetHistoryById(param)
                .then((ref) => {
                    let budgetHistoy = ref.data.budget;
                    let graphDatas = [];
                    let BudgetAmounts = budgetHistoy[0].BudgetedAmount.Amount;
                    let BudgetLength = budgetHistoy.length;
                    let currentAmnount = budgetHistoy[BudgetLength - 1].ActualAmount.Amount;
                    budgetHistoy.forEach(function (value, i) {

                        let graphData = {};
                        if (budgetInformation.BudgetPeriod === 'MONTHLY') {
                            let grapgMonth = moment(value.TimePeriod.Start, 'YYYY/MM/DD');

                            graphData['x'] = grapgMonth.format('MMM');
                        }

                        if (budgetInformation.BudgetPeriod === 'QUARTERLY') {
                            let grapgMonth = moment(value.TimePeriod.End, 'YYYY/MM/DD').utc().quarter();

                            graphData['x'] = "Q" + grapgMonth;
                        }

                        if (budgetInformation.BudgetPeriod === 'DAILY') {
                            let grapgMonth = moment(value.TimePeriod.Start, 'YYYY/MM/DD');

                            graphData['x'] = grapgMonth.format('DD');
                        }


                        graphData['y'] = Math.round(value.ActualAmount.Amount);

                        //console.log(graphData)
                        graphDatas.push(graphData)


                    });
                    let vari = BudgetAmounts - currentAmnount;
                    let per = (currentAmnount / BudgetAmounts) * 100;
                    setChartData({
                        BudgetedAmount: BudgetAmounts,
                        CurrentAmount: Math.round(currentAmnount),
                        varience: Math.round(vari),
                        percentage: [Math.round(per)],
                        graph: graphDatas,
                        noData: "There's no data"
                    })
                })
                .catch(err => (
                    console.log(err)
                ))
        } else if (acct_id && budgetInformation.BudgetPeriod === "ANNUALLY") {
            setChartData({
                BudgetedAmount: budgetInformation.budgetAmount,
                CurrentAmount: 0,
                varience: 0,
                percentage: [0],
                graph: [],
                noData: "No data history for Annual Budget"
            })
        }

    }, [graphLoad])


    const handleClose = () => {
        props.onChange(false);
        setOpen(false);

    };


    const handleInformationChange = (event) => {
        setBudgetInformation(event)
    }
    const handleUpdateInformation = (event) => {
        if (budgetInformation.amount) {
            var Bamount = budgetInformation.amount;
        } else if (budgetInformation.budgetAmount) {
            var Bamount = budgetInformation.budgetAmount;
        }

        if (budgetInformation.accountId) {
            var Baccount = budgetInformation.accountId;
        } else if (budgetInformation.account) {
            var Baccount = budgetInformation.account;
        }
        const param = {
            "BudgetAmount": Bamount,
            "budgetTypeId": "2",
            "accountId": accountLengthAdjust(Baccount),
            "id": budgetInformation.BudgetId,
            "region": budgetInformation.region,
            'period': budgetInformation.BudgetPeriod,
            "BudgetNotificationSubscribers": budgetInformation.notificationSubscribers

        }

        service.budget().updateBudget(param)
            .then((ref) => {
                handleClose();
            })
            .catch(err => (
                console.log(err)
            ))
    }

    return (
        <div>
            <Dialog fullWidth maxWidth={"lg"} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {budgetInformation.accountId ?
                        `${accountLengthAdjust(budgetInformation.accountId)}`
                        : "Untitled budget"}
                </DialogTitle>
                <DialogContent dividers>
                    <Collapse in={chartData.BudgetedAmount}>
                        <Graph chartData={chartData}/>
                    </Collapse>
                    {!chartData.BudgetedAmount && <Loading/>}
                    <div style={{marginTop: 20}}>
                        <AccountBudget
                            applicationName={budgetInformation.applicationName}
                            environment={budgetInformation.environment}
                            region={budgetInformation.region}
                            notificationSubscribers={budgetInformation.notificationSubscribers}
                            amount={budgetInformation.budgetAmount}
                            account={accountLengthAdjust(budgetInformation.accountId)}
                            BudgetId={budgetInformation.BudgetId}
                            BudgetPeriod={budgetInformation.BudgetPeriod}
                            update={true}
                            onChange={(e) => handleInformationChange(e)}/>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleUpdateInformation} color="secondary" disabled={disabled}>
                        Save changes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}


function Loading() {
    return (
        <Box display={"flex"} className={"gap"} alignItems={'center'} alignContent={'center'}>
            <ProgressCircleCustomSize size={16}/>
            <Typography> loading... </Typography>
        </Box>
    )
}