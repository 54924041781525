import React, {useState} from 'react';
import {Collapse, Divider, Typography} from "@material-ui/core";
import {WuValidationEnabledTextField} from "../General/WuTextFeild";
import WuInformationPopup from "../General/WuInformationPopup";
import {warningEmoji} from "../../constants/generalEmojiRepresentation";
import WuButton from "../General/WuButton";
import WuStack from "../General/WuStack";
import {ProgressCircleCustomSize} from "../ProgressCircle/ProgressCircle";
import {apiErrorResponseJsonParser} from "../../helper/generalFunctions";
import Box from "@material-ui/core/Box";
import {Form, Formik} from "formik";
import {newUserRequestFormValidation} from "../../validations/newUserRequestFormValidation";
import {RequestFailed, RequestSubmitted} from "./Response";
import PermissionGroupAutoComplete from "./PermissionGroupAutocomplete";
import './index.scss'
import service from "../../service/service";

const buttonContainer = {
    marginTop: 2
}

const FORM_INITIAL_VALUES = {
    firstName: '',
    lastName: '',
    email: '',
    permissionGroup: '',
    password: '',
    confirmPassword: ''
}

export default function RequestForm() {
    const [apiProgress, setApiProgress] = useState({loading: false, ready: false, error: false})
    const [open, setOpen] = useState(true);

    const fetchData = async (e) => {
        try {
            setApiProgress({loading: true, ready: false, error: false})
            const params = {
                first_name: e.firstName,
                last_name: e.lastName,
                email: e.email,
                permission_group: e.permissionGroup,
                password: e.password
            }
            await service.user().requestNewUser(params)
            localStorage.setItem('userRequestSubmitted', 'true')
            setApiProgress({loading: false, ready: true, error: false})

            setTimeout(() => {
                setOpen(false)
            }, 5000)

        } catch (e) {
            const error = apiErrorResponseJsonParser(e)
            setApiProgress({loading: false, ready: false, error: error})
        }
    }

    const handleButtonClick = async (e) => {
        fetchData(e).then()
    }

    return (
        <WuInformationPopup open={open}
                            title={apiProgress.ready ? '' : `User expiring! ${warningEmoji}`}
                            onClose={() => setOpen(false)}>
            <Collapse in={!apiProgress.ready}>
                <div className={'new-user-form-request'}>
                    <Typography gutterBottom>
                        Current user will <strong>no longer work</strong> from<br/>
                        <strong> 01 January, 2022. </strong>
                        Submit a new request for creating an individual user to continue using finops dashboard.
                    </Typography>
                    <Formik initialValues={FORM_INITIAL_VALUES}
                            validateOnChange={true}
                            validateOnBlur={true}
                            validationSchema={newUserRequestFormValidation}
                            onSubmit={handleButtonClick}>
                        <Form>
                            <Box>
                                <WuStack spacing={2}>
                                    <WuValidationEnabledTextField label={'First Name'}
                                                                  required
                                                                  autoFocus
                                                                  name={'firstName'}/>
                                    <WuValidationEnabledTextField label={'Last Name'}
                                                                  name={'lastName'}/>
                                </WuStack>
                                <Divider style={{margin: 5, marginTop: 8}}/>
                                <Box>
                                    <WuValidationEnabledTextField label={'Email'}
                                                                  required
                                                                  name={'email'}/>
                                    <PermissionGroupAutoComplete/>
                                    <WuValidationEnabledTextField label={'Password'}
                                                                  required
                                                                  type={'password'}
                                                                  name={'password'}/>
                                    <WuValidationEnabledTextField label={'Confirm Password'}
                                                                  required
                                                                  type={'password'}
                                                                  name={'confirmPassword'}/>
                                </Box>
                            </Box>
                            <RequestFailed open={Boolean(apiProgress.error)} error={apiProgress.error}/>
                            <WuStack spacing={2} boxProps={buttonContainer}>
                                <WuButton color={'secondary'}
                                          type={'submit'}
                                          style={{height: 35, width: 100}}
                                          disabled={apiProgress.loading}>
                                    {apiProgress.loading ? <ProgressCircleCustomSize size={16}/> : 'SUBMIT'}
                                </WuButton>
                                <WuButton variant={'outlined'}
                                          disabled={apiProgress.loading}
                                          style={{height: 35}}
                                          color={'secondary'}>
                                    REMIND ME LATER
                                </WuButton>
                            </WuStack>
                        </Form>
                    </Formik>
                </div>
            </Collapse>
            <RequestSubmitted open={apiProgress.ready}/>
        </WuInformationPopup>
    )
}




