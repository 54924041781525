import moment from "moment";

export function setSpendInsightInfoForTodayAndYesterday(data) {
    const today = moment().format('YYYY-MM-DD')
    const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD')
    const dayBeforeYesterday = moment().subtract(2, 'days').format('YYYY-MM-DD')

    const spendInfo = {
        todaySpend: 0,
        yesterdaySpend: 0,
        dayBeforeYesterdaySpend: 0
    }

    return data.reduce((acc, current) => {
        acc['todaySpend'] += current[today]?.spend || 0
        acc['yesterdaySpend'] += current[yesterday]?.spend || 0
        acc['dayBeforeYesterdaySpend'] += current[dayBeforeYesterday]?.spend || 0
        return acc
    }, spendInfo)
}


export function summaryCalculator(data) {
    const {todaySpend, dayBeforeYesterdaySpend, yesterdaySpend} = setSpendInsightInfoForTodayAndYesterday(data)
    const todayPercentageChange = percentageChangeFn(todaySpend, yesterdaySpend)
    const yesterdayPercentageChange = percentageChangeFn(yesterdaySpend, dayBeforeYesterdaySpend)
    return ({
        loading: false,
        today: {
            spend: 12500,
            percentageChange: -67
        },
        yesterday: {
            spend: 56000,
            percentageChange: 15
        }
    })
}

function percentageChangeFn(currentValue = 0, previousValue = 0) {
    const change = currentValue - previousValue
    return parseFloat(((change / currentValue) * 100 || 0).toFixed(2))
}


export function filterResults(data = [], filter) {
    switch (filter) {
        case 'above0':
            return filterFunction(data, 0)
        case 'above20':
            return filterFunction(data, 20)
        case 'above50':
            return filterFunction(data, 50)
        case 'above100':
            return filterFunction(data, 100)
        case 'lessThan80':
            return filterFunctionBetween(data, -100000, -80)
        case 'betweenNeg80AndNeg60':
            return filterFunctionBetween(data, -80, -60)
        case 'betweenNeg60AndNeg40':
            return filterFunctionBetween(data, -60, -40)
        case 'betweenNeg40AndNeg20':
            return filterFunctionBetween(data, -40, -20)
        case 'betweenNeg20And0':
            return filterFunctionBetween(data, -20, 0)
        case 'between0And20':
            return filterFunctionBetween(data, 0, 20)
        case 'between20And40':
            return filterFunctionBetween(data, 20, 40)
        case 'between40And60':
            return filterFunctionBetween(data, 40, 60)
        case 'between60And80':
            return filterFunctionBetween(data, 60, 80)
        case 'moreThan80':
            return filterFunction(data, 80)
        default:
            return data
    }
}


function filterFunction(data, filterThreshold) {
    for (const [index, account] of data.entries()) {
        const accountProperties = Object.keys(account)
        accountProperties.forEach(timeProperties => {
            if (!(timeProperties === 'AccountId' || timeProperties === 'AccountAlias')) {
                if (Number(data[index][timeProperties]['changePercentage']) < filterThreshold) {
                    delete data[index][timeProperties]
                }
            }
        })
    }
    return data
}

function filterFunctionBetween(data, filterMin, filterMax) {
    for (const [index, account] of data.entries()) {
        const accountProperties = Object.keys(account)
        accountProperties.forEach(timeProperties => {
            if (!(timeProperties === 'AccountId' || timeProperties === 'AccountAlias')) {
                if (Number(data[index][timeProperties]['changePercentage']) < filterMin
                    || Number(data[index][timeProperties]['changePercentage']) > filterMax) {
                    delete data[index][timeProperties]
                }
            }
        })
    }
    return data
}