import {useCallback} from "react";
import {useDropzone} from 'react-dropzone';
import {Grid, IconButton, LinearProgress, Typography} from "@material-ui/core";
import {DeleteOutlined} from "@material-ui/icons";

export function UploadWidget(props) {
    const {myFiles, setMyFiles, progress} = {...props}

    const UPLOAD_PROPS = {
        accept: '.csv,.xlsx',
        maxFiles: 1,
        maxSize: 10485760
    }

    const onDrop = useCallback(
        acceptedFiles => {
            setMyFiles([...myFiles, ...acceptedFiles]);
        },
        [myFiles]
    );

    const {getRootProps, getInputProps} = useDropzone({
        ...UPLOAD_PROPS,
        onDrop
    });


    const handleDelete = () => {
        setMyFiles([])
    }

    return (
        <div>
            <div {...getRootProps({className: 'at-upload-dropzone'})}>
                <input {...getInputProps()} />
                <Typography>
                    Drop your file here, or browse
                </Typography>
                <Typography color={'textSecondary'} variant={'body2'}>
                    Supports CSV, XLSX (should not exceed 10MB)
                </Typography>
            </div>
            {myFiles.length > 0 && <Grid container className={'at-upload-file-list-wrapper'}>
                {myFiles.map(e => <AcceptedFiles key={e?.path} name={e?.path} size={e?.size}
                                                 progress={progress}
                                                 onDelete={handleDelete}/>)}
            </Grid>}
        </div>
    )
}


function AcceptedFiles(props) {
    const {name, size, onDelete, progress} = {...props}
    return (
        <Grid item xs={12} spacing={2} className={'at-upload-wrapper'}>
            <div className={'at-upload-file-item'}>
                <Typography>
                    {name} - {convertToMbFromBytes(size)} MB
                </Typography>
                <IconButton size={'small'} onClick={onDelete}>
                    <DeleteOutlined/>
                </IconButton>
            </div>
            <div className={'at-upload-file-item-status'}>
                <LinearProgress variant={'determinate'}
                                color={'secondary'}
                                value={progress}
                                style={{background: progress ? 'inherit' : 'transparent'}}
                />
            </div>
        </Grid>
    )
}

function convertToMbFromBytes(bytes = 0) {
    return ((bytes / 1024) / 1024).toFixed(2)
}