import React, {useEffect, useState} from 'react';
import ReactApexChart from "react-apexcharts";


export default function MostSpendServiceGraph({graphDataItem}) {
    const [ready, setReady] = useState(false);
    const [graphData, setGraphData] = useState({});
    useEffect(() => {
        setReady(false);
        setGraphData({
            series: [{data: graphDataItem.accountAndSpend.splice(0, 7)}],
            options: {
                chart: {
                    type: 'bar',
                    height: 380,
                    offsetY: -20,
                    fontFamily: 'Roboto',
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    bar: {
                        barHeight: '80%',
                        distributed: true,
                        horizontal: true,
                        borderRadius: 4,
                        endingShape: 'rounded',
                        dataLabels: {
                            position: 'bottom'
                        },
                    }
                },
                grid: {
                    show: false
                },
                dataLabels: {
                    style: {
                        fontSize: '12px',
                        fontWeight: 'medium',
                        colors: ['#000']
                    },
                    background: {
                        enabled: false,
                        foreColor: '#fff',
                    },
                    enabled: true,
                    textAnchor: 'start',
                    formatter: function (val, opt) {
                        return opt.w.globals.labels[opt.dataPointIndex].replace('AWS', '').replace('Amazon', '') + ": $" + val
                    },
                    offsetX: 0,
                    dropShadow: {
                        enabled: false
                    }
                },
                stroke: {
                    width: 1,
                    colors: ['rgba(0,0,0,0)']
                },
                xaxis: {
                    categories: graphDataItem.graphData.xAxis.splice(0, 7),
                    labels: {
                        formatter: function (val, opt) {
                            return "$" + val
                        },
                    }
                },
                tooltip: {
                    enabledOnSeries: false,
                    y: {
                        formatter: function (val, opt) {
                            return "- $" + val
                        },
                        title: {
                            formatter: function (val, opt) {
                                return opt.w.globals.labels[opt.dataPointIndex]
                            }
                        }
                    }
                },
                yaxis: {
                    labels: {
                        show: false
                    },
                    title: {
                        text: 'Service',
                        style: {
                            fontSize: '12px',
                            fontWeight: 'medium',
                            fontFamily: 'Century Gothic',
                            color: '#263238'
                        },
                    },
                },
                legend: {
                    show: false,
                    position: 'bottom',
                    offsetY: 8,
                    offsetX: -10,
                    markers: {
                        offsetX: -4,
                        offsetY: 0
                    },
                    itemMargin: {
                        horizontal: 8,
                        vertical: 8
                    },
                    formatter: function (val, opt) {
                        return val.replace('AWS', '').replace('Amazon', '')
                    },
                    onItemHover: {
                        highlightDataSeries: true
                    },
                },
            },
        });
        setReady(true);
    }, [])

    return (
        <>
            {
                ready &&
                <ReactApexChart options={graphData.options} series={graphData.series} type="bar" height={380}/>
            }
        </>
    )
}